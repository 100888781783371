import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ClassifyService } from '@libs/core/src/lib/services/template/classify.service';
import { ClassifyLevel } from '@libs/core/src/lib/models/classify';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';

@Component({
  selector: 'app-add-tem',
  templateUrl: './add-tem.component.html',
  styleUrls: ['./add-tem.component.scss']
})
export class AddTemComponent implements OnInit {
  products: ClassifyLevel[];
  currentProduct: ClassifyLevel;

  loading = false;

  classify_pid;
  classify_id;

  constructor(
    public coop: CoopService,
    private modalRef: NzModalRef,
    public classify: ClassifyService,
    private http: HttpClient,
    private message: MessageService
  ) { }

  ngOnInit() {
    this.products = this.classify.products.map(data => {
      data.classifyConfig = data.classifyConfig.filter(item => item.expand == 1 && item.classifyId != item.classifyPid);
      return data;
    });
    // this.currentProduct = this.products[0]
  }

  changeProduct(val) {
    this.currentProduct = this.products.find(item => item.classify.classifyId == val);
  }

  submit() {
    if (!this.classify_pid) {
      this.message.info('请选择品类');
      return false;
    }

    const params: any = {
      classify_pid: this.classify_pid
    };
    if (this.classify_id) {
      params['classify_id'] = this.classify_id
    }
    this.loading = true;
    this.http.post('/coop-template-official', params).subscribe(
      () => {
        this.loading = false;
        this.message.success('添加成功');
        this.modalRef.close('refresh');
      },
      err => {
        this.loading = false;
        this.message.error(err)
      }
    )
  }

  close() {
    this.modalRef.close()
  }

}
