import { Component, OnInit, Input } from '@angular/core';
import { EditorService } from '../../../services/editor.service';
import { UserInterfaceService } from '../../../services/ui.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { getSvgColors } from '../../../utils/misc/common';
import * as $ from 'jquery';
import * as Color from 'color';
declare const EyeDropper: any;
@Component({
  selector: 'app-color-gradient',
  templateUrl: './color-gradient.component.html',
  styleUrls: ['./color-gradient.component.scss']
})
export class ColorGradientComponent implements OnInit {
  @Input() guideItem;
  @Input() type;
  @Input() itemType;
  public downType = '';
  colorType = '0';
  hue = 0;
  saturation;
  value;
  HEX = 'fffff';
  Alpha = 100;
  colors = [
    'rgb(255, 105, 0)',
    'rgb(252, 185, 0)',
    'rgb(123, 220, 181)',
    'rgb(0, 208, 132)',
    'rgb(142, 209, 252)',
    'rgb(6, 147, 227)',
    'rgb(171, 184, 195)',
    'rgb(96, 125, 139)',
    'rgb(235, 20, 76)',
    'rgb(247, 141, 167)',
    'rgb(186, 104, 200)',
    'rgb(153, 0, 239)',
  ];
  gradientColors = [
    'linear-gradient(0deg, rgb(255, 177, 153) 0%, rgb(255, 8, 68) 100%)',
    'linear-gradient(270deg, rgb(251, 171, 126) 8%, rgb(247, 206, 104) 92%)',
    'linear-gradient(315deg, rgb(150, 230, 161) 8%, rgb(212, 252, 121) 92%)',
    'linear-gradient(to left, rgb(249, 240, 71) 0%, rgb(15, 216, 80) 100%)',
    'linear-gradient(315deg, rgb(194, 233, 251) 8%, rgb(161, 196, 253) 92%)',
    'linear-gradient(0deg, rgb(0, 198, 251) 0%, rgb(0, 91, 234) 100%)',
    'linear-gradient(0deg, rgb(167, 166, 203) 0%, rgb(137, 137, 186) 51%, rgb(137, 137, 186) 100%)',
    'linear-gradient(0deg, rgb(80, 82, 133) 0%, rgb(88, 94, 146) 15%, rgb(101, 104, 159) 28%, rgb(116, 116, 176) 43%, rgb(126, 126, 187) 57%, rgb(131, 137, 199) 71%, rgb(151, 149, 212) 82%, rgb(162, 161, 220) 92%, rgb(181, 174, 228) 100%)',
    'linear-gradient(270deg, rgb(255, 126, 179) 0%, rgb(255, 117, 140) 100%)',
    'linear-gradient(90deg, rgb(120, 115, 245) 0%, rgb(236, 119, 171) 100%)',
    'linear-gradient(45deg, rgb(46, 38, 111) 0%, rgba(150, 100, 221, 0.22) 100%)',
    'radial-gradient(circle, yellow 0%, rgb(0, 153, 102) 50%, purple 100%)',
  ];
  gradientColorArr = [
    {
      "type": 1,
      "style": "background:linear-gradient(360deg,rgba(255, 194, 238, 1) 0%,rgba(252, 192, 235, 1) 0%,rgba(175, 202, 250, 1) 100%)",
      "color": "",
      "opacity": "1.0000",
      "gradientMode": "linear",
      "gradientAngle": 360,
      "radial": null,
      "gradientList": [
        {
          "color": "rgba(255, 194, 238, 1)",
          "colorHex": "#ffc2ee",
          "opacity": "1.0000",
          "position": "0%"
        },
        {
          "color": "rgba(252, 192, 235, 1)",
          "colorHex": "#fcc0eb",
          "opacity": "1.0000",
          "position": "0%"
        },
        {
          "color": "rgba(175, 202, 250, 1)",
          "colorHex": "#afcafa",
          "opacity": "1.0000",
          "position": "100%"
        }
      ]
    },
    {
      "type": 1,
      "style": "background:linear-gradient(135deg,rgba(250, 216, 105, 1) 0%,rgba(255, 163, 135, 1) 100%)",
      "color": "",
      "opacity": "1.0000",
      "gradientMode": "linear",
      "gradientAngle": 135,
      "radial": null,
      "gradientList":[
        {
          "color": "rgba(250, 216, 105, 1)",
          "colorHex": "#fad869",
          "opacity": "1.0000",
          "position": "0%"
        },
        {
          "color": "rgba(255, 163, 135, 1)",
          "colorHex": "#ffa387",
          "opacity": "1.0000",
          "position": "97.02%"
        }
      ]
    },
    {
      "type": 1,
      "style": "background:linear-gradient(360deg,rgba(255, 150, 154, 1) 0%,rgba(255, 207, 240, 1) 100%)",
      "color": "",
      "opacity": "1.0000",
      "gradientMode": "linear",
      "gradientAngle": 360,
      "radial": null,
      "gradientList":[
        {
          "color": "rgba(255, 150, 154, 1)",
          "colorHex": "#ff969a",
          "opacity": "1.0000",
          "position": "0%"
        },
        {
          "color": "rgba(255, 207, 240, 1)",
          "colorHex": "#ffcff0",
          "opacity": "1.0000",
          "position": "97.02%"
        }
      ]
    },
    {
      "type": 1,
      "style":"background:linear-gradient(360deg,rgba(255, 211, 199, 1) 0%,rgba(252, 204, 252, 1) 100%)",
      "color": "",
      "opacity": "1.0000",
      "gradientMode": "linear",
      "gradientAngle": 360,
      "radial": null,
      "gradientList":[
        {
          "color": "rgba(255, 211, 199, 1)",
          "colorHex": "#ffd3c7",
          "opacity": "1.0000",
          "position": "0%"
        },
        {
          "color": "rgba(252, 204, 252, 1)",
          "colorHex": "#fcccfc",
          "opacity": "1.0000",
          "position": "97.02%"
        }
      ]
    },
    {
      "type": 1,
      "style": "background:linear-gradient(90deg,rgba(255, 236, 209, 1) 0%,rgba(255, 186, 163, 1) 100%)",
      "color": "",
      "opacity": "1.0000",

      "gradientMode": "linear",
      "gradientAngle": 90,
      "radial": null,
      "gradientList":[
        {
          "color": "rgba(255, 236, 209, 1)",
          "colorHex": "#ffecd1",
          "opacity": "1.0000",
          "position": "0%"
        },
        {
          "color": "rgba(255, 186, 163, 1)",
          "colorHex": "#ffbaa3",
          "opacity": "1.0000",
          "position": "100%"
        }
      ]
    },
    {
      "type": 1,
      "style": "background:linear-gradient(360deg,rgb(0,0,0,1) 0%,rgba(180, 157, 235, 1) 0%,rgba(255, 199, 239, 1) 100%)",
      "color": "",
      "opacity": "1.0000",
      "gradientMode": "linear",
      "gradientAngle": 360,
      "radial": null,
      "gradientList":[
        {
          "color": "rgb(0,0,0,1)",
          "colorHex": "#000",
          "opacity": "1.0000",
          "position": "0%"
        },
        {
          "color": "rgba(180, 157, 235, 1)",
          "colorHex": "#b49deb",
          "opacity": "1.0000",
          "position": "0%"
        },
        {
          "color": "rgba(255, 199, 239, 1)",
          "colorHex": "#ffc7ef",
          "opacity": "1.0000",
          "position": "100%"
        }
      ]
    },
    {
      "type": 1,
      "style": "background:linear-gradient(360deg,rgba(255, 204, 243, 1) 0%,rgba(242, 235, 245, 1) 100%)",
      "color": "",
      "opacity": "1.0000",
      "gradientMode": "linear",
      "gradientAngle": 360,
      "radial": null,
      "gradientList":[
        {
          "color": "rgba(255, 204, 243, 1)",
          "colorHex": "#ffccf3",
          "opacity": "1.0000",
          "position": "0%"
        },
        {
          "color": "rgba(242, 235, 245, 1)",
          "colorHex": "#f2ebf5",
          "opacity": "1.0000",
          "position": "100%"
        }
      ]
    },
    {
      "type": 1,
      "style": "background:linear-gradient(135deg,rgba(166, 200, 255, 1) 0%,rgba(194, 236, 255, 1) 100%)",
      "color": "",
      "opacity": "1.0000",
      "gradientMode": "linear",
      "gradientAngle": 135,
      "radial": null,
      "gradientList":[
        {
          "color": "rgba(166, 200, 255, 1)",
          "colorHex": "#a6c8ff",
          "opacity": "1.0000",
          "position": "0%"
        },
        {
          "color": "rgba(194, 236, 255, 1)",
          "colorHex": "#c2ecff",
          "opacity": "1.0000",
          "position": "100%"
        }
      ]
    },
    {
      "type": 1,
      "style": "background:linear-gradient(135deg,rgba(212, 252, 119, 1) 0%,rgba(157, 242, 169, 1) 100%)",
      "color": "",
      "opacity": "1.0000",
      "gradientMode": "linear",
      "gradientAngle": 135,
      "radial": null,
      "gradientList":[
        {
          "color": "rgba(212, 252, 119, 1)",
          "colorHex": "#d4fc77",
          "opacity": "1.0000",
          "position": "0%"
        },
        {
          "color": "rgba(157, 242, 169, 1)",
          "colorHex": "#9df2a9",
          "opacity": "1.0000",
          "position": "100%"
        }
      ]
    },
    {
      "type": 1,
      "style":"background:linear-gradient(135deg,rgba(166, 193, 255, 1) 0%,rgba(252, 129, 133, 1) 100%)",
      "color": "",
      "opacity": "1.0000",
      "gradientMode": "linear",
      "gradientAngle": 135,
      "radial": null,
      "gradientList":[
        {
          "color": "rgba(166, 193, 255, 1)",
          "colorHex": "#a6c1ff",
          "opacity": "1.0000",
          "position": "0%"
        },
        {
          "color": "rgba(252, 129, 133, 1)",
          "colorHex": "#fc8185",
          "opacity": "1.0000",
          "position": "100%"
        }
      ]
    },
    {
      "type": 1,
      "style": "background:linear-gradient(135deg,rgba(255, 206, 145, 1) 0%,rgba(225, 136, 247, 1) 100%)",
      "color": "",
      "opacity": "1.0000",
      "gradientMode": "linear",
      "gradientAngle": 135,
      "radial": null,
      "gradientList": [
        {
          "color": "rgba(255, 206, 145, 1)",
          "colorHex": "#ffce91",
          "opacity": "1.0000",
          "position": "0%"
        },
        {
          "color": "rgba(225, 136, 247, 1)",
          "colorHex": "#e188f7",
          "opacity": "1.0000",
          "position": "100%"
        }
      ]
    },
    {
      "type": 1,
      "style": "background:linear-gradient(135deg,rgba(228, 199, 255, 1) 0%,rgba(144, 198, 252, 1) 100%)",
      "color": "",
      "opacity": "1.0000",
      "gradientMode": "linear",
      "gradientAngle": 135,
      "radial": null,
      "gradientList": [
        {
          "color": "rgba(228, 199, 255, 1)",
          "colorHex": "#e4c7ff",
          "opacity": "1.0000",
          "position": "0%"
        },
        {
          "color": "rgba(144, 198, 252, 1)",
          "colorHex": "#90c6fc",
          "opacity": "1.0000",
          "position": "100%"
        }
      ]
    }
  ];
  circle = {
    tl:'circle at left top',
    tm:'circle at center top',
    tr:'circle at right top',
    l:'circle at left center',
    m:'circle',
    r:'circle at right center',
    bl:'circle at left bottom',
    bm:'circle at center bottom',
    br:'circle at right bottom',
  }
  gradientAngle = 180;
  gradientAngleRotate = 'rotate(90deg)';
  gradientList = [
    {
      position: '0%',
      color:'rgb(0,0,0,1)',
      opacity:'1',
      colorHex:'#000'
      //style:'left: 0%; color: rgba(0,0,0,1);'
    },{
      position: '100%',
      color:'rgb(255, 255, 255,1)',
      opacity:'1',
      colorHex:'#fff'
      //style:'left: 100%; color: rgba(255, 255, 255,1);'
    }
  ]
  selectListIndex = 0;
  gradientBackground = 'linear-gradient(0deg, rgba(0, 0, 0,1) 0%, rgba(255, 255, 255,1) 100%)';
  gradientMode = true;
  radial = 'm';
  colorStyle;
  selectDisabled = false;
  fileList: any[];
  thumbnailUrl: string;
  // 查询条件
  initialQuery = {
    page: 1
  };
  query = {
    ...this.initialQuery
  };
  // 颜色类型
  color = {
    0:'单色',
    1:'渐变',
  } ;
  editId: string | null = null;
  startContentText:string;
  loading = false;
  loadingItem = false;
  showInputSort = false;
  checked = true;
  setOfCheckedId = new Set<number>();
  editorDisabled = true;
  editCid = '' ;
  indeterminate = false;
  isAllChecked = false;
  constructor(
    public editor: EditorService,
    public ui: UserInterfaceService,
    private modal: NzMessageService,
  ) {
  }

  ngOnInit() {
    this.colorType = this.type;
    this.init();
  }

  onFocus() {
    this.editor.hasFocus = false;
  }
  onHexBlur(){
    this.editor.hasFocus = true;
    if(/^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(this.HEX)){
      if(this.HEX.length === 3) {
        let arr = this.HEX.split('');
        let brr = this.HEX.split('');
        arr.splice(1, 0, brr[0]);
        arr.splice(3, 0, brr[1]);
        arr.splice(5, 0, brr[2]);
        this.HEX = arr.join('');
      }
    } else {
      this.HEX = '000000'
    }
    const colorpicker = document.getElementById('colorpicker');
    const spanSlider = document.getElementById('spanSlider');

    const colorBar = document.getElementById('colorBar');
    const divSlider = document.getElementById('divSlider');

    const alphaBar = document.getElementById('alphaBar');
    const alphaSlider = document.getElementById('alphaSlider');

    const colorpickerBbox = colorpicker.getBoundingClientRect();
    const colorBarBbox = colorBar.getBoundingClientRect();
    let hsv = this.hexToHsv('#' + this.HEX);
    if(!hsv.h) {
      hsv.h = 0;
    }
    const divSliderXLeft = (Number(hsv.h)/360)*colorBarBbox.width - 7;
    divSlider.style.left = divSliderXLeft + 'px';
    const spanSliderXLeft = (Number(hsv.s)/100)*colorpickerBbox.width - 7;
    const spanSliderYTop = (1 - Number(hsv.v)/100)*colorpickerBbox.height - 7;
    spanSlider.style.left = spanSliderXLeft + 'px';
    spanSlider.style.top = spanSliderYTop + 'px';

    this.hue = hsv.h;
    this.saturation = hsv.s;
    this.value = hsv.v;
    let rgb = this.hsvTORgb(this.hue,100,100);
    //色相选择后新的背景色
    let rgba = this.generateRGBA(rgb[0],rgb[1],rgb[2],this.Alpha/100);
    divSlider.style.backgroundColor = rgba;
    colorpicker.style.backgroundColor = rgba;
    //滑块新颜色
    let rgb2 = this.hsvTORgb(this.hue,this.saturation,this.value);
    let rgba2 = this.generateRGBA(rgb2[0],rgb2[1],rgb2[2],this.Alpha/100);
    spanSlider.style.backgroundColor = rgba2;
    alphaSlider.style.backgroundColor = rgba2;
    if(this.colorType === '0'){
      this.onBgColorChange(rgba2);
    } else {
      this.gradientList[this.selectListIndex].color = rgba2;
      this.gradientList[this.selectListIndex].colorHex = '#' + this.HEX;
    }
    alphaBar.style.background = 'linear-gradient(to right, rgba(' + rgb2.join(',') + ',0'  + '), rgb(' + rgb2.join(',') + '))';
    this.setGradient()
  }

  onBlur() {
    this.editor.hasFocus = true;
    const alphaBar = document.getElementById('alphaBar');
    const alphaSlider = document.getElementById('alphaSlider');
    const alphaBbox = alphaBar.getBoundingClientRect();
    let xLeft = alphaBbox.width * Number(this.Alpha/100) - 7;
    alphaSlider.style.left = xLeft + 'px';
    alphaSlider.style.opacity = String(this.Alpha/100);
    let rgb = this.hsvTORgb(this.hue,this.saturation,this.value);
    let backgroundColor = this.rgbToHex(rgb[0],rgb[1],rgb[2]);
    let rgba = this.generateRGBA(rgb[0],rgb[1],rgb[2],this.Alpha/100);
    if(this.colorType === '0'){
      this.colorStyle = 'background-color:' + rgba;
      this.onBgColorChange(rgba);
    } else {
      this.gradientList[this.selectListIndex].color = rgba;
      this.gradientList[this.selectListIndex].colorHex = '#' + backgroundColor;
      this.setGradient();
    }

  }

  init(color?){
    if((this.guideItem.selectedColor && this.type == this.guideItem.selectedColor.type) || color) {
      let selectedColor;
      if(color) {
        selectedColor = color;
      } else {
        selectedColor = this.guideItem.selectedColor;
      }

      const colorpicker = document.getElementById('colorpicker');
      const spanSlider = document.getElementById('spanSlider');

      const colorBar = document.getElementById('colorBar');
      const divSlider = document.getElementById('divSlider');

      const alphaBar = document.getElementById('alphaBar');
      const alphaSlider = document.getElementById('alphaSlider');

      const colorpickerBbox = colorpicker.getBoundingClientRect();
      const colorBarBbox = colorBar.getBoundingClientRect();
      const alphaBbox = alphaBar.getBoundingClientRect();
      let hsv ;
      let xLeft;
      if(this.type == '0') {
        hsv = this.hexToHsv(selectedColor.color);
        xLeft = alphaBbox.width * Number(selectedColor.opacity) - 7;
        this.HEX = selectedColor.color.slice(1);
        this.Alpha = 100 * Number(selectedColor.opacity);
        this.gradientAngle = 180;
        this.gradientAngleRotate = '';
        this.gradientList = [];
        this.selectListIndex = 1;
        this.gradientBackground = '';
        this.gradientMode = true;
        this.radial = '';
      }
      if(this.type == '1') {
        this.gradientList =  selectedColor.gradientList;
        let length = selectedColor.gradientList.length;
        hsv = this.hexToHsv(selectedColor.gradientList[length-1].colorHex);
        xLeft = alphaBbox.width * Number(selectedColor.gradientList[length-1].opacity) - 7;
        this.HEX = selectedColor.gradientList[length-1].colorHex.slice(1);
        this.Alpha = 100 * Number(selectedColor.gradientList[length-1].opacity);
      }
      alphaSlider.style.left = xLeft + 'px';
      const divSliderXLeft = (Number(hsv.h)/360)*colorBarBbox.width - 7;
      divSlider.style.left = divSliderXLeft + 'px';
      const spanSliderXLeft = (Number(hsv.s)/100)*colorpickerBbox.width - 7;
      const spanSliderYTop = (1 - Number(hsv.v)/100)*colorpickerBbox.height - 7;
      spanSlider.style.left = spanSliderXLeft + 'px';
      spanSlider.style.top = spanSliderYTop + 'px';
      this.hue = hsv.h;
      this.saturation = hsv.s;
      this.value = hsv.v;
      let rgb = this.hsvTORgb(this.hue,100,100);
      //色相选择后新的背景色
      let rgba = this.generateRGBA(rgb[0],rgb[1],rgb[2],this.Alpha/100);
      divSlider.style.backgroundColor = rgba;
      colorpicker.style.backgroundColor = rgba;
      //滑块新颜色
      let rgb2 = this.hsvTORgb(this.hue,this.saturation,this.value);
      let rgba2 = this.generateRGBA(rgb2[0],rgb2[1],rgb2[2],this.Alpha/100);
      spanSlider.style.backgroundColor = rgba2;
      alphaSlider.style.backgroundColor = rgba2;
      if(this.type == '1'){
        this.gradientMode = selectedColor.gradientMode === 'linear' ? true :false;
        this.gradientAngle = selectedColor.gradientAngle;
        this.gradientAngleRotate = 'rotate('+ (this.gradientAngle - 90) + 'deg)';
        this.radial = selectedColor.radial;
        this.setGradient();
        if(color && this.itemType && this.itemType === 'svg') {
          setTimeout(() => {
            this.updateColor()
          },0)
        }
      }
    } else {
      this.hue = 0;
      this.saturation = 0;
      this.value = 100;
      this.Alpha = 100;
      this.HEX = 'fffff';
      this.gradientAngle = 180;
      this.gradientAngleRotate = 'rotate(90deg)';
      this.gradientList = [
        {
          position: '0%',
          color:'rgb(0,0,0,1)',
          opacity:'1',
          colorHex:'#000'
        },{
          position: '100%',
          color:'rgba(255, 255, 255, 1)',
          opacity:'1',
          colorHex:'#fffff'
        }
      ];
      this.selectListIndex = 1;
      this.gradientBackground = 'linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgb(255, 255, 255, 1) 100%)';
      this.gradientMode = true;
      this.radial = 'm';
    }
  }

  onBgColorChange(color): void {
    if (
      this.editor.activePage.props.bgId &&
      this.editor.activePage.props.bgId !== ''
    ) {
      const bgElement = this.editor.findElement(
        this.editor.activePage.props.bgId,
        this.editor.activePage.id
      );

      if (bgElement) {
        bgElement.remove(false);
        bgElement.parent.emit('element:removed', {
          target: bgElement,
          commit: false
        });
      }

      this.editor.activePage.props.bgId = null;
      this.editor.activePage.emit('modified', {
        type: 'bgId',
        target: this.editor.activePage
      });
    }
    this.editor.activePage.props.background = color;
    this.editor.activePage.props.bgColor = color;


    this.editor.template.emit('page:modified', {
      type: 'bgColor',
      target: this.editor.activePage
    });

  }

  onGradientColorsChange(background,flag?){
    if(this.itemType && this.itemType === 'text') {
      setTimeout(() => {
        this.onTextColorChange(background)
      },0)
    } else if(this.itemType && this.itemType === 'svg') {
      setTimeout(() => {
        this.onFillColorChange()
      },0)
    } else {
      if (
        this.editor.activePage.props.bgId &&
        this.editor.activePage.props.bgId !== ''
      ) {
        const bgElement = this.editor.findElement(
          this.editor.activePage.props.bgId,
          this.editor.activePage.id
        );

        if (bgElement) {
          bgElement.remove(false);
          bgElement.parent.emit('element:removed', {
            target: bgElement,
            commit: false
          });
        }

        this.editor.activePage.props.bgId = null;
        this.editor.activePage.emit('modified', {
          type: 'bgId',
          target: this.editor.activePage
        });
      }
      this.editor.activePage.props.bgColor = '';
      this.editor.activePage.props.background = background;

      this.editor.template.emit('page:modified', {
        type: 'bgColor',
        target: this.editor.activePage
      });
    }
  }



  /**
   * 事件:bright区域按下鼠标
   */
  onBrightMouseDown(event): void {
    this.downType = 'bright';
    let x, y;
    if (this.downType === 'bright') {
      const colorpicker = document.getElementById('colorpicker');
      const spanSlider = document.getElementById('spanSlider');
      const alphaBar = document.getElementById('alphaBar');
      const alphaSlider = document.getElementById('alphaSlider');
      const bbox = colorpicker.getBoundingClientRect();
      (x = event.clientX - bbox.left), (y = event.clientY - bbox.top);
      x = x > bbox.width ?  bbox.width : (x < 0 ? 0 : x);
      y = y > bbox.height ?  bbox.height : (y < 0 ? 0 : y);
      const yTop = y - 7;
      const xLeft = x - 7 ;
      spanSlider.style.top = yTop + 'px';
      spanSlider.style.left = xLeft + 'px';
      this.saturation = Math.round(x / bbox.width * 100);
      this.value = Math.round((1 - y / bbox.width ) * 100);
      let rgb = this.hsvTORgb(this.hue,this.saturation,this.value);
      let backgroundColor = this.rgbToHex(rgb[0],rgb[1],rgb[2])
      let rgba = this.generateRGBA(rgb[0],rgb[1],rgb[2],this.Alpha/100);
      spanSlider.style.backgroundColor = rgba;
      alphaSlider.style.backgroundColor = rgba;
      alphaBar.style.background = 'linear-gradient(to right, rgba(' + rgb.join(',') + ',0'  + '), rgb(' + rgb.join(',') + '))';
      this.HEX = backgroundColor;
      if(this.colorType === '0'){
        this.colorStyle = 'background-color:' + rgba;
        this.onBgColorChange(rgba);
      } else {
      this.gradientList[this.selectListIndex].color = rgba;
      this.gradientList[this.selectListIndex].colorHex = '#' + backgroundColor;
      this.setGradient();
      }
    }
  }
  /**
   * 事件:色相选择器鼠标按下
   */
  onHueMouseDown(event): void {
    this.downType = 'hue';
    let x, y;
    if (this.downType === 'hue') {
      const colorpicker = document.getElementById('colorpicker');
      const spanSlider = document.getElementById('spanSlider');

      const colorBar = document.getElementById('colorBar');
      const divSlider = document.getElementById('divSlider');

      const alphaBar = document.getElementById('alphaBar');
      const alphaSlider = document.getElementById('alphaSlider');
      const bbox = colorBar.getBoundingClientRect();
      x = event.clientX - bbox.left;
      x = x > bbox.width ?  bbox.width : (x < 0 ? 0 : x);
      const xLeft = x - 7 ;
      divSlider.style.left = xLeft + 'px';
      this.hue = Math.round((x/bbox.width)*360*100)/100 | 0;
      let rgb = this.hsvTORgb(this.hue,100,100);
      //let backgroundColor = this.rgbToHex(rgb[0],rgb[1],rgb[2]);
      //色相选择后新的背景色
      let rgba = this.generateRGBA(rgb[0],rgb[1],rgb[2],this.Alpha/100);
      divSlider.style.backgroundColor = rgba;
      colorpicker.style.backgroundColor = rgba;
      //滑块新颜色
      let rgb2 = this.hsvTORgb(this.hue,this.saturation,this.value);
      let backgroundColor2 = this.rgbToHex(rgb2[0],rgb2[1],rgb2[2]);
      let rgba2 = this.generateRGBA(rgb2[0],rgb2[1],rgb2[2],this.Alpha/100);
      spanSlider.style.backgroundColor = rgba2;
      alphaSlider.style.backgroundColor = rgba2;
      this.HEX = backgroundColor2;
      alphaBar.style.background = 'linear-gradient(to right, rgba(' + rgb2.join(',') + ',0'  + '), rgb(' + rgb2.join(',') + '))';
      if(this.colorType === '0'){
        this.colorStyle = 'background-color:' + rgba2;
        this.onBgColorChange(rgba2);
      } else {
        this.gradientList[this.selectListIndex].color = rgba2;
        this.gradientList[this.selectListIndex].colorHex = '#' + backgroundColor2;
        this.setGradient();
      }
    }
  }

  /**
   * 事件:透明度选择器鼠标按下
   */
  onAlphaMouseDown(event): void {
    this.downType = 'Alpha';
    let x;
    if (this.downType === 'Alpha') {
      const alphaBar = document.getElementById('alphaBar');
      const alphaSlider = document.getElementById('alphaSlider');
      const bbox = alphaBar.getBoundingClientRect();
      x = event.clientX - bbox.left;
      x = x > bbox.width ?  bbox.width : (x < 0 ? 0 : x);
      const xLeft = x - 7 ;
      alphaSlider.style.left = xLeft + 'px';
      this.Alpha = Math.round(x/bbox.width * 100);
      alphaSlider.style.opacity = String(this.Alpha/100);
      let rgb = this.hsvTORgb(this.hue,this.saturation,this.value);
      let backgroundColor = this.rgbToHex(rgb[0],rgb[1],rgb[2]);
      let rgba = this.generateRGBA(rgb[0],rgb[1],rgb[2],this.Alpha/100);
      if(this.colorType === '0'){
        this.colorStyle = 'background-color:' + rgba;
        this.onBgColorChange(rgba);
      } else {
        this.gradientList[this.selectListIndex].color = rgba;
        this.gradientList[this.selectListIndex].colorHex = '#' + backgroundColor;
        this.setGradient();
      }
    }
  }
  /**
   * 事件:渐变方式鼠标按下
   */
  onGradientMouseDown(event): void {
    if (!this.gradientMode) return;
    this.downType = 'gradient';
    let x,y;
    if (this.downType === 'gradient') {
      const gradient = document.getElementById('gradient');
      const bbox = gradient.getBoundingClientRect();
      let centerX = bbox.x + bbox.width/2;
      let centerY = bbox.y + bbox.height/2;
      x = event.clientX;
      y = event.clientY;
      const possibleAngles = [0, 45, 90, 135, 180, 225, 270, 315, 360];
      // 计算两点之间的角度
      const dx = x - centerX;
      const dy = y - centerY;
      let angle = Math.atan2(dy, dx) * 180 / Math.PI; // 转换弧度到角度
      if(angle<0){
        angle = angle + 360;
      }
      //console.log('鼠标点击和固定点形成的最近角是：', angle, '度');
      let result = this.findClosestValue(angle,possibleAngles);
      //console.log('鼠标点击和固定点形成的最近45角是：', result, '度');
      this.gradientAngleRotate = 'rotate('+ result + 'deg)';
      this.gradientAngle = result + 90;
      this.setGradient();
    }
  }

    /**
   * 事件:渐变点鼠标按下
   */
  onGradientLineMouseDown(event): void {
    if (!this.gradientMode) return;
    this.downType = 'gradient-point';
    let x,y;
    if (this.downType === 'gradient-point') {
      const gradient = document.getElementById('gradient-point');
      const bbox = gradient.getBoundingClientRect();
      x = event.clientX - bbox.left;
      x = x > bbox.width ?  bbox.width : (x < 0 ? 0 : x);
      const xLeft = x;
      const position = parseFloat((xLeft*100/bbox.width).toFixed(2));
      // const point = JSON.parse(JSON.stringify(this.gradientList[this.selectListIndex]));
      // point.position = xLeft*100/bbox.width + '%';
      // this.gradientList.push(point);
      // this.gradientList.sort((a,b) => Number(a.position.split('%').join('')) - Number(b.position.split('%').join('')))
      const index = this.gradientList.findIndex(item => Math.abs(Number(item.position.split('%').join('')) - position) < 7);
      if( index !== -1) {
        this.selectListIndex = index;
      }
    }
  }
  onGradientLineMouseUp(event): void {
    if (!this.gradientMode) return;
    let x,y;
    const gradient = document.getElementById('gradient-point');
    const bbox = gradient.getBoundingClientRect();
    x = event.clientX - bbox.left;
    x = x > bbox.width ?  bbox.width : (x < 0 ? 0 : x);
    const xLeft = x;
    const point = JSON.parse(JSON.stringify(this.gradientList[this.selectListIndex]));
    point.position = parseFloat((xLeft*100/bbox.width).toFixed(2)) + '%';
    this.gradientList.push(point);
    this.gradientList.sort((a,b) => Number(a.position.split('%').join('')) - Number(b.position.split('%').join('')))
    const index = this.gradientList.findIndex(item => item.position === point.position);
    this.selectListIndex = index;
    this.setGradient();
  }

  /**
   * 事件:鼠标在窗口移动
   * @param event 事件对象
   */
  onWindowMouseMove(event): void {
    let x, y;
    const colorpicker = document.getElementById('colorpicker');
    const spanSlider = document.getElementById('spanSlider');
    const colorBar = document.getElementById('colorBar');
    const divSlider = document.getElementById('divSlider');
    const alphaBar = document.getElementById('alphaBar');
    const alphaSlider = document.getElementById('alphaSlider');
    if (this.downType === 'bright') {
      const bbox = colorpicker.getBoundingClientRect();
      (x = event.clientX - bbox.left), (y = event.clientY - bbox.top);
      x = x > bbox.width ?  bbox.width : (x < 0 ? 0 : x);
      y = y > bbox.height ?  bbox.height : (y < 0 ? 0 : y);
      const yTop = y - 7;
      const xLeft = x - 7 ;
      spanSlider.style.top = yTop + 'px';
      spanSlider.style.left = xLeft + 'px';
      this.saturation = Math.round(x / bbox.width * 100);
      this.value = Math.round((1 - y / bbox.width ) * 100);
      let rgb = this.hsvTORgb(this.hue,this.saturation,this.value);
      let backgroundColor = this.rgbToHex(rgb[0],rgb[1],rgb[2])
      let rgba = this.generateRGBA(rgb[0],rgb[1],rgb[2],this.Alpha/100);
      spanSlider.style.backgroundColor = rgba;
      alphaSlider.style.backgroundColor = rgba;
      alphaBar.style.background = 'linear-gradient(to right, rgba(' + rgb.join(',') + ',0'  + '), rgb(' + rgb.join(',') + '))';
      this.HEX = backgroundColor;
      if(this.colorType === '0'){
        this.colorStyle = 'background-color:' + rgba;
        this.onBgColorChange(rgba);
      } else {
      this.gradientList[this.selectListIndex].color = rgba;
      this.gradientList[this.selectListIndex].colorHex = '#' + backgroundColor;
      this.setGradient();
      }
    } else if (this.downType === 'hue') {
      const bbox = alphaBar.getBoundingClientRect();
      x = event.clientX - bbox.left;
      x = x > bbox.width ?  bbox.width : (x < 0 ? 0 : x);
      const xLeft = x - 7 ;
      divSlider.style.left = xLeft + 'px';
      this.hue = Math.round((x/bbox.width)*360*100)/100 | 0;
      let rgb = this.hsvTORgb(this.hue,100,100);
      let backgroundColor = this.rgbToHex(rgb[0],rgb[1],rgb[2]);
      //色相选择后新的背景色
      let rgba = this.generateRGBA(rgb[0],rgb[1],rgb[2],this.Alpha/100);
      divSlider.style.backgroundColor = rgba;
      colorpicker.style.backgroundColor = rgba;
      //滑块新颜色
      let rgb2 = this.hsvTORgb(this.hue,this.saturation,this.value);
      let backgroundColor2 = this.rgbToHex(rgb2[0],rgb2[1],rgb2[2]);
      let rgba2 = this.generateRGBA(rgb2[0],rgb2[1],rgb2[2],this.Alpha/100);
      spanSlider.style.backgroundColor = rgba2;
      alphaSlider.style.backgroundColor = rgba2;
      this.HEX = backgroundColor2;
      alphaBar.style.background = 'linear-gradient(to right, rgba(' + rgb2.join(',') + ',0'  + '), rgb(' + rgb2.join(',') + '))';
      if(this.colorType === '0'){
        this.colorStyle = 'background-color:' + rgba2;
        this.onBgColorChange(rgba2);
      } else {
        this.gradientList[this.selectListIndex].color = rgba2;
        this.gradientList[this.selectListIndex].colorHex = '#' + backgroundColor2;
        this.setGradient();
      }
    } else if (this.downType === 'Alpha') {
      const bbox = alphaBar.getBoundingClientRect();
      x = event.clientX - bbox.left;
      x = x > bbox.width ?  bbox.width : (x < 0 ? 0 : x);
      const xLeft = x - 7 ;
      alphaSlider.style.left = xLeft + 'px';
      this.Alpha = Math.round(x/bbox.width * 100);
      alphaSlider.style.opacity = String(this.Alpha/100);
      let rgb = this.hsvTORgb(this.hue,this.saturation,this.value);
      let backgroundColor = this.rgbToHex(rgb[0],rgb[1],rgb[2]);
      let rgba = this.generateRGBA(rgb[0],rgb[1],rgb[2],this.Alpha/100);
      if(this.colorType === '0'){
        this.colorStyle = 'background-color:' + rgba;
        this.onBgColorChange(rgba);

      } else {
        this.gradientList[this.selectListIndex].color = rgba;
        this.gradientList[this.selectListIndex].colorHex = '#' + backgroundColor;
        this.setGradient();
      }
    } else if(this.downType === 'gradient-point') {
      const gradient = document.getElementById('gradient-point');
      const bbox = gradient.getBoundingClientRect();
      x = event.clientX - bbox.left;
      x = x > bbox.width ?  bbox.width : (x < 0 ? 0 : x);
      const xLeft = x;
      this.gradientList[this.selectListIndex].position = parseFloat((xLeft*100/bbox.width).toFixed(2)) + '%';
      // const point = JSON.parse(JSON.stringify(this.gradientList[this.selectListIndex]));
      // point.position = xLeft*100/bbox.width + '%';
      // this.gradientList.push(point);
      //console.log('position2',this.gradientList[this.selectListIndex].position)
      this.gradientList.sort((a,b) => Number(a.position.split('%').join('')) - Number(b.position.split('%').join('')))
      //const index = this.gradientList.findIndex(item => item.position === point.position);
      //this.selectListIndex = index;
      this.setGradient();
    }
  }
  /**
   * 事件:窗口松开鼠标
   */
  async onWindowMouseUp() {
    if (this.downType !== '') {
      this.downType = '';
      // this.selectFn && this.selectFn(this.nowColor);
      // const ColorStr = `rgb(${Math.floor(this.rgbColor[0])},${Math.floor(this.rgbColor[1])},${Math.floor(this.rgbColor[2])})`
      // this.nowColor = ColorStr;
      // this.hsb = RGB2HSB(Color(ColorStr).color);
      // this.colorChange.emit(this.nowColor);
    }
    if(this.itemType && this.itemType === 'svg') {
      this.updateColor();
    }
  }

  modeClick(){
    this.gradientMode = !this.gradientMode;
    if(!this.gradientMode) {
      this.setGradient()
    }
  }

  radialClick(radial){
    this.radial = radial;
    this.setGradient();
  }

  gradientClick(i) {
    this.selectListIndex = i;
  }

   setGradient(){
    let value;
    let strArr = [];
    this.gradientList.forEach(element => {
      strArr.push(element.color + ' ' + element.position)
    });
    let str = strArr.join(',')
    if(this.gradientMode){
      value = this.gradientAngle;
      this.gradientBackground = 'linear-gradient(' + this.gradientAngle + 'deg,' + str + ')';
    } else {
      value = this.radial;
      this.gradientBackground = 'radial-gradient(' + this.circle[value] + ',' + str + ')';
    }

    this.onGradientColorsChange(this.gradientBackground)
  }

  onTextColorChange(background,resetFlag?) {
    //this.fontColor = color;
    const value = {} as any;
    value.type = this.colorType;
    value.color = '';
    value.opacity = '';
    value.gradientList = this.gradientList;
    if(this.gradientMode){
      value.gradientAngle = this.gradientAngle;
      value.gradientMode = "linear"
    } else {
      value.radial = this.radial;
      value.gradientMode = "radial"
    }
    value.style = this.gradientBackground
    const activeElements = this.editor.activeElements;
    for (const eleItem of activeElements) {
      if (!eleItem.options.isNewText) {
        eleItem.options.isNewText = true;
        eleItem.changeTextY();
      }
      delete eleItem.options.v4svg;
      delete eleItem.options.url;
      eleItem.options.selectedColor = value;
    }
    if (activeElements.length === 1 && activeElements[0].states.editMode) {
      let sel = window.getSelection();
      let range = activeElements[0].states.range;
      const $text = $(activeElements[0].options.text);
      if (range) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
      activeElements[0].states.range = sel.getRangeAt(0);
      // 如果全部选中的话
      if ($text[0].innerText.length === sel.toString().length) {
        $text.find('*[color]').attr('color', null);
        $text.find('*[style]').css('color', '');
        $text.css('background', background);
        $text.css('-webkit-background-clip', 'text');
        $text.css('-webkit-text-fill-color', 'transparent');
        activeElements[0].options.text = $text[0].outerHTML;
        activeElements[0].emit('modified', {
          type: 'fontColor',
          target: activeElements[0],
          stopPropagation: false
        });

      }
    } else if (
      (activeElements.length === 1 && !activeElements[0].states.editMode) ||
      activeElements.length > 1
    ) {
      // 单个或者多个文本,非编辑模式下,颜色修改针对全局
      activeElements.forEach(eleItem => {
        const $text = $(eleItem.options.text);
        $text.find('*[color]').attr('color', null);
        $text.find('*[style]').css('color', '');
        // 包括font标签,填充颜色
        $text.css('background', background);
        $text.css('-webkit-background-clip', 'text');
        $text.css('-webkit-text-fill-color', 'transparent');
        eleItem.options.text = $text[0].outerHTML;
        eleItem.emit('modified', {
          type: 'fontColor',
          target: eleItem,
          stopPropagation: activeElements.length > 1
        });
        if (eleItem.options.curve) {
          const curveobj = eleItem.getCurvedText(eleItem.options.text, eleItem.props.width, eleItem.options.curve.dir);
          eleItem.options.curve.html = curveobj.html;
          eleItem.props.oHeight = eleItem.props.height = curveobj.height;
          eleItem.states.html = eleItem.toHTML();
        }
      });
      if (activeElements.length > 1) {
        activeElements[0].parent.emit('element:modified', {
          types: 'fontColor',
          targets: activeElements
        });
      }
    }
  }

  reset(){
    const value = {} as any;
    value.type = '1';
    value.color = '';
    value.opacity = '';
    value.gradientList = [
      {
          "position": "0%",
          "color": "rgba(255, 0, 0, 1)",
          "opacity": "1",
          "colorHex": "#ff0000"
      },
      {
          "position": "100%",
          "color": "rgba(0, 0, 255, 1)",
          "opacity": "1",
          "colorHex": "#0000FF"
      }
    ];
    value.gradientMode = "linear"
    value.gradientAngle = 90;
    value.style = 'linear-gradient(90deg,rgba(255, 0, 0, 1) 0%,rgba(0, 0, 255, 1) 100%)';
    this.guideItem.selectedColor = value;
    this.init();
  }

  onFillColorChange() {
    const activeElements = this.editor.activeElements;
    let id = activeElements[0].id;
    let gradient;
    if(this.gradientMode){
      gradient = $('<linearGradient>').attr('id', id);
      let angle = this.gradientAngle > 360 ? this.gradientAngle - 360 : this.gradientAngle;
      switch (angle) {
        case 135:
          gradient.attr('x1', '0%').attr('y1', '0%').attr('x2', '100%').attr('y2', '100%');;
            break;
        case 90:
          gradient.attr('x1', '0%').attr('y1', '50%').attr('x2', '100%').attr('y2', '50%');;
            break;
        case 45:
          gradient.attr('x1', '0%').attr('y1', '100%').attr('x2', '100%').attr('y2', '0%');;
            break;
        case 360:
          gradient.attr('x1', '50%').attr('y1', '100%').attr('x2', '50%').attr('y2', '0%');;
            break;
        case 315:
          gradient.attr('x1', '100%').attr('y1', '100%').attr('x2', '0%').attr('y2', '0%');;
            break;
        case 270:
          gradient.attr('x1', '100%').attr('y1', '50%').attr('x2', '0%').attr('y2', '50%');
            break;
        case 225:
          gradient.attr('x1', '100%').attr('y1', '0%').attr('x2', '0%').attr('y2', '100%');
            break;
        case 180:
          gradient.attr('x1', '50%').attr('y1', '0%').attr('x2', '50%').attr('y2', '100%');
            break;
        default:
          gradient.attr('x1', '50%').attr('y1', '0%').attr('x2', '50%').attr('y2', '100%');
            break;
    }

    } else {
      gradient = $('<radialGradient>').attr('id', id);
      let radial = this.radial;
      switch (radial) {
        case 'tl':
          gradient.attr('cx', '50%').attr('cy', '50%').attr('r', '100%').attr('fx', '0%').attr('fy', '0%');
            break;
        case 'tm':
          gradient.attr('cx', '50%').attr('cy', '50%').attr('r', '100%').attr('fx', '50%').attr('fy', '0%');
            break;
        case 'tr':
          gradient.attr('cx', '50%').attr('cy', '50%').attr('r', '100%').attr('fx', '100%').attr('fy', '0%');
            break;
        case 'l':
          gradient.attr('cx', '50%').attr('cy', '50%').attr('r', '100%').attr('fx', '0%').attr('fy', '50%');
            break;
        case 'm':
          gradient.attr('cx', '50%').attr('cy', '50%').attr('r', '50%').attr('fx', '50%').attr('fy', '50%');
            break;
        case 'r':
          gradient.attr('cx', '50%').attr('cy', '50%').attr('r', '100%').attr('fx', '100%').attr('fy', '50%');
            break;
        case 'bl':
          gradient.attr('cx', '50%').attr('cy', '50%').attr('r', '100%').attr('fx', '0%').attr('fy', '100%');
            break;
        case 'bm':
          gradient.attr('cx', '50%').attr('cy', '50%').attr('r', '100%').attr('fx', '50%').attr('fy', '100%');
            break;
        case 'br':
          gradient.attr('cx', '50%').attr('cy', '50%').attr('r', '100%').attr('fx', '100%').attr('fy', '100%');
            break;
        default:
          gradient.attr('cx', '50%').attr('cy', '50%').attr('r', '100%').attr('fx', '50%').attr('fy', '50%');
            break;
    }
    }
      // linearGradient.append($('<stop>')
      // .attr('offset', '0%')
      // .attr('stop-color', 'rgba(255, 0, 0, 1)'));
      // linearGradient.append($('<stop>')
      // .attr('offset', '100%')
      // .attr('stop-color', '#ffffff'));
    this.gradientList.forEach(list => {
      let alpha = this.getAlphaFromRGBA(list.color)
      gradient.append($('<stop>')
      .attr('offset', list.position)
      .attr('stop-color', list.colorHex)
      .attr('stop-opacity', alpha));
    });
    const $svg = $(activeElements[0].states.svg);
    $svg.find('linearGradient').remove();
    $svg.find('radialGradient').remove();
    $svg.append(gradient);
    $svg.find('path,rect,circle,polygon,ellipse,polyline').each(function() {
      const $this = $(this);
      // $this.attr('fill', 'url(#myLinearGradient)')
      $this.attr('fill', 'url(#' + id + ')')
    })
    activeElements[0].states.svg = $svg[0].outerHTML;
    activeElements[0].options.svg = $svg[0].outerHTML;
    //activeElements[0].states.oColors = getSvgColors($svg[0]);
    activeElements[0].emit('modified', {
      type: 'gradientColor',
      target: activeElements[0],
      stopPropagation: false
    });
  }


  /**
   * 事件:吸色工具按钮被点击
   */
  async onOpenColorDropperClick() {
    const eyeDropper = new (window as any).EyeDropper()
    if(!eyeDropper) {
      this.modal.info('该浏览器不兼容此功能!');
    }
    const abortController = new AbortController();
    try {
      const result = await eyeDropper.open({
        signal: abortController.signal,
      });
      //console.log(result);
      try {
        this.HEX = result.sRGBHex.split('#').join('');
        this.onHexBlur();
      } catch (error) {
        this.modal.info('获取颜色失败');
      }
    } catch (e) {
      console.log(e);
    }

    //this.openColorDropper.emit('openColorDropper');
    //this.parentModal.emit('openColorDropper');

  }

  hsvTORgb(hue,saturation,value){
    let h = hue;
    let s = saturation;
    let v = value;

    s = s / 100;
    v = v / 100;
    let r = 0, g = 0, b = 0;
    let i = parseInt(String((h / 60) % 6));
    let f = h / 60 - i;
    let p = v * (1 - s);
    let q = v * (1 - f * s);
    let t = v * (1 - (1 - f) * s);
    switch (i) {
        case 0:
            r = v; g = t; b = p;
            break;
        case 1:
            r = q; g = v; b = p;
            break;
        case 2:
            r = p; g = v; b = t;
            break;
        case 3:
            r = p; g = q; b = v;
            break;
        case 4:
            r = t; g = p; b = v;
            break;
        case 5:
            r = v; g = p; b = q;
            break;
        default:
            break;
    }
    r = (r * 255.0)
    g = (g * 255.0)
    b = (b * 255.0)
    return [Math.round(r), Math.round(g), Math.round(b)];

  }



  rgbToHex(r, g, b) {
    return ((r << 16) + (g << 8) + b).toString(16).padStart(6, '0');
  }

  hexToRgb(hex) {
    // 移除十六进制颜色代码中的'#'
    let sanitizedHex = hex.replace("#", "");

    // 解析红、绿、蓝色值
    let r = parseInt(sanitizedHex.substring(0, 2), 16);
    let g = parseInt(sanitizedHex.substring(2, 4), 16);
    let b = parseInt(sanitizedHex.substring(4, 6), 16);

    return `rgb(${r}, ${g}, ${b})`;
  }

  hexToHsv(hex) {
    // 将十六进制颜色转换为RGB
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    // 转换RGB到HSV
    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let delta = max - min;
    let h, s, v = max / 255;

    if (max === 0) {
      s = 0;
    } else {
      s = delta / max;
    }

    if (r === max) {
      h = (g - b) / delta;
    } else if (g === max) {
      h = 2 + (b - r) / delta;
    } else {
      h = 4 + (r - g) / delta;
    }

    h = Math.min(h * 60, 360);
    if (h < 0) {
      h += 360;
    }

    // 返回HSV对象
    return {
      h: Math.round(h),
      s: Math.round(s * 100),
      v: Math.round(v * 100)
    };
  }
  findClosestValue(target, array) {
    let minDiff = Infinity;
    let closestValue;

    for (let i = 0; i < array.length; i++) {
      const diff = Math.abs(array[i] - target);
      if (diff < minDiff) {
        minDiff = diff;
        closestValue = array[i];
      }
    }

    return closestValue;
  }

  generateRGBA(r, g, b, a) {
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }


  getAlphaFromRGBA(rgbaColor) {
    const match = rgbaColor.match(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/);
    return match ? match[4] : '1';
  }
  updateColor(){
    const activeElements = this.editor.activeElements;
    const $svg = $(activeElements[0].states.svg);
    activeElements[0].states.oColors = getSvgColors($svg[0]);
  }

  ngOnDestroy(): void {

  }
}
