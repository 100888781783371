import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from '@libs/core/src/lib/services/post-order/admin.service';
import { map } from 'rxjs/operators';
import { DataProvider } from '@libs/core/src/lib/util/data-provider';
import { Env } from '@libs/core/src/lib/util/environment';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AuthService } from '@libs/core/src/lib/services/auth.service';

@Component({
  selector: 'app-export-excel',
  templateUrl: './export-excel.component.html',
  styleUrls: ['./export-excel.component.scss']
})
export class ExportExcelComponent implements OnInit {
  @Input() type;
  storeList = [];
  dataProvider = new DataProvider<any>();
  shop_id = '';
  dateRange = [];
  startAt = 0;
  endAt = 0;
  service_id = '';
  serviceList = [];
  constructor(private adminService: AdminService, public env: Env, public coop: CoopService, private modal: NzModalRef, private auth: AuthService) { }

  onChange(result) {
    let startTime = `${this.formatTime(result[0])} 00:00:00`;
    this.startAt = Math.floor((new Date(startTime).getTime()) / 1000);
    let endTime = `${this.formatTime(result[1])} 23:59:59`;
    this.endAt = Math.floor((new Date(endTime).getTime()) / 1000);
  }

  formatNumber(n) {
    const str = n.toString()
    return str[1] ? str : `0${str}`
  }

  formatTime(date) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const t1 = [year, month, day].map(this.formatNumber).join('/');
    return `${t1}`
  }

  downLoad() {
    let url;
    if (this.type === 'store') {
      url = `${location.origin}/api/download-shop?shop_id=${this.shop_id}&start_at=${this.startAt}&end_at=${this.endAt}`;
    } else if (this.type === 'service') {
      url = `${location.origin}/api/download-service?service_id=${this.service_id}&start_at=${this.startAt}&end_at=${this.endAt}`;
    } else {
      url = `${location.origin}/api/download-service?service_id=${this.auth.user.taskMember.id}&start_at=${this.startAt}&end_at=${this.endAt}`;
    }
    window.open(url);
    this.close();
  }

  close() {
    this.modal.close();
  }

  ngOnInit() {
    if (this.type === 'store') {
      this.adminService.storeList().pipe(
        map(data => this.dataProvider.prepare(data))
      ).subscribe(res => {
        this.storeList = res;
      })
    } else if (this.type === 'service') {
      this.adminService.serviceList().pipe(
        map(data => this.dataProvider.prepare(data))
      ).subscribe(res => {
        this.serviceList = res;
      })
    }
  }

}
