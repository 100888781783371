import { ToolBarCombinationComponent } from '@libs/editor/src/lib/components/editor-ui/t-tool-bar/tools/tool-bar-combination/tool-bar-combination.component';
import { Element } from '../base/element';
import { TemplateLoader } from '@libs/editor/src/lib/utils/misc/template-loader';
import { deepClone } from '@libs/editor/src/lib/utils/misc/common';
import $ from 'jquery'

export class Combination extends Element {
  public type = 'combination';
  onCreated(): void {
    this.config.markAble = false;
    this.config.toolbar = ToolBarCombinationComponent;
    this.config.corner = {
      tl: false,
      tr: false,
      ml: false,
      mr: true,
      bl: false,
      br: true,
      mt: false,
      mb: false,
      rotate: false,
      quick: false
    };
    // 初始化属性
    if (!this.options.align) {
      this.options.align = 'tl'
    }
    if (!this.options.spaceVertical) {
      this.options.spaceVertical = 0
    }
    if (!this.options.spaceHorizontal) {
      this.options.spaceHorizontal = 0
    }
    if (!this.options.scale) {
      this.options.scale = 1;
    }
    // 缩放处理
    let downWidth = 0;
    let downScale = 1;
    this.on('cornermousedown', e => {
      downScale = this.options.scale;
      downWidth = this.props.width;
    });
    this.on('scaling', e => {
      if (
        e.corner === 'tl' ||
        e.corner === 'tr' ||
        e.corner === 'bl' ||
        e.corner === 'br'
      ) {
        this.options.scale = (this.props.width / downWidth) * downScale;
        this.toHTML()
      } else {
        this.updateHeight();
      }
    });

    this.on('deselected', () => {
      this.states.activeIndex = undefined;
    });
    this.checkName();
    this.options.items = Combination.loadItems(this.options.items, this.parent);
    this.states.html = this.toHTML()
  }

  checkName() {
    const template = this.parent.parent;
    const names = []
    for (let page of template.pages) {
      let name = page.elements
        .filter(el => el.type === 'combination' && el.options.name)
        .map(el => el.options.name);
      names.push(...name)
    }
    if (!this.options.name || names.indexOf(this.options.name) !== -1) {
      const numbers = names.map(n => parseInt(n.match(/\d+/)[0])).sort((a, b) => a - b);
      let index = numbers.findIndex((v, i) => v !== i+1);
      index = index === -1 ? numbers.length + 1 : index + 1;
      this.options.name = numbers.length ? '组合' + index : '组合1'
    }
  }

  static loadItems(items, parent) {
    return items.map(item => ({
      data: item.data,
      elements: Combination.loadElements(item.elements, parent)
    }))
  }

  static loadElements(elements, parent) {
    return elements.map(el => {
      const element = TemplateLoader.loadElement(el);
      element.parent = parent;
      // 传入参数
      element.set(element.params);
      // 调用创建时调用的方法
      element.onCreated();
      return element;
    })
  }

  async addItems(data) {
    const elements = Combination.loadElements(deepClone(this.options.baseElements), this.parent);
    for (const element of elements) {
      if (element.props.sign in data) {
        element.changeSignCombination(data[element.props.sign])
      }
    }
    this.options.items.push({
      data: data,
      elements: elements
    });
    this.updateHeight();
  }

  removeItems(index = this.states.activeIndex) {
    if (index >= this.options.items.length - 1) {
      this.states.activeIndex = index - 1;
    }
    this.options.items.splice(index, 1);
    this.states.htmlData.splice(index, 1);
    this.updateHeight();
    this.states.html = this.toHTML();
  }

  /**
   * 更新某个组的打标数据
   * @param data 打标数据
   * @param index 组的索引
   */
  async changeItems(data, index) {
      const item = this.options.items[index];
      if (!item) return false;
      for (const key in data) {
        if (key in item.data || key === '条形码') {
          item.data[key] = data[key];
        }
      }
      for (const element of item.elements) {
        if (['text', 'textv2', 'container', 'image'].indexOf(element.type) !== -1 && element.props.sign in data) {
          element.changeSignCombination(data[element.props.sign])
        }
      }
  }

  async getHtmlData(elements) {
    const htmls = await Promise.all(elements.map(async (element, i) => {
      let html = '';
      if (['svg', 'shape'].indexOf(element.type) !== -1 && !element.states.svg) {
        html = await element.loadSvg();
      } else {
        if (element.type === 'textv2') {
          await window['editor'].waitLoadFont(element.options.fontFamily);
        }
        html = element.toHTML({scale: this.options.scale})
      }
      return `<div style="${this.getInnerElementStyle(element, elements.indexOf(element))}">${html}</div>`
    }))

    return htmls.join('');
  }

  /**
   * 单元数据的元素样式
   * @param element
   * @param index
   */
  getInnerElementStyle(element, index) {
    const { x, y, width, height, rotation, opacity} = element.props;
    const scale = this.parent.parent.scale * this.options.scale;
    const styleObj = {
      position: 'absolute',
      left: x*scale + 'px',
      top: y*scale + 'px',
      width: width*scale + 'px',
      height: height*scale + 'px',
      transform: `rotate(${rotation}deg)`,
      opacity: opacity,
      filter: '',
      'pointer-events': 'none',
      'z-index': index * 10
    };
    let style = '';
    for (const key in styleObj) {
      style += `${key}: ${styleObj[key]};`
    }
    return style;
  }

  /**
   * 更新 元素高度
   * 生成 每个商品定位
   */
  updateHeight() {
    const { oWidth, oHeight, width } = this.props;
    const { scale, align, spaceVertical, spaceHorizontal, items } = this.options;

    let unitWidth = oWidth + spaceHorizontal;
    let unitHeight = oHeight + spaceVertical;
    // 每行最多
    let lineLength = Math.floor(width / (unitWidth * scale)) + (width % (unitWidth * scale) > oWidth * scale ? 1 : 0);
    if (lineLength === 0) lineLength = 1;
    // 多少行
    let lines = Math.ceil(items.length / lineLength);
    // 最后一行几个
    let lastLineLength = items.length % lineLength || lineLength;
    // 定位数组
    let pos: {x: number, y: number}[][] =
    new Array(lines).fill(0).map((v, l) =>
      new Array(lineLength)
        .fill(0)
        .map((v, i) => ({x: i * unitWidth, y: l * unitHeight}))
    );

    if (lineLength !== lastLineLength) {
      switch (align) {
        case 'tl':
          pos[lines - 1].splice(lastLineLength, lineLength - lastLineLength)
          break;
        case 'tr':
          pos[lines - 1].splice(0, lineLength - lastLineLength)
          break;
        case 'bl':
          pos[0].splice(lastLineLength, lineLength - lastLineLength)
          break;
        case 'br':
          pos[0].splice(0, lineLength - lastLineLength)
          break
      }
    }

    this.props.height = unitHeight * scale * lines - spaceVertical * scale;
    this.options.posMatrix = pos.reduce((prev, cur) => prev.concat(cur), []);
  }

  toHTML(index?: number) {
    if (index >= 0) {
      this.getHtmlData(this.options.items[index].elements).then(res => {
        this.states.htmlData[index] = res
      })
    } else {
      Promise.all(this.options.items.map(async item => await this.getHtmlData(item.elements))).then(res => {
        this.states.htmlData = res;
        this.updateHeight();
      })
    }
    /*const { oWidth, oHeight} = this.props;
    const scale = this.options.scale;
    return this.states.htmlData.map(html => `<div style="position: relative;width: ${oWidth*scale}px; height: ${oHeight*scale}px;float: left;">${html}</div>`).join("");*/
    return ''
  }

  /**
   * 重写 options.elements 需要JSON化
   * @param deepClone
   */
  toObject(deepClone: boolean = false): any {
    const obj = {
      id: this.id,
      type: this.type,
      props: this.props,
      options: {
        ...this.options,
        items: this.options.items.map(item => ({
          data: item.data,
          elements: item.elements.map(el => el.toObject(deepClone))
        }))
      }
    };
    if (deepClone) {
      return JSON.parse(JSON.stringify(obj));
    } else {
      return obj;
    }
  }


  async toSvg() {
    const {oWidth, oHeight, x, y, width, height} = this.props;
    const {items, scale, posMatrix} = this.options;
    let combinationItem = '';
    for (let i=0;i<items.length;i++) {
      let combinationEleItem = '';
      const item = items[i]
      for (let j=0; j<item.elements.length; j++) {
        const element = item.elements[j]
        const $svg = $(await element.toSvg())
        $svg.attr('transform-origin', (element.props.x + element.props.width / 2) + " " + (element.props.y + element.props.height / 2))
        $svg.css({
          'opacity': element.props.opacity,
          'transform': `rotate(${element.props.rotation}deg) scale(${element.props.flipX ? '-1' : '1'},${element.props.flipY ? '-1' : '1'})`
        })
        if (element.props.filter.shadow && element.props.filter.shadow.use) {
          const shadow = element.props.filter.shadow;
          // 将弧度转换成角度
          const angle = (Math.PI / 180) * shadow.rotation;
          const distance = shadow.distance * 0.084666;
          const strength = shadow.strength * 0.084666;
          // 转换坐标与角度为坐标
          const x = distance * Math.cos(angle),
            y = distance * Math.sin(angle);
          // 将color和opacity转换为rgba
          const color = shadow.color.replace('rgb', 'rgba').replace(')', shadow.opacity + ')');
          $svg.attr('filter', `drop-shadow(${color} ${x} ${y} ${strength})`)
        }

        combinationEleItem += $svg[0].outerHTML
      }

      combinationItem += `<svg x="${posMatrix[i].x}" y="${posMatrix[i].y}" width="${oWidth}" height="${oHeight}">${combinationEleItem}</svg>`
    }

    return `<svg x="${x}" y="${y}" width="${width}" height="${height}"><g transform="scale(${scale})">${combinationItem}</g></svg>`
  }
}
