import { Axis, Core, Info, _calcY } from './chartUtil';
export default class Groupbar {
  public base;
  constructor(chart: any) {
    this.base = this._initData(chart);
  }
  private _initData(chart) {
    let bottom = 100;
    //数据信息
    let base = { ...chart.options, ...chart.props };

    base.padding = [80, 50];
    //坐标轴颜色
    base.color = '#afafaf';
    //绘图区域尺寸
    base.chart_width = base.width - base.padding[0] * 2;
    base.chart_height = base.height - base.padding[1] * 2 - bottom;
    base.stepY = 6;
    base.stepX = base.mainK.length;

    let _val = [];
    base.data.forEach(val => (_val = _val.concat(val.values)));
    let _max = Math.max.apply(null, _val);
    //maxY = _calc(_max+minY)
    base.maxY = _calcY(_max);
    base.minY = 0;

    let gapY = [];
    for (let i = 0; i <= base.stepY; i++) {
      let c = (i * base.maxY) / 6;
      if (c.toString().indexOf('.') > 0) {
        gapY.push(c.toFixed(1));
      } else {
        gapY.push(c.toFixed(0));
      }
    }
    base.gapY = gapY;
    base.gapX = base.chart_width / (base.stepX - 1);
    base.gapBarX = base.chart_width / base.stepX;

    return base;
  }
  public getHtml(): string {
    let _html = '';
    let base = this.base;
    _html += `<svg width="${base.width}" height="${base.height}" viewbox="0 0 ${
      base.width
    } ${
      base.height
    }" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg" style="user-select:none; background:#ececec">
			<defs><filter id="ff" width="1000%" height="1000%"><feGaussianBlur in="SourceAlpha" stdDeviation="4"></feGaussianBlur> <feOffset dx="1" dy="1" result="offsetblur"></feOffset><feComponentTransfer><feFuncA type="linear" slope="0.3"></feFuncA></feComponentTransfer><feMerge><feMergeNode></feMergeNode><feMergeNode in="SourceGraphic"></feMergeNode></feMerge></filter></defs>
				<g transform="translate(${base.padding[0]},${base.padding[1]})">
      `;
    _html += `<g class="xPix" transform="translate(0,${base.chart_height})">`;
    _html += Axis.barXAxis(base);
    _html += '</g>';
    _html += `<g class="yPix">`;
    _html += Axis.yAxis(base);
    _html += '</g>';
    _html += Core.drawGroupBar(base);
    _html += Info.drawAixs(base);

    _html += '</g></svg>';
    return _html;
  }
}
