import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { OrderService } from '@libs/core/src/lib/services/post-order/order.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { map, debounceTime, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataProvider } from '@libs/core/src/lib/util/data-provider';
import { DesignerService } from '@libs/core/src/lib/services/post-order/designer.service';

@Component({
	selector: 'task-handle',
	templateUrl: 'task-handle.component.html',
	styleUrls: ['./task-handle.component.scss']
})

export class TaskHandleComponent implements OnInit {
	@Input() type;
	@Input() kind;
	@Input() id;
  @Input() orderPrice;
	@Input() status;
	@Input() content;
	showDis = false;
	reward = '';
	designerId;
	price = '';
	closeReward = '';
	chooseMethod = 'complete';
	timer;
	remainText = '00天00小时00分00秒'
	company = '';
	close_reason = '';
	close_reason2 = '';
	isLoading = false;
	searchChange$ = new BehaviorSubject('');
	optionList = [];
	dataProvider = new DataProvider<any>();
	description; // 放弃驳回的原因
	btnLoading = false;
	constructor(
		private message: MessageService,
		private modal: NzModalRef,
		private order: OrderService,
		private router: Router,
		public authService: AuthService,
		private designerService: DesignerService,
		public coop: CoopService) {}
	receipt() {
		this.btnLoading = true;
		// this.order.designerStatus({ order_id: this.id, method: this.type, reward: this.closeReward, close_reason: this.close_reason2 }).subscribe(() => {
		this.order.designerStatus({ order_id: this.id, method: this.type, reward: this.closeReward, close_reason: this.close_reason2, description: this.description}).subscribe(() => {
			this.btnLoading = false;
			if (this.type === 'give_up') {
				this.close();
				this.message.success('放弃成功！');
				this.router.navigate(['/designer/myOrder']);
			} else if (this.type === 'accept') {
				if (this.kind == 3) {
          this.close();
          setTimeout(() => {
            window.open(`/postorder/designer/taskDetail/${this.id}?from=order`);
          }, 100)
					// this.router.navigate(['/designer/taskDetail/', this.id], {queryParams: {from: 'order'}});
				} else if (this.kind == 1) {
					window.location.reload();
				}
			} else if (this.type === 'fail') {
        this.close();
        this.router.navigate(['/customer/order']);
      } else {
				this.message.success('操作成功！');
				window.location.reload();
			}
		}, (err: HttpErrorResponse) => {
			this.btnLoading = false;
      this.message.error(err);
    })
	}

	checkOrReject() {
		this.btnLoading = true;
		this.order.designerStatus({ order_id: this.id, method: this.chooseMethod, company: this.company, description: this.description}).subscribe(() => {
			this.btnLoading = false;
			this.message.success('操作成功！');
			this.close();
			window.location.reload();
    }, (err: HttpErrorResponse) => {
			this.btnLoading = false;
      this.message.error(err);
    })
  }

	resetOrder() {
		this.btnLoading = true;
		this.order.resetOrder({ order_id: this.id, price: this.price, reward: this.reward, designer_id: this.designerId, close_reason: this.close_reason}).subscribe(() => {
			this.btnLoading = false;
			this.close();
			this.router.navigate(['/customer/order']);
		}, (err: HttpErrorResponse) => {
			this.btnLoading = false;
      this.message.error(err);
    })
	}
	close() {
    this.modal.close();
	}
	cutDown(lefttime) {
    this.timer = setInterval(() => {
      lefttime = lefttime - 1;
      var d = Math.floor(lefttime / (24*60*60));
      var h = Math.floor(lefttime / (60 * 60) % 24);
      var m = Math.floor(lefttime / 60 % 60);
      var s = Math.floor(lefttime % 60);
      if (d > 0) {
        this.remainText = `${this.addZero(d)}天${this.addZero(h)}小时${this.addZero(m)}分${this.addZero(s)}秒`;
      } else {
       	this.remainText = `${this.addZero(h)}小时${this.addZero(m)}分${this.addZero(s)}秒`;
      }
      if (lefttime < 0) {
        window.clearInterval(this.timer);
      }
    },1000);
	}
	addZero(i) {
    return i < 10 ? "0" + i: i + "";
  }

  know() {
		this.close();
  }

	onSearch(value: string) {
		this.isLoading = true;
		this.searchChange$.next(value);
	}

	ngOnInit() {
		console.log(this.content, 'content');
		if (this.type === 'accept' && this.kind == 4) {
			let remainTime = ((new Date(Number(this.authService.user.taskMember.restrictAt))).getTime() - (new Date()).getTime()/1000);
			this.cutDown(remainTime);
		}
		if (this.type === 'reset') {
			const getRandomNameList = (name: string) => this.designerService.designerList({ status: 20, keywords: name }).pipe(
				map(data => this.dataProvider.prepare(data))
			)
			const optionList$: Observable<string[]> = this.searchChange$.asObservable().pipe(debounceTime(500)).pipe(switchMap(getRandomNameList));
			optionList$.subscribe(data => {
				this.optionList = data;
				this.isLoading = false;
			});
		}

		if (this.type === 'change' && this.kind !== 1 && this.content['changeOrder'].content) {
			this.content['changeOrder'].content = this.content['changeOrder'].content.split('\n').join('<br/>');
		}
	}
	ngOnDestroy(): void {
		window.clearInterval(this.timer);
	}
}
