import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '@libs/core/src/lib/services/post-order/admin.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { map, catchError } from 'rxjs/operators';
import { DataProvider } from '@libs/core/src/lib/util/data-provider';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ServiceCommon } from '@libs/core/src/lib/models/post-admin.interface';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { CoopService } from '@libs/core/src/lib/services/coop.service';

@Component({
	selector: 'add-service',
	templateUrl: 'add-service.component.html',
	styleUrls: ['./add-service.component.scss']
})

export class AddServiceComponent implements OnInit {
	@Input() id: any;
	validateForm: FormGroup;
	titleText:string;
	serviceInfo = {
		shop_id: null,
		user_id: null,
		nickname: '',
		phone: '',
		qq: '',
		wang_wang: '',
		wechat: ''
	}
	storeList;
	memberList;
	dataProvider = new DataProvider<any>();
	optionList: string[] = [];
	isLoading = false;
	btnLoading = false;
	// tslint:disable:no-any
  getRandomNameList: Observable<string[]> = this.http
    .get(`/task-member`)
    .pipe(map((res: any) => res))
    .pipe(
      map((list: any) => {
        return list.map((item: any) => `${item.username}`);
      })
    );

  loadMore(): void {
    this.isLoading = true;
    this.getRandomNameList.subscribe(data => {
      this.isLoading = false;
      this.optionList = [...this.optionList, ...data];
    });
  }
	constructor(
		private fb: FormBuilder,
		private adminService: AdminService,
		private message: MessageService,
    private http: HttpClient,
    public coop: CoopService,
		private modal: NzModalRef){}

	submitForm(value:any) {
		for (const key in this.validateForm.controls) {
			this.validateForm.controls[key].markAsDirty();
			this.validateForm.controls[key].updateValueAndValidity();
		}
		if (this.validateForm.invalid) {
			return
		}
		value.shop_id = value.shop_id.toString();
		if (this.id !== 'add') {
			value.id = this.id;
			this.btnLoading = true;
			this.adminService.editService(value).subscribe(res => {
				this.btnLoading = false;
				this.message.success('修改成功');
				// window.location.reload();
				this.modal.close(res);
			}, (err: HttpErrorResponse) => {
				this.btnLoading = false;
				this.message.error(err);
			})
		} else {
			this.btnLoading = true;
			this.adminService.addService(value).pipe(
				catchError(err => {
					return of(err.error)
				})
			).subscribe(res => {
				this.btnLoading = false;
				if (res.message) {
					this.message.error(res.message);
				} else {
					this.message.success('添加成功');
					// window.location.reload();
					this.modal.close(res);
				}
			}, (err: HttpErrorResponse) => {
				this.btnLoading = false;
				this.message.error(err);
			})
		}
	}


	formInit() {
		this.validateForm = this.fb.group({
			shop_id: [this.serviceInfo.shop_id, [Validators.required]],
			user_id: [this.serviceInfo.user_id, [Validators.required]],
			nickname: [this.serviceInfo.nickname, [Validators.required]],
			qq: [this.serviceInfo.qq],
			wang_wang: [this.serviceInfo.wang_wang],
			wechat: [this.serviceInfo.wechat]
    });
	}

	ngOnInit() {
		this.formInit();
		if (this.id !== 'add') {
			this.titleText = '修改';
			this.adminService.serviceDetail(this.id).subscribe(res => {
				this.serviceInfo.shop_id = res.taskShop.map(item => {return item['id']});
				this.serviceInfo.user_id = res.userId;
				this.serviceInfo.nickname = res.nickname;
				this.serviceInfo .qq = res.qq;
				this.serviceInfo.wechat = res.wechat;
				this.serviceInfo.wang_wang = res.wangWang;
				this.formInit();
			}, (err: HttpErrorResponse) => {
				this.message.error(err);
			})
		} else {
			this.titleText = '创建';
		}
		this.adminService.storeList().pipe(
			map(data => this.dataProvider.prepare(data))
		).subscribe(res => {
			this.storeList = res;
		})
		this.http.get<any>('/task-member').subscribe(data => {
			this.memberList = data;
		})
	}
}
