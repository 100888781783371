export function getFilterHtml(params) {
  let html = '';
  // 饱和度
  if (params.saturation && params.saturation !== 0) {
    html +=
      '<feColorMatrix in="SourceGraphic" type="saturate" values="' +
      (params.saturation / 100 + 1) +
      '"></feColorMatrix>';
  }
  // 亮度-0.5 - 0.5
  if (params.bright && params.bright !== 0 && params.bright < 0) {
    const value = (params.bright * 0.5) / 100;
    html +=
      '<feComponentTransfer><feFuncR type="linear" intercept="' +
      value +
      '" slope="1"/><feFuncG type="linear" intercept="' +
      value +
      '" slope="1"/><feFuncB type="linear" intercept="' +
      value +
      '" slope="1"/></feComponentTransfer>';
  }
  // 对比度0.4-2
  if (params.contrast && params.contrast !== 0) {
    const value =
      (params.contrast * (params.contrast > 1 ? 1 / 0.6 : 1) + 100) / 100;
    const value2 = -(0.5 * value) + 0.5;
    html +=
      '<feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" s' +
      'lope="' +
      value +
      '" intercept="' +
      value2 +
      '"></feFuncR><feFuncG type="linear" slope="' +
      value +
      '" intercept="' +
      value2 +
      '"></feFuncG><feFuncB type="linear" slope="' +
      value +
      '" intercept="' +
      value2 +
      '"></feFuncB></feComponentTransfer>';
  }
  // 模糊
  if (params.blur && params.blur !== 0) {
    if (params.blur > 0) {
      // 模糊
      html +=
        '<feGaussianBlur stdDeviation="' +
        params.blur +
        '" class="blur"></feGaussianBlur>';
    } else {
      // 锐化
      let e = params.blur;
      e *= -1;
      e = (e / 100) * 4;
      e = [0, -e, 0, -e, 4 * e + 1, -e, 0, -e, 0];
      html +=
        '<feConvolveMatrix order="3" kernelMatrix="' +
        e.join(' ') +
        '" class="sharpen"></feConvolveMatrix>';
    }
  }
  // 色调
  if (params.hue && params.hue !== 0) {
    var rr = function(t) {
      t = t - 200 / 7;
      if (0 > t) {
        t = 200 + t;
      }
      t = nn((360 * t) / 200, 100, 50);
      return {
        color: {
          r: t[0],
          g: t[1],
          b: t[2]
        },
        Dm: 7
      };
    };

    var nn = function(t, e, r) {
      t = t / 360;
      e = e / 100;
      r = r / 100;
      let n;
      if (0 == e) {
        r = e = t = r;
      } else {
        if (0.5 > r) {
          n = r * (1 + e);
        } else {
          n = r + e - r * e;
        }
        var s = 2 * r - n;
        r = ii(s, n, t + 1 / 3);
        e = ii(s, n, t);
        t = ii(s, n, t - 1 / 3);
      }
      return [Math.round(255 * r), Math.round(255 * e), Math.round(255 * t)];
    };

    var ii = function(t, e, r) {
      if (0 > r) {
        return (r += 1);
      } else {
        if (r > 1) {
          --r;
        }
        if (1 / 6 > r) {
          return t + 6 * (e - t) * r;
        } else {
          if (0.5 > r) {
            return e;
          } else {
            if (2 / 3 > r) {
              return t + (e - t) * (2 / 3 - r) * 6;
            } else {
              return t;
            }
          }
        }
      }
    };
    var e = rr(params.hue);
    var n = {
      r: e.color.r / 255,
      g: e.color.g / 255,
      b: e.color.b / 255
    };
    var i = (e.Dm / 100) * 2;
    html +=
      '<feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" s' +
      'lope="' +
      (1 - i) +
      '" intercept="' +
      n.r * i +
      '"></feFuncR><feFuncG type="linear" slope="' +
      (1 - i) +
      '" intercept="' +
      n.g * i +
      '"></feFuncG><feFuncB type="linear" slope="' +
      (1 - i) +
      '" intercept="' +
      n.b * i +
      '"></feFuncB></feComponentTransfer>';
  }

  // 负片冲印
  if (params.film && params.film != 0) {
    var s = function(t) {
      if (t > 0) {
        var n = [];
        var i = [];
        var r = [];

        var n_arr = [0, 0.2, 0, -0.1, 0];
        var i_arr = [0, 0.1, 0, -0.1, 0];
        var r_arr = [0.2, 0, 0.2];

        for (var z in n_arr) {
          n.push(parseFloat(((n_arr[z] * t) / 100).toFixed(2)));
        }
        for (var z in i_arr) {
          i.push(parseFloat(((i_arr[z] * t) / 100).toFixed(2)));
        }
        for (var z in r_arr) {
          r.push(parseFloat(((r_arr[z] * t) / 100).toFixed(2)));
        }

        return {
          red: [[0, 0], [0.3, 0.3 - n[1]], [0.7, 0.7 - n[3]], [1, 1]],
          green: [[0, 0], [0.25, 0.25 - i[1]], [0.75, 0.75 - i[3]], [1, 1]],
          blue: [
            [0, r[0]],
            [0 + (2 / 3) * 0.5, r[0] + (2 / 3) * (0.5 - r[0])],
            [1 + (2 / 3) * -0.5, 1 - r[2] + (2 / 3) * (0.5 - (1 - r[2]))],
            [1, 1 - r[2]]
          ]
        };
      }
      var n = [];
      var i = [];
      var r = [];

      var n_arr = [0, 0.2, 0.5, -0.05];
      var i_arr = [0, -0.15, 0, 0];
      var r_arr = [0, -0.01, 0, 0.3];

      for (var z in n_arr) {
        n.push(parseFloat(((n_arr[z] * t) / -100).toFixed(2)));
      }
      for (var z in i_arr) {
        i.push(parseFloat(((i_arr[z] * t) / -100).toFixed(2)));
      }
      for (var z in r_arr) {
        r.push(parseFloat(((r_arr[z] * t) / -100).toFixed(2)));
      }
      return {
        red: [
          [0, 0],
          [0.3, 0.3 - n[1]],
          [0.7, 0.7 - n[2]],
          [0.95, 0.95 - n[3]]
        ],
        green: [[0, 0], [0.25, 0.25 - i[1]], [0.6, 0.6], [1, 1]],
        blue: [[0, 0], [0.3, 0.3 - r[1]], [0.5, 0.5], [1, 1 - r[3]]]
      };
    };

    var a = function(t, e, r, n) {
      var i = function(t, e) {
        var r = t * t;
        var n = 1 - t;
        var i = n * n;
        return (
          e[0] * i * n + 3 * e[1] * i * t + 3 * e[2] * n * r + e[3] * r * t
        );
      };
      for (var s, a, o = 0, c = []; 1 > o; ) {
        s = i(o, [t.x, e.x, r.x, n.x]);
        a = i(o, [t.y, e.y, r.y, n.y]);
        c.push({
          x: s,
          y: a
        });
        o = o + 5e-5;
      }

      var u = [
        {
          x: t.x,
          y: t.y
        }
      ];
      var l = 0.05;
      var f = null;
      c.forEach(function(t) {
        if (f !== null && t.x > l) {
          u.push(f);
          l = l + 0.05;
        }
        f = t;
      });
      u.push({
        x: n.x,
        y: n.y
      });
      return u;
    };
    var tt = s(params.film);
    var r = {
      r: tt.red,
      g: tt.green,
      b: tt.blue
    };

    var flimFilter = '';
    ['r', 'g', 'b'].forEach(function(t) {
      var type = t;
      var ttt = r[t];
      var arr = a(
        {
          x: ttt[0][0],
          y: ttt[0][1]
        },
        {
          x: ttt[1][0],
          y: ttt[1][1]
        },
        {
          x: ttt[2][0],
          y: ttt[2][1]
        },
        {
          x: ttt[3][0],
          y: ttt[3][1]
        }
      );
      var arrb = [];
      for (var i in arr) {
        arrb.push(arr[i].y);
      }
      ttt = arrb;
      flimFilter =
        flimFilter +
        '<feFunc' +
        type.toUpperCase() +
        ' type="table" tableValues="' +
        ttt.join(' ') +
        '"></feFunc' +
        type.toUpperCase() +
        '>';
    });

    html +=
      '<feComponentTransfer color-interpolation-filters="sRGB">' +
      flimFilter +
      '</feComponentTransfer>';
  }

  return html;
}
