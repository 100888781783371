export class VipVersion {
  constructor(
    public name: string,
    public title: string,
    public price: number,
    public sale: number
  ) {}

  getYearPrice() {
    return this.price * 12 * this.sale;
  }

  getMonthPrice() {
    return this.price;
  }

  getYearMonthPrice() {
    return this.getYearPrice() / 12;
  }

}
