import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalService } from '../../../../../../core/src/lib/services/modal.service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-no-vip-modal',
  templateUrl: './no-vip-modal.component.html',
  styleUrls: ['./no-vip-modal.component.scss']
})
export class NoVipModalComponent implements OnInit {
  @Input() type;
  @Input() template;
  fileType = 'jpg';
  loading = false;
  downloadSuccess = false;
  constructor(
    @Inject(forwardRef(() => ModalService))
    public modalService,
    private modal: NzModalRef,
    public coop: CoopService,
    public auth: AuthService,
    private message: MessageService,
    private http: HttpClient
  ) { }
  ngOnInit() {
    console.log(this.auth.user, 'user');
  }
  showBuyVipEdit(){
    this.modal.destroy();
    //this.modalService.closeAll();
    this.modalService.showBuyVipS()
  }

  apply() {
    this.modal.destroy();
    this.modalService.showKejieApply(this.template);
  }
  async download(target = 'pc') {
    let params = {
      template_id: this.template.id,
      format: this.fileType
    };
    if (this.coop.coopInfo.id === '12864') {
      params['type'] = this.type;
    }
    this.loading = true;
    const data = await this.http.post('/coop-download', params).toPromise();
    if (data) {
      this.loading = false;
      this.downloadSuccess = true;
      if (!data['filePath']) {
        this.auth.updateUser({
          ...this.auth.user,
          coopMemberInfo: { num: this.auth.user.coopMemberInfo.num - 1 < 0 ? 0 : this.auth.user.coopMemberInfo.num - 1, check: this.auth.user.coopMemberInfo.check }
        })
      }
    } else {
      this.message.error('网络错误，请重试')
    }
  }
  goMyDownload() {
    this.modal.destroy();
    window.open('/member/downloadList')
  }
}
