import { Element } from '../base/element';
import dateTOLunarCalendar from '../../utils/calender/calendar.js';
import { gregorianHoliday,lunarHoliday } from '@libs/editor/src/lib/utils/calender/holidayData';
import { ToolBarCalendarComponent } from '@libs/editor/src/lib/components/editor-ui/t-tool-bar/tools/tool-bar-calendar/tool-bar-calendar.component';
import * as $ from 'jquery'

/**
 * 日历元素
 */
export class Calendar extends Element {
  public type = 'calendar';
  onCreated() {
    const defaultCorner = {
      tl: false,
      tr: false,
      ml: false,
      mr: true,
      bl: false,
      br: true,
      mt: true,
      mb: true,
      rotate: false
    };
    this.config.corner = defaultCorner;
    this.config.toolbar = ToolBarCalendarComponent;
    this.config.markAble = false;
    this.config.resizeFromInput = false;
    this.config.minWidth = this.props.width*0.5;
    this.config.minHeight = this.props.height*0.5;
    this.on('cornermousedown', e => {

    });
    // 日历缩放时位置计算
    this.on('scaling', e => {
      if (e.corner === 'mr' || e.corner === 'mt'||e.corner === 'mb'){
        this.config.minWidth = this.props.oWidth*this.options.scale/this.parent.parent.scale*0.5;
        this.config.minHeight = this.props.oHeight*this.options.scale/this.parent.parent.scale*0.7;
        this.options.scaleWidth = (this.props.width*this.parent.parent.scale)/this.options.scale;
      }
      // 如果是等比缩放角点,计算缩放比例
      if (
        e.corner === 'tl' ||
        e.corner === 'tr' ||
        e.corner === 'bl' ||
        e.corner === 'br'
      ) {
        this.config.minWidth = 0;
        this.config.minHeight = 0;
        this.options.scale = this.props.width*this.parent.parent.scale/this.options.scaleWidth;
      }
      this.states.html = this.toHTML();
    });
    this.states.html = this.toHTML();
  }

  /**
   * 重写:获取当前元素的html结果
   * @returns {string} 根据元素信息生成的html
   */
  toHTML(){
    let {
      headerHtml, dayItemHtml, weekItemHtml, splitHtml, rightBorder, column,
      fonts, borderColors, bgColors
    } = this.options.templateData;
    let year = this.options.year;
    let month = this.options.month;
    let color = this.options.color;

    //旧版替换颜色
    if (color && color.length) {
      for (let i = 0;i<color.length;i++){
        let reg = new RegExp("{{color"+i+"}}","g");
        headerHtml = headerHtml.replace(reg,color[i]);
        dayItemHtml = dayItemHtml.replace(reg,color[i]);
        weekItemHtml = weekItemHtml.replace(reg,color[i]);
        splitHtml && (splitHtml = splitHtml.replace(reg,color[i]))
      }
    }
    // 新版替换颜色字体
    if (fonts && fonts.length) {
      // 提取字体id 用于后端获取字体
      this.options.fontsFamily = fonts.map(font => 'font_' + font.id).join(",");

      for (let i = 0;i<fonts.length;i++){
        let idReg = new RegExp("{{fontId"+i+"}}","g");

        headerHtml = headerHtml.replace(idReg, fonts[i].id);
        dayItemHtml = dayItemHtml.replace(idReg, fonts[i].id);
        weekItemHtml = weekItemHtml.replace(idReg, fonts[i].id);
        splitHtml && (splitHtml = splitHtml.replace(idReg, fonts[i].id))

        for (let x = 0; x < fonts[i].colors.length; x++) {
          let colorReg = new RegExp("{{font"+i+"color"+x+"}}","g");

          headerHtml = headerHtml.replace(colorReg, fonts[i].colors[x]);
          dayItemHtml = dayItemHtml.replace(colorReg, fonts[i].colors[x]);
          weekItemHtml = weekItemHtml.replace(colorReg, fonts[i].colors[x]);
          splitHtml && (splitHtml = splitHtml.replace(colorReg, fonts[i].colors[x]))
        }
      }
    }

    if (borderColors && borderColors.length) {
      for (let i = 0;i<borderColors.length;i++){
        let reg = new RegExp("{{borderColor"+i+"}}","g");

        headerHtml = headerHtml.replace(reg, borderColors[i]);
        dayItemHtml = dayItemHtml.replace(reg, borderColors[i]);
        weekItemHtml = weekItemHtml.replace(reg, borderColors[i]);
        splitHtml && (splitHtml = splitHtml.replace(reg, borderColors[i]))
      }
    }

    if (bgColors && bgColors.length) {
      for (let i = 0;i<bgColors.length;i++){
        let reg = new RegExp("{{bgColor"+i+"}}","g");

        headerHtml = headerHtml.replace(reg, bgColors[i]);
        dayItemHtml = dayItemHtml.replace(reg, bgColors[i]);
        weekItemHtml = weekItemHtml.replace(reg, bgColors[i]);
        splitHtml && (splitHtml = splitHtml.replace(reg, bgColors[i]))
      }
    }

    headerHtml = $(headerHtml)[0];
    // let headerHtml = $(htmlData.headerHtml.replace(reg,color))[0];
    // let dayItemHtml = htmlData.dayItemHtml.replace(reg,color);
    // let weekItemHtml = htmlData.weekItemHtml.replace(reg,color);
    //当月的第一天为周几
    let week = new Date(year+'/'+month+'/'+'1').getDay();
    //当月多少天数
    let dayNumber = this.getMonthDayNumber(year,month);
    //当月的周数
    let weekNumber = this.getWeekNumber(year,month,dayNumber,week);
    //节日样式(农历)
    let lunarHolidayStyle = $(dayItemHtml).attr('lunar-holiday-style');
    //节日样式（公历）
    let holidayStyle = $(dayItemHtml).attr('holiday-style');
    //周末样式（公历）
    let weekStyle = $(dayItemHtml).attr('week-style');
    let satStyle = $(dayItemHtml).attr('sat-style');
    let sunStyle = $(dayItemHtml).attr('sun-style');
    //周末样式（农历）
    let lunarWeekStyle = $(dayItemHtml).attr('lunar-week-style');
    let lunarSatStyle = $(dayItemHtml).attr('lunar-sat-style');
    let lunarSunStyle = $(dayItemHtml).attr('lunar-sun-style');
    //正常样式（公历）
    let normalStyle = $(dayItemHtml).attr('normal-style');
    //正常样式（农历）
    let lunarStyle = $(dayItemHtml).attr('lunar-style');
    // 分割位置
    let splitPosition = 0;
    if (splitHtml) {
      splitPosition = parseInt($(splitHtml).attr('split-position'))
    }

    if (column === 6){
      for (let i = 0;i < weekNumber;i++){
        let newWeekHtml = $(weekItemHtml)[0];
        if (weekNumber === 5){
          newWeekHtml.style.height = '19.8%'
        }else{
          newWeekHtml.style.height = '16.4%'
        }
        for (let z = 1; z < 8;z++){
          let newDayHtml = document.createElement('div');
          if (z == 7&&!rightBorder){
            newDayHtml.setAttribute('style',$(dayItemHtml).attr('style')+'border:none;');
          }else{
            newDayHtml.setAttribute('style',$(dayItemHtml).attr('style'));
          }
          let dayHtml = '';
          let weekDayStyle = z == 1 ? sunStyle || weekStyle : satStyle || weekStyle;
          let lunarWeekDayStyle = z == 1 ? lunarSunStyle || lunarWeekStyle : lunarSatStyle || lunarWeekStyle;
          if (dayNumber >= i*7+z-week && i*7+z-week > 0){
            let today = i*7+z-week;
            //获取农历数据
            let lunarCalendar = dateTOLunarCalendar.solar2lunar(year,month,today);
            //节日数据
            let holidayData = this.getTodayIsHoliday(lunarCalendar);
            dayHtml = `<span style="${z == 1 || z == 7 ? weekDayStyle : (holidayData.type == 5 ? normalStyle : holidayStyle) }">${today}</span>
            <span style="${holidayData.type == 5 ? lunarStyle : (holidayData.type == 4 ? lunarWeekDayStyle : lunarHolidayStyle) }">${holidayData.text}</span>`;
          }else{
            dayHtml = `<span style="${z == 1 || z == 7 ? weekDayStyle : normalStyle }"></span>
            <span style="${z == 1 || z == 7 ? lunarWeekDayStyle : lunarStyle }"></span>`;
          }
          newDayHtml.innerHTML = dayHtml;
          newWeekHtml.appendChild(newDayHtml);
          if (z === splitPosition) {
            newWeekHtml.appendChild($(splitHtml)[0])
          }
        }
        headerHtml.getElementsByClassName('day-content')[0].appendChild(newWeekHtml);
      }
    } else if(column === 2){
      for (let i = 0;i < 2;i++){
        let newWeekHtml = $(weekItemHtml)[0];
        for (let z = 1; z < 22;z++){
          let newDayHtml = document.createElement('div');
          newDayHtml.setAttribute('style',$(dayItemHtml).attr('style'));
          let dayHtml = '';
          if (dayNumber >= i*21+z-week && i*21+z-week > 0){
            let today = i*21+z-week;
            //获取农历数据
            let lunarCalendar = dateTOLunarCalendar.solar2lunar(year,month,today);
            //节日数据
            let holidayData = this.getTodayIsHoliday(lunarCalendar);
            dayHtml = `<span style="${z % 7 == 0 || z % 7 == 1 ? weekStyle : (holidayData.type == 5 ? normalStyle : holidayStyle) }">${today}</span>
            <span style="${holidayData.type == 5 ? lunarStyle : (holidayData.type == 4 ? lunarWeekStyle : lunarHolidayStyle) }">${holidayData.text}</span>`;
          }else{
            dayHtml = `<span style="${z % 7 == 0 || z % 7 == 1 ? weekStyle : normalStyle }"></span>
            <span style="${z % 7 == 0 || z % 7 == 1 ? lunarWeekStyle : lunarStyle }"></span>`;
          }
          newDayHtml.innerHTML = dayHtml;
          newWeekHtml.appendChild(newDayHtml);
        }
        headerHtml.getElementsByClassName('day-content')[0].appendChild(newWeekHtml);
      }
    }else{
      let newWeekHtml = $(weekItemHtml)[0];
      let $weekHtml = $(headerHtml).find('.week-content');
      let weekItems = $weekHtml.children();
      let weekBox = $weekHtml.empty()[0];
      let weekHtml = '';
      for (let z = 1; z < dayNumber+1;z++){
        let newDayHtml = document.createElement('div');
        newDayHtml.setAttribute('style',$(dayItemHtml).attr('style'));
        let dayHtml = '';
        let today = z;
        //获取农历数据
        let lunarCalendar = dateTOLunarCalendar.solar2lunar(year,month,today);
        weekHtml += weekItems.eq(lunarCalendar.nWeek-1)[0].outerHTML;
        //节日数据
        let holidayData = this.getTodayIsHoliday(lunarCalendar);
        dayHtml = `<span style="${lunarCalendar.nWeek == 6 || lunarCalendar.nWeek == 7 ? weekStyle : (holidayData.type == 5 ? normalStyle : holidayStyle) }">${today}</span>
          <span style="${holidayData.type == 5 ? lunarStyle : (holidayData.type == 4 ? lunarWeekStyle : lunarHolidayStyle) }">${holidayData.text}</span>`;
        newDayHtml.innerHTML = dayHtml;
        newWeekHtml.appendChild(newDayHtml);
      }
      weekBox.innerHTML = weekHtml;
      headerHtml.getElementsByClassName('day-content')[0].appendChild(newWeekHtml);
    }

    return `
      <div style="
      display: flex;
      height: ${100/this.options.scale}%;
      width:${100/this.options.scale}%;
      flex-direction: column;
      transform: scale(${this.options.scale});
      transform-origin:0% 0% 0px;
      font-family:Arial,'微软雅黑',serif;
      ">${headerHtml.innerHTML}</div>
    `;
  }

  async toSvg() {
    const $text = $(this.states.html);
    const scale = this.options.scale / this.parent.parent.scale
    $text.css({
      'transform': `scale(${scale})`,
      'width': `${100/scale}%`,
      'height': `${100/scale}%`
    })
    $text.attr('xmlns', "http://www.w3.org/1999/xhtml")
    return `<foreignObject x="${this.props.x}" y="${this.props.y}" width="${this.props.width}" height="${this.props.height}">${$text[0].outerHTML}</foreignObject>`
  }

  /**
   * 获取某月天数
   * @param year 年份
   * @param month 月份
   */
  public getMonthDayNumber(year, month){
    let d = new Date(year, month, 0);
    return d.getDate();
  }

  /**
   * 获取每月多少天
   * @param year 年份
   * @param month 月份
   * @param mouthDayNumber 天数
   * @param week 第一天为周几
   */
  public getWeekNumber(year, month, mouthDayNumber, week){
    let firstWeekDate = 0;
    if (week === 1) {
      //1号为周一
      firstWeekDate = 6;
    }else if (week === 0){
      //1号为周日
      firstWeekDate = 7;
    } else{
      firstWeekDate = 7 - week;
    }

    let monthHasDays = mouthDayNumber - firstWeekDate;
    return Math.ceil(monthHasDays/7)+1; // 计算本月有几周
  }

  /**
   * 获取某天有没有节日
   * @param lunarToday 公历转农历生成的数据
   */
  public getTodayIsHoliday(lunarToday){
    //公历节日
    let greHol = '';
    if (gregorianHoliday.hasOwnProperty(lunarToday.cMonth+'-'+lunarToday.cDay)){
      greHol = gregorianHoliday[lunarToday.cMonth+'-'+lunarToday.cDay];
    }
    //农历节日
    let lunHol = '';
    if (lunarHoliday.hasOwnProperty(lunarToday.lMonth+'-'+lunarToday.lDay)){
      lunHol = lunarHoliday[lunarToday.lMonth+'-'+lunarToday.lDay];
    }
    //母亲节5月第二个周日
    if (lunarToday.cMonth == 5){
      if (lunarToday.cDay > 7 && lunarToday.cDay <= 14 && lunarToday.nWeek == 7){
        greHol = '母亲节'
      }
    }
    //父亲节 6月第三个周日
    if (lunarToday.cMonth == 6){
      if (lunarToday.cDay > 14 && lunarToday.cDay <= 21 && lunarToday.nWeek == 7){
        greHol = '父亲节'
      }
    }
    //感恩节 11月第四个周四
    if (lunarToday.cMonth == 11){
      if (lunarToday.cDay > 21 && lunarToday.cDay <= 28 && lunarToday.nWeek == 4){
        greHol = '感恩节'
      }
    }
    //清明节
    if (lunarToday.Term == '清明'){
      lunHol = '清明节'
    }
    //type 1 农历节日 2公历节日 3节气 4周末 5普通
    let holidayData = {
      type:null,
      text:''
    };

    holidayData.text = lunHol || greHol || lunarToday.Term;

    if (lunHol){
      holidayData.type = 1;
      return holidayData;
    } else if(greHol){
      holidayData.type = 2;
      return holidayData;
    } else if(lunarToday.Term){
      holidayData.type = 3;
      return holidayData;
    } else{
      if (lunarToday.nWeek === 6||lunarToday.nWeek === 7) {
        holidayData.type = 4;
      }else{
        holidayData.type = 5;
      }
      holidayData.text = lunarToday.IDayCn === '初一' ? lunarToday.IMonthCn : lunarToday.IDayCn;
      return holidayData;
    }
  }
}
