import { Injectable } from '@angular/core';
import {
  DesignParamService,
  DesignParam
} from '@libs/core/src/lib/services/design-param.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Template } from '../models/base/template';
import { TemplateOfficialService } from '@libs/core/src/lib/services/template/template-official.service';
import { MemberService } from '@libs/core/src/lib/services/member/member.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';
import { Page } from '../models/base/page';
import { EditorService } from './editor.service';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { JSONSchema, LocalStorage } from '@ngx-pwa/local-storage';
import * as jsonpack from 'jsonpack';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { ToastIcon } from '@libs/modal/src/lib/models/toast-icon';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { ModalService as Modal2Service } from '@libs/core/src/lib/services/modal.service';
import { delay, map, retryWhen, switchMap, take } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { apiMemberTemplate } from '@libs/core/src/lib/util/api';
import * as JsBarcode from 'jsbarcode';
import { CookieService } from 'ngx-cookie-service';
import { url_btoa } from '@libs/core/src/lib/util/base64';
import { Svg } from '@libs/editor/src/lib/models/element/svg';
@Injectable()
export class ApiService {
  // 当前打开的模版对应的classifyId
  templateClassifyId = 0;
  classifyPid = 0;
  likePid = 0;
  classifyParentName = '';
  cardSetting;
  // 用户模版来源ID;
  editFrom;
  // 背景图素材的请求ID
  backgroundMaterialId = 0;
  orderLinks: {
    coop_id: number
    created_time: string
    fid: number
    id: number
    logo: string
    pid: number
    title: string
    url: string
  }[];
  tplRes = null;
  classify = [
    // 素材
    // {
    //   tab: 'material',
    //   name: '我的素材',
    //   cid: 0,
    //   img: '/assets/img/editor/icon/siyousucai.svg',
    //   icon: 'icon-wodesucai',
    //   materialLayout: 'upload'
    // },
    // {
    //   tab: 'material',
    //   name: '图片相框',
    //   cid: 165,
    //   img: '/assets/img/editor/icon/tupianrongqi.svg',
    //   icon: 'icon-rongqi',
    //   materialLayout: 'item',
    //   type: 'container',
    // },
    // {
    //   name: '文字容器',
    //   cid: 11,
    //   img: '/assets/img/editor/icon/wenzirongqi.svg',
    //   icon: 'icon-tiaofu',
    //   materialLayout: 'item',
    //   class: 'svg',
    // },
    // {
    //   tab: 'material',
    //   name: '图片',
    //   cid: 1,
    //   img: '/assets/img/editor/icon/tupian.svg',
    //   icon: 'icon-tupian3',
    //   materialLayout: 'item',
    //   class: 'image',
    //   mark: true,
    //   fillMode: 'cover'
    // },
    // {
    //   tab: 'material',
    //   name: '封面主题素材',
    //   cid: 458,
    //   img: '/assets/img/editor/icon/tupian.svg',
    //   icon: 'icon-tupian3',
    //   materialLayout: 'item',
    //   class: 'image',
    //   mark: true,
    //   fillMode: 'cover'
    // },
    {
      tab: 'material',
      name: '图标',
      cid: 90,
      img: '/assets/img/editor/icon/tubiao.svg',
      icon: 'icon-biaoji',
      materialLayout: 'group',
      mark: true
    },
    // {
    //   tab: 'material',
    //   name: '线条/箭头',
    //   cid: 3,
    //   img: '/assets/img/editor/icon/xiantiao.svg',
    //   icon: 'icon-xianxingjiantou',
    //   materialLayout: 'item',
    //   mark: true
    // },
    {
      tab: 'material',
      name: '插图',
      cid: 170,
      img: '/assets/img/editor/icon/chatu.svg',
      icon: 'icon-chatu',
      materialLayout: 'item',
      mark: true
    },
    {
      tab: 'material',
      name: '形状',
      cid: 197,
      img: '/assets/img/editor/icon/chatu.svg',
      icon: 'icon-chatu',
      materialLayout: 'item',
      mark: true
    },
    // {
    //   tab: 'material',
    //   name: '名企LOGO',
    //   cid: 425,
    //   img: '/assets/img/editor/icon/chatu.svg',
    //   icon: 'icon-chatu',
    //   materialLayout: 'item',
    //   mark: true
    // },
    {
      tab: 'material',
      name: '电商元素',
      cid: 2,
      img: '/assets/img/editor/icon/dianshang.svg',
      icon: 'icon-dianshang',
      materialLayout: 'item',
      mark: true
    },
    {
      tab: 'material',
      name: '免抠图片',
      cid: 26,
      img: '/assets/img/editor/icon/miankou.svg',
      icon: 'icon-gangbi',
      materialLayout: 'item',
      mark: true,
      info: true
    },
    {
      tab: 'coop_material',
      name: this.coop.coopInfo.folderName,
      cid: this.coop.coopInfo.folderId,
      coop: 1,
      img: '/assets/img/editor/icon/coop_material.svg',
      icon: 'icon-dianshang',
      materialLayout: 'item',
      mark: true,
      info: true
    },
    // 形状
    {
      tab: 'shape',
      cid: 197,
      type: 'shape'
    },
    // 背景
    {
      tab: 'background',
      cid: 204
    },
    // 文字
    {
      tab: 'text',
      name: '标题样式',
      cid: 195,
      img: '/assets/img/editor/icon/text1.svg',
      icon: 'icon-text3',
      materialLayout: 'item'
    },

    {
      tab: 'text',
      name: '文字组合',
      cid: 189,
      img: '/assets/img/editor/icon/text3.svg',
      icon: 'icon-text2',
      materialLayout: 'item'
    },
    {
      tab: 'text',
      name: '图文组合',
      cid: 190,
      img: '/assets/img/editor/icon/text4.svg',
      icon: 'icon-text3',
      materialLayout: 'item'
    },
    {
      tab: 'text',
      name: '艺术字体',
      cid: 196,
      img: '/assets/img/editor/icon/text5.svg',
      icon: 'icon-text1',
      materialLayout: 'item'
    },
    // // 工具
    // {
    //   tab: 'tool',
    //   name: '表格',
    //   cid: 194,
    //   img: '/assets/img/editor/icon/art_table.png',
    //   icon: 'icon-biaoge',
    //   materialLayout: 'item'
    // },
    {
      tab: 'tool',
      name: '文字识别',
      cid: 0,
      img: '/assets/img/editor/icon/text_read.png',
      icon: 'icon-text1',
      materialLayout: 'text-read'
    }
  ];
  // 字体列表
  fontList = [];
  tplParams;
  urlParams;
  // 团队颜色
  teamColors = [];
  // 在打开或者保存模版之后,是否修改过
  templateChanged = false;
  constructor(
    public designParam: DesignParamService,
    public http: HttpClient,
    public templateOfficial: TemplateOfficialService,
    public templateMember: MemberService,
    public router: Router,
    public route: ActivatedRoute,
    public modal: ModalService,
    public modal2: Modal2Service,
    public editor: EditorService,
    public localStorage: LocalStorage,
    private coop: CoopService,
    public auth: AuthService,
    public cookie: CookieService
  ) {
    window['http'] = http;
    this.urlParams = route.snapshot.queryParams;
  }
  /**
   * 获取分类配置信息
   * @param tab material=素材列表 text=文字列表 tool=工具列表
   */
  getClassify(tab = null) {
    if (!tab) return this.classify;
    return this.classify.filter(item => item.tab === tab);
  }

  async createBarCode(id): Promise<any> {
    await new Promise<void>(resolve => {
      const barSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      JsBarcode(barSvg, id, {
        width: 1,
        height: 26,
        fontSize: 10,
        margin: 8,
        marginBottom: 4,
        textMargin: 0
      });
      setTimeout(async () => {
        barSvg.removeAttribute('width');
        barSvg.removeAttribute('height');
        barSvg.style.height = '100%';
        if (this.editor.template.props.noFirstPage) {
          this.editor.template.pages[this.editor.template.pages.length - 1].props.barCode = barSvg.outerHTML;
        } else {
          this.editor.template.pages[0].props.barCode = barSvg.outerHTML;
        }
        await this.saveTemplate(this.editor.template);
        resolve();
      });
    });
  }
  /**
   * 保存历史纪录
   * @param template 模版
   */
  async saveHistory(template) {
    if (template.id > 0) {
      try {
        // 获取是否有记录
        let historyList: any = await this.localStorage.getItem('template_backup').toPromise();
        if (historyList) {
          historyList = JSON.parse(historyList);
        } else {
          historyList = [];
        }
        // 查找是否有重复ID的
        const findRes = historyList.find(item => item.id === template.id);
        if (findRes) {
          // 判断时间,超过10分钟再保存
          const diff = (Date.parse(new Date().toString()) - Date.parse(findRes.date)) / 1000 / 60;
          if (diff > 10) {
            // 将当前记录插入到第一个
            historyList.unshift({
              title: template.title,
              id: template.id,
              date: new Date(),
              data: jsonpack.pack(template.toObject())
            });
            // 只保留三个
            historyList = historyList.slice(0, 3);
            await this.localStorage.setItem('template_backup', JSON.stringify(historyList)).toPromise();
          }
        } else {
          // 这个模版没有保存到历史过,将当前记录插入到第一个
          historyList.unshift({
            title: template.title,
            id: template.id,
            date: new Date(),
            data: jsonpack.pack(template.toObject())
          });
          // 只保留三个
          historyList = historyList.slice(0, 3);
          await this.localStorage.setItem('template_backup', JSON.stringify(historyList)).toPromise();
        }

      } catch (e) {
        // 满了,清空
        await this.localStorage.removeItem('template_backup').toPromise();
      }
    }
  }
  async submitPrint(params) {
    const data = (await this.http.post('/template-member/template-print', params).toPromise());
    return data;
  }
  /**
 * 设计师提交模版审核
 * @param template 模版对象
 */
  async saveNewTemplate(template: Template,method:string) {
    // 组合的一些特殊处理
    console.log('this',this)
    const templateObj = template.toObject(true, false);
    let combinationItemsCount = 0, combinationIndex = 0;
    for (let page of templateObj.pages) {
      for (let el of page.elements) {
        if (el.type === 'combination') {
          combinationIndex ++;
          combinationItemsCount += el.options.items.length;
          el.options.name = '组合' + combinationIndex;
        }
      }
    }
    let param = {
      title: template.title,
      content: JSON.stringify(templateObj),
      classify_id: this.templateClassifyId,
      template_id: template.id,
      is_official: method === 'official'? 1: 0 
    };
    const res = await this.templateMember
    .templateDesigner(param)
    .toPromise();
    // 更新template对象的ID
    //this.tplRes = res;
    //template.id = res.templateId;

    // 更新URL
    // const callbackUrl = this.tplParams.callbackUrl;
    // let routerObj: any = {
    //   method: 'member',
    //   templateId: template.id,
    //   callbackUrl
    // };
  }
  /**
   * 保存模版
   * @param template 模版对象
   * @param save_type 保存方式 1 手动 2 自动
   */
  async saveTemplate(template: Template, save_type = 1) {
    this.templateChanged = false;
    const params = this.tplParams;
    const containerComplete = (this.likePid === 1) && template.pages.every(item => {
      return item.elements
        .filter(el => el.type === 'container')
        .every(el => el.options.clip)
    });

    // 组合的一些特殊处理
    const templateObj = template.toObject(true, false);
    let combinationItemsCount = 0, combinationIndex = 0;
    for (let page of templateObj.pages) {
      for (let el of page.elements) {
        if (el.type === 'combination') {
          combinationIndex ++;
          combinationItemsCount += el.options.items.length;
          el.options.name = '组合' + combinationIndex;
        }
      }
    }

    // 保存到历史保存
    if (params.method === 'member' && template.id === 0) {
      // 用户首次创建模版
      let param;
      if (params.method === 'member' && params.width && params.height) {
        param = {
          title: template.title,
          content: JSON.stringify(templateObj),
          classify_id: this.templateClassifyId,
          is_diy: 1,
          edit_from: params.editFrom || this.editFrom
        };
      } else {
        param = {
          title: template.title,
          content: JSON.stringify(templateObj),
          classify_id: this.templateClassifyId,
          edit_from: params.from
        };
      }
      if (params.taskOrderId) {
        param.task_order_id = parseInt(params.taskOrderId);
        param.task_order_link_id = params.taskOrderLinkId;
      }
      // 第三方接入逻辑
      const urlParamIsFrom = sessionStorage.getItem('coop_from')
      if (urlParamIsFrom == 'proManage') {
        param.open_from = urlParamIsFrom;
        param.open_unique_id = sessionStorage.getItem('coop_order_Child_id');
      }

      // 判断相册书容器填充完成参数
      if (containerComplete) {
        param.complete_img = '1';
      }
      if (params.size && params.size.childData) {
        param.extend_config = decodeURIComponent(atob(params.size.childData))
      }
      param.save_type = save_type;
      if (params.cid) {
        param.extend_config_id = params.cid;
      }
      const res = await this.templateMember
        .create(param)
        .toPromise();
      // 更新template对象的ID
      this.tplRes = res;
      template.id = res.templateId;
      template.editFrom = res.editFrom;
      // 更新URL
      const callbackUrl = this.tplParams.callbackUrl;
      let routerObj: any = {
        method: 'member',
        templateId: template.id,
        callbackUrl
      };

      // 非登录状态下保存,使用游客method
      if (res['noLoginKey']) {
        routerObj = {
          method: 'guest-member',
          key: res['noLoginKey'],
          callbackUrl
        };
        this.tplParams = routerObj;
      }

      const routeUrl = this.router.url.split('/').slice(1, -1).join('/') + '/' + url_btoa(JSON.stringify(routerObj));
      this.router.navigate([routeUrl], { queryParams: this.route.snapshot.queryParams });
      await this.saveHistory(template);
      /*if (this.likePid === 1 && this.coop.coopInfo.bar_code === '1') {
        await this.createBarCode(res.templateId);
      }*/
      return {
        ...res,
        mode: 'new',
        params
      };
    } else if ((params.method === 'member' && template.id > 0) || params.method === 'guest-member') {
      // 用户保存已创建模版
      let param: any = {
        templateId: template.id,
        title: template.title,
        content: JSON.stringify(templateObj),
        save_type: save_type,
        classify_id: this.templateClassifyId
      };
      // 判断相册书容器填充完成参数
      if (containerComplete) {
        param.complete_img = '1';
      }
      // 第三方接入逻辑
      const urlParamIsFrom = sessionStorage.getItem('coop_from')
      if (urlParamIsFrom == 'proManage') {
        param.open_from = urlParamIsFrom;
        param.open_unique_id = sessionStorage.getItem('coop_order_Child_id');
      }
      const res = await this.templateMember
        .update(param)
        .toPromise();
      this.tplRes = res;
      await this.saveHistory(template);
      return {
        ...res,
        mode: 'member',
        params
      };
    } else if (params.method === 'official' && template.id > 0) {
      // 官方 保存官方模版
      console.log('保存官方模版');
      await this.saveHistory(template);

      templateObj.combinationItemsCount = combinationItemsCount;
      const res = await this.templateOfficial.update({
        templateId: template.id,
        title: template.title,
        content: JSON.stringify(templateObj),
        classifyId: this.templateClassifyId
      } as TemplateOfficial).toPromise();
      this.tplRes = res;
      return {
        ...res,
        mode: 'offical',
        params
      };
    }
  }
  // @ts-ignore
  /**
   * 另存为模版
   * @param title 标题
   * @param template 模版对象
   * @param folderId 文件夹ID
   */
  async saveTemplateAs(title, template: Template, folder_id) {
    const params = this.tplParams;
    // 用户保存官方模版,转为用户个人模版
    let param: any = {};
    if (params.method === 'member' && params.width && params.height) {
      param['is_diy'] = 1;
      param = {
        title: title,
        content: template.toJson(),
        classify_id: this.templateClassifyId || 0,
        edit_from: this.editFrom || 0,
        is_diy: 1,
        folder_id
      };
    } else {
      param = {
        title: title,
        content: template.toJson(),
        classify_id: this.templateClassifyId,
        edit_from: params.from || this.editFrom,
        folder_id
      };
    }
    if (params.cid || (this.tplRes && this.tplRes.extendConfigId)) {
      param.extend_config_id = this.tplRes && this.tplRes.extendConfigId || params.cid;
    }
    const res = await this.templateMember
      .create(param)
      .toPromise();
    // 更新template对象的ID/标题
    template.id = res.templateId;
    template.title = res.title;
    template.editFrom = res.editFrom;
    // 更新URL
    const routeUrl = this.designParam.getParam({
      method: 'member',
      templateId: template.id
    });
    this.router.navigate([routeUrl]);
    await this.saveHistory(template);
    /*if (this.likePid === 1 && this.coop.coopInfo.bar_code === '1') {
      await this.createBarCode(res.templateId);
    }*/
    return res;
  }
  /**
   * 获取模版数据
   */
  async getTemplate(params: DesignParam) {
    console.log('路由参数', params);
    this.tplParams = params;

    let tplJson;
    if (params.method === 'official' && params.templateId) {
      // 从后台打开官方模版
      const res = await this.templateOfficial.view(params.templateId).toPromise();
      console.log('从后台打开官方模版', res);
      this.tplRes = res;
      this.templateClassifyId = res.classifyId;
      this.classifyPid = res.classifyPid;
      this.likePid = res.likePid || res.classifyPid;

      if (res.editConfig) {
        if (res.editConfig.resize) {
          res.editConfig.resize = JSON.parse(res.editConfig.resize);
        }
        if (res.editConfig.addPage) {
          res.editConfig.addPage = JSON.parse(res.editConfig.addPage);
        }
      }
      const editConfig = res.editConfig;
      let splitHorizonLine;
      let splitLine = editConfig.split.split(',').map(item => item ? parseFloat(item) : 0);
      if (editConfig.split_horizon) {
        splitHorizonLine = editConfig.split_horizon.split(',').map(item => item ? parseFloat(item) : 0);
      }
      if (splitLine.length === 1) {
        splitLine = splitLine[0];
      }
      if (splitHorizonLine && splitHorizonLine.length === 1) {
        splitHorizonLine = splitHorizonLine[0];
      }

      let bleed = parseFloat(editConfig.bleed);

      if (!res.content) {
        const tpl = new Template({
          props: {
            width: parseFloat(editConfig.width),
            height: parseFloat(editConfig.height),
            unit: editConfig.unit,
            bleed,
            splitLine,
            splitHorizonLine
          }
        });
        const pageNum = parseInt(editConfig.default_page, 10);
        for (let i = 0; i < pageNum; i++) {
          const page = new Page({
            id: i
          });
          tpl.addPage(page);
        }

        if (res.likePid === 9998) {
          const {
            firstPageWidth,
            firstPageHeight,
            firstPageGroove,
            firstPageSpine,
            firstPageOverlapping,
            bindPosition,
            banEndPage,
            splitCover,
            splitInside
          } = res.editConfig;
          if (firstPageWidth) {
            tpl.pages[0].props.width = parseFloat(firstPageWidth)
          }
          if (firstPageHeight) {
            tpl.pages[0].props.height = parseFloat(firstPageHeight)
          }
          if (firstPageGroove) {
            tpl.pages[0].props.groove = parseFloat(firstPageGroove);
          }
          if (firstPageOverlapping) {
            const overlapping = parseFloat(firstPageOverlapping);
            tpl.pages[0].props.overlapping = overlapping;
            tpl.pages[0].props.width = tpl.pages[0].width + overlapping * 2;
            tpl.pages[0].props.height = tpl.pages[0].height + overlapping * 2;
          }
          if (firstPageSpine) {
            tpl.pages[0].props.spine = parseFloat(firstPageSpine);
            const { props: {spine}, width, height} = tpl.pages[0];
            const {bleed} = tpl.props;

            let eleItem = new Svg({
              id: this.editor.getNewID(),
              props: {
                x: (width - spine) / 2,
                y: -bleed || 0,
                width: spine,
                height: height + 2 * (bleed || 0),
                oWidth:200, oHeight:800,
                sign:'书脊',
                lock: true},
              options: {
                url: '//image.tubangzhu.net/updata_new/201910/30/9192033b1d3839c16306.svg',
                source: 13070356,
                colors: []
              }
            });
            tpl.pages[0].addElement(eleItem)
          }
          if (banEndPage == '1') {
            tpl.pages[0].props.banLeft = true;
          }
          if (splitCover) {
            tpl.props.split_cover = splitCover
          }
          if (splitInside) {
            tpl.props.split_inside = splitInside
          }
          if (bindPosition) {
            tpl.props.bind_position = parseFloat(bindPosition);
          }

        }

        const obj = tpl.toObject();
        obj.id = res.templateId;
        obj.title = res.title;

        obj.states = {
          ...res.editConfig,
          isPrint: res.isPrint === 1
        };
        //如果链接传参lockSize不让变尺寸
        if (params.lockSize === 1) {
          obj.props.resize = false;
        }
        tplJson = obj;
      } else {
        res.content.title = res.title;
        res.content.id = res.templateId;
        res.content.states = {
          ...res.editConfig,
          isPrint: res.isPrint === 1
        };

        //如果链接传参lockSize不让变尺寸
        if (params.lockSize === 1) {
          res.content.props.resize = false;
        }
        // 分割线在有内容时也去品类设置里的
        res.content.props.splitLine = splitLine;
        res.content.props.splitHorizonLine = splitHorizonLine;
        // 如果第一页的宽高是单独设置的,则禁止这个模版修改尺寸 end
        tplJson = res.content;
      }
    } else if (params.method === 'member' && params.from) {
      // 用户打开官方模版
      const res = await this.templateOfficial.view(params.from).toPromise();
      this.tplRes = res;
      this.templateClassifyId = res.classifyId;
      this.classifyParentName = res.parentName;
      this.classifyPid = res.classifyPid;
      this.likePid = res.likePid || res.classifyPid;
      res.content.title = res.title;
      if (res.editConfig) {
        if (res.editConfig.resize) {
          res.editConfig.resize = JSON.parse(res.editConfig.resize);
        }
        if (res.editConfig.addPage) {
          res.editConfig.addPage = JSON.parse(res.editConfig.addPage);
        }
      }
      res.content.states = {
        ...res.editConfig,
        isPrint: res.isPrint === 1
      };

      //如果链接传参lockSize不让变尺寸
      if (params.lockSize === 1 || res.content.states.resize === false) {
        res.content.props.resize = false;
      }

      // 如果第一页的宽高是单独设置的,则禁止这个模版修改尺寸 end
      tplJson = res.content;
    } else if (params.method === 'member' && params.templateId) {
      // 用户打开用户自己的模版
      const res = await this.templateMember.view(params.templateId).toPromise();
      this.tplRes = res;
      this.templateClassifyId = res.classifyId;
      this.classifyParentName = res.classifyParentName;
      this.editFrom = res.editFrom;
      this.classifyPid = res.classifyPid;
      this.likePid = res.likePid || res.classifyPid;
      if (res.editConfig) {
        if (res.editConfig.resize) {
          res.editConfig.resize = JSON.parse(res.editConfig.resize);
        }
        if (res.editConfig.addPage) {
          res.editConfig.addPage = JSON.parse(res.editConfig.addPage);
        }
      } else {
        res.editConfig = {
          addPage: true,
          print: false,
          resize: true
        };
      }
      if (!res.content) {
        const editConfig = res.editConfig;
        let splitHorizonLine;
        let splitLine = editConfig.split.split(',').map(item => item ? parseFloat(item) : 0);
        if (editConfig.split_horizon) {
          splitHorizonLine = editConfig.split_horizon.split(',').map(item => item ? parseFloat(item) : 0);
        }
        if (splitLine.length === 1) {
          splitLine = splitLine[0];
        }
        if (splitHorizonLine && splitHorizonLine.length === 1) {
          splitHorizonLine = splitHorizonLine[0];
        }
  
        let bleed = parseFloat(editConfig.bleed);
        const tpl = new Template({
          props: {
            width: parseFloat(editConfig.width),
            height: parseFloat(editConfig.height),
            unit: editConfig.unit,
            bleed,
            splitLine,
            splitHorizonLine
          }
        });
        const pageNum = parseInt(editConfig.default_page, 10);
        for (let i = 0; i < pageNum; i++) {
          const page = new Page({
            id: i
          });
          tpl.addPage(page);
        }

        if (res.likePid === 9998) {
          const {
            firstPageWidth,
            firstPageHeight,
            firstPageGroove,
            firstPageSpine,
            firstPageOverlapping,
            bindPosition,
            banEndPage,
            splitCover,
            splitInside
          } = res.editConfig;
          if (firstPageWidth) {
            tpl.pages[0].props.width = parseFloat(firstPageWidth)
          }
          if (firstPageHeight) {
            tpl.pages[0].props.height = parseFloat(firstPageHeight)
          }
          if (firstPageGroove) {
            tpl.pages[0].props.groove = parseFloat(firstPageGroove);
          }
          if (firstPageOverlapping) {
            const overlapping = parseFloat(firstPageOverlapping);
            tpl.pages[0].props.overlapping = overlapping;
            tpl.pages[0].props.width = tpl.pages[0].width + overlapping * 2;
            tpl.pages[0].props.height = tpl.pages[0].height + overlapping * 2;
          }
          if (firstPageSpine) {
            tpl.pages[0].props.spine = parseFloat(firstPageSpine);
            const { props: {spine}, width, height} = tpl.pages[0];
            const {bleed} = tpl.props;

            let eleItem = new Svg({
              id: this.editor.getNewID(),
              props: {
                x: (width - spine) / 2,
                y: -bleed || 0,
                width: spine,
                height: height + 2 * (bleed || 0),
                oWidth:200, oHeight:800,
                sign:'书脊',
                lock: true},
              options: {
                url: '//image.tubangzhu.net/updata_new/201910/30/9192033b1d3839c16306.svg',
                source: 13070356,
                colors: []
              }
            });
            tpl.pages[0].addElement(eleItem)
          }
          if (banEndPage == '1') {
            tpl.pages[0].props.banLeft = true;
          }
          if (splitCover) {
            tpl.props.split_cover = splitCover
          }
          if (splitInside) {
            tpl.props.split_inside = splitInside
          }
          if (bindPosition) {
            tpl.props.bind_position = parseFloat(bindPosition);
          }

        }

        const obj = tpl.toObject();
        obj.id = res.templateId;
        obj.title = res.title;

        obj.states = {
          ...res.editConfig,
          isPrint: res.isPrint === 1
        };
        //如果链接传参lockSize不让变尺寸
        if (params.lockSize === 1) {
          obj.props.resize = false;
        }
        tplJson = obj;
      } else {
        res.content.id = res.templateId;
        res.content.title = res.title;
        
        res.content.states = {
          ...res.editConfig,
          isPrint: res.isPrint === 1
        };
        //如果链接传参lockSize不让变尺寸
        if (params.lockSize === 1) {
          res.content.props.resize = false;
        }
        // 如果第一页的宽高是单独设置的,则禁止这个模版修改尺寸 end
        tplJson = res.content;
      }
    } else if (params.method === 'member' && params.width && params.height) {
      // 新建模版
      console.log('用户创建新模版', params);
      let editConfig, splitLine, splitHorizonLine, bleed = 0;

      if (params.classifyPid) {
        this.classifyPid = params.classifyPid;
      }
      if (params.likePid) {
        this.likePid = params.likePid;
      }
      if (params.templateClassifyId) {
        this.templateClassifyId = params.templateClassifyId;
      }
      if (params.editFrom) {
        this.editFrom = params.editFrom;
        editConfig = (await this.templateOfficial.viewConfig(params.editFrom).toPromise()).editConfig;
        if (editConfig) {
          if (editConfig.resize) {
            editConfig.resize = JSON.parse(editConfig.resize);
          }
          if (editConfig.addPage) {
            editConfig.addPage = JSON.parse(editConfig.addPage);
          }
        }
        splitLine = editConfig.split.split(',').map(item => item ? parseFloat(item): 0);
        if (editConfig.split_horizon) {
          splitHorizonLine = editConfig.split_horizon.split(',').map(item => item ? parseFloat(item): 0);
        }
        if (splitLine.length === 1) splitLine = splitLine[0];
        if (splitHorizonLine && splitHorizonLine.length === 1) splitHorizonLine = splitHorizonLine[0];
        bleed = parseFloat(editConfig.bleed);
      }
      const tpl = new Template({
        props: {
          width: parseFloat(params.width.toString()),
          height: parseFloat(params.height.toString()),
          unit: params.unit ? params.unit : 'mm',
          bleed: bleed,
          splitLine,
          splitHorizonLine
        }
      });
      const page = new Page({
        id: 0
      });
      tpl.addPage(page);
      const obj = tpl.toObject();
      obj.title = params.title;
      obj.states = {
        isPrint: false,
        resize: true,
        ...editConfig
      };

      if (obj.states.resize === false) {
        obj.props.resize = false;
      }

      tplJson = obj;
    } else if (params.method === 'guest-member') {

      // 用户打开用户自己的模版
      const res: any = await this.http.get('/template-member/info', {
        params: {
          key: params['key'],
          expand: 'content,editConfig'
        }
      }).toPromise();
      this.tplRes = res;
      this.editFrom = res.editFrom;
      this.templateClassifyId = res.classifyId;
      this.classifyPid = res.classifyPid;
      this.likePid = res.likePid || res.classifyPid;
      res.content.id = res.templateId;
      res.content.title = res.title;
      if (res.editConfig) {
        if (res.editConfig.resize) {
          res.editConfig.resize = JSON.parse(res.editConfig.resize);
        }
        if (res.editConfig.addPage) {
          res.editConfig.addPage = JSON.parse(res.editConfig.addPage);
        }
      } else {
        res.editConfig = {
          addPage: true,
          print: false,
          resize: true
        };
      }
      res.content.states = {
        ...res.editConfig,
        isPrint: res.isPrint === 1
      };

      //如果链接传参lockSize不让变尺寸
      if (params.lockSize === 1) {
        res.content.props.resize = false;
      }
      // 如果第一页的宽高是单独设置的,则禁止这个模版修改尺寸 end
      this.tplRes = res;
      tplJson = res.content;
    }

    if (this.classifyPid) {
      this.orderLinks = await this.http.get<any>('/cooperation/order-link', {
        params: { pid: this.classifyPid.toString(), type: 'all'}
      }).toPromise();
    }

    // 异常数据处理 begin
    for (const pageItem of tplJson.pages) {
      for (const eleItem of pageItem.elements) {
        if (eleItem.type === 'text') {
          const $text = $(eleItem.options.text);
          if ($text.find('font[face]').length === 0 && !$text.css('font-family')) {
            console.info('异常数据处理:无字体文字', eleItem);
            $text.wrapInner('<font face="font_169"></font>');
            eleItem.options.text = $text[0].outerHTML;
          }
          if ($text.children('div').length === 0) {
            console.info('异常数据处理:无div文字', eleItem);
            $text.wrapInner('<div></div>');
            eleItem.options.text = $text[0].outerHTML;
          }
          // 换行文字
          $text.children('div').each(function (i) {
            if ($(this).text() === ' ') {
              console.info('异常数据处理:前排空格换行问题', eleItem);
              delete eleItem.options.linesLength;
              $(this).remove();
            } else {
              return false;
            }
          });
          eleItem.options.text = $text[0].outerHTML;
        }
      }
    }
    // 异常数据处理 end
    return tplJson;
  }

  async getSignData(signData?) {
    if (this.auth.isGuest) return;
    const template = this.editor.template;
    if (!signData && this.tplParams.method === 'member' && this.auth.user.cardSetting) {

      if (this.tplParams.from) {
        signData = this.auth.user.cardSetting;
      } else if (this.tplParams.templateId && template.props.cardSettingId) {
        if (this.auth.user.cardSetting.id === template.props.cardSettingId) {
          signData = this.auth.user.cardSetting;
        } else {
          signData = await this.auth.getCardSettingId(template.props.cardSettingId).toPromise();
        }
      }
    }

    if (signData) {
      template.props.cardSettingId = signData.id;
      this.cardSetting = signData;
    }
  }

  /**
   * 获取相册书模板
   *
   */
  async getPhotoTemplate(params: DesignParam){
    // 用户打开官方模版
    const res = await this.templateOfficial.view(params.from).toPromise();
    // 如果第一页的宽高是单独设置的,则禁止这个模版修改尺寸 end
    return res.content;
  }
  /**
   * 会员下载
   */
  async vipDownload() {
    if (this.auth.isGuest) {
      this.modal2.loginModal();
      return;
    }
    if (!this.auth.user.isVip) {
      if (this.coop.coopInfo.id === '12864') {
        const loadingToast = this.modal.toast({
          icon: ToastIcon.LOADING,
          msg: '正在准备下载',
          duration: 0
        });
        try {
          this.editor.exitAllEditMode();
          // 保存模版
          await this.saveTemplate(this.editor.template);
          if (this.auth.user.coopMemberInfo && this.auth.user.coopMemberInfo.check === 20) {
            this.modal2.showNoVipUser(this.editor.template, 'kejie');
          } else {
            this.modal2.showNoVipUser(this.editor.template);
          }
        } catch (e) {
          this.modal.toast({
            icon: ToastIcon.ERROR,
            msg: '下载失败,请检查重试'
          });
        } finally {
          loadingToast.close();
        }
      } else {
        this.modal2.showNoVipUser();
      }
    } else {
      const loadingToast = this.modal.toast({
        icon: ToastIcon.LOADING,
        msg: '正在准备下载',
        duration: 0
      });
      try {
        this.editor.exitAllEditMode();
        // 保存模版
        await this.saveTemplate(this.editor.template);
        this.modal2.showVipDownload(this.editor.template)
      } catch (e) {
        this.modal.toast({
          icon: ToastIcon.ERROR,
          msg: '下载失败,请检查重试'
        });
      } finally {
        loadingToast.close();
      }
    }
  }
  /**
   * 下载列表
   */
  vipDownloadList() {
    this.modal2.showVipDownloadList(this.editor.template)
  }
  /**
   * 获取字体数据
   */
  async getFontList(designParam): Promise<any[]> {
    const params = {
      template_type: null,
      template_id: null
    };
    if (designParam.method === 'official' && designParam.templateId) {
      params.template_type = '1';
      params.template_id = designParam.templateId;
    } else if (designParam.method === 'member' && designParam.from) {
      params.template_type = '1';
      params.template_id = designParam.from;
    } else if (designParam.method === 'member' && designParam.templateId) {
      params.template_type = '2';
      params.template_id = designParam.templateId;
    } else {
      delete params.template_type;
      delete params.template_id;
    }
    let fontList = (await this.http.get('/font/editor-font-search', {
      params
    }).toPromise()) as Array<any>;
    // 将团队字体加入team分组
    // fontList = fontList.map(item => {
    //   if (item.isOfficial === 0) {
    //     item.group = 'team';
    //   }
    //   return item;
    // });
    // console.log('fontList', fontList);
    // 为每个项目增加states(加载状态),notLoaded=未加载 loading=加载中 loaded=已加载
    fontList = fontList.map(item => {
      item['states'] = 'notLoaded';
      return item;
    });

    return fontList;
    // let fontList = [];
    // for (let i = 0; i < 40; i++) {
    //   fontList.push({
    //     id: 'a' + i,
    //     name: '测试字体' + i,
    //     thumb: '',
    //     url: '/assets/mock/font/a' + i + '.ttf',
    //     copyright: true,
    //     show: true,
    //     group: 'user'
    //   });
    //   fontList.push({
    //     id: 'b' + i,
    //     name: '测试字体' + i,
    //     thumb: '',
    //     url: '/assets/mock/fontb' + i + '.ttf',
    //     copyright: true,
    //     show: true,
    //     group: 'user'
    //   });
    //   fontList.push({
    //     id: 'c' + i,
    //     name: '测试字体' + i,
    //     thumb: '',
    //     url: '/assets/mock/font/c' + i + '.ttf',
    //     copyright: true,
    //     show: true,
    //     group: 'user'
    //   });
    //   fontList.push({
    //     id: 'd' + i,
    //     name: '测试字体' + i,
    //     thumb: '',
    //     url: '/assets/mock/font/d' + i + '.ttf',
    //     copyright: true,
    //     show: true,
    //     group: 'user'
    //   });
    //   fontList.push({
    //     id: 'e' + i,
    //     name: '测试字体' + i,
    //     thumb: '',
    //     url: '/assets/mock/font/e' + i + '.ttf',
    //     copyright: true,
    //     show: true,
    //     group: 'user'
    //   });
    //   fontList.push({
    //     id: 'f' + i,
    //     name: '测试字体' + i,
    //     thumb: '',
    //     url: '/assets/mock/font/f' + i + '.ttf',
    //     copyright: true,
    //     show: true,
    //     group: 'user'
    //   });
    //   fontList.push({
    //     id: 'g' + i,
    //     name: '测试字体' + i,
    //     thumb: '',
    //     url: '/assets/mock/font/g' + i + '.ttf',
    //     copyright: true,
    //     show: true,
    //     group: 'user'
    //   });
    // }
    // // 为每个项目增加states(加载状态),notLoaded=未加载 loading=加载中 loaded=已加载

    // return fontList;
  }

  /**
   * 缩略图生成成功监听
   * @param id
   */
  async getFinishThumbnail(id, expand: any = {}) {
    return this.http.get<any>(apiMemberTemplate + '/' + id, {params: expand}).pipe(
      switchMap(data => {
        if (data.thumbnailUpdatedAt < data.thumbnailShouldAt) {
          return throwError(new HttpErrorResponse({error: 'thumbnail not update'}));
        } else {
          return of(data);
        }
      }),
      retryWhen(err => err.pipe(
        delay(2000), take(120)
      ))
    ).toPromise();
  }
}
