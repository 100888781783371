import { Rect } from '../../services/editor.service';
import * as $ from 'jquery';
import Color from 'color';
import parseCSS from 'css-rules';
import axios from 'axios'

export const editorThumbProps = {
  fontList: [],
  canvas: null
}

/**
 * 检测两个矩形是否发生碰撞重叠
 * @param rect1 矩形1
 * @param rect2 矩形2
 */
export function hitTest(rect1: Rect, rect2: Rect): boolean {
  let px, py;
  px = rect1.x <= rect2.x ? rect2.x : rect1.x;
  py = rect1.y <= rect2.y ? rect2.y : rect1.y;
  // 判断点是否都在两个对象中
  if (
    px >= rect1.x &&
    px <= rect1.x + rect1.width &&
    py >= rect1.y &&
    py <= rect1.y + rect1.height &&
    px >= rect2.x &&
    px <= rect2.x + rect2.width &&
    py >= rect2.y &&
    py <= rect2.y + rect2.height
  ) {
    return true;
  } else {
    return false;
  }
}
/**
 * 深度复制
 * @param object 需要被深度复制的对象
 */
export function deepClone(object: object): object {
  if (object && typeof object === 'object') {
    return JSON.parse(JSON.stringify(object));
  } else {
    return undefined;
  }
}

export function offset(ele) {
  const obj: any = {};
  obj.left = ele.offsetLeft;
  obj.top = ele.offsetTop;

  while (ele.offsetParent) {//寻找父级，当寻找到 body 往上的时候为 null ，所以循环停止
    ele = ele.offsetParent; //初始值
    obj.left += ele.offsetLeft
    obj.top += ele.offsetTop
  }
  return obj
}
/**
 * 替换文本(全部替换)
 * @param str 源文本
 * @param find 需要查找的字符
 * @param replace 替换为
 */
export function replace(str: string, find: string, replaceTo: string): string {
  return str.replace(new RegExp(find, 'gm'), replaceTo);
}
/**
 * 获取当前设备的DPI
 */
export function getDPI(): number {
  const screen: any = window.screen;
  if (screen.deviceXDPI !== undefined) {
    return screen.deviceXDPI;
  } else {
    const tmpNode = document.createElement('DIV');
    tmpNode.style.cssText =
      'width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden';
    document.body.appendChild(tmpNode);
    const dpi = parseInt(tmpNode.offsetWidth.toString(), 10);
    tmpNode.parentNode.removeChild(tmpNode);
    return dpi;
  }
}
/**
 * 转换像素为点
 * @param px 像素数
 * @param tplDPI 模版DPI
 */
export function px2pt(px, tplDPI = 300) {
  const userDPI = 72;
  return px / userDPI * 72 * (userDPI / tplDPI);
}
/**
 * 转换点为像素
 * @param pt 点数
 * @param tplDPI 模版DPI
 */
export function pt2px(pt, tplDPI = 300) {
  const userDPI = 72;
  return pt * userDPI / 72 * (tplDPI / userDPI);
}
/**
 * 获取一个单位相对于像素的缩放比例
 * @param unit 单位
 */
export function getUnitScale(unit: string): number {
  switch (unit) {
    case 'px':
      return 1;
    case 'mm':
      return 300 / 25.4;
  }
}
/**
 * 获取SVG文档的颜色信息
 * @param svg SVG
 */
export function getSvgColors(svg) {
  const $svg = $(svg);
  // 设置缩放属性
  $svg.attr('width', '100%');
  $svg.attr('height', '100%');
  $svg.attr('preserveAspectRatio', 'none');
  // 如果colors属性为空,则为首次添加,分析颜色信息
  let colorList: any[] = [];
  // 从属性读取颜色
  $svg.find('*').each(function () {
    const colors = [
      $(this).attr('fill'),
      //$(this).attr('stroke'),
      $(this).attr('stop-color')
    ];
    const styleText = $(this)[0].getAttribute('style');
    if (styleText !== null) {
      const styleColors = styleText.split(';').forEach(item => {
        colors.push(item.split(':')[1]);
      });
    }
    // 添加颜色到colorList
    for (const item of colors) {
      if (item && item !== 'none' && item.indexOf('url') === -1) {
        try {
          const hex = new Color(item).hex(),
            findRes = colorList.find(color => color.hex === hex);
          if (!findRes) {
            colorList.push({
              hex,
              proto: item,
              weight: 0
            });
            break;
          } else {
            findRes.weight++;
          }
        } catch (error) { }
      }
    }
  });
  // 从CSS样式读取
  const cssRules = parseCSS($svg.find('style').text());
  for (const ruleItem of cssRules) {
    const colors = [
      ruleItem[1].fill,
      //ruleItem[1].stroke,
      ruleItem[1].stopColor
    ];
    // 添加颜色到colorList
    for (const item of colors) {
      if (item && item !== 'none' && item.indexOf('url') === -1) {
        const hex = new Color(item).hex(),
          findRes = colorList.find(color => color.hex === hex);
        if (!findRes) {
          colorList.push({
            hex,
            proto: item,
            weight: 0
          });
          break;
        } else {
          findRes.weight++;
        }
      }
    }
  }
  // 排序
  colorList = colorList.sort((a, b) => {
    return b.weight - a.weight;
  });
  return colorList.map(item => {
    return { oColor: item.proto, nColor: item.hex };
  });
}
/**
 * 获取SVG文档的描边颜色信息
 * @param svg SVG
 */
export function getStrokeWidth(svg) {
  const $svg = $(svg);
  $svg.find('*').each(function () {
    const width = [
      $(this).attr('stroke-width'),
    ];
    if(width && width.length > 0 && width[0]) {
      console.log('width',width)
      console.log('width',width[0])
      return JSON.parse(JSON.stringify(width[0]))
    }
    return ''
  })
}
/**
 * 获取SVG文档的描边颜色信息
 * @param svg SVG
 */
export function getStrokeSvgColors(svg) {
  const $svg = $(svg);
  // 设置缩放属性
  $svg.attr('width', '100%');
  $svg.attr('height', '100%');
  $svg.attr('preserveAspectRatio', 'none');
  // 如果colors属性为空,则为首次添加,分析颜色信息
  let colorList: any[] = [];
  // 从属性读取颜色
  $svg.find('*').each(function () {
    const colors = [
      $(this).attr('stroke'),
    ];
    const styleText = $(this)[0].getAttribute('style');
    if (styleText !== null) {
      const styleColors = styleText.split(';').forEach(item => {
        colors.push(item.split(':')[1]);
      });
    }
    // 添加颜色到colorList
    for (const item of colors) {
      if (item && item !== 'none' && item.indexOf('url') === -1) {
        try {
          const hex = new Color(item).hex(),
            findRes = colorList.find(color => color.hex === hex);
          if (!findRes) {
            colorList.push({
              hex,
              proto: item,
              weight: 0
            });
            break;
          } else {
            findRes.weight++;
          }
        } catch (error) { }
      }
    }
  });
  // 从CSS样式读取
  const cssRules = parseCSS($svg.find('style').text());
  for (const ruleItem of cssRules) {
    const colors = [
      ruleItem[1].stroke,
    ];
    // 添加颜色到colorList
    for (const item of colors) {
      if (item && item !== 'none' && item.indexOf('url') === -1) {
        const hex = new Color(item).hex(),
          findRes = colorList.find(color => color.hex === hex);
        if (!findRes) {
          colorList.push({
            hex,
            proto: item,
            weight: 0
          });
          break;
        } else {
          findRes.weight++;
        }
      }
    }
  }
  // 排序
  colorList = colorList.sort((a, b) => {
    return b.weight - a.weight;
  });
  return colorList.map(item => {
    return { oColor: item.proto, nColor: item.hex };
  });
}
export function getLayoutSvgColors(svg) {
  const $svg = $(svg);
  // 设置缩放属性
  $svg.attr('width', '100%');
  $svg.attr('height', '100%');
  $svg.attr('preserveAspectRatio', 'none');
  // 如果colors属性为空,则为首次添加,分析颜色信息
  let colorList: any[] = [];
  // 从属性读取颜色
  $svg.find('*').not('rect[id]').each(function () {
    if (this.parentNode.tagName === 'defs') return;
    const colors = [
      $(this).attr('fill'),
      $(this).attr('stroke'),
      $(this).attr('stop-color')
    ];
    const styleText = $(this)[0].getAttribute('style');
    if (styleText !== null) {
      const styleColors = styleText.split(';').forEach(item => {
        colors.push(item.split(':')[1]);
      });
    }
    // 添加颜色到colorList
    for (const item of colors) {
      if (item && item !== 'none' && item.indexOf('url') === -1) {
        try {
          const hex = new Color(item).hex(),
            findRes = colorList.find(color => color.hex === hex);
          if (!findRes) {
            colorList.push({
              hex,
              weight: 0
            });
            break;
          } else {
            findRes.weight++;
          }
        } catch (error) { }
      }
    }
  });
  // 从CSS样式读取
  const cssRules = parseCSS($svg.find('style').text());
  for (const ruleItem of cssRules) {
    const colors = [
      ruleItem[1].fill,
      ruleItem[1].stroke,
      ruleItem[1].stopColor
    ];
    // 添加颜色到colorList
    for (const item of colors) {
      if (item && item !== 'none' && item.indexOf('url') === -1) {
        const hex = new Color(item).hex(),
          findRes = colorList.find(color => color.hex === hex);
        if (!findRes) {
          colorList.push({
            hex,
            weight: 0
          });
          break;
        } else {
          findRes.weight++;
        }
      }
    }
  }
  // 排序
  colorList = colorList.sort((a, b) => {
    return b.weight - a.weight;
  });
  return colorList.map(item => {
    return { oColor: item.hex, nColor: item.hex };
  });
}
/**
 * 从一段文本中获取颜色样式(例如css)
 * @param css CSS文本
 */
export function getStringColors(str) {
  let colorList = [];
  const reg = new RegExp('#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})', 'g');
  str.match(reg).forEach(hex => {
    const findRes = colorList.find(color => color.hex === hex);
    if (!findRes) {
      colorList.push({
        hex,
        weight: 0
      });
    } else {
      findRes.weight++;
    }
  });
  // 排序
  colorList = colorList.sort((a, b) => {
    return b.weight - a.weight;
  });
  return colorList.map(item => {
    return { oColor: item.hex, nColor: item.hex };
  });
}
/**
 * RGB色值转HSB
 * @param color rgb颜色数组
 */
export function RGB2HSB(color) {
  const r = color[0],
    g = color[1],
    b = color[2];
  const nR = r / 255,
    nG = g / 255,
    nB = b / 255,
    nmax = Math.max(Math.max(nR, nG), nB),
    nmin = Math.min(Math.min(nR, nG), nB),
    ndelMax = nmax - nmin,
    nV = nmax;
  let nH, nS;
  if (ndelMax === 0) {
    nH = 0;
    nS = 0;
  } else {
    nS = ndelMax / nmax;
    const ndelR = ((nmax - nR) / 6 + ndelMax / 2) / ndelMax,
      ndelG = ((nmax - nG) / 6 + ndelMax / 2) / ndelMax,
      ndelB = ((nmax - nB) / 6 + ndelMax / 2) / ndelMax;
    if (nR === nmax) {
      nH = ndelB - ndelG;
    } else if (nG === nmax) {
      nH = 1 / 3 + ndelR - ndelB;
    } else if (nB === nmax) {
      nH = 2 / 3 + ndelG - ndelR;
    }
    if (nH < 0) {
      nH = nH + 1;
    }
    if (nH > 1) {
      nH = nH - 1;
    }
  }
  return [nH * 360, nS * 100, nV * 100];
}
export function HSB2RGB(color) {
  const h = color[0],
    s = color[1],
    b = color[2];
  const nH = h / 360,
    nS = s / 100,
    nV = b / 100;
  let nR, nG, nB;
  if (s === 0) {
    nR = nV * 255;
    nG = nV * 255;
    nB = nV * 255;
    return [nR, nG, nB];
  } else {
    let hi = nH * 6;
    if (hi === 6) {
      hi = 0;
    }
    const f = parseInt(hi.toString(), 10),
      p = nV * (1 - nS),
      q = nV * (1 - nS * (hi - f)),
      t = nV * (1 - nS * (1 - (hi - f)));
    if (f === 0) {
      nR = nV;
      nG = t;
      nB = p;
    } else if (f === 1) {
      nR = q;
      nG = nV;
      nB = p;
    } else if (f === 2) {
      nR = p;
      nG = nV;
      nB = t;
    } else if (f === 3) {
      nR = p;
      nG = q;
      nB = nV;
    } else if (f === 4) {
      nR = t;
      nG = p;
      nB = nV;
    } else {
      nR = nV;
      nG = p;
      nB = q;
    }
    return [nR * 255, nG * 255, nB * 255];
  }
}
/**
 * 生成随机数
 * @param minNum 最小值
 * @param maxNum 最大值
 */
export function randomNum(minNum, maxNum) {
  return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
}
/**
 * 获取SVG的栅格化图像base64数据(用以优化性能)
 * @param svg SVG文本
 */
export function getSVGPixelBase64Data({ svg, width, height }) {
  return new Promise((resolve, reject) => {
    // 获取svg宽高
    const id = 'svgToPixelCanvas_' + randomNum(0, 10000);
    // 创建临时canvas
    $('body').append(
      `<canvas id='${id}' style='display:none' width='${width}' height='${height}'></canvas>`
    );
    const $canvas = $(`#${id}`),
      canvas = $canvas[0] as HTMLCanvasElement,
      ctx = canvas.getContext('2d');
    // 创建Image对象
    const image = new Image(width, height);
    image.onload = () => {
      ctx.drawImage(image, 0, 0);
      const base64 = canvas.toDataURL();
      // 删除临时canvas
      $canvas.remove();
      resolve(base64);
    };
    const encoded = encodeURIComponent(svg)
      .replace(/'/g, '%27')
      .replace(/"/g, '%22');
    const header = 'data:image/svg+xml,';
    const dataUrl = header + encoded;
    image.src = dataUrl;
  });
}
export function getSVGText({
  text,
  family = 'font_a0',
  size = 12,
  color = '#000000',
  align = 'center',
  lineHeight = 1,
  letterSpacing = 0,
  bold = false,
  italic = false
}) {
  const ns = 'http://www.w3.org/2000/svg';
  // 将文本分为多行
  const splitText = text.split('\n');
  // 创建一个临时SVG node
  const svgNode = document.createElementNS(ns, 'svg');
  document.body.appendChild(svgNode);
  // 遍历所有行,获取他们的宽高
  const textList = [];
  for (const text of splitText) {
    // 创建一个text
    const textNode = document.createElementNS(ns, 'text');
    textNode.innerHTML = text;
    textNode.style.fontFamily = family;
    textNode.style.fontSize = size.toString();
    textNode.style.letterSpacing = letterSpacing.toString();
    textNode.style.fontWeight = bold ? 'bold' : 'normal';
    textNode.style.fontStyle = italic ? 'italic' : 'normal';
    // 添加到svg内
    svgNode.appendChild(textNode);
    // 获取宽高
    const { width, height } = textNode.getBBox();
    // 删除这个text
    svgNode.removeChild(textNode);
    textList.push({
      text,
      width: width + text.length * letterSpacing,
      height: height * lineHeight,
      oWidth: width,
      oHeight: height
    });
  }
  // 获取所有文字的宽高
  const svgWidth = Math.max(...textList.map(item => item.oWidth));
  const svgHeight = textList
    .map(item => item.height)
    .reduce((total, value) => {
      return total + value;
    });
  // 创建文本
  let pos = 0;
  const firstLine = textList[0];
  // 创建一个G元素,将text都添加进来,稍后获取boundingClient
  const gNode = document.createElementNS(ns, 'g');
  for (const item of textList) {
    const textNode = document.createElementNS(ns, 'text');
    if (align === 'center') {
      // 居中对齐
      textNode.setAttribute('x', (svgWidth / 2 - item.oWidth / 2).toString());
    } else if (align === 'right') {
      // 右对齐
      textNode.setAttribute('x', (svgWidth - item.oWidth).toString());
    }
    textNode.setAttribute('y', pos + firstLine.height);
    textNode.style.fill = color;
    textNode.style.fontFamily = family;
    textNode.style.fontSize = size.toString();
    textNode.style.letterSpacing = letterSpacing.toString();
    textNode.style.fontWeight = bold ? 'bold' : 'normal';
    textNode.style.fontStyle = italic ? 'italic' : 'normal';
    textNode.setAttribute('dominant-baseline', 'central');
    textNode.innerHTML = item.text;
    pos += item.height;
    gNode.appendChild(textNode);
  }
  // 获取gNode的bbox
  svgNode.appendChild(gNode);
  const gNodeBbox = gNode.getBoundingClientRect();
  // 添加width和height属性
  svgNode.setAttribute('width', svgWidth.toString());
  svgNode.setAttribute('height', svgHeight.toString());
  // 修改svgNode的viewBox
  svgNode.setAttribute('viewBox', `0 ${firstLine.height / 2} ${svgWidth} ${svgHeight}`);
  const html = svgNode.outerHTML;
  document.body.removeChild(svgNode);
  return html;
}
/**
 * 获取表格样式
 * @param row_index 行索引
 * @param col_index 列索引
 * @param total_row 总共行数
 * @param total_col 总共列
 * @param style_data style
 */
export function getTableStyle(row_index, col_index, total_row, total_col, style_data) {
  let temp_arr = [];
  let default_style = {};
  for (let i = 0; i < style_data.length; i++) {
    let hit_style = 0;
    for (let a = 0; a < style_data[i].rule.length; a++) {
      let item = style_data[i].rule[a];
      if (item[0] === 'row_index' && item[1] === row_index) {
        hit_style++;
      }
      if (item[0] === 'row_final' && row_index === total_row - 1) {
        hit_style++;
      }
      if (item[0] === 'col_index' && item[1] === col_index) {
        hit_style++;
      }
      if (item[0] === 'col_final' && col_index === total_col - 1) {
        hit_style++;
      }
      if (item[0] === 'row_odd' && row_index % 2 !== 0) {
        hit_style++;
      }
      if (item[0] === 'col_odd' && col_index % 2 !== 0) {
        hit_style++;
      }
      if (item[0] === 'row_even' && row_index % 2 === 0) {
        hit_style++;
      }
      if (item[0] === 'col_even' && col_index % 2 === 0) {
        hit_style++;
      }
      if (item[0] === 'default') {
        default_style = style_data[i];
      }
    }
    if (hit_style === style_data[i].rule.length) {
      temp_arr.push(style_data[i]);
    }
  }
  let style_item;
  if (temp_arr.length > 0) {
    style_item = temp_arr[temp_arr.length - 1];
  } else {
    style_item = default_style;
  }
  //初始化样式,填充没有定义的样式
  style_item.paddingLeft = style_item.paddingLeft ? style_item.paddingLeft : 0;
  style_item.paddingRight = style_item.paddingRight ? style_item.paddingRight : 0;
  style_item.paddingTop = style_item.paddingTop ? style_item.paddingTop : 0;
  style_item.paddingBottom = style_item.paddingBottom ? style_item.paddingBottom : 0;

  style_item.borderLeftWidth = style_item.borderLeftWidth ? style_item.borderLeftWidth : 0;
  style_item.borderRightWidth = style_item.borderRightWidth ? style_item.borderRightWidth : 0;
  style_item.borderTopWidth = style_item.borderTopWidth ? style_item.borderTopWidth : 0;
  style_item.borderBottomWidth = style_item.borderBottomWidth ? style_item.borderBottomWidth : 0;

  style_item.color = style_item.color ? style_item.color : '#000000';
  style_item.background = style_item.background ? style_item.background : '#FFFFFF';
  style_item.align = style_item.align ? style_item.align : 'center';

  style_item.bold = style_item.bold ? style_item.bold : false;
  style_item.italic = style_item.italic ? style_item.italic : false;

  return style_item;
}

export function distance(x1, y1, x2, y2) {
  let calX = x2 - x1;
  let calY = y2 - y1;
  return Math.pow((calX * calX + calY * calY), 0.5);
}


export function getBase64fromUrl(url) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url,
      responseType: 'blob'
    }).then(res => {
      if (res && res.data && res.data.size) {
        const dataInfo = res.data
        let reader = new FileReader()
        reader.readAsDataURL(dataInfo)
        reader.onload = function (e) {
          const result = e.target.result
          resolve(result)
        }
      } else {
        reject()
      }
    }).catch(e => {
      reject(e)
    })
  })
}


export function getBase64fromImg(url) {
  return new Promise(resolve => {
    if (!editorThumbProps.canvas) {
      editorThumbProps.canvas = document.createElement('canvas')
    }
    const canvas = editorThumbProps.canvas;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.setAttribute("crossOrigin",'Anonymous')
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0, img.width, img.height);
      resolve(canvas.toDataURL("image/png"))
    }
    img.src = url
  })
}

export function getSvgFromUrl(url) {
  return axios({
    method: 'get',
    url: url,
    responseType: 'text'
  })
}
