import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from '@libs/core/src/lib/services/post-order/admin.service';
import { map } from 'rxjs/operators';
import { DataProvider } from '@libs/core/src/lib/util/data-provider';
import { Env } from '@libs/core/src/lib/util/environment';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-upgrade-detail',
  templateUrl: './upgrade-detail.component.html',
  styleUrls: ['./upgrade-detail.component.scss']
})
export class UpgradeDetailComponent implements OnInit {
  @Input() type;
  icon:string
  title:string
  description:string
  constructor(private adminService: AdminService, public env: Env, public coop: CoopService, private modal: NzModalRef, private auth: AuthService, public router : Router) { }

  ngOnInit() {
    if(this.type == '店铺') {
      this.icon = '03'
      this.title = '店铺&数据Excel'
      this.description = '进阶版可创建多间店铺；使用店铺数据功能导出Excel数据表格，便于店铺信息的总结及订单数据汇总，及时有效地反馈店铺运营数据。'
    }
    if(this.type == '客服') {
      this.icon = '06'
      this.title = '客服&数据Excel'
      this.description = '进阶版可设置多名客服；使用客服数据导出汇总客服数据，订单数据信息一目了然，更直观的体现客服工作量信息。'
    }
    if(this.type == '搜索') {
      this.icon = '08'
      this.title = '搜索设计师'
      this.description = '在庞大的设计师放单系统内查找搜索设计师、查看设计师数据变成最有效的效率工具。'
    }
    if(this.type == '限制') {
      this.icon = '10'
      this.title = '限制接单功能'
      this.description = '在设计师接单过多情况下，可以自行设置限制设计师接单，从而避免因过度接单造成订单延期的问题。'
    }
    if(this.type == '接单') {
      this.icon = '12'
      this.title = '接单注意事项设置'
      this.description = '设置品类的接单注意事项，有效避免因品类或特殊工艺无法制作而弃单的情况。'
    }
    if(this.type == '文件') {
      this.icon = '14'
      this.title = '文件规范设置'
      this.description = '提前设置文件规范，避免因文件问题使设计师重复劳动，从而提高工作效率和工作质量。'
    }
    if(this.type == '客户') {
      this.icon = '16'
      this.title = '客户管理'
      this.description = '添加设置常用客户信息，发布订单时可与客户关联，清晰的展现订单关系，更好的服务客户。'
    }
    if(this.type == '设计') {
      this.icon = '18'
      this.title = '设计师标签'
      this.description = '为设计师定义标签，方便客户放单时更快速、更准确的指定设计师。'
    }
    if(this.type == '留言') {
      this.icon = '20'
      this.title = '留言管理'
      this.description = '设计师及客户留言，更直观的表达问题、加快沟通，了解订单情况。'
    }
    if(this.type == '品类') {
      this.icon = '22'
      this.title = '品类不限量'
      this.description = '品类设置无上限，可详细的将产品分类，方便产品管理。'
    }
    if(this.type == '订单') {
      this.icon = '24'
      this.title = '订单发布不限量'
      this.description = '订单无上限，充分满足业务增长量带来的订单数量。'
    }
  }

  tabFunctionPanel (config) {
    if(config == 0) {
      this.icon = '03'
      this.title = '店铺&数据Excel'
      this.description = '进阶版可创建多间店铺；使用店铺数据功能导出Excel数据表格，便于店铺信息的总结及订单数据汇总，及时有效地反馈店铺运营数据。'
    }
    if(config == 1) {
      this.icon = '06'
      this.title = '客服&数据Excel'
      this.description = '进阶版可设置多名客服；使用客服数据导出汇总客服数据，订单数据信息一目了然，更直观的体现客服工作量信息。'
    }
    if(config == 2) {
      this.icon = '08'
      this.title = '搜索设计师'
      this.description = '在庞大的设计师放单系统内查找搜索设计师、查看设计师数据变成最有效的效率工具。'
    }
    if(config == 3) {
      this.icon = '10'
      this.title = '限制接单功能'
      this.description = '在设计师接单过多情况下，可以自行设置限制设计师接单，从而避免因过度接单造成订单延期的问题。'
    }
    if(config == 4) {
      this.icon = '12'
      this.title = '接单注意事项设置'
      this.description = '设置品类的接单注意事项，有效避免因品类或特殊工艺无法制作而弃单的情况。'
    }
    if(config == 5) {
      this.icon = '14'
      this.title = '文件规范设置'
      this.description = '提前设置文件规范，避免因文件问题使设计师重复劳动，从而提高工作效率和工作质量。'
    }
    if(config == 6) {
      this.icon = '16'
      this.title = '客户管理'
      this.description = '添加设置常用客户信息，发布订单时可与客户关联，清晰的展现订单关系，更好的服务客户。'
    }
    if(config == 7) {
      this.icon = '18'
      this.title = '设计师标签'
      this.description = '为设计师定义标签，方便客户放单时更快速、更准确的指定设计师。'
    }
    if(config == 8) {
      this.icon = '20'
      this.title = '留言管理'
      this.description = '设计师及客户留言，更直观的表达问题、加快沟通，了解订单情况。'
    }
    if(config == 9) {
      this.icon = '22'
      this.title = '品类不限量'
      this.description = '品类设置无上限，可详细的将产品分类，方便产品管理。'
    }
    if(config == 10) {
      this.icon = '24'
      this.title = '订单发布不限量'
      this.description = '订单无上限，充分满足业务增长量带来的订单数量。'
    }
  }

  // 跳转升级页面
  toUpdate () {
    window.open(location.origin + '/postorder/apply')
  }
}
