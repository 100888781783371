import { Element } from '../base/element';
import * as $ from 'jquery';
import { LayoutEditComponent } from '../../components/edit-tools/layout-edit/layout-edit.component';
import { LayoutCoverComponent } from '../../components/edit-tools/layout-cover/layout-cover.component';
import { getSvgColors, getLayoutSvgColors, getBase64fromImg } from '../../utils/misc/common';
import { ToolBarLayoutComponent } from '@libs/editor/src/lib/components/editor-ui/t-tool-bar/tools/tool-bar-layout/tool-bar-layout.component';
export class Layout extends Element {
    public type = 'layout';
    public base64map: any = {};
    async onCreated() {
        this.config.toolbar = ToolBarLayoutComponent;
        this.config.corner = {
            tl: false,
            tr: false,
            ml: false,
            mr: true,
            bl: false,
            br: true,
            mt: false,
            mb: false,
            rotate: false,
            quick: false
        };
        // 加载svg图片
        let svg = (await window['http'].get(this.options.url, {responseType: 'text'}).toPromise());
        let $svg;
        // 取出SVG数据,去除描述信息
        const $svgData = $(svg);
        for (let i = 0; i < $svgData.length; i++) {
            const item = $svgData[i];
            if (item.nodeName === 'svg') {
                $svg = $(item);
                break;
            }
        }
        // 查找没有fill属性又没有style又没有class的元素(解决颜色不识别问题)
        $svg.find('path,rect,circle,polygon,ellipse,polyline,text').not('[style],[fill],[class]').attr('fill', '#000000');

        // 删除标记
        $svg.find('rect[opacity="0"]').remove();
        svg = $svg[0].outerHTML;
        // 替换hex的简化形式,替换为完整的hex (例如:#000 => #000000)
        svg = svg.replace(/#([0-9a-fA-F]{3})(?![0-9a-fA-F]){3}/g, (m, p1) => {
            return '#' + p1 + p1;
        });
        svg = svg.replace(/#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})/g, (m, p1) => {
            return m.toLowerCase();
        });
        //提取文字列表
        let layoutTextList = '';
        $svg.find('text, tspan').each(function () {
          let $this = $(this);
          if ($this.attr('font-family')){
            layoutTextList += $this.attr('font-family')+',';
          }
        });
        this.options.svgTextList = layoutTextList;
        this.states.svg = svg;

        // 分析颜色信息
        this.states.oColors = getLayoutSvgColors(svg);

        const items = [];
        let mode = 'grid';
        $svg.find('*').each(function () {
            const id = $(this).attr('id');
            if (/(image|text)-[\u4e00-\u9fa5a-zA-Z0-9]+$/g.test(id)) {
                const arr = id.split('-');
                items.push({
                    name: arr[1],
                    type: arr[0]
                });
                mode = 'grid';
            } else if (/(image|text)-([\u4e00-\u9fa5a-zA-Z0-9]+)-([0-9]+)$/g.test(id)) {
                const arr = id.split('-');
                items.push({
                    name: arr[1],
                    type: arr[0],
                    group: parseInt(arr[2], 10)
                });
                mode = 'single';
            }
        });
        // if (mode === 'grid') {
        //     this.config.corner = {
        //         ...this.config.corner,
        //         mt: false,
        //         mb: false,
        //         rotate: false
        //     };
        // }
        this.states.items = items;
        this.states.mode = mode;
        this.updateHeight();

        this.states.html = this.toHTML();
        let downWidth = 0;
        let downScale = 1;
        this.on('cornermousedown', e => {
            downScale = this.options.scale;
            downWidth = this.props.width;
        });
        this.on('scaling', e => {
            if (
                e.corner === 'tl' ||
                e.corner === 'tr' ||
                e.corner === 'bl' ||
                e.corner === 'br'
            ) {
                this.options.scale = (this.props.width / downWidth) * downScale;
            } else {
                this.updateHeight();
            }
            this.states.html = this.toHTML();
        });
        // 双击:进入编辑模式
        this.on('selected', this.enterEditMode.bind(this));
        // 取消选择:退出编辑模式
        this.on('deselected', this.exitEditMode.bind(this));
        this.on('deselected', () => {
            this.states.activeIndex = null;
        });
        // this.on('selected', e => {
        //     if (e.targets) return;
        //     this.emit('enterEdit', {
        //         target: this,
        //         toolComponent: LayoutCoverComponent
        //     });
        // });
        // this.on('deselected', e => {
        //     this.emit('exitEdit', {
        //         target: this,
        //         toolComponent: LayoutCoverComponent
        //     });
        // });
        // this.config.minWidth = this.props.oWidth * this.options.scale;
    }
    enterEditMode(e) {
      if (!e || e.targets) return;
      if (this.states.mode !== 'grid') return;
      if (!this.states.editMode) {
        this.states.editMode = true;
        // this.config.transform = false;
        this.config.visible = false;
        this.emit('exitEdit', {
            target: this,
            toolComponent: LayoutCoverComponent
        });
        this.emit('enterEdit', {
            target: this,
            toolComponent: LayoutEditComponent
        });
      }
    }
    exitEditMode() {
      if (this.states.editMode) {
        this.states.editMode = false;
        // this.config.transform = true;
        this.config.visible = true;
        this.emit('exitEdit', {
            target: this,
            toolComponent: LayoutEditComponent
        });
        delete this.states.activeIndex;
      }
    }
    updateHeight() {
        if (this.states.mode === 'grid') {
            // 排版
            const { props, options } = this;
            const scale = options.scale;
            const { oWidth, oHeight } = props;

            let x = 0;
            let y = oHeight * scale;
            for (let i = 0; i < options.data.length; i++) {
                let width = (oWidth * scale);
                let height = (oHeight * scale);
                if (x + width > props.width && i > 0) {
                    x = 0;
                    y += height;
                }
                x += width;
            }
            this.props.height = y;
        }
    }
    toHTML() {
        const { props, options } = this;
        const { oWidth, oHeight } = props;
        const scale = options.scale;
        let svg = `<svg style="position: absolute;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 ${props.width} ${props.height}">`;

        if (this.states.mode === 'single') {
            let itemSVG = this.states.svg;
            // 替换颜色(只支持HEX颜色的替换)
            for (const colorItem of this.options.colors) {
                // 替换颜色
                itemSVG = itemSVG.replace(
                    new RegExp(colorItem.oColor, 'gmi'),
                    colorItem.nColor
                );
            }
            const $svg = $(itemSVG);
            for (let i = 0; i < options.data.length; i++) {
                const dataItem = options.data[i];
                for (const key in dataItem) {
                    const headItem = this.states.items.find(item => item.name === key && item.group === i + 1);
                    if (headItem) {
                        const $el = $svg.find('#' + headItem.type + '-' + headItem.name + '-' + headItem.group);
                        switch (headItem.type) {
                            case 'text':
                                $el.text(dataItem[key]);
                                break;
                            case 'image':
                                const image = dataItem[key];
                                $el[0].outerHTML = `<image xlink:href="${image.filePath}" viewBox="0 0 ${image.width} ${image.height}" preserveAspectRatio="xMidYMid slice" clip-path="${$el.attr('clip-path')}" x="${$el.attr('x') || 0}" y="${$el.attr('y') || 0}" width="${$el.attr('width')}" height="${$el.attr('height')}"></image>`;
                                break;
                        }
                    }
                }

            }
            itemSVG = $svg[0].outerHTML;

            // 处理ID重复问题
            itemSVG = itemSVG.replace(/url\(#(.*?)\)/g, (m, p1) => {
                return `url(#${this.id + '-' + p1})`;
            });

            itemSVG = itemSVG.replace(/id="(.*?)"/g, (m, p1) => {
                return `id="${this.id + '-' + p1}"`;
            });
            itemSVG = itemSVG.replace(/xlink:href="#(.*?)"/g, (m, p1) => {
                return `xlink:href="#${this.id + '-' + p1}"`;
            });

            svg += itemSVG;
        } else if (this.states.mode === 'grid') {
            const imgList = [];
            let x = 0;
            let y = 0;
            for (let i = 0; i < options.data.length; i++) {
                const item = options.data[i];
                let width = (oWidth * scale);
                let height = (oHeight * scale);
                if (x + width > props.width && i > 0) {
                    x = 0;
                    y += height;
                }
                imgList.push({ x, y, width, height, item });
                x += width;
            }
            // 生成svg
            for (let i = 0; i < imgList.length; i++) {
                const imgItem = imgList[i];
                let itemSVG = this.states.svg;
                // 替换颜色(只支持HEX颜色的替换)
                for (const colorItem of this.options.colors) {
                    // 替换颜色
                    itemSVG = itemSVG.replace(
                        new RegExp(colorItem.oColor.toLowerCase(), 'gmi'),
                        colorItem.nColor.toUpperCase()
                    );
                }
                const $svg = $(itemSVG);
                $svg.attr('x', imgItem.x);
                $svg.attr('y', imgItem.y);
                $svg.attr('width', imgItem.width);
                $svg.attr('height', imgItem.height);
                for (const key in imgItem.item) {
                    const headItem = this.states.items.find(item => item.name === key);
                    if (!headItem) break;
                    const $el = $svg.find('#' + headItem.type + '-' + headItem.name);
                    switch (headItem.type) {
                        case 'text':
                            $el.text(imgItem.item[key]);
                            break;
                        case 'image':
                            const image = imgItem.item[key];
                            // $el.attr('opacity', 0);
                            $el[0].outerHTML = `<image xlink:href="${image.filePath}" viewBox="0 0 ${image.width} ${image.height}" preserveAspectRatio="xMidYMid slice" clip-path="${$el.attr('clip-path')}" x="${$el.attr('x') || 0}" y="${$el.attr('y') || 0}" width="${$el.attr('width')}" height="${$el.attr('height')}"></image>`;
                            break;
                    }
                }

                itemSVG = $svg[0].outerHTML;

                // 处理ID重复问题
                itemSVG = itemSVG.replace(/url\(#(.*?)\)/g, (m, p1) => {
                    return `url(#${this.id + '-' + p1 + '-' + i})`;
                });
                itemSVG = itemSVG.replace(/id="(.*?)"/g, (m, p1) => {
                    return `id="${this.id + '-' + p1 + '-' + i}"`;
                });
                itemSVG = itemSVG.replace(/xlink:href="#(.*?)"/g, (m, p1) => {
                    return `xlink:href="#${this.id + '-' + p1 + '-' + i}"`;
                });
                svg += itemSVG;
            }

            svg += '</svg>';
        }

        return svg;
    }

  async toSvg(): Promise<string> {
    const $svg = $(this.states.html)
    const $images = $svg.find('image')
    for (let i=0; i<$images.length; i++) {
      const img = $images[i];
      const url = img.getAttribute('xlink:href') + '?x-oss-process=style/thumb800';
      if (!this.base64map[url]) {
        this.base64map[url] = await getBase64fromImg(url)
      }

      img.removeAttribute('xlink:href')
      img.setAttribute('href', this.base64map[url])
    }

    $svg.removeAttr('xmlns:xlink').removeAttr('style')
      .attr('x', this.props.x)
      .attr('y', this.props.y)
      .attr('width', this.props.width)
      .attr('height', this.props.height)

    return `<g>${$svg[0].outerHTML}</g>`
  }
}
