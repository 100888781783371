import { Inject, Pipe, PipeTransform, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Pipe({
  name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  transform(value: string, ...args: any[]): any {
    let resize = '';
    let formatStr = '';
    let [width, format] = args;

    if (width > 0)
      resize = '/resize,w_' + width;

    if (format)
      formatStr = '/format,' + format;

    if (isPlatformBrowser(this.platformId)) {
      if (navigator && navigator.userAgent.toLowerCase().indexOf('chrome') === -1)
        formatStr = '';
    }

    if ((resize || formatStr) && value && value.length > 0 && value.indexOf('.svg') === -1)
      return value + '?x-oss-process=image' + resize + formatStr;
    else
      return value;
  }
}
