import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderService } from '@libs/core/src/lib/services/post-order/order.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
	selector: 'cut-payment',
	templateUrl: 'cut-payment.component.html',
	styleUrls: ['./cut-payment.component.scss']
})

export class CutPaymentComponent implements OnInit {
  @Input() id:any;
  @Input() deduct:any;
  @Input() reason:any;
  @Input() deduct_order_id:any;
  validateForm: FormGroup;
  require = true;
  btnLoading = false;
	// deduct;
	// reason;
  constructor(private fb: FormBuilder, private order: OrderService, private message: MessageService, private modal: NzModalRef) {}
	submitForm(value:any) {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
		if (this.validateForm.invalid) {
			return
		}
    value.designer_id = this.id;
    this.btnLoading = true;
		this.order.deductOrder(value).subscribe(() =>{
      this.btnLoading = false;
      alert('操作成功');
      this.modal.close(true);
		}, (err: HttpErrorResponse) => {
      this.btnLoading = false;
      this.message.error(err);
    })
  }
  deductChange() {
    // 如果为修改退款
    if (this.deduct) {
      // 如果删除扣款，都不为必填
      if (this.validateForm.value.deduct == 0) {
        // this.validateForm.get('reason').setValidators(Validators.required);
        this.validateForm.get('deduct').setValidators(Validators.nullValidator);
        this.validateForm.get('reason').setValidators(Validators.nullValidator);
        this.validateForm.get('deduct_order_id').setValidators(Validators.nullValidator);
        // this.require = false;
        for (const i in this.validateForm.controls) {
          this.validateForm.controls[i].markAsDirty();
          this.validateForm.controls[i].updateValueAndValidity();
        }
      }
    }
  }
	ngOnInit() {
		this.validateForm = this.fb.group({
			deduct: [this.deduct, [Validators.required]],
      reason: [this.reason, [Validators.required]],
      deduct_order_id: [this.deduct_order_id, [Validators.required]]
    });
	}
}
