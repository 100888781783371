import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from '@libs/core/src/lib/services/post-order/admin.service';
import { map } from 'rxjs/operators';
import { DataProvider } from '@libs/core/src/lib/util/data-provider';
import { Env } from '@libs/core/src/lib/util/environment';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  constructor(private adminService: AdminService, public env: Env, public coop: CoopService, private modal: NzModalRef, private auth: AuthService, public router : Router) { }

  ngOnInit() {
  }

  handleCancel () {
    this.modal.close();
  }
}
