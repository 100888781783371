import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataTime'
})
export class DataTimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const date = new Date();
    date.setTime(value * 1000);
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const mm = m < 10 ? ('0' + m) : m;
    const d = date.getDate();
    const dd = d < 10 ? ('0' + d) : d;
    const h = date.getHours();
    const hh = h < 10 ? ('0' + h) : h;
    return y + '年' + m + '月' + d + '日';
  }

}
