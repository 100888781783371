import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { EditorService } from '../../../../../../../services/editor.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
@Component({
  selector: 'app-font-distance-tool',
  templateUrl: './font-distance-tool.component.html',
  styleUrls: ['./font-distance-tool.component.scss']
})
export class FontDistanceToolComponent implements OnInit {
  letterSpacing = 0;
  lineHeight = 0;
  element;
  constructor(
    public editor: EditorService,
    public message:MessageService
  ) { }

  ngOnInit() {
    this.element = this.editor.activeElements[0];
    if (this.element.type === 'text') {
      const $text = $(this.element.options.text);
      const letterSpacing = $text.css('letter-spacing');
      const lineHeight = $text.css('line-height');
      if (letterSpacing !== '') {
        this.letterSpacing = parseFloat(letterSpacing);
      }
      if (lineHeight !== '') {
        if (lineHeight.indexOf('%') === -1){
          this.lineHeight = (parseFloat(lineHeight) * 100 - 100) / 4;
        }else{
          this.lineHeight = (parseFloat(lineHeight) - 100) / 4;
        }
      }

      this.element.options.text = $text[0].outerHTML;
    } else if (this.element.type === 'textv2') {
      this.letterSpacing = this.element.options.letterSpacing || 0;
      this.lineHeight = this.element.options.lineHeight;
    }

  }
  /**
   * 字间距被修改
   * @param value 新字间距
   */
  onLetterSpacingChange(value) {
    value = parseFloat(value)
    if (value < -20){
      this.message.error('字间距最小为-20');
      return;
    }
    this.letterSpacing = value;

    if (this.element.type === 'text') {
      this.textSpaceChange(value)
    } else if (this.element.type === 'textv2') {
      this.textV2SpaceChange(value)
    }
  }

  textSpaceChange(value) {
    const eleItem = this.element;
    if (!eleItem.options.isNewText){
      eleItem.options.isNewText = true;
      eleItem.changeTextY();
    }
    if (eleItem.options.directWrite && sessionStorage.getItem('directWrite') != eleItem.options.directWrite) {
      eleItem.options.directWrite = sessionStorage.getItem('directWrite');
    }
    const $tempText = $(eleItem.options.text);
    $tempText.css('letter-spacing', value + 'px');
    eleItem.options.text = $tempText[0].outerHTML;
    // $('.text-edit').focus();
    // 单个文本编辑模式下,颜色修改针对选中区域
    if (eleItem.options.curve){
      const curveobj = eleItem.getCurvedText(eleItem.options.text, eleItem.props.width, eleItem.options.curve.dir);
      eleItem.options.curve.html = curveobj.html;
      eleItem.props.oHeight = eleItem.props.height = curveobj.height;
      eleItem.states.html = eleItem.toHTML();
    } else{
      eleItem.states.html = eleItem.toHTML();
    }
    setTimeout(()=>{
      const textInfo = eleItem.getTextBoxInfo(this.editor.zoom);
      if (eleItem.options.mode === 'h') {
        eleItem.props.height = textInfo.height;
        eleItem.props.oHeight = textInfo.height / eleItem.options.scale;
      } else {
        eleItem.props.width = textInfo.width;
        eleItem.props.oWidth = textInfo.width / eleItem.options.scale;
      }
    })
  }

  textV2SpaceChange(value) {
    const el = this.element;
    el.options.letterSpacing = value;
    el.states.html = el.toHTML();
    const scale = el.props.width / el.props.oWidth;
    el.options.fontSize = (el.options.fontSize * scale);
    el.options.strokeWidth *= scale;
    // 获取bbox
    const bbox = el.getBBox();
    el.props.width = el.props.oWidth = bbox.width;
    el.props.height = el.props.oHeight = bbox.height;

    el.emit('modified', {
      type: 'letterSpacing',
      target: el,
      stopPropagation: this.editor.activeElements.length > 1
    });
  }
  /**
   * 行距被修改
   * @param value 新行距
   */
  onLineHeightChange(value) {
    value = parseFloat(value)
    if (value < -10) {
      this.message.error('行距最小为-10');
      return;
    }
    this.lineHeight = value;

    if (this.element.type === 'text') {
      this.textLineHeightChange(value)
    } else {
      this.textV2LineHeightChange(value)
    }
  }

  textLineHeightChange(value) {
    const lineHeightValue = (value * 4) + 100;

    const eleItem = this.editor.activeElements[0];
    if (!eleItem.options.isNewText){
      eleItem.options.isNewText = true;
      eleItem.changeTextY();
    }
    if (eleItem.options.curve) return;
    //单行文字不能修改行距
    if (eleItem.options.mode === 'd') return;
    const $tempText = $(eleItem.options.text);
    $tempText.css('line-height', lineHeightValue + '%');
    // 修改文字顶部
    // const fontSize = parseFloat($tempText.css('font-size'));
    // $tempText.css('margin-top', (((1 - lineHeightValue) / 100) * fontSize / 2 + (fontSize / 2)) + 'px');
    $tempText.css('margin-top', '');
    eleItem.options.text = $tempText[0].outerHTML;
    // $('.text-edit').focus();
    eleItem.states.html = eleItem.toHTML();
    setTimeout(()=>{
      const textInfo = eleItem.getTextBoxInfo(this.editor.zoom);
      if (eleItem.options.mode === 'h') {
        eleItem.props.height = textInfo.height;
        eleItem.props.oHeight = textInfo.height / eleItem.options.scale;
      } else {
        eleItem.props.width = textInfo.width;
        eleItem.props.oWidth = textInfo.width / eleItem.options.scale;
      }
    })
  }

  textV2LineHeightChange(value) {
    const el = this.element;
    el.options.lineHeight = value;
    el.states.html = el.toHTML();
    const scale = el.props.width / el.props.oWidth;
    el.options.fontSize = (el.options.fontSize * scale);
    el.options.strokeWidth *= scale;
    // 获取bbox
    const bbox = el.getBBox();
    el.props.width = el.props.oWidth = bbox.width;
    el.props.height = el.props.oHeight = bbox.height;
    el.emit('modified', {
      type: 'lineHeight',
      target: el,
      stopPropagation: this.editor.activeElements.length > 1
    });
  }

  onEndChange() {
    this.editor.snapshot();
  }
}
