import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageSendService {
  private messageSubject = new Subject<string>();
  messageNew = this.messageSubject.asObservable();
  constructor(){}

  sendMessage(message:any){
    this.messageSubject.next(message)
  }


}
