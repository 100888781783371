import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { DesignParamService } from '@libs/core/src/lib/services/design-param.service';
import { TemplateItemComponent } from '@libs/shared/src/lib/components/template-item/template-item.component';
import { CreateTemplateComponent } from '@libs/shared/src/lib/components/create-template/create-template.component';
import { ClassifyLevel } from '@libs/core/src/lib/models/classify';
import { CoopService } from '@libs/core/src/lib/services/coop.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-list-query',
  templateUrl: './list-query.component.html',
  styleUrls: ['./list-query.component.scss']
})
export class ListQueryComponent implements OnInit, OnDestroy {
  @Input() favorite = 'normal';
  @Input() emptyTip = true;
  @Input() query;
  @Input() listPrev = false;
  @Input() loading = false;
  @Input() templateList: TemplateOfficial[];
  @Input() current: ClassifyLevel;

  @Output() action = new EventEmitter<{method: string, data: any}>();

  cell = TemplateItemComponent;
  //previous = [CreateTemplateComponent];
  previous = [];

  constructor(
    public designService: DesignParamService,
    public authService: AuthService,
    public coop: CoopService
  ) {
  }

  ngOnDestroy() {
    console.log('销毁了');
  }

  ngOnInit(): void {}

}
