import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppActions, AppActionTypes } from './app.actions';
import { TemplateMember } from '@libs/core/src/lib/models/template-member';
import { Member } from '@libs/core/src/lib/models/member';
import { User } from '@libs/core/src/lib/models/user';
import { Message } from '@libs/core/src/lib/models/message';

/**
 * Interface for the 'App' data used in
 *  - AppState, and
 *  - appReducer
 */
export interface AppData {
  // 当前版本
  version: string;
  // 团队ID
  teamId: number;
  // 菜单栏类型
  menu: string;
  // 菜单栏active状态
  categoryMenu: string;
  // Login
  loginType: string;
  loginParams: User;
  deleteTemplate: number;
  reductionTemplate: number;
  // Modal
  modalType: string;
  sideBar: boolean;
  modalChildType: string;
  modalParams: {
    template: TemplateMember | null;
    message: Message | null;
  };
  doneImport: boolean;
}

/**
 * Interface to the part of the Store containing AppState
 * and other information related to AppData.
 */
export interface AppState {
  readonly app: AppData;
}

export const initialState: AppData = {
  version: 'personal',
  teamId: 0,
  menu: 'member',
  categoryMenu: '',
  loginType: 'password',
  loginParams: null,
  modalType: '',
  modalChildType: '',
  deleteTemplate: 0,
  reductionTemplate: 0,
  doneImport: false,
  sideBar: false,
  modalParams: {
    template: null,
    message: null
  }
};

export function appReducer(state = initialState, action: AppActions): AppData {
  switch (action.type) {
    case AppActionTypes.PersonalAction:
      return {
        ...state,
        version: 'personal',
        teamId: 0
      };

    case AppActionTypes.TeamAction: {
      return {
        ...state,
        version: 'team',
        teamId: action.payload
      };
    }

    case AppActionTypes.MenuMember: {
      return {
        ...state,
        menu: 'member'
      };
    }
    case AppActionTypes.MenuOfficial: {
      return {
        ...state,
        menu: 'official'
      };
    }
    case AppActionTypes.MenuCategoryActive: {
      return {
        ...state,
        categoryMenu: action.payload
      };
    }
    case AppActionTypes.WechatLogin:
      return {
        ...state,
        loginType: 'wechat'
      };
    case AppActionTypes.PasswordLogin:
      return {
        ...state,
        loginType: 'password'
      };
    case AppActionTypes.MobileLogin:
      return {
        ...state,
        loginType: 'mobile'
      };
    case AppActionTypes.RegisterLogin:
      return {
        ...state,
        loginType: 'register'
      };
    case AppActionTypes.BindMobile:
      return {
        ...state,
        loginType: 'bindMobile',
        loginParams: action.payload
      };
    case AppActionTypes.ForgetPassword:
      return {
        ...state,
        loginType: 'forgetPassword'
      };
    case AppActionTypes.ResetPassword:
      return {
        ...state,
        loginType: 'resetpassword'
      };
    case AppActionTypes.ModifySuccess:
      return {
        ...state,
        loginType: 'modifysuccess'
      };
    case AppActionTypes.ModalOpenDiy:
      return {
        ...state,
        modalType: 'diy'
      };
    case AppActionTypes.ModalOpenImport:
      return {
        ...state,
        modalType: 'import',
        doneImport: false
      };
    case AppActionTypes.ModalOpenTeam:
      return {
        ...state,
        modalType: 'team'
      };
    case AppActionTypes.ModalOpenNotice:
      return {
        ...state,
        modalType: 'notice'
      };
    case AppActionTypes.ModalOpenNoticeDetail:
      return {
        ...state,
        modalType: 'notice-detail',
        modalParams: {
          ...state.modalParams,
          message: action.payload
        }
      };
    case AppActionTypes.ModalOpenAccount:
      return {
        ...state,
        modalType: 'account',
        modalChildType: action.payload
      };
    case AppActionTypes.ModalOpenTemplatePreview:
      return {
        ...state,
        modalType: 'memberPreview',
        modalChildType: '',
        modalParams: {
          ...state.modalParams,
          template: action.payload
        }
      };
    case AppActionTypes.ModalClose:
      return {
        ...state,
        modalType: '',
        modalChildType: ''
      };
    case AppActionTypes.DeleteTemplate:
      return {
        ...state,
        deleteTemplate: action.payload
      };
    case AppActionTypes.ReductionTemplate:
      return {
        ...state,
        reductionTemplate: action.payload
      };
    case AppActionTypes.DoneImport:
      return {
        ...state,
        doneImport: true
      };
    case AppActionTypes.SideBarNot:
      return {
        ...state,
        sideBar: false
      };
    case AppActionTypes.SideBarHas:
      return {
        ...state,
        sideBar: true
      };
    default:
      return state;
  }
}

export const selectAppState = createFeatureSelector<AppData>('app');
export const selectAppMenu = createSelector(
  selectAppState,
  (state: AppData) => state.menu
);
export const selectAppVersion = createSelector(
  selectAppState,
  (state: AppData) => state.version
);
export const selectAppTeamId = createSelector(
  selectAppState,
  (state: AppData) => state.teamId
);
export const selectAppCategory = createSelector(
  selectAppState,
  (state: AppData) => state.categoryMenu
);

export const selectLoginType = createSelector(
  selectAppState,
  (state: AppData) => state.loginType
);

export const selectLoginParams = createSelector(
  selectAppState,
  (state: AppData) => state.loginParams
);

export const selectModalType = createSelector(
  selectAppState,
  (state: AppData) => state.modalType
);

export const selectModalParams = createSelector(
  selectAppState,
  (state: AppData) => state.modalParams
);

export const selectModalChildType = createSelector(
  selectAppState,
  (state: AppData) => state.modalChildType
);

export const selectDeleteTemplate = createSelector(
  selectAppState,
  (state: AppData) => state.deleteTemplate
);

export const selectReductionTemplate = createSelector(
  selectAppState,
  (state: AppData) => state.reductionTemplate
);

export const selectDoneImport = createSelector(
  selectAppState,
  (state: AppData) => state.doneImport
);

export const selectSideBarState = createSelector(
  selectAppState,
  (state: AppData) => state.sideBar
);
