import { Component, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Classify, ClassifyLevel } from '@libs/core/src/lib/models/classify';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateService } from '../template/services/template.service';
import { RouterUrlService } from '@libs/core/src/lib/services/router-url.service';
import { TemplateListCallback } from '@libs/core/src/lib/models/list.interface';
import { DataProvider } from '@libs/core/src/lib/util/data-provider';
import { HttpClient } from '@angular/common/http';
import { ClassifyService } from '@libs/core/src/lib/services/template/classify.service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { ModalService } from '@libs/core/src/lib/services/modal.service';


@Component({
  selector: 'app-linklist',
  templateUrl: './linklist.component.html',
  styleUrls: ['../spec-list/spec-list.component.scss']
})
export class LinklistComponent implements OnInit {

  listPrev: boolean;
  callback;
  current: ClassifyLevel;
  query: any;
  loading = false;
  //列表类型water:multi-column\list:flex
  cid: string;
  dataProvider = new DataProvider<TemplateListCallback>();

  classifyList:any

  paramsList
  classifyName
  showInfoList
  className

  constructor(
    public routerUrlService: RouterUrlService,
    public coop: CoopService,
    public modal: ModalService,
    private routeInfo: ActivatedRoute,
    private router: Router,
    private templateService: TemplateService,
    private classify: ClassifyService,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.getTemList()
  }

  getTemList () {
    this.paramsList = this.routeInfo.snapshot.queryParams
    this.http.get('/cooperation/template?query='+'key-'+ this.paramsList.key+'-l-28')
      .subscribe((data:any)=>{
        this.showInfoList = data.data.tplData
        this.classifyName =  this.showInfoList[0].parentName;
        this.className = this.showInfoList[0].classify.name
        this.current = this.classify.products.find(item => item.pid == this.showInfoList[0].classifyPid)
      })
  }

  ngOnDestroy(): void {}
}
