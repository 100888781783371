import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-nologin-save',
  templateUrl: './nologin-save.component.html',
  styleUrls: ['./nologin-save.component.scss']
})
export class NologinSaveComponent implements OnInit {
  url: string;
  constructor(
    private message: MessageService,
    @Inject(DOCUMENT)
    private document: Document,
    private clipboard: ClipboardService,
  ) { }

  ngOnInit() {
    this.url = window.location.href;
  }

  collect() {
    if (window.external && window.external['addFavorite']) {
      window.external['addFavorite'](window.location.href, this.document.title);
      this.message.success('收藏成功');
    } else if (window['sidebar'] && window['sidebar']['addPanel']) {
      window['sidebar']['addPanel'](this.document.title, window.location.href,'');
      this.message.success('收藏成功');
    } else {
      this.message.error("加入收藏夹失败，请使用Ctrl+D快捷键进行添加操作!");
    }
  }

  copy () {
    this.clipboard.copyFromContent(this.url);
    this.message.success('复制成功!');
  }
}
