import { MenuItem } from '../../../models/ui/menu-item';
import { UserInterfaceItem } from '../../../models/base/user-interface-item';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener
} from '@angular/core';
import { Menu } from '../../../models/ui/menu';
import { fadeInOut } from '../../../../../../core/src/lib/animations/animations';

@Component({
  selector: 'app-t-menu',
  templateUrl: './t-menu.component.html',
  styleUrls: ['./t-menu.component.scss'],
  animations: [fadeInOut]
})
export class TMenuComponent implements OnInit, OnDestroy {
  @Input() item: UserInterfaceItem;
  @ViewChild('menuRef') menuRef: ElementRef;
  private childrenMenu: Menu;
  private nowChildren;
  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      const offsetX = Math.max(
        0,
        this.menuRef.nativeElement.getBoundingClientRect().right -
        window.innerWidth
      ),
        offsetY = Math.max(
          0,
          this.menuRef.nativeElement.getBoundingClientRect().bottom -
          window.innerHeight
        );
      this.item.params.x -= offsetX;
      this.item.params.y -= offsetY;
    }, 1);
  }
  ngOnDestroy(): void {
    if (this.childrenMenu) {
      this.item.parent.remove(this.childrenMenu);
    }
  }
  /**
   * 事件:菜单项目被按下
   * @param {MenuItem} menuItem
   */
  onMenuItemMouseDown(menuItem: MenuItem): void {
    if (menuItem.fn) {
      menuItem.fn();
      // 关闭所有菜单
      this.item.parent.clear('menu')
    }
  }
  /**
   * 事件:菜单项目鼠标进入
   * @param item 菜单项目
   * @param event 鼠标事件
   */
  onMenuItemMouseEnter(item: MenuItem, event): void {
    if (this.nowChildren !== item.children && this.childrenMenu) {
      this.item.parent.remove(this.childrenMenu);
      this.childrenMenu = undefined;
      this.nowChildren = undefined;
    }
    if (item.children.length > 0 && !this.nowChildren) {
      this.nowChildren = item.children;
      const bbox = event.currentTarget.getBoundingClientRect();
      this.childrenMenu = this.item.parent.showMenu({
        x: bbox.right + 5,
        y: bbox.top - 5,
        items: item.children
      });
    }
  }

  /**
   * 事件:窗口左键单击
   */
  @HostListener('document:mousedown', ['$event']) onWindowMouseDown(): void {
    this.item.parent.remove(this.item);
  }
  /**
   * 事件:菜单主体被按下
   * @param event 事件对象
   */
  onMenuMouseDown(event: MouseEvent): void {
    event.stopPropagation();
  }
}
