import { Menu } from '../models/ui/menu';
import { UserInterfaceItem } from '../models/base/user-interface-item';
import { Injectable } from '@angular/core';

@Injectable()
/**
 * UI管理服务
 */
export class UserInterfaceService {
  // id计数器
  private idCounter = 0;

  // UI组件状态(显示隐藏等)
  public store = {
    // 左侧资源选项卡组件
    sourceTab: {
      show: true
    },
    // 舞台状态
    stage: {
      containerBgColor: '#c7c7e6',
      showRuler: false,
      showGrid: false,
      showBleed: true,
      showRulerGuider: true,
      lockRulerGuider: false,
      autoGuide: true
    }
  };
  // 当前获取焦点的UI
  public activeUI: UserInterfaceItem = null;

  // 动态创建组件列表
  public userInterfaceList: UserInterfaceItem[] = [];
  showMenu({ x = 0, y = 0, items }): any {
    items = items.filter(item => item);
    const menu = new Menu({ x, y, items });
    this.add(menu);
    return menu;
  }

  /**
   * 添加UI项目到容器
   * @param item UI项目
   */
  add(item: UserInterfaceItem): number {
    this.idCounter++;
    item.id = this.idCounter;
    item.parent = this;
    this.userInterfaceList.push(item);
    this.setActiveUI(item);
    return item.id;
  }
  /**
   * 从UI容器销毁该项目
   * @param item UI项目
   */
  remove(item: UserInterfaceItem): void {
    this.setActiveUI(null);
    this.userInterfaceList = this.userInterfaceList.filter(arrItem => arrItem !== item);
  }

  /**
   * 清除某个类型的弹框
   * @param type
   */
  clear(type?): void {
    this.setActiveUI(null);
    let list = [];
    if (type) {
      list = this.userInterfaceList.filter(item => item.type !== type);
    }
    this.userInterfaceList = list;
  }

  clearReference(type?) {
    this.setActiveUI(null);
    let list = [];
    if (type) {
      list = this.userInterfaceList.filter(item => item.params.modelType !== type);
    }
    this.userInterfaceList = list;
  }
  /**
   * 设置焦点UI
   * @param item ui项目
   */
  setActiveUI(item: UserInterfaceItem): void {
    if (this.activeUI === item) {
      return;
    }
    this.activeUI = item;
  }
}
