import { Component, ElementRef, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import * as Color from 'color';
import { RouterUrlService } from '@libs/core/src/lib/services/router-url.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    RouterUrlService
  ]
})
export class SearchComponent implements OnInit, OnDestroy {
  iscolor = false;
  @Input() type: string;
  @Input() url: string = '/template/list';
  @Input() clear = true;
  @Input() query = {};

  @Input() keywords: string;
  constructor(
    public coop: CoopService,
    private routerUrlService: RouterUrlService,
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnDestroy() {}

  ngOnInit() {
  }

  search(event?) {
    if (event && event.code !== 'Enter') return;
    this.routerUrlService.getUrlParam([{ name: this.type === 'search2' ? 'kb' : 'k', value: this.keywords },{ name: 'p', value: ''}], this.clear)
      .pipe(take(1))
      .subscribe(
        data => {
          this.router.navigate([this.url, data], {queryParams: this.query});
        }
      );
  }
}
