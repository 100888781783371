import { Injectable } from '@angular/core';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { RouterUrlService } from '@libs/core/src/lib/services/router-url.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  apiTemplateList, apiTemplateMenu,
  apiTemplateOfficial,
  apiMemberTemplate,
  apiTemplateRecommend, apiTemplateSize
} from '@libs/core/src/lib/util/api';
import { TemplateOfficialService } from '@libs/core/src/lib/services/template/template-official.service';
import { DataProvider } from '@libs/core/src/lib/util/data-provider';
import { Observable } from 'rxjs';
import { TitleService } from '@libs/core/src/lib/services/title.service';
import { TemplateListCallback } from '@libs/core/src/lib/models/list.interface';
import { Classify } from '@libs/core/src/lib/models/classify';
import { Tags } from '@libs/core/src/lib/models/tags';
import { Adv } from '@libs/core/src/lib/models/adv';
import { RecommendTem } from '@libs/core/src/lib/models/recommendTem';
const apitemTag = '/template-label';
const apiDiyTop = '/cooperation/homepage-top';
//const apiClassify = 'http://192.168.0.251:8008/v1/cooperation/homepage-hot-classify';
const apiClassify = '/cooperation/homepage-hot-classify';
const apiallClassify = '/cooperation/product';
const apiAdv = '/cooperation/homepage-adv';
//const apiRecommendTem = 'http://192.168.0.251:8008/v1/cooperation/homepage-relevant-template ';
const apiRecommendTem = '/cooperation/homepage-relevant-template';

@Injectable()
export class TemplateService {
  dataProvider = new DataProvider<TemplateListCallback[]>();
  constructor(
    private template: TemplateOfficialService,
    private http: HttpClient,
    private toastr: NzMessageService,
    private routerUrlService: RouterUrlService,
    private titleService: TitleService
  ) {}

  /**
   * 查询模版列表
   * @param {() => void} cb
   * @returns {Observable<TemplateOfficial[]>}
   */
  getTemplateList(params): Observable<HttpResponse<TemplateListCallback>> {
    return this.http.get<TemplateListCallback>(
      apiTemplateList,
      {
        params: {query: params},
        observe: 'response'
      }
      );
  }

  getTemplateMenu(params?): Observable<any> {
    return this.http.get<any>(apiTemplateMenu, {params});
  }

  getTemplateSize(params): Observable<Classify[]> {
    return this.http.get<Classify[]>(apiTemplateSize, {params});
  }

  /**
   * 获取官方模版详情
   * @param id
   * @returns {Observable<TemplateOfficial>}
   */
  getTemplateInfo(id): Observable<TemplateOfficial> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('expand', 'category,classify,isBuy,member,isFavorite,pages,content,setInfo');
    return this.http.get<TemplateOfficial>(apiTemplateOfficial + '/' + id, {
      params: httpParams
    });
  }


    /**
   * 获取商户模版详情
   * @param id
   * @returns {Observable<TemplateOfficial>}
   */
    getTemplateInfo2(data): Observable<any> {
      let httpParams = {} as any;
      httpParams.content = JSON.stringify(data.content);
      httpParams.save_type = 1;
      httpParams.edit_from = data.templateId;
      httpParams.title = data.title;
      httpParams.classify_id = data.classifyId;
      let url = 'https://192.168.0.190:8008/template-member'
      //httpParams = httpParams.set('expand', 'content,editConfig');
      return this.http.post<any>(url,httpParams);
    }

  /**
   * 获取模版的相关推荐
   * @param id
   * @returns {Observable<TemplateOfficial[]>}
   */
  getRecommend(id): Observable<TemplateOfficial[]> {
    return this.http.get<TemplateOfficial[]>(apiTemplateRecommend + '/' + id);
  }

   //查询模版标签
   temTags(params): Observable<Tags[]> {
    return this.http.get<Tags[]>(apitemTag, { params: params });
  }

  //商户定制首页头部
  diyHead(): Observable<any> {
    return this.http.get<any>(apiDiyTop);
  }

  //商户定制热门品类
  hotClassify(): Observable<Classify[]> {
    return this.http.get<Classify[]>(apiClassify);
  }

  //商户定制广告
  advDiy(params): Observable<Adv[]> {
    return this.http.get<Adv[]>(apiAdv, { params: params });
  }

  //商户定制推荐模版
  recommendTem(): Observable<RecommendTem[]> {
    return this.http.get<RecommendTem[]>(apiRecommendTem);
  }

}
