import { Component, forwardRef, Inject, OnDestroy, OnInit,Input, } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { DesignParamService } from '@libs/core/src/lib/services/design-param.service';
import { Env } from '@libs/core/src/lib/util/environment';
import { Store } from '@ngrx/store';
import { AppState } from 'apps/frontend/src/app/+state/app.reducer';
import { ModalClose } from 'apps/frontend/src/app/+state/app.actions';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { HttpClient } from '@angular/common/http';
import { UploadService } from '@libs/core/src/lib/services/upload.service';
import { NzUploadFile, UploadFilter } from 'ng-zorro-antd/upload';
import { Subject,Subscription } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';
import { ToastIcon } from '@libs/modal/src/lib/models/toast-icon';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';


@AutoUnsubscribe()
@Component({
  selector: 'app-diy-designer',
  templateUrl: './diy-designer.component.html',
  styleUrls: ['./diy-designer.component.scss']
})
export class DiyDesignerComponent implements OnInit, OnDestroy {
  @Input() products;
  iscolor = false;
  diyTitle: string;
  selectedCategory = '';
  selectedSize = '';
  svgList$ = new Subject();
  svgList: [];
  fileList: any[];
  uploadSubscription: Subscription;
  uploadLoading = false;
  loading = false;
  showSize = false;
  categoryData = [];
  sizeData: { [place: string]: any[] } = {
  };
  defaultEditData =[];
  item = 1;
  // 文件上传过滤
  psdUploadFilter: UploadFilter[] = [{
    name: 'psd', fn: files => {
      return files.filter(file => {
        const result = file.name.toLowerCase().indexOf('.psd') > 0;
        if (!result)
          this.message.error('不允许的上传文件');
        return result;
      });
    }
  }];
    // 文件上传过滤
  svgUploadFilter: UploadFilter[] = [{
    name: 'svg', fn: files => {
      return files.filter(file => {
        const result = file.name.toLowerCase().indexOf('.svg') > 0;
        if (!result)
          this.message.error('不允许的上传文件');
        return result;
      });
    }
  }];
  uploadContent;
  template;
  isSpinning = false;


  constructor(
    public coop: CoopService,
    public modal: NzModalRef,
    public ModalService: ModalService,
    private message: MessageService,
    private designService: DesignParamService,
    private store: Store<AppState>,
    public env: Env,
    private http: HttpClient,
    private uploadService: UploadService,
    private notification: NzNotificationService

  ) {}

  ngOnInit(): void {
    this.products.forEach(item =>{
      this.categoryData.push({ label:item.classify.name , value: item.classify.classifyId});
      this.defaultEditData.push({ defaultEdit:item.classify.defaultEdit, key: item.classify.classifyId});
      if(item.children.length){
        let arr = []  
        item.children.forEach(element => {
          arr.push({ label:element.name , value: element.classifyId});
          this.defaultEditData.push({ defaultEdit:element.defaultEdit, key:element.classifyId});
        });
        this.sizeData[item.classify.classifyId] = arr;
      }
    })
    this.selectedCategory = this.categoryData[0].value;
    this.provinceChange(this.selectedCategory);
  }
  provinceChange(value: string): void {
    if(this.sizeData[value] && this.sizeData[value].length){
      this.selectedSize = this.sizeData[value][0].value;
      this.showSize = true;
    }else {
      this.selectedSize = '';
      this.showSize = false;
    }
    
  }
  resetParam() {
    this.selectedCategory = '';
    this.selectedSize = '';
    this.uploadService.uploadFileKey = [];
    this.uploadContent = null;
    this.fileList = [];
  }
  fileUpload = item => {
    return this.uploadService.uploadDesigner(item);
  };

  uploadFont(type: string,info: NzUploadChangeParam) {
    if (info.file.status === 'uploading') {
      this.uploadLoading = true;
      return;
    }
    if (info.file.status === 'done'&& info.type === 'success') {
      this.uploadLoading = false;
      let fileList = [...info.fileList];
      if (type === 'file') {
        fileList = fileList.map(file => {
          if (file.response) {
            file.name = file.originFileObj.name;
            file.url = file.response.url;
          }
          return file;
        });
        this.fileList  = fileList;
      }
    }
  }
  async windowOpen(type:string){
    if(!this.checkout()) return;
    this.isSpinning = true;
    let uploadUrl = '';
    if (type === 'psd') {
      uploadUrl = '/soft-ware-use/upload-psd-file';
    } else {
      uploadUrl = '/soft-ware-use/upload-svg-file'
    }

    const promiseArray = [];
    this.uploadService.uploadFileKey.forEach( (item,index) => {
      let promise = this.http.post(uploadUrl, {
        filename: this.uploadService.uploadFileKey[index]
      }).toPromise();
      promiseArray.push(promise);
    })
    const pages = [];
    const props = {} as any;
    let prop = this.defaultEditData.filter(defaultEdit => {
      return defaultEdit.key == this.selectedCategory;
    })[0];
    if (this.selectedSize) {
      prop = this.defaultEditData.filter(defaultEdit => {
        return defaultEdit.key == this.selectedSize;
      })[0];
    }
    props.bleed = prop.defaultEdit.bleed ? parseFloat(prop.defaultEdit.bleed) : 0;
    props.height = prop.defaultEdit.height ? parseFloat(prop.defaultEdit.height) : 0;
    props.splitLine = prop.defaultEdit.split ? parseFloat(prop.defaultEdit.split) : 0;
    props.unit = prop.defaultEdit.unit ?  prop.defaultEdit.unit : 'mm';
    props.usedColors = [];
    props.width = prop.defaultEdit.width ? parseFloat(prop.defaultEdit.width) : 0 ;
    let flag  = false;
    await Promise.all(promiseArray).then(results => {
      // 所有promise都成功完成时执行的代码
      flag = true
      results.forEach((page,index) => {
        page.pages[0].id = index + 1;
        page.pages[0].elements.forEach(element => {
          element.props.width *= 25.4 / 300;
          element.props.height *= 25.4 / 300;
          element.props.x *= 25.4 / 300;
          element.props.y *= 25.4 / 300;
        })
        pages.push(page.pages[0])
      });
      let uploadContent = {
        pages:pages,
        props:props
      }
      this.uploadContent = JSON.stringify(uploadContent)
    }).catch(error => {
      // 任何一个promise失败时执行的代码
      this.isSpinning = false;
      this.message.error(error.error.errorMessage)
      return
    });
    if(flag) {
      await this.http.post('/template-member', {
        classify_id: this.selectedSize?this.selectedSize:this.selectedCategory,
        content: this.uploadContent,
        is_diy: 1,
        save_type: 1,
        title: this.diyTitle,
      }).toPromise().then(res => {
         this.template = res ;
      })
      this.isSpinning = false;
      let newWindow = window.open();
      let url = this.designService.getMemberUpdateUrl(this.template.templateId,this.template);
      newWindow.location.href = url;
      this.message.success('创建成功');
      this.modal.close();
    } else {
      this.isSpinning = false;
      this.message.error('创建失败');
    }


  }
  ngOnDestroy(): void {}

  close(): void {
    this.modal.close();
  }
  createDiy() {
    if(!this.checkout()) return;
    const params = {
      title:this.diyTitle,
      classify_id: this.selectedCategory
    };
    if (this.selectedSize) {
      params.classify_id = this.selectedSize
    }
    this.http.post('/template-member', params).subscribe(
      res => {
        const data = res as any;
        let newWindow = window.open();
        let url = this.designService.getMemberUpdateUrl(data.templateId,data);
        newWindow.location.href = url;
        this.message.success('添加成功');
        this.modal.close();
      },
      err => {
        this.message.error(err)
      }
    )
  }

  checkout(){
    if(!this.diyTitle){
      this.message.error('请输入模板名称');
      return false
    }
    if(!this.selectedCategory){
      this.message.error('请选择模板品类');
      return false
    }
    if(this.selectedCategory && this.showSize &&!this.selectedSize){
      this.message.error('请选择模板尺寸');
      return false
    }
    if(this.item === 0 && (!this.uploadService.uploadFileKey || !this.uploadService.uploadFileKey.length)) {
      this.message.error('请上传PSD文件');
      return false
    }
    if(this.item === 2 && (!this.uploadService.uploadFileKey || !this.uploadService.uploadFileKey.length)) {
      this.message.error('请上传SVG文件');
      return false
    }
    return true
  }

}
