import { Injectable } from '@angular/core';
import { Classify, ClassifyConfig, ClassifyLevel } from '@libs/core/src/lib/models/classify';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { apiClassify, apiTemplateMenu } from '@libs/core/src/lib/util/api';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClassifyService {
  products: ClassifyLevel[];
  no_required_query = ['width', 'height', 'bleed', 'firstPageWidth', 'firstPageHeight', 'firstPageSpine', 'firstPageGroove', 'firstPageOverlapping', 'banEndPage', 'splitCover', 'splitInside', 'bindPosition', 'insideBackCover', 'addPage', 'resize', 'page'];

  constructor(private http: HttpClient) {}

  loadProduct(data: ClassifyLevel[]) {
    data.forEach((item, i) => {
      if (item.classifyConfig) {
        if (item.classifyConfig.length === 1) return
        const expandLen = item.classifyConfig.filter(val => val.status == 1 && val.expand == 0);
        item.classifyConfig = item.classifyConfig
          .filter(val => val.status == 1 && (val.classifyId != val.classifyPid || val.expand == 0 || expandLen.length))
        /*item.classifyConfig.forEach(val => {val.query = this.sizeQuery(val)})*/
      }
    });
    this.products = data;
  }

  sizeQuery(temp: ClassifyConfig): {[x: string]: string} {
    let query = {};
    this.no_required_query.forEach(val => {
      if (temp[val]) query[val] = temp[val];
    });
    return query;
  }

  /**
   * 开启设计页的数据
   * @returns {Observable<Classify[]>}
   */
  getTemplateCenterList(): Observable<ClassifyLevel[]> {
    return this.http.get<ClassifyLevel[]>(apiTemplateMenu, {headers: {"Access-No-Login": "10"}});
  }

  /**
   * 模板分类列表
   * @returns {Observable<Classify[]>}
   */
  list(
    params: { category: string; pid: string } | {} = {}
  ): Observable<Classify[]> {
    return this.http.get<Classify[]>(apiClassify, { params: params });
  }

  synClassify(params): Observable<any> {
    return this.http.post<any>('/classify/distribute-classify', params);
  }

  detail(id: number, expand?): Observable<Classify> {
    const expandParams = {};
    if (expand)  expandParams['expand'] = expand;
    return this.http.get<Classify>(apiClassify + '/' + id, {params: expandParams});
  }

  /**
   * 新增
   * @param {Classify} params
   * @returns {Observable<Classify>}
   */
  create(params: Classify): Observable<Classify> {
    if (params.classifyId)
      return this.http.put<Classify>(
        apiClassify + '/' + params.classifyId,
        params
      );
    else return this.http.post<Classify>(apiClassify, params);
  }

  /**
   * 删除
   * @param {Classify} item
   * @returns {Observable<any>}
   */
  delete(item: Classify): Observable<any> {
    return this.http.delete(apiClassify + '/' + item.classifyId);
  }

  getCrmPrint(params): Observable<any> {
    return this.http.get('/crm/member-print-classify', {params})
  }

  getCrmWord(params): Observable<any> {
    return this.http.get('/crm/keywords-list', {params})
  }

  getCrmClassify(params): Observable<any> {
    return this.http.get('/crm/classify-count-list', {params})
  }

  updateCrm(): Observable<any> {
    return this.http.get('/')
  }
}
