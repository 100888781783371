import { Component, OnInit } from '@angular/core';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';

@Component({
  selector: 'app-table-text-align-tool',
  templateUrl: './table-text-align-tool.component.html',
  styleUrls: ['./table-text-align-tool.component.scss']
})
export class TableTextAlignToolComponent implements OnInit {
  align = 'left';
  public eleItem;
  constructor(
    public editor:EditorService
  ) { }

  ngOnInit() {
    this.eleItem = this.editor.activeElements[0]
  }
  /**
   * 事件:对齐按钮被单击
   * @param type 对齐方式
   */
  onAlignClick(type) {
    this.align = type;
    let chooseItems = this.eleItem.getChooseItem();
    if (this.eleItem && chooseItems.length) {
      for (let item of chooseItems){
        item.style.textAlign = type;
      }
      this.editor.snapshot();
    }
  }
}
