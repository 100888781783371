import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-mama-order',
  templateUrl: './mama-order.component.html',
  styleUrls: ['./mama-order.component.css']
})
export class MamaOrderComponent implements OnInit {
  url;
  constructor(
    private routeInfo: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.routeInfo.params.pipe(
      map(data => {
        if (data.type === 'order') {
          const obj = this.routeInfo.snapshot.queryParams;
          let string = '';
          for (let i in obj) {
            let result = obj[i];
            if (i === 'UserToken') {
              result = decodeURIComponent(result)
            }
            string += `&${i}=${result}`;
          }
          return 'http://webmmzj.youdianpinwei.com/orders/OrderPayinfo.html' + string.replace(/&/, '?')
        } else if (data.type === 'guide') {
          return 'http://webmmzj.youdianpinwei.com/orders/making.html';
        }
      })
    ).subscribe(data => {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data);
    });
  }

}
