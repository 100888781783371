import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { OrderCommon } from '../../models/post-order.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  apiOrder = '/task-order';
  apiStatus = '/task-designer-order';
  apiOrderfile = '/task-designer-file';
  apiRest = '/task-order/reset';
  apiSettlement = '/task-order-settlement';
  apiParentOrder = '/task-parent-order';
  constructor(private http: HttpClient) { }

  addOrder(params) : Observable<OrderCommon> {
    return this.http.post<OrderCommon>(this.apiOrder, params);
  }

  orderList(params?): Observable<HttpResponse<OrderCommon>> {
    return this.http.get<OrderCommon>(this.apiOrder, { params, observe: 'response' });
  }

  orderDetail(id): Observable<any> {
    return this.http.get(`${this.apiOrder}/${id}`);
  }

  orderMark(params): Observable<any> {
    return this.http.post(`${this.apiOrder}/sub-mark`, params);
  }

  designerStatus(params): Observable<any> {
    return this.http.post(this.apiStatus, params);
  }

  designerList(params?): Observable<HttpResponse<OrderCommon>> {
    return this.http.get<OrderCommon>(this.apiStatus, { params, observe: 'response' });
  }

  delteOrderfile(id): Observable<any> {
    return this.http.delete(`${this.apiOrderfile}/${id}`);
  }

  resetOrder(params): Observable<any> {
    return this.http.post(this.apiRest, params);
  }

  editOrder(params): Observable<OrderCommon> {
    return this.http.put<OrderCommon>(`${this.apiOrder}/${params.id}`, params);
  }
  // 结算订单
  settlOrder(params?) : Observable<any> {
    return this.http.post(this.apiSettlement, params);
  }

  /**
   * 扣款
   * @param {number} designer_id 设计师id
   * @param {number} deduct 扣款金额
   * @param {string} reason 理由
  */
  deductOrder(params?) : Observable<any> {
    return this.http.post('/task-order-settlement/deduct', params);
  }

  settledList(params?): Observable<HttpResponse<OrderCommon>> {
    return this.http.get<OrderCommon>(this.apiSettlement, { params, observe: 'response' });
  }

  // 收入明等
  incomeList(params?): Observable<HttpResponse<OrderCommon>> {
    return this.http.get<OrderCommon>(this.apiStatus, { params, observe: 'response' });
  }

  // 检查设计师订单状态
  checkDesignerOrder(params?) : Observable<any> {
    return this.http.post('/task-designer-order/check', params);
  }

  // 下载设计师文件
  downLoad(params?) : Observable<any> {
    return this.http.post('/task-designer-file/download', params);
  }

  // 下载客户自带文件
  downLoadFile(params?): Observable<any> {
    return this.http.post('/task-order/download', params);
  }

  // 查询帮助
  helpList(params?): Observable<any> {
    return this.http.get<any>('/help-classify', { params });
  }

  // 帮助详情
  helpDetail(id): Observable<any> {
    return this.http.get<any>(`/help-article/${id}`);
  }

  // 添加关联客户信息
  addParentOrder(params?): Observable<any> {
    return this.http.post(this.apiParentOrder, params);
  }

  // 修改关联客户信息
  editParentOrder(params?): Observable<any> {
    return this.http.put<any>(`${this.apiParentOrder}/${params.id}`, params);
  }

  // 关联客户信息详情
  parentOrderDetail(id): Observable<any> {
    return this.http.get(`${this.apiParentOrder}/${id}`);
  }

  // 关联客户列表
  parentOrderList(params?): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.apiParentOrder, { params, observe: 'response' });
  }

  // 监听订单是否变动
  isOrderChange(id): Observable<any> {
    // return this.http.get(`/task-change-order/${id}`);
    return this.http.get(`/task-order-notice/${id}`);
  }

  // 修改订单变动
  editOrderChange(params?): Observable<any> {
    return this.http.put<any>(`/task-order-notice/${params.id}`, params);
  }

  // 查询单个设计师上传文件信息
  downloadHistroy(id): Observable<any> {
    return this.http.get(`/task-designer-file/${id}`);
  }
}
