import { Component, OnInit } from '@angular/core';
import { CoopService } from '@libs/core/src/lib/services/coop.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  year = new Date().getFullYear();

  constructor(
    public coop: CoopService
  ) { }

  ngOnInit() {
  }

  get isKeJieHost() {
    return window.location.host === 'www.020oa.com';
  }

}
