import {
  ComponentFactoryResolver,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { WaterfallItem } from '@libs/shared/src/lib/directives/waterfall-item.component';
import { ClassifyLevel } from '@libs/core/src/lib/models/classify';

@Directive({
  selector: '[appWaterfallItem]'
})
export class WaterfallItemDirective implements OnInit {
  @Input() template: TemplateOfficial;
  @Input() query: any;
  @Input() index: number;
  @Input() thumbnailData;
  @Input() current:ClassifyLevel;
  @Input() favorite = 'normal';
  @Input() comp;
  @Output() action = new EventEmitter<{method: string, data: any}>();

  constructor(
    private container: ViewContainerRef,
    private factory: ComponentFactoryResolver
  ) {
  }

  ngOnInit() {
    let component = this.factory.resolveComponentFactory<WaterfallItem>(this.comp);
    this.container.clear();
    let ref = this.container.createComponent<WaterfallItem>(component);
    ref.instance.template = this.template;
    ref.instance.query = this.query;
    ref.instance.index = this.index;
    ref.instance.thumbnailData = this.thumbnailData;
    ref.instance.favorite = this.favorite;
    ref.instance.current = this.current;
    ref.instance.delete.subscribe(data => this.action.emit({method: 'delete', data}));
  }
}
