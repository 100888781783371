import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TemplateDetailService {
  // 模版收藏接口
  favoriteUrl = '/my-favorite';

  constructor(private http: HttpClient) {}

  /**
   * 收藏模版
   * @param {number} templateId
   * @returns {Observable<boolean>}
   */
  favTemplate(templateId: number): Observable<boolean> {
    return this.http.post<boolean>(this.favoriteUrl, {
      template_id: templateId
    });
  }

  /**
   * 取消收藏
   * @param {number} templateId
   * @returns {Observable<boolean>}
   */
  unFavTemplate(templateId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.favoriteUrl + '/' + templateId);
  }
}
