const gregorianHoliday = {
  '1-1': '元旦',
  '2-14': '情人节',
  '3-8': '妇女节',
  '3-12': '植树节',
  '4-1': '愚人节',
  '4-22': '地球日',
  '5-1': '劳动节',
  '5-4': '青年节',
  '5-12': '护士节',
  '6-1': '儿童节',
  '7-1': '建党节',
  '8-1': '建军节',
  '9-10': '教师节',
  '10-1': '国庆节',
  '12-24': '平安夜',
  '12-25': '圣诞节'
};
const lunarHoliday = {
  '1-1': '春节',
  '1-15': '元宵节',
  '5-5': '端午节',
  '7-7': '七夕',
  '7-15': '中元节',
  '8-15': '中秋节',
  '9-9': '重阳节',
  '12-8': '腊八节',
  '12-23': '小年',
  '12-30': '除夕'
}
const monthSignData = {
  bigCNMonthList:['壹月','贰月','叁月','肆月','伍月','陆月','柒月','捌月','玖月','拾月','拾壹月','拾贰月'],
  CNMonthList:['一月','二月','三月','四月','五月','六月','七月','八月','九月','十月','十一月','十二月'],
  numberZeroMonthList:['01','02','03','04','05','06','07','08','09','10','11','12'],
  completeENMonthList:['January','February','March','April','May','June','July','August','September','October','November','December'],
  bigCompleteENMonthList:['JANUARY','FEBRUARY','MARCH','APRIL','MAY','JUNE','JULY','AUGUST','SEPTEMBER','OCTOBER','NOVEMBER','DECEMBER'],
  simpleENMonthList:['Jan.','Feb.','Mar.','Apr.','May.','Jun.','Jul.','Aug.','Sept.','Oct.','Nov.','Dec.']
}
export { gregorianHoliday,lunarHoliday,monthSignData }

