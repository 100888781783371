// 判断品类的正则 路由时用
export const categoryMenuReg = /^(printing|display|social|website|commerce|creativelife)$/i;
// 判断品类和分类的正则 路由时用
export const categoryClassifyMenuReg = /^\/(printing|display|social|website|commerce|creativelife)\/[\w{2}.+\-{0,1}]+\.html$/;
// 判断搜索是正则 路由时用
export const searchClassifyMenuReg = /^\/[\w{2}.+\-{0,1}]+\.html$/;
// 判断分类的正则 路由时用
export const classifyMenuReg = /[\w{2}.+\-{0,1}]+\.html.*?$/;

export const topicInfoMenuReg = /[\w{2}\d+\-{0,1}]+\.html$/;

export const templateInfoReg = /^\d+\.html?$/;
// 用户中心判断Url
export const memberAccountUrl = /\/member\/account\/(recharge-record|vip-record|invoice|invite|reset-password|reset-mobile)/i;

// 手机号正则
export const mobileReg = /^[1][3,4,5,7,8][0-9]{9}$/;
