import { Component, forwardRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { DesignParamService } from '@libs/core/src/lib/services/design-param.service';
import { Env } from '@libs/core/src/lib/util/environment';
import { Store } from '@ngrx/store';
import { AppState } from 'apps/frontend/src/app/+state/app.reducer';
import { ModalClose } from 'apps/frontend/src/app/+state/app.actions';
import { CoopService } from '@libs/core/src/lib/services/coop.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-diy',
  templateUrl: './diy.component.html',
  styleUrls: ['./diy.component.scss']
})
export class DiyComponent implements OnInit, OnDestroy {
  iscolor = false;
  diyTitle: string;
  width: number;
  height: number;
  unit = 'mm';
  arrayName = ['名片', 'DM宣传单', '海报', '展架', '不干胶', 'A4'];
  item = 0;
  it = 0;

  size = [
    { name: '自定义', width: 200, height: 200 },
    { name: 'A4', width: 210, height: 297 },
    { name: 'A5', width: 148, height: 210 },
    { name: 'A3', width: 297, height: 420 },
    { name: '名片', width: 56, height: 92 },
    { name: '宣传单', width: 216, height: 291 },
    { name: '易拉宝', width: 800, height: 2000 },
    { name: '工作证', width: 91, height: 131 }
  ];
  title: string;

  params = {
    width: 200,
    height: 200,
    unit: 'mm'
  };

  constructor(
    public coop: CoopService,
    public modal: NzModalRef,
    private message: MessageService,
    private designService: DesignParamService,
    private store: Store<AppState>,
    public env: Env
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {}

  close(): void {
    // this.store.dispatch(new ModalClose());
    this.modal.close();
  }

  setDiyParam(width: number, height: number, unit: string) {
    this.width = width;
    this.height = height;
    this.unit = unit;
  }

  createDiy() {
    const params = {
      width: this.width,
      height: this.height,
      unit: this.unit
    };
    let reg=/^\d+$/;
    if (!reg.test(params.width.toString()) || !reg.test(params.height.toString())) {
      this.message.info('请输入数字');
      return;
    }
    if ((params.width > 4000 || params.height > 4000) && params.unit === 'mm') {
      this.message.info('最大值不得超过4000毫米');
      return;
    }
    if ((params.width < 10 || params.height < 10) && params.unit === 'mm') {
      this.message.info('最小值为10毫米');
      return;
    }
    if ((params.width > 10000 || params.height > 10000) && params.unit === 'px') {
      this.message.info('最大值不得超过10000像素');
      return;
    }
    if ((params.width < 10 || params.height < 10) && params.unit === 'px') {
      this.message.info('最小值为10像素');
      return;
    }
    if (this.diyTitle)
    params[ 'title' ] = this.diyTitle;
    let newWindow = window.open();
    if (this.env.thirdParty !== '') {
      // window.location.href = this.designService.getMemberDiy(params);
      newWindow.location.href = this.designService.getMemberDiy(params);
    } else {
      const windowRef = window.open();
      // windowRef.location.href = this.designService.getMemberDiy(params);
      newWindow.location.href = this.designService.getMemberDiy(params);
      this.store.dispatch(new ModalClose());
    }
  }

  resetParam(item?) {
    if (this.it && item && this.it !== item) {
      this.it = item;
      const tmp = {
        ...{width: this.width, height: this.height, unit: this.unit}
      };
      this.setDiyParam(tmp.height, tmp.width, tmp.unit);
    } else {
      this.width = undefined;
      this.height = undefined;
      this.unit = 'mm';
    }
  }

  openDiy(): void {
    if (this.params.width > 2000 || this.params.height > 2000) {
      this.message.info('最大值不得超过2000毫米');
      return;
    }
    if (this.params.width < 1 || this.params.height < 1) {
      this.message.info('最小值为1毫米');
      return;
    }
    const windowRef = window.open();
    if (this.title)
      this.params[ 'title' ] = this.title;
    this.modal.close();
    windowRef.location.href = this.designService.getMemberDiy(this.params);
  }

}
