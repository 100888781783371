import { FormControl, FormGroup } from '@angular/forms';
// 定义一个密码组的验证方法
export function passwordValidator(controlGroup: FormGroup): any {
  // 获取密码输入框的值
  const password = controlGroup.get('password').value as FormControl;
  const passwordRepeat = controlGroup.get('password_repeat')
    .value as FormControl;
  return password === passwordRepeat
    ? null
    : { passwordValidator: { info: '两次密码输入不一致' } };
}
