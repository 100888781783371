import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TemplateService } from '../services/template.service';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { DesignParamService } from '@libs/core/src/lib/services/design-param.service';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';
import { RouterUrlService } from '@libs/core/src/lib/services/router-url.service';
import { ApiService } from '@libs/editor/src/lib/services/api.service';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { ModalService as Modal2Service } from '@libs/core/src/lib/services/modal.service';
import { ToastIcon } from '@libs/modal/src/lib/models/toast-icon';
import { Subscription,timer } from 'rxjs';
import { map, switchMap, take, tap  } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { TemplateItemComponent } from '@libs/shared/src/lib/components/template-item/template-item.component';




@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  temp: TemplateOfficial;
  templateList:TemplateOfficial[];
  query: any = this.routeInfo.snapshot.queryParams;
  isQuery = Object.keys(this.query).length === 0;
  downLoadId:number;
  chooseAdv = [];
  topSubscription: Subscription;
  //show = false;
  socket: Subscription;
  timer;
  setId;
  cell = TemplateItemComponent;
  constructor(
    private routeInfo: ActivatedRoute,
    public designService: DesignParamService,
    private templateService: TemplateService,
    public routerUrlService: RouterUrlService,
    private modal: ModalService,
    public coop: CoopService,
    public auth: AuthService,
    public modal2: Modal2Service,
    private message: NzMessageService,
    public http: HttpClient,


  ) { }

  ngOnInit() {
    this.routeInfo.params.pipe(
      switchMap(data => this.templateService.getTemplateInfo(data.id.replace('.html', '')))
    ).subscribe(data => {
      this.temp = data;
      if(this.temp && this.temp.price && this.temp.price !== 0) {
        this.temp.price = this.temp.price / 100 ;
      }
      if(this.temp && this.temp.setInfo && this.temp.setInfo.items.length) {
        this.setId = this.temp.setInfo.setId;
        this.templateList = [];
        this.temp.setInfo.items.forEach(item => {
          this.templateList.push(item.template)
        })
      }
      // this.templateService.getTemplateInfo2(this.temp).subscribe(data => {
      //   this.downLoadId = data.templateId;
      // })
      //console.log(this.temp)
    });
    this.topSubscription = this.templateService.diyHead()
    .subscribe(data => {
      if (data) {
        this.chooseAdv = data.linkContent ? JSON.parse(data.linkContent) : [];
      }
    });
  }

  /**
   * 会员下载
   */
  async vipDownload() {
    const template ={} as any;
    template.id = this.downLoadId;
    if (this.auth.isGuest) {
      this.modal2.loginModal();
      return;
    }
    if (!this.auth.user.isVip) {
      if (this.coop.coopInfo.id === '12864') {
        const loadingToast = this.modal.toast({
          icon: ToastIcon.LOADING,
          msg: '正在准备下载',
          duration: 0
        });
        try {
          // 保存模版
          if (this.auth.user.coopMemberInfo && this.auth.user.coopMemberInfo.check === 20) {
            this.modal2.showNoVipUser(template, 'kejie');
          } else {
            this.modal2.showNoVipUser(template);
          }
        } catch (e) {
          this.modal.toast({
            icon: ToastIcon.ERROR,
            msg: '下载失败,请检查重试'
          });
        } finally {
          loadingToast.close();
        }
      } else {
        this.modal2.showNoVipUser();
      }
    } else {
      const loadingToast = this.modal.toast({
        icon: ToastIcon.LOADING,
        msg: '正在准备下载',
        duration: 0
      });
      try {
        this.modal2.showVipDownload(template)
      } catch (e) {
        this.modal.toast({
          icon: ToastIcon.ERROR,
          msg: '下载失败,请检查重试'
        });
      } finally {
        loadingToast.close();
      }
    }
  }

  async copy(){
    try {
      await navigator.clipboard.writeText(this.temp.templateId.toString());
      this.message.success('模板编号已复制');
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  }

  /*edit() {
    if (this.temp.classify.pid === 2) {
      this.modal.cardMessage(true, this.temp.templateId);
    } else {
      window.open(this.designService.getMemberCreateUrl(this.temp.templateId, this.temp));
    }
  }*/


  templateEditor(template: TemplateOfficial): void {
    if(!this.auth.isGuest) {
      if(this.temp && this.temp.price > 0 && !this.temp.isBuy) {
        this.message.info('请先购买模板!');
        return
      }
      // if(this.setId) {
      //   this.http
      //   .post<any>('/set-template-member', {
      //     set_id: template.setInfo.setId
      //   })
      //   .subscribe(data => {
      //     let temp = data.items.filter(item => item.templateOfficialId === template.templateId)
      //     let newWindow = window.open();
      //     let url = this.designService.getMemberUpdateUrl(temp[0].templateMemberId, temp[0].templateMember);
      //     newWindow.location.href = url;
      //   });
      // } else {
      let newWindow = window.open();
      let url = this.designService.getMemberCreateUrl(template.templateId, template);
      newWindow.location.href = url;
      newWindow.onload = function() {
        newWindow.scrollTo(0, 0);
      };
    } else {
      this.modal2.loginModal();
    }
  }

  showWechatQrcode(template: TemplateOfficial) {
    this.http
    .post<any>('/buy-template', {
      payment_name: 'wechat',
      template_id: template.templateId
    })
    .subscribe(data => {
      this.modal2.showWechatQrcode(data.wechatUrl);
      this.loopGet(data.orderSn);
      this.timer = setTimeout(()=>{
        clearTimeout(this.timer);
        this.loopGetWechat(data.orderSn);
      }, 300000)
    });
  }

  buyAll(){
    this.http
    .post<any>('/buy-set-template', {
      payment_name: 'wechat',
      set_id: this.setId
    })
    .subscribe(data => {
      this.modal2.showWechatQrcode(data.wechatUrl);
      this.loopGet(data.orderSn);
      this.timer = setTimeout(()=>{
        clearTimeout(this.timer);
        this.loopGetWechat(data.orderSn);
      }, 300000)
    });
  }

  wechactAddListener() {
    if ((this.socket && !this.socket.closed))
      return false;

    this.socket = timer(3000, 3000)
      .pipe(take(600))
      .subscribe(data => {
        //this.list$.next(this.currentFolder.id);
      });
  }

  loopGet(orderSn) {
    try{
      this.http.get<any>('/buy-template/'+ orderSn).subscribe({
        next: data => {
          if(data) {
            this.modal2.closeWechat();
            this.temp.isBuy = 1;
            clearTimeout(this.timer);
          }
        },
        error: err => {
          this.timer=setTimeout(()=>{
            clearTimeout(this.timer);
            this.loopGet(orderSn);
          }, 4000)
        }
      });
    } catch(error) {
      throw '报错'+error
    }

  }

  loopGetWechat(orderSn) {
    try{
      this.http.get<any>('/buy-template/query/'+ orderSn).subscribe({
        next: data => {
          if(data && data === true) {
            this.modal2.closeWechat();
            this.temp.isBuy = 1;
          }
        },
        error: err => {
        }
      });
    } catch(error) {
      throw '报错'+error
    }
  }

}
