import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve, Router,
  RouterStateSnapshot
} from '@angular/router';
import { TemplateService } from './template.service';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TemplateInfoResolverService implements Resolve<TemplateOfficial> {
  constructor(
    private router: Router,
    private templateService: TemplateService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<TemplateOfficial | null> {
    const templateId = route.paramMap
      .get('id')
      .replace(/([\d\w]+)\.html?$/, '$1');
    return this.templateService.getTemplateInfo(templateId).pipe(
      catchError(() => {
        this.router.navigate(['/404.html']);
        return of(null);
      })
    );
  }
}
