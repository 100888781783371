import { Pipe, PipeTransform } from '@angular/core';
import { RouterUrlService } from '@libs/core/src/lib/services/router-url.service';

@Pipe({
  name: 'queryUrl'
})
export class QueryUrlPipe implements PipeTransform {
  constructor(private routerUrlService: RouterUrlService) {}

  transform(value: any, index: string): any {
    const params = [];
    if (typeof value === 'number' && index) {
      params.push({ name: index, value: value });
    } else if (length in value) {
      value.forEach(item => params.push(item));
    }
    return this.routerUrlService.getUrlParam(params);
  }
}
