import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DesignerCommon, DesignerInfo } from '../../models/post-designer.interface';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DesignerService {
  apiDesigner = '/task-designer';
  apiInfo = '/task-member/info';
  apiQuestion = '/task-designer-question';

  constructor(private http: HttpClient) { }

  addDesigner(params:string): Observable<DesignerCommon> {
    return this.http.post<DesignerCommon>(this.apiDesigner, params);
  }

  getDesinger(id): Observable<DesignerInfo> {
    return this.http.get<DesignerInfo>(`${this.apiDesigner}/${id}`);
  }

  /* 待审核 status 0 未审核 20 通过 10 审核不通过*/
  designerList(params?): Observable<HttpResponse<DesignerInfo>> {
    return this.http.get<DesignerInfo>(this.apiDesigner, { params, observe: 'response' });
  }
  // method: impression:印象,restrict:限制接单,pass_yes:审核通过，pass_no:审核未通过,no_classify:限制分类
  optDesigner(params): Observable<DesignerInfo> {
    return this.http.put<DesignerInfo>(`${this.apiDesigner}/${params.id}`, params);
  }

  // 获取设计师信息
  designerInfo(): Observable<any> {
    if (location.pathname.indexOf('postorder') !== -1) {
      return this.http.get(this.apiInfo);
    } else {
      return of({})
    }
  }

  // 获取全局设置
  getGobal(): Observable<any> {
    if (location.pathname.indexOf('postorder') !== -1) {
      return this.http.get<any>('/task-cooperation/info');
    } else {
      return of({})
    }
  }

  // 获取设计师订单详情页的广告信息
  getDetailsAd(): Observable<any> {
    return this.http.get<any>('/task-ad/ad-online');
  }

  getQuestion(): Observable<any> {
    return this.http.get<any>(this.apiQuestion);
  }

  // 答题/task-designer-question/submit
  submitQuestion(params?): Observable<any> {
    return this.http.post('/task-designer-question/submit', params);
  }

  // 添加/修改设计师上线状态
  editOnline(params): Observable<any> {
    return this.http.post('/task-designer/online', params);
  }

  // 添加留言
  addMessage(params?): Observable<any> {
    return this.http.post('/task-order-message', params);
  }

  // 留言列表
  messageList(params?): Observable<HttpResponse<any>> {
    return this.http.get<any>('/task-order-message', { params, observe: 'response' });
  }

  // 修改留言信息
  handleMessage(params): Observable < DesignerInfo > {
    return this.http.put<DesignerInfo>(`/task-order-message/${params.id}`, params);
  }

  // 获取消息提醒列表
  noticeList(params?): Observable<HttpResponse<any>> {
    return this.http.get<any>('/task-order-notice', { params, observe: 'response' });
  }

  // 监听订单消息
  messageListen(params?): Observable<HttpResponse<any>> {
    return this.http.get<any>('/task-order-notice/listen', { params });
  }
}
