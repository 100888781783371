import { Component, Input, OnInit } from '@angular/core';
import { ClassifyConfig } from '@libs/core/src/lib/models/classify';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { MessageService } from '@libs/core/src/lib/services/message.service';

@Component({
  selector: 'app-choice-size',
  templateUrl: './choice-size.component.html',
  styleUrls: ['./choice-size.component.scss']
})
export class ChoiceSizeComponent implements OnInit {
  value: ClassifyConfig;
  page: number;
  @Input() size: ClassifyConfig[];
  constructor(
    private modalRef: NzModalRef,
    private message: MessageService
  ) { }

  ngOnInit() {
    this.value = this.size[0]
  }

  sure() {
    if (this.value.page.length > 1 && !this.page) {
      this.message.info('请选择P数');
      return;
    }
    this.modalRef.close({
      size: this.value,
      page: this.page
    });
  }
}
