import { Element } from '../models/base/element';
import { Template } from '../models/base/template';
import { Page } from '../models/base/page';
import { Injectable } from '@angular/core';
import { UserInterfaceService } from './ui.service';
import * as $ from 'jquery';
import { LocalStorage } from '@ngx-pwa/local-storage';
import FontFaceObserver from 'fontfaceobserver';
// import type { } from "css-font-loading-module";
// "@types/css-font-loading-module": "^0.0.7",
/**
 * 编辑器状态
 * @class EditorService
 */
@Injectable()
export class EditorService {
  public photoEditor = {
    showMode: 'stage'
  };
  public menuEditor = {
    width: null,
    height: null
  };
  public style: any = {
    nav: {
      bg: {
        backgroundColor: '#33373c'
      },
      button: {
        primary: {
          color: '#ffffff',
          backgroundImage: 'linear-gradient(135deg, #067cd9, #0658d3)'
        },
        group: {
          default: {
            backgroundColor: '#191b1e'
          }
        }
      }
    },
    tab: {
      header: {
        active: {
          color: '#fff',
          backgroundColor: '#0083e9'
        }
      }
    }
  };
  // 格式刷字体样式
  public  textStyle;
    // 格式刷字体样式
  public  textStyleTwo;
   // 格式刷激活状态
  public  FormatBrush = false;
  // 舞台组件对象
  public stage;
  // 模版数据(原docData)
  public template: Template;
  // 当前选择的页面
  public activePage: Page = null;
  public activePageIndex = 0;
  // 当前选择的元素
  public activeElements = [];
  // 当前正在编辑的组元素
  public groupEditElements = [];
  // 状态管理(撤销恢复)
  public stageManager = {
    // 状态指针
    index: -1,
    // 撤销恢复状态列表
    stack: []
  };
  // 是否显示元素打标
  public showElementSign = false;
  // 舞台是否拥有焦点
  public hasFocus = true;
  // 舞台缩放比例
  public zoom = 1;
  // 舞台位置
  public stagePos: any = {
    x: 0,
    y: 0
  };
  // 鼠标状态
  public mouse: any = {
    // 鼠标在画布内的位置
    x: 0,
    y: 0,
    // 按下坐标
    downX: 0,
    downY: 0,
    // 移动向量
    vecX: 0,
    vecY: 0,
    // 鼠标按下时,相对于元素左上角的坐标信息
    parentX: 0,
    parentY: 0,
    // 用于计算移动向量的上次事件坐标
    lastX: 0,
    lastY: 0,
    // 鼠标按下后,是否移动过
    moved: false,
    // 是否按下
    isDown: false
  };
  // 自由变换组件
  public freeTransform: FreeTransform = {
    // 是否正在框选状态
    selection: {
      show: false,
      rect: null,
      selectedRect: null,
      beforeSelectedRect: null
    },
    // 正在按下的控制点
    corner: null
  };
    // 拖拽调整组件
  public dragTransform: DragTransform = {
    // 是否正在框选状态
    selection: {
      show: false,
      rect: null,
      selectedRect: null,
      beforeSelectedRect: null
    },
    // 正在按下的控制点
    corner: null
  };
  // 状态管理
  public key = {
    ctrlIsDown: false,
    spaceIsDown: false,
    shiftIsDown: false
  };
  fontList = [];
  // 是否开启自动保存
  public autoSave = false;
  // 事件管理器
  private eventManager = [];
  // 事件ID计数器
  private eventIndexCounter = 0;
  // 元素页面ID计数器
  private indexCounter = 10000;
  // 是否显示锁定解锁
  public showLock = true;
  // 变量工具是否开启
  public showVariable = false;
  public variableData = {};
  constructor(
    public ui: UserInterfaceService,
    private localStorage: LocalStorage,
  ) {
    this.on('ready', e => {
      // 获取最大的ID,并写入ID计数器
      let maxID = 0;
      e.template.pages.forEach(pageItem => {
        if (pageItem.id > maxID) {
          maxID = pageItem.id;
        }
        pageItem.elements.forEach(eleItem => {
          if (eleItem.id > maxID) {
            maxID = eleItem.id;
          }
        });
      });
      this.indexCounter = maxID;
    });
    window['editor'] = this;
  }
  /**
   * 设置当前选择页面
   * @param pageItem 已经创建的页面对象
   * @param fireEvent 是否触发page:select事件
   */
  public setActivePage(pageItem: Page, fireEvent = true): void {
    if (this.showVariable) return;
    // console.log('setActivePage', pageItem);
    if (this.activePage !== pageItem) {
      // 取消元素选择
      this.setActiveElement(null);
      // 触发取消选择事件
      if (this.activePage !== null) {
        this.activePage.emit('deselected', {
          target: this.activePage,
          nextTarget: pageItem
        });
      }
      const lastTarget = this.activePage;
      this.activePage = pageItem;
      this.activePageIndex = this.template.pages.indexOf(pageItem);
      if (fireEvent) {
        this.activePage.emit('select', {
          target: pageItem,
          lastTarget
        });
      }
    }
  }

  getWindowRadio() {
    let ratio=1;
    const screen: any=window.screen,ua=navigator.userAgent.toLowerCase();

    if(~ua.indexOf('msie')) {
      if(screen!.deviceXDPI && screen.logicalXDPI) {
        ratio=screen.deviceXDPI/screen.logicalXDPI;
      }
    } else if(window.outerWidth !== undefined && window.innerWidth !== undefined) {
      ratio=window.outerWidth/window.innerWidth;
    }

    if(ratio) {
      ratio=Math.round(ratio*100);
    }
    return ratio;
  }

  async waitLoadFont(font_name) {
    const fontId = font_name.match(/\d+/)?.[0];
    if (!fontId) return;
    const fontItem = this.fontList.find(item => item.fontId == fontId);

    if (!fontItem || fontItem.states === 'loaded') return;

    // if (window.FontFace) {
    //   const font = new FontFace(`font_${fontItem.fontId}`, `url(${fontItem.path})`);
    //   await font.load().then(() => {
    //     fontItem.states = 'loaded'
    //   })
    //   document.fonts.add(font)
    //
    // } else {
      await new FontFaceObserver(font_name).load(null, 6000).then(() => {
        fontItem.states = 'loaded'
      }).catch(() => null);
    // }
  }

  changePageNumEl() {
    for (const page of this.template.pages) {
      let pageStr = this.getPageNum(page);
      let pageArr = pageStr.replace(/\s*/g,"").split('-');
      for (const element of page.elements) {
        if (element.type === 'svg' && element.props.sign === '页码') {
          element.options.pageNumber = element.options.pageFloat === 'left' ? pageArr[0] : pageArr[1];
          element.states.html = element.toHTML();
        }
      }
    }
  }

  /**
   * 获取当前页码
   * @param {Page} pageItem
   */
  public getPageNum(pageItem?: Page): string {
    if (!this.template) {
      return '正在加载...'
    }
    const page = pageItem || this.activePage;
    const i = this.template.pages.findIndex(item => item.id === page.id);
    if (this.template.props.noFirstPage) {
      if (this.template.props.insideBackCover) {
        if (i == 0) return '封二 - 01';
        else if (i == this.template.pages.length - 1) return `${this.getNum(i * 2)} - 封三`;
        else return `${this.getNum(i * 2)} - ${this.getNum(i * 2 + 1)}`
      } else {
        return `${this.getNum(i * 2 + 1)} - ${this.getNum(i * 2 + 2)}`
      }
    } else {
      if (i == 0) return '封底 - 封面';
      if (this.template.props.insideBackCover) {
        // 封三的页码显示
        if (i == 1) return '封二 - 01';
        else if (i == this.template.pages.length - 1) return `${this.getNum(i * 2 - 2)} - 封三`;
        else return `${this.getNum(i * 2 - 2)} - ${this.getNum(i * 2 - 1)}`
      }
      else {
        return `${this.getNum(i * 2 - 1)} - ${this.getNum(i * 2)}`
      }
    }
  }

  getNum(i) {
    return i < 10 ? '0' + i : i;
  }
  /**
   * 设置当前选择的元素(单选)
   * @param {Element} eleItem
   * @memberof EditorService
   */
  public setActiveElement(eleItem: Element): void {
    // 防止重复选择同一个元素
    if (
      this.activeElements &&
      this.activeElements.length === 1 &&
      this.activeElements[0] === eleItem
    ) {
      return;
    }

    if (eleItem == null || eleItem !== this.activeElements[0]) {
      // 触发取消选择事件
      if (this.activeElements.length === 1) {
        this.activeElements[0].emit('deselected', {
          target: this.activeElements[0]
        });
      } else if (this.activeElements.length > 1) {
        this.activeElements.forEach(item => {
          item.emit('deselected', {
            target: item,
            targets: this.activeElements,
            stopPropagation: true
          });
        });
        if(this.activeElements[0].parent){
          this.activeElements[0].parent.emit('element:deselected', {
            targets: this.activeElements
          });
        }
        // this.activeElements[0].parent.emit('element:deselected', {
        //   targets: this.activeElements
        // });
      }
    }
    if (eleItem == null) {
      this.activeElements = [];
    } else {
      this.activeElements = [eleItem];
      eleItem.emit('selected', {
        target: eleItem
      });
      // // 判断是否正在组编辑模式,以及当前选择的元素是否属于groupEditElements
      // if (this.groupEditElements.length > 1) {
      //   // 如果选中了不是本组的元素
      //   if (!(this.groupEditElements.find(item => eleItem === item))) {
      //     console.log('不存在,退出编辑模式');
      //     this.groupEditElements = [];
      //   }
      // }
    }
  }
  /**
   * 设置当前选择的元素(多选)
   * @param {Element} eleItem
   */
  public setActiveElements(eleItems: Element[]): any {
    // 防止重复选择同一个(多个)元素
    if (this.activeElements && this.activeElements === eleItems) {
      return;
    }
    // 判断是否正在组编辑模式,以及当前选择的元素是否属于groupEditElements
    // if (this.groupEditElements.length > 1 && eleItems !== null) {
    //   // 正在组编辑模式,过滤掉不是本组的元素
    //   eleItems = eleItems.filter(eleItem => {
    //     return (this.groupEditElements.find(item => eleItem === item));
    //   });
    // }


    if (eleItems == null || eleItems.map(item => item.id).sort().join(',') !== this.activeElements.map(item => item.id).sort().join(',')) {
      // 触发取消选择事件
      if (this.activeElements.length === 1) {
        this.activeElements[0].emit('deselected', {
          target: this.activeElements[0]
        });
      } else if (this.activeElements.length > 1) {
        this.activeElements.forEach(item => {
          item.emit('deselected', {
            target: item,
            targets: this.activeElements,
            stopPropagation: true
          });
        });
        if(this.activeElements[0].parent){
          this.activeElements[0].parent.emit('element:deselected', {
            targets: this.activeElements
          });
        }
      }
    }
    if (eleItems !== null) {
      // 更新选择框
      this.stage.changeDector.detectChanges();
      // 更新selectionRect
      const $stageContent = $('.stage-content'),
        leftOffset = $stageContent.offset().left,
        topOffset = $stageContent.offset().top;
      // 取出框选元素的包围盒数据
      let finalLeft = null,
        finalRight = null,
        finalTop = null,
        finalBottom = null;
      eleItems.forEach(eleItem => {
        const bbox = this.stage.getBBox(eleItem),
          left = (bbox.left - leftOffset) / this.zoom,
          right = (bbox.right - leftOffset) / this.zoom,
          top = (bbox.top - topOffset) / this.zoom,
          bottom = (bbox.bottom - topOffset) / this.zoom;

        if (finalLeft === null) {
          finalLeft = left;
        }
        if (finalRight === null) {
          finalRight = right;
        }
        if (finalTop === null) {
          finalTop = top;
        }
        if (finalBottom === null) {
          finalBottom = bottom;
        }

        if (left < finalLeft) {
          finalLeft = left;
        }
        if (top < finalTop) {
          finalTop = top;
        }
        if (right > finalRight) {
          finalRight = right;
        }
        if (bottom > finalBottom) {
          finalBottom = bottom;
        }
      });
      const scale = this.template.scale;
      this.freeTransform.selection.selectedRect = {
        x: finalLeft / scale,
        y: finalTop / scale,
        width: finalRight / scale - finalLeft / scale,
        height: finalBottom / scale - finalTop / scale
      };
      // 记录每个元素相对于selectedRect的坐标比例与宽高比例
      const { selectedRect } = this.freeTransform.selection;
      eleItems.forEach(eleItem => {
        const cx = eleItem.props.x - selectedRect.x,
          cy = eleItem.props.y - selectedRect.y;
        eleItem.states.xRadio = cx / selectedRect.width;
        eleItem.states.yRadio = cy / selectedRect.height;
        eleItem.states.widthRadio = eleItem.props.width / selectedRect.width;
        eleItem.states.heightRadio = eleItem.props.height / selectedRect.height;
      });
      // 修改
      this.activeElements = eleItems;
      if (eleItems.length === 1) {
        eleItems[0].emit('selected', {
          target: eleItems[0]
        });
      } else if (eleItems.length > 1) {
        eleItems.forEach(eleItem => {
          eleItem.emit('selected', {
            target: eleItem,
            targets: eleItems,
            stopPropagation: true
          });
        });
        if(eleItems[0].parent){
          eleItems[0].parent.emit('element:selected', {
            targets: eleItems,
            stopPropagation: true
          });
        }
      }
    }
  }
  /**
   * 设置模版对象
   * @param template 模版对象
   */
  public setTemplate(template: Template): void {
    // 设置模版
    this.template = template;
    // 设置第一页为活动页
    this.setActivePage(template.pages[0], false);
    // 强制更新舞台
    if (this.stage) {
      this.stage.changeDector.detectChanges();

    }
  }

  get addPageAble(): boolean {
    if (this.template) {
      if (this.template.props && "addPage" in this.template.props) {
        return this.template.props.addPage
      }
      if (this.template.states && "addPage" in this.template.states) {
        return this.template.states.addPage
      }
      return true
    }
    return false;
  }

  get noSvg() {
    return this.activeElements.filter(item => item.type == 'shape' || item.type == 'svg').length == 0
  }

  randomString(len = 32): string {
    const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    const maxPos = $chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  }
  /**
   * 获取一个新的唯一ID
   */
  public getNewID() {
    return this.randomString(10);
  }
  /**
   * 查找元素并返回元素实例(找不到返回undefined)
   * @param elementID 元素ID
   * @param pageID 页面ID(不填也可以,填写可以减少无用的遍历)
   */
  public findElement(elementID: number, pageID: number = null): Element {
    let eleItem;
    for (const page of this.template.pages) {
      if (!pageID || pageID === page.id) {
        eleItem = page.elements.find(item => elementID == item.id);
        if (eleItem) {
          break;
        }
      }
    }
    return eleItem;
  }
  /**
   * 查找页面并返回页面实例(找不到返回undefined)
   * @param pageID 页面ID
   */
  public findPage(pageID: number): Page {
    return this.template.pages.find(item => item.id === pageID);
  }
  /**
   * 检测正在编辑模式的元素,并将其退出
   */
  public exitAllEditMode() {
    for (const pageItem of this.template.pages) {
      for (const eleItem of pageItem.elements) {
        if (eleItem.states.editMode && eleItem['exitEditMode']) {
          eleItem['exitEditMode']();
        }
      }
    }
  }

  /**
   * 注册事件侦听器
   * @param {string} event
   * @param {Function} callback
   * @returns {any}
   */
  public on(event: string, callback: Function): any {
    const events = event.split(' ');
    if (events.length === 1) {
      this.eventIndexCounter++;
      const id = this.eventIndexCounter;
      this.eventManager.push({
        id,
        event,
        callback
      });
      return id;
    } else if (events.length > 1) {
      const idArr = [];
      for (const item of events) {
        this.eventIndexCounter++;
        const id = this.eventIndexCounter;
        this.eventManager.push({
          id,
          event: item,
          callback
        });
        idArr.push(id);
      }
      return idArr;
    }
  }
  /**
   * 销毁事件侦听器
   * @param eventID 注册事件侦听器返回的ID
   */
  public off(eventID): any {
    if (eventID.length) {
      eventID.forEach(event => {
        const eventIndex = this.eventManager.findIndex(
          item => item.id === event
        );
        if (eventIndex !== -1) {
          this.eventManager.splice(eventIndex, 1);
        } else {
          throw new Error('无法销毁未注册的事件');
        }
      });
    } else {
      const eventIndex = this.eventManager.findIndex(
        item => item.id === eventID
      );
      if (eventIndex !== -1) {
        this.eventManager.splice(eventIndex, 1);
      } else {
        throw new Error('无法销毁未注册的事件');
      }
    }
  }
  /**
   * 触发事件
   * @param event 事件名
   * @param data 触发事件
   */
  public emit(event: string, data: any = {}): void {
    this.eventManager.forEach(item => {
      if (item.event === event) {
        item.callback({
          event,
          ...data
        });
      }
    });
  }
  /**
   * 获取系统设置
   * @param name 设置名
   */
  public getSetting(name): any {
    return this.localStorage.getItem('setting_' + name).toPromise();
  }
  /**
   * 写入系统设置
   * @param name 设置名
   * @param value 值
   */
  public setSetting(name, value): any {
    console.log('setSetting', name, value);
    this.localStorage.setItem('setting_' + name, value).subscribe();
    this.emit('settingChange', { name, value });
  }
  /**
   * 创建撤销恢复快照
   */
  public snapshot(): any {
    if (this.stageManager.index !== this.stageManager.stack.length - 1) {
      // 如果当前指针不是在最新状态,则删除从当前的index开始往后的所有步骤
      this.stageManager.stack.splice(this.stageManager.index + 1, this.stageManager.stack.length - this.stageManager.index - 1);
    }
    if (this.stageManager.stack.length >= 30) {
      // 只保留30步,每大于30就删除第一个
      this.stageManager.stack.splice(0, 1);
    }
    const activePageIndex = this.template.pages.findIndex(
      pageItem => pageItem === this.activePage
    );
    // console.log('记录页面索引', activePageIndex);
    this.stageManager.stack.push({
      template: this.template.toJson(false, true),
      index: activePageIndex
    });

    this.stageManager.index = this.stageManager.stack.length - 1;
  }

}

export interface FreeTransform {
  // 是否正在框选状态
  selection: {
    // 是否显示选择框
    show: boolean;
    // 选择框拖动时的矩形信息
    rect: Rect;
    // 选择框松开进行碰撞检测后获取的bbox信息
    selectedRect: Rect;
    // 鼠标按下时记录的selectedRect
    beforeSelectedRect: any;
  };
  // 正在按下的控制点
  corner: string;
}

export interface DragTransform {
  // 是否正在框选状态
  selection: {
    // 是否显示选择框
    show: boolean;
    // 选择框拖动时的矩形信息
    rect: Rect;
    // 选择框松开进行碰撞检测后获取的bbox信息
    selectedRect: Rect;
    // 鼠标按下时记录的selectedRect
    beforeSelectedRect: any;
  };
  // 正在按下的控制点
  corner: string;
}
export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
  rotation?:number
}
