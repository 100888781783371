import { UserInterfaceService } from '../../../../services/ui.service';
import { Element } from '../../../../models/base/element';
import { EditorService } from '../../../../services/editor.service';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { deepClone, getUnitScale } from '../../../../utils/misc/common';
import * as $ from 'jquery';
import { MenuItem } from '../../../../models/ui/menu-item';
import { Table } from '../../../../models/element/table';

@Component({
  selector: 'app-table-cell-editor',
  templateUrl: './table-cell-editor.component.html',
  styleUrls: ['./table-cell-editor.component.scss']
})
export class TableCellEditorComponent implements OnInit, OnDestroy {



  get activeElement(): Table {
    return this.editor.activeElements[0];
  }
  get scale(): any {
    return this.editor.zoom * this.editor.template.scale;
  }
  // 尺寸修改器状态
  private resizer = {
    // 是否按下修改器(拖动状态)
    isDown: false,
    // 正在修改的单元格类型
    type: '',
    // 正在修改的单元格索引
    index: -1,
    // 开始缩放前记录的单元格信息
    beforeCellData: null,
    // 开始缩放前的props
    // beforeProps: null
  };
  eventIds;
  constructor(public editor: EditorService, public ui: UserInterfaceService) { }

  ngOnInit(): void {
    // this.eventIds = this.activeElement.on('scaling ready', e => {
    // });
  }
  ngOnDestroy(): void {
    // this.activeElement.off(this.eventIds);
  }
  /**
   * 获取最外面的表格整体的样式
   */
  getCellEditorStyle(): any {
    const elementItem: Element = this.editor.activeElements[0];
    const props = elementItem.props;
    const scale = this.editor.template.scale;
    const style = {
      left: this.editor.stagePos.x + props.x * this.editor.zoom * scale + 'px',
      top: this.editor.stagePos.y + props.y * this.editor.zoom * scale + 'px',
      width: props.width * this.editor.zoom * scale + 'px',
      height: props.height * this.editor.zoom * scale + 'px',
      transform: `rotate(${props.rotation}deg)`
    };
    return style;
  }
  getControllerItemSize(type, index) {
    const scale = this.editor.template.scale;
    // 获取row或者type总共长度
    const totalLength = this.activeElement.options[type].reduce((total, value) => total + value);
    // 计算比例
    const ratio = this.activeElement.options[type][index] / totalLength;
    // 获取表格现在的宽度
    const bbox = this.editor.stage.getBBox(this.activeElement);
    return ratio * (bbox[type === 'row' ? 'height' : 'width']);
  }
  /**
   * 事件:单元格控制器上的菜单按钮被单击
   * @param event 鼠标事件
   * @param type 行或列
   * @param index 索引
   */
  onCellItemMenuBtnClick(event, type, index): void {
    console.log({ event, type, index });
    const items = [];
    if (type === 'col') {
      items.push(
        new MenuItem({
          title: '删除整列',
          fn: () => {
            this.removeCellItem('col', index);
          }
        })
      );
    } else {
      items.push(
        new MenuItem({
          title: '删除整行',
          fn: () => {
            this.removeCellItem('row', index);
          }
        })
      );
    }
    this.ui.showMenu({
      x: event.clientX,
      y: event.clientY,
      items
    });
  }
  /**
   * 事件:插入单元格按钮被单击
   * @param type 行货列
   */
  onAddCellItemClick(type): void {
    const props = this.activeElement.props,
      options = this.activeElement.options;
    // 获取当前单元格行或者列的宽度
    const totalLength = options[type].reduce((total, value) => total + value);
    // 去最后一行或列的length
    const lastLength = options[type][options[type].length - 1];
    const radio = lastLength / totalLength;
    props[type === 'row' ? 'height' : 'width'] += (radio * props[type === 'row' ? 'height' : 'width']);
    // 添加到row或者col属性中
    options[type].push(lastLength);
    // 创建空的单元格
    if (type === 'row') {
      const rowArr: any = deepClone(options.cell[options.cell.length - 1]);
      for (const item of rowArr) {
        item.text = '';
      }
      options.cell.push(rowArr);
    } else {
      for (const row of options.cell) {
        const obj: any = deepClone(row[row.length - 1]);
        obj.text = '';
        row.push(obj);
      }
    }
    this.activeElement.emit('modified', {
      target: this.activeElement,
      type: 'add-cell'
    });
  }

  /**
   * 删除单元格
   * @param type 行或列
   * @param index 索引
   */
  removeCellItem(type, index): void {
    const props = this.activeElement.props,
      options = this.activeElement.options;
    // 获取当前单元格行或者列的宽度
    const totalLength = options[type].reduce((total, value) => total + value);
    const radio = options[type][index] / totalLength;
    props[type === 'row' ? 'height' : 'width'] -= (radio * props[type === 'row' ? 'height' : 'width']);
    options[type].splice(index, 1);
    // 删除cell
    if (type === 'row') {
      options.cell.splice(index, 1);
    } else {
      for (const row of options.cell) {
        row.splice(index, 1);
      }
    }
    this.activeElement.states.html = this.activeElement.toHTML();
    this.activeElement.emit('modified', {
      target: this.activeElement,
      type: 'remove-cell'
    });
  }

  /**
   * 事件:单元格尺寸修改器被按下
   * @param type 行或列
   * @param index 索引
   */
  onResizerMouseDown(type, index) {
    // 记录按下前的宽度
    this.resizer.beforeCellData = {
      row: deepClone(this.activeElement.options.row),
      col: deepClone(this.activeElement.options.col)
    }
    this.resizer.isDown = true;
    this.resizer.index = index;
    this.resizer.type = type;
  }
  /**
   * 事件:鼠标在窗口移动
   * @param event 事件对象
   */
  @HostListener('document:mousemove', ['$event']) onWindowMouseMove(event) {
    const mouse = this.editor.mouse;
    const scale = this.editor.template.scale;
    const { type, index } = this.resizer;
    const { props, options, states } = this.activeElement;
    if (this.resizer.isDown) {
      const offset = (type === 'row' ? mouse.vecY : mouse.vecX) / scale;
      const totalLength = options[type].reduce((total, value) => total + value);
      const pxLength = offset * (totalLength / props[type === 'row' ? 'height' : 'width']);
      if (options[type][index] + pxLength > 0 && options[type][index + 1] - pxLength > 0) {
        options[type][index] += pxLength;
        options[type][index + 1] -= pxLength;
      }
      states.html = this.activeElement.toHTML();
    }
  }
  /**
   * 事件:鼠标在窗口松开
   * @param event 事件对象
   */
  @HostListener('document:mouseup', ['$event']) onWindowMouseUp(event) {
    if (this.resizer.isDown) {
      this.resizer.isDown = false;
      this.activeElement.emit('modified', {
        target: this.activeElement,
        type: 'resize-cell'
      });
    }
  }
}
