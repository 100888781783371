import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {

  transform(value: any, max: number, tail: any): any {
    if (!value) return '';
    if (!max) return value;
    if (value.length <= max) return value;
    value = value.substr(0, max);
    return value + (tail || ' …');
  }

}
