import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caseConvert'
})
export class CaseConvertPipe implements PipeTransform {
  transform(value: any, type: string): any {
    if (type === 'upper') {
      return value.toUpperCase();
    } else {
      return value.toLowerCase();
    }
  }
}
