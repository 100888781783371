import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { CardSetting } from '@libs/core/src/lib/models/user';
import { UploadService } from '@libs/core/src/lib/services/upload.service';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { DesignParamService } from '@libs/core/src/lib/services/design-param.service';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-custom-card-message',
  templateUrl: './custom-card-message.component.html',
  styleUrls: ['./custom-card-message.component.scss']
})
export class CustomCardMessageComponent implements OnInit {
  @Input() type: 'editor' | 'normal';
  @Input() current: CardSetting;
  tab: 'message' | 'history' = 'message';

  logoLoading: boolean;
  codeLoading: boolean;
  logoPath: string;
  codePath: string;
  focus: number;
  form: FormGroup;
  submitLoading = false;
  // 是否覆盖
  cover = false;

  history$ = new Subject();
  historyList: CardSetting[];
  historyLoading = false;
  historyKeywords = '';

  constructor(
    private fb: FormBuilder,
    private upload: UploadService,
    private auth: AuthService,
    public modal: NzModalRef,
    private message: MessageService,
    private paramService: DesignParamService
  ) { }

  ngOnInit() {
    const cardSetting = this.current || this.auth.user.cardSetting;
    if (cardSetting) {
      this.logoPath = cardSetting.logoPath;
      this.codePath = cardSetting.codePath;
    }
    this.form = this.fb.group({
      id: [cardSetting ? cardSetting.id: ''],
      company: [cardSetting && cardSetting.company || ''],
      name: [cardSetting && cardSetting.name || ''],
      job: [cardSetting && cardSetting.job || ''],
      mobile: [cardSetting && cardSetting.mobile || ''],
      phone: [cardSetting && cardSetting.phone || ''],
      email: [cardSetting && cardSetting.email || ''],
      web_url: [cardSetting && cardSetting.webUrl || ''],
      address: [cardSetting && cardSetting.address || ''],
      logo_id: [cardSetting && cardSetting.logoId || ''],
      logo_config: [cardSetting && cardSetting.logoConfig || null],
      code_id: [cardSetting && cardSetting.codeId || ''],
      code_config: [cardSetting && cardSetting.codeConfig || null]
    });

    this.history$.pipe(
      tap(() => this.historyLoading = true),
      switchMap(data => this.auth.cardSettingHistory(data))
    ).subscribe(data => {
      this.historyLoading = false;
      this.historyList = data;
    }, () => this.historyLoading = false);

    this.history$.next();

    if (this.type === 'editor') {
      this.cover = true;
      this.tab = this.current ? 'message' : 'history';
    } else if (this.type === 'normal') {
      this.cover = this.isView()
    }
  }

  fileUpload = (item): Subscription => this.upload.uploadTemp(item);

  uploadLogo(info: { file: NzUploadFile }) {
    if (info.file.status === 'uploading') {
      this.logoLoading = true;
      return;
    }
    if (info.file.status === 'done') {
      this.logoLoading = false;
      const data = info.file.response;
      this.logoConfig.patchValue(data);
      this.logoId.patchValue(data.fileId);
      this.logoPath = data.filePath;
    }
  }

  uploadCode(info: { file: NzUploadFile }) {
    if (info.file.status === 'uploading') {
      this.codeLoading = true;
      return;
    }
    if (info.file.status === 'done') {
      this.codeLoading = false;
      const data = info.file.response;
      this.codeConfig.patchValue(data);
      this.codeId.patchValue(data.fileId);
      this.codePath = data.filePath;
    }
  }

  submit(method?: string) {
    let value: any = {}, isDefault = this.type == 'editor' ? 0 : 1;
    if (!this.cover && method == 'normal') {
      this.form.get('id').patchValue('')
    }
    this.submitLoading = true;
    this.auth.cardSetting({...this.form.value, default: isDefault}).subscribe(() => {
      this.message.success('保存成功');
      this.submitLoading = false;

      for(let i in this.form.value) {
        value[i.replace(/_(\w)/, (sub, word) => word.toUpperCase())] = this.form.value[i];
      }
      if (isDefault) {
        this.auth.updateUser({
          ...this.auth.user,
          cardSetting: {
            ...value,
            logoPath: this.logoPath,
            codePath: this.codePath
          }
        });
      }
      this.modal.close(isDefault ? '' : value);
    }, () => this.submitLoading = false);
  }

  clear() {
    this.form.setValue({
      id: '',
      company: '',
      name: '',
      job: '',
      mobile: '',
      phone: '',
      email: '',
      web_url: '',
      address: '',
      logo_id: '',
      logo_config: null,
      code_id: '',
      code_config: null
    });
    this.logoPath = null;
    this.codePath = null;
    this.form.markAsPristine();

    this.cover = false;
  }

  setFormValue(data: CardSetting, toEdit = false) {
    this.form.setValue({
      id: data.id,
      company: data.company || '',
      name: data.name || '',
      job: data.job || '',
      mobile: data.mobile || '',
      phone: data.phone || '',
      email: data.email || '',
      web_url: data.webUrl || '',
      address: data.address || '',
      logo_id: data.logoId || '',
      logo_config: data.logoConfig || null,
      code_id: data.codeId || '',
      code_config: data.codeConfig || null
    });
    this.logoPath = data.logoPath || null;
    this.codePath = data.codePath || null;

    if (toEdit) {
      this.cover = true;
      this.tab = 'message';
    }
  }

  isView() {
    return this.form.value.company ||
      this.form.value.name ||
      this.form.value.job ||
      this.form.value.mobile ||
      this.form.value.phone ||
      this.form.value.email ||
      this.form.value.web_url ||
      this.form.value.address ||
      this.form.value.logo_id ||
      this.form.value.logo_config ||
      this.form.value.code_id ||
      this.form.value.code_config
  }

  searchHistory(val) {
    if (this.historyKeywords != val) {
      this.historyKeywords = val;
      this.history$.next({keywords: val})
    }
  }

  delHistory(data) {
    this.auth.removeCardSetting(data.id).subscribe(() => {
      const index = this.historyList.indexOf(data);
      this.historyList.splice(index, 1);
    });
  }

  get logoConfig(): AbstractControl {
    return this.form.get('logo_config')
  }

  get logoId() {
    return this.form.get('logo_id')
  }

  get codeConfig() {
    return this.form.get('code_config')
  }

  get codeId() {
    return this.form.get('code_id')
  }
}
