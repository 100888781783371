import { Action } from '@ngrx/store';
import { User } from '../models/user';

export enum AuthActionTypes {
  Login = '[Auth] Login',
  LoginClose = '[Auth] Login Close',
  Logout = '[Auth] Logout',
  LoginSuccess = '[Auth] Login Success',
  LoginFailure = '[Auth] Login Failure',
  LoginSuccessLocal = '[Auth] Login Success'
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;
}

export class LoginClose implements Action {
  readonly type = AuthActionTypes.LoginClose;
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;

  constructor(public payload: { user: User }) {}
}

export class LoginSuccessLocal implements Action {
  readonly type = AuthActionTypes.LoginSuccessLocal;

  constructor(public payload: { user: User }) {}
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;

  constructor(public payload: any) {}
}

export type AuthActions =
  | Login
  | LoginClose
  | Logout
  | LoginSuccess
  | LoginFailure
  | LoginSuccessLocal;
