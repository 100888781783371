import { Component, OnInit, OnDestroy } from '@angular/core';
import { EditorService } from '../../../services/editor.service';
import * as $ from 'jquery';
import Sortable from 'sortablejs';
import { deepClone } from '../../../utils/misc/common';
import { UploadService } from '@libs/core/src/lib/services/upload.service';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';
import { ToastIcon } from '@libs/modal/src/lib/models/toast-icon';
import { Window } from '../../../models/ui/window';
import { UserInterfaceService } from '@libs/editor/src/lib/services/ui.service';
import { TMenuCutImgComponent } from '@libs/editor/src/lib/components/editor-ui/t-menu-cut-img/t-menu-cut-img.component';

@Component({
  selector: 'app-layout-edit',
  templateUrl: './layout-edit.component.html',
  styleUrls: ['./layout-edit.component.scss']
})
export class LayoutEditComponent implements OnInit, OnDestroy {
  gridData = [];
  svgData = [];
  activeDataItem;
  cutImg;
  constructor(
    public editor: EditorService,
    public upload: UploadService,
    public modal: ModalService,
    public ui:UserInterfaceService
  ) {
    const eleItem = this.editor.activeElements[0];
    this.gridData = eleItem.options.data;
    this.svgData = this.gridData.map(item => this.getSVG(item));
  }

  ngOnInit() {
    // var el = document.querySelector('.edit-area');
    // Sortable.default.create(el, {
    //   filter: '.disable-drag',
    //   animation: 150,
    //   onSort: e => {
    //     console.log('e', e);

    //     const eleItem = this.editor.activeElements[0];
    //     const dragItem = eleItem.options.data[e.oldIndex];
    //     eleItem.options.data.splice(e.oldIndex, 1);
    //     eleItem.options.data.splice(e.newIndex, 0, dragItem);
    //     eleItem.states.activeIndex = e.newIndex;
    //     this.gridData = eleItem.options.data;
    //     this.svgData = this.gridData.map(item => this.getSVG(item));
    //     this.editor.snapshot();
    //   }
    // });
  }
  ngOnDestroy() {
    const eleItem = this.editor.activeElements[0];
    eleItem.states.html = eleItem.toHTML();
    this.editor.snapshot();
  }
  getEditAreaStyle() {
    const eleItem = this.editor.activeElements[0];
    const { props, options } = eleItem;
    const zoom =
      this.editor.zoom * this.editor.template.scale;
    const style = {
      left: this.editor.stagePos.x + props.x * zoom + 'px',
      top: this.editor.stagePos.y + props.y * zoom + 'px',
      width: props.width * zoom + 'px',
      height: props.height * zoom + 'px',
      transform: `rotate(${props.rotation}deg)`
    };
    return style;
  }
  getSVG(item) {
    const eleItem = this.editor.activeElements[0];
    const { props, options } = eleItem;
    const { oWidth, oHeight } = props;
    const scale = options.scale;

    let itemSVG = eleItem.states.svg;
    // 替换颜色(只支持HEX颜色的替换)
    for (const colorItem of eleItem.options.colors) {
      // 替换颜色
      itemSVG = itemSVG.replace(
        new RegExp(colorItem.oColor.toLowerCase(), 'gmi'),
        colorItem.nColor.toUpperCase()
      );
    }
    const $svg = $(itemSVG);

    $svg.attr('width', oWidth * scale);
    $svg.attr('height', oHeight * scale);
    for (const key in item) {
      const headItem = eleItem.states.items.find(item => item.name === key);
      if (!headItem) break;
      const $el = $svg.find('#' + headItem.type + '-' + headItem.name);
      switch (headItem.type) {
        case 'text':
          $el.text(item[key]);
          break;
        case 'image':
          const image = item[key];
          $el[0].outerHTML = `<image xlink:href="${image.filePath}" viewBox="0 0 ${image.width} ${image.height}" preserveAspectRatio="xMidYMid slice" clip-path="${$el.attr('clip-path')}" x="${$el.attr('x') || 0}" y="${$el.attr('y') || 0}" width="${$el.attr('width')}" height="${$el.attr('height')}"></image>`;
          break;
      }
    }
    return $svg[0].outerHTML;
  }
  getItemStyle() {
    const eleItem = this.editor.activeElements[0];
    const zoom =
      this.editor.zoom * this.editor.template.scale;
    const { props, options } = eleItem;
    const { oWidth, oHeight } = props;
    const scale = options.scale;
    return {
      width: (oWidth * scale * zoom) + 'px',
      height: oHeight * scale * zoom + 'px'
    };
  }
  getActiveIndex() {
    const eleItem = this.editor.activeElements[0];
    return eleItem.states.activeIndex;
  }
  onImgItemClick(item, index) {
    //双击判断
    const eleItem = this.editor.activeElements[0];
    eleItem.states.activeIndex = index;
  }
  onImgItemMousedown() {
    if (event['which'] !== 1) {
      return false;
    }
    const { x: mouseX, y: mouseY } = this.editor.mouse;
    const scale = this.editor.template.scale;
    const eleItem = this.editor.activeElements[0];
    // 开始移动时,记录缩放前的信息
    eleItem.states.beforeProps = deepClone(eleItem.props);
    this.editor.mouse.parentX = mouseX - eleItem.props.x * scale;
    this.editor.mouse.parentY = mouseY - eleItem.props.y * scale;

    this.editor.freeTransform.corner = 'move';
    // 触发元素对象事件
    eleItem.emit('mousedown', {
      target: eleItem
    });
  }
  onPageRemoveClick(item, index) {
    const eleItem = this.editor.activeElements[0];
    if (eleItem.options.data.length > 1) {
      eleItem.options.data.splice(index, 1);
      this.gridData = eleItem.options.data;
      this.svgData = this.gridData.map(item => this.getSVG(item));
      eleItem.updateHeight();
    }
  }
  onReplaceImageClick(item, index) {
    this.activeDataItem = item;
    $('#layout-edit-image-upload').click();
  }
  onFileChange() {
    const eleItem = this.editor.activeElements[0];
    const file = $('#layout-edit-image-upload')[0].files[0];
    // 判断类型
    if (
      ['jpg', 'png', 'svg+xml', 'jpeg'].filter(
        item => 'image/' + item === file.type
      ).length === 0
    ) {
      alert('仅支持上传图片文件');
      return;
    } else if (file.size > 20 * 1024 * 1024) {
      // 尺寸判断
      alert('文件最大尺寸为20M，请压缩后上传，推荐压缩文件网址：                 https://www.bejson.com/ui/compress_img/');
      return;
    }
    const loadingToast = this.modal.toast({
      icon: ToastIcon.LOADING,
      msg: '正在上传',
      duration: 0
    });
    this.upload.uploadTemp({
      file,
      onSuccess: res => {
        this.activeDataItem['图片'] = res;
        eleItem['exitEditMode']();
        eleItem.states.html = eleItem.toHTML();
        loadingToast.close();
        this.modal.toast({
          icon: ToastIcon.SUCCESS,
          msg: '已更换'
        });
        this.editor.snapshot();
      },
      onFail: e => {
        console.log('上传失败', e);
        loadingToast.close();
        this.modal.toast({
          icon: ToastIcon.ERROR,
          msg: '上传失败'
        });
      }
    });
    $('#layout-edit-image-upload').val('');
  }
  onImgItemDblClick(item,i){
    if(!item.hasOwnProperty('图片')) return;
    if (this.cutImg){
      this.ui.remove(this.cutImg)
    }
    this.cutImg = new Window({
      width: 554,
      height: 508,
      props:{itemData:item,index:i},
      skin: 'common',
      taskbar: false,
      title:"调整菜图",
      component: TMenuCutImgComponent
    });
    this.ui.add(this.cutImg);
  }
}
