import { MaskImageCropperComponent } from '../../components/edit-tools/mask-image-cropper/mask-image-cropper.component';
import { Element } from '../base/element';
import { getFilterHtml } from '../../utils/misc/filter';
import { ToolBarContainerComponent } from '../../components/editor-ui/t-tool-bar/tools/tool-bar-container/tool-bar-container.component';
import { getBase64fromImg, getSvgFromUrl } from '@libs/editor/src/lib/utils/misc/common';
import $ from 'jquery'

export class Container extends Element {
  public type = 'container';
  onCreated() {
    this.config.update = true;
    this.config.dropAble = true;
    this.config.markAble = false;
    this.config.toolbar = ToolBarContainerComponent;
    this.on('rotating',e=>{
      this.states.html = this.toHTML();
    });
    //图片旋转角度 兼容以前模板 如果没有为0
    if (!this.props.hasOwnProperty('imgRotate')) {
      this.props.imgRotate = 0;
    }
    // 事件:按下控制角点(缩放)时
    this.on('cornermousedown', e => {
      if (!this.options.clip) return;
      this.states.beforeClipData = {
        width: this.props.width,
        height: this.props.height,
        clipX: this.options.clip.x,
        clipY: this.options.clip.y,
        clipWidth: this.options.clip.width,
        clipHeight: this.options.clip.height
      };
    });
    // 图片缩放时位置计算
    this.on('scaling', e => {
      if (!this.options.clip) return;
      const widthRatio = this.options.clip.oWidth / this.options.clip.oHeight;
      const heightRatio = this.options.clip.oHeight / this.options.clip.oWidth;
      // 元素宽度大于剪辑宽度,等比缩放剪辑的宽度和高度
      if (this.props.width > this.options.clip.width + this.options.clip.x) {
        this.options.clip.width = this.props.width - this.options.clip.x;
        this.options.clip.height =
          (this.props.width - this.options.clip.x) * heightRatio;
      }
      // 元素宽度大于剪辑高度,等比缩放剪辑的宽度和高度
      if (this.props.height > this.options.clip.height + this.options.clip.y) {
        this.options.clip.height = this.props.height - this.options.clip.y;
        this.options.clip.width =
          (this.props.height - this.options.clip.y) * widthRatio;
      }
      // 如果是等比缩放角点,计算缩放比例
      if (
        e.corner === 'tl' ||
        e.corner === 'tr' ||
        e.corner === 'bl' ||
        e.corner === 'br'
      ) {
        const scale = this.props.width / this.states.beforeClipData.width;
        this.options.clip = {
          ...this.options.clip,
          x: this.states.beforeClipData.clipX * scale,
          y: this.states.beforeClipData.clipY * scale,
          width: this.states.beforeClipData.clipWidth * scale,
          height: this.states.beforeClipData.clipHeight * scale
        };
      }
      this.states.html = this.toHTML();
    });
    // 双击:进入编辑模式
    this.on('dblclick', this.enterEditMode.bind(this));
    // 取消选择:退出编辑模式
    this.on('deselected', this.exitEditMode.bind(this));
    // 有图片拖到元素里
    this.on('drop', e => {
        if (!e.dataTransfer.getData('item')) return;
        const obj = JSON.parse(e.dataTransfer.getData('item'));
        if (obj.filePath.match(/\.[Ss][Vv][Gg]$/)) return;
        this.loadImage(obj.filePath, obj.fileId, obj.width, obj.height);

      //拖入的是容器，替换容器
      // if (obj.oneLevelCid === 165) {
      //   this.updateContainer(obj);
      // } else {
      //   this.loadImage(obj.filePath, obj.fileId, obj.width, obj.height);
      // }
    });
    this.on('modified', (e) => {
      if (e.type === 'changeImg') {
        this.states.base64 = null
      }
    });
    // 加载maskPath
    const id = this.id;

    this.states.maskSVG = `<image width="100%" height="100%" xlink:href="${this.options.maskPath}"  preserveAspectRatio="none"></image>`;
    this.states.html = this.toHTML();

  }

  resetClip() {
    const widthRatio = this.options.clip.oWidth / this.options.clip.oHeight;
    const heightRatio = this.options.clip.oHeight / this.options.clip.oWidth;
    // 元素宽度大于剪辑宽度,等比缩放剪辑的宽度和高度
    if (this.props.width > this.options.clip.width + this.options.clip.x) {
      this.options.clip.width = this.props.width - this.options.clip.x;
      this.options.clip.height =
        (this.props.width - this.options.clip.x) * heightRatio;
    }
    // 元素宽度大于剪辑高度,等比缩放剪辑的宽度和高度
    if (this.props.height > this.options.clip.height + this.options.clip.y) {
      this.options.clip.height = this.props.height - this.options.clip.y;
      this.options.clip.width =
        (this.props.height - this.options.clip.y) * widthRatio;
    }
  }

  updateContainer(data) {
    const { width, height, oWidth, oHeight } = this.props;
    this.props.oWidth = data.width;
    this.props.height = data.height / data.width * width;
    this.props.oHeight = data.height;
    this.options.maskPath = data.filePath;
    this.options.mask = data.fileId;
    this.states.maskSVG = `<image width="100%" height="100%" xlink:href="${this.options.maskPath}" preserveAspectRatio="none"></image>`;
    const widthRatio = this.options.clip.oWidth / this.options.clip.oHeight,
      heightRatio = this.options.clip.oHeight / this.options.clip.oWidth;
    if (this.options.clip.width < this.props.width) {
      this.options.clip.width = this.props.width;
      this.options.clip.height = this.props.width * heightRatio;
    }
    if (this.options.clip.height < this.props.height) {
      this.options.clip.height = this.props.height;
      this.options.clip.width = this.props.height * widthRatio;
    }
    this.options.clip.x = -(this.options.clip.width - this.props.width) / 2;
    this.options.clip.y = -(this.options.clip.height - this.props.height) / 2;

    this.emit('modified', {
      type: 'changeContainer',
      target: this
    });
  }

  loadImage(url, fileId, oWidth, oHeight,firstEvent = true) {
    this.options.source = fileId;
    this.options.url = url;
    this.options.clip = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      oWidth: oWidth,
      oHeight: oHeight
    };
    const imageRadio = this.options.clip.oWidth / this.options.clip.oHeight;
    const containerRadio = this.props.width / this.props.height;

    if (this.options.fillType === 'inside') {
      if (imageRadio > containerRadio) {
        this.options.clip.width = this.props.width;
        this.options.clip.height = this.props.width / imageRadio
      } else {
        this.options.clip.height = this.props.height;
        this.options.clip.width = this.props.height * imageRadio
      }
    } else {
      if (imageRadio > containerRadio) {
        this.options.clip.height = this.props.height;
        this.options.clip.width = this.props.height * imageRadio
      } else {
        this.options.clip.width = this.props.width;
        this.options.clip.height = this.props.width / imageRadio
      }

    }

    this.options.clip.x = (this.props.width - this.options.clip.width) / 2;
    this.options.clip.y = (this.props.height - this.options.clip.height) / 2;
    if (firstEvent){
      this.emit('modified', {
        type: 'changeImg',
        target: this
      });
    }
  }

  changeSignCombination(data) {
    if (!data) return;
    const {fileId, filePath, width, height} = data;
    if (!fileId || !filePath || !width || !height) return;
    this.options.source = fileId;
    this.options.url = filePath;

    this.options.clip = {
      ...this.options.clip,
      width: width,
      height: height,
      oWidth: width,
      oHeight: height
    };

    this.verifyClip(true, true);
  }

  /**
   * 验证图片布局
   * @param verifyAlign 验证水平垂直对齐
   */
  verifyClip(verifyAlign = false, resize = false) {
    const {clip, fill} = this.options;
    const {width, height} = this.props;
    const {oWidth, oHeight} = clip;
    const type = fill && fill.type || 'inside';
    const alignX = fill && fill.alignX || 'center';
    const alignY = fill && fill.alignY || 'center';
    const imgRadio = oWidth / oHeight;
    const roomRadio = width / height;

    if (type === 'outside') {
      if (imgRadio > roomRadio && (clip.height < height || resize)) {
        clip.height = height;
        clip.width = imgRadio * height
      }

      if (imgRadio <= roomRadio && (clip.width < width || resize)) {
        clip.width = width;
        clip.height = width / imgRadio;
      }

    } else {
      if (imgRadio > roomRadio && (clip.width > width || resize)) {
        clip.width = width;
        clip.height = width / imgRadio;
      }

      if (imgRadio <= roomRadio && (clip.height > height || resize)) {
        clip.height = height;
        clip.width = imgRadio * height
      }
    }

    if (verifyAlign) {
      switch (alignX) {
        case 'left':
          clip.x = 0;
          break;
        case 'center':
          clip.x = (width - clip.width) / 2;
          break;
        case 'right':
          clip.x = width - clip.width;
          break;
      }

      switch (alignY) {
        case 'top':
          clip.y = 0;
          break;
        case 'center':
          clip.y = (height - clip.height) / 2;
          break;
        case 'bottom':
          clip.y = height - clip.height;
          break;
      }
    } else if (type === 'outside') {
      if (clip.x > 0) {
        clip.x = 0;
      }
      if (clip.y > 0) {
        clip.y = 0
      }

      if (clip.x + clip.width < width) {
        clip.x = width - clip.width
      }
      if (clip.y + clip.height < height) {
        clip.y = height - clip.height
      }
    } else {
      if (clip.x < 0) {
        clip.x = 0;
      }
      if (clip.y < 0) {
        clip.y = 0
      }

      if (clip.x + clip.width > width) {
        clip.x = width - clip.width
      }
      if (clip.y + clip.height > height) {
        clip.y = height - clip.height
      }
    }
  }

  enterEditMode() {
    if (!this.options.url) {
      return;
    }
    this.states.editMode = true;
    this.config.transform = false;
    // this.config.visible = false;
    // this.emit('enterEdit', {
    //   target: this,
    //   toolComponent: MaskImageCropperComponent
    // });
  }
  exitEditMode() {
    if (this.states.editMode) {
      this.emit('exitEdit', {
        target: this,
        toolComponent: MaskImageCropperComponent
      });
      this.states.editMode = false;
      this.config.visible = true;
      this.config.transform = true;
      this.states.html = this.toHTML();
    }
  }
  toHTML() {
    const id = this.id;
    const props = this.props;
    const options = this.options;
    const states = this.states;
    const clip = options.clip;
    const scale = this.parent.parent.scale;
    const hasFilter = options.filter && (options.filter.bright  < 0 || options.filter.contrast !== 0 || options.filter.saturation !== 0 || options.filter.hue !== 0 || options.filter.blur !== 0 || options.filter.film !== 0);
    let url = '';
    if (this.options.url && this.options.url.indexOf('.svg') === -1) {
      url = this.options.url + '?x-oss-process=style/thumb800';
    } else {
      url = this.options.url;
    }
    if (!this.states.maskSVG) return '';
    if (!clip) {
      this.states.maskSVG = `<image width="100%" transform="rotate(${-props.imgRotate},${props.width/2} ${props.height/2})" height="100%" xlink:href="${this.options.maskPath}"  preserveAspectRatio="none"></image>`
      // 这个容器还没有图片
      return `<svg style="position: absolute;" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${props.width} ${props.height}" preserveAspectRatio="none">
            <defs>
                <mask id="mask-source-${id}">
                ${this.states.maskSVG}
                </mask>
            </defs>
            <g mask="url(#mask-source-${id})">
            <rect width="${props.width}" height="${props.height}" fill="#ececec"></rect>
            <g transform="translate(${props.width / 2} ${props.height / 2})rotate(${-props.rotation})">
              <svg xmlns="http://www.w3.org/2000/svg"
                x="-31" y="-10"
                width="65" height="31" viewBox="0 0 113.61 62.81">
                <path fill="none" stroke="#a8a8a8" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M255.89,243.32l-1.12-1.92a1.65,1.65,0,0,0-1.43-.82h-6.69a1.65,1.65,0,0,0-1.43.82l-1.12,1.92a1.65,1.65,0,0,1-1.43.82h-.86a1.65,1.65,0,0,0-1.65,1.65v12a1.65,1.65,0,0,0,1.65,1.65h16.35a1.65,1.65,0,0,0,1.65-1.65v-12a1.65,1.65,0,0,0-1.65-1.65h-.86A1.65,1.65,0,0,1,255.89,243.32Z"
                  transform="translate(-196.62 -239.59)" />
                <path fill="none" stroke="#a8a8a8" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M251.84,255.33a4.48,4.48,0,1,1,2.45-2.79"
                  transform="translate(-196.62 -239.59)" />
                <text fill="#a8a8a8" font-size="12" transform="translate(0 40)">
                  拖拽照片至此处添加
                </text>
              </svg>
            </g>
            
          </g></svg>`;
    } else {
      this.states.maskSVG = `<image width="100%" transform="rotate(${-props.imgRotate},${props.width/2} ${props.height/2})" height="100%" xlink:href="${this.options.maskPath}"  preserveAspectRatio="none"></image>`
      // 这个有容器
      return `<svg style="position: absolute;${options.filter && options.filter.bright > 0 ? 'filter:brightness('+ (options.filter.bright / 100 + 1) +')' : ''}" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${
        props.width
        } ${props.height}" preserveAspectRatio="none">
            <defs>
                <mask id="mask-source-${id}">
                  ${this.states.maskSVG}
                </mask>
                ${
        hasFilter
          ? `<filter id="filter-source-${id}">` +
          getFilterHtml(options.filter) +
          '</filter>'
          : ''
        }
            </defs>
            <image
             x="${clip.x}"
             y="${clip.y}"
             ${hasFilter ? `filter="url(#filter-source-${id})"` : ''}
             width="${clip.width}"
             height="${clip.height}"
             xlink:href="${url}"
             preserveAspectRatio="none"
             transform="rotate(${props.imgRotate},${props.width/2} ${props.height/2})"
             mask="url(#mask-source-${id})"></image>
    </svg>`;
    }
  }

  async toSvg(): Promise<string> {
    if (!this.states.maskCode) {
      if (this.options.maskPath.match(/.svg$/i)) {
        const res = await getSvgFromUrl(this.options.maskPath);
        const $svg = $(res.data.replace(/^<\?(.|\r|\n)+<svg/, '<svg').replace('xlink:href', 'href'));
        $svg
          .removeAttr('id')
          .removeAttr('data-name')
          .removeAttr('xmlns')
          .removeAttr('xmlns:xlink')
          .attr('width', '100%')
          .attr('height', '100%')
          .attr('preserveAspectRatio', "none")
        this.states.maskCode = `<g transform="rotate(${-this.props.imgRotate},${this.props.width/2} ${this.props.height/2})">${$svg[0].outerHTML}</g>`
      } else {
        const img = await getBase64fromImg(this.options.maskPath)
        this.states.maskCode = `<image width="100%" height="100%" transform="rotate(${-this.props.imgRotate},${this.props.width/2} ${this.props.height/2})" href="${img}" preserveAspectRatio="none"></image>`
      }
    }
    let svg;
    if (this.options.clip) {
      const bright = this.options.filter && this.options.filter.bright > 0 ? `filter:brightness(${this.options.filter.bright / 100 + 1})` : '';
      const hasFilter = this.options.filter && (this.options.filter.bright  < 0 || this.options.filter.contrast !== 0 || this.options.filter.saturation !== 0 || this.options.filter.hue !== 0 || this.options.filter.blur !== 0 || this.options.filter.film !== 0);

      if (!this.states.base64 && !this.options.url.match(/.svg$/i)) {
        this.states.base64 = await getBase64fromImg(this.options.url + '?x-oss-process=style/thumb800')
      }

      svg = `<g><svg style="${bright}" x="${this.props.x}" y="${this.props.y}" width="${this.props.width}" height="${this.props.height}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.props.width} ${this.props.height}" preserveAspectRatio="none">`
      svg += `<defs><mask id="mask-source-${this.id}">${this.states.maskCode}</mask>`
      if (hasFilter) {
        svg += `<filter id="filter-source-${this.id}">${getFilterHtml(this.options.filter)}</filter>`
      }
      svg += `</defs><image x="${this.options.clip.x}" y="${this.options.clip.y}" ${hasFilter ? `filter="url(#filter-source-${this.id})"` : ''} width="${this.options.clip.width}" height="${this.options.clip.height}" href="${this.states.base64}" preserveAspectRatio="none" transform="rotate(${this.props.imgRotate},${this.props.width/2} ${this.props.height/2})" mask="url(#mask-source-${this.id})"></image></svg></g>`
    } else {
      svg =
        `<g><svg x="${this.props.x}" y="${this.props.y}" width="${this.props.width}" height="${this.props.height}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.props.width} ${this.props.height}" preserveAspectRatio="none">
          <defs><mask id="mask-source-${this.id}">${this.states.maskCode}</mask></defs>
          <g mask="url(#mask-source-${this.id})"><rect width="${this.props.width}" height="${this.props.height}" fill="#ececec"></rect>
            <g transform="translate(${this.props.width / 2} ${this.props.height / 2})rotate(${-this.props.rotation})">
              <svg xmlns="http://www.w3.org/2000/svg" x="-31" y="-10" width="65" height="31" viewBox="0 0 113.61 62.81">
                <path fill="none" stroke="#a8a8a8" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M255.89,243.32l-1.12-1.92a1.65,1.65,0,0,0-1.43-.82h-6.69a1.65,1.65,0,0,0-1.43.82l-1.12,1.92a1.65,1.65,0,0,1-1.43.82h-.86a1.65,1.65,0,0,0-1.65,1.65v12a1.65,1.65,0,0,0,1.65,1.65h16.35a1.65,1.65,0,0,0,1.65-1.65v-12a1.65,1.65,0,0,0-1.65-1.65h-.86A1.65,1.65,0,0,1,255.89,243.32Z"
                  transform="translate(-196.62 -239.59)" />
                <path fill="none" stroke="#a8a8a8" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M251.84,255.33a4.48,4.48,0,1,1,2.45-2.79"
                  transform="translate(-196.62 -239.59)" />
                <text fill="#a8a8a8" font-size="12" transform="translate(0 40)">
                  拖拽照片至此处添加
                </text>
              </svg>
            </g>
          </g>
        </svg></g>`
    }
    return svg;
  }
}
