import { ImageCropperComponent } from '../../components/edit-tools/image-cropper/image-cropper.component';
import { Element } from '../base/element';
import { ToolBarImageComponent } from '../../components/editor-ui/t-tool-bar/tools/tool-bar-image/tool-bar-image.component';
import { getBase64fromImg, getUnitScale } from '../../utils/misc/common';
import { getFilterHtml } from '../../utils/misc/filter';
import { MenuItem } from '../ui/menu-item';
import { ToastIcon } from '@libs/modal/src/lib/models/toast-icon';
import { Container } from './container';
import { editorThumbProps } from '@libs/editor/src/lib/utils/misc/common';
/**
 * 图像元素
 */
export class Image extends Element {
  public type = 'image';
  onCreated() {
    this.config.update = true;
    this.config.toolbar = ToolBarImageComponent;
    this.config.menu = [
      new MenuItem({ title: '转换为相框', fn: async () => {
          const toast = window['editor'].stage.modal.toast({ icon: ToastIcon.LOADING, msg: '正在转换为相框', duration: 0 });
          await this.covertToContainer();
          toast.close();
          window['editor'].stage.modal.toast({ icon: ToastIcon.SUCCESS, msg: '已转换为相框' });
      }})
    ];
    if (!this.options.clip) {
      this.options.clip = {
        x: 0,
        y: 0,
        width: this.props.oWidth,
        height: this.props.oHeight
      };
    }
    // 双击:进入编辑模式
    this.on('dblclick', this.enterEditMode.bind(this));
    // 取消选择:退出编辑模式
    this.on('deselected', this.exitEditMode.bind(this));
    this.on('modified', (e) => {
      if (e.type === 'changeImage') {
        this.states.base64 = null
      }
    });
    this.states.html = this.toHTML();
  }
  enterEditMode() {
    this.states.editMode = true;
    this.config.transform = false;
    this.emit('enterEdit', {
      toolComponent: ImageCropperComponent
    });
  }
  exitEditMode() {
    if (this.states.editMode) {
      this.emit('exitEdit', {
        toolComponent: ImageCropperComponent
      });
      this.states.editMode = false;
      this.config.transform = true;
      this.states.html = this.toHTML();
    }
  }
  /**
   * 转换为容器
   */
  covertToContainer() {
    return new Promise(resolve => {
      const canvas = document.createElement('canvas');
      canvas.width = this.props.oWidth;
      canvas.height = this.props.oHeight;
      // 加载图片
      const ctx = canvas.getContext('2d');
      const image = document.createElement('img');
      image.crossOrigin = 'anonymous';
      image.src = this.options.url + '?t=' + new Date().getTime();
      image.onload = async () => {
        ctx.drawImage(image, 0, 0);
        const img = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const pix = img.data;
        for (let i = 0, n = pix.length; i < n; i += 4) {
          const alpha = pix[i + 3];
          pix[i] = alpha;
          pix[i + 1] = alpha;
          pix[i + 2] = alpha;
          pix[i + 3] = 255;
        }

        ctx.putImageData(img, 0, 0);
        const base64 = canvas.toDataURL('image/png');
        // 上传
        const res = await window['http'].post('/soft-ware-use/upload-img', {
          content: base64
        }).toPromise();
        // 创建容器元素,删除自身
        const newElement = new Container({
          id: window['editor'].getNewID(),
          props: {
            ...this.props
          },
          options: {
            mask: res.fileId,
            maskPath: res.path
          }
        });
        this.parent.addElement(newElement);
        this.remove();

        resolve(newElement);
      };
    })

  }

  /**
   * 验证图片布局
   * @param verifyAlign 验证水平垂直对齐
   */
  verifyClip(verifyAlign: boolean) {
    const {clip, fill} = this.options;
    const {width, height, oWidth, oHeight} = this.props;
    const type = fill && fill.type || 'inside';
    const alignX = fill && fill.alignX || 'center';
    const alignY = fill && fill.alignY || 'center';
    const imgRadio = oWidth / oHeight;
    const roomRadio = width / height;

    if (type === 'outside') {
      if (imgRadio > roomRadio && (clip.height < height || verifyAlign)) {
        clip.height = height;
        clip.width = imgRadio * height
      }

      if (imgRadio <= roomRadio && (clip.width < width || verifyAlign)) {
        clip.width = width;
        clip.height = width / imgRadio;
      }

    } else {
      if (imgRadio > roomRadio && (clip.width > width || verifyAlign)) {
        clip.width = width;
        clip.height = width / imgRadio;
      }

      if (imgRadio <= roomRadio && (clip.height > height || verifyAlign)) {
        clip.height = height;
        clip.width = imgRadio * height
      }
    }

    if (verifyAlign) {
      switch (alignX) {
        case 'left':
          clip.x = 0;
          break;
        case 'center':
          clip.x = (width - clip.width) / 2;
          break;
        case 'right':
          clip.x = width - clip.width;
          break;
      }

      switch (alignY) {
        case 'top':
          clip.y = 0;
          break;
        case 'center':
          clip.y = (height - clip.height) / 2;
          break;
        case 'bottom':
          clip.y = height - clip.height;
          break;
      }
    } else if (type === 'outside') {
      if (clip.x > 0) {
        clip.x = 0;
      }
      if (clip.y > 0) {
        clip.y = 0
      }

      if (clip.x + clip.width < width) {
        clip.x = width - clip.width
      }
      if (clip.y + clip.height < height) {
        clip.y = height - clip.height
      }
    } else {
      if (clip.x < 0) {
        clip.x = 0;
      }
      if (clip.y < 0) {
        clip.y = 0
      }

      if (clip.x + clip.width > width) {
        clip.x = width - clip.width
      }
      if (clip.y + clip.height > height) {
        clip.y = height - clip.height
      }
    }
  }

  toCombinationHtml() {
    const id = this.id,
      props = this.props,
      options = this.options,
      scale = this.parent.parent.scale;
    const clip = options.clip;
    const radius = this.options.radius ? this.options.radius * scale : 0;
    const hasFilter = options.filter && (options.filter.bright < 0 || options.filter.contrast !== 0 || options.filter.saturation !== 0 || options.filter.hue !== 0 || options.filter.blur !== 0 || options.filter.film !== 0);

    return `<svg style="position: absolute;border-radius:${radius}px;${options.filter && options.filter.bright > 0 ? 'filter:brightness('+ (options.filter.bright / 100 + 1) +')' : ''}" width="100%" height="100%"
             xmlns="http://www.w3.org/2000/svg"
             preserveAspectRatio="none"
             viewBox="0 0 ${props.width} ${props.height}">
             <defs>
                ${hasFilter ? '<filter id="filter-source-' + id + '">' + getFilterHtml(options.filter) + '</filter>' : ''}
             </defs>
             <image 
                x="${clip.x}"
                y="${clip.y}"
                width="${clip.width}" 
                height="${clip.height}" 
                crossorigin="anonymous" 
                preserveAspectRatio="none" 
                href="${options.url}${options.url && options.url.indexOf('.svg') < 0 ? '?x-oss-process=style/thumb800':''}"
                ${hasFilter ? 'filter="url(#filter-source-' + id + ')"' : ''}
                />
        </svg>`;
  }

  /**
   * 重写:获取当前元素的html结果
   * @returns {string} 根据元素信息生成的html
   */
  public toHTML(): string {
    const id = this.id,
      props = this.props,
      options = this.options,
      scale = this.parent.parent.scale;
    const radius = this.options.radius ? this.options.radius * scale : 0;
    const hasFilter = options.filter && (options.filter.bright < 0 || options.filter.contrast !== 0 || options.filter.saturation !== 0 || options.filter.hue !== 0 || options.filter.blur !== 0 || options.filter.film !== 0);


    return `<svg style="position: absolute;border-radius:${radius}px;${options.filter && options.filter.bright > 0 ? 'filter:brightness('+ (options.filter.bright / 100 + 1) +')' : ''}" width="100%" height="100%"
             xmlns="http://www.w3.org/2000/svg"
             preserveAspectRatio="none"
             viewBox="${options.clip.x} ${options.clip.y} ${
      options.clip.width
      } ${options.clip.height}">
             <defs>
                ${
      hasFilter
        ? '<filter id="filter-source-' + id + '">' +
        getFilterHtml(options.filter) +
        '</filter>'
        : ''
      }
             </defs>
                <image width="${props.oWidth}" height="${props.oHeight}" preserveAspectRatio="none" href="${options.url}${options.url && options.url.indexOf('.svg') < 0 ? '?x-oss-process=style/thumb800':''}" ${
      hasFilter ? 'filter="url(#filter-source-' + id + ')"' : ''
      }/>
        </svg>`;
  }

  async toSvg(): Promise<string> {
    const bright = this.options.filter && this.options.filter.bright > 0 ? `filter:brightness(${this.options.filter.bright / 100 + 1})` : '';
    const radius = this.options.radius ? this.options.radius * this.parent.parent.scale : 0;
    const hasFilter = this.options.filter && (this.options.filter.bright < 0 || this.options.filter.contrast !== 0 || this.options.filter.saturation !== 0 || this.options.filter.hue !== 0 || this.options.filter.blur !== 0 || this.options.filter.film !== 0);

    if (!this.states.base64 && !this.options.url.match(/.svg$/i)) {
      this.states.base64 = await getBase64fromImg(this.options.url + '?x-oss-process=style/thumb800')
    }

    const {x, y, width, height} = this.options.clip;
    let svg = `<g><svg style="${bright}" x="${this.props.x}" y="${this.props.y}" width="${this.props.width}" height="${this.props.height}" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="${x} ${y} ${width} ${height}"><defs>`
    if (hasFilter) {
      svg += `<filter id="filter-source-${this.id}">${getFilterHtml(this.options.filter)}</filter>`
    }
    if (radius) {
      svg += `<rect id="clip-rect-${this.id}" x="0" y="0" width="100%" height="100%" rx="${radius}"/><clipPath id="clip-path-${this.id}"><use xlink:href="#clip-rect-${this.id}" /></clipPath>`
    }
    svg += `</defs><image width="${this.props.oWidth}" height="${this.props.oHeight}" preserveAspectRatio="none" href="${this.states.base64 || this.options.url}" ${hasFilter ? `filter="url(#filter-source-${this.id})"` : ''}/></svg></g>`
    return svg;
  }

  changeForSignData(data) {
    const { width, height, x, y} = this.props;

    this.props.oWidth = data.width;
    this.props.oHeight = data.height;

    const clip = {
      x: 0,
      y: 0,
      width: data.width,
      height: data.height
    };

    if (data.width / data.height > width / height) {
      clip.height = data.width / width * height;
      clip.y = (data.height - clip.height) / 2
    } else {
      clip.width = data.height / height * width;
      clip.x = (data.width - clip.width) / 2
    }

    this.options.clip = clip;
    this.options.url = data.filePath;
    this.options.source = data.fileId;

    this.states.html = this.toHTML();
  }

  changeSignCombination(data) {
    if (!data || !data.fileId || !data.filePath || !data.width || !data.height) return;
    const {fileId, filePath, width, height} = data;
    const {props, options} = this;
    props.oWidth = width;
    props.oHeight = height;
    options.clip.width = width;
    options.clip.height = height;
    options.source = fileId;
    options.url = filePath;
    this.verifyClip(true);
  }
}
