import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import {
  AuthState
} from '@libs/core/src/lib/+state/auth.reducer';
import { AuthActionTypes, LoginSuccess, LoginSuccessLocal } from '@libs/core/src/lib/+state/auth.actions';
import { tap } from 'rxjs/operators';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Store } from '@ngrx/store';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import * as punycode from 'punycode';
import { Observable } from 'rxjs';
import { Env } from '@libs/core/src/lib/util/environment';

@Injectable()
export class AuthEffects {
  host: string;
  /**
   * 登录监听
   * @type {Observable<any>}
   */
  @Effect({ dispatch: false })
  loginSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoginSuccess),
    tap((action: LoginSuccess | LoginSuccessLocal) => {
      if (action.payload.user) {
        const data = action.payload.user;
        this.authService.isGuest = false;
        this.authService.user = data;
        this.localStorage.setItem('_token', data).subscribe();
        this.localStorage.setItem('_coop_id', data.coopId).subscribe();

        if (data.uidEncode) {
          this.deleteCoopCookie();
          const time = this.coop.coopInfo ? this.coop.coopInfo.cookieTime : 1296000000;
          this.cookieService.set(data.coopId+'_shanghu_cookie', data.uidEncode, this.futureDay(time), '/', this.host)

          if (data.accessToken && this.env.coop_id == '12146')
            this.cookieService.set(data.coopId+'_shanghu_token', data.accessToken, this.futureDay(time), '/', this.host);

          // 同一账号不能 同时登录
          if (data.login_key)
            this.cookieService.set(`${data.coopId}_cooperation_visit`, data.login_key, new Date(0x7fffffff * 1e3),'/', this.host)
        }

        if (this.authService.afterLoginMethod) {
          this.authService.afterLoginMethod();
          this.authService.afterLoginMethod = null;
        }
      }
    })
  );

  /**
   * 退出登录监听
   * @type {Observable<any>}
   */
  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType(AuthActionTypes.Logout),
    tap(() => {
      this.authService.user = null;
      this.authService.isGuest = true;
      this.localStorage.removeItem('_token').subscribe();
      this.localStorage.removeItem('_coop_id').subscribe();
      this.deleteCoopCookie();
    })
  );

  deleteCoopCookie () {
    let all_cookie = this.cookieService.getAll();
    for (let i in all_cookie) {
      if (/\d+_shanghu_cookie/.test(i)) {
        this.cookieService.delete(i, '/', this.host);
      }
    }
  }

  futureDay(milliseconds: number): Date {
    const date = new Date();
    date.setTime(date.getTime() + milliseconds);
    return date;
  }

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<AuthState>,
    private store: Store<AuthState>,
    private router: Router,
    private env: Env,
    private authService: AuthService,
    private localStorage: LocalStorage,
    private cookieService: CookieService,
    private coop: CoopService
  ) {
    this.host = punycode.toASCII(location.hostname);
    if (this.host.split('.').length > 2)
      this.host = this.host.slice(this.host.indexOf('.') + 1);
    console.log(this.host);
  }
}
