import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { WaterfallItemDirective } from '@libs/shared/src/lib/directives/waterfall-item.directive';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { ActivatedRoute } from '@angular/router';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

export interface WaterData {
  data: TemplateOfficial;
  index: number;
  top: number;
  left: number;
  width: number;
  height: number;
  thumbnail: {
    aHeight: number,
    aWidth: number,
    padding: number,
    rankNumber: number,
    heightNumber: number
  }
}

@Component({
  selector: 'app-waterfall',
  templateUrl: './waterfall.component.html',
  styleUrls: ['./waterfall.component.css']
})
export class WaterfallComponent implements OnInit, OnChanges {
  //列表前面插入的内容（组件数组）

  @Input() previous: any[];
  //整体宽度
  @Input() width: number;
  //模版数据
  @Input() source: TemplateOfficial[];
  //外边框距离
  @Input() padding = 9;
  //除了模版宽高内边距之外的高度（模版信息的占位）
  @Input() fixHeight = 0;
  //横向间隔
  @Input() getterX = 10;
  //纵向间隔
  @Input() getterY = 10;
  //瀑布流子组件
  @Input() cell;
  //分类
  @Input() current;
  @Input() favorite = 'normal';
  // 路由参数
  @Input() query;
  @ViewChildren(WaterfallItemDirective) waterItem: QueryList<WaterfallItemDirective>;
  @Output() action = new EventEmitter<{ method: string, data: any }>();
  //总高度
  scrollHeight: number;
  //最终输出数据
  renderData: WaterData[];
  //计算
  matrix: number[];
  //列数
  column: number;
  //单位宽度
  unitW: number;

  prevData: any[];

  constructor() {
  }

  ngOnInit() {
    this.calcMatrix();
  }

  ngOnChanges(change: SimpleChanges) {
    if (
      change.source && !change.source.firstChange ||
      change.width && !change.width.firstChange ||
      change.query && !change.query.firstChange
    ) {
      this.calcMatrix();
    }
  }

  calcMatrix() {
    this.calcUnit(this.width);
    const { getterX, getterY, fixHeight, source, padding, column, unitW } = this;
    this.renderData = [];
    this.prevData = [];
    this.matrix = [];
    for (let i = 0; i < column; i++) {
      this.matrix[i] = 0;
    }
    //如果列表前有按钮
    if (this.previous) {
      this.previous.forEach(comp => {
        const data = source[0];
        if (!data.height || !data.width) return false;
        const width = +unitW.toFixed(5);
        const height = (unitW - padding * 2) * data.height / data.width + padding * 2 + fixHeight -50;
        const minIndex = this.matrixMinIndex();
        const top = this.matrix[minIndex];
        const left = (+unitW + getterX) * minIndex;
        this.prevData.push({ comp, data, top, left, width, height });
        this.matrix[minIndex] += height + getterY;
      });
    }
    if(!source) return;
    source.forEach((data, index) => {
      const width = +unitW.toFixed(5);
      let height;
      //横向单个图片长度
      let aWidth = 0;
      //竖向单个图片高度
      let aHeight = 0;
      //横向需要展示几行
      let rankNumber;
      //竖向可以排列几列
      let heightNumber;
      if (!data.height || !data.width) return false;
      //判断是否需要展示所有缩略图
      if (data.classify && data.classify.thumbnailConfig && (typeof data.classify.thumbnailConfig === 'object') && data.pages && data.pages.length > 1) {
        rankNumber = data.sizeInfo.width > data.sizeInfo.height ? data.classify.thumbnailConfig.across : data.classify.thumbnailConfig.vertical;
        heightNumber = Math.ceil(data.pages.length / rankNumber);
        aWidth = (width - (rankNumber + 1) * padding - 20) / rankNumber;
        aHeight = aWidth / data.sizeInfo.width * data.sizeInfo.height;
        height = aHeight * heightNumber + (heightNumber + 1) * padding + fixHeight + 20 - 50;
      } else {
        //height = (unitW - padding * 2) * data.height / data.width + padding * 2 + fixHeight  - 50;
        height = (unitW - padding * 2) * data.height / data.width + padding * 2;
      }
      const minIndex = this.matrixMinIndex();
      const top = this.matrix[minIndex];
      const left = (+unitW + getterX) * minIndex;
      this.renderData.push({
        data,
        index,
        top,
        left,
        width,
        height,
        thumbnail: { aHeight, padding, aWidth, rankNumber, heightNumber }
      });
      this.matrix[minIndex] += height + getterY;
    });
    this.scrollHeight = Math.max.apply(null, this.matrix);
  }

  calcUnit(width) {
    this.column = this.calcColumn(width);
    this.unitW = (width - this.getterX * (this.column - 1)) / this.column;
  }

  calcColumn(width) {
    if (this.source && this.source[0].classify && this.source[0].classify.thumbnailConfig && (typeof this.source[0].classify.thumbnailConfig === 'object')) return 3;
    if (width > 1460) return 5;
    else return 4;

  }

  matrixMinIndex() {
    const min = Math.min.apply(null, this.matrix);
    return this.matrix.indexOf(min);
  }

  actionFun($event) {
    if ($event.method === 'delete') {
      this.source.splice($event.data.index, 1);
      this.calcMatrix();
    }
  }

}
