import { EditorService } from '../../../services/editor.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { deepClone, getUnitScale } from '../../../utils/misc/common';
import * as $ from 'jquery';

@Component({
  selector: 'app-mask-image-cropper',
  templateUrl: './mask-image-cropper.component.html',
  styleUrls: ['./mask-image-cropper.component.scss']
})
export class MaskImageCropperComponent implements OnInit {
  // 当前按下的角点
  public corner = null;
  // 鼠标按下时记录的clip信息
  public beforeClip;
  public get activeElement(): any {
    return this.editor.activeElements[0];
  }
  public get scale(): any {
    return this.editor.template.scale * this.editor.zoom;
  }
  constructor(public editor: EditorService) { }

  ngOnInit(): void {
    this.activeElement.states.clip = deepClone(this.activeElement.options.clip);
  }
  /**
   * 获取原始图像样式
   */
  getOImageStyle(): any {
    if (this.editor.activeElements.length !== 1) return;
    const elementItem = this.editor.activeElements[0];
    const props = elementItem.props,
      states = elementItem.states,
      options = elementItem.options;
    const scale =
      this.editor.template.scale * this.editor.zoom;

    const style = {
      width: states.clip.width * scale + 'px',
      height: states.clip.height * scale + 'px',
      transform: `translate(${states.clip.x * scale}px,${states.clip.y *
        scale}px)`
    };
    return style;
  }

  /**
   * 获取SVG样式
   */
  getElementStyle(): any {
    if (this.editor.activeElements.length !== 1) return;
    const elementItem = this.editor.activeElements[0];
    const props = elementItem.props;
    const scale =
      this.editor.template.scale * this.editor.zoom;
    const style = {
      left: this.editor.stagePos.x + props.x * scale + 'px',
      top: this.editor.stagePos.y + props.y * scale + 'px',
      width: props.width * scale + 'px',
      height: props.height * scale + 'px',
      transform: `rotate(${props.rotation}deg)`
    };
    return style;
  }
  /**
   * 获取当前角点是否处在选择状态
   * @param corner 角点
   */
  getCornerSelectedState(corner): any {
    return this.corner === corner;
  }
  /**
   * 获取操作div样式
   */
  getOptionsStyle(): any {
    const bbox = $('.oImg-image')[0].getBoundingClientRect();
    const style = {
      left: bbox.right + 10 + 'px',
      top: bbox.top + bbox.height / 2 + 'px'
    };
    return style;
  }
  /**
   * 事件:缩放角点被按下
   * @param corner 角点
   */
  onCornerMouseDown(corner, event: MouseEvent): void {
    this.corner = corner;
    this.beforeClip = deepClone(this.activeElement.states.clip);
    const mouse = this.editor.mouse;
    mouse.downX = mouse.x;
    mouse.downY = mouse.y;
    event.stopPropagation();
  }
  /**
   * 事件:窗口鼠标移动
   */
  @HostListener('document:mousemove', ['$event']) onWindowMouseMove(): void {
    if (this.corner) {
      const activeElement = this.activeElement,
        { props, options, states } = activeElement,
        mouse = this.editor.mouse,
        widthRatio = states.clip.oWidth / states.clip.oHeight,
        heightRatio = states.clip.oHeight / states.clip.oWidth,
        // 计算实际移动位置
        angel = (activeElement.props.rotation * Math.PI) / 180,
        scale = this.editor.template.scale;
      // 右,下,右下三点
      const distanceX =
        (Math.cos(angel) * (mouse.x - mouse.downX) +
          Math.sin(angel) * (mouse.y - mouse.downY)) /
        scale;
      const distanceY =
        (Math.cos(angel) * (mouse.y - mouse.downY) -
          Math.sin(angel) * (mouse.x - mouse.downX)) /
        scale;
      switch (this.corner) {
        case 'move':
          states.clip.x = this.beforeClip.x + distanceX;
          states.clip.y = this.beforeClip.y + distanceY;

          break;
        case 'tl':
          states.clip.x = this.beforeClip.x + distanceX;
          states.clip.y = this.beforeClip.y + distanceX * heightRatio;
          states.clip.width = this.beforeClip.width - distanceX;
          states.clip.height = this.beforeClip.height - distanceX * heightRatio;

          break;
        case 'tr':
          states.clip.y = this.beforeClip.y - distanceX * heightRatio;
          states.clip.width = this.beforeClip.width + distanceX;
          states.clip.height = this.beforeClip.height + distanceX * heightRatio;

          break;
        case 'bl':
          states.clip.x = this.beforeClip.x + distanceX;
          states.clip.width = this.beforeClip.width - distanceX;
          states.clip.height = this.beforeClip.height - distanceX * heightRatio;

          break;
        case 'br':
          states.clip.width = this.beforeClip.width + distanceX;
          states.clip.height = this.beforeClip.height + distanceX * heightRatio;

          break;
      }
      // 限制图片移动范围和缩放
      if (states.clip.width < props.width) {
        states.clip.width = props.width;
        states.clip.height = props.width * heightRatio;
      }
      if (states.clip.height < props.height) {
        states.clip.height = props.height;
        states.clip.width = props.height * widthRatio;
      }
      if (states.clip.x > 0) {
        states.clip.x = 0;
      }
      if (states.clip.y > 0) {
        states.clip.y = 0;
      }
      if (states.clip.x < -states.clip.width + props.width) {
        states.clip.x = -states.clip.width + props.width;
      }
      if (states.clip.y < -states.clip.height + props.height) {
        states.clip.y = -states.clip.height + props.height;
      }
    }
  }
  /**
   * 事件:窗口鼠标松开
   */
  @HostListener('document:mouseup', ['$event']) onWindowMouseUp(): void {
    this.corner = undefined;
  }
  /**
   * 应用修改被单击
   */
  onApplyClick(): void {
    // 修改clip
    this.activeElement.options.clip = this.activeElement.states.clip;
    this.activeElement.emit('modified', {
      type: 'clip',
      target: this.activeElement
    });
    this.activeElement.exitEditMode();
  }
  /**
   * 取消修改被单击
   */
  onCancelClick(): void {
    this.activeElement.exitEditMode();
  }

  /**
   * svg文件不加oss后缀
   * @param url
   * @returns {string}
   */
  imgUrlJust(url): string {
    if (url.indexOf('.svg') < 0) {
      url += '?x-oss-process=style/thumb800';
    }
    return url;
  }
}
