import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TemplateMember } from '@libs/core/src/lib/models/template-member';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { url_atob, url_btoa } from '@libs/core/src/lib/util/base64';

export interface DesignParam {
  title?: string;
  width?: number;
  height?: number;
  unit?: string;
  // 官方模板来源
  from?: number;
  fromDesign?: number;
  // 拓展尺寸id
  cid?: number;
  // 编辑模板
  templateId?: number;
  templateIds?: number[];
  editFrom ?: number;
  classifyPid?: number;
  likePid?: number;
  classifyId?: number;
  templateClassifyId?: number;
  //名片配置
  sign?: any;
  size?: any;
  key?: any;
  //拍拍印定制
  lockSize?: any;
  callbackUrl?: string;
  templateTitle?: string;
  // 来源 用户还是官方
  method: string;
  // 放单相关数据
  taskOrderId?: number;
  taskOrderLinkId?: number;
}

@Injectable({
  providedIn: 'root'
})
export class DesignParamService {
  constructor(
    private coop: CoopService,
    @Inject(PLATFORM_ID) private platFormId: object
  ) { }

  /**
   * 返回用户编辑的链接
   * @param {number} templateId
   * @returns {string}
   */
  getMemberUpdateUrl(templateId: number, template = null, params = {}): string {
    return this.getParam({ method: 'member', templateId: templateId, ...params }, template);
  }

  getMemberNologin(key: number, template = null, params = {}) {
    return this.getParam({ method: 'guest-member', key: key, ...params }, template);
  }

  /**
   * 生成用户自定义模板的Url
   * @param data
   * @returns {string}
   */
  getMemberDiy(data: any,template = null): string {
    return this.getParam({
      ...data,
      method: 'member'
    }, template);
  }

  /**
   * 返回用户新建模板的链接
   * @param {number} templateId
   * @returns {string}
   */
  getMemberCreateUrl(templateId: number, template = null): string {
    return this.getParam({ method: 'member', from: templateId }, template);
  }

  getWulfulUrl(templateId: number, template = null, cid, size): string {
    if (size && size.loginCode) delete size.loginCode;
    return this.getParam({ method: 'member', from: templateId, cid, size }, template);
  }

  getCardCreateUrl(templateId: number, sign): string {
    return this.getParam({ method: 'member', from: templateId, sign });
  }

  getOfficialUrl(templateId: number, template?: TemplateMember): string {
    return this.getParam({ method: 'official', templateId: templateId },template);
  }
  getdesignerUrl(templateId: number, template?: TemplateMember): string {
    return this.getParam({ method: 'designer', templateId: templateId },template);
  }

  getParam(data: DesignParam, template?: TemplateMember): string {
    //console.log('this.coop.coopInfo',this.coop.coopInfo);
    const info = this.coop.coopInfo;
    let urlPrev, back_url = sessionStorage.getItem('coop_callback_url'),
      template_title = sessionStorage.getItem('coop_template_title');

    if (data.title) data.title = encodeURIComponent(data.title);
    if (back_url) data.callbackUrl = back_url;
    if (template_title) data.templateTitle = template_title;

    const classifyPid = template ? (template.classifyPid || (template.classify ? template.classify.pid : 0)) : 0;
    const likePid = template ? (template.likePid || classifyPid) : 0;

    if (info.id == 10682 || info.id == 13018) {
      urlPrev = '/design-for-you/';
    } else {
      if (info.id == 12718) {
        urlPrev = '/design-mama/';
      } else {
        urlPrev = '/design/';
      }

      if (likePid == 1 && info.diy_open == '1') {
        urlPrev += 'photo/';
      } else if (info.diy_open == '0') {
        urlPrev += 'cate/';
      }
    }

    if (!data.classifyPid) {
      data.classifyPid = classifyPid;
    }
    if (!data.likePid) {
      data.likePid = likePid;
    }

    return urlPrev + url_btoa(encodeURIComponent(JSON.stringify(data)));
  }

  parseParam(str: string): DesignParam {
    let parse;
    parse = JSON.parse(decodeURIComponent(url_atob(str)));
    if (parse.title) parse.title = decodeURIComponent(parse.title);
    return parse;
  }
}
