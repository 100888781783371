import { Category } from '@libs/core/src/lib/models/category';
import { Classify, ClassifyConfig } from '@libs/core/src/lib/models/classify';
import { Member } from '@libs/core/src/lib/models/member';
import { Tag } from '@libs/core/src/lib/models/tag';
import { TemplateMemberPages } from '@libs/core/src/lib/models/template-member-pages';

export class TemplateOfficial {
  constructor(
    //
    public templateId: number,
    public template_id: string,
    public classifyId: number,
    public classifyPid: number,
    public likePid: number,
    public imageCount: number,
    public templateIntroduce: string,
    //  用户id
    public userId: number,
    //  商户id
    public cooperationId: number,
    public coopId: number,
    public customTags: string[],
    //  模板标题
    public title: string,
    //  模板缩略图
    public thumbnailUrl: string,
    public thumbnail_url: string,
    public isEdit: number,
    public parentName: string,

    // 统计数据
    public extendJson: {
      container_counts: number// 模版内容器数量
    },

    //  模板id
    public thumbnailId: number,
    public isRender: number,
    public pages: TemplateMemberPages[],
    //  状态
    public status: number,
    //  创建时间
    public createdAt: number,
    //  修改时间
    public updatedAt: number,
    //  修改时间
    public onlineAt: string,
    //  模板价格
    public price: number,
    //  编辑量
    public amountEdit: number,
    //  虚拟编辑量
    public virtualEdit: number,
    //  浏览量
    public amountView: number,
    //  虚拟浏览量
    public virtualView: number,
    //  收藏量
    public amountFavorite: number,
    //  虚拟收藏量
    public virtualFavorite: number,
    public width: number,
    public height: number,
    public editConfig: EditorConfig,
    public classifyConfig: ClassifyConfig[],
    public sizeInfo: {
      width: number,
      height: number,
      unit: string
    },
    //  购买量
    public amountBuy: number,
    //  排序
    public sort: number,
    // 是否收藏
    public isFavorite: number,
    //  模板数据
    public content: string,
    public isBuy: number,
    public member: Member,
    public category: Category,
    public classify: Classify,
    public pageNum: string,
    public coopUserUrl: string, // 拍拍印定制 子商户官方模板提交跳转链接
    public tags: { industry?: Tag[]; style?: Tag[]; function?: Tag[] }
  ) { }

  getTagIds(key: string): Tag[] {
    return [];
  }
}


export interface EditorConfig {
  addPage: boolean;
  bleed: string;
  default_page: string;
  dpi: string;
  height: string;
  print: boolean;
  resize: boolean;
  showFillPicture: boolean;
  split: string;
  unit: string;
  width: string;
  zoom: string;
}
