import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { MetaService } from '@ngx-meta/core';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(
    private meta: MetaService
  ) { }

  setTitleFromResponse(data: HttpResponse<any>) {
    const title = data.headers.get('Og-title');
    const keywords = data.headers.get('Og-keywords');
    const description = data.headers.get('Og-description');

    if (title !== null)
      this.meta.setTitle(decodeURIComponent(title));

    if (keywords !== null)
      this.meta.setTag('keywords', decodeURIComponent(keywords));

    if (description !== null)
      this.meta.setTag('description', decodeURIComponent(keywords));

  }

}
