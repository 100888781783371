import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { CoopService } from '@libs/core/src/lib/services/coop.service';

@Component({
  selector: 'app-ppy-hint',
  templateUrl: './ppy-hint.component.html',
  styleUrls: ['./ppy-hint.component.scss']
})
export class PpyHintComponent implements OnInit {
  @Input() url: string;
  constructor(
    public modal: NzModalRef,
    private coop: CoopService
  ) { }

  ngOnInit() {
    console.log(this.url);
  }

  sure() {
    if (this.url) {
      this.modal.close();
      window.open(this.url);
    } else {
      window.open(this.coop.coopInfo.coop_pc_domain);
    }
  }

}
