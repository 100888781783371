import { Component, OnInit } from '@angular/core';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { Login, Logout } from '@libs/core/src/lib/+state/auth.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.scss']
})
export class SimpleHeaderComponent implements OnInit {

  constructor(
    public coop: CoopService,
    public auth: AuthService,
    private store: Store<any>
  ) { }

  ngOnInit() {
  }

  logout(): void {
    this.store.dispatch(new Logout());
  }

  login(): void {
    this.store.dispatch(new Login());
  }

}
