import { Component, OnInit } from '@angular/core';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';

@Component({
  selector: 'app-curve-text-tool',
  templateUrl: './curve-text-tool.component.html',
  styleUrls: ['./curve-text-tool.component.scss']
})
export class CurveTextToolComponent implements OnInit {
  constructor(public editor: EditorService) { }

  ngOnInit() {
  }
  setDir(dir) {

    const el = this.editor.activeElements[0];
    el.applyScaleToHTML();
    delete el.options.url;
    delete el.options.v4svg;
    if (!el.options.isNewText){
      el.options.isNewText = true;
      el.changeTextY();
    }
    if (el.options.directWrite && sessionStorage.getItem('directWrite') != el.options.directWrite) {
      el.options.directWrite = sessionStorage.getItem('directWrite');
    }
    if (dir === 0) {
      delete el.options.curve;
      el.props.height = el.getTextInfo(el.options.text).height;
      el.props.oHeight = el.props.height / el.options.scale;
    } else {
      const curveobj = el.getCurvedText(el.options.text, el.props.width, dir);
      el.options.mode = 'h';
      el.options.curve = {
        dir,
        html: curveobj.html
      };
      el.props.height = el.props.oHeight = curveobj.height;
    }
    el.states.html = el.toHTML();
    this.editor.snapshot();
    el.emit('modified', {
      type: 'curve',
      target: el
    });
  }
}
