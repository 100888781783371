import { ToolBarTableComponent } from '../../components/editor-ui/t-tool-bar/tools/tool-bar-table/tool-bar-table.component';
import { TableTextEditorComponent } from '../../components/edit-tools/table/table-text-editor/table-text-editor.component';
import { TableCellEditorComponent } from '../../components/edit-tools/table/table-cell-editor/table-cell-editor.component';
import { Element } from '../base/element';
import { getSVGText, getTableStyle } from '../../utils/misc/common';
import * as $ from 'jquery';
export class Table extends Element {
  public type = 'table';
  onCreated() {
    this.config.toolbar = ToolBarTableComponent;
    this.config.update = true;
    this.config.multiSelectToolBar = false;
    this.config.corner = {
      ...this.config.corner,
      tl: false,
      tr: false,
      mt: false,
      ml: false,
      bl: false,
      rotate: false
    };
    this.states.editMode = {
      cell: false,
      text: false
    };
    // 表格被选择时,显示单元格编辑工具
    this.on('selected', e => {
      if (e.targets) return;
      this.enterCellEditMode();
    });
    // 表格双击时,显示表格文本编辑工具
    this.on('dblclick', e => {
      this.enterTextEditMode();
      this.exitCellEditMode();
    });
    // 表格取消选择时,销毁单元格编辑工具与文本表格编辑工具
    this.on('deselected', e => {
      this.exitCellEditMode();
      this.exitTextEditMode();
    });
    // // 若拖动角点时正在文本编辑状态,则退出
    // this.on('cornermousedown', this.exitTextEditMode.bind(this));
    // 缩放时整体缩小单元格高度
    // 请求样式信息
    // axios.get(this.options.url).then(res => {
    //   this.states.style = res.data;
    //   this.states.html = this.toHTML();
    // }).catch(err => {
    //   console.error('[元素加载失败]', err);
    // });
    this.states.html = this.toHTML();
  }
  /**
   * 进入单元格编辑模式
   */
  public enterCellEditMode() {
    if (this.states.editMode.cell === false) {
      this.states.editMode.cell = true;
      this.emit('enterEdit', {
        target: this,
        toolComponent: TableCellEditorComponent
      });
    }
  }
  /**
   * 退出单元格编辑模式
   */
  public exitCellEditMode() {
    if (this.states.editMode.cell === true) {
      this.states.editMode.cell = false;
      this.emit('exitEdit', {
        target: this,
        toolComponent: TableCellEditorComponent
      });
    }
  }
  /**
   * 进入表格文本编辑模式
   */
  public enterTextEditMode() {
    if (this.states.editMode.text === false) {
      this.states.editMode.text = true;
      this.states.html = this.toHTML();
      this.emit('enterEdit', {
        target: this,
        toolComponent: TableTextEditorComponent
      });
      this.config.corner = {
        ...this.config.corner,
        mr: false,
        br: false,
        mb: false
      };
      // this.states.activeCell = this.options.cell[0][0];
    }
  }
  /**
   * 退出表格文本编辑模式
   */
  public exitTextEditMode() {
    if (this.states.editMode.text === true) {
      this.states.editMode.text = false;
      // 生成所有单元格文字的svg并存储到svg属性
      for (const row of this.options.cell) {
        for (const col of row) {
          const cell = getSVGText({
            ...col,
            color: col.color.nColor,
            // textarea和text的字间距差距太大，修正
            letterSpacing: col.letterSpacing / 5
          });
          col.svg = cell;
        }
      }
      this.states.html = this.toHTML();
      this.emit('exitEdit', {
        target: this,
        toolComponent: TableTextEditorComponent
      });
      this.emit('modified', {
        target: this,
        type: 'table-text'
      });

      this.config.corner = {
        ...this.config.corner,
        mr: true,
        br: true,
        mb: true
      };
    }
  }
  public toHTML(): string {
    const scale = this.parent.parent.scale;
    let $svgItem = $('<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" preserveAspectRatio="none"></svg>');
    let table_data = this.options.cell;
    let row_controller = this.options.row;
    let column_controller = this.options.col;
    let style = this.options.style;
    let width = this.props.width * scale;
    let height = this.props.height * scale;
    let total_row_height = 0;
    let total_column_width = 0;
    for (let i = 0; i < row_controller.length; i++) {
      total_row_height = total_row_height + row_controller[i];
    }
    for (let i = 0; i < column_controller.length; i++) {
      total_column_width = total_column_width + column_controller[i];
    }
    let $defs = $('<defs></defs>');
    //遍历表格数据
    for (let row = 0; row < table_data.length; row++) {
      let row_item = table_data[row];
      //当前表列读取头位置
      for (let column = 0; column < row_item.length; column++) {
        //获取样式
        let style_item = getTableStyle(row, column, table_data.length, row_item.length, style);
        let cell = row_item[column];
        let cell_column_width = 0;
        let cell_row_height = 0;
        cell_row_height = row_controller[row];
        cell_column_width = column_controller[column];

        let cell_x = 0;
        let cell_y = 0;
        for (let i = 0; i < column; i++) {
          cell_x = cell_x + column_controller[i];
        }
        for (let i = 0; i < row; i++) {
          cell_y = cell_y + row_controller[i];
        }
        let cell_x_final = ((cell_x / total_column_width) * width) + (style_item.paddingLeft);
        let cell_y_final = ((cell_y / total_row_height) * height) + (style_item.paddingTop);

        let row_height_final = ((cell_row_height / total_row_height) * height) - (style_item.paddingLeft) - (style_item.paddingBottom);
        let column_width_final = ((cell_column_width / total_column_width) * width) - (style_item.paddingTop) - (style_item.paddingRight);

        let $cellItem = $('<g></g>');
        $cellItem.attr('clip-path', 'url(#clip_' + this.id + '_' + row + '_' + column + ')');
        let svg_rect = null;

        if (style_item.specialShape === undefined) {
          if (column_width_final > 0 && row_height_final > 0) {
            $cellItem.append('<rect data-col="' + column + '" data-row="' + row + '" data-id="' + this.id + '" fill="' + style_item.background.nColor + '" width="' + column_width_final + '" height="' + row_height_final + '" x="' + cell_x_final + '" y="' + cell_y_final + '"></rect>');
          }
        } else if (style_item.specialShape === 'rect1') {

          let bound_height = 15;
          if (column_width_final > 0 && (bound_height * 2) > 0) {
            $cellItem.append('<rect fill="' + style_item.background.nColor + '" width="' + column_width_final + '" height="' + (bound_height * 2) + '" x="' + cell_x_final + '" y="' + cell_y_final + '" rx="' + bound_height + '" ry="' + bound_height + '"></rect>');
          }
          if (column_width_final > 0 && (row_height_final - bound_height) > 0) {
            $cellItem.append('<rect data-col="' + column + '" data-row="' + row + '" data-id="' + this.id + '" fill="' + style_item.background.nColor + '" width="' + column_width_final + '" height="' + (row_height_final - bound_height) + '" x="' + cell_x_final + '" y="' + (cell_y_final + bound_height) + '"></rect>');
          }
        }
        //边框填充
        let padding = 20;
        //添加文字
        if (cell.svg && !this.states.editMode.text) {
          let $textItem = $(cell.svg);

          $defs.append('<clipPath id="clip_' + this.id + "_" + row + '_' + column + '"><rect x="' + (cell_x_final) + '" y="' + cell_y_final + '" width="' + column_width_final + '" height="' + row_height_final + '"/></clipPath>');

          //如果宽度大于本身宽度,则设置相等的宽度
          if (column_width_final < $textItem.attr('width')) {
            if (column_width_final < 0) {
              $textItem.attr('width', 0);
            }
          }

          if (row_height_final < $textItem.attr('height')) {
            if (row_height_final < 0) {
              $textItem.attr('height', 0);
            }
          }

          //水平位置确认
          if (cell.align === 'left') {
            //水平居左
            $textItem.attr('x', cell_x_final + padding);

          } else if (cell.align === 'center') {
            //水平居中

            $textItem.attr('x', cell_x_final + (column_width_final / 2) - ($textItem.attr('width') / 2));

          } else if (cell.align === 'right') {
            //水平居右
            $textItem.attr('x', cell_x_final + (column_width_final - $textItem.attr('width')) - padding);
          }

          $textItem.attr('y', cell_y_final + (row_height_final / 2) - (parseFloat($textItem.attr('height')) / 2));
          $cellItem.html($cellItem.html() + $textItem[0].outerHTML);
        }
        //左边线条
        if (style_item.borderLeftColor) {
          let x1 = cell_x_final + ((style_item.borderLeftWidth) / 2);
          let y1 = cell_y_final;
          let x2 = cell_x_final + ((style_item.borderLeftWidth) / 2);
          let y2 = cell_y_final + row_height_final;
          $cellItem.append('<line stroke="' + style_item.borderLeftColor.nColor + '" x1="' + x1 + '" y1="' + y1 + '" x2="' + x2 + '" y2="' + y2 + '" stroke-width="' + (style_item.borderLeftWidth) + '"></line>');
        }
        //顶边线条
        if (style_item.borderTopColor) {
          let x1 = cell_x_final;
          let y1 = cell_y_final + ((style_item.borderTopWidth) / 2);
          let x2 = cell_x_final + column_width_final;
          let y2 = cell_y_final + ((style_item.borderTopWidth) / 2);
          $cellItem.append('<line stroke="' + style_item.borderTopColor.nColor + '" x1="' + x1 + '" y1="' + y1 + '" x2="' + x2 + '" y2="' + y2 + '" stroke-width="' + (style_item.borderTopWidth) + '"></line>');
        }
        //底边线条
        if (style_item.borderBottomColor) {
          let x1 = cell_x_final;
          let y1 = cell_y_final + row_height_final - ((style_item.borderBottomWidth) / 2);
          let x2 = cell_x_final + column_width_final;
          let y2 = cell_y_final + row_height_final - ((style_item.borderBottomWidth) / 2);
          $cellItem.append('<line stroke="' + style_item.borderBottomColor.nColor + '" x1="' + x1 + '" y1="' + y1 + '" x2="' + x2 + '" y2="' + y2 + '" stroke-width="' + (style_item.borderBottomWidth) + '"></line>');
        }
        //右边线条
        if (style_item.borderRightColor) {
          let x1 = cell_x_final + column_width_final - ((style_item.borderRightWidth) / 2);
          let y1 = cell_y_final;
          let x2 = cell_x_final + column_width_final - ((style_item.borderRightWidth) / 2);
          let y2 = cell_y_final + row_height_final;
          $cellItem.append('<line stroke="' + style_item.borderRightColor.nColor + '" x1="' + x1 + '" y1="' + y1 + '" x2="' + x2 + '" y2="' + y2 + '" stroke-width="' + (style_item.borderRightWidth) + '"></line>');
        }

        $svgItem.append($cellItem);
      }
    }
    $svgItem.append($defs);
    return $svgItem[0].outerHTML;
  }

}
