import { Component, OnInit } from '@angular/core';
import { EditorService } from '../../../../../services/editor.service';

@Component({
  selector: 'app-line-tool',
  templateUrl: './line-tool.component.html',
  styleUrls: ['./line-tool.component.scss']
})
export class LineToolComponent implements OnInit {
  defaultDashArray = [[], [5, 5], [10, 5], [10, 5, 5, 5], [8, 8], [3, 3]];
  constructor(public editor: EditorService) { }
  ngOnInit() { }
  getDashArrayStr(item) {
    return item.map(i => i / 2).join();
  }
  /**
   * 事件:属性被修改
   * @param name 属性
   * @param value 值
   * @param fireEvent 是否触发事件
   */
  onChange(name, value, fireEvent = true): void {
    this.editor.activeElements.forEach(eleItem => {
      eleItem.options[name] = value;
      eleItem.states.html = eleItem.toHTML();
    });
    if (fireEvent) {
      if (this.editor.activeElements.length === 1) {
        this.editor.activeElements[0].emit('modified', {
          type: 'set' + name,
          target: this.editor.activeElements[0]
        });
      } else if (this.editor.activeElements.length > 1) {
        this.editor.activeElements.forEach(eleItem => {
          eleItem.emit('modified', {
            type: 'set' + name,
            target: eleItem,
            targets: this.editor.activeElements,
            stopPropagation: true
          });
        });
        this.editor.activeElements[0].parent.emit('element:modified', {
          type: 'set' + name,
          targets: this.editor.activeElements
        });
      }
    }
  }
}
