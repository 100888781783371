import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { LoginSuccess } from '@libs/core/src/lib/+state/auth.actions';
import { User } from '@libs/core/src/lib/models/user';
import { DomSanitizer } from '@angular/platform-browser';
import { Env } from '@libs/core/src/lib/util/environment';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { AuthService } from '@libs/core/src/lib/services/auth.service';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.scss']
})
export class OauthComponent implements OnInit {
  @Input() url;
  loginUrl: any;

  constructor(
    private env: Env,
    private auth: AuthService,
    private coop: CoopService,
    private sanitizer: DomSanitizer,
    private modalRef: NzModalRef,
    private store: Store<any>
  ) {
  }

  ngOnInit() {
    this.loginUrl = this.safeUrl();
  }

  @HostListener('window:message', ['$event'])
  handleMessage(event: MessageEvent): void {
    if (event.data.payStatus === true) {
      this.modalRef.close();
      window.location.href = this.coop.coopInfo.coop_pc_domain+"/customer/user/order/50";
    }

    // 拍拍印登录iframe 回调
    if (this.coop.coopInfo.id == 12146) {
      if (event.data.event == 'closeMe') {
        this.modalRef.close();
      } else if (event.data.event == 'loginOk') {
        this.auth.codeLogin(event.data.loginCode).subscribe(user => {
            this.auth.user = user;
            this.auth.isGuest = false;
            this.store.dispatch(new LoginSuccess({ user: user }));
        })
      }
    }
  }

  safeUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  close() {
    this.modalRef.close()
  }

}
