import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compatible',
  templateUrl: './compatible.component.html',
  styleUrls: ['./compatible.component.scss']
})
export class CompatibleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
