import { forwardRef, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Order } from '@libs/core/src/lib/models/order';
import { MemberCharge } from '@libs/core/src/lib/models/member-charge';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import {
  apiMemberCharge, apiPayMenu,
  apiWarchOrder,apiBuyVip
} from '@libs/core/src/lib/util/api';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { PayMenu } from '@libs/core/src/lib/models/pay-menu';
import { Env } from '@libs/core/src/lib/util/environment';
import { Observable, of } from 'rxjs';
import { delay, retryWhen, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class PayService {
  // 是否弹出显示二维码
  modalWechat = false;
  payment = 'alipay';
  wechatUrl = '';
  payWindow: any;
  // 是否显示加载
  loading = false;

  constructor(
    private env: Env,
    private authService: AuthService,
    private http: HttpClient,
    private message: MessageService,
    @Inject(forwardRef(() => ModalService))
    private modal
  ) {
  }

  /**
   * 商户充值
   * @param {number} money
   * @param {() => void} cb
   * @returns {Observable<MemberCharge | Order>}
   */
  coopCharge(params: object, cb?: () => void): Observable<MemberCharge | Order> {
    this.commonPay();
    return this.http
      .post<Order>(apiMemberCharge, {
        ...params,
        payMethod: this.payment
      })
      .pipe(
        tap(() => cb && cb()),
        switchMap((data: Order) => {
          return this.handlePayment(data);
        })
      );
  }

  getChargeMenu(): Observable<PayMenu[]> {
    return this.http.post<PayMenu[]>(apiPayMenu,{})
  }

  /**
   * 检测订单状态
   * @param {string} orderSn
   * @returns {Observable<Order>}
   */
  watchPay(orderSn: string): Observable<Order> {
    return this.http.post<Order>(apiWarchOrder, { order_sn: orderSn }).pipe(
      retryWhen(err =>
        err.pipe(
          delay(1500),
          take(40)
        )
      ),
      tap(() => {
        if (this.payment === 'wechat') {
          this.modal.closeWechat();
        }
      })
    );
  }


  /**
   * 处理不同的支付类型
   * @param data
   * @returns {Observable<any>}
   */
  private handlePayment(data): Observable<Order> {
    if (data) {
      this.loading = false;
      switch (this.payment) {
        case 'recharge':
          return of(data);
        case 'wechat':
          if (data.wechatUrl === null)
            this.message.error('亲，联系管理员开通微信支付');
          this.wechatUrl = data.url;
          // 打开二维码
          if (this.modalWechat)
            this.modal.showWechatQrcode(this.wechatUrl);
          return this.watchPay(data.orderNumber);
        case 'alipay':
          // 新窗口支付
          if (this.payWindow) this.payWindow.location.href = `${this.env.baseUrl}/${this.env.version}/pay/alipay?order_sn=${data.orderNumber}`;
          return this.watchPay(data.orderNumber);
      }
    } else {
      of(null);
    }
  }

  private commonPay(): void {
    this.loading = true;
    if (this.payment === 'alipay') this.payWindow = window.open();
    if (this.payment === 'wechat') this.wechatUrl = '';
  }
  buyVip(type?): Observable<Order> {
    this.commonPay();
    return this.http.post<Order>(apiBuyVip, {
      trade_way: this.payment,
      type: type ? type : 1
    }).pipe(
      switchMap(data => this.handlePayment(data))
    );
  }
}
