import {
  forwardRef,
  Injector,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ConfigLoader, ConfigModule, ConfigService } from '@ngx-config/core';
import { ConfigHttpLoader } from '@ngx-config/http-loader';
import { MetaLoader, MetaModule, MetaStaticLoader } from '@ngx-meta/core';
import { FormsModule } from '@angular/forms';
import { NotFoundPageComponent } from '@libs/core/src/lib/components/not-found-page/not-found-page.component';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { AuthReducer } from '@libs/core/src/lib/+state/auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from '@libs/core/src/lib/+state/auth.effects';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { NgProgressModule } from 'ngx-progressbar';
import { ClipboardModule } from 'ngx-clipboard';

// for AoT compilation
export function configFactory(injector: Injector): ConfigLoader {
  const http = forwardRef(() => injector.get(HttpClient)) as any;

  return new ConfigHttpLoader(http, '/system/config');
}

// 设置SEO
export function metaFactory(config: ConfigService): MetaLoader {
  return new MetaStaticLoader({
    pageTitlePositioning: config.getSettings('seo.pageTitlePositioning'),
    pageTitleSeparator: config.getSettings('seo.pageTitleSeparator'),
    applicationName: config.getSettings('system.applicationName'),
    applicationUrl: config.getSettings('system.applicationUrl'),
    defaults: {
      title: config.getSettings('seo.defaultPageTitle'),
      description: config.getSettings('seo.defaultMetaDescription'),
      keywords: config.getSettings('seo.defaultMetaKeywords'),
      'og:site_name': config.getSettings('system.applicationName'),
      'og:type': 'website'
    }
  });
}

@NgModule({
  imports: [
    CommonModule,
    // 全局配置
    ConfigModule.forRoot(),
    // SEO配置
    MetaModule.forRoot(),
    // Store
    StoreModule.forFeature('auth', AuthReducer),
    EffectsModule.forFeature([AuthEffects]),
    NgProgressModule,
    NgProgressRouterModule
  ],
  declarations: [NotFoundPageComponent ],
  exports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    NgProgressModule,
    NgProgressRouterModule,
    ClipboardModule
  ]
})
export class CoreModule {
  static forRoot(configuredProviders: Array<any>): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: configuredProviders
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule)
      throw new Error('CoreModule already loaded; import in root module only.');
  }
}
