import { Component, OnInit } from '@angular/core';
import { FilterSettingToolComponent } from '../../modals/filter-setting-tool/filter-setting-tool.component';
import { EditorService } from '../../../../../services/editor.service';
import { UserInterfaceService } from '../../../../../services/ui.service';
import { Modal } from '../../../../../models/ui/modal';
import { ImageRadiusSettingToolComponent } from '../tool-bar-image/modals/image-radius-setting-tool/image-radius-setting-tool.component';
import { ToastIcon } from '../../../../../../../../modal/src/lib/models/toast-icon';
import { ModalService } from '../../../../../../../../modal/src/lib/services/modal.service';
import { UploadService } from '../../../../../../../../core/src/lib/services/upload.service';
import * as $ from 'jquery';
import { Image } from '@libs/editor/src/lib/models/element/image';
@Component({
  selector: 'app-tool-bar-container',
  templateUrl: './tool-bar-container.component.html',
  styleUrls: ['./tool-bar-container.component.scss']
})
export class ToolBarContainerComponent implements OnInit {
  eleItem;
  constructor(public editor: EditorService, public ui: UserInterfaceService, public modal: ModalService, public upload: UploadService) { }

  ngOnInit() { }
  /**
   * 换图按钮被单击
   */
  onChangeImageClick() {
    $('#change-image-upload').click();
  }
  /**
   * 换图的回调
   */
  onFileChange() {
    const eleItem = this.editor.activeElements[0];
    const file = $('#change-image-upload')[0].files[0];
    // 判断类型
    if (
      ['jpg', 'png', 'svg+xml', 'jpeg'].filter(
        item => 'image/' + item === file.type
      ).length === 0
    ) {
      alert('仅支持上传图片文件');
      return;
    } else if (file.size > 20 * 1024 * 1024) {
      // 尺寸判断
      alert('文件最大尺寸为20M，请压缩后上传，推荐压缩文件网址：                 https://www.bejson.com/ui/compress_img/');
      return;
    }
    const loadingToast = this.modal.toast({
      icon: ToastIcon.LOADING,
      msg: '正在上传',
      duration: 0
    });
    this.upload.uploadTemp({
      file,
      onSuccess: res => {
        if (res.filePath.match(/\.[Ss][Vv][Gg]$/)) return;
        eleItem.loadImage(res.filePath, res.fileId, res.width, res.height);
        // eleItem.props.oWidth = res.width;
        // eleItem.props.oHeight = res.height;
        // eleItem.options.source = res.fileId;
        // eleItem.options.url = res.filePath;
        // if (
        //   (eleItem.props.width < eleItem.props.height && res.width > res.height) ||
        //   (eleItem.props.width > eleItem.props.height && res.width > res.height) ||
        //   (eleItem.props.width < eleItem.props.height && res.width === res.height) ||
        //   (eleItem.props.width === eleItem.props.height && res.width > res.height)
        // ) {
        //   let ratio = res.width / res.height;
        //   eleItem.options.clip.width = eleItem.props.height * ratio;
        //   eleItem.options.clip.height = eleItem.props.height;
        //   eleItem.options.clip.x = -(eleItem.options.clip.width - eleItem.props.width) / 2;
        // } else {
        //   let ratio = res.height / res.width;
        //   eleItem.options.clip.width = eleItem.props.width;
        //   eleItem.options.clip.height = eleItem.props.width * ratio;
        //   eleItem.options.clip.y = -(eleItem.options.clip.height - eleItem.props.height) / 2;
        // }
        // eleItem.emit('modified', {
        //   type: 'changeImage',
        //   target: eleItem
        // });
        loadingToast.close();
        this.modal.toast({
          icon: ToastIcon.SUCCESS,
          msg: '已更换'
        });
      },
      onFail: e => {
        console.log('上传失败', e);
        loadingToast.close();
        this.modal.toast({
          icon: ToastIcon.ERROR,
          msg: '上传失败'
        });
      }
    });
    $('#change-image-upload').val('');
  }
  /**
   * 事件:滤镜按钮被单击
   * @param event mouse
   */
  onFilterBtnClick(event) {
    const modal = new Modal({
      x: event.clientX - 305 / 2,
      y: event.clientY + 20,
      width: 305,
      height: 584,
      component: FilterSettingToolComponent
    });
    this.ui.add(modal);
  }
  /**
   * 事件:裁剪按钮被单击
   */
  onClipBtnClick() {
    const eleItem = this.editor.activeElements[0];
    if (!eleItem.states.editMode) {
      eleItem.enterEditMode();
    }
  }
  /**
   * 事件:圆角按钮被单击
   */
  onRadiusBtnClick(event) {
    const modal = new Modal({
      x: event.clientX - 260 / 2,
      y: event.clientY + 20,
      width: 260,
      height: 70,
      component: ImageRadiusSettingToolComponent
    });
    this.ui.add(modal);
  }
  onExtractImageClick(){
    let element = this.editor.activeElements[0];
    //这是图片元素,直接插入
    const scale = this.editor.zoom * this.editor.template.scale;
    const stageBBox = $('.stage-container')[0].getBoundingClientRect();
    const stagePosX = -this.editor.stagePos.x + stageBBox.width / 2;
    const stagePosY = -this.editor.stagePos.y + stageBBox.height / 2;
    const center = {
      x: stagePosX / scale,
      y: stagePosY / scale
    };
    let width = element.options.clip.width;
    let height = element.options.clip.height;
    let x = center.x - width / 2;
    let y = center.y - height / 2;
    let oWidth = element.options.clip.oWidth;
    let oHeight = element.options.clip.oHeight;
    let newElement = new Image({
      id: this.editor.getNewID(),
      props: { x, y, width, height, oWidth, oHeight },
      options: {
        url: element.options.url,
        source: element.options.source
      }
    });
    delete element.options.url;
    delete element.options.source;
    delete element.options.clip;
    element.props.imgRotate = 0;
    element.toHTML();
    element.emit('modified', {
      type: 'changeImg',
      target: element,
      commit:false
    });
    this.editor.activePage.addElement(newElement);
  }
  onChangeSizeClick(){
    let element = this.editor.activeElements[0];
    let addWidth = element.options.clip.width - element.props.width;
    let addHeight = element.options.clip.height - element.props.height;
    element.props.width = element.options.clip.width;
    element.props.height = element.options.clip.height;
    element.options.clip.x = 0;
    element.options.clip.y = 0;
    element.props.x -= addWidth / 2;
    element.props.y -= addHeight / 2;
    element.states.html = element.toHTML();
  }
  oneToOne(){
    let actEle = this.editor.activeElements[0];
    actEle.options.clip.x = 0;
    actEle.options.clip.y = 0;
    actEle.props.width = actEle.options.clip.oWidth / this.editor.template.scale;
    actEle.props.height = actEle.options.clip.oHeight / this.editor.template.scale;
    actEle.options.clip.width = actEle.props.width;
    actEle.options.clip.height = actEle.props.height;
    actEle.states.html = actEle.toHTML();
  }
}
