import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.less']
})
export class QrcodeComponent implements OnInit {
  @Input() url;

  constructor() { }

  ngOnInit() {
  }

}
