import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';
import { ToastIcon } from '@libs/modal/src/lib/models/toast-icon';
import { deepClone } from '@libs/editor/src/lib/utils/misc/common';

@Component({
  selector: 'app-new-table-edit',
  templateUrl: './new-table-edit.component.html',
  styleUrls: ['./new-table-edit.component.scss']
})
export class NewTableEditComponent implements OnInit {
  public tableArray = [];
  public element;
  public downItem;
  public downType = '';
  public downX = 0;
  public downY = 0;
  public rowIndex = -1;
  public cellIndex = -1;
  //鼠标样式
  public cursor = 'auto';
  //碰撞检测距离
  public judgeCollisionPx = 8;
  //是否是在添加
  public addState = false;
  //添加时的参考线
  public addLineDom = null;
  //添加单元格的时候的变量
  public startEvent = null;
  public addMoveDirection = '';
  //添加时如果单元格被合并 记录要切割的索引
  public cutIndex = 0;
  public borderColor = '#000000';
  public tableBgColor = '#000000';
  public tableBorder:any = 0;
  public tableCellBorder:any = 0;
  //删除按钮样式
  public deleteColStyle = {
    left: '5px',
    top: '40%'
  };
  public deleteRowStyle = {
    left: '30%',
    top: '8px'
  };
  //存储Ctrl键是否被按下
  public ctrlDown = false;
  //存储shift是否被按下
  public shiftDown = false;
  //最近选择的颜色
  public colorList = [];
  @ViewChild('editArea') editArea: ElementRef;
  @ViewChild('tableElement') tableElement: ElementRef;
  constructor(
    public editor:EditorService,
    public modal:ModalService
  ) { }

  get colArr() {
    return new Array(this.editor.activeElements[0].options.colNumber)
  }

  get thStyle() {
    const options = this.editor.activeElements[0].options;
    const borderStyle = `${options.cellBorderWidth}px solid ${options.borderColor}`;
    return {
      padding: '0',
      height: '0',
      borderLeft: borderStyle,
      borderRight: borderStyle,
    }
  }

  thColWidth(index) {
    const options = this.editor.activeElements[0].options;
    return (options.tableColWidths[index] - options.cellBorderWidth) + 'px';
  }

  ngOnInit() {
    this.element = this.editor.activeElements[0];
    this.borderColor = this.element.options.borderColor;
    this.tableBgColor = this.element.options.tableBgColor;
    this.tableArray = this.element.options.tableArray;
    this.tableBorder = (this.element.options.borderWidth / this.editor.template.scale).toFixed(2);
    this.tableCellBorder = (this.element.options.cellBorderWidth / this.editor.template.scale).toFixed(2);
  }
  getEditAreaStyle() {
    const eleItem = this.editor.activeElements[0];
    const { props, options } = eleItem;
    const zoom =
      this.editor.zoom * this.editor.template.scale;
    return {
      left: this.editor.stagePos.x + props.x * zoom - 30 + 'px',
      top: this.editor.stagePos.y + props.y * zoom - 30 + 'px',
      width: props.width * zoom + 60 + 'px',
      height: props.height * zoom + 60 + 'px',
      padding:'30px',
      transform: `rotate(${props.rotation}deg)`,
      cursor: this.cursor
    };
  }
  /**
   * 获取表格缩放
   */
  getTableStyle(){
    return {
      transform: `scale(${this.editor.zoom*this.element.options.scale})`,
      transformOrigin: '0 0',
      border:`${this.element.options.borderWidth}px ${this.element.options.borderColor} solid`,
      background:this.element.options.tableBgColor,
      width: `${1/(this.editor.zoom*this.element.options.scale)*100}%`
    };
  }
  getNullTd(){
    let tds = "";
    for (let i = 0;i < this.element.options.colNumber;i++){
      tds += `<td rowspan="1" colspan="1" style="width:${this.element.options.tableColWidths[i]}px;padding:0;height:0;border:0;"></td>`;
    }
    return tds;
  }
  /**
   * 鼠标抬起或者离开时清除拖动信息
   */
  tableMouseUp(){
    this.getDeleteStyle();
    if (this.downType == 'clear'){
      return;
    }
    if (this.downType == 'add'){
      if (this.addState) this.addCell();
      return;
    }
    if (this.downType == 'col' || this.downType == 'row'){
      //如果是改变单元格 就重新计算单元格真实大小
      setTimeout(()=>{
        for (let i = 0;i < this.tableArray.length;i++){
          if (this.tableArray[i].cell[this.cellIndex].colspan != 0 && this.tableArray[i].cell[this.cellIndex].rowspan != 0){
            this.element.changeCellRealSize(i,this.cellIndex,this.editor.zoom,true,false)
          }
        }
        setTimeout(()=>{
          this.changeBoxSize();
          this.editor.snapshot();
        })
      })
    }
    this.downType = '';
  }

  /**
   * 鼠标移动事件
   * @param e 鼠标事件event
   */
  tableMouseMove(e){
    if (this.downType != ''){
      e.preventDefault();
    }
    e.target.style.cursor = this.cursor;
    if (this.downType == 'col'){
      this.changeTableSize('col',e);
    }else if (this.downType == 'row'){
      this.changeTableSize('row',e);
    }else if (this.downType == 'add'){
      if (this.addState){
        this.drawAddLine(e);
      }
    }
  }

  /**
   * 检测鼠标是否在表格边缘移动
   * @param cell {object} 单元格数据
   * @param rowIndex {Number} 行index
   * @param cellIndex {Number} 列index
   * @param e 鼠标event
   */
  cellMouseMove(cell,rowIndex,cellIndex,e){
    if (this.downType == 'add' || this.downType == 'multi') return;
    let boxStyle = e.target.getBoundingClientRect();
    let boxWidth = boxStyle.width/this.editor.zoom/this.element.options.scale;
    let boxHeight = boxStyle.height/this.editor.zoom/this.element.options.scale;
    let judgeRange = this.judgeCollisionPx;
    let borderWidth = this.element.options.cellBorderWidth;
    if (e.offsetX < judgeRange||e.offsetX > boxWidth - judgeRange - borderWidth){
      if (e.offsetX < judgeRange && cellIndex == 0 && cellIndex != this.element.options.colNumber-1){
        this.downType == 'clear' ? this.changeMouseStyle('clear') : this.changeMouseStyle('auto');
      } else{
        e.preventDefault();
        this.downType == 'clear' ? this.changeMouseStyle('clear-ac') : this.changeMouseStyle('col-resize');
      }
    }else if(e.offsetY < judgeRange||e.offsetY > boxHeight-judgeRange - borderWidth){
      if (e.offsetY < judgeRange && rowIndex == 0&&rowIndex != this.element.options.rowNumber-1){
        this.downType == 'clear' ? this.changeMouseStyle('clear') : this.changeMouseStyle('auto');
      } else{
        e.preventDefault();
        this.downType == 'clear' ? this.changeMouseStyle('clear-ac') : this.changeMouseStyle('row-resize');
      }
    } else{
      this.downType == 'clear' ? this.changeMouseStyle('clear') : this.changeMouseStyle('auto');
    }
  }

  /**
   * 鼠标在单元格中落下
   * @param cell 单元格
   * @param rowIndex 列索引
   * @param cellIndex 单元格索引
   * @param e 鼠标事件
   */
  cellMouseDown(cell,rowIndex,cellIndex,e){
    if (this.downType == 'add'){
      this.downItem = cell;
      e.preventDefault();
      this.judgeDownType('add',rowIndex,cellIndex,e);
      return;
    }
    //Ctrl被按下进入多选
    if (this.ctrlDown||this.shiftDown){
      if (this.shiftDown&&this.ctrlDown) return;
      this.downType = 'multi';
      let range = window.getSelection();
      range.removeAllRanges();
      e.preventDefault();
      if (this.shiftDown){
        let startRowIndex = Math.min(this.rowIndex,rowIndex);
        let startCellIndex = Math.min(this.cellIndex,cellIndex);
        let endRowIndex = Math.max(this.rowIndex,rowIndex);
        let endCellIndex = Math.max(this.cellIndex,cellIndex);
        this.element.options.chooseCells = [];
        for (let i = startRowIndex;i <= endRowIndex;i++){
          for (let z = startCellIndex;z <= endCellIndex;z++){
            this.element.options.chooseCells.push(this.tableArray[i].cell[z]);
          }
        }
        return;
      }
      if (this.ctrlDown){
        let itemIndex = this.element.options.chooseCells.findIndex(item=>item == cell);
        if (itemIndex == -1){
          this.element.options.chooseCells.push(cell);
        } else{
          this.element.options.chooseCells.splice(itemIndex,1);
        }
        this.rowIndex = rowIndex;
        this.cellIndex = cellIndex;
      }
      return;
    }
    this.rowIndex = rowIndex;
    this.cellIndex = cellIndex;
    let boxStyle = e.target.getBoundingClientRect();
    let boxWidth = boxStyle.width/this.editor.zoom/this.element.options.scale;
    let boxHeight = boxStyle.height/this.editor.zoom/this.element.options.scale;
    let judgeRange = this.judgeCollisionPx;
    let borderWidth = this.element.options.cellBorderWidth;
    this.getDeleteStyle();
    if ((e.offsetX < judgeRange && cellIndex > 0) || e.offsetX > boxWidth-judgeRange - borderWidth){
      e.preventDefault();
      if (e.offsetX < judgeRange){
        //寻找上一个col不为0的Item
        for (let i = cellIndex-1;i >= 0;i--){
          if (this.tableArray[rowIndex].cell[i].rowspan != 0 && this.tableArray[rowIndex].cell[i].colspan != 0){
            //如果是清除状态调用清除
            this.downItem = this.tableArray[rowIndex].cell[i];
            this.cellIndex = i;
            if(this.judgeDownType('col',rowIndex,i,e)) return;
            break;
          }
        }
      }else{
        if(this.judgeDownType('col',rowIndex,cellIndex,e)) return;
        this.downItem = cell;
      }
      if (this.downType != 'clear'){
        this.downType = 'col';
      }
    }else if((e.offsetY < judgeRange && rowIndex > 0 )||e.offsetY > boxHeight-judgeRange - borderWidth){
      e.preventDefault();
      if (e.offsetY < judgeRange){
        //寻找上一个row不为0的Item
        for (let i = rowIndex-1;i >= 0;i--){
          if (this.tableArray[i].cell[cellIndex].colspan != 0 && this.tableArray[i].cell[cellIndex].rowspan != 0){
            //如果是清除或者是绘制状态调用清除或者绘制方法
            this.downItem = this.tableArray[i].cell[cellIndex];
            this.rowIndex = i;
            if(this.judgeDownType('row',i,cellIndex,e)) return;
            break;
          }
        }
      }else{
        if(this.judgeDownType('row',rowIndex,cellIndex,e)) return;
        this.downItem = cell;
      }
      if (this.downType != 'clear'){
        this.downType = 'row'
      }
    } else{
      if (this.downType != 'clear'){
        this.downType = '';
      }
    }
    this.element.options.chooseCells = [cell];
    this.downX = e.clientX;
    this.downY = e.clientY;
    if (cell.style.background){
      this.tableBgColor = cell.style.background;
    } else{
      this.tableBgColor = '#FFFFFF'
    }
  }

  /**
   * 判断当前鼠标状态
   * @param type 清除类型 row 行 col 列
   * @param rowIndex 索引
   * @param cellIndex 索引
   * @param e 鼠标事件
   */
  judgeDownType(type,rowIndex,cellIndex,e){
    if (this.downType == 'clear'){
      this.clearCell(type,rowIndex,cellIndex);
      return true;
    } else if (this.downType == 'add'){
      this.downX = e.clientX;
      this.downY = e.clientY;
      this.rowIndex = rowIndex;
      this.cellIndex = cellIndex;
      this.startEvent = e;
      this.addState = true;
      return true;
    }
  }
  /**
   * 文字更改事件
   * @param e event
   * @param cell 单元格
   */
  valueChange(e,cell){
    if (cell.value != e.target.innerHTML) {
      cell.value = e.target.innerHTML;
      this.editor.snapshot();
    }
  }

  /**
   * 文字更改时更新单元格大小
   * @param e event
   * @param rowIndex 行索引
   * @param cell 单元格对象
   */
  changeBoxHeight(e,rowIndex,cell){
    let tdStyle = e.target.parentNode.getBoundingClientRect();
    let tdSumHeight = tdStyle.height/this.editor.zoom/this.element.options.scale;
    if (cell.rowspan > 1){
      for (let i = 1; i < cell.rowspan;i++){
        tdSumHeight -= this.element.options.tableRowHeights[rowIndex+i];
      }
    }
    this.element.options.tableRowHeights[rowIndex] = tdSumHeight;
    this.tableArray[rowIndex].style.height = this.element.options.tableRowHeights[rowIndex] + 'px';
    this.changeBoxSize();
  }
  /**
   * 改变当前盒子大小
   */
  changeBoxSize(){
    //计算当前表格大小
    let tableStyle = this.tableElement.nativeElement.getBoundingClientRect();
    let newTableWidth = tableStyle.width;
    let newTableHeight = tableStyle.height;
    this.editor.activeElements[0].props.height = newTableHeight/(this.editor.template.scale*this.editor.zoom);
    this.editor.activeElements[0].props.width = newTableWidth/(this.editor.template.scale*this.editor.zoom);
  }

  /**
   * 修改当前显示的toolbar
   * @param type
   */
  showToolbar(type){
    this.editor.activeElements[0].options.toolbarType = type;
  }
  tableCellEditFocus(e,cell,rowIndex,cellIndex){
    if (window.getSelection && cell.value == '双击进入编辑'){
      let range = document.createRange();
      range.selectNodeContents(e.target);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }
    this.rowIndex = rowIndex;
    this.cellIndex = cellIndex;
  }
  operateCell(type){
    if (this.downType == type){
      this.downType = '';
      this.changeMouseStyle('auto')
    } else{
      this.changeMouseStyle(type)
    }
  }

  /**
   * 改变当前鼠标样式
   * @param type 类型
   */
  changeMouseStyle(type = 'auto'){
    switch (type) {
      case 'clear':
        this.cursor = 'url("/assets/img/editor/ui/clear.png") 0 16, auto';
        this.downType = 'clear';
        break;
      case 'add':
        this.cursor = 'url("/assets/img/editor/ui/add.png") 0 16, auto';
        this.downType = 'add';
        break;
      case 'clear-ac':
        this.cursor = 'url("/assets/img/editor/ui/clear-ac.png") 0 21, auto';
        this.downType = 'clear';
        break;
      case 'multi':
        this.cursor = 'pointer';
        this.downType = 'multi';
        break;
      default:
        this.cursor = type;
        break;
    }
  }
  /**
   * 擦除单元格
   * @param type 类型
   * @param rowIndex 行索引
   * @param cellIndex 列索引
   */
  clearCell(type,rowIndex,cellIndex){
    if (type == 'col'){
      //当前单元格
      let chooseCell = this.tableArray[rowIndex].cell[cellIndex];
      //记录单元格内容
      let value = chooseCell.value;
      //右侧单元格(即将合并)
      let preCell = this.tableArray[rowIndex].cell[cellIndex+chooseCell.colspan];
      let preRowspan = 0;
      //当前单元格合并行数大于即将合并单元格行数 计算即将合并单元格下方单元格总行数
      if (chooseCell.rowspan > preCell.rowspan){
        for (let i = 0;i < chooseCell.rowspan;i++){
          if (this.tableArray[rowIndex+i].cell[cellIndex+chooseCell.colspan].colspan != preCell.colspan){
            this.showDontClear();
            return;
          }
          preRowspan += this.tableArray[rowIndex+i].cell[cellIndex+chooseCell.colspan].rowspan;
        }
        //行数相等相加
        if (preRowspan == chooseCell.rowspan) {
          let span = chooseCell.colspan;
          chooseCell.colspan += preCell.colspan;
          for (let i = 0;i < chooseCell.rowspan;i++){
            this.tableArray[rowIndex+i].cell[cellIndex+span].colspan = 0;
            if (this.tableArray[rowIndex+i].cell[cellIndex+span].value){
              value += `<br>${this.tableArray[rowIndex+i].cell[cellIndex+span].value}`
            }
          }
        }else{
          this.showDontClear();
          return;
        }
      }
      //即将合并单元格行数大于当前单元格行数 计算当前单元格下方单元格行数
      let chooseRowspan = 0;
      if (preCell.rowspan > chooseCell.rowspan){
        for (let i = 0; i < preCell.rowspan;i++){
          if (this.tableArray[rowIndex+i].cell[cellIndex].colspan != chooseCell.colspan){
            this.showDontClear();
            return;
          }
          chooseRowspan += this.tableArray[rowIndex+i].cell[cellIndex].rowspan;
        }
        if (chooseRowspan == preCell.rowspan){
          chooseCell.rowspan = chooseRowspan;
          chooseCell.colspan += preCell.colspan;
          for (let i = 1; i < preCell.rowspan;i++){
            for (let z= 0;z < chooseCell.colspan;z++){
              this.tableArray[rowIndex+i].cell[cellIndex+z].colspan = 0;
              if (this.tableArray[rowIndex+i].cell[cellIndex+z].value){
                value += `<br>${this.tableArray[rowIndex+i].cell[cellIndex+z].value}`
              }
            }
          }
        }else{
          this.showDontClear();
          return;
        }
      }
      //当前单元格合并的列数等于右侧单元格合并的列数
      if (preCell.rowspan == chooseCell.rowspan){
        chooseCell.colspan += preCell.colspan;
        preCell.colspan = 0;
        if (preCell.value){
          value += `<br>${preCell.value}`
        }
      }
      //修改单元格value
      chooseCell.value = value;
    } else if (type == 'row'){
      let chooseCell = this.tableArray[rowIndex].cell[cellIndex];
      let preCell = this.tableArray[rowIndex+chooseCell.rowspan].cell[cellIndex];
      //记录单元格内容
      let value = chooseCell.value;
      //选择的单元格和即将合并的单元格合并的列数一致直接合并行
      if (chooseCell.colspan == preCell.colspan){
        chooseCell.rowspan += preCell.rowspan;
        preCell.rowspan = 0;
        if (preCell.value){
          value += `<br>${preCell.value}`
        }
      }
      //即将合并的单元格合并的列数大于选择的单元格
      if (preCell.colspan > chooseCell.colspan){
        let chooseColspan = 0;
        for (let i = 0; i < preCell.colspan;i++){
          if (this.tableArray[rowIndex].cell[cellIndex+i].rowspan != preCell.rowspan){
            this.showDontClear();
            return;
          }
          chooseColspan += this.tableArray[rowIndex].cell[cellIndex+i].colspan;
        }
        if (chooseColspan == preCell.colspan) {
          chooseCell.rowspan += preCell.rowspan;
          chooseCell.colspan = chooseColspan;
          for (let i = 1; i < preCell.colspan;i++){
            this.tableArray[rowIndex].cell[cellIndex+i].rowspan = 0;
            if (this.tableArray[rowIndex].cell[cellIndex+i].value){
              value += `<br>${this.tableArray[rowIndex].cell[cellIndex+i].value}`
            }
          }
        }else{
          this.showDontClear();
          return;
        }
      }
      //选择的单元格列数大于即将合并的单元格 计算多个单元格列数 列数一致合并
      let preColspan = 0;
      if (chooseCell.colspan > preCell.colspan){
        for (let i = 0;i < chooseCell.colspan;i++){
          if (this.tableArray[rowIndex+chooseCell.rowspan].cell[cellIndex+i].rowspan != chooseCell.rowspan){
            this.showDontClear();
            return;
          }
          preColspan+=this.tableArray[rowIndex+chooseCell.rowspan].cell[cellIndex+i].colspan;
        }
        if (preColspan == chooseCell.colspan) {
          let span = chooseCell.rowspan;
          chooseCell.rowspan += preCell.rowspan;
          for (let i = 0;i < chooseCell.colspan;i++){
            this.tableArray[rowIndex+span].cell[cellIndex+i].rowspan = 0;
            if(this.tableArray[rowIndex+span].cell[cellIndex+i].value){
              value += `<br>${this.tableArray[rowIndex+span].cell[cellIndex+i].value}`
            }
          }
        }else{
          this.showDontClear();
          return;
        }
      }
      chooseCell.value = value;
    }
    this.element.calculationCellWidth();
    setTimeout(()=>{
      this.changeBoxSize();
      this.correctRowAndCol();
      this.editor.snapshot();
    })
  }
  correctRowAndCol(){
    for (let i = 0;i < this.tableArray.length;i++){
      for (let z = 0;z < this.tableArray[i].cell.length;z++){
        if (this.tableArray[i].cell[z].colspan > 1){
          for (let c = 1;c < this.tableArray[i].cell[z].colspan;c++){
            this.tableArray[i].cell[z + c].colspan = 0;
            this.tableArray[i].cell[z + c].rowspan = this.tableArray[i].cell[z].rowspan;
          }
        }
        if (this.tableArray[i].cell[z].rowspan > 1){
          for (let r = 1;r < this.tableArray[i].cell[z].rowspan;r++){
            this.tableArray[i + r].cell[z].rowspan = 0;
            this.tableArray[i + r].cell[z].colspan = this.tableArray[i].cell[z].colspan;
          }
        }
      }
    }
  }
  /**
   * 显示擦除失败提示
   */
  showDontClear(){
    this.modal.toast({
      icon:ToastIcon.ERROR,
      msg:'无可合并单元格'
    })
  }

  /**
   * 添加单元格
   */
  addCell(){
    this.addState = false;
    this.addLineDom.remove();
    this.addLineDom = null;
    let currentCell = this.tableArray[this.rowIndex].cell[this.cellIndex];
    //横向添加
    if (this.addMoveDirection == 'row'){
      //如果当前行没有合并过
      if (this.tableArray[this.rowIndex].cell[this.cellIndex].rowspan == 1){
        //切割的高度
        let startStyle = this.startEvent.target.getBoundingClientRect();
        let topHeight = (this.downY - startStyle.top) / this.editor.zoom;
        let bottomHeight = parseFloat(this.tableArray[this.rowIndex].style.height) - topHeight;
        //修改当前单元格高度
        this.tableArray[this.rowIndex].style.height = topHeight + 'px';
        //修改当前表格高度数组
        this.element.options.tableRowHeights.splice(this.rowIndex,1,topHeight,bottomHeight);
        //clone当前行
        let cloneRow = JSON.parse(JSON.stringify(this.tableArray[this.rowIndex]));
        cloneRow.style.height = bottomHeight + 'px';
        for (let i = 0;i < this.element.options.colNumber;i++){
          //合并其他单元格
          if (i < this.cellIndex || i > this.cellIndex+currentCell.colspan-1){
            let cell = this.tableArray[this.rowIndex].cell[i];
            if (cell.rowspan == 0&&cell.colspan!=0){
              cell = this.lookupCell('top',i,this.rowIndex);
              if (cell.rowspan > 1){
                cell.rowspan += 1;
              }
            }else{
              cell.rowspan += 1;
            }
          }
          if (i != this.cellIndex){
            cloneRow.cell[i].rowspan = 0;
            cloneRow.cell[i].colspan = 1;
          }
        }
        //修改当前行数量
        this.element.options.rowNumber += 1;
        this.tableArray.splice(this.rowIndex+1,0,cloneRow);
        //修改单元格高度为真实高度
        setTimeout(()=>{
          this.element.changeCellRealSize(this.rowIndex,this.cellIndex,this.editor.zoom);
          this.element.changeCellRealSize(this.rowIndex+1,this.cellIndex,this.editor.zoom);
        })
      }else{
        //当前单元格合并过 切割单元格
        let currentRowspan = this.tableArray[this.rowIndex].cell[this.cellIndex].rowspan;
        //拆分当前单元格
        this.tableArray[this.rowIndex].cell[this.cellIndex].rowspan = this.cutIndex + 1;
        this.tableArray[this.rowIndex+this.cutIndex + 1].cell[this.cellIndex].rowspan = currentRowspan - this.cutIndex - 1;
        this.tableArray[this.rowIndex+this.cutIndex + 1].cell[this.cellIndex].colspan = this.tableArray[this.rowIndex].cell[this.cellIndex].colspan;
      }
    } else if (this.addMoveDirection == 'col'){
      //竖向添加
      //切割的宽度
      let startStyle = this.startEvent.target.getBoundingClientRect();
      let leftWidth = (this.downX - startStyle.left) / this.editor.zoom;
      let rightWidth = parseFloat(currentCell.style.width) - leftWidth;
      //如果当前行没有合并过
      if (this.tableArray[this.rowIndex].cell[this.cellIndex].colspan == 1){
        for (let i = 0;i < this.element.options.rowNumber;i++){
          let cloneCol = JSON.parse(JSON.stringify(this.tableArray[i].cell[this.cellIndex]));
          //合并其他单元格
          if (i < this.rowIndex || i > this.rowIndex+currentCell.rowspan-1){
            let cell = this.tableArray[i].cell[this.cellIndex];
            if (cell.rowspan != 0 && cell.colspan == 0){
              cell = this.lookupCell('left',this.cellIndex,i);
              if (cell.colspan > 1){
                cell.colspan += 1;
              }
            }else{
              cell.colspan += 1;
            }
          }
          if (i != this.rowIndex){
            cloneCol.rowspan = 1;
            cloneCol.colspan = 0;
          }else{
            cloneCol.colspan = 1;
          }
          this.tableArray[i].cell.splice(this.cellIndex+1,0,cloneCol)
        }
        //获取当前单元格最小宽度 切割宽度小于最小宽度赋值最小宽度
        //左侧
        let cellMinWidth = this.element.options.padding*2+parseInt(this.tableArray[this.rowIndex].cell[this.cellIndex].style.fontSize);
        if (leftWidth < cellMinWidth){
          leftWidth = cellMinWidth;
        }
        if (rightWidth < cellMinWidth){
          rightWidth = cellMinWidth;
        }
        this.element.options.tableColWidths.splice(this.cellIndex,1,leftWidth,rightWidth);
        this.element.options.colNumber += 1;
        this.element.calculationCellWidth();
        //修改单元格高度和宽度为真实高度和宽度
        setTimeout(()=>{
          this.element.changeCellRealSize(this.rowIndex,this.cellIndex,this.editor.zoom);
          this.element.changeCellRealSize(this.rowIndex,this.cellIndex+1,this.editor.zoom);
        });
      }else{
        //当前单元格合并过 切割单元格
        let currentColspan = currentCell.colspan;
        //拆分当前单元格
        currentCell.colspan = this.cutIndex + 1;
        this.tableArray[this.rowIndex].cell[this.cellIndex+this.cutIndex + 1].colspan = currentColspan - this.cutIndex - 1;
        this.tableArray[this.rowIndex].cell[this.cellIndex+this.cutIndex + 1].rowspan = this.tableArray[this.rowIndex].cell[this.cellIndex].rowspan;
        this.element.calculationCellWidth();
      }
    }
    setTimeout(()=>{
      this.changeBoxSize();
      this.correctRowAndCol();
      this.editor.snapshot();
    },20)
  }

  /**
   * 改变当前选择的单元格大小
   * @param type 类型 col 横向拉伸 row 竖向拉伸
   * @param e 鼠标事件
   */
  changeTableSize(type,e){
    if (type == 'col'){
      //当前列
      let currentCell = this.tableArray[this.rowIndex].cell[this.cellIndex];
      //鼠标移动时计算列宽度
      let addWidth = (e.clientX - this.downX) / this.editor.zoom / this.element.options.scale;
      if (this.cellIndex + currentCell.colspan < this.element.options.colNumber){
        //下一列宽度
        let nextWidth = this.element.options.tableColWidths[this.cellIndex+currentCell.colspan];
        //判断当前列宽度加上addwidth是否小于最小宽度
        //判断下一列宽度加上addwidth是否小于最小宽度
        //判断当前列是否存在字体被挤压的情况
        let minFlg = false;
        let cellWidth = this.element.options.tableColWidths[this.cellIndex+currentCell.colspan-1];
        let maxAddWidth = -999;
        for (let i = 0;i < this.tableArray.length;i++){
          let minCurrentWidth = this.element.options.padding*2+parseInt(this.tableArray[i].cell[this.cellIndex+currentCell.colspan-1].style.fontSize);
          if (cellWidth+addWidth < minCurrentWidth){
            minFlg = true;
            maxAddWidth = Math.max(minCurrentWidth - cellWidth,maxAddWidth);
          }
          let minNextWidth = this.element.options.padding*2+parseInt(this.tableArray[i].cell[this.cellIndex+currentCell.colspan].style.fontSize);
          if (nextWidth-addWidth < minNextWidth){
            minFlg = true;
            maxAddWidth = Math.max(nextWidth - minNextWidth,maxAddWidth);
          }
        }
        if (minFlg){
          this.element.options.tableColWidths[this.cellIndex+currentCell.colspan-1] += maxAddWidth;
          this.element.options.tableColWidths[this.cellIndex+currentCell.colspan] -= maxAddWidth;
        } else{
          this.element.options.tableColWidths[this.cellIndex+currentCell.colspan - 1] += addWidth;
          this.element.options.tableColWidths[this.cellIndex+currentCell.colspan] -= addWidth;
        }
      } else{
        //取出最大字体大小 方便计算单元格最小值
        let maxFontSize = 0;
        for (let row of this.tableArray){
          for (let cell of row.cell){
            maxFontSize = Math.max(parseInt(cell.style.fontSize),maxFontSize)
          }
        }
        // let sumWidth = document.getElementById('table-element').getBoundingClientRect().width*this.editor.zoom*this.editor.template.scale;
        let minCellWidth = this.element.options.padding*2+maxFontSize;
        let zAddWidth = 0;
        for (let i = 0;i < this.element.options.tableColWidths.length;i++){
          if (this.element.options.tableColWidths[i] + addWidth/this.element.options.colNumber > minCellWidth) {
            this.element.options.tableColWidths[i] += addWidth/this.element.options.colNumber;
            zAddWidth += addWidth/this.element.options.colNumber;
          }
        }
        this.element.props.width += zAddWidth/this.editor.template.scale;
      }
      this.element.calculationCellWidth();
      this.downX = e.clientX;
      // setTimeout(()=>{
      //   this.changeBoxSize();
      // });
    } else if (type == 'row'){
      //鼠标移动时计算行高度
      let addHeight = (e.clientY - this.downY)/this.editor.zoom/this.element.options.scale;
      this.element.options.tableRowHeights[this.rowIndex] += addHeight;
      if (this.element.options.tableRowHeights[this.rowIndex] < this.element.options.padding * 2){
        this.element.options.tableRowHeights[this.rowIndex] = this.element.options.padding * 2;
      }
      this.tableArray[this.rowIndex].style.height = this.element.options.tableRowHeights[this.rowIndex] + 'px';
      this.changeBoxSize();
      this.downY = e.clientY;
    }
    this.getDeleteStyle();
  }

  /**
   * 查找显示的单元格
   * @param type 类型
   * @param cellIndex
   * @param rowIndex
   * @return 查找到的单元格
   */
  lookupCell(type,cellIndex,rowIndex){
    if (type == 'left'){
      for (let i = cellIndex;i >= 0;i--){
        if (this.tableArray[rowIndex].cell[i].colspan > 0){
          return this.tableArray[rowIndex].cell[i];
        }
      }
    }else if(type == 'top'){
      for (let i = rowIndex;i >= 0;i--){
        if (this.tableArray[i].cell[cellIndex].rowspan > 0){
          return this.tableArray[i].cell[cellIndex];
        }
      }
    }
  }

  /**
   * 绘制添加线条
   * @param e 鼠标事件
   */
  drawAddLine(e){
    if (!this.addLineDom){
      this.addLineDom = document.createElement('div');
      document.body.appendChild(this.addLineDom);
      this.addLineDom.style.position = 'absolute';
      this.addLineDom.style.backgroundColor = '#000000';
      this.addLineDom.style.pointerEvents = 'none';
    }
    if (Math.abs(e.clientX - this.downX) > Math.abs(e.clientY - this.downY)){
      this.addMoveDirection = 'row';
      this.addLineDom.style.height = '2px';
      let startBoxStyle = this.startEvent.target.getBoundingClientRect();
      this.addLineDom.style.top = this.downY + 'px';
      if (this.downItem.rowspan > 1){
        let rowHeightArr = [];
        let tableRowHeights = this.element.options.tableRowHeights;
        //如果单元格已合并 找出在那个格子附近
        for (let i = 0; i < this.downItem.rowspan;i++){
          let rowHeight = tableRowHeights[this.rowIndex+i]*this.editor.zoom*this.element.options.scale;
          if (i > 0) {
            rowHeight += rowHeightArr[i-1];
          }else{
            rowHeight += startBoxStyle.top;
          }
          rowHeightArr.push(rowHeight);
        }
        let nearbyIndex = this.getNearbyIndex(this.downY,rowHeightArr);
        this.cutIndex = nearbyIndex;
        this.addLineDom.style.top = rowHeightArr[nearbyIndex] + 'px';
      }
      if (e.clientX - this.downX > 0){
        //判断鼠标经过的单元格是否和当前单元格合并的列不相同
        this.addLineDom.style.width = Math.abs(startBoxStyle.left - startBoxStyle.right) + 'px';
        this.addLineDom.style.left = startBoxStyle.left + 'px';
      }else{
        this.addLineDom.style.width = Math.abs(startBoxStyle.right - startBoxStyle.left) + 'px';
        this.addLineDom.style.left = startBoxStyle.left + 'px';
      }
    } else{
      this.addMoveDirection = 'col';
      this.addLineDom.style.width = '2px';
      this.addLineDom.style.left = this.downX + 'px';
      let startBoxStyle = this.startEvent.target.getBoundingClientRect();
      if (this.downItem.colspan > 1){
        let cellWidthArr = [];
        let tableColWidths = this.element.options.tableColWidths;
        //如果单元格已合并 找出在那个格子附近
        for (let i = 0; i < this.downItem.colspan; i++){
          let cellWidth = tableColWidths[this.cellIndex+i]*this.editor.zoom*this.element.options.scale;
          if (i > 0) {
            cellWidth += cellWidthArr[cellWidthArr.length-1];
          }else{
            cellWidth += startBoxStyle.left;
          }
          cellWidthArr.push(cellWidth);
        }
        let nearbyIndex = this.getNearbyIndex(this.downX,cellWidthArr);
        this.cutIndex = nearbyIndex;
        this.addLineDom.style.left = cellWidthArr[nearbyIndex] + 'px';
      }
      if (e.clientY - this.downY > 0){
        this.addLineDom.style.height = Math.abs(startBoxStyle.top - startBoxStyle.bottom) + 'px';
        this.addLineDom.style.top = startBoxStyle.top + 'px';
      }else{
        this.addLineDom.style.height = Math.abs(startBoxStyle.top - startBoxStyle.bottom) + 'px';
        this.addLineDom.style.top = startBoxStyle.top + 'px';
      }
    }
  }

  /**
   * 求出接近的index
   * @param x
   * @param xArr
   */
  getNearbyIndex(x,xArr){
    for (let i = 0;i < xArr.length-1;i++){
      if (x < xArr[0]) return 0;
      if (x > xArr[xArr.length-2]) return xArr.length-2;
      if (x == xArr[i]) return i;
      if (x > xArr[i] && x < xArr[i+1]){
        if (x > (xArr[i+1] - xArr[i]) / 2 + xArr[i]) {
          return i + 1
        }else{
          return i
        }
      }
    }
  }

  /**
   * 获取表格遮罩样式
   */
  getTableEditStyle(){
    let stageContainerStyle = document.getElementsByClassName('stage-container')[0].getBoundingClientRect();
    return {
      top:stageContainerStyle.top + 'px',
      left:stageContainerStyle.left + 'px',
      right:stageContainerStyle.right + 'px',
      bottom:stageContainerStyle.bottom + 'px',
      width:stageContainerStyle.width + 'px',
      height:stageContainerStyle.height + 'px'
    }
  }

  /**
   * 退出编辑模式
   */
  exitEditMode(){
    this.changeBoxSize();
    this.element.options.chooseCells = [];
    this.element.exitEditMode();
  }

  /**
   * 修改表格边框颜色
   * @param $event 颜色色值
   */
  onBorderColorChange($event){
    this.element.options.borderColor = $event;
    this.borderColor = $event;
    this.element.changeBorderColor();
    this.editor.snapshot();
  }

  /**
   * 修改表格背景色
   * @param $event 颜色色值
   * @param isFirst 是否是第一次选择颜色
   */
  onBgColorChange($event,isFirst = true){
    if (isFirst){
      if (this.colorList.indexOf($event) != -1) return;
      if (this.colorList.length == 3){
        this.colorList.splice(0,1)
      }
      this.colorList.push($event);
    }
    if (this.element.options.chooseCells.length == 0) {
      this.modal.toast({
        icon:ToastIcon.ERROR,
        msg:'请先选择单元格'
      });
      return;
    }
    for (let item of this.element.options.chooseCells) {
      item.style.background = $event;
      this.tableBgColor = $event;
    }
    this.editor.snapshot();
  }

  /**
   * 添加行或列
   * @param type
   * @param position
   */
  addTableRowOrCol(type,position){
    if (type == 'col'){
      let rowIndex = this.rowIndex;
      let height = this.element.options.tableRowHeights[rowIndex];
      let lastRow = this.tableArray[rowIndex];
      let cloneRow = {
        style:{
          height:`${height}px`,
        },
        cell:[]
      };
      for (let i = 0;i < this.element.options.colNumber;i++){
        cloneRow.cell.push(this.element.createNewTableCell(this.element.options.tableColWidths[i],parseInt(lastRow.cell[i].style.fontSize)));
      }
      for (let i = 0;i < this.tableArray[rowIndex].cell.length;i++){
        if (position == 'top' && rowIndex == 0){
          cloneRow.cell[i].rowspan = 1;
          cloneRow.cell[i].colspan = this.tableArray[rowIndex].cell[i].colspan;
          continue;
        }
        if (position == 'bottom' && rowIndex+1 == this.tableArray[rowIndex].length){
          cloneRow.cell[i].rowspan = 1;
          cloneRow.cell[i].colspan = this.tableArray[rowIndex].cell[i].colspan;
          continue;
        }
        if (this.tableArray[rowIndex].cell[i].rowspan > 1){
          if (position == 'top'){
            cloneRow.cell[i].rowspan = 1;
            cloneRow.cell[i].colspan = 1;
            continue;
          } else{
            cloneRow.cell[i].rowspan = 0;
            cloneRow.cell[i].colspan = 1;
            this.tableArray[rowIndex].cell[i].rowspan += 1;
            continue;
          }
        }
        if (this.tableArray[rowIndex].cell[i].rowspan == 1){
          cloneRow.cell[i].rowspan = 1;
          cloneRow.cell[i].colspan = this.tableArray[rowIndex].cell[i].colspan;
          continue;
        }
        if (this.tableArray[rowIndex].cell[i].rowspan == 0){
          if (position == 'bottom' && rowIndex + 1 <= this.tableArray.length){
            if (this.tableArray[rowIndex + 1].cell[i].rowspan == 1) {
              cloneRow.cell[i].rowspan = 1;
              cloneRow.cell[i].colspan = 1;
              continue;
            }
          }
          for (let z = rowIndex;z >= 0;z--){
            if (this.tableArray[z].cell[i].rowspan > 1){
              cloneRow.cell[i].rowspan = 0;
              cloneRow.cell[i].colspan = 1;
              this.tableArray[z].cell[i].rowspan++;
              break;
            }
          }
        }
      }
      if (position == 'bottom') {
        rowIndex+=1;
      }
      this.tableArray.splice(rowIndex,0,cloneRow);
      this.element.options.tableRowHeights.splice(rowIndex,0,height);
      this.element.options.rowNumber += 1;
      this.element.props.height += height/this.editor.template.scale;
    } else{
      let cellIndex = this.cellIndex;
      let width = this.element.options.tableColWidths[cellIndex];
      for (let i = 0;i < this.element.options.rowNumber;i++){
        let fontSize = parseInt(this.tableArray[i].cell[this.element.options.colNumber-1].style.fontSize);
        let cell = this.element.createNewTableCell(width,fontSize);
        if (position == 'left' && cellIndex == 0){
          cell.colspan = 1;
          cell.rowspan = 1;
        }else if (position == 'right' && cellIndex+1 == this.tableArray[i].cell.length){
          cell.rowspan = 1;
          cell.colspan = 1;
        }else if (this.tableArray[i].cell[cellIndex].colspan == 0){
          if (position == 'right' && cellIndex + 1 <= this.tableArray[i].cell.length){
            if (this.tableArray[i].cell[cellIndex + 1].colspan == 1) {
              cell.rowspan = 1;
              cell.colspan = 1;
            }
            this.tableArray[i].cell.splice(cellIndex+1,0,cell);
            continue;
          }
          for (let z = cellIndex;z >= 0;z--){
            if (this.tableArray[i].cell[z].colspan > 1){
              cell.colspan = 0;
              cell.rowspan = this.tableArray[i].cell[cellIndex].rowspan;
            }
          }
        }else if (this.tableArray[i].cell[cellIndex].colspan > 1){
          if (position == 'left'){
            cell.colspan = 1;
            cell.rowspan = 1;
          } else{
            cell.colspan = 0;
            cell.rowspan = this.tableArray[i].cell[cellIndex].rowspan;
            this.tableArray[i].cell[cellIndex].colspan++;
          }
        }else if (this.tableArray[i].cell[cellIndex].colspan == 1){
          cell.colspan = 1;
          cell.rowspan = this.tableArray[i].cell[cellIndex].rowspan;
        }
        if (position == 'right'){
          this.tableArray[i].cell.splice(cellIndex+1,0,cell);
        } else{
          this.tableArray[i].cell.splice(cellIndex,0,cell);
        }
      }
      this.element.options.tableColWidths.splice(cellIndex,0,width);
      this.element.options.colNumber += 1;
      this.element.props.width += width/this.editor.template.scale;
    }
    this.element.options.chooseCells = [];
    this.rowIndex = -1;
    this.cellIndex = -1;
    this.editor.snapshot();
  }

  /**
   * 删除行和列
   * @param type
   */
  delTableRowOrCol(type){
    if (this.element.options.chooseCells.length == 0) return;
    if (type == 'col'){
      let rowIndex = this.rowIndex;
      if (this.tableArray.length <= 1) return;
      for (let i = 0;i < this.tableArray[rowIndex].cell.length;i++){
        if (this.tableArray[rowIndex].cell[i].rowspan == 0){
          let cell = this.lookupCell('top',i,rowIndex);
          cell.rowspan -= 1;
        }
        if (this.tableArray[rowIndex].cell[i].rowspan > 1){
          this.tableArray[rowIndex+1].cell[i].rowspan = this.tableArray[rowIndex].cell[i].rowspan - 1;
        }
      }
      this.element.props.height -= (this.element.options.tableRowHeights[rowIndex] + this.element.options.cellBorderWidth) * this.element.options.scale/this.editor.template.scale;
      this.tableArray.splice(rowIndex,1);
      this.element.options.tableRowHeights.splice(rowIndex,1);
      this.element.options.rowNumber -= 1;
    } else if (type == 'row') {
      let cellIndex = this.cellIndex;
      if (this.tableArray[0].cell.length <= 1) return;
      for (let i = 0;i < this.tableArray.length;i++){
        if (this.tableArray[i].cell[cellIndex].colspan == 0){
          let cell = this.lookupCell('left',cellIndex,i);
          cell.colspan -= 1;
        }
        if (this.tableArray[i].cell[cellIndex].colspan > 1){
          this.tableArray[i].cell[cellIndex+1].colspan = this.tableArray[i].cell[cellIndex].colspan - 1;
        }
        this.tableArray[i].cell.splice(cellIndex,1);
      }
      this.element.props.width -= (this.element.options.tableColWidths[cellIndex] + this.element.options.cellBorderWidth) * this.element.options.scale/this.editor.template.scale;
      this.element.options.tableColWidths.splice(cellIndex,1);
      this.element.options.colNumber -= 1;
    }
    this.element.options.chooseCells = [];
    this.rowIndex = -1;
    this.cellIndex = -1;
    this.editor.snapshot();
  }

  /**
   * 改变表格边框宽度
   * @param e
   */
  tableBorderChange(e){
    if (!this.verificationNumber(e.target.value)) return;
    let value = parseFloat(e.target.value) * this.editor.template.scale;
    let addNumber = (value-this.element.options.borderWidth)/this.editor.template.scale;
    this.element.props.width += addNumber;
    this.element.props.height += addNumber;
    this.element.options.borderWidth = value;
    this.editor.snapshot();
  }

  /**
   * 改变表格单元格边框宽度
   * @param e
   */
  tableCellBorderChange(e){
    if (!this.verificationNumber(e.target.value)) return;
    let value = parseFloat(e.target.value) * this.editor.template.scale;
    let tableArray = this.element.options.tableArray;
    let addNumber = (value-this.element.options.cellBorderWidth)*2;
    this.element.options.cellBorderWidth = value;
    //更新单元格宽度和高度
    for (let i = 0; i < this.element.options.tableRowHeights.length;i++){
      this.element.options.tableRowHeights[i] = this.element.options.tableRowHeights[i] + addNumber;
    }
    for (let i = 0; i < this.element.options.tableColWidths.length;i++){
      this.element.options.tableColWidths[i] = this.element.options.tableColWidths[i]+addNumber;
    }
    //更新元素大小
    this.element.props.width += addNumber*this.element.options.tableColWidths.length/this.editor.template.scale;
    this.element.props.height += addNumber*this.element.options.tableRowHeights.length/this.editor.template.scale;
    //更新单元格样式
    for (let i = 0;i < tableArray.length;i++){
      tableArray[i].style.height = this.element.options.tableRowHeights[i]+'px';
    }
    this.element.changeBorderColor();
    this.element.calculationCellWidth();
    this.editor.snapshot();
  }

  /**
   * 检测输入
   * @param value
   */
  verificationNumber(value){
    if (!parseFloat(value)) {
      this.modal.toast({
        icon:ToastIcon.ERROR,
        msg:'请输入数字'
      });
      return false;
    }else{
      return true;
    }
  }


  /**
   * 缩放
   * @param e
   */
  scaleTable(e){
    this.downType = 'scale';
    this.downX = e.clientX;
    this.downY = e.clientY;
    this.editor.freeTransform.corner = 'br';
    this.element.states.beforeProps = deepClone(this.element.props);
    // 触发corner
    this.element.emit('cornermousedown', {
      corner:'br'
    });
  }
  getDeleteStyle(){
    if (this.rowIndex == -1 || this.cellIndex == -1) return;
    let currentCellStyle = document.getElementById(`td-${this.rowIndex}-${this.cellIndex}`).getBoundingClientRect();
    let tableStyle = document.getElementById('table-element').getBoundingClientRect();
    this.deleteRowStyle.left = currentCellStyle.left + currentCellStyle.width / 2 - 30 -tableStyle.left + 30 + 'px';
    this.deleteColStyle.top = currentCellStyle.top + currentCellStyle.height / 2 - 30 - tableStyle.top + 30 + 'px';
  }
  @HostListener('document:keydown', ['$event']) onCtrlAKeyDown(event: KeyboardEvent) {
    if (event.code == 'KeyA' && event.ctrlKey){
      this.element.exitEditMode();
    }
  }
  @HostListener('keydown', ['$event']) onTableKeyDown(event: KeyboardEvent) {
    //ctrl+a选择全部单元格
    if (event.code == 'KeyA' && event.ctrlKey){
      event.preventDefault();
      event.stopPropagation();
      this.element.options.chooseCells = [];
      for (let row of this.tableArray){
        for (let cell of row.cell){
          this.element.options.chooseCells.push(cell);
        }
      }
    }
    if (event.ctrlKey&&event.key=='Control'||event.shiftKey&&event.key=='Shift') {
      if (!this.ctrlDown){
        this.ctrlDown = event.ctrlKey;
      }
      if (!this.shiftDown){
        this.shiftDown = event.shiftKey;
      }
      this.changeMouseStyle('multi');
    }
    if ((event.key == 'Backspace' || event.key == 'Delete') && this.element.options.chooseCells.length > 1){
      for (let cell of this.element.options.chooseCells){
        cell.value = '';
      }
      this.element.states.html = this.element.toHTML();
      this.editor.snapshot();
    }
  }
  @HostListener('keyup', ['$event']) onTableKeyUp(event: KeyboardEvent) {
    this.ctrlDown = event.ctrlKey;
    this.shiftDown = event.shiftKey;
    this.downType = '';
    this.changeMouseStyle('auto');
  }
  tableMouseLeave(){
    if (this.cursor == 'col-resize' || this.cursor == 'row-resize') {
      this.cursor = 'auto';
    }
  }
  /**
   * 当粘贴文本时
   * @param event 事件对象
   */
  onTextPaste(event: ClipboardEvent): boolean {
    // this.oneLineEdit = false;
    const pastedText = event.clipboardData.getData('Text');
    let resultText = '';
    const filteChars = [9, 10, 13, 32];
    for (const char of pastedText) {
      const code = char.charCodeAt(0),
        idx = filteChars.findIndex(item => item === code);
      if (idx === -1) {
        resultText += char;
      }
    }
    document.execCommand('insertText', true, resultText);
    // if ($(this.element.options.text).find('.text-wrap').find('div').length === 0) {
    //   // 粘贴进来的文字没有包裹div,手动添加
    //   const html = '<div>' + $(this.element.options.text).find('.text-wrap').text() + '</div>';
    //   $(this.element.options.text).find('.text-wrap').html(html);
    // }
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
}
