import { Component, OnInit, ViewChild } from '@angular/core';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';
import { ApiService } from '@libs/editor/src/lib/services/api.service';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';
import { ToastIcon } from '@libs/modal/src/lib/models/toast-icon';
import { UploadService } from '@libs/core/src/lib/services/upload.service';
import { deepClone } from '@libs/editor/src/lib/utils/misc/common';
import { Combination } from '@libs/editor/src/lib/models/element/combination';
import { Window } from '@libs/editor/src/lib/models/ui/window';
import { UserInterfaceService } from '@libs/editor/src/lib/services/ui.service';
import { CombinationListComponent } from '@libs/editor/src/lib/editor/classify-editor/components/cate-source-tab/tabs/combination-list/combination-list.component';
import { Modal } from '@libs/editor/src/lib/models/ui/modal';
import { TextAlignToolComponent } from '@libs/editor/src/lib/components/editor-ui/t-tool-bar/tools/tool-bar-text/modal/text-align-tool/text-align-tool.component';
import { CombinationAlignToolComponent } from '@libs/editor/src/lib/components/editor-ui/t-tool-bar/tools/tool-bar-combination/modals/combination-align-tool/combination-align-tool.component';
import { CombinationDistanceToolComponent } from '@libs/editor/src/lib/components/editor-ui/t-tool-bar/tools/tool-bar-combination/modals/combination-distance-tool/combination-distance-tool.component';

@Component({
  selector: 'app-tool-bar-combination',
  templateUrl: './tool-bar-combination.component.html',
  styleUrls: ['./tool-bar-combination.component.scss']
})
export class ToolBarCombinationComponent implements OnInit {
  @ViewChild('imageUpload', {static: true}) imageUpload;
  changeImgWindow;
  element: Combination;
  constructor(
    public editor: EditorService,
    public modal: ModalService,
    public upload: UploadService,
    public api: ApiService,
    public ui: UserInterfaceService,
  ) { }

  get fillImage() {
    if (!this.element) return null;
    if (isNaN(this.element.states.activeIndex)) return null;
    const {options, states} = this.element;
    return options.items[states.activeIndex].elements
      .find(el => (el.type === 'container' || el.type === 'image') && el.props.sign === '商品图')
  }

  ngOnInit() {
    this.element = this.editor.activeElements[0];
  }

  getFillConfig(config) {
    if (!this.fillImage) return;
    if (!this.fillImage.options.fill || !this.fillImage.options.fill[config]) {
      switch (config) {
        case 'type': return 'inside';
        case 'alignX': return 'center';
        case 'alignY': return 'center';
      }
    }
    return this.fillImage.options.fill[config]
  }

  setFillConfig(config, e) {
    if (!this.fillImage) return;
    if (!this.fillImage.options.fill) {
      this.fillImage.options.fill = {}
    }
    this.fillImage.options.fill[config] = e.target.value;

    this.fillImage.verifyClip(true, config === 'type');
    this.element.emit('modified', {
      type: 'imageMove',
      target: this.element,
      activeIndex: this.element.states.activeIndex
    });
  }

  // 添加一个商品
  async addItems() {
    const data = deepClone(this.element.options.items[0].data);
    await this.element.addItems(data);
    this.element.emit('modified', {
      type: 'add',
      target: this.element,
      activeIndex: this.element.options.items.length - 1
    });
  }

  removeItems() {
    if (this.element.states.activeIndex >= 0) {
      this.element.removeItems();
      this.element.emit('modified', {
        type: 'remove',
        target: this.element
      });
    }
  }

  // 换图
  onFileChange() {
    const file = this.imageUpload.nativeElement.files[0];
    if (
      ['jpg', 'png', 'jpeg'].filter(
        item => 'image/' + item === file.type
      ).length === 0
    ) {
      this.modal.toast({
        icon: ToastIcon.ERROR,
        msg: '仅支持上传jpg,png图片文件'
      });
      return
    } else if (file.size > 20 * 1024 * 1024) {
      // 尺寸判断
      this.modal.toast({
        icon: ToastIcon.ERROR,
        msg: '文件最大尺寸为20M'
      });
      return;
    }

    const loadingToast = this.modal.toast({
      icon: ToastIcon.LOADING,
      msg: '正在上传',
      duration: 0
    });

    this.upload.uploadTemp({
      file,
      onSuccess: async res => {
        await this.element.changeItems({"商品图": res}, this.element.states.activeIndex)
        this.element.emit('modified', {
          type: 'imageChange',
          target: this.element,
          activeIndex: this.element.states.activeIndex
        });

        loadingToast.close();
        this.modal.toast({
          icon: ToastIcon.SUCCESS,
          msg: '已更换'
        });
      },
      onFail: e => {
        loadingToast.close();
        this.modal.toast({
          icon: ToastIcon.ERROR,
          msg: '上传失败'
        });
      }
    });

  }

  openCombinationList() {
    if (this.changeImgWindow){
      this.ui.remove(this.changeImgWindow)
    }
    this.changeImgWindow = new Window({
      width: 336,
      height: 650,
      props:{
        element: this.element,
        isWindow: true,
      },
      skin: 'common',
      taskbar: false,
      title: "商品图",
      component: CombinationListComponent
    });
    this.ui.add(this.changeImgWindow);
  }


  alignClick(event: MouseEvent) {
    const modal = new Modal({
      x: event.clientX - 120,
      y: event.clientY + 20,
      width: 240,
      height: 80,
      component: CombinationAlignToolComponent
    });
    this.ui.add(modal);
  }

  distanceClick(event: MouseEvent) {
    const modal = new Modal({
      x: event.clientX - 140,
      y: event.clientY + 20,
      width: 280,
      height: 100,
      component: CombinationDistanceToolComponent
    });
    this.ui.add(modal);
  }
}
