import { Component, Input, OnInit } from '@angular/core';
import { TemplateOfficial } from '../../../../../../core/src/lib/models/template-official';

@Component({
  selector: 'app-charge-template',
  templateUrl: './charge-template.component.html',
  styleUrls: ['./charge-template.component.scss']
})
export class ChargeTemplateComponent implements OnInit {
  @Input() template: TemplateOfficial;

  constructor() { }

  ngOnInit() {
  }

}
