import {
  animate, AnimationTriggerMetadata, state,
  style,
  transition,
  trigger,
  useAnimation
} from '@angular/animations';
import {
  flipInX,
  flipOutX,
  flipOutY,
  rollIn,
  rollOut,
  slideInDown, slideInLeft,
  slideInUp,
  slideOutDown, slideOutRight,
  slideOutUp
} from 'ng-animate';

/**
 * 进场: 从下向上滑动
 * 离场：从上向上滑动
 * @type {AnimationTriggerMetadata}
 */
export const slideInAndOutUp = trigger('slideInAndOutUp', [
  transition(
    ':enter',
    useAnimation(slideInUp, {
      params: { timing: 0.2, fromOpacity: 0.8 }
    })
  ),
  transition(
    ':leave',
    useAnimation(slideOutUp, {
      params: { timing: 0.2, toOpacity: 0.8 }
    })
  )
]);

// 淡入淡出
export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('0.2s ease-out', style({ opacity: 1 }))
  ]),
  transition(':leave', [animate('0.2s ease-out', style({ opacity: 0 }))])
]);
// 淡出
export const fadeOut = trigger('fadeOut', [
  transition(':leave', [
    style({ opacity: 1 }),
    animate('0.2s ease-out', style({ opacity: 0 }))
  ])
]);
// 从左侧飞入
export const flyLeft = trigger('flyLeft', [
  transition(':enter', [
    style({ transform: 'translateX(-100%)' }),
    animate('0.2s ease-out', style({ transform: 'translateX(0%)' }))
  ]),
  transition(':leave', [
    animate('0.2s ease-in', style({ transform: 'translateX(-100%)' }))
  ])
]);

// 从顶部飞入
export const flyTop = trigger('flyTop', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)', opacity: 0 }),
    animate('0.2s ease-out', style({ transform: 'translateY(0%)', opacity: 1 }))
  ]),
  transition(':leave', [
    animate(
      '0.2s ease-out',
      style({ transform: 'translateY(-100%)', opacity: 0 })
    )
  ])
]);

// 从底部飞入
export function flyBottom(time = 400): AnimationTriggerMetadata {
  return trigger('flyBottom', [
    transition(':enter', [
      style({ transform: 'translateY(100%)', opacity: 0 }),
      animate(
        `${time}ms ease-in`,
        style({ transform: 'translateY(0%)', opacity: 1 })
      )
    ]),
    transition(':leave', [
      animate(
        `${time}ms ease-out`,
        style({ transform: 'translateY(100%)', opacity: 0 })
      )
    ])
  ]);
}

// 宽度拉伸
export const widthStretching = trigger('widthStretching', [
  transition(':enter', [
    style({ transform: 'scaleX(0)', transformOrigin: 'left top' }),
    animate('0.2s ease-out', style({ transform: 'scaleX(1)' }))
  ]),
  transition(':leave', [
    animate(
      '0.2s ease-out',
      style({ transform: 'scaleX(0)', transformOrigin: 'left top' })
    )
  ])
]);
// 缩放进入退出
export const scaleInOut = trigger('scaleInOut', [
  transition(':enter', [
    style({ transform: 'scale(0)', opacity: 0 }),
    animate('0.2s ease-out', style({ transform: 'scale(1)', opacity: 1 }))
  ]),
  transition(':leave', [
    animate('0.2s ease-out', style({ transform: 'scale(0)', opacity: 0 }))
  ])
]);
