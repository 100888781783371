import { TToastComponent } from './../components/common-ui/t-toast/t-toast.component';
import { ModalObject } from './../models/modal-object';
import {
  Injectable,
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Injector
} from '@angular/core';
import { TAlertComponent } from '@libs/modal/src/lib/components/common-ui/t-alert/t-alert.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}
  /**
   * 创建模态框
   * @param component 要创建的组件
   * @param props 向组件内传入的属性(由@Input定义的属性)
   */
  createModal(component, props?): ModalObject {
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);
    const modalObject = new ModalObject(this.appRef, componentRef);
    // 如果传入了props则将其复制到新创建的组件中
    if (props) {
      for (const propsItem of Object.keys(props)) {
        componentRef.instance[propsItem] = props[propsItem];
      }
    }
    componentRef.instance['modalObject'] = modalObject;
    // 解决 ExpressionChangedAfterItHasBeenCheckedError 错误
    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);
    // 3. Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    // 4. Append DOM element to the body
    document.body.appendChild(domElem);
    return modalObject;
  }
  /**
   * Toast 提示框
   */
  toast({ icon, msg, duration = 3000 }): ModalObject {
    const toastModal = this.createModal(TToastComponent, {
      icon,
      msg,
      duration
    });
    if (duration > 0) {
      setTimeout(() => {
        toastModal.close();
      }, duration);
    }
    return toastModal;
  }
  /**
   * 消息提示框
   */
  alert({ title, msg, buttons }): any {
    return new Promise((resolve, reject) => {
      const alertModal = this.createModal(TAlertComponent, {
        title,
        msg,
        buttons
      });
      alertModal.componentRef.instance.select.subscribe(idx => {
        resolve(idx);
        alertModal.close();
      });
    });
  }
}
