import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StoreCommon, ServiceCommon, classifyCommon, classifyDetail, gobalCommon } from '../../models/post-admin.interface';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  apiStore = '/task-shop';
  apiService = '/task-service';
  apiClassify = '/task-classify';
  apiOrder = '/task-order';
  apiCooperate = '/task-cooperation';
  apiStandard = '/task-classify-standard';
  apiAd = '/task-ad';

  constructor(private http: HttpClient) { }

  storeList(params?): Observable<HttpResponse<StoreCommon>> {
    return this.http.get<StoreCommon>(this.apiStore, { params, observe: 'response' });
  }

  memorandumList(params?): Observable<any> {
    return this.http.get(`${this.apiOrder}-record`, { params, observe: 'response' });
  }

  addStore(params:string): Observable<any> {
    return this.http.post(this.apiStore, params);
  }

  storeDetail(id): Observable<any> {
    return this.http.get<any>(`${this.apiStore}/${id}`);
  }

  deleteFileByID(id): Observable<any> {
    return this.http.delete<any>(`${this.apiStore}-file/${id}`);
  }

  editStore(params): Observable<StoreCommon> {
    return this.http.put<StoreCommon>(`${this.apiStore}/${params.id}`, params);
  }

  addService(params) : Observable<ServiceCommon> {
    return this.http.post<ServiceCommon>(this.apiService, params);
  }

  serviceList(params?): Observable<HttpResponse<ServiceCommon>> {
    return this.http.get<ServiceCommon>(this.apiService, { params, observe: 'response' });
  }

  serviceDetail(id): Observable<ServiceCommon> {
    return this.http.get<ServiceCommon>(`${this.apiService}/${id}`);
  }

  editService(params): Observable<ServiceCommon> {
    return this.http.put<ServiceCommon>(`${this.apiService}/${params.id}`, params);
  }

  delteService(id): Observable<ServiceCommon> {
    return this.http.delete<ServiceCommon>(`${this.apiService}/${id}`);
  }

  addCategory(params): Observable<ServiceCommon> {
    return this.http.post<ServiceCommon>(`${this.apiClassify}/create-classify`, { ...params, observe: 'response' });
  }

  editCategory(params): Observable<ServiceCommon> {
    return this.http.post<ServiceCommon>(`${this.apiClassify}/create-classify`, { ...params, observe: 'response' });
  }

  addMemorandum(params): Observable<ServiceCommon> {
    return this.http.post<ServiceCommon>(`${this.apiOrder}-record`, { ...params, observe: 'response' });
  }

  editMemorandum(params): Observable<ServiceCommon> {
    return this.http.put<ServiceCommon>(`${this.apiOrder}-record/${params.id}`, { ...params, observe: 'response' });
  }

  deleteMemorandum(id): Observable<ServiceCommon> {
    return this.http.delete<ServiceCommon>(`${this.apiOrder}-record/${id}`);
  }

  addClassify(params) : Observable<classifyCommon> {
    return this.http.post<classifyCommon>(this.apiClassify, params);
  }

  classifyList(params?): Observable<HttpResponse<classifyCommon>> {
    return this.http.get<classifyCommon>(this.apiClassify, { params, observe: 'response' });
  }

  classifyListByStore(params?): Observable<HttpResponse<classifyCommon>> {
    return this.http.get<classifyCommon>(`${this.apiClassify}-relation`, { params, observe: 'response' });
  }

  delteClassify(id): Observable<any> {
    return this.http.delete(`${this.apiClassify}/${id}`);
  }

  delteClassifyByStore(id): Observable<any> {
    return this.http.post(`${this.apiClassify}/rela-delete`, { id });
  }

  classifyDetail(id): Observable<classifyDetail> {
    return this.http.get<classifyDetail>(`${this.apiClassify}/${id}`);
  }

  editClassify(params): Observable<classifyDetail> {
    return this.http.put<classifyDetail>(`${this.apiClassify}/${params.id}`, params);
  }

  sortClassify(params): Observable<classifyCommon> {
    return this.http.post<classifyCommon>(`${this.apiClassify}/sort`, params);
  }

  setNotice(params) : Observable<gobalCommon> {
    return this.http.post<gobalCommon>(this.apiCooperate, params);
  }

  setStandard(params) : Observable<any> {
    return this.http.post(this.apiStandard, params);
  }

  standardList(params?): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.apiStandard, { params, observe: 'response' });
  }

  standardDetail(id): Observable<any> {
    return this.http.get<any>(`${this.apiStandard}/${id}`);
  }

  editStandard(params): Observable<any> {
    return this.http.put<any>(`${this.apiStandard}/${params.id}`, params);
  }

  delteStandard(id): Observable<any> {
    return this.http.delete(`${this.apiStandard}/${id}`);
  }

  // 查询全局设置
  gobalDetail(): Observable<any> {
    return this.http.get<any>('/task-cooperation/info');
  }

  adList(params?): Observable<any> {
    return this.http.get<ServiceCommon>(this.apiAd, { params, observe: 'response' });
  }

  addAd(params?): Observable<any> {
    return this.http.post(this.apiAd, params);
  }

  deleteAd(id): Observable<any> {
    return this.http.put(`${this.apiAd}/${id}`,{status: 5})
  }

  editAd(id,status): Observable<any> {
    return this.http.put(`${this.apiAd}/${id}`,{status: status})
  }
}
