import { Component, OnInit } from '@angular/core';
import { ToastIcon } from '@libs/modal/src/lib/models/toast-icon';
import { UploadService } from '@libs/core/src/lib/services/upload.service';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';
import { deepClone } from '@libs/editor/src/lib/utils/misc/common';
import * as $ from 'jquery';
import { Window } from '@libs/editor/src/lib/models/ui/window';
import { TMenuCutImgComponent } from '@libs/editor/src/lib/components/editor-ui/t-menu-cut-img/t-menu-cut-img.component';
import { UserInterfaceService } from '@libs/editor/src/lib/services/ui.service';

@Component({
  selector: 'app-tool-bar-layout',
  templateUrl: './tool-bar-layout.component.html',
  styleUrls: ['./tool-bar-layout.component.css']
})
export class ToolBarLayoutComponent implements OnInit {
  activeDataItem;
  cutImg;
  constructor(
    public editor: EditorService,
    public upload: UploadService,
    public modal: ModalService,
    public ui: UserInterfaceService
  ) { }

  ngOnInit() {
    this.activeDataItem = this.editor.activeElements[0].states.activeIndex;
  }
  getColors(): any {
    const activeElements = this.editor.activeElements;
    if (!activeElements) {
      return [];
    }
    const colors = [];
    activeElements.filter(item => item.type === 'layout').forEach(eleItem => {
      // 获取svg的原始颜色
      const resColor = eleItem.states.oColors ? eleItem.states.oColors : [];
      // 将元素的colors属性与colors变量合并
      resColor.forEach(colorItem => {
        const findColor = eleItem.options.colors.find(
          item => item.oColor.toUpperCase() === colorItem.oColor.toUpperCase()
        );
        if (findColor) {
          colorItem.nColor = findColor.nColor.toUpperCase();
        }
      });
      // 剔除重复颜色
      resColor.forEach(colorItem => {
        const findColor = colors.find(item => item.oColor.toUpperCase() === colorItem.oColor.toUpperCase());
        if (!findColor) {
          colors.push(colorItem);
        }
      });
    });

    return colors;
  }
  onAddItemClick() {
    const eleItem = this.editor.activeElements[0];
    eleItem.options.data.push(deepClone(eleItem.options.data[eleItem.options.data.length - 1]));
    eleItem.updateHeight();
    this.editor.activeElements[0]['exitEditMode']();
    this.editor.template.emit('element:modified', {
      type: 'add',
      target: eleItem
    });
  }
  /**
   * 事件:当颜色改变是
   * @param color 颜色对象
   * @param color 新颜色
   */
  onColorChange(item, color, fireEvent = true): void {
    this.setColor(this.editor.activeElements, item.oColor, color, fireEvent);
    this.editor.activeElements[0]['exitEditMode']();
  }
  /**
   * 设置指定元素的颜色
   * @param eleItems 元素
   * @param oColor 原始颜色
   * @param nColor 即将设置的新颜色
   * @param fireEvent 是否触发事件
   */
  setColor(eleItems, oColor, nColor, fireEvent): void {
    console.log('设置颜色,', eleItems, oColor, nColor, fireEvent);

    // 修改颜色
    eleItems.forEach(eleItem => {
      const findColor = eleItem.options.colors.findIndex(
        colorItem => colorItem.oColor.toLowerCase() === oColor.toLowerCase()
      );
      if (findColor !== -1) {
        // 这个颜色存在于colors属性里
        if (oColor === nColor) {
          // 新修改的颜色和原始颜色一样,从颜色数组内移除
          eleItem.options.colors.splice(findColor, 1);
        } else {

          // 颜色被更新,使用新颜色
          eleItem.options.colors[findColor].nColor = nColor;
        }
      } else {
        // 这个颜色不存在于colors属性里,如果新修改的颜色与原始颜色不一致,则将其添加到colors属性内
        eleItem.options.colors.push({
          oColor,
          nColor
        });
      }
      if (fireEvent === false) {
        // 如果不触发modified事件,则手动更新HTML
        eleItem.states.html = eleItem.toHTML();
      }
    });
    // 触发更改事件
    if (fireEvent) {
      eleItems.forEach(eleItem => {
        if (eleItems.length === 1) {
          eleItem.emit('modified', {
            type: 'colorChange',
            target: eleItem
          });
        } else if (eleItems.length > 1) {
          eleItem.emit('modified', {
            type: 'colorChange',
            target: eleItem,
            targets: eleItems,
            stopPropagation: true
          });
        }
      });
      if (eleItems.length > 1) {
        eleItems[0].parent.emit('element:modified', {
          type: 'colorChange',
          targets: eleItems
        });
      }
    }
  }
  onReplaceImageClick() {
    $('#layout-edit-image-upload111').click();
  }
  onCutImgClick(){
    let item;
    let i;
    if (this.editor.activeElements[0].states.activeIndex){
      item = this.editor.activeElements[0].options.data[this.editor.activeElements[0].states.activeIndex];
      i = this.editor.activeElements[0].states.activeIndex;
    } else{
      item = this.editor.activeElements[0].options.data[0];
      i = 0;
    }
    if(!item.hasOwnProperty('图片')) return;

    if (this.cutImg){
      this.ui.remove(this.cutImg)
    }
    this.cutImg = new Window({
      width: 554,
      height: 508,
      props:{itemData:item,index:i},
      skin: 'common',
      taskbar: false,
      title:"调整菜图",
      component: TMenuCutImgComponent
    });
    this.ui.add(this.cutImg);
  }
  onPageRemoveClick() {

    const eleItem = this.editor.activeElements[0];
    if (eleItem.options.data.length > 1) {
      if (this.editor.activeElements[0].states.activeIndex) {
        eleItem.options.data.splice(this.editor.activeElements[0].states.activeIndex, 1);
      } else {
        eleItem.options.data.splice(0, 1);
      }
      // this.gridData = eleItem.options.data;
      // this.svgData = this.gridData.map(item => this.getSVG(item));
      eleItem.updateHeight();
      this.editor.activeElements[0]['exitEditMode']();
      this.editor.template.emit('element:modified', {
        type: 'delete',
        target: eleItem
      });
      //eleItem.states.html = eleItem.toHTML();
      //this.editor.snapshot();
    }
  }
  onFileChange() {
    const eleItem = this.editor.activeElements[0];
    const file = $('#layout-edit-image-upload111')[0].files[0];
    // 判断类型
    if (
      ['jpg', 'png', 'svg+xml', 'jpeg'].filter(
        item => 'image/' + item === file.type
      ).length === 0
    ) {
      alert('仅支持上传图片文件');
      return;
    } else if (file.size > 20 * 1024 * 1024) {
      // 尺寸判断
      alert('文件最大尺寸为20M，请压缩后上传，推荐压缩文件网址：                 https://www.bejson.com/ui/compress_img/');
      return;
    }
    const loadingToast = this.modal.toast({
      icon: ToastIcon.LOADING,
      msg: '正在上传',
      duration: 0
    });
    this.upload.uploadTemp({
      file,
      onSuccess: res => {
        if (this.editor.activeElements[0].states.activeIndex) {
          this.editor.activeElements[0].options.data[this.editor.activeElements[0].states.activeIndex]['图片'] = res;
        } else {
          this.editor.activeElements[0].options.data[0]['图片'] = res;
        }
        eleItem['exitEditMode']();
        this.editor.template.emit('element:modified', {
          type: 'changeImg',
          target: eleItem
        });
        loadingToast.close();
        this.modal.toast({
          icon: ToastIcon.SUCCESS,
          msg: '已更换'
        });
      },
      onFail: e => {
        console.log('上传失败', e);
        loadingToast.close();
        this.modal.toast({
          icon: ToastIcon.ERROR,
          msg: '上传失败'
        });
      }
    });
    $('#layout-edit-image-upload111').val('');
  }
}
