import { Component, OnInit, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { Env } from '@libs/core/src/lib/util/environment';
import { CoopService } from '@libs/core/src/lib/services/coop.service';

@Component({
  selector: 'app-edit-matting',
  templateUrl: './edit-matting.component.html',
  styleUrls: ['./edit-matting.component.scss']
})
export class EditMattingComponent implements OnInit {
  @Input() template;
  stageSize = 30;
  cWidth = 592;
  cHeight = 578;
  // 背景方块图片
  alphaBgImg = null;
  // 鼠标小手图片
  dragCursorImg = null;
  // 原图
  image = null;
  // 抠完的图
  cutImage = null;
  // 是否鼠标按下
  isDown = false;
  // 笔触粗细
  size = 20;
  // 舞台位置
  x = 0;
  y = 0;
  spaceIsDown = false;
  // 舞台缩放
  zoom = 1;
  // 鼠标位置
  mouseX = -100;
  mouseY = -100;
  lastX = 0;
  lastY = 0;
  downX;
  downY;
  // 自定义绘制
  customTrimap = null; // 绘制笔触
  resultCanvs = null; // 绘制结果图
  oldImgCanvas = null;
  // 笔刷类型
  brushType = 'patch'; // patch修补 erase擦除
  // 为了移除事件
  thisOnCanvasMouseMove;
  thisonDocumentMouseup;
  thisonDocumentKeyDown;
  thisonDocumentKeyUp;
  loading = false;
  btnLoading = false;
  // 保存历史记录数组
  // 状态管理(撤销恢复)
  public stageManager = {
    // 状态指针
    index: -1,
    // 撤销恢复状态列表
    stack: []
  };
  zoomTip = false;
  timer;
  bulePointNum = 0;
  afterBuleNum = 0;
  isInPath = false;

  // 缓存像素信息
  colorData;
  cutData;
  resultData;
  imgData;
  canvasStyle = {
    'position': 'absolute',
    'width': '592px',
    'height': '578px',
    'left': '0px',
    'top': '0px',
    'touch-action': 'none',
    'user-select': 'none'
  }
  customTrimpData: any;
  constructor(
    public coop: CoopService,
    private http: HttpClient,
    private message: MessageService,
    private modal: NzModalRef,
    private modalS: ModalService,
    public env: Env
  ) { }

  // 画布缩小放大 还缺一个change方法
  subStage() {
    let delta = -0.05;
    const lastZoom = this.zoom;
    if (this.zoom + delta < 0.05 || this.zoom + delta > 5) return;
    this.zoom += delta;
    const lx = (this.mouseX - this.x) / this.zoom,
      ly = (this.mouseY - this.y) / this.zoom;
    const offsetX = this.zoom * lx - lastZoom * lx;
    const offsetY = this.zoom * ly - lastZoom * ly;
    this.x -= offsetX;
    this.y -= offsetY;
    this.updateCanvas();
  }

  plusStage() {
    let delta = 0.05;
    const lastZoom = this.zoom;
    if (this.zoom + delta < 0.1 || this.zoom + delta > 5) return;
    this.zoom += delta;
    const lx = (this.mouseX - this.x) / this.zoom,
      ly = (this.mouseY - this.y) / this.zoom;
    const offsetX = this.zoom * lx - lastZoom * lx;
    const offsetY = this.zoom * ly - lastZoom * ly;
    this.x -= offsetX;
    this.y -= offsetY;
    this.updateCanvas();
  }

  // 恢复原始大小
  adaptStage() {
    this.spaceIsDown = false;
    this.zoom = 1;
    this.x = 0;
    this.y = 0;
    this.mouseX = -100;
    this.mouseY = -100;
    this.lastX = 0;
    this.lastY = 0;
    const canvasLeft = document.getElementById("canvasLeft");
    const maxSize = 600;
    const scale =
      maxSize / Math.max(maxSize, this.image.width, this.image.height);
    const scaleCanvas = document.createElement("canvas");
    scaleCanvas.width = this.image.width * scale;
    scaleCanvas.height = this.image.height * scale;

    // 适应canvas
    this.zoom =
      Math.min(
        canvasLeft['width'] / this.image.width,
        canvasLeft['height'] / this.image.height
      ) * 0.8;
    this.x = canvasLeft['width'] / 2 - (this.image.width * this.zoom) / 2;
    this.y = canvasLeft['height'] / 2 - (this.image.height * this.zoom) / 2;
    this.updateCanvas();
  }

  // 鼠标按下 开始画线
  onCanvasMousedown(e) {
    this.isDown = true;
    const leftCanvas = document.getElementById("canvasLeft");
    const bbox = leftCanvas.getBoundingClientRect();
    this.mouseX = e.pageX - bbox.left;
    this.mouseY = e.pageY - bbox.top - window.scrollY;
    this.lastX = this.mouseX;
    this.lastY = this.mouseY;

    if (!this.spaceIsDown) {
      // 获取点击点是否在图片上，要加上画笔的半径
      const maxXpoint = this.x + this.oldImgCanvas.width * this.zoom + this.size;
      const maxYpoint = this.y + this.oldImgCanvas.height * this.zoom + this.size;

      if (this.x <= this.mouseX && this.mouseX <= maxXpoint && this.y <= this.mouseY && this.mouseY <= maxYpoint) {
        this.isInPath = true;
        // 保存初始历史记录
        if (this.stageManager.stack.length === 0) {
          this.snapshot(this.customTrimap);
        }

        // 设置线条的颜色和圆圈的颜色
        let ctx = this.customTrimap.getContext("2d");
        if (this.brushType === 'patch') {
          ctx.strokeStyle = "rgb(32, 83, 244)";
          ctx.fillStyle = "rgb(32, 83, 244)";
          ctx.globalCompositeOperation = 'source-over';
        } else {
          ctx.strokeStyle = "red";
          ctx.fillStyle = "red";
          ctx.globalCompositeOperation = 'destination-out';
        }

        // 开始画线
        ctx.lineWidth = this.size / this.zoom;
        ctx.beginPath();
      } else {
        this.isInPath = false;
      }
    }
  }

  // 鼠标滚动，放大缩小画布
  onCanvasMouseWheel(e) {
    let delta = 0;
    if (e.deltaY < 0) {
      // 缩小
      delta = 0.05;
    } else {
      // 放大
      delta = -0.05;
    }
    const lastZoom = this.zoom;
    this.zoomTip = true;
    if (this.zoom + delta < 0.1 || this.zoom + delta > 5) return;
    this.zoom += delta;
    const lx = (this.mouseX - this.x) / this.zoom,
      ly = (this.mouseY - this.y) / this.zoom;
    const offsetX = this.zoom * lx - lastZoom * lx;
    const offsetY = this.zoom * ly - lastZoom * ly;
    this.x -= offsetX;
    this.y -= offsetY;
    this.updateCanvas();
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.zoomTip = false;
    }, 3000);
  }

  onCanvasMouseMove(e) {
    const leftCanvas = document.getElementById("canvasLeft");
    const bbox = leftCanvas.getBoundingClientRect();
    this.mouseX = e.pageX - bbox.left;
    this.mouseY = e.pageY - bbox.top - window.scrollY;
    if (this.isDown) {
      if (this.spaceIsDown) {
        const deltaX = this.mouseX - this.lastX;
        const deltaY = this.mouseY - this.lastY;

        this.x += deltaX;
        this.y += deltaY;

        this.lastX = this.mouseX;
        this.lastY = this.mouseY;
      } else {
        if (this.isInPath) {
          let ctx = this.customTrimap.getContext("2d");
          ctx.lineTo(
            (this.mouseX - this.x) / this.zoom,
            (this.mouseY - this.y) / this.zoom
          );
          ctx.moveTo(
            (this.mouseX - this.x) / this.zoom,
            (this.mouseY - this.y) / this.zoom
          );
          ctx.closePath();
          ctx.stroke();
          this.computedCanvas();
        }
      }
    }
    this.updateCanvas();
  }

  onDocumentMouseup(e) {
    if (this.isDown) {
      this.isDown = false;
      if (!this.spaceIsDown) {
        // 只有开端在图片内才保存历史记录
        if (this.isInPath) {
          // 鼠标未移动不足够划线则画点
          let ctx = this.customTrimap.getContext('2d');
          const customTrimpData = ctx.getImageData(0, 0, this.customTrimap.width, this.customTrimap.height);
          ctx.arc((this.mouseX - this.x) / this.zoom, (this.mouseY - this.y) / this.zoom, (this.size / 2) / this.zoom, 0, 2 * Math.PI);
          ctx.fill();
          this.computedCanvas();
          this.snapshot(this.customTrimap);
        }
      }
    }
  }

  onDocumentKeyUp(e) {
    if (e.keyCode == 32) {
      this.spaceIsDown = false;
      this.updateCanvas();
    }
  }

  onDocumentKeyDown(e) {
    if (e.keyCode == 32) {
      this.spaceIsDown = true;
      this.updateCanvas();
    }
  }

  computedCanvas() {
    const oldImgCanvasCtx = this.oldImgCanvas.getContext('2d');
    // 先画原图 最底层画布
    oldImgCanvasCtx.drawImage(this.image, 0, 0, this.image.width, this.image.height);
    oldImgCanvasCtx.save();
    oldImgCanvasCtx.globalAlpha = 0.4;
    oldImgCanvasCtx.drawImage(this.customTrimap, 0, 0);
    oldImgCanvasCtx.restore();

    // 绘制结果画布
    const resultCanvsCtx = this.resultCanvs.getContext('2d');
    resultCanvsCtx.save();
    resultCanvsCtx.globalAlpha = 1;
    resultCanvsCtx.drawImage(this.image, 0, 0, this.image.width, this.image.height);
    resultCanvsCtx.globalCompositeOperation = 'destination-in';
    resultCanvsCtx.drawImage(this.customTrimap, 0, 0);
    resultCanvsCtx.restore();
  }

  updateCanvas() {
    const canvasLeft = document.getElementById("canvasLeft");
    const canvasRight = document.getElementById("canvasRight");
    // @ts-ignore
    const canvasLeftCtx = canvasLeft.getContext('2d');
    canvasLeftCtx.clearRect(0, 0, canvasLeft.clientWidth, canvasLeft.clientHeight);
    // @ts-ignore
    const canvasRightCtx = canvasRight.getContext('2d');
    canvasRightCtx.clearRect(0, 0, canvasRight.clientWidth, canvasRight.clientHeight);

    // **绘制左侧图像 begin**
    // 绘制原图
    this.drawOImage(canvasLeft);
    // 绘制笔刷
    this.drawBrush(canvasLeft);

    // **绘制右侧图像 begin**
    // 绘制抠好的图
    this.drawRImage(canvasRight);
    // 绘制笔刷
    this.drawBrush(canvasRight);
  }

  // 加载图片
  loadImage(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        resolve(img);
      };
      img.onerror = e => {
        reject(e);
      };
      img.src = url + '?' + (new Date().getTime());
    });
  }

  // 绘制透明背景方格图
  drawAlpha(canvas) {
    const ctx = canvas.getContext("2d");
    var bg = ctx.createPattern(
      this.alphaBgImg,
      "repeat"
    ); /* 图片的平铺方式 repeat repeat-x repeat-y no-repeat */
    ctx.fillStyle = bg; /* 请注意 : fillStyle 填充样式这个属性不仅可以设置颜色还可以设置背景图片 */
    ctx.save();
    ctx.translate(-5, 0);
    ctx.fillRect(0, 0, canvas.width + 5, canvas.height);

    ctx.restore();
  }


  // 优化，只做绘制
  drawOImage(canvas) {
    // 左侧画布
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      this.oldImgCanvas,
      this.x,
      this.y,
      this.image.width * this.zoom,
      this.image.height * this.zoom
    );
  }

  drawRImage(canvas) {
    const ctx = canvas.getContext("2d");
    // 画右图边框
    ctx.strokeStyle = "#337cf9";
    ctx.strokeRect(this.x, this.y, this.image.width * this.zoom, this.image.height * this.zoom);
    ctx.drawImage(
      this.resultCanvs,
      this.x,
      this.y,
      this.cutImage.width * this.zoom,
      this.cutImage.height * this.zoom
    );
  }

  // 取消
  esc() {
    this.modal.close();
  }

  save() {
    this.modalS.selectFolder().afterClose.subscribe(folder_id => {
      if (!isNaN(folder_id)) {
        this.completOpt({folder_id, is_material: 1})
      }
    })
  }

  // 完成优化
  async completOpt(options?) {
    this.btnLoading = true;
    const canvas = document.createElement("canvas");
    canvas.width = this.image.width;
    canvas.height = this.image.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      this.resultCanvs,
      0,
      0,
      this.cutImage.width,
      this.cutImage.height
    );
    let baseUrl = canvas.toDataURL("image/png");
    let res = (await this.http.post(`/soft-ware-use/upload-base64-to-update`, { base64: baseUrl, ...options }).toPromise()) as any;
    this.btnLoading = false;
    this.modal.close(res);
  }

  // 绘制笔刷
  drawBrush(canvas) {
    const ctx = canvas.getContext("2d");
    if (this.spaceIsDown) {
      ctx.drawImage(
        this.dragCursorImg,
        this.mouseX - this.dragCursorImg.width / 2,
        this.mouseY - this.dragCursorImg.height / 2
      );
    } else {
      ctx.beginPath();
      if (this.brushType === 'patch') {
        ctx.fillStyle = "rgba(32, 83, 244, .8)";
      } else {
        ctx.fillStyle = "rgba(245, 60, 48, .8)";
      }
      ctx.arc(this.mouseX, this.mouseY, this.size / 2, 0, 2 * Math.PI);
      ctx.fill();
    }
  }

  // 切换笔刷
  changeBrush(type) {
    this.brushType = type;
    this.spaceIsDown = false;
    this.updateCanvas();
  }

  zoomChange() {
    if (this.zoom < 0.1) {
      this.zoom = 0.1;
    }
  }

  // 保存快照 只保留三十次 只保存存储画笔的canvas就可以
  snapshot(canvas) {
    let ctx = canvas.getContext("2d");
    let width = ctx.canvas.width;
    let height = ctx.canvas.height;
    const imgData = ctx.getImageData(0, 0, width, height);
    if (this.stageManager.index !== this.stageManager.stack.length - 1) {
      // 如果当前指针不是在最新状态,则删除从当前的index开始往后的所有步骤
      this.stageManager.stack.splice(this.stageManager.index + 1, this.stageManager.stack.length - this.stageManager.index - 1);
    }
    // 如果保存的历史大于30个，删除第一个，然后在push。因为第一个是存储的原始状态，所以这里大于31
    if (this.stageManager.stack.length >= 31) {
      this.stageManager.stack.splice(0, 1);
    } else {
      this.stageManager.index++;
    }
    this.stageManager.stack.push(imgData);
  }

  // 撤销
  undo() {
    if (this.stageManager.index > 0) {
      this.stageManager.index--;
      const imgData = this.stageManager.stack[this.stageManager.index];
      let ctx = this.customTrimap.getContext("2d");
      ctx.putImageData(imgData, 0, 0);
      this.computedCanvas();
      this.updateCanvas();
    } else {
      this.message.warning('无可撤销操作!');
    }
  }

  // 恢复
  recovery() {
    if (this.stageManager.index < this.stageManager.stack.length - 1) {
      this.stageManager.index++;
      const imgData = this.stageManager.stack[this.stageManager.index];
      let ctx = this.customTrimap.getContext("2d");
      ctx.putImageData(imgData, 0, 0);
      this.computedCanvas();
      this.updateCanvas();
    } else {
      this.message.warning('无可恢复操作!');
    }
  }

  originalSize() {
    this.zoomTip = true;
    this.loading = true;
    const iWidth = this.image.width;
    const iHeight = this.image.height;
    this.zoom = 1;
    this.x = (592 - this.zoom * iWidth) / 2;
    this.y = (578 - this.zoom * iHeight) / 2;
    this.computedCanvas();
    this.updateCanvas();
    this.loading = false;
    this.timer = setTimeout(() => {
      this.zoomTip = false;
    }, 3000);
  }

  computedInitSize() {
    const iWidth = this.image.width;
    const iHeight = this.image.height;
    const cWidth = this.cWidth - 20; // 每边预留10像素
    const cHeight = this.cHeight - 20; // 每边最多预留10像素

    if (iWidth <= cWidth && iHeight <= cHeight) { // 宽高均不大于画布的宽高
      this.zoom = 1;
      this.x = (this.cWidth - iWidth) / 2;
      this.y = (this.cHeight - iHeight) / 2;
    } else if (iWidth > cWidth && iHeight > cHeight) { // 宽高均大于画布宽高
      let zoomW = cWidth / iWidth;
      let zoomH = cHeight / iHeight;
      this.zoom = Math.min(zoomH, zoomW);
      this.x = (this.cWidth - this.zoom * iWidth) / 2;
      this.y = (this.cHeight - this.zoom * iHeight) / 2;
    } else if (iHeight > cHeight) { // 仅高度大于
      this.zoom = cHeight / iHeight;
      this.x = (this.cWidth - this.zoom * iWidth) / 2;
      this.y = (this.cHeight - this.zoom * iHeight) / 2;
    } else if (iWidth > cWidth) { // 仅宽度大于
      this.zoom = cWidth / iWidth;
      this.x = (this.cWidth - this.zoom * iWidth) / 2;
      this.y = (this.cHeight - this.zoom * iHeight) / 2;
    }
  }

  ngOnInit(): void { this.loading = true;}

  async ngAfterViewInit() {
    // 加载图片资源
    // console.log(this.template, 'item');
    this.dragCursorImg = await this.loadImage("/assets/img/common/cursor-drag.png");
    // this.image = await this.loadImage('//image.tubangzhu.com.cn/updata/201901/21/541ea6b49ea4bf8484c1.jpeg');
    // this.cutImage = await this.loadImage('//image.tubangzhu.com.cn/temporary/image/matting/202008/18/e3dfcc81417c00a38239.png');
    this.image = await this.loadImage(this.template.oldImgUrl);
    this.cutImage = await this.loadImage(this.template.newImgUrl);
    this.image.width = this.cutImage.width;
    this.image.height = this.cutImage.height;

    // 左侧画布
    this.oldImgCanvas = document.createElement('canvas');
    this.oldImgCanvas.width = this.image.width;
    this.oldImgCanvas.height = this.image.height;

    // 用于绘制笔触和初始颜色的画布
    this.customTrimap = document.createElement("canvas");
    this.customTrimap.width = this.image.width;
    this.customTrimap.height = this.image.height;
    const customTrimapCtx = this.customTrimap.getContext("2d");
    customTrimapCtx.lineCap = "round";
    customTrimapCtx.drawImage(this.image, 0, 0, this.image.width, this.image.height);
    this.customTrimpData = customTrimapCtx.getImageData(0, 0, this.customTrimap.width, this.customTrimap.height);

    // 画cutImage 获得像素改变的初始颜色
    const cutImageCanvas = document.createElement('canvas');
    cutImageCanvas.width = this.image.width;
    cutImageCanvas.height = this.image.height;
    const cutImageCtx = cutImageCanvas.getContext('2d');
    cutImageCtx.drawImage(this.cutImage, 0, 0, this.image.width, this.image.height);
    const cutData = cutImageCtx.getImageData(0, 0, cutImageCanvas.width, cutImageCanvas.height);

    // 初始化颜色
    for (let i = 0; i < this.customTrimpData.data.length; i += 4) {
      if (cutData.data[i + 3] != 0) {
        this.customTrimpData.data[i] = 32;
        this.customTrimpData.data[i + 1] = 83;
        this.customTrimpData.data[i + 2] = 244;
        this.customTrimpData.data[i + 3] = cutData.data[i + 3];
      } else {
        this.customTrimpData.data[i + 3] = 0;
      }
    }

    customTrimapCtx.putImageData(this.customTrimpData, 0, 0);

    // 创建虚拟画布，绘制左图涂抹后的结果图
    this.resultCanvs = document.createElement('canvas');
    this.resultCanvs.width = this.image.width;
    this.resultCanvs.height = this.image.height;
    const resultCanvsCtx = this.resultCanvs.getContext('2d');
    resultCanvsCtx.drawImage(this.image, 0, 0, this.image.width, this.image.height);

    this.computedInitSize();
    this.computedCanvas();
    this.updateCanvas();

    // 此操作是为了能顺利remove监听事件
    this.thisOnCanvasMouseMove = this.onCanvasMouseMove.bind(this);
    this.thisonDocumentMouseup = this.onDocumentMouseup.bind(this);
    this.thisonDocumentKeyDown = this.onDocumentKeyDown.bind(this);
    this.thisonDocumentKeyUp = this.onDocumentKeyUp.bind(this);
    document.addEventListener("mousemove", this.thisOnCanvasMouseMove);
    document.addEventListener("mouseup", this.thisonDocumentMouseup);
    document.addEventListener("keydown", this.thisonDocumentKeyDown);
    document.addEventListener("keyup", this.thisonDocumentKeyUp);
    this.loading = false;
  }

  ngOnDestroy(): void {
    document.removeEventListener("mousemove", this.thisOnCanvasMouseMove);
    document.removeEventListener("mouseup", this.thisonDocumentMouseup);
    document.removeEventListener("keydown", this.thisonDocumentKeyDown);
    document.removeEventListener("keyup", this.thisonDocumentKeyUp);
    window.onresize = null;
  }
}
