import { Injectable } from '@angular/core';
import { FolderMember } from '@libs/core/src/lib/models/folder-member';
import { Classify } from '@libs/core/src/lib/models/classify';
import { MemberService } from '@libs/core/src/lib/services/member/member.service';
import { DesignParamService } from '@libs/core/src/lib/services/design-param.service';
import { ReductionTemplate } from '../../../+state/app.actions';
import { Store } from '@ngrx/store';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { DataProvider } from '@libs/core/src/lib/util/data-provider';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { UploadService } from '@libs/core/src/lib/services/upload.service';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Font } from '@libs/core/src/lib/models/font';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class MemberManageService {
  apiFont = '/font';
  apiDelFont = '/font/delete-user-font';
  type = 'template';
  // 选择的分类
  searchList: any[];
  // 我的收藏的选择分类
  favoriteSearchList: any[];
  selectSearch: any = 0;
  // 排序值manageStatus
  sortValue: string;
  sortType: number;
  // 全选
  checkAll = false;
  selectCount = 0;
  // 查询条件
  public query = {
    folder: 0
  };
  private diffFolder = true;
  // 管理状态
  public manageStatus = false;
  loading = false;
  // 选中状态
  public checkOptions: { value: number; checked: boolean }[] = [];

  // 正在编辑的数据
  public editData: any;
  // 是否正在编辑标题
  public editTitle: boolean;

  public classifyList: Classify[] = [];
  // 查询列表
  public list: any[];
  // 返回的查询值
  private _list$ = new Subject<any>();
  // 查询后端接口的值
  private _sourceList$ = new Subject<any>();
  private _folderList$ = new Subject<any>();
  public folder: FolderMember[];
  private _folder$ = new Subject<FolderMember[]>();
  listSubscription: Subscription;
  folderSubscription: Subscription;
  dataProvider = new DataProvider(this._sourceList$);

  fileUpload = item => {
    return this.uploadService.upload(item, this.query.folder);
  };

  constructor(
    private authService: AuthService,
    private store: Store<any>,
    private uploadService: UploadService,
    private modal: ModalService,
    private message: MessageService,
    private memberService: MemberService,
    private designService: DesignParamService,
    private http: HttpClient
  ) {
    this.resetQuery();
    // 查询模版列表
    this.listSubscription = this.sourceList$
      .pipe(
        tap(() => {
          this.manageStatus = false;
          this.loading = true;
          this.list = [];
          this.selectCount = 0;
          this.dataProvider.reset();
        }),
        switchMap((data: any) => {
          // 强制更新参数
          const force = data.force;
          delete data.force;
          if (force) this.query = data;
          // 处理查询参数
          if (data.folder !== undefined && data.folder !== this.query.folder) {
            this.diffFolder = true;
            this.query = data;
            // 重置选择
            this.resetQuery();
          } else {
            this.diffFolder = true;
            this.query = {
              ...this.query,
              ...data
            };
          }
          // 模版列表
          if (this.type === 'template')
            return this.memberService.getMemberTemplateList({...this.query, limit: '24'});
          if (this.type === 'favoriteDesign')
            return this.memberService.getMemberTemplateList({...this.query, limit: '24', favorite: '20'});
          // 素材列表
          else if (this.type === 'material')
            return this.memberService.getMaterialList(this.query);
          // 我的收藏
          else if (this.type === 'favorite')
            return this.memberService.getMemberFavoriteList(this.query);
          else if (this.type === 'trash')
            return this.memberService.getMemberTrashList(this.query);
        }),
        map(data => this.dataProvider.prepare(data))
      )
      .subscribe((data: any[]) => {
        this.loading = false;
        if (data['search']) {
          // 处理场景值
          const search = data['search'];
          this.searchList = search;
          data = data['template'];
        }
        if (this.type === 'favorite' && !(this.favoriteSearchList && this.favoriteSearchList.length && this.favoriteSearchList[0].key)) {
          this.favoriteSearchList = data
            .filter((cls, index, arr) => arr.findIndex(item => item.classifyPid == cls.classifyPid) === index)
            .map(data => {return {key: data.classifyPid, name: data.parentName}})
        }
        // 处理数据选择和分类选择
        this.generateCheckOptions(data);
        // 处理分类选择
        this.list = data;
        this.list$.next(this.list);
      });
    // 查询文件夹
    this.folderSubscription = this.folderList$
      .pipe(
        switchMap(() => {
          if (this.type === 'template') {
            return this.memberService.getFolderList();
          } else if (this.type === 'material') {
            return this.memberService.getFolderMaterial();
          } else {
            return of(null)
          }
        })
      )
      .subscribe(data => {
        this.folder = data;
        this.folder$.next(this.folder);
      });
  }

  /**
   * 上传素材
   * @param {{file: UploadFile}} info
   */
  uploadMaterial(info: { file: NzUploadFile }) {
    if (info.file.status === 'uploading') {
      this.loading = true;
      return;
    }
    if (info.file.status === 'done') {
      this.loading = false;
      const data = info.file.response;
      this.list.unshift(data);
      this.checkOptions.unshift({
        value: data.id,
        checked: false
      });
      // 增加对应文件夹数量
      const index = this.folder.findIndex(item => item.id === this.query.folder);
      if (index >= 0)
        this.folder[index].count += 1;
      this.generateCheckOptions(this.list);
      this.list$.next(this.list);
    }
  }

  // 根据参数重新查询
  doSearch() {
    this.sourceList$.next({
      sort: (this.sortType === 1 ? '-' : '') + this.sortValue,
      classify_diy: this.selectSearch
    });
    if (this.type === 'template' || this.type === 'material') {
      this.folderList$.next('search');
    }
  }

  /**
   * 改变排序方式
   */
  changeSort() {
    this.sortType = this.sortType === 1 ? 2 : 1;
    this.doSearch();
  }

  /**
   * 初始化筛选项
   */
  resetQuery() {
    this.selectSearch = 0;
    this.sortType = 1;
    this.sortValue = 'created_at';
  }

  /**
   * 选中一个项目
   * @param index
   * @param {boolean} force 强制选中
   */
  checkedOne(index, force = false) {
    if (this.manageStatus === false && this.type === 'template') {
      // const windowRef = window.open();
      // this.editData = this.list[index];
      // windowRef.location.href = this.handleOne('edit');
      return;
    }
    // if (!this.manageStatus)
    //   this.resetSelected();
    // 取消其他项
    if (!this.manageStatus) {
      let count = 0;
      this.checkOptions.forEach((value, i) => {
        if (index !== i) {
          value.checked = false;
        } else {
          if (value.checked === true)
            count = 1;
        }
      });
      this.selectCount = count;
    }
    const value = this.checkOptions[index];
    this.checkOptions[index] = {
      ...value,
      checked: force ? force : !value.checked
    };
    if (value.checked === false) this.selectCount++;
    else if (force === false) this.selectCount--;
  }

  /**
   * 针对一个模版进行操作
   * @param {string} type
   * @param {MouseEvent} event
   * @returns {any}
   */
  handleOne(type: string, event?: MouseEvent): any {
    if (event) event.stopPropagation();
    let data;
    if (this.editData) {
      data = this.editData;
    } else {
      let index;
      this.checkOptions.forEach(item => {
        if (item && item.checked === true) {
          index = item.value;
        }
      });
      data = this.list[
        this.list.findIndex(item => item[this.identify] === index)
      ];
    }
    if (!data) return;
    switch (type) {
      case 'preview':
        return;
      // this.store.dispatch(new ModalOpenTemplatePreview(data));
      // return;
      case 'edit':
        return this.designService.getParam({
          templateId: data.templateId,
          method: 'member'
        });
      case 'rename':
        this.editTitle = true;
        this.editData = {
          ...data
        };
        return;
      case 'download':
        this.modal.downloadTemplate(data);
        return;
      case 'share': {
        return this.modal.shareTemplateModal(userId => this.memberService.shareTemplate(data.templateId, userId));
      }

    }
  }

  /**
   * 对模版进行操作
   * @param {string} type
   * @param extra
   * @param {boolean} batch
   */
  operation(type: string, extra?: any, batch = false, fun = (data: any) => { }): void {
    const template = this.editData;
    let id, data$: Observable<any>;
    if (!batch && type !== 'emptyTrash') {
      id = [template[this.identify]];
    } else if (type === 'emptyTrash') {
      id = [];
    } else {
      const ids = [];
      this.checkOptions.filter(item => item.checked === true).forEach(value => {
        ids.push(value.value);
      });
      id = ids;
    }
    if ((!id || id.length <= 0) && type !== 'emptyTrash') return;
    let params: any = {
      type: type,
      ids: id
    };
    switch (type) {
      // 重命名
      case 'rename': {
        // 判断值
        const index = this.list.findIndex(
          item => item[this.identify] === template[this.identify]
        );
        let field;
        if (this.type === 'template' || this.type === 'favoriteDesign') field = 'title';
        else if (this.type === 'material') field = 'fileName';
        if (template['title'] === this.list[index][field]) return;
        const tmp = this.list[index];
        // 临时修改模版数据
        this.list[index][field] = template[field];
        params[field] = template[field];
        params[this.identify] = template[this.identify];
        let tmpOb;
        if (this.type === 'template' || this.type === 'favoriteDesign') {
          tmpOb = this.memberService.update(params);
        } else if (this.type === 'material') {
          params.ids = id[0];
          tmpOb = this.getOperation(params);
        }
        data$ = tmpOb.pipe(
          tap(() => {
            this.list[index].title = template.title;
            this.loading = false;
            this.editTitle = false;
            this.editData = null;
          }),
          catchError(err => {
            this.loading = false;
            this.editData = tmp;
            this.message.error(err);
            return of(null);
          })
        );
        break;
      }
      // 取消收藏
      case 'no_favorite':
        data$ = this.memberService.favoriteTemplate({template_id: template.templateId, favorite: 10}).pipe(
          // 操作成功
          tap(() => {
            this.loading = false;
            this.deleteItemFromIds(id);
            this.message.success('已取消收藏!');
            this.resetSelected();
          }),
          catchError(err => {
            this.resetSelected();
            this.loading = false;
            this.message.error(err);
            return of(null);
          })
        );
        this.modal.confirm('确认不再收藏此模板？', () => {
          this.loading = true;
          data$.subscribe();
        });
        return;
      // 移动到回收站
      case 'trash': {
        data$ = this.getOperation(params).pipe(
          // 操作成功
          tap(() => {
            this.loading = false;
            this.deleteItemFromIds(id);
            this.message.success('删除成功!');
            this.resetSelected();
          }),
          catchError(err => {
            this.resetSelected();
            this.loading = false;
            this.message.error(err);
            return of(null);
          })
        );
        let title;
        if (this.type === 'template') {
          title = '是否要将选中的' + id.length + '项移动到回收站？';
        } else if (this.type === 'material') {
          title = '是否要将选中的' + id.length + '项素材刪除？';
        }
        this.modal.confirm(title, () => {
          this.loading = true;
          data$.subscribe(() => {
            if (this.type === 'template')
              this.authService.updateUser({
                ...this.authService.user,
                'trashCount': this.authService.user.trashCount + 1,
                'templateCount': this.authService.user.templateCount - 1
              });
            if (this.type === 'material')
              this.authService.updateUser({
                ...this.authService.user,
                'materialCount': this.authService.user.materialCount - 1
              });
          });
        });
        return;
      }
      // 复制
      case 'copy': {
        data$ = this.getOperation(params).pipe(
          tap(() => {
            this.store.dispatch(new ReductionTemplate(id.length));
            this.loading = false;
            this.message.success('复制成功!');
            this.authService.updateUser({
              ...this.authService.user,
              'templateCount': this.authService.user.templateCount + 1
            });
            // 重新查询数据
            this.doSearch();
          }),
          catchError(err => {
            this.loading = false;
            this.message.error(err);
            return of(null);
          })
        );
        break;
      }
      // 移动到文件夹
      case 'move': {
        const folder = extra.folder;
        if (!folder && folder !== 0) return;
        params = {
          ...params,
          folder: folder
        };
        data$ = this.getOperation(params).pipe(
          tap(() => {
            this.loading = false;
            this.deleteItemFromIds(id);
            // 对应文件夹增加个数
            const index = this.folder.findIndex(item => item.id === folder);
            this.folder[index].count += id.length;
            this.folder$.next(this.folder);
            this.resetSelected();
          }),
          catchError(err => {
            this.loading = false;
            this.message.error(err);
            return of(null);
          })
        );
        break;
      }
      case 'emptyTrash':
        data$ = this.memberService.operation(params).pipe(
          // 操作成功
          tap(() => {
            this.loading = false;
            this.deleteItemFromIds(id, true);
            this.message.success('回收站清空成功！');
          }),
          catchError(err => {
            this.loading = false;
            this.message.error(err);
            return of(null);
          })
        );
        this.modal.confirm('确认要清空回收站吗？', () => {
          this.loading = true;
          data$.subscribe(() => {
            this.authService.updateUser({
              ...this.authService.user,
              'trashCount': 0
            });
          });
        });
        return;
      case 'reduction':
      case 'delete': {
        data$ = this.memberService.operation(params).pipe(
          // 操作成功
          tap(() => {
            this.loading = false;
            this.deleteItemFromIds(id);
            let title;
            if (this.type === 'favoriteDesign') {
              title = '取消收藏成功！'
            } else if (type === 'delete') {
              title = '模版删除成功！'
            } else {
              title = '模版还原成功';
            }
            this.message.success(title);
            this.resetSelected();
          }),
          catchError(err => {
            this.loading = false;
            this.message.error(err);
            this.resetSelected();
            return of(null);
          })
        );
        let title;
        if (this.type === 'favoriteDesign') {
          title = '确认取消收藏此模板？'
        } else if (type === 'delete') {
          title = '是否要将选中的' + id.length + '项永久删除？'
        } else {
          title = '是否要讲选中的' + id.length + '项恢复到我的设计？'
        }
        this.modal.confirm(title, () => {
          this.loading = true;
          data$.subscribe(() => {
            if (this.type === 'favoriteDesign') return;
            if (type === 'reduction') {
              this.authService.updateUser({
                ...this.authService.user,
                'trashCount': this.authService.user.trashCount - 1,
                'templateCount': this.authService.user.templateCount + 1
              });
            } else if (type === 'delete') {
              this.authService.updateUser({
                ...this.authService.user,
                'trashCount': this.authService.user.trashCount - 1
              });
            }
          });
        });
        return;
      }
    }
    this.loading = true;
    data$.subscribe(fun);
  }

  // 全选的值改变
  changeSelectAll(reverse = false): boolean {
    // 处理选中
    this.checkOptions.forEach((value, index) => {
      if (reverse) {
        this.checkAll = this.checkAll ? false : this.checkAll;
        value.checked = !value.checked;
      } else {
        if (value.checked !== this.checkAll) {
          value.checked = this.checkAll;
        }
      }
      this.checkOptions[index] = value;
    });
    // 统计选中数
    let count = 0;
    this.checkOptions.forEach(value => {
      if (value.checked === true) count++;
    });
    this.selectCount = count;
    return false;
  }

  // isPrint() {
  //   const selectIndex = this.checkOptions.findIndex(item => item.checked === true);
  //   const template: TemplateOfficial = this.list[selectIndex];
  //   return (template && template.classify && template.classify.unit === 'mm') ? (template.classify.orderLink ? template.classify.orderLink : 'http://www.duoduo.com') : '';
  // }

  /**
   * 重置已选择的条目
   */
  resetSelected() {
    // 处理选中
    this.checkOptions.forEach((value, index) => {
      value.checked = false;
      this.checkOptions[index] = value;
    });
    this.checkAll = false;
    this.selectCount = 0;
  }

  /**
   * 从列表删除数据
   * @param ids
   */
  private deleteItemFromIds(ids, empty?: boolean) {
    if (empty) {
      this.list = [];
    } else {
      ids.forEach(value => {
        const index = this.list.findIndex(item => item[this.identify] === value);
        this.list.splice(index, 1);
      });
    }
    // 减少文件夹数量
    if (this.folder && this.query.folder) {
      const index = this.folder.findIndex(item => item.id === this.query.folder);
      this.folder[index].count -= ids.length;
    }
    this.generateCheckOptions(this.list);
    if (this.list.length <= 10 && this.dataProvider.totalPages > 1) {
      this.doSearch();
    } else {
      this.list$.next(this.list);
    }
  }

  private generateCheckOptions(list) {
    this.checkOptions = [];
    list.forEach(value => {
      this.checkOptions.push({
        value: value[this.identify],
        checked: false
      });
    });
  }

  private getOperation(params?) {
    if (this.type === 'template') {
      return this.memberService.operation(params);
    } else if (this.type === 'material') {
      return this.memberService.operationMaterial(params);
    }
  }

  get identify(): string {
    if (this.type === 'template' || this.type === 'trash' || this.type === 'favorite' || this.type === 'favoriteDesign') {
      return 'templateId';
    } else if (this.type === 'material') {
      return 'id';
    }
  }

  get sourceList$(): Subject<any> {
    return this._sourceList$;
  }

  get folderList$(): Subject<any> {
    return this._folderList$;
  }

  get list$(): Subject<any[]> {
    return this._list$;
  }

  get folder$(): Subject<FolderMember[]> {
    return this._folder$;
  }

  //用户自定义字体列表
  getFontList(): Observable<Font[]> {
    return this.http.get<Font[]>(this.apiFont);
  }

  //用户上传字体
  uploadFontId(params): Observable<any> {
    return this.http.post(this.apiFont, params);
  }

  //用户删除自定义字体
  delFont(params): Observable<any> {
    return this.http.post(this.apiDelFont, params);
  }
}
