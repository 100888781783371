import { Page } from './page';
import $ from 'jquery'

/**
 * 元素类
 *
 * @export
 * @class Element
 */
export class Element {
  public parent: Page;
  // 元素唯一ID
  public id: any;
  // 元素类型
  public type: string;
  // 通用属性
  public props: any = {
    // 宽高
    width: 0,
    height: 0,
    // 原始宽高
    oWidth: 0,
    oHeight: 0,
    // 旋转
    rotation: 0,
    // 坐标
    x: 0,
    y: 0,
    // 翻转
    flipX: false,
    flipY: false,
    // 不透明度
    opacity: 1,
    // 锁定
    lock: false,
    // 是否能解锁
    enable_change_lock: true,
    // 滤镜
    filter: {},
    // 编组信息
    group: [],
    //专色
    spotColor: null
  };
  // 专有属性
  public options: any = {};
  // 临时属性
  public states: any = {};
  // 附加CSS样式
  public styles;
  // 图层位置
  public index;
  // 配置信息
  public config: any = {
    // 在缩放元素时是否更新元素(例如文本元素就需要缩放时更新)
    update: false,
    // 是否显示元素(例如容器进入裁剪模式后可能需要隐藏原先元素)
    visible: true,
    // 是否可被变形(是否显示自由变形工具)
    transform: true,
    // 自定义CSS样式 (不允许使用transform,left,top,width,height,display)
    styles: {},
    // 自由变形工具角点显示
    corner: {
      tl: true,
      tr: true,
      ml: true,
      mr: true,
      bl: true,
      br: true,
      mt: true,
      mb: true,
      rotate: true,
      quick: true
    },
    // 工具栏组件
    toolbar: null,
    // 多选元素时(都是这个元素的类型)是否显示工具栏
    multiSelectToolBar: true,
    // 是否允许dragable元素放置
    dropAble: false,
    // 是否允许收藏
    markAble: true,
    // 是否允许使用尺寸面板修改尺寸
    resizeFromInput: true,
    // 最小宽度
    minWidth: 0.001,
    minHeight: 0.001,
    menu: [],
  };
  // 事件管理器
  private eventManager = [];
  // 事件ID计数器
  private eventIDCounter = 0;
  constructor(public params) { }
  /**
   * 元素初始化创建完成调用此方法
   */
  public onCreated(): void { }

  /**
   * 获取当前元素的DOM元素
   */
  public getDom(): any {
    return this.states.dom;
  }
  /**
   * 设置元素参数
   * @param params 元素参数
   */
  public set(params): void {
    if (params['id']) {
      this.id = params['id'];
    }
    if (params['type']) {
      this.id = params['type'];
    }

    // 原始宽高如果没有填入,则直接使用传入的宽高
    if (params.props.width && !params.props.oWidth) {
      params.props.oWidth = params.props.width;
    }
    if (params.props.height && !params.props.oHeight) {
      params.props.oHeight = params.props.height;
    }
    if (params.props) {
      this.props = {
        ...this.props,
        ...params.props
      };
    }
    if (params.options) {
      this.options = {
        ...this.options,
        ...params.options
      };
    }
    if (params.config) {
      this.config = {
        ...this.config,
        ...params.config
      };
    }
  }

  calcRotate(forward = true): number | void {
    let angle = this.props.rotation * Math.PI / 180;
    const x0 = this.props.x + this.props.width / 2;
    const y0 = this.props.y + this.props.height / 2;

    if (forward) {
      const x1 = Math.cos(angle) * (-this.props.width / 2) - Math.sin(angle) * (-this.props.height / 2) + x0;
      const x2 = Math.cos(angle) * (this.props.width / 2) - Math.sin(angle) * (-this.props.height / 2) + x0;
      const x3 = Math.cos(angle) * (-this.props.width / 2) - Math.sin(angle) * (this.props.height / 2) + x0;
      const x4 = Math.cos(angle) * (this.props.width / 2) - Math.sin(angle) * (this.props.height / 2) + x0;

      const y1 = Math.sin(angle) * (-this.props.width / 2) + Math.cos(angle) * (-this.props.height / 2) + y0;
      const y2 = Math.sin(angle) * (this.props.width / 2) + Math.cos(angle) * (-this.props.height / 2) + y0;
      const y3 = Math.sin(angle) * (-this.props.width / 2) + Math.cos(angle) * (this.props.height / 2) + y0;
      const y4 = Math.sin(angle) * (this.props.width / 2) + Math.cos(angle) * (this.props.height / 2) + y0;
      this.props.x = Math.min.apply(null, [x1, x2, x3, x4]);
      this.props.y = Math.min.apply(null, [y1, y2, y3, y4]);
      this.props.width = Math.max.apply(null, [x1, x2, x3, x4]) - Math.min.apply(null, [x1, x2, x3, x4]);
      this.props.height = Math.max.apply(null, [y1, y2, y3, y4]) - Math.min.apply(null, [y1, y2, y3, y4]);
    } else {
      const cosA = Math.cos(angle), sinA = Math.sin(angle), cos2A = Math.cos(angle * 2),
        width = this.props.width, height = this.props.height;
      this.props.width = Math.abs((width * cosA - height * sinA) / cos2A);
      this.props.height = Math.abs((height * cosA - width * sinA) / cos2A);
      this.props.x = x0 - this.props.width / 2;
      this.props.y = y0 - this.props.height/ 2;
    }

  }

  changeSignCombination(options) {}
  /**
   * 获取当前元素的html结果
   * @returns {string} 根据元素信息生成的html
   */
  public toHTML(options?): string {
    return '未重写toHTML方法';
  }

  public async toSvg() {
    const $svg = $(this.states.html);
    $svg.css('position', '')
      .attr('x', this.props.x)
      .attr('y', this.props.y)
      .attr('width', this.props.width)
      .attr('height', this.props.height)

    $svg.removeAttr('xmlns')

    return `<g>${$svg[0].outerHTML}</g>`;
  }

  /**
   * 删除当前元素
   * @param fireEvent 是否触发removed事件
   */
  public remove(fireEvent = true): void {
    this.parent.removeElement(this, fireEvent);
  }
  /**
   * 输出可保存对象
   * @param deepClone 是否深度复制
   */
  public toObject(deepClone = false): any {
    const obj = {
      id: this.id,
      type: this.type,
      props: this.props,
      options: this.options
    };
    if (deepClone) {
      return JSON.parse(JSON.stringify(obj));
    } else {
      return obj;
    }
  }

  /**
   * 注册事件侦听器
   * @param event 事件名
   * @param callback 回调函数
   */
  public on(event: string, callback: Function): any {
    const events = event.split(' ');
    if (events.length === 1) {
      this.eventIDCounter++;
      const id = this.eventIDCounter;
      this.eventManager.push({
        id,
        event,
        callback
      });
      return id;
    } else if (events.length > 1) {
      const idArr = [];
      for (const item of events) {
        this.eventIDCounter++;
        const id = this.eventIDCounter;
        this.eventManager.push({
          id,
          event: item,
          callback
        });
        idArr.push(id);
      }
      return idArr;
    }
  }
  /**
   * 销毁事件侦听器
   * @param eventID 注册事件侦听器返回的ID
   */
  off(eventID) {
    if (!eventID) {
      return false;
    }
    if (eventID.length) {
      eventID.forEach(event => {
        const eventIndex = this.eventManager.findIndex(
          item => item.id === event
        );
        if (eventIndex !== -1) {
          this.eventManager.splice(eventIndex, 1);
        } else {
          throw new Error('无法销毁未注册的事件');
        }
      });
    } else {
      const eventIndex = this.eventManager.findIndex(
        item => item.id === eventID
      );
      if (eventIndex !== -1) {
        this.eventManager.splice(eventIndex, 1);
      } else {
        throw new Error('无法销毁未注册的事件');
      }
    }
  }
  /**
   * 触发事件
   * @param event 事件名
   * @param data 触发事件
   */
  public emit(event: string, data: any = {}): void {
    this.eventManager.forEach(item => {
      if (item.event === event) {
        item.callback({
          event,
          ...data
        });
      }
    });
    if (!data.stopPropagation) {
      this.parent.emit('element:' + event, data);
    }
  }
}
