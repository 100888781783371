import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-download-history',
  templateUrl: './download-history.component.html',
  styleUrls: ['./download-history.component.scss']
})
export class DownloadHistoryComponent implements OnInit {
  @Input() list;
  constructor(private modal: NzModalRef) { }

  download() {
    this.modal.close('下载');
  }

  ngOnInit() {
  }

}
