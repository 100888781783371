import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { ModalService } from '@libs/core/src/lib/services/modal.service';

@Component({
  selector: 'app-upgrade-prompt',
  templateUrl: './upgrade-prompt.component.html',
  styleUrls: ['./upgrade-prompt.component.scss']
})
export class UpgradePromptComponent implements OnInit {
  @Input() template: TemplateOfficial;

  constructor(
    @Inject(forwardRef(() => ModalService))
    public modalService,
    private modal: NzModalRef
  ) { }

  ngOnInit() {
  }

  upgrade() {
    this.modalService.buyVipModal('team', 'year');
    this.modal.destroy();
  }

  close() {
    this.modal.destroy();
  }

}
