import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Validator } from '@libs/shared/src/lib/validators/validators';
import { mobileValidator } from '@libs/shared/src/lib/directives/mobile.directive';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { LoginSuccess } from '@libs/core/src/lib/+state/auth.actions';
import { Store } from '@ngrx/store';
import { AuthState } from '@libs/core/src/lib/+state/auth.reducer';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { passwordValidator } from '@libs/shared/src/lib/validators/passwordvalidators';

@Component({
  selector: 'app-new-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.scss']
})
export class NewLoginComponent implements OnInit {
  loginForm: FormGroup;
  forgetForm: FormGroup;

  countryCode: any[] = [];
  @Input() is_coop = 1;
  @Input() is_agent = 0;
  public val: Validator;
  isLoadingSubmit = false;
  isLoadingSubmit1 = false;
  type = 'login';
  count = 0;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private store: Store<AuthState>,
    private message: MessageService
  ) {
    this.authService.getCountryCode().subscribe(data => {
      this.countryCode = data;
    });
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      is_coop: [''],
      is_agent: [''],
      mobile: ['', [Validators.required, mobileValidator()]],
      password: ['', Validators.required]
    });
    this.forgetForm = this.fb.group({
      country_code_id: [1],
      mobile: ['', [Validators.required, mobileValidator()]],
      code: ['', [Validators.required, Validators.minLength(6)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_repeat: ['', [Validators.required]]
    },
    { validator: passwordValidator });
  }
  onSubmit() {
    if (this.loginForm.invalid) return;
    this.isLoadingSubmit = true;
    this.authService.loginByMobile(this.loginForm.value).subscribe(
      res => {
        this.isLoadingSubmit = false;
        if (res.status === 'y') {
          this.store.dispatch(new LoginSuccess({ user: res.user }));
          location.href = res.callback;
        } else {
          this.message.info(res.msg);
        }
      },
      err => {
        this.isLoadingSubmit = false;
        console.error('登录接口出错', err);
      }
    );
  }

  onSubmit1() {
    if (this.forgetForm.invalid) return;
    this.isLoadingSubmit1 = true;
    this.authService.loginForget(this.forgetForm.value).subscribe(
      () => {
        this.message.success('更改成功，请登录');
        this.type = 'login';
      }
    );
  }

  getMobileCode(mobile, type): void {
    this.count = 59;
    const interval$ = setInterval(() => {
      this.count -= 1;
      if (this.count <= 0) clearInterval(interval$);
    }, 1000);
    this.authService.getSms(mobile, type, this.forgetForm.value.country_code_id).subscribe(data => {
    });
  }

  forget(): void {
    this.type = 'forget';
  }

}
