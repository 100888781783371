import { EditorService } from './../../../../../../../services/editor.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-art-font-text-editor',
  templateUrl: './art-font-text-editor.component.html',
  styleUrls: ['./art-font-text-editor.component.scss']
})
export class ArtFontTextEditorComponent implements OnInit {
  text = '';
  el;
  @Input() parentWindow: Window;
  constructor(
    public editor: EditorService
  ) { }

  ngOnInit() {
    const el = this.editor.activeElements[0];
    this.el = el;
    this.text = el.options.text;
  }
  onTextChange(e) {
    this.text = e.target.value;
  }
  onSubmitClick() {
    const el = this.el;
    const oldWidth = el.props.width;
    el.options.text = this.text;

    el.emit('modified', {
      type: 'text',
      target: el,
      oldWidth,
      stopPropagation: this.editor.activeElements.length > 1
    });
    this.parentWindow.close();

  }

}
