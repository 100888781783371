import {
  FormControl,
  FormGroup,
  ValidatorFn,
  AbstractControl
} from '@angular/forms';

export function mobileValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    /*if (control.value === '')*/ return null;
    // 手机号码正则
    /*const mobieReg = /^1[3-9][0-9]\d{8}$/;
    const result = mobieReg.test(control.value);
    return result ? null : { mobileReg: { value: control.value } };*/
  };
}
