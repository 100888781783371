import { Component, OnInit } from '@angular/core';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';

@Component({
  selector: 'app-pattern-scale-tool',
  templateUrl: './pattern-scale-tool.component.html',
  styleUrls: ['./pattern-scale-tool.component.scss']
})
export class PatternScaleToolComponent implements OnInit {

  constructor(
    public editor: EditorService
  ) { }

  ngOnInit() {
  }
  getScale() {
    const eleItem = this.editor.activeElements[0];
    if (!eleItem) return 0;
    return eleItem.options.pattern.scale;
  }
  onInput(event) {
    const eleItem = this.editor.activeElements[0];
    eleItem.options.pattern.scale = event;
    eleItem.states.html = eleItem.toHTML();
  }
  onChange(event) {
    this.editor.snapshot();
  }

}
