import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-size-update-hint',
  templateUrl: './size-update-hint.component.html',
  styleUrls: ['./size-update-hint.component.scss']
})
export class SizeUpdateHintComponent implements OnInit {

  constructor(
    public modalRef: NzModalRef
  ) { }

  ngOnInit() {
  }

}
