import { Inject, Injectable, PLATFORM_ID, isDevMode } from '@angular/core';
import { LoginSuccess, Logout } from '@libs/core/src/lib/+state/auth.actions';
import { Authenticate, User } from '@libs/core/src/lib/models/user';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Store } from '@ngrx/store';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { CodeService } from '@libs/core/src/lib/services/code.service';
import { CookieService } from 'ngx-cookie-service';
import { AppService } from '@libs/core/src/lib/services/app.service';
import { Env } from '@libs/core/src/lib/util/environment';
import { CoopService } from './coop.service';
import * as bowser from 'bowser';
import { ClassifyService } from '@libs/core/src/lib/services/template/classify.service';
import { catchError, map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { of, zip } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StartupService {
  cookie: string;
  constructor(
    public env: Env,
    private coopService: CoopService,
    private cookieService: CookieService,
    private classify: ClassifyService,
    private codeService: CodeService,
    private authService: AuthService,
    private appService: AppService,
    private localStorage: LocalStorage,
    private store: Store<Authenticate>,
    @Inject(PLATFORM_ID) private platformId
  ) {
  }

  load(): Promise<any> {
    // 移动端跳转
    const platform = bowser.getParser(window.navigator.userAgent).getPlatform();
    const code = this.getSearchParam();
    console.log(code);
    // if (platform.type === 'mobile' && ((/\/template\/center|\/template\/list\/|\/spec-list\/|\/share\/shared\//.test(window.location.pathname)) || (window.location.pathname === '/' && window.location.hostname.indexOf('www.tubangzhu') === -1))) {
    //   window.location.href = window.location.href
    //     .replace(/((?:http|https):\/\/)/, "$1m-")
    //     .replace(/template/, "cooperation")
    //     .replace(/center/, "list")
    //     .replace(/spec-list/, "cooperation/diyList")
    //     .replace(/share\/shared/, "cooperation/share")
    //     .replace(/\.html/, "")
    // }

    // return promise;
    return new Promise((resolve) => {
      this.appService.getAppCoopId().pipe(
        tap(data => {
          this.localStorage.setItem('_coop_id', data).subscribe();
          this.env.coop_id = data;
        }),
        switchMap(() => zip(
          this.localStorage.getItem('_token').pipe(
            switchMap((user: User) => {

              //开发模式下不使用cookie验证登录
              if (code.wechatWorkCode) {
                const prefix = Object.keys(code).length === 1 ? '?':'&'
                const url = window.location.href.replace(prefix + 'wechatWorkCode=' + code.wechatWorkCode, '')
                window.history.replaceState(null, '', url)
                return this.authService.wechatWorkCodeLogin(code.wechatWorkCode).pipe(
                  catchError(() => {
                    // this.localStorage.removeItemSubscribe('_token');
                    return of(null);
                  })
                );
              } else if (user && user.accessToken && (isDevMode() || this.env.handle === 'backend')) {
                const payload = JSON.parse(atob(user.accessToken.split('.')[1]));
                const timestamp = Date.parse(new Date().toString());
                if (payload.ref < timestamp / 1000) {
                  this.localStorage.removeItem('_token').subscribe();
                  return of(null);
                }
                // 时期时间小于30分钟 更新
                if ((payload.exp - 1800) < timestamp / 1000) {
                  return this.authService
                    .refreshToken(payload.data.refreshToken)
                    .pipe(
                      catchError(() => {
                        this.localStorage.removeItem('_token').subscribe();
                        return of(null);
                      })
                    );
                } else {
                  // 刷新用户信息
                  return this.authService.updateUserInfo(user)
                    .pipe(
                      map(res => {
                        if (res) {
                          res.accessToken = user.accessToken;
                        }
                        return res;
                      }),
                      catchError(() => of(null))
                    );
                }
              } else if (code.loginCode) {
                const prefix = Object.keys(code).length === 1 ? '?':'&'
                const url = window.location.href.replace(prefix + 'loginCode=' + code.loginCode, '')
                window.history.replaceState(null, '', url)
                return this.authService.codeLogin(code.loginCode).pipe(
                  catchError(() => {
                    // this.localStorage.removeItemSubscribe('_token');
                    return of(null);
                  })
                );
              } else if (this.cookieService.get(this.env.coop_id + '_shanghu_cookie') && this.env.handle === 'frontend') {
                //有当前商户的cookie进行验证登录
                return this.authService.cookieLogin(this.cookieService.get('tubangzhu')).pipe(
                  skipWhile(res => {
                    return location.hostname.match(/www\.(tbz|tubangzhu)/) && res.user.canShow === 0;
                  }),
                  switchMap(res => {
                    if (res.status === 'y') {
                      return this.authService.updateUserInfo(res.user).pipe(
                        map(data => Object.assign(data, res.user))
                      );
                    } else if (code.loginCode) {
                      return this.authService.codeLogin(code.loginCode).pipe(
                        catchError(() => {
                          // this.localStorage.removeItemSubscribe('_token');
                          return of(null);
                        })
                      );
                    }else {
                      return of(null);
                    }
                  }),
                  catchError(() => {
                    if (code.loginCode) {
                      return this.authService.codeLogin(code.loginCode).pipe(
                        catchError(() => {
                          // this.localStorage.removeItemSubscribe('_token');
                          return of(null);
                        })
                      );
                    } else {
                      return of(null);
                    }
                  })
                );
              } else {
                this.authService.isGuest = true;
                return of(null);
              }
            })
          ),
          this.coopService.searchCoopInfo().pipe(
            catchError(() => of(null))
          ),
          this.classify.getTemplateCenterList().pipe(
            catchError(() => of(null))
          )
        ))
      ).subscribe(([user, coopInfo, products]) => {
          if (user) {
            this.authService.user = user;
            this.authService.isGuest = false;
            this.store.dispatch(new LoginSuccess({ user: user }));
          } else {
            this.store.dispatch(new Logout())
          }
          if (products) {
            this.classify.loadProduct(products)
          }
          if(coopInfo){
            this.coopService.load(coopInfo);
          }


          // 定制客户记录 返回链接
          if (code.callbackUrl) {
            sessionStorage.setItem('coop_callback_url', decodeURIComponent(code.callbackUrl));
          }

          //来自哪个项目
          if (code.isFrom) {
              sessionStorage.setItem('coop_from', code.isFrom);
              sessionStorage.setItem('coop_order_Child_id', code.orderChildId);
          }



          if (code.templateTitle) {
            sessionStorage.setItem('coop_template_title', decodeURIComponent(code.templateTitle))
          } else {
            sessionStorage.removeItem('coop_template_title')
          }
        },
        () => {},
        () => {
          resolve(null);
        }
      );
    });
  }

  getSearchParam(): {[a: string]: string} {
    let search = window.location.search, obj = {};
    if (search) {
      search.replace('?', '').split('&').forEach(item => {
        const arr = item.split('=');
        obj[arr[0]] = arr[1];
      });
    }
    return obj;
  }
}
