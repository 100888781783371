import { Component, Input, OnInit } from '@angular/core';
import { FolderMember } from '@libs/core/src/lib/models/folder-member';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { CoopService } from '@libs/core/src/lib/services/coop.service';

@Component({
  selector: 'app-preferential',
  templateUrl: './preferential.component.html',
  styleUrls: ['./preferential.component.scss']
})
export class PreferentialComponent implements OnInit {
  @Input() side: string;
  @Input() type: string;
  @Input() data;
  @Input() folder: FolderMember[];

  constructor(
    private modal: NzModalRef,
    public coop: CoopService
  ) { }

  ngOnInit() {
  }
  edit(type: {action: string, id?: number}): void {
    this.modal.close(type);
  }
}
