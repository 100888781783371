import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-juchen-break',
  templateUrl: './juchen-break.component.html',
  styleUrls: ['./juchen-break.component.css']
})
export class JuchenBreakComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
