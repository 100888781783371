import { EditorService } from '../../../../../services/editor.service';
import { Component, OnInit } from '@angular/core';
import { UserInterfaceService } from '@libs/editor/src/lib/services/ui.service';


@Component({
  selector: 'app-svg-outline',
  templateUrl: './svg-outline.component.html',
  styleUrls: ['./svg-outline.component.scss']
})
export class SvgOutlineComponent implements OnInit {
  private defaultShadowProps = {
    // 距离
    distance: 5,
    // 颜色
    color: '#000',
  };
  constructor(
    public editor: EditorService,
    public ui: UserInterfaceService,
  ) { }

  ngOnInit(): void { }

  getOutline(): any {
    // 修改阴影对象
    if (this.editor.activeElements.length === 1) {
      const outline = this.editor.activeElements[0].states.strockWidth;
      //console.log('onutline',outline)
      return Number(outline)

    } else {
      const ontlineElements = this.editor.activeElements.filter(
        eleItem => eleItem.states.strockWidth
      );
      if (ontlineElements.length === 0) {
        return this.defaultShadowProps;
      } else {
        return ontlineElements[0].states.strockWidth;
      }
    }
  }

  /**
   * 获取当前选中颜色的颜色对象
   */
  getStrokeSvgColors(): any {
    const activeElements = this.editor.activeElements;
    if (!activeElements) {
      return [];
    }
    if(activeElements.length === 0) {
      this.ui.clear();
    }
    const colors = [];
    activeElements.filter(item => item.type === 'svg').forEach(eleItem => {
      // 获取svg的原始颜色
      const resColor = eleItem.states.oStrockColors ? eleItem.states.oStrockColors : [];
      // 将元素的colors属性与colors变量合并
      resColor.forEach(colorItem => {
        const findColor = eleItem.options.colors.find(
          item => item.oColor.toUpperCase() === colorItem.oColor.toUpperCase()
        );
        if (findColor) {
          colorItem.nColor = findColor.nColor.toUpperCase();
        }
      });
      // 剔除重复颜色
      resColor.forEach(colorItem => {
        const findColor = colors.find(item => item.oColor.toUpperCase() === colorItem.oColor.toUpperCase());
        if (!findColor) {
          colors.push(colorItem);
        }
      });
    });

    return colors;
  }

    /**
   * 事件:当颜色改变是
   * @param color 颜色对象
   * @param color 新颜色
   */
  onColorChange(item, color, fireEvent = true): void {
    this.setColor(this.editor.activeElements, item.oColor, color, fireEvent);
  }

  setColor(eleItems, oColor, nColor, fireEvent): void {
    //console.log('eleItems',eleItems)
    // 修改颜色
    eleItems.forEach(eleItem => {
      const findColor = eleItem.options.colors.findIndex(
        colorItem => colorItem.oColor.toLowerCase() === oColor.toLowerCase()
      );
      if (findColor !== -1) {
        // 这个颜色存在于colors属性里
        if (oColor === nColor) {
          // 新修改的颜色和原始颜色一样,从颜色数组内移除
          eleItem.options.colors.splice(findColor, 1);
        } else {

          // 颜色被更新,使用新颜色
          eleItem.options.colors[findColor].nColor = nColor;
        }
      } else {
        // 这个颜色不存在于colors属性里,如果新修改的颜色与原始颜色不一致,则将其添加到colors属性内
        eleItem.options.colors.push({
          oColor,
          nColor
        });
      }
      if (fireEvent === false) {
        // 如果不触发modified事件,则手动更新HTML
        eleItem.states.html = eleItem.toHTML();
      }
    });
    // 触发更改事件
    if (fireEvent) {
      eleItems.forEach(eleItem => {
        if (eleItems.length === 1) {
          eleItem.emit('modified', {
            type: 'colorChange',
            target: eleItem
          });
        } else if (eleItems.length > 1) {
          eleItem.emit('modified', {
            type: 'colorChange',
            target: eleItem,
            targets: eleItems,
            stopPropagation: true
          });
        }
      });
      if (eleItems.length > 1) {
        eleItems[0].parent.emit('element:modified', {
          type: 'colorChange',
          targets: eleItems
        });
      }
    }
  }

  /**
   * 修改描边的值
   * @param name 参数名
   * @param value 值
   * @param fireEvent 触发事件
   */
  setShadowProps(value, fireEvent = true): void {
    //console.log('value',value)
    const activeElements = this.editor.activeElements;

    activeElements.forEach(eleItem => {
      if (!eleItem.states.strockWidth) {
        // 没有使用,传入默认参数
        eleItem.states.strockWidth = '';
      }
      eleItem.states.strockWidth = value;
      if (fireEvent === true) { 
        if (activeElements.length === 1) {
          eleItem.emit('modified', {
            type: 'colorChange',
            target: eleItem
          });
        } else if (activeElements.length > 1) {
          eleItem.emit('modified', {
            type: 'colorChange',
            target: eleItem,
            targets: activeElements,
            stopPropagation: true
          });
        }
      } else {
        if (fireEvent === false) {
          // 如果不触发modified事件,则手动更新HTML
          eleItem.states.html = eleItem.toHTML('outline');
        }
      }
    });
    if (activeElements.length > 1) {
      if (fireEvent === true) {
        activeElements[0].parent.emit('element:modified', {
          type: 'colorChange',
          targets: activeElements
        });
      }
    }
  }
}
