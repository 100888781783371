export class MenuItem {
  public title = '';
  public children = [];
  public fn;
  public checked = false;
  public disable = false;
  constructor(public params) {
    for (const item of Object.keys(params)) {
      this[item] = params[item];
    }
  }
}
