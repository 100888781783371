import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { DesignParamService } from '@libs/core/src/lib/services/design-param.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { TemplateDetailService } from '@libs/shared/src/lib/components/template-detail/template-detail.service';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { CodeService } from '@libs/core/src/lib/services/code.service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { ClassifyConfig, ClassifyLevel } from '@libs/core/src/lib/models/classify';
import { ClassifyService } from '@libs/core/src/lib/services/template/classify.service';
import { RouterUrlService } from '@libs/core/src/lib/services/router-url.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-template-item',
  templateUrl: './template-item.component.html',
  styleUrls: ['./template-item.component.scss']
})
export class TemplateItemComponent implements OnInit, AfterViewInit {
  @Input() template: TemplateOfficial;
  @Input() thumbnailData;
  @Input() current: ClassifyLevel;
  @Input() favorite = 'normal';
  @Input() index;
  @Output() delete = new EventEmitter<{status: string, template: TemplateOfficial, index: number}>();

  @Input() query: any = {};

  cid;
  favoriteSize: ClassifyConfig[];
  virtual: ClassifyConfig;
  child_page: string;
  @ViewChild('tempName') tempName: ElementRef;
  showAllName = false;

  sizeMsgStr: string;
  isTest = false;

  constructor(
    public routerUrlService: RouterUrlService,
    public coop: CoopService,
    public classify: ClassifyService,
    public designService: DesignParamService,
    public authService: AuthService,
    private routeInfo: ActivatedRoute,
    private modal: ModalService,
    private templateService: TemplateDetailService,
    private message: NzMessageService,
    private code: CodeService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    if (this.route.snapshot.routeConfig.path === 'test-list/:query') {
      this.isTest = true;
    } else {
      this.isTest = false;
    }
    const product = this.classify.products.find(item => item.classify.classifyId == this.template.classifyPid.toString());
    if (!product) return;
    this.child_page = this.routerUrlService.params.get('cp');
    if (this.favorite == 'choice') {
      this.favoriteSize = product.classifyConfig.filter(item => item.classifyId == this.template.classifyId);
      this.virtual = {
        ...this.virtual,
        name: product.classify.name
      };
    } else {
      this.cid = this.routerUrlService.params.get('cid') || this.query.cid;
      if (this.cid) {
        this.virtual = product.classifyConfig.find(item => item.id == this.cid);
      } else {
        const virtual = product.classifyConfig.find(item => this.activeState(item));
        if (virtual) this.virtual = virtual
      }
    }

    this.sizeMsgStr = this.sizeMsg;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.showAllName = this.tempName ? this.tempName.nativeElement.offsetWidth < this.tempName.nativeElement.scrollWidth : false;
    }, 0);
  }

  favoriteTemplate(template: TemplateOfficial, event): void {
    event.preventDefault();
    event.stopPropagation();
    if (template.isFavorite || this.favorite == 'choice') {
      // 设置为已收藏
      template.isFavorite = 0;
      this.templateService.unFavTemplate(template.templateId)
        .subscribe(
          () => {
            this.authService.updateUser({...this.authService.user, favoriteCount: this.authService.user.favoriteCount - 1});
            if (this.favorite == 'choice') {
              this.delete.emit({status: 'remove', template, index: this.index});
            }
            this.message.success('已取消收藏!');
          },
          err => {
            template.isFavorite = 1;
            this.message.error(this.code.getOne(err));
          }
        );
    } else {
      // 设置为已收藏
      template.isFavorite = 1;
      this.templateService.favTemplate(template.templateId)
        .subscribe(
          () => {
            this.authService.updateUser({
              ...this.authService.user,
              favoriteCount: this.authService.user.favoriteCount + 1
            });
            this.message.success('模版收藏成功!');
          },
          err => {
            template.isFavorite = 0;
            this.message.error(this.code.getOne(err));
          }
        );
    }
  }

  /*edit() {
    if (this.template.classify.pid === 2) {
      this.modal.cardMessage(true, this.template.templateId);
    } else {
      window.open(this.designService.getMemberCreateUrl(this.template.templateId, this.template));
    }
  }*/

  choiceToDesign(event) {
    event.stopPropagation();
    if (this.isTest) return;
    if(this.authService.isGuest) {
      this.modal.loginModal();
    } else {
      if (this.favorite == 'choice' && this.favoriteSize && this.favoriteSize.length) {
        if (this.favoriteSize.length > 1 || this.favoriteSize[0].page.length > 1) {
          this.modal.choiceSize(this.favoriteSize).afterClose.subscribe(data => {
            if (data) {
              this.toDesign(data.size, data.page)
            }
          })
        } else {
          this.toDesign(this.favoriteSize[0])
        }
      } else {
        this.toDesign();
      }
    }
  }

  toDesign(size?: ClassifyConfig, page?) {
    if (this.isTest) return;
    const data = size || this.virtual;
    const query = { ...this.query };
    if (this.child_page) query.page = this.child_page;
    if (page) query.page = page;
    if (query.page === "") delete query.page

    if (
      this.authService.isGuest &&
      !this.authService.loginTip &&
      this.coop.coopInfo.coop_agent_id != 5 // 非恒盛的
    ) {
      this.authService.loginTip = true;
      this.modal.diyConfirm({
        title: '您当前未登录!',
        content: '未登录上传的图片等文件将不会被存储，登录后您的文件将永久保存至您的账号。',
        buttons: [{
          text: '立即登录',
          style: {
            backgroundColor: this.coop.coopInfo.color,
            border: 0,
            color: '#fff',
            width: '150px'
          },
          fun: () => {
            this.modal.loginModal();
          }
        }, {
          text: '跳过，继续编辑',
          style: {
            color: this.coop.coopInfo.color,
            border: `1px solid ${this.coop.coopInfo.color}`,
            width: '150px'
          },
          fun: () => {
            window.open(this.designService.getWulfulUrl(
              this.template.templateId, this.template,
              data ? data.id : null, query
            ));
          }
        }]
      });
    } else {
      window.open(this.designService.getWulfulUrl(
        this.template.templateId, this.template,
        data ? data.id : null, query
      ));
    }
  }

  activeState(temp: ClassifyConfig): boolean {
    if (this.template.classifyId != temp.classifyId) {
      return false;
    }
    const judge = this.classify.no_required_query.every(val => {
      if (!this.query[val]) return true;
      return temp[val] == this.query[val]
    });

    return judge
  }

  preview(template: TemplateOfficial): void {
    if (this.router.url.indexOf('/test-list') === 0) {
      this.modal.preview(template, false, this.cid, this.virtual);
    } else {
      this.modal.preview(template, true, this.cid, this.virtual);
    }
  }

  get sizeMsg() {
    let width, height;
    if (this.virtual && this.virtual.expand === 0) {
      width = this.virtual.width;
      height = this.virtual.height;
    } else {
      width = this.template.sizeInfo.width;
      height = this.template.sizeInfo.height
    }
    if ([1, 3].indexOf(this.template.likePid || this.template.classifyPid) >= 0) {
      width /= 2;
    }
    if (this.template.classifyId == 144) {
      width /= 2;
    }

    return `${width}*${height}mm`
  }

  get showNew() {
    const days = parseInt(this.coop.coopInfo.new_days);
    const timeArr = this.template.onlineAt.split('-').map(val => parseInt(val));
    const onlineTime = new Date(timeArr[0], timeArr[1] - 1, timeArr[2]).getTime();
    const now = new Date().getTime();
    return now - onlineTime < days * 86400000;
  }

  get pageNum() {
    if (this.query && this.query.page) {
      return parseInt(this.query.page);
    } else if (this.virtual && this.virtual.expand === 0 && this.virtual.page.length) {
      if (this.virtual.page.length > 1 && this.child_page)
      return parseInt(this.child_page);
      else return parseInt(this.virtual.page[0])
    } else {
      return parseInt(this.template.pageNum);
    }
  }

  get showPhotoCount() {
    return (
      this.virtual &&
      this.virtual.expand == 1 &&
      this.template.extendJson &&
      this.template.extendJson.container_counts &&
      (!this.query.page/* || parseInt(this.query.page) / 2 === this.template.pages.length*/)
    );
  }
  templateDetail(template: TemplateOfficial): void {
    let newWindow = window.open();
    newWindow.location.href = '/template/detail/' + template.templateId;
    newWindow.onload = function() {
      newWindow.scrollTo(0, 0);
    };
  }

  templateEditor(template: TemplateOfficial): void {
    let newWindow = window.open();
    let url = this.designService.getMemberCreateUrl(template.templateId, template);
    newWindow.location.href = url;
    newWindow.onload = function() {
      newWindow.scrollTo(0, 0);
    };
  }
}
