import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

export class Validator {
  mobileValidator(control: FormControl): any {
    const myReg = /^1(3|4|5|7|8)+\d{9}$/;
    const valid = myReg.test(control.value);
    console.log('moblie的校验结果是' + valid);
    return valid ? null : { mobile: true }; //如果valid是true 返回是null
  }
}
