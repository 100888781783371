export class ToastIcon {
  /**
   * 消息提示图标
   */
  static INFO = 'info';
  /**
   * 警告图标
   */
  static WARN = 'warn';
  /**
   * 错误图标
   */
  static ERROR = 'error';
  /**
   * 加载状态图标
   */
  static LOADING = 'loading';
  /**
   * 成功状态图标
   */
  static SUCCESS = 'success';
}
