import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate, CanActivateChild, Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { Store } from '@ngrx/store';
import { Login } from '@libs/core/src/lib/+state/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class CoopLoginGuard implements CanActivate, CanActivateChild {
  constructor(
    private route: Router,
    private coop: CoopService,
    private auth: AuthService,
    private store: Store<any>
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return true;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.canActivate(next, state);
  }

}
