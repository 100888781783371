
export class Env {
  production: boolean;
  baseUrl: string;
  coop_id: string;
  version: string;
  clientId: string;
  handle: string;
  thirdParty: string;
  my_photo_id?: string;
  xiangdehao_id?: string;
  meishubao_id?: string;
  env?: string;
}
