import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from '../../../../../../core/src/lib/services/message.service';

@Component({
  selector: 'app-wechat-pay-qrcode',
  templateUrl: './wechat-pay-qrcode.component.html',
  styleUrls: ['./wechat-pay-qrcode.component.scss']
})
export class WechatPayQrcodeComponent implements OnInit {
  @Input() wechatUrl: string;
  @Input() title = '微信扫描完成支付';

  constructor(
    private message: MessageService
  ) {}

  ngOnInit() {
    if (!this.wechatUrl)
      this.message.error('亲，联系管理员开通微信支付');
  }
}
