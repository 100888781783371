import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { MetaGuard } from '@ngx-meta/core';
import { NotFoundPageComponent } from '@libs/core/src/lib/components/not-found-page/not-found-page.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { SpecListComponent } from './spec-list/spec-list.component';
import { CoopLoginGuard } from '@libs/core/src/lib/guard/coop-login.guard';
import { PageLoginComponent } from './page-login/page-login.component';
import { MamaOrderComponent } from './mama-order/mama-order.component';
import { AddCardComponent } from './add-card/add-card.component';
import { JuchenBreakComponent } from './juchen-break/juchen-break.component';
import { LinklistComponent } from './linklist/linklist.component';
import { VariableExcelComponent } from './variable-excel/variable-excel.component';
import { TestListComponent } from './test-list/test-list.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./coop/coop.module').then(m => m.CoopModule),
        pathMatch: 'full'
      },
      {
        path: 'index',
        loadChildren: () => import('./coop/coop.module').then(m => m.CoopModule)
      },
      {
        path: 'template',
        loadChildren: () => import('./template/template.module').then(m => m.TemplateModule),
      },
      { path: 'spec-list/:query', component: SpecListComponent },
      { path: 'test-list/:query', component: TestListComponent },
      { path: 'linklist', component: LinklistComponent },
      { path: 'shareExcel/:code', component: VariableExcelComponent },
      { path: 'mama-iframe/:type', component: MamaOrderComponent },
      { path: 'help', loadChildren: () => import('./help-center/help-center.module').then(m => m.HelpCenterModule) },
      { path: 'member', loadChildren: () => import('./member/member.module').then(m => m.MemberModule) },
      { path: 'share', loadChildren: () => import('./share/share.module').then(m => m.ShareModule) },
      { path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },
      { path: 'design', loadChildren: () => import('@libs/editor/src/lib/editor.module').then(m => m.EditorModule) },
      { path: 'design-for-you', loadChildren: () => import('@libs/editor-for-you/src/lib/editor.module').then(m => m.EditorModule) },
      { path: 'design-mama', loadChildren: () => import('@libs/editor-mama/src/lib/editor-mama.module').then(m => m.EditorMamaModule) },
      { path: 'third', loadChildren: () => import('./third/third.module').then(m => m.ThirdModule) },
      { path: 'aboutus', component: AboutusComponent },
      { path: 'add-card', component: AddCardComponent },
      { path: 'break', component: JuchenBreakComponent }
    ],
    canActivate: [CoopLoginGuard, MetaGuard],
    canActivateChild: [CoopLoginGuard, MetaGuard]
  },
  { path: 'login', component: PageLoginComponent },
  { path: '**', redirectTo: '/404.html' },
  { path: '404.html', component: NotFoundPageComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
