import { Element } from '../base/element';
import Area from './chart/area';
import Bar from './chart/bar';
import Pie from './chart/pie';
import Multipie from './chart/multiPie';
import Groupbar from './chart/groupBar';
/**
 * 图表元素类
 */
export class Chart extends Element {
  public type = 'chart';
  /**
   * 重写:获取当前元素的html结果
   * @returns {string} 根据元素信息生成的html
   */
  public toHTML(): string {
    switch (this.options.type) {
      case 'area': {
        return new Area(this).getHtml();
      }
      case 'bar': {
        return new Bar(this).getHtml();
      }
      case 'pie': {
        return new Pie(this).getHtml();
      }
      case 'multiPie': {
        return new Multipie(this).getHtml();
      }
      case 'groupBar': {
        return new Groupbar(this).getHtml();
      }
    }
  }
}
