import {
  AfterViewChecked,
  Component, ElementRef, HostListener,
  OnInit
} from '@angular/core';
import { NgProgress } from 'ngx-progressbar';
import { Env } from '@libs/core/src/lib/util/environment';

@Component({
  selector: 'app-root',
  template: `
    <ng-progress [color]="'#28649a'"></ng-progress>
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit {

  constructor(public progress: NgProgress, public ef: ElementRef, public env: Env) {
  }


  ngOnInit(): void {
    // this.progress.setConfig({ color: 'green' });
  }
}
