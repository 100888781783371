import { UserInterfaceService } from '../../services/ui.service';

export class UserInterfaceItem {
  // 唯一ID
  public id;
  // UI类型
  public type;
  // 使用的组件
  public component;
  // 属性
  public params: any = {};
  // 临时状态
  public states: any = {};
  // 父级(user-interface-service服务)
  public parent;
  // 事件管理器
  private eventManager = [];
  private eventIDCounter = 0;
  constructor(com, params = null) {
    this.component = com;
    if (params) {
      this.params = params;
    }
  }
  /**
   *
   * 注册事件侦听器
   * @param {string} event
   * @param {Function} callback
   * @returns {any}
   */
  public on(event: string, callback: Function): any {
    const events = event.split(' ');
    if (events.length === 1) {
      this.eventIDCounter++;
      const id = this.eventIDCounter;
      this.eventManager.push({
        id,
        event,
        callback
      });
      return id;
    } else if (events.length > 1) {
      const idArr = [];
      for (const item of events) {
        this.eventIDCounter++;
        const id = this.eventIDCounter;
        this.eventManager.push({
          id,
          event: item,
          callback
        });
        idArr.push(id);
      }
      return idArr;
    }
  }
  /**
   * 销毁事件侦听器
   * @param eventID 注册事件侦听器返回的ID
   */
  public off(eventID): any {
    if (eventID.length) {
      eventID.forEach(event => {
        const eventIndex = this.eventManager.findIndex(
          item => item.id === event
        );
        if (eventIndex !== -1) {
          this.eventManager.splice(eventIndex, 1);
        } else {
          throw new Error('无法销毁未注册的事件');
        }
      });
    } else {
      const eventIndex = this.eventManager.findIndex(
        item => item.id === eventID
      );
      if (eventIndex !== -1) {
        this.eventManager.splice(eventIndex, 1);
      } else {
        throw new Error('无法销毁未注册的事件');
      }
    }
  }
  /**
   * 触发事件
   * @param event 事件名
   * @param data 触发事件
   */
  public emit(event: string, data: any = {}): void {
    this.eventManager.forEach(item => {
      if (item.event === event) {
        item.callback({
          event,
          ...data
        });
      }
    });
  }
}
