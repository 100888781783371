import {
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef,
  Input,
  QueryList,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { NavigationEnd, Router, RouterEvent, RouterLink, RouterLinkWithHref } from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[routerLinkActiveStyle]'
})
export class RouterLinkActiveStyleDirective {
  @ContentChildren(RouterLink, { descendants: true })
  links: QueryList<RouterLink>;
  @ContentChildren(RouterLinkWithHref, { descendants: true })
  linksWithHrefs: QueryList<RouterLinkWithHref>;
  // 保存激活样式数组
  private classes: string[] = [];
  // 保存激活style
  private styles: { [key: string]: any };

  private subscription: Subscription;
  public readonly isActive: boolean = false;

  @Input() routerLinkActiveOptions: { exact: boolean } = { exact: false };

  constructor(
    private router: Router,
    private element: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {
    this.subscription = router.events.subscribe((s: RouterEvent) => {
      if (s instanceof NavigationEnd) {
        this.update();
      }
    });
  }

  ngAfterContentInit(): void {
    this.links.changes.subscribe(_ => this.update());
    this.linksWithHrefs.changes.subscribe(_ => this.update());
    this.update();
  }

  // 设置样式激活数组
  @Input()
  set routerLinkActiveClass(data: string[] | string) {
    const classes = Array.isArray(data) ? data : data.split(' ');
    this.classes = classes.filter(c => !!c);
  }
  // 设置激活style
  @Input()
  set routerLinkActiveStyle(data: { [key: string]: any }) {
    this.styles = data;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private update(): void {
    if (!this.links || !this.linksWithHrefs || !this.router.navigated) return;
    Promise.resolve().then(() => {
      const hasActiveLinks = this.hasActiveLinks();
      if (this.isActive !== hasActiveLinks) {
        (this as any).isActive = hasActiveLinks;
        if (hasActiveLinks) {
          Object.keys(this.styles).map(res => {
            this.renderer.setStyle(
              this.element.nativeElement,
              res,
              this.styles[res]
            );
          });
        } else {
          Object.keys(this.styles).map(res => {
            this.renderer.removeStyle(this.element.nativeElement, res);
          });
        }
        this.classes.forEach(c => {
          // 生效
          if (hasActiveLinks) {
            this.renderer.addClass(this.element.nativeElement, c);
          } else {
            this.renderer.removeClass(this.element.nativeElement, c);
          }
        });
      }
    });
  }

  private isLinkActive(
    router: Router
  ): (link: RouterLink | RouterLinkWithHref) => boolean {
    return (link: RouterLink | RouterLinkWithHref) =>
      router.isActive(link.urlTree, this.routerLinkActiveOptions.exact);
  }

  private hasActiveLinks(): boolean {
    return (
      this.links.some(this.isLinkActive(this.router)) ||
      this.linksWithHrefs.some(this.isLinkActive(this.router))
    );
  }
}
