import { Component, forwardRef, ElementRef,Inject, OnDestroy, OnInit,Input,ViewChild } from '@angular/core';
import { AuthState, selectAuthState } from '@libs/core/src/lib/+state/auth.reducer';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { Store } from '@ngrx/store';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { LoginSuccess } from '@libs/core/src/lib/+state/auth.actions';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { CodeService } from '@libs/core/src/lib/services/code.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Validator } from '../../../../../../../shared/src/lib/validators/validators';
import { mobileValidator } from '../../../../../../../shared/src/lib/directives/mobile.directive';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';
import { Env } from '@libs/core/src/lib/util/environment';
import { passwordValidator } from '@libs/shared/src/lib/validators/passwordvalidators';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { DesignerService } from '@libs/core/src/lib/services/post-order/designer.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { map, switchMap, take, tap  } from 'rxjs/operators';
import { of,Subject,Subscription,timer,} from 'rxjs';
import { Login } from '@libs/core/src/lib/+state/auth.actions';
import { FolderMember } from '@libs/core/src/lib/models/folder-member';
import { MemberService } from '@libs/core/src/lib/services/member/member.service';
import { UploadService } from '@libs/core/src/lib/services/upload.service';
import { ModalService as CoreModal } from '@libs/core/src/lib/services/modal.service';
import { ToastIcon } from '@libs/modal/src/lib/models/toast-icon';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { MessageSendService } from '@libs/core/src/lib/services/messageSend.service';



import * as $ from 'jquery';
import { stringify } from 'querystring';
declare const wx: any;
@AutoUnsubscribe()
@Component({
  selector: 'app-upload-files',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadMoadlComponent implements OnInit, OnDestroy {
  @Input() currentFolder;
  public val: Validator; //定义一个validators类型的变量 val
  // 是否电脑上传type
  isComputerUpload= true;
  url;
  folders: FolderMember[];
  //currentFolder: FolderMember;
  socket: Subscription;
  list$ = new Subject();
  //临时文件夹
  public temporaryFolder = false;
  //是否正在上传
  public isUpload = false;
  //已上传数量
  public uploadNum = 0;
  //上传文件总数量
  public uploadSumNum = 0;
  fileList: any = [];
  temporaryName = 'tbz_temporary_file';
  @ViewChild('uploadInput') uploadInput: ElementRef;
  imageList = [];
  constructor(
    private env: Env,
    public coop: CoopService,
    private authService: AuthService,
    private store: Store<AuthState>,
    private notification: NzNotificationService,
    private modal: ModalService,
    @Inject(forwardRef(() => ModalService))
    private modalService,
    private message: MessageService,
    private code: CodeService,
    private fb: FormBuilder,
    private designer: DesignerService,
    private cookie: CookieService,
    private router: Router,
    public http: HttpClient,
    public auth: AuthService,
    private upload: UploadService,
    private local: LocalStorage,
    private MessageSend: MessageSendService,
    private modalS: CoreModal,
    //public member: MemberService,
  ) {
    this.val = new Validator();
  }

  ngOnInit() {
  }
  
  uploadMethod(event){
    console.log('sss')
    event.stopPropagation();
    this.uploadInput.nativeElement.click();
  }
  changeType(){
    this.isComputerUpload = !this.isComputerUpload;
    if(!this.isComputerUpload){
      if (this.auth.isGuest) {
        this.auth.afterLoginMethod = this.loginStateFile.bind(this);
        this.store.dispatch(new Login());
        return;
      }
      this.http
        .post<string>('/material/mobile-upload', {
          target: 'material',
          folder: this.currentFolder.id
        })
        .subscribe(url => {
          this.url = url;
          this.mobileAddListener();
        });
    }
  }
  loginStateFile() {
    // this.member.getFolderMaterial().subscribe(data => {
    //   this.folders = data;
    //   //this.setFolder(this.folders[0]);
    // });
  }


  mobileAddListener() {
    if ((this.socket && !this.socket.closed) || this.temporaryFolder)
      return false;

    this.socket = timer(3000, 3000)
      .pipe(take(600))
      .subscribe(data => {
        this.list$.next(this.currentFolder.id);
      });
  }

  /**
   * 事件:文件上传
   * @param event e
   * @param  上传使用传过来的file
   */
  onFileChange(event) {
    let files = this.uploadInput.nativeElement.files;
    if (files.length > 0) {
      for (const fileItem of files) {
        // 判断类型
        if (
          ['jpg', 'png', 'svg+xml', 'jpeg'].filter(
            item => 'image/' + item === fileItem.type
          ).length === 0
        ) {
          this.uploadInput.nativeElement.value = '';
          alert('仅支持上传图片文件');
          return;
        } else if (fileItem.size > 20 * 1024 * 1024) {
          this.uploadInput.nativeElement.value = '';
          // 尺寸判断
          alert(
            '文件最大尺寸为20M，请压缩后上传，推荐压缩文件网址：                 https://www.bejson.com/ui/compress_img/'
          );
          return;
        }
      }
      this.uploadNum = 0;
      this.uploadSumNum = files.length;
      let uploadCount = files.length;
      let count = 0;
      let fileList = [];
      this.isUpload = true;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const loadingToast = this.modal.toast({
          icon: ToastIcon.LOADING,
          msg: '正在上传',
          duration: 0
        });
        let methods = 'material';
        if (this.temporaryFolder) methods = 'normal';
        this.upload.upload(
          {
            file,
            onSuccess: res => {
              loadingToast.close();
              this.uploadNum++;
              uploadCount--;
              count++;
              if (this.auth.isGuest) {
                fileList.push({ ...res, fileName: file.name });
              }
              if (uploadCount == 0) {
                this.uploadSumNum = 0;
                this.uploadNum = 0;
                this.isUpload = false;
                this.modal.toast({
                  icon: ToastIcon.SUCCESS,
                  msg: '上传成功'
                });
                this.MessageSend.sendMessage({
                  messsage:'上传成功',
                  count:count,
                  fileList:fileList
                });
                this.modalS.closeAll();
              }
      
            },
            onFail: e => {
              //loadingToast.close();
              uploadCount--;
              this.modal.toast({
                icon: ToastIcon.ERROR,
                msg: e.error.message
              });
              this.isUpload = false;
            }
          },
          this.currentFolder.id,
          'frontend',
          methods
        );
      }
    }
    this.uploadInput.nativeElement.value = '';
  }

  operatorTemporary(method: string, data?) {
    if (method == 'add') {
      this.fileList.unshift(data);
      this.local.setItem(this.temporaryName, this.fileList).subscribe();
    } else if (method == 'remove') {
      this.fileList = this.fileList.filter(item =>
        data.every(v => item.id != v)
      );
      this.local.setItem(this.temporaryName, this.fileList).subscribe();
    }
    //this.imageList = this.getImageList
  }


  ngOnDestroy(): void { 

  }

}
