import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { Subscription } from 'rxjs';
import { FileCommon } from '@libs/core/src/lib/models/file-common';
import { Store } from '@ngrx/store';
import * as OSS from 'ali-oss';
import {Md5} from "ts-md5";
import { DatePipe } from '@angular/common';
import { MessageService } from './message.service';
import { switchMap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import { of, throwError } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  client;
  ossOption: any;
  // 签名数据
  signData: any;
  signatureUrl = '/system/oss-policy';
  // 文件信息
  fileData: any;
  loading: boolean;
  public uploadFileKey = [];

  constructor(
    private date: DatePipe,
    private http: HttpClient,
    private authService: AuthService,
    private notification: NzNotificationService,
    private store: Store<any>,
    private message: MessageService
  ) {
    // 获取OSS 签名信息
    this.http
      .get(this.signatureUrl)
      .subscribe(
        data => {
          this.signData = data;
          this.client = new OSS({
            region: this.signData.region,
            accessKeyId: this.signData.accessKeyIdUploads,
            accessKeySecret: this.signData.accessKeySecretUploads,
            bucket: this.signData.bucket,
            useFetch: false
          });
          const callback = JSON.parse(atob(this.signData.callback));
          this.ossOption = {
            partSize: 5242880,
            callback: {
              url: callback.callbackUrl,
              body: callback.callbackBody,
              contentType: callback.callbackBodyType,
              customValue: {
                folder_id: '0',
                handle: 'frontend',
                method: 'font'
              }
            }
          };
        },
        err => this.notification.error('请刷新页面重试', '')
      );
  }


  /**
   * 上传后台文件
   * @param item
   * @returns {Subscription}
   */
  uploadBackend(item: any) {
    return this.upload(item, 0, 'backend');
  }

  uploadBackendNormal(item: any) {
    return this.upload(item, 0, 'backend', 'normal');
  }

  uploadBackendOrderMustSee(item: any) {
    return this.uploadMultipart(item, '0', 'backend', 'order_must_see');
  }

  uploadBackendVideo(item: any) {
    return this.uploadMultipart(item, '0', 'backend', 'video');
  }

  /**
   * 上传字体
   * @param item
   * @param {string} handle
   * @returns {Subscription}
   */
  uploadFont(item: any, handle = 'frontend') {
    return this.upload(item, 0, handle, 'font');
  }

  uploadBackendFont(item: any) {
    return this.upload(item, 0, 'backend', 'font');
  }

  uploadMyFont(item: any): Subscription {
    return this.uploadMultipart(item, '0', 'frontend', 'font', 30);
  }

  uploadBack(item: any) {
    return this.upload(item, 0, 'frontend', 'back', 20);
  }

  uploadTemp(item: any) {
    return this.upload(item, 0, 'frontend', 'normal', 20);
  }
  //上传我的菜图
  uploadMyMenuImg(item: any, folder, method = 'menu') {
    return this.upload(item, folder, 'frontend', method, 20);
  }
  uploadPSD(item: any): Subscription {
    return this.uploadMultipart(item, '0', 'frontend', 'editor_upload_psd');
  }

  upload(item: any, folder = 0, handle = 'frontend', method = 'material', limit = 300, order_id = '', demand_id = '', typeName = [], callback?):any{
    const file = item.file;
    //判断文件格式
    if (method === 'material' || method === 'normal') {
      let rules = ['svg', 'jpg', 'jpeg', 'png','psd'];
      if (handle === 'backend') {
        rules.push('gif');
        rules.push('json');
      }
      if (rules.indexOf(file.name.split('.').pop().toLowerCase()) < 0) {
        this.notification.error('文件格式错误', '');
        if (item.onFail) item.onFail({});
        return;
      }
    }
    if (method.includes('order')) {
      if (typeName.length > 0 && typeName.indexOf(file.name.split('.').pop().toLowerCase()) < 0) {
        // this.notification.error('文件格式错误', '');
        this.message.error('文件格式错误');
        callback();
        if (item.onFail) item.onFail({});
        return;
      }
    }
    if (file.size > limit * 1024 * 1024) {
      if (method.includes('order')) {
        this.message.error('文件超过'+ limit +'M,无法上传');
        callback();
      } else {
        this.notification.error('文件超过'+ limit +'M,无法上传', '');
      }
      if (item.onFail) item.onFail({});
      return;
    }
    this.loading = true;
    const formData = new FormData();
    //formData.append('name', file.name);
    let key = this.signData.dir + this.randomString() + '_' + file.name.replace(/%/g, '')
    formData.append('key',key);
    // formData.append('policy', this.signData.policy);
    // formData.append('OSSAccessKeyId', this.signData.accessid);
    // formData.append('signature', this.signData.signature);
    // formData.append('success_action_status', '200');
    // formData.append('callback', this.signData.callback);
    
    // // 用户文件夹
    // formData.append('x:folder_id', folder.toString());
    // formData.append('x:handle', handle);
    // formData.append('x:method', method);
    // formData.append('x:order_id', order_id);
    formData.append('acl', 'public-read');
    formData.append('bucket', this.signData.bucket);
    formData.append('policy', this.signData.policy);
    formData.append('X-Amz-Credential', this.signData.credential);
    formData.append('X-Amz-Date', this.signData.amzDate);
    formData.append('X-Amz-Signature', this.signData.signature);
    formData.append('x-amz-algorithm', 'AWS4-HMAC-SHA256');
    formData.append('file', file, file.name);
    // 自定义参数
    // if (!this.authService.isGuest) {
    //   formData.append('x:user_id', this.authService.user.id.toString());
    // }
    let imagParam = {} as any;
    imagParam.filename = key;
    imagParam.etag = '';
    imagParam.size = '';
    imagParam.mimeType = '';
    imagParam.height = '';
    imagParam.width ='' ;
    imagParam.format = '';
    //imagParam.user_id = this.authService.user.id.toString();
    imagParam.user_id = '2363194';
    imagParam.folder_id =  folder.toString();
    imagParam.team = '';
    imagParam.handle = handle;
    imagParam.method = method;
    imagParam.order_id = order_id;
    imagParam.demand_id = '';
    return this.http.post<any>(this.signData.host + '/' + this.signData.bucket , formData,{ observe: 'response' })
      .pipe(
        switchMap(data => this.uploadImage(data,imagParam))
      )
      .subscribe(
        data => {
          item.onSuccess(data);
          this.loading = false;
          this.fileData = data;
        },
        err => {
          if (method.includes('order') && method !== 'order_must_see') {
            this.message.error('上传失败');
            callback();
          } else {
            this.notification.error('上传失败', '');
          }
          if (item.onFail) item.onFail(err);
        }
      );
  }

  uploadDesigner(item: any, folder = 0, handle = 'frontend', method = 'material', limit = 300, order_id = '', demand_id = '', typeName = [], callback?):any{
    const file = item.file;
    //判断文件格式
    if (method === 'material' || method === 'normal') {
      let rules = ['psd','svg'];
      if (rules.indexOf(file.name.split('.').pop().toLowerCase()) < 0) {
        this.notification.error('文件格式错误', '');
        if (item.onFail) item.onFail({});
        return;
      }
    }
    if (method.includes('order')) {
      if (typeName.length > 0 && typeName.indexOf(file.name.split('.').pop().toLowerCase()) < 0) {
        this.message.error('文件格式错误');
        callback();
        if (item.onFail) item.onFail({});
        return;
      }
    }
    if (file.size > limit * 1024 * 1024) {
      if (method.includes('order')) {
        this.message.error('文件超过'+ limit +'M,无法上传');
        callback();
      } else {
        this.notification.error('文件超过'+ limit +'M,无法上传', '');
      }
      if (item.onFail) item.onFail({});
      return;
    }
    this.loading = true;
    const formData = new FormData();
    let key = this.signData.dir + this.randomString() + '_' + file.name.replace(/%/g, '')
    formData.append('key',key);
    formData.append('acl', 'public-read');
    formData.append('bucket', this.signData.bucket);
    formData.append('policy', this.signData.policy);
    formData.append('X-Amz-Credential', this.signData.credential);
    formData.append('X-Amz-Date', this.signData.amzDate);
    formData.append('X-Amz-Signature', this.signData.signature);
    formData.append('x-amz-algorithm', 'AWS4-HMAC-SHA256');
    formData.append('file', file, file.name);
    let imagParam = {} as any;
    imagParam.filename = key;
    imagParam.etag = '';
    imagParam.size = '';
    imagParam.mimeType = '';
    imagParam.height = '';
    imagParam.width ='' ;
    imagParam.format = '';
    //imagParam.user_id = '2363194';
    imagParam.user_id = this.authService.user.id.toString();
    imagParam.folder_id =  folder.toString();
    imagParam.team = '';
    imagParam.handle = handle;
    imagParam.method = method;
    imagParam.order_id = order_id;
    imagParam.demand_id = '';
    return this.http.post<any>(this.signData.host + '/' + this.signData.bucket , formData,{ observe: 'response' })
      .subscribe(
        data => {
          this.uploadFileKey.push(key);
          item.onSuccess(data);
          this.loading = false;
          this.fileData = data;
        },
        err => {
          if (method.includes('order') && method !== 'order_must_see') {
            this.message.error('上传失败');
            callback();
          } else {
            this.notification.error('上传失败', '');
          }
          if (item.onFail) item.onFail(err);
        }
      );
  }
   uploadImage(data:HttpResponse<any>,imagParam){
    imagParam.etag =  data.headers.get('Etag').replace(/"/g, '').toString();
    return this.http.post<any>('/upload/image', imagParam)
  }

  uploadBlob(item: any, folder = 0, handle = 'frontend', method = 'material', limit = 20) {
    const blob = item.blob;

    if (blob.size > limit * 1024 * 1024) {
      this.message.error('文件超过'+ limit +'M,无法上传');
      if (item.onFail) item.onFail({});
      return;
    }

    const formData = new FormData();
    const name = this.randomString() + '.' + item.type;
    formData.append('name', name);
    formData.append(
      'key',
      this.signData.dir + name
    );
    formData.append('policy', this.signData.policy);
    formData.append('OSSAccessKeyId', this.signData.accessid);
    formData.append('signature', this.signData.signature);
    formData.append('success_action_status', '200');
    formData.append('callback', this.signData.callback);
    formData.append('file', blob, name);
    // 用户文件夹
    formData.append('x:folder_id', folder.toString());
    formData.append('x:handle', handle);
    formData.append('x:method', method);
    // 自定义参数
    if (!this.authService.isGuest) {
      formData.append('x:user_id', this.authService.user.id.toString());
    }
    return this.http.post<FileCommon>(this.signData.host, formData)
      .pipe(
        switchMap(data => this.http.post<FileCommon>('/upload/image', data))
      )
      .subscribe(
        data => {
          item.onSuccess(data);
          this.loading = false;
          this.fileData = data;
        },
        err => {
          this.message.error('上传失败');
          if (item.onFail) item.onFail(err);
        }
      );
  }

  uploadTemporary(item: any, folder = 0, handle = 'frontend', method = 'material', limit = 300) {
    const file = item.file;
    //判断文件格式
    if (method === 'material' || method === 'normal') {
      let rules = ['svg', 'jpg', 'jpeg', 'png'];
      if (handle === 'backend') {
        rules.push('gif');
        rules.push('json');
      }
      if (rules.indexOf(file.name.split('.').pop().toLowerCase()) < 0) {
        this.notification.error('文件格式错误', '');
        if (item.onFail) item.onFail({});
        return;
      }
    }
    if (file.size > limit * 1024 * 1024) {
      this.notification.error('文件超过'+ limit +'M,无法上传', '');
      if (item.onFail) item.onFail({});
      return;
    }
    this.loading = true;
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append(
      'key',
      this.signData.dir + this.randomString() + '_' + file.name.replace(/%/g, '')
    );
    formData.append('policy', this.signData.policy);
    formData.append('OSSAccessKeyId', this.signData.accessid);
    formData.append('signature', this.signData.signature);
    formData.append('success_action_status', '200');
    formData.append('callback', this.signData.callback);
    formData.append('file', file, file.name);
    // 用户文件夹
    formData.append('x:folder_id', folder.toString());
    formData.append('x:handle', handle);
    formData.append('x:method', method);
    // 自定义参数
    if (!this.authService.isGuest) {
      formData.append('x:user_id', this.authService.user.id.toString());
    }
    return this.http.post<any>(this.signData.host, formData).subscribe(
        data => {
          item.onSuccess({
            ...data,
            temporaryUrl: this.signData.host + '/' + data.filename
          });
          this.loading = false;
        },
        err => {
          if (method.includes('order') && method !== 'order_must_see') {
            this.message.error('上传失败');
          } else {
            this.notification.error('上传失败', '');
          }
          if (item.onFail) item.onFail(err);
        }
      );
  }

  uploadMultipart(item: any, folder = '0', handle = 'frontend', method = 'material', limit = 300, order_id = '', demand_id = '', typeName = [], callback?): Subscription {
    const file = item.file;
    if (file.size > limit * 1024 * 1024) {
      if (method.includes('order')) {
        this.message.error('文件超过'+ limit +'M,无法上传');
        callback ? callback() : ''
      } else {
        this.notification.error('文件超过'+ limit +'M,无法上传', '');
      }
      if (item.onFail) item.onFail({});
      return;
    }
    if (method.includes('order') && method !== 'order_must_see') {
      if (typeName.length > 0 && typeName.indexOf(file.name.split('.').pop().toLowerCase()) < 0) {
        // this.notification.error('文件格式错误', '');
        this.message.error('文件格式错误');
        callback ? callback() : ''
        if (item.onFail) item.onFail({});
        return;
      }
    }

    if (method === 'order_must_see') {
      let rules = ['svg', 'jpg', 'jpeg', 'png', 'zip', 'rar', 'doc', 'docx'];
      if (rules.indexOf(file.name.split('.').pop().toLowerCase()) < 0) {
        this.notification.error('文件格式错误', '');
        if (item.onFail) item.onFail({});
        return;
      }
    }
    this.loading = true;
    const objKey = this.signData.dir + this.randomString() + '_' + file.name.replace(/%/g, '');
    this.ossOption.callback.customValue = {
      folder_id: folder,
      user_id: this.authService.user.id.toString(),
      handle,
      method,
      order_id: order_id.toString(),
      demand_id: demand_id.toString()
    };

    return fromPromise(this.client.multipartUpload(objKey, file, this.ossOption)).pipe(
      switchMap((data: any) => this.http.post<FileCommon>('/upload/image', data.data))
    ).subscribe(
      data => {
        item.onSuccess(data);
        this.loading = false;
      },
      err => {
        if (method.includes('order') && method !== 'order_must_see') {
          this.message.error('上传失败');
          callback ? callback() : ''
        } else {
          this.notification.error('上传失败', '');
        }
        if (item.onFail) item.onFail(err);
      }
    );
  }

  uploadMathor(item) {
    const file = item.file;

    if (file.size > 100 * 1024 * 1024) {
      this.notification.error('文件超过100M,无法上传', '');
      if (item.onFail) item.onFail({});
      return;
    }

    const formData = this.matherFormData(file);

    return this.http.post('https://mmzj.youdianpinwei.com/api/TBZ/TbzVideoUpload', formData).pipe(
      switchMap(res => {
        if (res['Data']['Code'] < 0) {
          return throwError(res['Data']['Msg'])
        } else {
          return of(res['Data']['ImageUrl'])
        }
      }),
      switchMap(data => this.http.post<FileCommon>('/upload/video-qrcode', {url: data}))
    ).subscribe(
      data => {
        item.onSuccess(data);
        this.loading = false;
        this.fileData = data;
      },
      err => {
        this.notification.error('上传失败', err);
        if (item.onFail) item.onFail(err);
      }
    )
  }

  randomString(len = 32): string {
    const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    const maxPos = $chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  }

  matherFormData(file) {
    const formData = new FormData();
    const time = this.date.transform(new Date().getTime(), 'yyyyMMddHHmmss');
    const signStr = `reqTime=${time}&uid=${this.authService.user.id}&k=9bd6c45e9ca74a76be81cd15e56840d8`;
    const sign = Md5.hashStr(signStr);
    formData.append('uid', this.authService.user.id.toString());
    formData.append('reqTime', time);
    formData.append('sign', sign as string);
    formData.append('file', file);
    return formData;
  }
}
