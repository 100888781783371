import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { Classify, ClassifyConfig, ClassifyLevel, KeyClassifyLevel } from '@libs/core/src/lib/models/classify';
import { Tag } from '@libs/core/src/lib/models/tag';
import { RouterUrlService } from '@libs/core/src/lib/services/router-url.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { TemplateService } from '../services/template.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { ClassifyService } from '@libs/core/src/lib/services/template/classify.service';
import { HSB2RGB, RGB2HSB } from '@libs/editor/src/lib/utils/misc/common';
import * as Color from 'color';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpClient } from '@angular/common/http';

@AutoUnsubscribe()
@Component({
  selector: 'app-list-menu',
  templateUrl: './list-menu.component.html',
  styleUrls: ['./list-menu.component.scss']
})
export class ListMenuComponent implements OnInit, OnDestroy, OnChanges {
  @Input() query: any;
  @Output() currentList = new EventEmitter<ClassifyLevel|KeyClassifyLevel>();
  @ViewChild('bright') brightEl: ElementRef;
  @ViewChild('hue') hueEl: ElementRef;
  fontColor: string;
  showMarket: string;
  kkw: string;
  kb: string;

  current: ClassifyLevel;
  currentChild: ClassifyConfig;
  keyProducts: KeyClassifyLevel[];
  keyCurrent: KeyClassifyLevel;
  keyCurrentChild: ClassifyConfig;
  pr: string;
  cid: string | number;
  cp: string;
  sort: string;
  scribe: Subscription;
  classifyName: string;
  tags: Tag[];
  co: string;
  // 分类数组
  url: string;
  keywords: string;
  templateId: string;
  isrotate1 = false;
  isrotate2 = false;
  classifyId: string | number;
  videoShow = false;
  public videoUrls = [
    {
      id:'1',
      url:'//image.tubangzhu.net/updata_new/video/demo_xiangceshu.mp4'
    },
    {
      id:'141',
      url:'//image.tubangzhu.net/updata_new/video/demo_caipu.mp4'
    }
  ];
  public sortArr = [
    { name: '默认排序', value: undefined},
    { name: '最新上传', value: '1'},
    { name: '最多浏览', value: '10'},
    { name: '最多收藏', value: '20'},
    { name: '最多使用', value: '30'}
  ];
  public videoUrl;
  @Input() dataProvider;
  public colorList:any = [];
  public hsb = [0, 0, 0];
  public nowColor = '#ffffff';
  public downType = '';
  public filterColor = '';
  public showColorPicker = false;
  public currentColor = '';
  constructor(
    public coop: CoopService,
    public routerUrlService: RouterUrlService,
    public templateService: TemplateService,
    public classify: ClassifyService,
    public modal: ModalService,
    private router: Router,
    public modal2: NzMessageService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.scribe = this.routerUrlService.data$.subscribe(data => {
      this.keywords = data.get('k');
      this.classifyId = data.get('pid');
      this.pr = data.get('pr');
      this.cid = data.get('cid');
      this.cp = data.get('cp');
      this.sort = data.get('o');
      this.co = data.get('co');
      // 非搜索模式
      if (!this.keywords) {
        let product, size, page, replace;
        if (this.classifyId) {
          product = this.classify.products.find(value => value.classify.classifyId == this.classifyId);
        }
        // if (!product) {
        //   replace = true;
        //   product = this.classify.products[0];
        // }
        if (this.pr && this.cid) {
          size = product.classifyConfig.find(item => item.id == this.cid)
        }
        // if (!size && product.classifyConfig.length) {
        //   replace = true;
        //   size = product.classifyConfig[0]
        // }
        if (size && size.page.length > 1 && size.page.indexOf(this.cp) < 0) {
          replace = true;
          page = size.page[0]
        }
        if (replace && this.routerUrlService.url === 'template/list') {
          const url = this.routerUrlService.urlParamStatic([
            {name: 'pid', value: product ? product.classify.classifyId:''},
            {name: 'pr',  value: size ? size.classifyId : ''},
            {name: 'cid', value: size ? size.id : ''},
            {name: 'cp', value: page || ''},
            { name: 'p', value: '' }
          ]);
          this.router.navigate(['/template/list', url], {replaceUrl: true});
          return;
        }

        this.current = product;
        this.currentChild = size;
      }

      this.kkw = data.get('kk');
      this.kb = data.get('kb');

      this.videoUrl = this.videoUrls.filter(item=> item.id === this.classifyId);

      this.showMarket = data.get('fm');
    });
    this.getColorList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.dataProvider) {
      if (changes.dataProvider.currentValue && this.keywords) {
        this.keyProducts = changes.dataProvider.currentValue.relationClassify;
        let product, size;
        if (this.classifyId) {
          product = this.keyProducts.find(item => item.classifyId == this.classifyId);
          product.children = product.children.filter(item => item.classifyId != product.classifyId || item.expand == 0)
        }
        if (product && product.children.length) {
          if (this.pr && this.cid) {
            size = product.children.find(item => item.id == this.cid)
          }
          if (!size) {
            size = product.children[0];
            const url = this.routerUrlService.urlParamStatic([
              {name: 'pr',  value: size.classifyId},
              {name: 'cid', value: size.id},
              {name: 'cp', value: size.page.length > 1 ? size.page[0] : ''},
              { name: 'p', value: '' }
            ]);
            this.router.navigate(['/template/list', url], {replaceUrl: true});
            return;
          }
        }
        this.keyCurrent = product;
        this.keyCurrentChild = size;
      }
    }
  }

  ngAfterViewInit(): void {
    // 绘制颜色选择图
    this.drawBright();
    // 绘制HUE
    this.drawHue();
  }

  ngOnDestroy(): void { }

  trackByProvider(index: number, item) {
    return item.classifyId
  }

  leavein1(): void {
    this.isrotate1 = true;
  }
  leaveout1(): void {
    this.isrotate1 = false;
  }
  leavein2(): void {
    this.isrotate2 = true;
  }
  leaveout2(): void {
    this.isrotate2 = false;
  }
  handleColorPicker (e) {
    if (!this.showColorPicker) {
      this.showColorPicker = true;
      this.hsb = RGB2HSB(new Color(this.nowColor.toLowerCase()).rgb().color);
      this.drawBright();
    } else {
      this.cancelColor();
    }
  }

  getColorList() {
    this.http.get('/color').subscribe(res => {
      this.colorList = res;
      if (this.co) {
        if (this.colorList.indexOf(this.co) !== -1) {
          this.currentColor = this.co; // 颜色
        } else {
          this.nowColor = this.co;
          this.filterColor = this.co;
        }
      }
    })
  }

  /**
   * 根据传入颜色绘制
   * @param color 颜色
   */
  drawBright(): void {
    if(this.brightEl){
      const el = this.brightEl.nativeElement,
      ctx = el.getContext('2d'),
      baseColor = Color.hsl(this.hsb[0], 100, 50).hex();
      // 绘制基础色
      ctx.fillStyle = baseColor;
      ctx.fillRect(0, 0, el.width, el.height);
      // 生成白色过渡(从左到右)
      const linearGradient1 = ctx.createLinearGradient(0, 0, el.width, 0);
      linearGradient1.addColorStop(0, 'rgba(255,255,255,1)');
      linearGradient1.addColorStop(1, 'rgba(255,255,255,0)');
      // 绘制白色过渡矩形
      ctx.fillStyle = linearGradient1;
      ctx.fillRect(0, 0, el.width, el.height);
      // 生成黑色过渡(从下到上)
      const linearGradient2 = ctx.createLinearGradient(0, el.height, 0, 0);
      linearGradient2.addColorStop(0, 'rgba(0,0,0,1)');
      linearGradient2.addColorStop(1, 'rgba(0,0,0,0)');
      // 绘制黑色过渡矩形
      ctx.fillStyle = linearGradient2;
      ctx.fillRect(0, 0, el.width, el.height);
    }
  }
  /**
   * 绘制hue色板
   */
  drawHue(): void {
    if (!this.hueEl) return;
    const el = this.hueEl.nativeElement,
      ctx = el.getContext('2d'),
      linearGradient = ctx.createLinearGradient(0, 0, 0, el.height);
    // 创建过渡色
    const colors = [
      '#ff0000',
      '#ff00ff',
      '#0000ff',
      '#00ffff',
      '#00ff00',
      '#ffff00',
      '#ff0000'
    ];
    for (let i = 0; i < colors.length; i++) {
      linearGradient.addColorStop(i / (colors.length - 1), colors[i]);
    }
    // 绘制矩形
    ctx.fillStyle = linearGradient;
    ctx.fillRect(0, 0, el.width, el.height);
  }
  /**
   * 获取当前颜色
   */
  getNowColor(): string {
    if (this.nowColor === 'transparent') {
      return 'ffffff';
    } else {
      return Color(this.nowColor.toLowerCase()).hex().replace('#', '');
    }
  }
  /**
   * 事件:bright区域按下鼠标
   */
  onBrightMouseDown(): void {
    this.downType = 'bright';
  }
  /**
   * 事件:色相选择器鼠标按下
   */
  onHueMouseDown(): void {
    this.downType = 'hue';
  }
  /**
   * 事件:鼠标在窗口移动
   * @param event 事件对象
   */
  onWindowMouseMove(event): void {
    let x, y;
    const offset = 0.1;
    if (this.downType === 'bright') {
      const bbox = this.brightEl.nativeElement.getBoundingClientRect();
      (x = event.clientX - bbox.left), (y = event.clientY - bbox.top);

      if (x > bbox.width - offset) {
        x = bbox.width - offset;
      }
      if (y > bbox.height - offset) {
        y = bbox.height - offset;
      }
      if (x < offset) {
        x = offset;
      }
      if (y < offset) {
        y = offset;
      }
      const el = this.brightEl.nativeElement,
        s = (x / el.width) * 100,
        b = 100 - (y / el.height) * 100;
      this.hsb[1] = s;
      this.hsb[2] = b;
      this.nowColor = Color.rgb(HSB2RGB(this.hsb)).hex();
    } else if (this.downType === 'hue') {
      const el = this.hueEl.nativeElement,
        bbox = el.getBoundingClientRect();
      y = event.clientY - bbox.top;
      if (y > bbox.height - offset) {
        y = bbox.height - offset;
      }
      if (y < offset) {
        y = offset;
      }
      const h = 360 - (y / el.height) * 360;
      this.hsb[0] = h;
      this.drawBright();
      this.nowColor = Color.rgb(HSB2RGB(this.hsb)).hex();
    }
    const colorObj = Color(this.nowColor.toLowerCase());
  }
  /**
   * 事件:窗口松开鼠标
   */
  async onWindowMouseUp() {
    if (this.downType !== '') {
      this.downType = '';
    }
  }
  /**
   * 事件:输入颜色文本被改变
   * @param event 事件对象
   */
  onColorInputChange(event): void {
    setTimeout(() => {
      let nColor = event.target.value;
      if (nColor.indexOf('#') === -1) {
        nColor = '#' + nColor;
      }
      let color;
      try {
        color = new Color(nColor);
        this.hsb = RGB2HSB(color.color);
        this.nowColor = color.hex();
        this.drawBright();
      } catch (error) {
        this.modal2.info('请输入正确的颜色值');
      }
    }, 1);
  }
  confirmColor() {
    this.filterColor = this.nowColor;
    this.currentColor = '';
    this.showColorPicker = false;
    this.router.navigate(['/template/list', this.routerUrlService.urlParamStatic([{name: 'co', value: this.filterColor}])])
  }
  cancelColor() {
    this.showColorPicker = false;
  }
  filterByColor(item) {
    this.currentColor = item;
    this.filterColor = '';
    this.showColorPicker = false;
    this.router.navigate(['/template/list', this.routerUrlService.urlParamStatic([{name: 'co', value: this.currentColor}])])
  }
  clearColor() {
    this.currentColor = '';
    this.filterColor = '';
    this.nowColor = '#ffffff'
    this.router.navigate(['/template/list', this.routerUrlService.urlParamStatic([{name: 'co', value: ''}])])
  }
}
