import { Axis, Core, Info, _calcY } from './chartUtil';
export default class Multipie {
  public base;
  constructor(chart: any) {
    this.base = this._initData(chart);
  }
  private _initData(chart) {
    let bottom = 100;
    //数据信息
    let base = { ...chart.options, ...chart.props };

    base.padding = [80, 50];
    //坐标轴颜色
    base.color = '#dcdddd';
    //绘图区域尺寸
    base.chart_width = base.width - base.padding[0] * 2;
    base.chart_height = base.height - base.padding[1] * 2 - bottom;

    return base;
  }
  public getHtml(): string {
    let _html = '';
    let base = this.base;
    _html += `<svg width="${base.width}" height="${base.height}" viewbox="0 0 ${
      base.width
    } ${
      base.height
    }" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg" style="user-select:none; background:#ececec">
				<g transform="translate(${base.padding[0]},${base.padding[1]})">
			`;
    _html += Core.drawMultiPie(base);
    _html += Info.drawPie(base);

    _html += '</g></svg>';
    return _html;
  }
}
