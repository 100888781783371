import { EventEmitter } from '@angular/core';

export class ModalObject {
  // 关闭之后事件
  afterClose = new EventEmitter();
  constructor(public appRef, public componentRef) {}
  /**
   * 关闭模态框
   */
  close() {
    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
    this.afterClose.emit();
  }
}
