import { EditorService } from '../../../services/editor.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { Element } from '../../../models/base/element';
import { deepClone, getUnitScale } from '../../../utils/misc/common';
import * as $ from 'jquery';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {
  public get props(): any {
    return this.editor.activeElements[0].props;
  }
  public get options(): any {
    return this.editor.activeElements[0].options;
  }
  public oImageStyle;
  public offsetX = 0;
  public offsetY = 0;
  public oImgWidth = 0;
  public oImgHeight = 0;
  public corner = undefined;
  constructor(public editor: EditorService) { }

  ngOnInit(): void {
    // 获取焦点,让stage丢失焦点,防止触发舞台移动和缩放
    $('.image-cropper-wrap').focus();
    // 获取图片未裁切时的位置
    const activeElement = this.editor.activeElements[0],
      props = activeElement.props,
      options = activeElement.options;
    const scale =
      this.editor.template.scale * this.editor.zoom;
    $('.image-cropper-wrap').append(
      `<svg class="hidden-img" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"><rect width="100" height="100"></rect></svg>`
    );
    const $hidden = $('.hidden-img');
    $hidden
      .attr('width', activeElement.props.width)
      .attr('height', activeElement.props.height)
      .attr(
        'viewBox',
        `${options.clip.x} ${options.clip.y} ${options.clip.width} ${
        options.clip.height
        }`
      );
    $hidden
      .find('rect')
      .attr('width', props.oWidth)
      .attr('height', props.oHeight);
    const bbox = $hidden.find('rect')[0].getBoundingClientRect(),
      svgbbox = $hidden[0].getBoundingClientRect();
    $('.image-cropper-wrap')
      .find('.hidden-img')
      .remove();
    (this.offsetX = bbox.left - svgbbox.left),
      (this.offsetY = bbox.top - svgbbox.top);

    this.oImgWidth = bbox.width;
    this.oImgHeight = bbox.height;

    this.oImageStyle = {
      left: this.editor.stagePos.x + (props.x + this.offsetX) * scale + 'px',
      top: this.editor.stagePos.y + (props.y + this.offsetY) * scale + 'px',
      width: bbox.width * scale + 'px',
      height: bbox.height * scale + 'px',
      transform: `rotate(${props.rotation}deg) scale(${
        props.flipX ? '-1' : '1'
        },${props.flipY ? '-1' : '1'})`,
      transformOrigin: `${(-this.offsetX + props.width / 2) * scale}px ${(-this
        .offsetY +
        props.height / 2) *
        scale}px`
    };
    activeElement.states.clip = deepClone(options.clip);
    $('.image-cropper-wrap').remove('.hidden-img');
  }
  /**
   * 获取角点鼠标样式
   * @param rotation 角点初始角度
   */
  getCursor(rotation) {
    const cursor = ['nw-resize', 'n-resize', 'ne-resize', 'e-resize', 'nw-resize', 'n-resize', 'ne-resize', 'e-resize', 'e-resize'];
    if (this.editor.activeElements.length === 1) {
      rotation += this.editor.activeElements[0].props.rotation;
    }
    //如果rotation大于360,则自减小
    while (rotation > 360) {
      rotation -= 360;
    }
    // 获取到角度
    let index;
    if (rotation % 45 === 0) {
      if (rotation === 0) {
        index = 3;
      } else {
        index = rotation / 45 - 1;
      }
    } else {
      index = (rotation - (rotation % 45)) / 45;
    }
    return cursor[index];
  }
  /**
   * 事件:操作角点被按下
   * @param corner 角点
   */
  onConerMouseDown(corner, event: MouseEvent): void {
    this.corner = corner;
    // console.log('角点被按下', this.corner);
    event.stopPropagation();
  }
  /**
   * 事件:鼠标在窗口移动
   * @param event 事件对象
   */
  @HostListener('document:mousemove', ['$event']) onWindowMouseMove(event) {
    if (this.corner) {
      const elementItem: Element = this.editor.activeElements[0];
      const props = elementItem.props,
        options = elementItem.options,
        states = elementItem.states,
        zoomX = this.oImgWidth / props.oWidth,
        zoomY = this.oImgHeight / props.oHeight;
      const angel = (props.rotation * Math.PI) / 180;
      const mouse = this.editor.mouse;
      const heightRatio = states.clip.height / states.clip.width;
      const scale = this.editor.template.scale;
      let vecX =
        (Math.cos(angel) * mouse.vecX + Math.sin(angel) * mouse.vecY) /
        zoomX /
        scale;
      let vecY =
        (Math.cos(angel) * mouse.vecY - Math.sin(angel) * mouse.vecX) /
        zoomY /
        scale;
      if (props.flipX) {
        vecX = -vecX;
      }
      if (props.flipY) {
        vecY = -vecY;
      }
      // console.log(this.corner);
      switch (this.corner) {
        case 'move':
          states.clip.x += vecX;
          states.clip.y += vecY;
          if (states.clip.x < 0) {
            states.clip.x = 0;
          }
          if (states.clip.x > props.oWidth - states.clip.width) {
            states.clip.x = props.oWidth - states.clip.width;
          }

          if (states.clip.y < 0) {
            states.clip.y = 0;
          }
          if (states.clip.y > props.oHeight - states.clip.height) {
            states.clip.y = props.oHeight - states.clip.height;
          }
          break;
        case 'br':
          if (
            states.clip.x + states.clip.width + vecX < props.oWidth &&
            states.clip.y + states.clip.height + vecX * heightRatio <
            props.oHeight &&
            states.clip.width + vecX > 0 &&
            states.clip.height + vecX * heightRatio > 0
          ) {
            states.clip.width += vecX;
            states.clip.height += vecX * heightRatio;
          }
          break;
        case 'tl':
          if (
            states.clip.width - vecX > 0 &&
            states.clip.height - vecX * heightRatio > 0 &&
            states.clip.x + vecX > 0 &&
            states.clip.y + vecX * heightRatio > 0
          ) {
            states.clip.x += vecX;

            states.clip.width -= vecX;
            states.clip.height -= vecX * heightRatio;
            states.clip.y += vecX * heightRatio;
          }
          break;
        case 'tr':
          if (
            states.clip.y - vecX * heightRatio > 0 &&
            states.clip.width + vecX > 0 &&
            states.clip.height + vecX * heightRatio > 0 &&
            states.clip.x + states.clip.width + vecX < props.oWidth
          ) {
            states.clip.width += vecX;
            states.clip.height += vecX * heightRatio;
            states.clip.y -= vecX * heightRatio;
          }
          break;
        case 'bl':

          if (
            states.clip.x + vecX > 0 &&
            states.clip.width - vecX > 0 &&
            states.clip.height - vecX * heightRatio > 0 &&
            states.clip.y + states.clip.height - vecX * heightRatio < props.oHeight
          ) {
            states.clip.x += vecX;
            states.clip.width -= vecX;
            states.clip.height -= vecX * heightRatio;
          }

          break;
        case 'mt':
          if (
            states.clip.y + vecY > 0 &&
            states.clip.height - vecY > 0
          ) {
            states.clip.y += vecY;
            states.clip.height -= vecY;
          }
          break;
        case 'mb':
          if (
            states.clip.height + vecY > 0 &&
            states.clip.y + states.clip.height + vecY < props.oHeight
          ) {
            states.clip.height += vecY;
          }
          break;
        case 'ml':
          if (
            states.clip.x + vecX >= 0 &&
            states.clip.width - vecX > 0
          ) {
            states.clip.x += vecX;
            states.clip.width -= vecX;
          }
          break;
        case 'mr':
          if (
            states.clip.width + vecX > 0 &&
            states.clip.x + states.clip.width + vecX < props.oWidth
          ) {
            states.clip.width += vecX;
          }
          break;
      }
    }
  }
  /**
   * 事件:鼠标在窗口松开鼠标
   */
  @HostListener('document:mouseup', ['$event']) onWindowMouseUp() {
    this.corner = null;
  }
  /**
   * 事件:应用修改被单击
   */
  onApplyClick() {
    const elementItem = this.editor.activeElements[0];
    const props = elementItem.props,
      options = elementItem.options,
      states = elementItem.states;
    const scale =
      this.editor.template.scale * this.editor.zoom,
      zoomX = this.oImgWidth / props.oWidth,
      zoomY = this.oImgHeight / props.oHeight;

    // 创建一个临时的裁剪rect,获取bbox
    $('.cropper').append(`<div class="element-temp-rect" style="
    border:2px solid green;
    box-sizing:border-box;
    z-index:1000;
    position:absolute;
    left:${options.clip.x * zoomX}px;
    top:${options.clip.y * zoomY}px;
    width:${options.clip.width * zoomX}px;
    height:${options.clip.height * zoomY}px;"></div>`);
    $('.cropper').append(`<div class="cropper-temp-rect" style="
      border:2px solid red;
      box-sizing:border-box;
      z-index:1000;
      position:absolute;
      left:${states.clip.x * zoomX}px;
      top:${states.clip.y * zoomY}px;
      width:${states.clip.width * zoomX}px;
      height:${states.clip.height * zoomY}px;"></div>`);
    // 获取bbox差值
    const elementBbox = $('.element-temp-rect')[0].getBoundingClientRect(),
      cropperBbox = $('.cropper-temp-rect')[0].getBoundingClientRect();

    const oldBBox = this.editor.stage.getBBox(elementItem, true);
    props.width = states.clip.width * zoomX;
    props.height = states.clip.height * zoomY;
    const newBBox = this.editor.stage.getBBox(elementItem, true);
    const offsetX =
      cropperBbox.left - elementBbox.left + (oldBBox.left - newBBox.left);
    const offsetY =
      cropperBbox.top - elementBbox.top + (oldBBox.top - newBBox.top);
    props.x += offsetX;
    props.y += offsetY;
    // 修改clip
    options.clip = states.clip;
    elementItem.emit('modified', {
      type: 'clip',
      target: elementItem
    });
    elementItem.exitEditMode();
  }
  /**
   * 事件:放弃修改被单击
   */
  onCancelClick() {
    const activeElement = this.editor.activeElements[0];
    activeElement.exitEditMode();
  }
  /**
   * 获取操作按钮样式
   */
  getOptionsStyle() {
    const bbox = $('.resizer')[0].getBoundingClientRect();
    const style = {
      left: bbox.right + 10 + 'px',
      top: bbox.top + bbox.height / 2 + 'px'
    };
    return style;
  }

    /**
   * 事件:按下按键
   */
    @HostListener('document:keydown', ['$event']) onStageContainerKeyDown(event: KeyboardEvent) {
      //console.log('event.code2',event.code)
      switch (event.code) {
        case 'Escape':
          const activeElement = this.editor.activeElements[0];
          activeElement.exitEditMode();
          break;
      }
    }
  /**
   * 获取裁切层样式
   */
  getImageCropperStyle() {
    const elementItem: Element = this.editor.activeElements[0];
    const props = elementItem.props,
      options = elementItem.options,
      states = elementItem.states;
    const scale =
      this.editor.template.scale * this.editor.zoom,
      zoomX = this.oImgWidth / props.oWidth,
      zoomY = this.oImgHeight / props.oHeight;
    const style = {
      left: states.clip.x * zoomX * scale + 'px',
      top: states.clip.y * zoomY * scale + 'px',
      width: states.clip.width * zoomX * scale + 'px',
      height: states.clip.height * zoomY * scale + 'px',
      background: `url(${options.url}) no-repeat`,
      backgroundSize: `${this.oImgWidth * scale}px ${this.oImgHeight *
        scale}px`,
      backgroundPosition: `${-(states.clip.x * zoomX * scale)}px ${-(
        states.clip.y *
        zoomY *
        scale
      )}px`
    };

    return style;
  }
}
