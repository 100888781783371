import { Component, OnInit, Input, forwardRef, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AdminService } from '@libs/core/src/lib/services/post-order/admin.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
// import { ModalService } from '@libs/modal/src/lib/services/modal.service';
// import { ModalService } from '@libs/core/src/lib/services/modal.service';

@Component({
	selector: 'create-store',
	templateUrl: 'creat-category.component.html',
	styleUrls: ['./creat-category.component.scss']
})

export class CreatCategoryComponent implements OnInit {
	@Input() id: any;
	@Input() name: any;
	validateForm: FormGroup;
	titleText: string;
	categoryName: string;
	qq: string;
	wang_wang: string;
	wechat_number: string;
	btnLoading = false;
  errFlag:boolean;
	constructor(
		private fb: FormBuilder,
		public http: HttpClient,
		private adminService: AdminService,
		private message: MessageService,
    private modal: NzModalRef,
    public coop: CoopService,
    // public modalTwo: ModalService
  ) { }
	submitForm(value:any) {
		if (this.validateForm.invalid) {
			return
		}
		if (this.id !== 'add') {
			let data = { id: this.id, name: value.categoryName }
			this.btnLoading = true;
			this.adminService.editCategory(data).subscribe(res => {
				this.btnLoading = false;
				this.message.success('修改成功');
				this.close(res);
			}, (err: HttpErrorResponse) => {
				this.btnLoading = false;
				this.message.error(err);
			})
		} else {
			this.btnLoading = true;
			this.adminService.addCategory({ name: this.validateForm.value.categoryName }).subscribe(res => {
				this.btnLoading = false;
				this.message.success('创建成功');
				this.close(res);
			}, (err: HttpErrorResponse) => {
        this.close(err);
        if(err.error.message == 80003){
          this.errFlag = true
        }else{
          this.errFlag  =false
        }
        if(this.errFlag){
          this.message.error('基础版只能创建5个品类');
          // alert('彈出')
          // this.modalTwo.upgradeModel('店铺')
        }else{
          this.message.error(err)
        }
				this.btnLoading = false;
				// this.message.error(err);
			})
		}
	}
	formInit() {
		this.validateForm = this.fb.group({
			categoryName: [this.name, [Validators.required]]
    });
	}
	close(data) {
		this.modal.close(data);
	}
	ngOnInit() {
		this.formInit();
		if (this.id !== 'add') {
			this.titleText = '修改';
			// this.adminService.storeDetail(this.id).subscribe(res => {
			// 	this.name = res.name;
			// 	this.formInit();
			// }, (err: HttpErrorResponse) => {
			// 	this.message.error(err);
			// })
		} else {
			this.titleText = '添加';
      this.name = '';
		}
	}
}
