import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CardSetting, User, UserCallback } from '@libs/core/src/lib/models/user';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { select, Store } from '@ngrx/store';
import { LoginSuccess, LoginSuccessLocal, Logout } from '@libs/core/src/lib/+state/auth.actions';
import { apiUpdateUserInfo, apiUserInfo, apiDoUserLogin } from '@libs/core/src/lib/util/api';
import { AppService } from '@libs/core/src/lib/services/app.service';
import { selectLoginParams } from '../../../../../apps/frontend/src/app/+state/app.reducer';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, zip } from 'rxjs';
import { catchError, delay, map, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { MessageService } from '@libs/core/src/lib/services/message.service';

export interface QrcodeReturn {
  content: string;
}

export interface EnterpriseWechatReturn {
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isGuest = true;
  public isSim = false;
  public user: User;

  public loginTip = false;
  public afterLoginMethod: any = null;

  private qrcodeUrl = '/wechat/qrcode';
  private sessionUrl = '/wechat/session';
  private refreshTokenUrl = '/wechat/refresh';
  private bindPhone = '/user/bind';
  private shortmessage = '/main/send-sms';
  private retrieve = '/user/reset-password';
  private resetPasswordUrl = '/user/reset-password-old';
  private resetMobileUrl = '/user/reset-mobile';
  private mobileLogin = '/user/login';
  private mobileForget = '/cooperation/forget';
  private apiUserForget = '/cooperation/user-forget';

  private apiReset = '/user/update-password';

  private mobileRegister = '/user/register';

  private apiCoopApply = '/coop-agent/apply';

  constructor(
    private http: HttpClient,
    private store: Store<any>,
    private localStorage: LocalStorage,
    private cookieService: CookieService,
    private appService: AppService,
    private message: MessageService,
  ) {
    window['auth'] = this;
  }

  getCountryCode() {
    return this.http.get<any[]>('/country-code')
  }

  isPersonalVip() {
    return this.user && this.user.personalExpired > Date.parse(new Date().toString()) / 1000;
  }

  isTeamVip() {
    return this.user && this.user.teamExpired > Date.parse(new Date().toString()) / 1000;
  }

  /**
   * 是否有VIP权限
   */
  isVip() {
    return this.isPersonalVip() || this.appService.isTeam();
  }

  /**
   * 登录二维码
   * @returns {Observable<QrcodeReturn>}
   */
  getLoginQrcode(): Observable<QrcodeReturn> {
    let  domain = window.location.hostname;
    let protocol = window.location.protocol;
    this.qrcodeUrl = protocol + '//' + domain + '/sxb-api/v1/wechat/qrcode';

    return this.http.post<QrcodeReturn>(this.qrcodeUrl, {});
  }
  /**
   * 企业微信登录地址
   * @returns {Observable<EnterpriseWechatReturn>}
   */
  getEnterpriseWechatUrl() {
    return this.http.get<EnterpriseWechatReturn>('/wechat-work/web-login')
  }

  /**
   * 验证扫码登录
   * @returns {Observable<User>}
   */

  getWxSession(): Observable<User> {
    let  domain = window.location.hostname;
    let protocol = window.location.protocol;
    this.sessionUrl = protocol + '//' +  domain + '/sxb-api/v1/wechat/session';
    return this.http.post<User>(this.sessionUrl, {});
  }

  getSession(): Observable<User> {
    return this.http.post<User>(this.sessionUrl, {}).pipe(
      retryWhen(err =>
        err.pipe(
          delay(1500),
          take(40)
        )
      )
    );
  }

  updatePassword(password: string): Observable<any> {
    return this.http.post(this.apiReset, { password });
  }

  cookieLogin(tubangzhu?: string): Observable<UserCallback> {
    if (isDevMode()) {
      return this.http.post<UserCallback>('/user/check-login', { tubangzhu });
    } else {
      return this.http.post<UserCallback>('/api/check-login', { tubangzhu });
    }
  }

  simLogin(key: string): Observable<UserCallback> {
    return this.http.post<UserCallback>(apiDoUserLogin, {key});
  }

  recheck() {
    return this.cookieLogin().pipe(
      switchMap(res => {
        if (res.status === 'y') {
          return this.updateUserInfo(res.user).pipe(
            map(data => Object.assign(data, res.user))
          );
        } else {
          return of(null);
        }
      }),
      tap(data => {
        if (data && this.isGuest) {
          this.store.dispatch(new LoginSuccess({ user: data }));
        } else if (!data && !this.isGuest) {
          this.store.dispatch(new Logout());
        }
      })
    )
  }

  //代理商申请
  coopApply(params): Observable<any> {
    return this.http.post(this.apiCoopApply, params);
  }

  /**
   * 手机号登录
   * @param mobile
   * @param password
   * @returns {Observable<User>}
   */
  loginByMobile(params): Observable<UserCallback> {
    return this.http.post<UserCallback>(this.mobileLogin, params);
  }
  loginForget(params): Observable<UserCallback> {
    return this.http.post<UserCallback>(this.mobileForget, params);
  }
  //注册
  register(params): Observable<UserCallback> {
    return this.http.post<UserCallback>(this.mobileRegister, params);
  }
  //用户忘记密码
  userForget(params): Observable<User> {
    return this.http.post<User>(this.apiUserForget, params);
  }

  //绑定手机号
  getBind(): Observable<User> {
    return this.http.post<User>(this.bindPhone, {});
  }

  //绑定手机短信验证
  getSms(code: string, type: string, country_code_id = 1): Observable<User> {
    return this.http.post<User>(this.shortmessage, {
      mobile: code,
      type: type,
      country_code_id
    });
  }

  /**
   * 绑定手机
   * @param {string} mobile
   * @param {string} code
   * @param {string} password
   * @param {string} passwordRepeat
   * @returns {Observable<User>}
   */
  bindMobile(
    mobile: string,
    code: string,
    password: string,
    passwordRepeat: string
  ): Observable<User> {
    return zip(
      this.localStorage.getItem('_invite'),
      this.store.select(selectLoginParams)
    ).pipe(
      switchMap(([data, user]) => {
        const params = {
          mobile: mobile,
          sms_code: code,
          password: password,
          password_repeat: passwordRepeat
        };
        if (typeof data === 'number') {
          params['invite'] = data;
        }
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + user.accessToken });
        return this.http.post<User>(this.bindPhone, params, { headers: headers }).pipe(
          map(() => user)
        );
      })
    );
  }

  /**
   * 重置密码
   * @param {string} phone
   * @param {string} code
   * @param {string} password
   * @param {string} passwordRepeat
   * @returns {Observable<User>}
   * @constructor
   */
  resetPassword(
    phone: string,
    code: string,
    password: string,
    passwordRepeat: string
  ): Observable<User> {
    return this.http.post<User>(this.retrieve, {
      mobile: phone,
      code: code,
      password: password,
      password_repeat: passwordRepeat
    });
  }

  /**
   * 重置密码
   * @param params
   * @returns {Observable<User>}
   */
  resetPasswordOld(params): Observable<User> {
    return this.http.post<User>(this.resetPasswordUrl, params);
  }

  codeLogin(login_code): Observable<User> {
    return this.http.post<User>('/user/login-by-code', { login_code }).pipe(
      catchError(e => {
        this.message.error(e.error.errorMessage)
        throw e
      }),
      switchMap(user => this.updateUserInfo(user).pipe(
        map(data => Object.assign(data, user))
      ))
    );
  }

  wechatWorkCodeLogin(wechatWorkCode): Observable<User> {
    return this.http.get<User>('/wechat-work/get-code-info/' + wechatWorkCode).pipe(
      catchError(e => {
        this.message.error(e.error.errorMessage)
        throw e
      }),
      switchMap(user => this.updateUserInfo(user).pipe(
        map(data => Object.assign(data, user))
      ))
    );
  }

  /**
   * 重置手机
   * @returns {Observable<User>}
   * @constructor
   */
  resetMobile(params): Observable<User> {
    return this.http.post<User>(this.resetMobileUrl, params).pipe(
      tap(data => this.updateUser(data))
    );
  }

  updateUserInfo(user?: User): Observable<User> {
    if (user) {
      this.store.dispatch(new LoginSuccessLocal({ user: user }));
    }
    return this.http.get<User>(apiUserInfo);
  }

  getCardSettingId(id) {
    return this.http.get('/member-template-info/' + id)
  }
  cardSetting(params) {
    if (params.id) {
      return this.http.put('/member-template-info/' + params.id, params);
    } else {
      return this.http.post('/member-template-info', params)
    }
  }

  removeCardSetting(id) {
    return this.http.delete('/member-template-info/' + id)
  }

  cardSettingHistory(params): Observable<CardSetting[]> {
    return this.http.get<CardSetting[]>('/member-template-info', {params})
  }

  /**
   * 修改用户信息
   * @param {User} user
   * @returns {Observable<User>}
   */
  doUpdateUserInfo(user: User): Observable<User> {
    return this.http.post<User>(apiUpdateUserInfo, user)
      .pipe(
        tap(data => this.updateUser(data))
      );
  }

  refreshToken(token): Observable<User> {
    return this.http.post<User>(this.refreshTokenUrl, { refresh_token: token });
  }

  updateUser(user: User) {
    const params = {
      ...this.user,
      ...user,
      accessToken: this.user.accessToken
    };
    this.store.dispatch(new LoginSuccessLocal({ user: params }));
  }
}
