import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { MemberService } from '@libs/core/src/lib/services/member/member.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { HttpClient } from '@angular/common/http';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { NzProgressModule } from 'ng-zorro-antd/progress';

@Component({
  selector: 'app-vip-download',
  templateUrl: './vip-download.component.html',
  styleUrls: ['./vip-download.component.scss'],
  providers: [
    MemberService
  ]
})
export class VipDownloadComponent implements OnInit {
  loading = false;
  format = 'jpg';
  scale = 1;
  dpi = 300;
  //dpi = '300';
  quality = 'hd';
  timer;
  target: string;
  qrcodeUrl: string;
  activeTab = 'pc';
  downloadSuccess = false;
  type = 'pdf';
  tip = ['正在导出文件,请稍后..', '这通常不会花费太多时间', '导出一般会在数秒至数分钟内完成', '正在完成文件导出,下载即将就绪..', '导出时间比预期长,但我们会很快完成它', '感谢您的耐心等待,我们将尽快完成导出', '服务器正在优先为您导出', '文件已进入高速通道,正在为您优先导出', '这个文件看起来很大,但我们将尽快完成导出', '正在准备下载文件'];
  modalId;
  proTime;
  percent = 0;
  showProgress = false;
  @Input() template;
  @Input() params: any = {};

  constructor(
    private authService: AuthService,
    private modal: NzModalRef,
    private memberService: MemberService,
    @Inject(forwardRef(() => ModalService))
    private modalService,
    private message: MessageService,
    private http: HttpClient,
    public coop: CoopService
  ) {
  }

  ngOnInit() {
  }
  onFormatItemClick(item){
    this.format = item.type;
  }
  typeChange(type) {
    if (type === 'pdf') {
      this.dpi = 300
    }
  }
  ourDownload() {
    this.progressLineMove();
    // let param = {} as any;
    // param.format = this.format;
    // param.scale = this.scale;
    // param.dpi = this.dpi;
    //this.loading = true;
    this.http.post<any>('/coop-download', {
      format:this.format,
      scale:this.scale,
      dpi:this.dpi,
      template_id: this.template.id,
    }).subscribe(data => {
      if(data.id) {
        this.startListen(data.id)
      }
      // if (data.status == 20) {
      //   window.location.href = data.filePath;
      // } else if (data.status < 20/*等待渲染*/ || data.status == 25 /*排队*/) {
      //   //this.modalId = this.message.loading('等待渲染...', { nzDuration: 0 }).messageId;
      //   this.startListen(data.id, data.status)
      // }
    }, () => {
      this.showProgress = false;
      clearInterval(this.proTime);
      this.message.error('下载失败');
    })
  
    // } else {
    //   this.http.post('/coop-download', {
    //     template_id: this.template.id,
    //     format,
    //     dpi: parseInt(this.dpi)
    //   }).subscribe(
    //     data => {this.downloadSuccess = true;},
    //     err => {this.message.error('网络错误，请重试')},
    //     () => {this.loading = false}
    //   )
    // }

  }
  startListen(id) {
    this.timer = setInterval(() => {
      this.http.get<any>('/coop-download/status', {params: {download_id: id}}).subscribe(data => {
        // if (status != data.status && this.modalId) {
        //   status = data.status;
        //   this.message.remove(this.modalId);
        //   if (status == 15) {
        //     this.modalId = this.message.loading('正在渲染...', { nzDuration: 0 }).messageId;
        //   }
        // }
        if (data.status == 20) {
          this.percent = 100;
          this.showProgress = false;
          clearInterval(this.timer);
          clearInterval(this.proTime);
          this.timer && clearInterval(this.timer);
          this.proTime && clearInterval(this.proTime);
          this.message.success('渲染成功');
          //console.log('data.filePath',data.filePath)
          window.location.href = data.downloadUrl;
        } else if (data.status == 30) {
          this.showProgress = false;
          clearInterval(this.timer);
          clearInterval(this.proTime);
          this.message.error('渲染失败');
        }
      })
    }, 1000)
  }
  onDownloadClick() {
    this.download();
  }
  async download() {
    let params = {
      id: this.template.id,
      format: this.coop.coopInfo.id !== '12864' ? this.format : this.type
    };
    this.loading = true;
    const data = await this.http.post('/coop-download',params).toPromise();
    if (data){
      this.loading = false;
      this.downloadSuccess = true;
    }else{
       this.message.error('网络错误，请重试')
    }
  }

  showQrcode(url: string) {
    this.qrcodeUrl = url;
    this.modalService.showQrcode(this.qrcodeUrl);
  }
  close() {
    this.modal.destroy();
  }
  // onTabItemSwitch(tab) {
  //   this.activeTab = tab;
  //   if (tab === 'mobile') {
  //     this.download('mobile');
  //   }
  // }
  goMyDownload(){
    this.close();
    window.open('/member/downloadList')
  }

  progressLineMove(){
    this.percent = 0;
    this.showProgress = true;
    this.proTime = setInterval(()=>{
      if (this.percent < 99) {
        this.percent++;
      }
    },100);
  }

  ngOnDestroy(): void {
    //卸载定时器
    clearInterval(this.timer);
    clearInterval(this.proTime);
  }
}
