import { Component, Input, OnInit } from '@angular/core';
import { ClassifyService } from '@libs/core/src/lib/services/template/classify.service';
import { ClassifyLevel } from '@libs/core/src/lib/models/classify';
import { RouterUrlService } from '@libs/core/src/lib/services/router-url.service';
import { AuthService } from '@libs/core/src/lib/services/auth.service';

@Component({
  selector: 'app-classify',
  templateUrl: './classify.component.html',
  styleUrls: ['./classify.component.scss']
})
export class ClassifyComponent implements OnInit {
  // 是否可收起
  @Input() overflow = false;
  // 收起展开状态
  close = false;
  products: ClassifyLevel[];
  constructor(
    public classify: ClassifyService,
    public urlS: RouterUrlService,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.products = this.overflow ? this.classify.products.slice(0, 13) : this.classify.products;
    this.close = this.overflow;
  }

  switchOverflow() {
    this.close = !this.close;
    this.products = this.close ? this.classify.products.slice(0, 13) : this.classify.products;
  }

  statistics(name) {
    if (window['zhuge']) {
      window['zhuge']['track']('微创印-点击模版分类图标', {
        '用户ID': this.auth.isGuest ? '0' : this.auth.user.coopUid,
        '模版分类名称': name
      })
    }
  }

}
