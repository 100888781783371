import { AfterViewInit, Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import Cropper from 'cropperjs';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';
import * as $ from 'jquery'
import { ToastIcon } from '@libs/modal/src/lib/models/toast-icon';
import { UploadService } from '@libs/core/src/lib/services/upload.service';
import { Window } from '@libs/editor/src/lib/models/ui/window';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';

@Component({
  selector: 'app-t-menu-cut-img',
  templateUrl: './t-menu-cut-img.component.html',
  styleUrls: ['./t-menu-cut-img.component.scss']
})
export class TMenuCutImgComponent implements OnInit, AfterViewInit{
  @Input() itemData;
  @Input() index;
  public cropper;
  public imgPath;
  public parentWindow: Window;
  public loading;
  public prohibitClick = false;
  constructor(
    public editor:EditorService,
    public modal: ModalService,
    public upload:UploadService
  ) { }

  ngOnInit() {
    if (this.itemData['图片'].hasOwnProperty('oldFilePath')) {
      this.imgPath = this.itemData['图片'].oldFilePath;
    }else{
      this.imgPath = this.itemData['图片'].filePath;
    }
    this.loading = true;
  }
  ngAfterViewInit(): void {
    try {
      this.initCutImg()
    }
    catch (e) {
      console.log(e)
    }
  }
  initCutImg(){
    //获取图片比例
    let svgDOM = $(this.editor.activeElements[0].states.svg).find('#image-图片')[0];
    let width = svgDOM.getAttribute('width');
    let height = svgDOM.getAttribute('height');
    const image = document.createElement('img');
    image.src = this.imgPath;
    image.crossOrigin = 'anonymous';
    image.style.display = 'none';
    image.onload = ()=>{
      document.getElementById('image').appendChild(image);
      this.cropper = new Cropper(image, {
        aspectRatio: width / height,
        viewMode:2,
        checkCrossOrigin:true,
        ready:()=>{
          this.loading = false;
          if (this.itemData['图片'].hasOwnProperty('oldFilePath')) {
            this.cropper.setData(this.itemData['图片'].cropperData);
            this.cropper.setCanvasData(this.itemData['图片'].cropperCanvasData);
            this.cropper.setCropBoxData(this.itemData['图片'].cropperBoxData);
          }
        }
      });
    }
  }
  close(){
    this.parentWindow.close();
  }
  confirmCut() {
    if (this.prohibitClick) return;
    this.prohibitClick = true;
    const loadingToast = this.modal.toast({
      icon: ToastIcon.LOADING,
      msg: '正在裁切',
      duration: 0
    });
    let canvasData = this.cropper.getCroppedCanvas();
    let imgData = TMenuCutImgComponent.getRoundedCanvas(canvasData).toDataURL("images/png",1.0);
    let fileName = TMenuCutImgComponent.randomString()+'.png';
    let imgFile = TMenuCutImgComponent.dataURLtoFile(imgData,fileName);
    let cropperData = this.cropper.getData();
    let cropperCanvasData = this.cropper.getCanvasData();
    let cropperBoxData = this.cropper.getCropBoxData();
    this.upload.uploadTemp({
      file:imgFile,
      onSuccess: res => {
        if (!this.itemData['图片'].hasOwnProperty('oldFilePath')) {
          this.itemData['图片'].oldFilePath = this.itemData['图片'].filePath;
          this.itemData['图片'].oldHeight = this.itemData['图片'].height;
          this.itemData['图片'].oldWidth = this.itemData['图片'].width;
          this.itemData['图片'].oldFileId = this.itemData['图片'].fileId;
        }
        this.itemData['图片'].filePath = res.filePath;
        this.itemData['图片'].height = res.height;
        this.itemData['图片'].width = res.width;
        this.itemData['图片'].fileId = res.fileId;
        this.itemData['图片'].cropperData = cropperData;
        this.itemData['图片'].cropperCanvasData = cropperCanvasData;
        this.itemData['图片'].cropperBoxData = cropperBoxData;
        this.editor.template.emit('element:modified', {
          type: 'image',
          target: this.editor.activeElements[0]
        });
        // 退出当前页所有的editMode
        for (const eleItem of this.editor.activePage.elements) {
          if (eleItem.type == 'layout'){
            eleItem['exitEditMode']();
          }
        }
        loadingToast.close();
        this.modal.toast({
          icon: ToastIcon.SUCCESS,
          msg: '已裁切'
        });
        this.prohibitClick = false;
        this.parentWindow.close();
      },
      onFail: e => {
        loadingToast.close();
        this.modal.toast({
          icon: ToastIcon.ERROR,
          msg: '裁切失败'
        });
        this.prohibitClick = false;
      }
    });
  }
  static dataURLtoFile(dataurl,filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  static getRoundedCanvas (sourceCanvas) {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    let width = sourceCanvas.width;
    let height = sourceCanvas.height;
    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    return canvas;
  }
  //随机生成文件名
  static randomString(len = 32): string {
    const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    const maxPos = $chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  }
}
