import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { TemplateMember } from '@libs/core/src/lib/models/template-member';
import { MemberService } from '@libs/core/src/lib/services/member/member.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-download-template',
  templateUrl: './download-template.component.html',
  styleUrls: ['./download-template.component.scss'],
  providers: [
    MemberService
  ]
})
export class DownloadTemplateComponent implements OnInit {
  loading = false;
  format = 'jpg';
  quality = 'hd';
  target: string;
  qrcodeUrl: string;
  activeTab = 'pc';
  formatList = [
    { type: 'jpg', name: 'JPG图片', describe: '用于互联网和社交圈传播' },
    { type: 'png', name: 'PNG图片', describe: '图片底部透明效果' },
    { type: 'pdf', name: 'PDF文件', describe: '用于高清打印和印刷' },
    { type: 'pptx', name: 'PPTX文件', describe: 'PPT软件演示和继续编辑' }
  ];
  tip = ['正在导出文件,请稍后..', '这通常不会花费太多时间', '导出一般会在数秒至数分钟内完成', '正在完成文件导出,下载即将就绪..', '导出时间比预期长,但我们会很快完成它', '感谢您的耐心等待,我们将尽快完成导出', '服务器正在优先为您导出', '文件已进入高速通道,正在为您优先导出', '这个文件看起来很大,但我们将尽快完成导出', '正在准备下载文件'];
  tipIdx = 0;
  tipTimer;
  @Input() template;

  constructor(
    private authService: AuthService,
    private modal: NzModalRef,
    private memberService: MemberService,
    @Inject(forwardRef(() => ModalService))
    private modalService,
    private message: MessageService,
    private http: HttpClient
  ) {
  }

  ngOnInit() {
  }
  onFormatItemClick(item) {
    this.format = item.type;

  }
  onDownloadClick() {
    this.download();
  }
  download(target = 'pc') {
    this.target = target;
    // if (!this.authService.isVip() && this.quality === 'hd') {
    //   this.message.info('您不是会员，不能下载高清图');
    //   return;
    // }
    this.loading = true;
    this.tipIdx = 0;
    this.tipTimer = setInterval(() => {
      this.tipIdx++;
      if (this.tipIdx > this.tip.length - 1) {
        this.tipIdx = 0;
      }
    }, 10000);
    this.memberService.download({ format: this.format, quality: this.quality, template_id: this.template.templateId, down_target: this.target })
      .subscribe(
        data => {
          this.loading = false;
          clearInterval(this.tipTimer);
          if (this.target === 'pc') {
            window.location.href = data;
          } else {
            this.qrcodeUrl = data;
          }
        },
        err => {
          this.loading = false;
          clearInterval(this.tipTimer);
          this.message.error(err);
        }
      );
  }

  showQrcode(url: string) {
    this.qrcodeUrl = url;
    this.modalService.showQrcode(this.qrcodeUrl);
  }

  close() {
    this.modal.destroy();
  }
  onTabItemSwitch(tab) {

    this.activeTab = tab;
    if (tab === 'mobile') {
      this.download('mobile');
    }
  }

}
