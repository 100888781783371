import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { DesignerService } from '@libs/core/src/lib/services/post-order/designer.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { OrderService } from '@libs/core/src/lib/services/post-order/order.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AdminService } from '@libs/core/src/lib/services/post-order/admin.service';

@Component({
	selector: 'porder-confirm',
	templateUrl: 'porder-confirm.component.html',
	styleUrls: ['./porder-confirm.component.scss']
})

export class PorderConfirmComponent implements OnInit {
	@Input() mainContent:string;
	@Input() subContent:string;
	@Input() type:string;
	@Input() id:string;
	mainPaddingTop:string;
	subPaddingTop:string;
	btnLoading = false;
	constructor(
		private message: MessageService,
		private designer: DesignerService,
		private adminService: AdminService,
		private modalRef: NzModalRef,
		private modal: NzModalRef,
		private order: OrderService) {}
	confirm() {
		// designer(设计师通过审核) designerDetail(通过详情通过的，通过后返回上一页) pay(单个设为已结算) batchPay(批量设置为已结算) deleteCategory(删除订单品类)
		if (this.type === 'designerDetail' || this.type === 'designer') {
			this.designer.optDesigner({id: this.id, method: 'pass_yes'}).subscribe(() => {
				this.message.success('操作成功');
				this.modalRef.close();
				if (this.type === 'designer') {
					window.location.reload();
				} else {
					window.history.go(-1);
				}
			}, (err: HttpErrorResponse) => {
				this.message.error(err);
			})
		}
		if (this.type === 'pay') {
			this.btnLoading = true;
			this.order.settlOrder({designer_id: this.id}).subscribe(() => {
				// this.message.success('操作成功');
				this.btnLoading = false;
				this.close(true);
				alert('操作成功');
			}, (err: HttpErrorResponse) => {
				this.btnLoading = false;
				this.message.error(err);
			})
		}
		if (this.type === 'batchPay') {
			this.btnLoading = true;
			this.order.settlOrder().subscribe(() => {
				this.btnLoading = false;
				this.close(true);
				alert('操作成功');
			}, (err: HttpErrorResponse) => {
				this.btnLoading = false;
				this.message.error(err);
			})
		}
		if (this.type === 'deleteCategory') {
			this.adminService.delteClassify(this.id).subscribe(() => {
				this.message.success('删除成功');
				window.location.reload();
			}, (err: HttpErrorResponse) => {
				this.message.error(err);
			})
		}
		if (this.type === 'deleteCategorys') {
			this.adminService.delteClassifyByStore(this.id).subscribe(() => {
				this.message.success('删除成功');
				window.location.reload();
			}, (err: HttpErrorResponse) => {
				this.message.error(err);
			})
		}
		if (this.type === 'deleteMemorandum') {
      this.adminService.deleteMemorandum(this.id).subscribe(() => {
        this.message.success('删除成功');
        window.location.reload();
      }, (err: HttpErrorResponse) => {
        this.message.error(err);
      })
		}
		// window.location.reload();
	}
	close(active?) {
		this.modal.close(active);
	}
	ngOnInit() {
		if (!this.subContent) {
			this.mainPaddingTop = '30px';
		} else {
			this.mainPaddingTop = '12px';
			this.subPaddingTop = '8px';
		}
	}
}
