import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { DesignerService } from '@libs/core/src/lib/services/post-order/designer.service';
import { DataProvider } from '@libs/core/src/lib/util/data-provider';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-order-notice',
  templateUrl: './order-notice.component.html',
  styleUrls: ['./order-notice.component.scss']
})
export class OrderNoticeComponent implements OnInit {

  dataList = [];
  dataProvider = new DataProvider<any>();
  currentTab = '';
  constructor(
    private router: Router,
    private modal: NzModalRef,
    private designer: DesignerService
  ) { }

  checkDetail(id) {
    this.modal.close();
    window.open(`/postorder/designer/taskDetail/${id}?from=order`);
  }

  tabChange(type) {
    this.dataProvider.currentPage = 1;
    this.currentTab = type;
    this.dataList = [];
    this.getNoticeList();
  }

  getNoticeList() {
    let data;
    if (this.currentTab === 'change') {
      data = { page: this.dataProvider.currentPage, change: 1 };
    } else if (this.currentTab === 'message') {
      data = { page: this.dataProvider.currentPage, message: 1 };
    } else if (this.currentTab === 'reject') {
      data = { page: this.dataProvider.currentPage, reject: 1 };
    } else {
      data = { page: this.dataProvider.currentPage };
    }
    this.designer.noticeList(data).pipe(
      map(data => this.dataProvider.prepare(data))
    ).subscribe(res => {
      if (this.dataProvider.currentPage === 1) {
        this.dataList = res;
      } else {
        this.dataList = this.dataList.concat(res);
      }
    });
  }

  ngOnInit() {
    this.getNoticeList();
  }

  listScroll(e) {
    if (e.srcElement.clientHeight + e.srcElement.scrollTop == e.srcElement.scrollHeight) {
      if (this.dataProvider.currentPage < this.dataProvider.totalPages) {
        this.dataProvider.currentPage += 1;
        this.getNoticeList();
      }
    }
  }

}
