import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { MessageService } from '@libs/core/src/lib/services/message.service';

@Component({
  selector: 'app-share-template',
  templateUrl: './share-template.component.html',
  styleUrls: ['./share-template.component.scss']
})
export class ShareTemplateComponent implements OnInit {

  @Input() doShare: (id) => Observable<any>;
  public userId: number;

  loading = false;

  constructor(
    private message: MessageService,
    private modal: NzModalRef
  ) { }

  ngOnInit() {
  }

  share() {
    if (!this.userId) {
      this.message.info('请输入要共享人的ID');
      return;
    }

    this.loading = true;
    this.doShare(this.userId).subscribe(
      data => {
        this.loading = false;
        this.message.success('共享成功!');
        this.modal.destroy();
      },
      err => {
        this.loading = false;
        this.message.error(err);
      }
    );

  }

  close() {
    this.modal.destroy();
  }

}
