import { Component, OnInit } from '@angular/core';
import { EditorService } from '../../../services/editor.service';

@Component({
  selector: 'app-layout-cover',
  templateUrl: './layout-cover.component.html',
  styleUrls: ['./layout-cover.component.scss']
})
export class LayoutCoverComponent implements OnInit {

  constructor(
    public editor: EditorService
  ) { }

  ngOnInit() {
  }
  getEditAreaStyle() {
    const eleItem = this.editor.activeElements[0];
    const { props, options } = eleItem;
    const zoom =
      this.editor.zoom * this.editor.template.scale;
    const style = {
      left: this.editor.stagePos.x + props.x * zoom + 'px',
      top: this.editor.stagePos.y + props.y * zoom + 'px',
      width: props.width * zoom + 'px',
      height: props.height * zoom + 'px',
      transform: `rotate(${props.rotation}deg)`
    };
    return style;
  }

}
