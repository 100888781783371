import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { MemberService } from '@libs/core/src/lib/services/member/member.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { HttpClient } from '@angular/common/http';
import { CoopService } from '@libs/core/src/lib/services/coop.service';

@Component({
  selector: 'app-vip-download-list',
  templateUrl: './vip-download-list.component.html',
  styleUrls: ['./vip-download-list.component.scss'],
  providers: [
    MemberService
  ]
})
export class VipDownloadListComponent implements OnInit {
  loading = false;
  downloadList:any = [];
  conductRendering = [];
  downloadSuccess = false;
  type = 'pdf';
  tip = ['正在导出文件,请稍后..', '这通常不会花费太多时间', '导出一般会在数秒至数分钟内完成', '正在完成文件导出,下载即将就绪..', '导出时间比预期长,但我们会很快完成它', '感谢您的耐心等待,我们将尽快完成导出', '服务器正在优先为您导出', '文件已进入高速通道,正在为您优先导出', '这个文件看起来很大,但我们将尽快完成导出', '正在准备下载文件'];
  modalId;
  @Input() template;
  @Input() params: any = {};

  constructor(
    private authService: AuthService,
    private modal: NzModalRef,
    private memberService: MemberService,
    @Inject(forwardRef(() => ModalService))
    private modalService,
    private message: MessageService,
    private http: HttpClient,
    public coop: CoopService
  ) {
  }

  ngOnInit() {
    this.getDownloadList();
  }

  async getDownloadList(){
    const params = {};
    const downList =  await this.http
      .get('/coop-download',{params,observe:'response'})
      .toPromise();
    if (downList.body['length']){
      this.downloadList = downList.body;
      this.downloadList = this.downloadList.filter(item=>{
        return item.status == 20;
      });
    }else{
      this.downloadList = [];
    }
  }

  download(item,$event){
    if (item.status != 20){
      $event.preventDefault();
      return;
    }
  }

  close() {
    this.modal.destroy();
  }

  goMyDownload(){
    this.close();
    window.open('/member/downloadList')
  }
}
