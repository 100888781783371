import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Classify, ClassifyLevel } from '@libs/core/src/lib/models/classify';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateService } from '../services/template.service';
import { RouterUrlService } from '@libs/core/src/lib/services/router-url.service';
import { TemplateListCallback } from '@libs/core/src/lib/models/list.interface';
import { DataProvider } from '@libs/core/src/lib/util/data-provider';
import { map, switchMap, take, tap } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
  listPrev: boolean;
  callback;
  current:ClassifyLevel;
  query: any;
  loading = false;
  //列表类型water:multi-column\list:flex
  cid: string;
  dataProvider = new DataProvider<TemplateListCallback>();
  constructor(
    public routerUrlService: RouterUrlService,
    private routeInfo: ActivatedRoute,
    private router: Router,
    private templateService: TemplateService
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.routeInfo.queryParams.subscribe(data => this.query = data);
    this.routeInfo.params.pipe(
      tap(url => {
        this.loading = true;
        this.listPrev = !/\bk-/.test(url.query);
      }),
      switchMap(url => this.templateService.getTemplateList(url.query.replace(/\.html/g, '-l-27').replace('kk-', 'k-'))),
      map(data => this.dataProvider.prepare(data))
    ).subscribe(res => {
      this.loading = false;
      if (res.status === 'y') {
        this.callback = res.data;
      }
    });
  }

  setPage(page) {
    this.routerUrlService.getUrlParam([{ name: 'p', value: page }])
      .pipe(take(1))
      .subscribe(data => {
        this.router.navigate(['/template/list', data], {queryParams: this.query});
      });
  }
  currentListChange(current){
    this.current = current;
  }
  ngOnDestroy(): void {}
}
