import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Validator } from '@libs/shared/src/lib/validators/validators';
import { mobileValidator } from '@libs/shared/src/lib/directives/mobile.directive';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { LoginSuccess } from '@libs/core/src/lib/+state/auth.actions';
import { Store } from '@ngrx/store';
import { AuthState } from '@libs/core/src/lib/+state/auth.reducer';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent implements OnInit {
  loginForm: FormGroup;
  loginByCode: FormGroup;

  countryCode: any[] = [];
  count = 0;
  is_coop = 1;
  is_agent = 0;
  public val: Validator;
  isLoadingSubmit = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private store: Store<AuthState>,
    private message: MessageService,
    public coop: CoopService,
    private router: Router
  ) {
    this.val = new Validator();
    this.authService.getCountryCode().subscribe(data => {
      this.countryCode = data;
    });
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      mobile: ['', [Validators.required, mobileValidator()]],
      password: ['', Validators.required]
    });
    this.loginByCode = this.fb.group({
      is_sms: [1],
      country_code_id: [1],
      mobile: ['', [Validators.required, mobileValidator()]],
      code: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  getMobileCode(mobile, type): void {
    this.count = 59;
    const interval$ = setInterval(() => {
      this.count -= 1;
      if (this.count <= 0) clearInterval(interval$);
    }, 1000);
    this.authService.getSms(mobile, type, this.loginByCode.value.country_code_id).subscribe(data => { });
  }

  onSubmit() {
    if (this.loginForm.invalid) return;
    this.isLoadingSubmit = true;
    this.authService.loginByMobile(this.loginForm.value).pipe(
      switchMap(data => {
        if (data.status == 'y') {
          return this.authService.updateUserInfo(data.user).pipe(
            map(res => Object.assign(res, data.user))
          );
        } else {
          return of(data.msg);
        }
      })
    ).subscribe(
        data => {
          this.isLoadingSubmit = false;
          if (typeof data == 'object') {
            this.store.dispatch(new LoginSuccess({ user: data }));
            this.router.navigate(['/'])
          } else {
            this.message.error(data);
          }
        },
        (err: HttpErrorResponse) => {
          this.isLoadingSubmit = false;
          this.message.error(err);
        }
      );
  }

  codeSubmit() {
    if (this.loginByCode.invalid) return;
    this.isLoadingSubmit = true;

    this.authService.loginByMobile(this.loginByCode.value).pipe(
      switchMap(data => {
        if (data.status == 'y') {
          return this.authService.updateUserInfo(data.user).pipe(
            map(res => Object.assign(res, data.user))
          );
        } else {
          return of(data.msg);
        }
      })
    ).subscribe(
      data => {
        this.isLoadingSubmit = false;
        if (typeof data == 'object') {
          this.store.dispatch(new LoginSuccess({ user: data }));
          this.router.navigate(['/']);
        } else {
          this.message.error(data);
        }
      },
      (err: HttpErrorResponse) => {
        this.isLoadingSubmit = false;
        this.message.error(err);
      }
    );
  }
}
