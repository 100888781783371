import { Element } from '../base/element';
import { ToolBarNewTableComponent } from '@libs/editor/src/lib/components/editor-ui/t-tool-bar/tools/tool-bar-new-table/tool-bar-new-table.component';
import { NewTableEditComponent } from '@libs/editor/src/lib/components/edit-tools/new-table-edit/new-table-edit.component';
import * as $ from 'jquery'

export class NewTable extends Element {
  public type = 'newTable';
  onCreated() {
    this.config.corner = {
      tl: false,
      tr: false,
      ml: false,
      mr: false,
      bl: false,
      br: true,
      mt: false,
      mb: false,
      rotate: false
    };
    this.config.toolbar = ToolBarNewTableComponent;
    this.config.markAble = false;
    this.config.resizeFromInput = false;
    this.states.editMode = false;
    this.options.toolbarType = 'table';
    if (!this.options.padding){
      this.options.padding = 8;
    }
    //当前选择的单元格列表
    this.options.chooseCells = [];
    let downWidth = 0;
    let downScale = 1;
    this.on('cornermousedown', e => {
      downScale = this.options.scale;
      downWidth = this.props.width;
    });
    // 表格缩放时位置计算
    this.on('scaling', e => {
      if (e.corner === 'mr' || e.corner === 'mt'||e.corner === 'mb'||e.corner === 'ml'){
        this.calculateNewSize();
      }
      //如果是等比缩放角点,计算缩放比例
      if (
        e.corner === 'tl' ||
        e.corner === 'tr' ||
        e.corner === 'bl' ||
        e.corner === 'br'
      ) {
        this.options.scale = (this.props.width / downWidth) * downScale;
        this.states.html = this.toHTML();
      }
    });
    // 双击:进入编辑模式
    this.on('dblclick', this.enterEditMode.bind(this));
    // 选中
    this.on('selected', this.enterSelect.bind(this));
    // 取消选择:退出编辑模式
    this.on('deselected', this.exitEditMode.bind(this));
    this.on('deselected', () => {
      this.states.activeIndex = null;
    });
    if (!this.options.scale) {
      this.options.scale = 1;
    }
    if (this.options.tableArray){
      this.states.html = this.toHTML();
    } else{
      this.createTableArray();
      setTimeout(()=>{
        this.changeEleSize()
      })
    }
  }
  toHTML(){
    let table = document.createElement('table');
    table.style.border = `${this.options.borderWidth}px ${this.options.borderColor} solid`;
    table.style.borderCollapse = `collapse`;
    table.style.tableLayout = `fixed`;
    table.style.transformOrigin = `0 0`;
    table.style.transform = `scale(${this.options.scale})`;
    table.style.width = `${1/this.options.scale*100}%`;
    table.setAttribute('id',this.id);
    let tableArray = this.options.tableArray;
    /*let hiddenTr = document.createElement('tr');
    hiddenTr.style.height = '0px';
    hiddenTr.style.border = '0';
    // hiddenTr.style.display = 'none';
    let tds = "";
    for (let i = 0;i < this.options.colNumber;i++){
      let tdItem = document.createElement('td');
      tdItem.style.width = this.options.tableColWidths[i] + 'px';
      tdItem.style.padding = '0';
      tdItem.style.height = '0';
      tdItem.style.border = '0';
      tdItem.setAttribute('rowspan','1');
      tdItem.setAttribute('colspan','1');
      tdItem.setAttribute('valign','middle');
      tds += tdItem.outerHTML;
    }
    hiddenTr.innerHTML = tds;
    table.appendChild(hiddenTr);*/
    let hiddenTr = document.createElement('tr');
    hiddenTr.style.height = '0px';
    hiddenTr.style.border = '0';
    hiddenTr.style.opacity = '0';
    let ths = "";
    for (let i = 0; i < this.options.colNumber; i++) {
      let thItem = document.createElement('th');
      thItem.style.width = (this.options.tableColWidths[i] - this.options.cellBorderWidth) + 'px';
      thItem.style.padding = '0';
      thItem.style.height = '0';
      thItem.style.borderLeft = `${this.options.cellBorderWidth}px solid ${this.options.borderColor}`;
      thItem.style.borderRight = `${this.options.cellBorderWidth}px solid ${this.options.borderColor}`;
      thItem.setAttribute('rowspan', '1');
      thItem.setAttribute('colspan', '1');
      thItem.setAttribute('valign', 'middle');
      ths += thItem.outerHTML;
    }
    hiddenTr.innerHTML = ths;
    table.appendChild(hiddenTr);

    for (let i = 0; i < tableArray.length;i++){
      let trItem = document.createElement('tr');
      let trStyleKeys = Object.keys(tableArray[i].style);
      for (let keys of trStyleKeys){
        trItem.style[keys] = tableArray[i].style[keys];
      }
      let tdHtml = '';
      for (let z = 0;z < tableArray[i].cell.length;z++){
        if (tableArray[i].cell[z].colspan == 0 || tableArray[i].cell[z].rowspan == 0) continue;
        let tdItem = document.createElement('td');
        let styleKeys = Object.keys(tableArray[i].cell[z].style);
        for (let keys of styleKeys){
          tdItem.style[keys] = tableArray[i].cell[z].style[keys];
        }
        tdItem.setAttribute('rowspan',tableArray[i].cell[z].rowspan);
        tdItem.setAttribute('colspan',tableArray[i].cell[z].colspan);
        tdItem.setAttribute('valign','middle');
        if (tableArray[i].cell[z].value != ''){
          let $text = $(`<div>${tableArray[i].cell[z].value}</div>`);
          $text.find('p').css('margin','0');
          let fontFamily = tableArray[i].cell[z].style.fontFamily;
          // 追加雅黑补充字体
          $text.find('*').each(function () {
            if ($(this).attr('face')) {
              $(this).attr('face', fontFamily);
            }
            if ($(this).css('font-family')) {
              $(this).css('font-family', fontFamily);
            }
          });
          tdItem.innerHTML = $text[0].innerHTML;
        } else{
          tdItem.innerHTML = tableArray[i].cell[z].value;
        }
        tdHtml += tdItem.outerHTML;
      }
      trItem.innerHTML = tdHtml;
      table.appendChild(trItem);
    }
    return table.outerHTML;
  }

  createTableArray(){
    let options = this.options,
      rowNumber = options.rowNumber,
      colNumber = options.colNumber,
    tableArray = [];
    let width = this.props.width*this.parent.parent.scale - this.options.borderWidth;
    let height = this.props.height*this.parent.parent.scale - this.options.borderWidth;
    for (let i = 0; i < rowNumber;i++){
      let tableTr = {
        style:{
          height:`${height/rowNumber}px`,
        },
        cell:[]
      };
      this.options.tableRowHeights.push(height/rowNumber);
      tableArray.push(tableTr);
      for (let z = 0;z < colNumber;z++){
        if (i == 0){
          this.options.tableColWidths.push(width/colNumber);
        }
        let tableCell = this.createNewTableCell();
        tableArray[i].cell.push(tableCell);
      }
    }
    this.options.tableArray = tableArray;
    this.states.html = this.toHTML();
  }
  createNewTableCell(cellWidth = 0,fontSize = 0){
    let options = this.options,
      colNumber = options.colNumber;
    let tableWidth = this.props.width*this.parent.parent.scale - this.options.borderWidth;
    let width = cellWidth || tableWidth/colNumber;
    return {
      value:'',
      rowspan:1,
      colspan:1,
      style:{
        width:`${width}px`,
        minWidth:`${width}px`,
        maxWidth:`${width}px`,
        boxSizing: 'border-box',
        padding:`${this.options.padding}px`,
        paddingTop:`${this.options.padding-2}px`,
        border:`${options.cellBorderWidth}px solid ${options.borderColor}`,
        fontSize:`${fontSize||Math.round(width/10)}px`,
        color:'#000',
        fontFamily: 'Microsoft YaHei',
        textAlign: 'left',
        overflowWrap: 'break-word'
      },
      oFontSize:fontSize||Math.round(width/10),
      chooseFont:null
    }
  }
  enterEditMode(e) {
    if (!e || e.targets) return;
    if (!this.states.editMode) {
      this.states.editMode = true;
      this.config.visible = false;
      this.config.transform = false;
      this.emit('enterEdit', {
        target: this,
        toolComponent: NewTableEditComponent
      });
    }
  }
  enterSelect(e){
    this.states.editMode = false;
    this.config.visible = true;
    this.config.transform = true;
  }
  exitEditMode() {
    if (this.states.editMode) {
      this.states.editMode = false;
      this.config.visible = true;
      this.config.transform = false;
    }
    this.states.html = this.toHTML();
    this.emit('exitEdit', {
      target: this,
      toolComponent: NewTableEditComponent
    });
  }
  calculateNewSize(isRatio = false){
    let tableArray = this.options.tableArray;
    //通过元素的宽度计算出表格的宽度 然后减去一个边框的宽度
    let newWidth = this.props.width * this.parent.parent.scale - this.options.borderWidth;
    let newHeight = this.props.height * this.parent.parent.scale - this.options.borderWidth;
    let frontWidth = this.options.frontWidth * this.parent.parent.scale;
    let sumWidth = 0;
    let sumHeight = 0;
    for (let width of this.options.tableColWidths) {
      sumWidth += width;
    }
    for (let height of this.options.tableRowHeights) {
      sumHeight += height;
    }
    if (sumWidth != newWidth){
      let widthArr = this.options.tableColWidths;
      for (let i = 0;i < widthArr.length;i++){
        widthArr[i] = widthArr[i]/sumWidth*newWidth;
      }
      this.calculationCellWidth(isRatio,newWidth,frontWidth);
    }

    if (sumHeight != newHeight){
      for (let i = 0;i < tableArray.length;i++){
        let newRowHeight = this.options.tableRowHeights[i]/sumHeight*newHeight;
        this.options.tableRowHeights[i] = newRowHeight;
        tableArray[i].style.height = newRowHeight + 'px';
      }
    }
    this.options.oWidth = this.props.width;
    this.options.oHeight = this.props.height;
    this.states.html = this.toHTML();
  }
  getChooseItem(){
    return this.options.chooseCells;
  }

  /**
   * 计算单元格宽度
   * @param isRatio
   * @param newWidth
   * @param frontWidth
   */
  calculationCellWidth(isRatio = false,newWidth = 0,frontWidth = 0){
    let widthArr = this.options.tableColWidths;
    // if (isRatio){
    //   this.options.minFontSize = 100;
    // }
    for (let i = 0; i < this.options.rowNumber;i++){
      for (let z = 0; z < this.options.colNumber;z++){
        let cell = this.options.tableArray[i].cell[z];
        if (cell.colspan == 1 || cell.colspan == 0){
          cell.style.width = widthArr[z] + 'px';
          cell.style.minWidth = widthArr[z] + 'px';
          cell.style.maxWidth = widthArr[z] + 'px';
        } else{
          let cellsWidth = 0;
          for (let j = 0;j < cell.colspan;j++){
            cellsWidth+=widthArr[z+j];
          }
          cell.style.width = cellsWidth + 'px';
          cell.style.minWidth = cellsWidth + 'px';
          cell.style.maxWidth = cellsWidth + 'px';
        }
      }
    }
  }

  /**
   * 修改表格边框颜色
   */
  changeBorderColor(){
    for (let row of this.options.tableArray){
      for (let cell of row.cell){
        cell.style.border = `${this.options.cellBorderWidth}px solid ${this.options.borderColor}`
      }
    }
  }

  /**
   * 改变当前单元格真实宽高度
   * @param rowIndex
   * @param cellIndex
   * @param zoom this.editor.zoom
   * @param changeWidth
   */
  changeCellRealSize(rowIndex,cellIndex,zoom,changeWidth = true,caWidth = true){
    let cellStyle = document.getElementById(`td-${rowIndex}-${cellIndex}`).getBoundingClientRect();
    let realHeight = cellStyle.height/zoom/this.options.scale;
    let realWidth = cellStyle.width/zoom/this.options.scale;
    if (this.options.tableArray[rowIndex].cell[cellIndex].colspan > 1) {
      let sumWidth = 0;
      for (let i = 1;i < this.options.tableArray[rowIndex].cell[cellIndex].colspan;i++){
        sumWidth += this.options.tableColWidths[cellIndex+i];
      }
      realWidth -= sumWidth;
    }
    if (this.options.tableArray[rowIndex].cell[cellIndex].rowspan > 1) {
      let sumHeight = 0;
      for (let i = 1;i < this.options.tableArray[rowIndex].cell[cellIndex].rowspan;i++){
        sumHeight += this.options.tableRowHeights[rowIndex+i];
      }
      realHeight -= sumHeight;
    }
    this.options.tableArray[rowIndex].style.height = realHeight + 'px';
    this.options.tableRowHeights[rowIndex] = realHeight;
    if (caWidth){
      this.options.tableColWidths[cellIndex] = realWidth;
    }
    if (changeWidth){
      this.calculationCellWidth();
    }
  }

  /**
   * 计算当前表格宽度 并修改元素宽度
   */
  changeEleSize(){
    let tableStyle = document.getElementById(this.id).getBoundingClientRect();
    this.props.width = tableStyle.width/window['editor'].zoom/this.parent.parent.scale;
    this.props.height = tableStyle.height/window['editor'].zoom/this.parent.parent.scale;
  }
  /**
   * 获取单元格在数组中的索引
   * @param cell
   * @return [rowIndex,cellIndex] or []
   */
  getCellIndex(cell){
    for (let i = 0;i < this.options.tableArray.length;i++){
      for (let z = 0; z < this.options.tableArray[i].cell.length;z++){
        if (this.options.tableArray[i].cell[z] == cell) return [i,z];
      }
    }
    return [];
  }
}
