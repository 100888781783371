import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Category } from '@libs/core/src/lib/models/category';
import { Observable } from 'rxjs';
import { apiCategory } from '@libs/core/src/lib/util/api';

@Injectable()
export class CategoryService {
  private userNeed = '/demand';
  constructor(private http: HttpClient) {}

  /**
   * 品类列表
   * @returns {Observable<Category[]>}
   */
  getCategoryList(): Observable<Category[]> {
    return this.http.get<Category[]>(apiCategory);
  }

  /**
   * 新增品类
   * @param {Category} params
   * @returns {Observable<Category>}
   */
  createCategory(params: Category): Observable<Category> {
    if (params.id > 0) {
      return this.http.put<Category>(apiCategory + '/' + params.id, params);
    } else {
      return this.http.post<Category>(apiCategory, params);
    }
  }

  /**
   * 删除品类
   * @param {Category} item
   * @returns {Observable<any>}
   */
  delete(item: Category): Observable<any> {
    return this.http.delete(apiCategory + '/' + item.id);
  }
  subRequirement(demand_desc: string): Observable<any> {
    return this.http.post(this.userNeed, {demand_desc: demand_desc});
  }
}
