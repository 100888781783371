import { Component, OnInit } from '@angular/core';
import { EditorService } from '../../../../../services/editor.service';
import { getFilterHtml } from '../../../../../utils/misc/filter';
import { getUnitScale } from '../../../../../utils/misc/common';

@Component({
  selector: 'app-filter-setting-tool',
  templateUrl: './filter-setting-tool.component.html',
  styleUrls: ['./filter-setting-tool.component.scss']
})
export class FilterSettingToolComponent implements OnInit {
  filters = [
    {
      name: '原图',
      bright: 0,
      contrast: 0,
      saturation: 0,
      hue: 0,
      blur: 0,
      film: 0
    },
    {
      name: '清新',
      bright: 12,
      contrast: 1,
      saturation: 11,
      hue: 0,
      blur: 0,
      film: 29
    },
    {
      name: '蓝调',
      bright: 14,
      contrast: -7,
      saturation: -28,
      hue: -35,
      blur: 0,
      film: 53
    },
    {
      name: '日系',
      bright: 10,
      contrast: 4,
      saturation: 11,
      hue: 67,
      blur: -3,
      film: 78
    },
    {
      name: '优雅',
      bright: 10,
      contrast: 18,
      saturation: 6,
      hue: 0,
      blur: -4,
      film: 55
    },
    {
      name: '古典',
      bright: 13,
      contrast: 15,
      saturation: -29,
      hue: 0,
      blur: 0,
      film: 0
    },
    {
      name: '阳光',
      bright: 10,
      contrast: -6,
      saturation: 10,
      hue: 0,
      blur: 0,
      film: 24
    },
    {
      name: '盛夏',
      bright: 10,
      contrast: 14,
      saturation: 18,
      hue: -46,
      blur: 0,
      film: 30
    },
    {
      name: '怀旧',
      bright: 8,
      contrast: 7,
      saturation: -51,
      hue: 55,
      blur: 1,
      film: 33
    },
    {
      name: '森林',
      bright: 11,
      contrast: -14,
      saturation: -18,
      hue: 49,
      blur: 0,
      film: 69
    },
    {
      name: '玫瑰',
      bright: 0,
      contrast: 27,
      saturation: -24,
      hue: -73,
      blur: -3,
      film: 27
    },
    {
      name: '黑白',
      bright: 0,
      contrast: 20,
      saturation: -100,
      hue: 0,
      blur: 0,
      film: 0
    }
  ];
  get activeElement() {
    return this.editor.activeElements[0];
  }
  filterHTML = [];
  constructor(public editor: EditorService) { }

  ngOnInit() {
    for (const item of this.filters) {
      const { props, type, options } = this.activeElement;
      let imgWidth = 0;
      let imgHeight = 0;
      if (type === 'container') {
        imgWidth = options.clip.oWidth;
        imgHeight = options.clip.oHeight;
      } else if (type === 'image') {
        imgWidth = props.oWidth;
        imgHeight = props.oHeight;
      }
      let width = 0;
      let height = 0;
      let x = 0;
      let y = 0;
      const size = 60;
      console.log({ imgWidth, imgHeight });
      if (imgWidth >= imgHeight) {
        height = size;
        width = imgWidth * (size / imgHeight);
      } else {
        width = size;
        height = imgHeight * (size / imgWidth);
      }
      x = -width / 2 + size / 2;
      y = 0;
      const html = `<svg width="100%" height="100%"
       xmlns="http://www.w3.org/2000/svg"
       style="filter:brightness(${item.bright >= 0 ? item.bright / 100 + 1 : 1});"
       preserveAspectRatio="none"
       <defs>
          ${
        item.name !== '原图'
          ? `<filter id="filter-${item.name}">` +
          getFilterHtml(item) +
          '</filter>'
          : ''
        }
       </defs>
          <image width="${width}" height="${height}" x="${x}" y="${y}" preserveAspectRatio="none" xlink:href="${options.url
      }${options.url && options.url.indexOf('.svg') < 0 ? '?x-oss-process=image/resize,m_lfit,w_60,limit_0/auto-orient,1':''}" ${
        item.name !== '原图' ? `filter="url(#filter-${item.name})"` : ''
        }/>
  </svg>`;
      this.filterHTML.push({
        item,
        html
      });
    }
  }
  /**
   * 获取滤镜的值
   * @param prop 滤镜名
   */
  getFilterProps(prop) {
    if (!this.activeElement || !this.activeElement.options.filter) {
      return 0;
    } else {
      return this.activeElement.options.filter[prop];
    }
  }
  /**
   * 预设滤镜被设置
   * @param item 滤镜项目
   */
  onFilterItemClick(item) {
    const eleItems = this.editor.activeElements;
    eleItems.forEach(eleItem => {
      eleItem.options.filter = item;
      eleItem.emit('modified', {
        type: 'setFilter',
        target: eleItem,
        stopPropagation: eleItems.length > 1
      });
    });
    if (eleItems.length > 1) {
      eleItems[0].parent.emit('element:modified', {
        type: 'setFilter',
        targets: eleItems
      });
    }
  }
  /**
   * 设置滤镜属性
   * @param prop 滤镜名
   * @param value 新值
   * @param fireEvent 是否触发修改事件
   */
  setFilterProps(prop, value, fireEvent = true) {
    /*if (!this.activeElement.options.filter) {
      this.activeElement.options.filter = this.filters[0];
    }
    this.activeElement.options.filter[prop] = value;
    if (fireEvent) {
      this.activeElement.emit('modified', {
        type: 'setFilter',
        target: this.activeElement
      });
    } else {
      this.activeElement.states.html = this.activeElement.toHTML();
    }*/

    const eleItems = this.editor.activeElements;
    eleItems.forEach(eleItem => {
      if (!eleItem.options.filter) {
        eleItem.options.filter = this.filters[0];
      }
      eleItem.options.filter[prop] = value;
      if (fireEvent) {
        eleItem.emit('modified', {
          type: 'setFilter',
          target: eleItem,
          stopPropagation: eleItems.length > 1
        });
      } else {
        eleItem.states.html = eleItem.toHTML();
      }
    });
    if (fireEvent && eleItems.length > 1) {
      eleItems[0].parent.emit('element:modified', {
        type: 'setFilter',
        targets: eleItems
      });
    }
  }
  /**
   * ngFor性能优化
   */
  trackByItem(index: number, item): any {
    return item.name;
  }
}
