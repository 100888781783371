import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app-notice-detail',
	templateUrl: './notice-detail.component.html',
	styleUrls: ['./notice-detail.component.scss']
})

export class NoticeDetailComponent implements OnInit {
	@Input() id;
	title: '';
	description: '';
	updatedAt: '';
	constructor(public http: HttpClient) {}
	getDetail() {
		const params = {
			id: this.id.toString()
		};
		this.http.get<any>('/message/guest-view', { params }).subscribe(data => {
			this.title = data.title;
			this.description = data.description;
			this.updatedAt = data.updatedAt;
		})
	}
	ngOnInit() {
		this.getDetail();
	}
}