import { Component, OnInit, Input, forwardRef, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AdminService } from '@libs/core/src/lib/services/post-order/admin.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { CoopService } from '@libs/core/src/lib/services/coop.service';

@Component({
	selector: 'create-memorandum',
	templateUrl: 'create-memorandum.component.html',
	styleUrls: ['./create-memorandum.component.scss']
})

export class CreateMemorandumComponent implements OnInit {
	@Input() id: any;
	@Input() name: any;
	@Input() order_price: any; // 接单价
	@Input() issuing_price: any; // 发单价
	@Input() remarks: any; // 备注
	validateForm: FormGroup;
	titleText: string;
	categoryName: string;
	qq: string;
	wang_wang: string;
	wechat_number: string;
	btnLoading = false;
  errFlag:boolean;
	constructor(
		private fb: FormBuilder,
		public http: HttpClient,
		private adminService: AdminService,
		private message: MessageService,
    private modal: NzModalRef,
    public coop: CoopService,
    // public modalTwo: ModalService
  ) { }
	submitForm(value:any) {
		if (this.validateForm.invalid) {
			return
		}
		if (this.id !== 'add') {
			let data = { id: this.id, ...value }
			this.btnLoading = true;
			this.adminService.editMemorandum(data).subscribe(res => {
				this.btnLoading = false;
				this.message.success('修改成功');
				this.close(res);
			}, (err: HttpErrorResponse) => {
				this.btnLoading = false;
				this.message.error(err);
			})
		} else {
			this.btnLoading = true;
			this.adminService.addMemorandum({ ...value }).subscribe(res => {
				this.btnLoading = false;
				this.message.success('创建成功');
				this.close(res);
			}, (err: HttpErrorResponse) => {
        this.close(err);
        // if(err.error.message == 80003){
        //   this.errFlag = true
        // }else{
        //   this.errFlag  =false
        // }
        // if(this.errFlag){
        //   this.message.error('基础版只能创建5个品类');
        //   // alert('彈出')
        //   // this.modalTwo.upgradeModel('店铺')
        // }else{
        //   this.message.error(err)
        // }
				this.btnLoading = false;
				this.message.error(err);
			})
		}
	}
	formInit() {
		this.validateForm = this.fb.group({
			name: [this.name],
			order_price: [this.order_price],
			issuing_price: [this.issuing_price],
			remarks: [this.remarks],
    });
	}
	close(data) {
		this.modal.close(data);
	}
	ngOnInit() {
    console.log(
     this.id,
     this.name,
     this.order_price,
     this.issuing_price,
     this.remarks
    )
		if (this.id !== 'add') {
			this.titleText = '修改';
			// this.adminService.storeDetail(this.id).subscribe(res => {
			// 	this.name = res.name;
			// 	this.formInit();
			// }, (err: HttpErrorResponse) => {
			// 	this.message.error(err);
			// })
		} else {
			this.titleText = '添加';
      this.name = '';
      this.order_price = '';
      this.issuing_price = '';
      this.remarks = '';
		}
		this.formInit();
	}
}
