import { Component, Input, OnInit } from '@angular/core';
import { UploadService } from '@libs/core/src/lib/services/upload.service';
import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';
import { MemberService } from '@libs/core/src/lib/services/member/member.service';
import { DesignParamService } from '@libs/core/src/lib/services/design-param.service';
import { TemplateOfficialService } from '@libs/core/src/lib/services/template/template-official.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
@Component({
  selector: 'app-upload-psd',
  templateUrl: './upload-psd.component.html',
  styleUrls: ['./upload-psd.component.scss'],
  providers: [MemberService]
})
export class UploadPsdComponent implements OnInit {
  @Input() template;
  uploadList = [];
  uploading = false;
  creating = false;
  succesed = false;
  constructor(
    public upload: UploadService,
    public http: HttpClient,
    public modal: ModalService,
    public message: MessageService,
    public templateMember: MemberService,
    public designParam: DesignParamService,
    private tempS: TemplateOfficialService
  ) { }

  ngOnInit() {
  }
  onAddPSDClick() {
    $('#upload-psd').click();
  }
  getStateText(state) {
    if (state === -1) {
      return '上传失败';
    } else if (state === 0 && !this.uploading) {
      return '就绪';
    } else if (state === 0 && this.uploading) {
      return '等待上传';
    } else if (state === 1) {
      return '正在上传..';
    } else if (state === 2) {
      return '已上传';
    } else if (state === -2) {
      return '无效PSD';
    }
  }
  onRemoveClick(idx) {
    this.uploadList.splice(idx, 1);
  }
  async startUpload() {
    if (this.uploadList.length == 0) {
      this.message.error('您当前未添加PSD文件，请添加后再上传模版');
      return;
    }
    this.uploading = true;
    for (const fileItem of this.uploadList) {
      await new Promise<void>((resolve, reject) => {
        fileItem.state = 1;
        this.upload.uploadPSD({
          file: fileItem.file,
          onSuccess: async res => {
            this.http.post('/soft-ware-use/upload-psd-file', {
              filename: res.filename
            }).toPromise().then(psdRes => {
              if (psdRes['pages']) {
                fileItem.state = 2;
                fileItem.data = psdRes;
              } else {
                fileItem.state = -2;
              }
              resolve();
            }).catch(e => {
              fileItem.state = -1;
              resolve();
            });
          },
          onFail: e => {
            fileItem.state = -1;
            resolve();
          }
        });
      });
    }
    this.creating = true;
    // 过滤成功的页
    const pages = this.uploadList.filter(item => item.state === 2);
    if (pages.length > 0) {
      if (pages.length !== this.uploadList.length) {
        alert('部分文件上传失败,已被自动忽略');
      }
      const unitScale = 25.4 / 300;
      const template = {
        props: {
          ...pages[0].data.props,
          unit: 'mm'
        },
        pages: []
      };
      // 转换成毫米单位值
      template.props.width *= unitScale;
      template.props.height *= unitScale;

      for (const fileItem of pages) {
        const pageItem = fileItem.data.pages[0];
        // 转换成毫米单位值
        for (const eleItem of pageItem.elements) {
          eleItem.props.width *= unitScale;
          eleItem.props.height *= unitScale;
          eleItem.props.x *= unitScale;
          eleItem.props.y *= unitScale;
        }
        template.pages.push(pageItem);
      }
      // 重新标注ID
      let id = 0;
      for (const pageItem of template.pages) {
        pageItem.id = id;
        id++;
        for (const eleItem of pageItem.elements) {
          eleItem.id = id;
          id++;
        }
      }
      const param: any = {
        // title: '',
        templateId: this.template.templateId,
        content: JSON.stringify(template),
        // is_diy: 1
      };
      const res = await this.tempS.update(param)
        .toPromise();
      // if (res['templateId']) {
      //   this.templateId = res['templateId'];
      // }
      this.creating = false;
      this.succesed = true;
    } else {
      this.uploading = false;
      this.creating = false;
      alert('上传失败,请检查网络');
    }

  }
  onOpenTemplateClick() {
    const url = this.designParam.getOfficialUrl(this.template.templateId, this.template);
    window.open(url);
  }
  onFileChange() {
    const files = $('#upload-psd')[0].files;
    if (files.length > 0) {
      for (const fileItem of files) {
        // 判断类型
        if (fileItem.size > 300 * 1024 * 1024) {
          // 尺寸判断
          alert('文件最大尺寸为300M,文件已跳过:' + fileItem.name);
        }
      }
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.uploadList.push({
          file,
          //0=排队中 1=上传中 2=已上传 -1=上传失败
          state: 0
        });
      }
    }
  }
}
