import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ClassifyService } from '@libs/core/src/lib/services/template/classify.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';

@Component({
  selector: 'app-template-link',
  templateUrl: './template-link.component.html',
  styleUrls: ['./template-link.component.scss']
})
export class TemplateLinkComponent implements OnInit {
  @Input() template: TemplateOfficial;
  loading = false;

  link: string;

  constructor(
    private modalRef: NzModalRef,
    public classify: ClassifyService,
    private http: HttpClient,
    private message: MessageService
  ) { }

  ngOnInit() {
    this.link = this.template.coopUserUrl;
  }

  submit() {
    if (!this.template || this.link == this.template.coopUserUrl) {
      this.modalRef.close();
      return false;
    }

    this.loading = true;
    this.http.post(
      '/coop-template-official/setting',
      {template_id: this.template.templateId, action: 'url', coop_user_url: this.link}
    ).subscribe(
      () => {
        this.loading = false;
        this.message.success('编辑成功');
        this.modalRef.close('refresh');
      },
      err => {
        this.loading = false;
        this.message.error(err)
      }
    )
  }

  close() {
    this.modalRef.close()
  }

}
