import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-iframe-modal',
  templateUrl: './iframe-modal.component.html',
  styleUrls: ['./iframe-modal.component.css']
})
export class IframeModalComponent implements OnInit {
  @Input() width;
  @Input() height;
  @Input() url;
  iframeUrl;
  constructor(
    private sanitizer: DomSanitizer,
    private modal: NzModalRef
  ) { }

  ngOnInit() {
    let that = this;
    this.iframeUrl = this.safeUrl();
    window.addEventListener('message', function(e) {
      if (e.data.data === 'y') {
        that.modal.close();
      }
    })
  }

  safeUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
