import { Component, OnInit } from '@angular/core';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterUrlService } from '@libs/core/src/lib/services/router-url.service';
import { TemplateService } from '../template/services/template.service';
import { DataProvider } from '@libs/core/src/lib/util/data-provider';
import { TemplateListCallback } from '@libs/core/src/lib/models/list.interface';
import { ClassifyService } from '@libs/core/src/lib/services/template/classify.service';
import {
  ClassifyConfig,
  ClassifyLevel
} from '@libs/core/src/lib/models/classify';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { map, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-spec-list',
  templateUrl: './spec-list.component.html',
  styleUrls: ['./spec-list.component.scss']
})
export class SpecListComponent implements OnInit {
  date = new Date();
  keywords: string;
  callback;
  loading = false;
  data: ClassifyLevel;
  query: { [key: string]: string };

  pid: string;
  pr: string;
  all: string;
  kk: string;

  myURL: SafeResourceUrl;

  cid: string;
  currentSize: ClassifyConfig;
  cp: string;
  sort: string;
  public sortArr = [
    { name: '默认排序', value: undefined},
    { name: '最新上传', value: '1' },
    { name: '最多浏览', value: '10' },
    { name: '最多收藏', value: '20' },
    { name: '最多使用', value: '30' }
  ];
  dataProvider = new DataProvider<TemplateListCallback>();
  constructor(
    public coop: CoopService,
    public routerUrlService: RouterUrlService,
    private routeInfo: ActivatedRoute,
    private router: Router,
    private templateService: TemplateService,
    public classify: ClassifyService,
    private sanitizer: DomSanitizer,
    public modal: ModalService
  ) {}

  get classifyName() {
    if (this.all == '1' || this.pid == this.pr) {
      return this.data.classify.name;
    } else if (this.currentSize) {
      return this.currentSize.name;
    } else {
      return '';
    }
  }

  get PageCount() {
    if (this.all == '1') {
      return '';
    } else if (this.query.page) {
      return this.query.page + 'P';
    } else if (this.currentSize && this.currentSize.page.length === 1) {
      return this.currentSize.page[0] + 'P';
    } else {
      return '';
    }
  }

  ngOnInit() {
    this.all = this.routerUrlService.params.get('all');
    this.pid = this.routerUrlService.params.get('pid');

    // 获取当前父分类
    this.data = this.classify.products.find(
      cls => cls.classify.classifyId == this.pid
    );

    if (!this.data) {
      return false
    }

    this.routeInfo.params
      .pipe(
        tap(() => (this.loading = true)),
        switchMap(url =>
          this.templateService.getTemplateList(
            url.query.replace(/\.html/g, '-l-32').replace('kk-', 'k-')
          )
        ),
        map(data => this.dataProvider.prepare(data))
      )
      .subscribe(res => {
        this.loading = false;
        if (res.status === 'y') {
          this.callback = res.data;
        }
      });

    this.routeInfo.queryParams.subscribe(data => (this.query = data));

    this.routerUrlService.data$.subscribe(data => {
      let pr = data.get('pr'), cid = data.get('cid'), cp = data.get('cp');
      const classifyConfig = this.data && this.data.classifyConfig;
      if ((!pr || !cid) && classifyConfig) {
        const firstChild = classifyConfig[0];
        const url = this.routerUrlService.urlParamStatic([
          { name: 'pr', value: firstChild.classifyId },
          { name: 'cid', value: firstChild.id },
          {
            name: 'cp',
            value:
              !(this.query && this.query.page) && firstChild.page.length > 1
                ? firstChild.page[0]
                : ''
          },
          { name: 'p', value: '' }
        ]);
        if (this.routerUrlService.url == 'spec-list')
          this.router.navigate(['/spec-list', url], {
            queryParams: this.query,
            replaceUrl: true
          });
      }

      this.currentSize = classifyConfig.find(item => item.classifyId == pr && item.id == cid);
      if (!this.currentSize) return;

      this.cid = cid;
      this.pr = pr;
      this.cp = data.get('cp');

      if (
        !(this.query && this.query.page) && !this.cp &&
        this.currentSize.page.length > 1
      ) {
        const url = this.routerUrlService.urlParamStatic([
          { name: 'cp', value: this.currentSize.page[0] },
          { name: 'p', value: '' }
        ]);
        if (this.routerUrlService.url == 'spec-list')
          this.router.navigate(['/spec-list', url], {
            queryParams: this.query,
            replaceUrl: true
          });
      }

      this.kk = data.get('kk');
      this.sort = data.get('o');
      const k = data.get('k');
      if (k !== '0' && this.keywords !== k) {
        this.keywords = k;
      }
    });

    /*this.routerUrlService.getKey('pr').subscribe(pr => {
      if (!pr && this.data && this.data.classifyConfig.length > 0) {
        const url = this.routerUrlService.urlParamStatic([{name: 'pr',  value: this.data.classifyConfig[0].classifyId}, { name: 'p', value: '' }]);
        this.router.navigate(['/spec-list', url], {replaceUrl: true})
      }
      this.pr = pr
    });

    this.routerUrlService.getKey('kk').subscribe(data => this.kk = data);
    this.routerUrlService.getKey('k').pipe(
      filter(data => data !== '0' && this.keywords !== data),
    ).subscribe(data => this.keywords = data);*/
  }

  activeState(temp: ClassifyConfig): boolean {
    if (this.pr != temp.classifyId.toString()) {
      return false;
    }
    const judge = this.classify.no_required_query.every(val => {
      if (!this.query[val]) return true;
      return temp[val] == this.query[val];
    });

    return judge;
  }

  setPage(page) {
    this.routerUrlService
      .getUrlParam([{ name: 'p', value: page }])
      .pipe(take(1))
      .subscribe(data => {
        this.router.navigate(['/spec-list', data], { queryParams: this.query });
      });
  }

  openQQ(qq: string) {
    window.open(`tencent://message/?Site=baidu.com&uin=${qq}&Menu=yes`);
  }
  openWW(ww: string) {
    this.myURL = this.sanitizer.bypassSecurityTrustResourceUrl(
      `http://amos.alicdn.com/getcid.aw?v=2&uid=${ww}&site=cntaobao&s=1&groupid=0&charset=utf-8`
    );
  }
}
