import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { EditorService } from '../../../../../../../services/editor.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
@Component({
  selector: 'app-combination-distance-tool',
  templateUrl: './combination-distance-tool.component.html',
  styleUrls: ['./combination-distance-tool.component.scss']
})
export class CombinationDistanceToolComponent implements OnInit {
  spaceVertical = 0;
  spaceHorizontal = 0;
  element;
  constructor(
    public editor: EditorService,
    public message:MessageService
  ) { }

  ngOnInit() {
    this.element = this.editor.activeElements[0];
    this.spaceVertical = this.element.options.spaceVertical;
    this.spaceHorizontal = this.element.options.spaceHorizontal;
  }
  /**
   * 上下间距被修改
   * @param value 新字间距
   */
  verticalSpaceChange(value) {
    this.spaceVertical = value;
    this.element.options.spaceVertical = value
    this.element.emit('modified', {
      type: 'space',
      target: this.element
    });
  }

  /**
   * 左右间距被修改
   * @param value 新行距
   */
  horizontalSpaceChange(value) {
    this.spaceHorizontal = value;
    this.element.options.spaceHorizontal = value;
    this.element.emit('modified', {
      type: 'space',
      target: this.element
    });
  }
}
