import { ToolBarSvgComponent } from '../../components/editor-ui/t-tool-bar/tools/tool-bar-svg/tool-bar-svg.component';
import { Element } from '../base/element';
import { Image } from './image';
import * as $ from 'jquery';
import * as Color from 'color';
import { monthSignData } from '@libs/editor/src/lib/utils/calender/holidayData';
import dateTOLunarCalendar from '../../utils/calender/calendar.js';
import {
  replace,
  getSVGPixelBase64Data,
  getSvgColors,
  getStrokeSvgColors,
  getStrokeWidth,
  getUnitScale
} from '../../utils/misc/common';
import { __await } from 'tslib';

/**
 * SVG元素
 * @export
 * @class Svg
 * @extends {Element}
 */
export class Svg extends Element {
  public type = 'svg';

  async onCreated() {
    this.config.update = true;
    this.config.toolbar = ToolBarSvgComponent;
    // 如果是填充图章,则修改角点显示
    if (this.options.pattern) {
      switch (this.options.pattern.type) {
        case 'h':
          this.config.corner = {
            tl: true,
            tr: true,
            ml: true,
            mr: true,
            bl: true,
            br: true,
            mt: false,
            mb: false,
            rotate: true,
            quick: true
          };
          break;
        case 'v':
          this.config.corner = {
            tl: true,
            tr: true,
            ml: false,
            mr: false,
            bl: true,
            br: true,
            mt: true,
            mb: true,
            rotate: true,
            quick: true
          };
          break;
        default:
          this.config.corner = {
            tl: true,
            tr: true,
            ml: true,
            mr: true,
            bl: true,
            br: true,
            mt: true,
            mb: true,
            rotate: true,
            quick: true
          };
          break;
      }
    } else {
      this.config.corner = {
        tl: true,
        tr: true,
        ml: true,
        mr: true,
        bl: true,
        br: true,
        mt: true,
        mb: true,
        rotate: true,
        quick: true
      };
    }
    this.on('cornermousedown', e => {
      // 如果是九宫格元素而且正在等比缩放
      if (
        e.corner === 'tl' ||
        e.corner === 'tr' ||
        e.corner === 'bl' ||
        e.corner === 'br'
      ) {
        this.states.beforeScaleWidth = this.props.width;
        if (this.options.scale9Grid) {
          this.states.beforeGridScale = this.options.scale9Grid.scale;
        } else if (this.options.pattern) {
          this.states.beforePatternScale = this.options.pattern.scale;
        }
      }
    });
    this.on('scaling', e => {
      // 如果是九宫格元素而且正在等比缩放
      if (
        e.corner === 'tl' ||
        e.corner === 'tr' ||
        e.corner === 'bl' ||
        e.corner === 'br'
      ) {
        if (this.options.scale9Grid) {
          this.options.scale9Grid.scale =
            this.states.beforeGridScale *
            (this.props.width / this.states.beforeScaleWidth);
        } else if (this.options.pattern) {
          this.options.pattern.scale =
            this.states.beforePatternScale *
            (this.props.width / this.states.beforeScaleWidth);
        }
      }
    });
    //this.states.html = this.toHTML();
    // 元素修改完成后将svg转换为像素图片以优化性能
    // this.on('modified', this.updateToPixel.bind(this));
    // 加载元素
    await this.loadSvg();
  }

  async loadSvg() {
    // 加载svg图片
    if (this.options.url.indexOf('.svg') == -1){
      return "";
    }
    let svg = await window['http'].get(this.options.url + '?' + new Date().getTime(), {responseType: 'text'}).toPromise();
    if (!svg) {
      this.states.html = this.toHTML();
      return ;
    }
    //匹配无用标签
    let foreignReg = new RegExp('<foreignObject([\\s\\S]*)</foreignObject>');
    let pgfReg = new RegExp('<i:pgf id=([\\s\\S]*)</i:pgf>');
    svg = svg.toString().replace(foreignReg,'');
    svg = svg.toString().replace(pgfReg,'');
    let $svg;
    // 替换rgb颜色
    const rgbRxp = /[rR][gG][Bb][Aa]?[\(]([\s]*(2[0-4][0-9]|25[0-5]|[01]?[0-9][0-9]?),){2}[\s]*(2[0-4][0-9]|25[0-5]|[01]?[0-9][0-9]?),?[\s]*(0\.\d{1,2}|1|0)?[\)]{1}/g;
    svg = svg.replace(rgbRxp, m => {
      const color = Color(m);
      if (color.alpha() !== 1) {
        return m;
      }
      return color.hex();
    });
    // 取出SVG数据,去除描述信息
    const $svgData = $(svg);
    for (let i = 0; i < $svgData.length; i++) {
      const item = $svgData[i];
      if (item.nodeName === 'svg') {
        $svg = $(item);
        break;
      }
    }
    // 设置缩放属性
    $svg.attr('width', '100%');
    $svg.attr('height', '100%');
    $svg.attr('preserveAspectRatio', 'none');
    //有字体强制覆盖为微软雅黑
    if ((!this.options.hasOwnProperty('isCalendarTitle')||!this.options.isCalendarTitle)&&this.props.sign != '页码'){
      $svg.find('text, tspan').each(function () {
        let $this = $(this);
        $this.css('font-family') && $this.css('font-family', 'font_2');
        $this.attr('font-family') && $this.attr('font-family', 'font_2');
      });
    }else{
      //台历标题提取文字列表
      let svgHtml = '';
      $svg.find('text, tspan').each(function () {
        let $this = $(this);
        if ($this.attr('font-family')){
          svgHtml += $this.attr('font-family')+',';
        }
      });
      const svgFonts = svg.match(/font_\d+/g);
      if (svgFonts) {
        svgHtml += svgFonts.join(",")
      }
      this.options.svgHtml = svgHtml;
    }
    // 查找没有fill属性又没有style又没有class的元素(解决颜色不识别问题)
    $svg.find('path,rect,circle,polygon,ellipse,polyline').each(function() {
      const $this = $(this);
      if (
        !$this.parents('g[style],g[fill],g[class]').length &&
        $this.not('[style],[fill],[class]').length &&
        !$this.parents('defs').length
      ) {
        $this.attr('fill', '#000000')
      }
    })

    svg = $svg[0].outerHTML;
    // 替换hex的简化形式,替换为完整的hex (例如:#000 => #000000)
    svg = svg.replace(/#([0-9a-fA-F]{3})(?![0-9a-fA-F]){3}/g, (m, p1) => {
      return '#' + p1 + p1;
    });
    svg = svg.replace(/#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})/g, (m, p1) => {
      return m.toLowerCase();
    });
    // 将颜色全部转换为小写

    // 处理ID重复问题
    svg = svg.replace(/url\(#(.*?)\)/g, (m, p1) => {
      return `url(#${this.id + '-' + p1})`;
    });
    svg = svg.replace(/id="(.*?)"/g, (m, p1) => {
      return `id="${this.id + '-' + p1}"`;
    });
    svg = svg.replace(/xlink:href="#(.*?)"/g, (m, p1) => {
      return `xlink:href="#${this.id + '-' + p1}"`;
    });
    // 解决class冲突问题
    const classes = [];
    const re = /class="([A-z0-9_-]+(?:\s[A-z0-9_-]+)?)"/g;
    let regRes: any = true;
    while (regRes) {
      regRes = re.exec(svg);
      if (regRes) {
        if (!classes.find(item => item === regRes[1])) {
          classes.push(regRes[1]);
        }
      }
    }
    for (const item of classes) {
      svg = svg.replace(new RegExp(`class="${item}"`, 'g'), `class="${item.split(/\s+/).map(cls => `class-${this.id}-${cls}`).join(' ')}"`);
      item.split(/\s+/).forEach(cls => {
        svg = svg.replace(new RegExp(`\\.${cls}`, 'g'), `.class-${this.id}-${cls}`);
      })
    }
    this.states.svg = svg;
    // 分析颜色信息
    this.states.oColors = getSvgColors(svg);
    this.states.oStrockColors = getStrokeSvgColors(svg);
    let strokeWidthRegex =  /stroke-width="([^"]+)/;
    let strokeWidth = svg.match(strokeWidthRegex);
    if (strokeWidth && strokeWidth.length > 1) {
      this.states.strockWidth = strokeWidth[1]
    }
    //this.states.strockWidth = getStrokeWidth(svg);
    //le.log('this.states',this.states);
    //console.log('this.options',this.options)

    this.states.html = this.toHTML();
    return this.states.html;
  }

  /**
   * 更新svg为像素数据,以用于优化性能
   */
  public updateToPixel() {
    const unitScale = this.parent.parent.scale;
    if (!(this.props.filter.shadow && this.props.filter.shadow.use)) {
      const width = this.props.width * unitScale;
      const height = this.props.height * unitScale;
      if (width < 3000 && height < 3000 && (width > 50 && height > 50)) {
        getSVGPixelBase64Data({
          svg: this.toHTML(),
          width,
          height
        }).then(base64 => {
          this.states.html = `<img style="position: absolute;pointer-events:none" width="100%" height="100%" src="${base64}"/>`;
        });
      }
    }
  }
  /**
   * 重写:获取当前元素的html结果
   * @returns {string} 根据元素信息生成的html
   */
  public toHTML(type?): string {
    const id = this.id,
      props = this.props,
      options = this.options;

    if (this.states.svg) {
      let svg = this.states.svg;
      // 替换颜色(只支持HEX颜色的替换)
      for (const colorItem of this.options.colors) {
        // 替换颜色
        svg = svg.replace(
          new RegExp(`\(\\W\)${colorItem.oColor.toLowerCase()}\(\\W\)`, 'gmi'),
          ($1, $2, $3) => {
            return $2 + colorItem.nColor.toUpperCase() + $3
          }
          /*new RegExp(`\\W\(${colorItem.oColor.toLowerCase()}\\W`, 'gmi'),
          new RegExp(colorItem.oColor.toLowerCase(), 'gmi'),
          colorItem.nColor.toUpperCase()*/
        );
      }
      // 九宫格或填充图案处理
      //console.log('this.options',this.options)
      if (this.options.scale9Grid) {
        // 九宫格处理
        const grid = options.scale9Grid,
          scale = grid.scale,
          svgWidth = props.width / scale,
          svgHeight = props.height / scale;
        svg = `<svg style="position: absolute;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 ${
          props.width
          } ${props.height}">
                        <defs>
                            <symbol id="${id}-grid-shape">
                            ${svg}
                            </symbol>
                        </defs>
                        <svg class="grid-1" width="${grid.x * scale}" height="${grid.y *
          scale}" viewBox="0 0 ${grid.x} ${grid.y}" preserveAspectRatio="none">
                            <use width="${props.oWidth}" height="${
          props.oHeight
          }" xlink:href="#${id}-grid-shape"/>
                        </svg>
                        <svg class="grid-2"
                        x="${grid.x * scale}"
                        width="${this.limitMin(
            (svgWidth -
              (props.oWidth - grid.x - grid.width) -
              grid.x) *
            scale
          )}"
                        height="${this.limitMin(grid.y * scale)}"
                        viewBox="${grid.x} 0 ${props.oWidth -
          grid.x -
          grid.width} ${grid.y}"
                        preserveAspectRatio="none">
                            <use width="${props.oWidth}" height="${
          props.oHeight
          }" xlink:href="#${id}-grid-shape"/>
                        </svg>
                        <svg class="grid-3" x="${(svgWidth -
            (props.oWidth - grid.x - grid.width)) *
          scale}"
                          width="${this.limitMin(
            (props.oWidth - grid.x - grid.width) * scale
          )}"
                          height="${this.limitMin(grid.y * scale)}"
                          viewBox="${grid.x + grid.width} 0 ${props.oWidth -
          grid.x -
          grid.width} ${grid.y}"
                          preserveAspectRatio="none">
                            <use width="${props.oWidth}" height="${
          props.oHeight
          }" xlink:href="#${id}-grid-shape"/>
                        </svg>
                        <svg class="grid-4" y="${grid.y * scale}"
                         width="${this.limitMin(grid.x * scale)}"
                         height="${this.limitMin(
            (svgHeight -
              (props.oHeight - grid.y - grid.height) -
              grid.y) *
            scale
          )}"
                         viewBox="0 ${grid.y} ${grid.x} ${
          grid.height
          }" preserveAspectRatio="none">
                            <use width="${props.oWidth}" height="${
          props.oHeight
          }" xlink:href="#${id}-grid-shape"/>
                        </svg>
                        <svg class="grid-5" x="${grid.x * scale}" y="${grid.y * scale}"
                          width="${this.limitMin(
            (svgWidth -
              (grid.x + (props.oWidth - grid.x - grid.width))) *
            scale
          )}"
                          height="${this.limitMin(
            (svgHeight -
              (props.oHeight - grid.y - grid.height) -
              grid.y) *
            scale
          )}"
                          viewBox="${grid.x} ${grid.y} ${grid.width} ${
          grid.height
          }"
                          preserveAspectRatio="none">
                            <use width="${props.oWidth}" height="${
          props.oHeight
          }" xlink:href="#${id}-grid-shape"/>
                        </svg>
                        <svg class="grid-6"
                         x="${(svgWidth -
            (props.oWidth - grid.width - grid.x)) *
          scale}"
                         y="${grid.y * scale}"
                         width="${this.limitMin((props.oWidth - grid.x - grid.width) * scale)}"
                         height="${this.limitMin((svgHeight - (props.oHeight - grid.y - grid.height) - grid.y) * scale)}"
                         viewBox="${grid.x + grid.width} ${
          grid.y
          } ${props.oWidth - grid.x - grid.width} ${grid.height}"
                         preserveAspectRatio="none">
                            <use width="${props.oWidth}" height="${
          props.oHeight
          }" xlink:href="#${id}-grid-shape"/>
                        </svg>
                        <svg class="grid-7"
                         y="${(svgHeight -
            (props.oHeight - grid.y - grid.height)) *
          scale}"
                         width="${this.limitMin(grid.x * scale)}"
                         height="${this.limitMin(
            (props.oHeight - grid.y - grid.height) * scale
          )}"
                         viewBox="0 ${grid.y + grid.height} ${
          grid.x
          } ${props.oHeight - grid.y - grid.height}"
                         preserveAspectRatio="none">
                            <use width="${props.oWidth}" height="${
          props.oHeight
          }" xlink:href="#${id}-grid-shape"/>
                        </svg>
                        <svg class="grid-8"
                         x="${grid.x * scale}"
                         y="${(svgHeight -
            (props.oHeight - grid.y - grid.height)) *
          scale}"
                         width="${this.limitMin(
            (svgWidth -
              (props.oWidth - grid.x - grid.width) -
              grid.x) *
            scale
          )}"
                         height="${this.limitMin(
            (props.oHeight - grid.y - grid.height) * scale
          )}"
                         viewBox="${grid.x} ${grid.y + grid.height} ${
          grid.width
          } ${props.oHeight - grid.y - grid.height}"
                         preserveAspectRatio="none">
                            <use width="${props.oWidth}" height="${
          props.oHeight
          }" xlink:href="#${id}-grid-shape"/>
                        </svg>
                        <svg class="grid-9" x="${(svgWidth -
            (props.oWidth - grid.x - grid.width)) *
          scale}"
                         y="${(svgHeight -
            (props.oHeight - grid.y - grid.height)) *
          scale}"
                         width="${this.limitMin(
            (props.oWidth - grid.x - grid.width) * scale
          )}"
                         height="${this.limitMin(
            (props.oHeight - grid.y - grid.height) * scale
          )}"
                         viewBox="${grid.x + grid.width} ${grid.y +
          grid.height} ${props.oWidth - grid.x - grid.width} ${props.oHeight -
          grid.y -
          grid.height}"
                         preserveAspectRatio="none">
                            <use width="${props.oWidth}" height="${
          props.oHeight
          }" xlink:href="#${id}-grid-shape"/>
                        </svg>
                    </svg>
                </svg>`;
      } else if (this.options.pattern) {
        const scale = this.options.pattern.scale;
        const unitScale = this.parent.parent.scale;
        // 填充图章处理
        svg = `<svg style="position: absolute;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${props.width *
          unitScale}" height="${props.height *
          unitScale}" preserveAspectRatio="none" viewBox="0 0 ${props.width} ${
          props.height
          }">
                <defs>
                    <pattern id="${id}-pattern-shape" width="${props.oWidth *
          scale}" height="${props.oHeight * scale}" viewBox="0 0 ${
          props.width
          } ${
          props.height
          }" preserveAspectRatio="none" patternUnits="userSpaceOnUse">
                    ${svg}
                    </pattern>
                </defs>
                <rect width="${props.width}" height="${
          props.height
          }" fill="url(#${id}-pattern-shape)"></rect>
                </svg>`;
      } else {
        svg = svg.replace('<svg ', '<svg style="position:absolute" ');
        let newViewBox = '';
        let regex = /viewBox="[^"]*"/;
        let widthregex = /stroke-width="[^"]*"/g;
        let strokeWidthRegex =  /stroke-width="([^"]+)/;
        let strokeWidth = svg.match(strokeWidthRegex);
        if (strokeWidth && strokeWidth.length > 1) {
          let value = strokeWidth[1];
          if(this.states.strockWidth || this.states.strockWidth === 0) {
            value = this.states.strockWidth;
          }
          newViewBox = String(-value) + ' ' + String(-value) + ' ' +  (Number(props.oWidth) + Number(2*value))  + ' ' + (Number(props.oHeight) + Number(2*value));
          svg = svg.replace(regex, `viewBox="${newViewBox}"`);
          svg = svg.replace(widthregex, `stroke-width="${value}"`);
          //console.log(value); // 输出: value123
        }


      }
      if(options.hasOwnProperty('isCalendarTitle') && options.isCalendarTitle){
        let month = options.month;
        let year = options.year;
        const sign = {
          '大写中文月份':monthSignData.bigCNMonthList[month-1],
          '中文月份':monthSignData.CNMonthList[month-1],
          '英文月份':monthSignData.completeENMonthList[month-1],
          '英文月份大写':monthSignData.bigCompleteENMonthList[month-1],
          '英文月份简写':monthSignData.simpleENMonthList[month-1],
          '数字月份':month,
          '数字年份':year,
          '农历年份': dateTOLunarCalendar.toGanZhiYear(year) + dateTOLunarCalendar.getAnimal(year),
          '补0月份':monthSignData.numberZeroMonthList[month-1]
        };
        let signArr = ['大写中文月份','中文月份','英文月份','英文月份大写','英文月份简写','数字月份','数字年份','农历年份','补0月份'];
        let $titleSvg = $(svg);
        signArr.forEach(item=>{
          if ($titleSvg.find("#"+id+"-"+item)){
            $titleSvg.find("#"+id+"-"+item).text(sign[item]);
          }
        });
        svg =  $titleSvg[0].outerHTML;
      }
      if(this.props.hasOwnProperty('sign') && this.props.sign === '页码'){
        let pageNumber = options.pageNumber;
        let $svg = $(svg);
        $svg.find("#"+id+"-页码").text(pageNumber);
        svg =  $svg[0].outerHTML;
      }
      if(type === 'outline') {
        this.options.svg = svg;
      }

      return svg;
    } else {
      return '';
    }
  }

  toImage() {
    const { parent, id, props, options } = this;

    options.clip = {
      x: 0,
      y: 0,
      width: this.props.oWidth,
      height: this.props.oHeight
    };


    const image = new Image({
      id: window['editor'].getNewID(),
      props, options
    });
    const index = this.parent.elements.indexOf(this);

    if (index > -1) {
      this.parent.insertElement(image, index, false);
      this.remove(false);
      delete image.options.colors;
    }

    return image;
  }

  /**
   * 限制最小数值,当num小于0则返回0
   * @param num 数值
   */
  limitMin(num) {
    return num < 0 ? 0 : num;
  }


// 创建线性渐变
  createLinearGradient(svg, x1, y1, x2, y2) {
    let gradient = svg.append("linearGradient")
        .attr("id", "linear-gradient")
        .attr("x1", x1)
        .attr("y1", y1)
        .attr("x2", x2)
        .attr("y2", y2);

    gradient.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", "red");

    gradient.append("stop")
        .attr("offset", "100%")
        .attr("stop-color", "blue");

    return gradient;
  }
}
