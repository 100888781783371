import { Component, Input, OnInit ,OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthState } from '@libs/core/src/lib/+state/auth.reducer';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Login, Logout } from '@libs/core/src/lib/+state/auth.actions';
import {
  ModalOpenAccount
} from '../../../../../../apps/frontend/src/app/+state/app.actions';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { selectAppVersion } from '../../../../../../apps/frontend/src/app/+state/app.reducer';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppService } from '@libs/core/src/lib/services/app.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { NoticeService } from '@libs/core/src/lib/services/notice/notice.service';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import * as Color from 'color';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
// 合并覆盖测试3

@AutoUnsubscribe()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() sharename: string;
  @Input() style: any;
  url = '';
  version: string;
  hostUrl: string;
  iscolor = false;
  constructor(
    public coopService: CoopService,
    public appService: AppService,
    public authService: AuthService,
    public noticeService: NoticeService,
    private store: Store<AuthState>,
    private modal: ModalService,
    private router: Router,
    private message: MessageService,
    public route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    // var color1 = Color('#ffffff');
    // var colors = Color('#e0e0e0');
    var coopcolor = this.coopService.coopInfo.color;
    var color = Color(coopcolor);
    if (color.hsl().object().l > 80) {
      this.iscolor = true;
    } else {
      this.iscolor = false;
    }

    if (this.coopService.coopInfo.id == 12320) {
      this.hostUrl = `shop${this.coopService.coopInfo.id}.tubangzhu.net`
    } else if (this.coopService.coopInfo.domain) {
      this.hostUrl = this.coopService.coopInfo.domain;
    } else {
      this.hostUrl = window.location.host;
    }
    this.url = this.router.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });

    this.store.select(selectAppVersion).subscribe(data => {
      this.version = data;
    });

    // 查询消息
    // this.noticeService.load();

    if (this.route.queryParams['value'].open === 'setting') {
      this.openAccount();
    }
  }

  get linkBtnStyle() {
    return this.coopService.style ? {
      'color': this.coopService.coopInfo.fontColor,
      ...this.coopService.style.header_link_btn
    } : {'color': this.coopService.coopInfo.fontColor}
  }

  get qrUrl() {
    if (this.coopService.coopInfo.id == 12718) {
      return 'http://mmzj.youdianpinwei.com/dist/xz.html'
    } else {
      //return 'http://m-' + this.hostUrl
      return 'http://' + this.hostUrl
    }
  }

  logout(): void {
    this.store.dispatch(new Logout());
  }

  isActive(route: string): boolean {
    const reg = new RegExp('^' + route);
    const url = this.url.split('?')[0];
    return url.match(reg) !== null && url !== '/template/home';
  }

  login(): void {
    this.store.dispatch(new Login());
  }

  openAccount(): void {
    this.store.dispatch(new ModalOpenAccount('account'));
  }
  openVip(){
    this.modal.showBuyVipS()
  }

  ngOnDestroy():void{

  }
}
