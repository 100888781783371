import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  @Input() title: string;
  @Input() success: (data) => void;
  @Input() type: string;

  deleteTemplate: boolean = false;

  renew: string = '1';

  constructor(
    private modal: NzModalRef
  ) { }

  ngOnInit() {
  }

  ok() {
    if (this.type === 'folder') {
      this.success(this.deleteTemplate);
    } else if (this.type === 'renew') {
      this.success(this.renew)
    } else {
      this.success('')
    }

    this.cancel(true);
  }

  cancel(ok = false) {
    this.modal.destroy(ok);
  }

}
