import { Component, forwardRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { VipService } from '@libs/core/src/lib/services/vip.service';
import { VipVersion } from '@libs/core/src/lib/models/vip-version';
import { PayService } from '@libs/shared/src/lib/services/pay.service';
import { Subscription } from 'rxjs';
import { Order } from '@libs/core/src/lib/models/order';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { PromoCode } from '@libs/core/src/lib/models/promo-code';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { Env } from '@libs/core/src/lib/util/environment';

@AutoUnsubscribe()
@Component({
  selector: 'app-buy-vip',
  templateUrl: './buy-vip.component.html',
  styleUrls: ['./buy-vip.component.scss'],
  providers: [
    PayService,
    VipService
  ]
})
export class BuyVipComponent implements OnInit, OnDestroy {
  @Input() version = 'team';
  @Input() times = 'year';
  @Input() isSale = false;
  nowTime: number;
  sumPriceSale: number;
  sumPrice: number;
  isAllowPay = false;
  loading = false;
  isSuccess = false;
  order: Order;
  saleCodeLoading = false;
  isshow = false;
  saleCode: string;
  promoCode: PromoCode;
  saleRef: NzModalRef;
  public versions: VipVersion[];
  public choseVersion: VipVersion;

  payment: string;
  paySubscription: Subscription;

  constructor(
    public authService: AuthService,
    private vipService: VipService,
    public payService: PayService,
    private messageService: MessageService,
    @Inject(forwardRef(() => NzModalService))
    private modalService,
    private modal: NzModalRef,
    public coop: CoopService,
    public env: Env
  ) {
  }

  ngOnDestroy() {
  }

  ngOnInit() {
    if (this.coop.coopInfo.id == '12864') {
      this.times = 'month';
    } else {
      this.times = 'year';
    }
    this.nowTime = (Date.parse(new Date().toString())) / 1000;
    this.payService.modalWechat = true;
    this.versions = this.vipService.versions;
    this.changeVersion(this.version);
    this.showPrompt();
  }

  /**
   * 改变选择版本
   * @param {string} version
   */
  changeVersion(version: string) {
    const index = this.versions.findIndex(item => item.name === version);
    this.choseVersion = this.versions[index];
    this.version = this.choseVersion.name;
    this.doSumPrice();
  }

  /**
   * 改变时长
   * @param {string} times
   */
  changeTimes(times: string) {
    this.times = times;
    this.doSumPrice();
  }

  doSumPrice() {
    this.paySubscription && this.paySubscription.unsubscribe();
    if (this.times === 'month') {
      this.sumPriceSale = this.choseVersion.price;
      if (this.isSale || ((this.authService.user.personalExpired - this.nowTime) > 0 && (this.authService.user.personalExpired - this.nowTime) < 432000)) {
        this.sumPriceSale = this.sumPriceSale - 5;
      }
      this.sumPrice = 0;
    } else {
      this.sumPriceSale = this.choseVersion.price * 12 * this.choseVersion.sale;
      this.sumPrice = this.choseVersion.price * 12;
      if (this.isSale) {
        this.sumPriceSale = this.sumPriceSale - 50;
      }
    }

    if (this.promoCode && this.promoCode.promoMoney) {
      this.sumPriceSale = this.sumPriceSale - parseInt(this.promoCode.promoMoney, 10);
      if (this.sumPriceSale < 0) this.sumPriceSale = 0;
    }
  }

  pay() {
    //const times = this.times === 'year' ? 12 : 1;
    let type;
    if (this.coop.coopInfo.id === '12864' || this.coop.coopInfo.id == this.env.my_photo_id) {
      if (this.times === 'year') {
        type = 1;
      } else if (this.times === 'halfYear') {
        type = 2;
      } else if (this.times === 'quarter') {
        type = 3;
      } else if (this.times === 'month') {
        type = 4;
      }
    } else {
      type = 1;
    }
    this.paySubscription = this.payService
      .buyVip(type)
      .subscribe(
        data => {
          if (!data) return;
          this.order = data;
          // 购买成功
          this.authService.updateUserInfo()
            .subscribe(data => {
              this.authService.updateUser(data);
            });
          this.isSuccess = true;
        },
        err => {
          this.payService.loading = false;
          this.messageService.error(err);
        }
      );
  }

  close() {
    this.modal.destroy();
  }

  /**
   * 选择支付方式
   * @param {string} name
   */
  chosePayment(name: string): void {
    this.paySubscription && this.paySubscription.unsubscribe();
    this.payService.payment = name;
    this.isAllowPay = false;
  }

  showPrompt(): void {
    if (!this.authService.isGuest) {
      if (((this.authService.user.personalExpired - this.nowTime) > 0) && ((this.authService.user.personalExpired - this.nowTime) < 432000)) {
        this.isshow = true;
      } else {
        this.isshow = false;
      }
    }
  }
}
