import { Component, OnInit, forwardRef, Inject, Input } from '@angular/core';
import { UploadService } from '@libs/core/src/lib/services/upload.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '../../../../../../core/src/lib/services/modal.service';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
@Component({
  selector: 'app-kejie-apply',
  templateUrl: './kejie-apply.component.html',
  styleUrls: ['./kejie-apply.component.scss']
})
export class KejieApplyComponent implements OnInit {
  @Input() template;
  lLoading = false;
  fLoading = false;
  bLoading = false;
  radioCheck = 'license';
  lImgInfo = {
    file_path: '',
    file_id: null
  };
  fImgInfo = {
    file_path: '',
    file_id: null
  };
  bImgInfo = {
    file_path: '',
    file_id: null
  };
  constructor(
    @Inject(forwardRef(() => ModalService))
    public modalService,
    public upload: UploadService,
    private message: MessageService,
    public http: HttpClient,
    private modal: NzModalRef,
    public auth: AuthService
  ) { }

  fileUpload = item => {
    return this.upload.upload(item, 0, 'frontend', 'normal');
  };

  updateImage(info: { file: NzUploadFile }, type) {
    if (info.file.status === 'uploading') {
      if (type === 'license') {
        this.lLoading = true;
      } else if (type === 'front') {
        this.fLoading = true;
      } else {
        this.bLoading = true;
      }
      return;
    }
    if (info.file.status === 'done') {
      if (type === 'license') {
        this.lLoading = false;
        this.lImgInfo.file_path = info.file.response.filePath;
        this.lImgInfo.file_id = info.file.response.fileId;
      } else if (type === 'front') {
        this.fLoading = false;
        this.fImgInfo.file_path = info.file.response.filePath;
        this.fImgInfo.file_id = info.file.response.fileId;
      } else {
        this.bLoading = false;
        this.bImgInfo.file_path = info.file.response.filePath;
        this.bImgInfo.file_id = info.file.response.fileId;
      }
    }
  }

  delImg(imgInfo) {
    imgInfo.file_path = '';
    imgInfo.id = null;
  }

  async submit() {
    // this.auth.updateUserInfo().subscribe();
    let data;
    if (this.radioCheck === 'license') {
      if (!this.lImgInfo.file_id) {
        this.message.warning('未上传营业执照');
        return
      } else {
        data = {
          business_img_id: this.lImgInfo.file_id
        }
      }
    }
    if (this.radioCheck === 'identity') {
      if (!this.fImgInfo.file_id) {
        this.message.warning('未上传身份证正面');
        return
      }
      if (!this.bImgInfo.file_id) {
        this.message.warning('未上传身份证反面');
        return
      }
      if (this.fImgInfo.file_id && this.bImgInfo.file_id) {
        data = {
          card_img_id: this.fImgInfo.file_id,
          card_back_img_id: this.bImgInfo.file_id
        }
      }
    }
    let res = await this.http.post(`/cooperation/submit-member-info`, data).toPromise();
    if (res) {
      this.auth.updateUserInfo().subscribe(res => {
        this.auth.updateUser(res);
      });
      this.modal.close();
      this.modalService.showNoVipUser(this.template, 'kejie');
    }
  }

  ngOnInit() {
  }

}
