import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TemplateDetailService } from './template-detail.service';
import { DesignParamService } from '@libs/core/src/lib/services/design-param.service';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { CodeService } from '@libs/core/src/lib/services/code.service';
import { ModalService } from '@libs/core/src/lib/services/modal.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-template-detail',
  templateUrl: './template-detail.component.html',
  styleUrls: ['./template-detail.component.scss'],
  providers: [TemplateDetailService]
})
export class TemplateDetailComponent implements OnInit, OnDestroy {
  @Input() template: TemplateOfficial;
  @Input() manageService: any;
  @Output() delete = new EventEmitter<{status: string, data: TemplateOfficial}>();

  favoriteSubscription: Subscription;

  constructor(
    public authService: AuthService,
    private modal: ModalService,
    private templateService: TemplateDetailService,
    private message: NzMessageService,
    private code: CodeService,
    private designParamService: DesignParamService
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  /**
   * 收藏模版
   * @param {TemplateOfficial} template
   */
  favoriteTemplate(template: TemplateOfficial): void {
    if (template.isFavorite) {
      // 设置为已收藏
      template.isFavorite = 0;
      this.delete.emit({status: 'success', data: template});
      this.favoriteSubscription = this.templateService
        .unFavTemplate(template.templateId)
        .subscribe(
          () => {
            this.delete.emit({status: 'success', data: template});
            this.message.success('已取消收藏!');
          },
          err => {
            template.isFavorite = 1;
            this.message.error(this.code.getOne(err));
            this.delete.emit({status: 'error', data: template});
          }
        );
    } else {
      // 设置为已收藏
      template.isFavorite = 1;
      this.favoriteSubscription = this.templateService
        .favTemplate(template.templateId)
        .subscribe(
          () => {
            this.message.success('模版收藏成功!');
          },
          err => {
            template.isFavorite = 0;
            this.message.error(this.code.getOne(err));
          }
        );
    }
  }

  /**
   * Vip 编辑
   * @param template
   */
  vipEdit(template): void {
    if (!this.authService.isPersonalVip()) {
      this.modal.showTemplateBuyVip(template);
      return;
    }
    window.open(this.getDesignParamEncodeString(template.templateId));
  }

  /**
   * 获取经过编码的模版参数
   */
  getDesignParamEncodeString(from) {
    return this.designParamService.getParam({
      method: 'member',
      from
    });
  }
}
