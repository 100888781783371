import { Component, OnInit, OnDestroy,HostListener } from '@angular/core';
import * as Color from 'color';
import * as $ from 'jquery';
import { ApiService } from '../../../../../services/api.service';
import { EditorService } from '../../../../../services/editor.service';
import { px2pt, pt2px, getUnitScale } from '../../../../../utils/misc/common';
import { Modal } from '../../../../../models/ui/modal';
import { FontDistanceToolComponent } from './modal/font-distance-tool/font-distance-tool.component';
import { TextTranslateToolComponent } from './modal/text-translate-tool/text-translate-tool.component';
import { UserInterfaceService } from '../../../../../services/ui.service';
import { TextAlignToolComponent } from './modal/text-align-tool/text-align-tool.component';
import { CurveTextToolComponent } from '../../modals/curve-text-tool/curve-text-tool.component';
import { ModalService as CoreModal } from '@libs/core/src/lib/services/modal.service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { TextV2 } from '@libs/editor/src/lib/models/element/textv2';
import { Window } from '@libs/editor/src/lib/models/ui/window';
import { ColorGradientComponent } from '@libs/editor/src/lib/components/edit-tools/color-gradient/color-gradient.component';
@Component({
  selector: 'app-tool-bar-text',
  templateUrl: './tool-bar-text.component.html',
  styleUrls: ['./tool-bar-text.component.scss']
})
export class ToolBarTextComponent implements OnInit, OnDestroy {
  fontColor = '#000000';
  fontFamily = this.api.fontList[0];
  fontType = '';
  fontSize = 12;
  fontSizeItems: any = [4, 6, 8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72];
  bold = false;
  italic = false;
  underLine = false;
  deleteLine = false;
  upperCase = false;
  mulitFont = false;
  align = 'left';

  enterEditEventID;
  eleItem;
  chooseColor = 'unicolor';
  public addReferenceWindow = null;
  constructor(
    public editor: EditorService,
    public api: ApiService,
    public ui: UserInterfaceService,
    public coreModal: CoreModal,
    public coop: CoopService
  ) { }

  ngOnInit(): void {
    this.fontSizeItems = this.fontSizeItems.map(item => {
      return {
        option: item,
        value: item
      };
    });
    // 获取第一个颜色
    this.eleItem = this.editor.activeElements[0];
    if(this.eleItem.options.selectedColor) {
      this.chooseColor = 'gradation';
    }
    const $text = $(this.eleItem.options.text);
    this.fontColor =
      $text.data("cmyk-color") ||
      $text.find("*[style]").css("color") ||
      $text.find("*[color]").attr("color") ||
      $text.css("color") || '#FFFFFF';
    // 获取字体对象
    const fontId = parseInt(this.getFontFamily($text).split('_')[1], 10);

    let currentFace = $text.find('*[face]').eq(0).attr('face');
    const self = this;
    $text.find('*[face]').each(function () {
      // console.log('now', $(this).attr('face'));
      if ($(this).text() && $(this).attr('face') !== currentFace) {
        // console.log('多个字体');
        self.mulitFont = true;
      }
    });

    this.fontFamily =
      this.api.fontList.find(item => item.fontId === fontId) ||
      this.api.fontList[0];

    // 获取字号
    const scale = this.editor.template.scale;
    const fontSize = parseFloat($text.css('font-size'));
    let fontScale = this.eleItem.options.scale;
    const dpi = this.editor.template.props.unit === 'px' ? 72 : 300;
    this.fontSize = Math.round(px2pt(fontSize, dpi) * fontScale);

    // 获取是否加粗
    this.bold = $text.find('b').length > 0;
    this.italic = $text.find('i').length > 0;
    this.underLine = $text.find('u').length > 0;
    this.deleteLine = $text.find('del').length > 0;
    this.align = this.eleItem.options.textAlign;
    this.upperCase = /[a-zA-Z]+/.test($text.text()) && $text.text() === $text.text().toUpperCase();
    this.enterEditEventID = this.eleItem.on('modified', e => {
      const $text = $(this.eleItem.options.text);
      const fontSize = parseFloat($text.css('font-size'));
      let fontScale = this.eleItem.options.scale;
      const dpi = this.editor.template.props.unit === 'px' ? 72 : 300;
      this.fontSize = Math.round(px2pt(fontSize, dpi) * fontScale);
      this.align = this.eleItem.options.textAlign;
    });
    //格式刷更新样式
    setTimeout(()=>{
      this.formatStyle();
    },10)

  }
  getFontFamily($text) {
    if ($text.find('*[face]').attr('face')) {
      return $text.find('*[face]').attr('face');
    } else {
      let family = '';
      $text.find('*').each(function () {
        if ($(this).css('font-family')) {
          family = $(this).css('font-family');
        }
      });
      if (!family) {
        return $text.css('font-family');
      }
      return family;
    }
  }
  onStrokeClick() {
    // if(confirm('若要使用"描边"功能,需要先转换为艺术字。\n\n确认转换为艺术字吗?')){
    // }

    const $text = $(this.eleItem.options.text);
    //console.log('this.eleItem',this.eleItem )
    //console.log('textEl',$text )
    const fill =
      $text.data("cmyk-color") ||
      $text.find("*[style]").css("color") ||
      $text.find("*[color]").attr("color") ||
      $text.css("color") || '#FFFFFF';
    let text = $text.text().split('');

    const linesLength = this.eleItem.options.linesLength.slice(0, this.eleItem.options.linesLength.length - 1);
    let start = 0
    for (const num of linesLength) {
      text.splice(start + num, 0, '\n');
      start += num + 1;
    }

    text = text.join('');



    const fontFamily = this.getFontFamily($text);
    const fontSize = parseFloat($text.css('font-size')) * this.eleItem.options.scale / this.editor.template.scale;
    const lineHeight = parseFloat($text.css('line-height')) / 100;
    const letterSpacing = parseFloat($text.css('letter-spacing')) || 0;
    const textAlign = this.eleItem.options.textAlign;
    const textOptions = this.eleItem.options;
    const textProps = this.eleItem.props;

    const textEl = new TextV2({
      id: this.editor.getNewID(),
      props: {
        ...this.eleItem.props,
        oWidth: this.eleItem.props.oWidth,
        oHeight: this.eleItem.props.oHeight,
      },
      options: {
        text,
        fontFamily,
        fontSize,
        lineHeight,
        letterSpacing,
        strokeWidth: 2,
        stroke: 'rgba(255,0,255,255000100000000)',
        textAlign,
        fill,
        textOptions,
        textProps
      }
    });
    this.eleItem.remove();
    this.editor.activePage.addElement(textEl);
  }
  ngOnDestroy(): void {
    if (this.eleItem && this.enterEditEventID) {
      this.eleItem.off(this.enterEditEventID);
    }
  }
  getAlignIconClass() {
    switch (this.align) {
      case 'left':
        return 'icon-zuoduiqiwenben';
      case 'center':
        return 'icon-juzhongwenben';
      case 'right':
        return 'icon-youduiqiwenben';
      case 'justify':
        return 'icon-liangduanduiqi-';
    }
  }
  /**
   * 获取字号大小
   */
  // getFontSize() {

  //   const props = this.editor.activeElements[0].props;
  //   const scale = props.width / props.oWidth;
  //   return parseInt((this.fontSize * scale).toString(), 10);
  // }
  /**
   * 事件:当颜色被修改
   * @param color 新颜色
   */
  onColorChange(color,flag?) {
    this.fontColor = color;
    const activeElements = this.editor.activeElements;
    for (const eleItem of activeElements) {
      if (!eleItem.options.isNewText) {
        eleItem.options.isNewText = true;
        eleItem.changeTextY();
      }
      delete eleItem.options.v4svg;
      delete eleItem.options.url;
    }
    // if (color.indexOf('rgba') !== -1) {
    //   for (const eleItem of activeElements) {
    //     eleItem.exitEditMode();
    //   }
    // }
    if (activeElements.length === 1 && activeElements[0].states.editMode) {
      // 单个文本编辑模式下,颜色修改针对选中区域
      // 单个文本编辑模式下,颜色修改针对选中区域
      let sel = window.getSelection();
      let range = activeElements[0].states.range;
      const $text = $(activeElements[0].options.text);

      if (range) {
        sel.removeAllRanges();
        sel.addRange(range);
      }

      document.execCommand('foreColor', false, color);
      document.querySelector('body').style.setProperty('--selection-color', color);
      activeElements[0].states.range = sel.getRangeAt(0);

      // 如果全部选中的话
      if ($text[0].innerText.length === sel.toString().length) {
        $text.find('*[color]').attr('color', null);
        $text.find('*[style]').css('color', '');
        $text.find('*[style]').css('background', '');
        $text.find('*[style]').css('-webkit-background-clip', '');
        $text.find('*[style]').css('-webkit-text-fill-color', '');
        // 包括font标签,填充颜色
        $text.css('color', color);
        $text.css('background', '');
        $text.css('-webkit-background-clip', '');
        $text.css('-webkit-text-fill-color', '');
        if (color.indexOf('rgba') !== -1) {
          $text.attr('data-cmyk-color', color);
        } else {
          $text.attr('data-cmyk-color', null);
        }
        activeElements[0].options.text = $text[0].outerHTML;
        if(!flag) {
          activeElements[0].emit('modified', {
            type: 'fontColor',
            target: activeElements[0],
            stopPropagation: false
          });
        }
      }

      // 取消选择
      //const el = $('.text-edit')[0];
      //el.focus();
      // range = document.createRange();
      // range.selectNodeContents(el);
      // range.collapse(false);
      // sel = window.getSelection();
      // sel.removeAllRanges();
      // sel.addRange(range);

    } else if (
      (activeElements.length === 1 && !activeElements[0].states.editMode) ||
      activeElements.length > 1
    ) {
      // 单个或者多个文本,非编辑模式下,颜色修改针对全局
      activeElements.forEach(eleItem => {
        const $text = $(eleItem.options.text);
        $text.find('*[color]').attr('color', null);
        $text.find('*[style]').css('color', '');
        $text.find('*[style]').css('background', '');
        $text.find('*[style]').css('-webkit-background-clip', '');
        $text.find('*[style]').css('-webkit-text-fill-color', '');
        // 包括font标签,填充颜色
        $text.css('color', color);
        $text.css('background', '');
        $text.css('-webkit-background-clip', '');
        $text.css('-webkit-text-fill-color', '');
        if (color.indexOf('rgba') !== -1) {
          $text.attr('data-cmyk-color', color);
        } else {
          $text.attr('data-cmyk-color', null);
        }
        eleItem.options.text = $text[0].outerHTML;
        eleItem.emit('modified', {
          type: 'fontColor',
          target: eleItem,
          stopPropagation: activeElements.length > 1
        });
        if (eleItem.options.curve) {
          const curveobj = eleItem.getCurvedText(eleItem.options.text, eleItem.props.width, eleItem.options.curve.dir);
          eleItem.options.curve.html = curveobj.html;
          eleItem.props.oHeight = eleItem.props.height = curveobj.height;
          eleItem.states.html = eleItem.toHTML();
        }
      });
      if (activeElements.length > 1) {
        activeElements[0].parent.emit('element:modified', {
          types: 'fontColor',
          targets: activeElements
        });
      }
    }
  }

  /**
   * 事件:当字体被修改
   * @param font 字体
   */
  async onFontChange(font,flag?) {
    this.fontFamily = font;
    const activeElements = this.editor.activeElements;
    for (const eleItem of this.editor.activeElements) {
      delete eleItem.options.v4svg;
      delete eleItem.options.url;
      if (eleItem.states.editMode) {
        if (eleItem.options.curve) {
          let $text = $($(`.text-wrap`).html());
          $text.find('*').each(function () {
            if ($(this).css('font-family')) {
              $(this).css('font-family', 'font_' + font.fontId);
            }
          });
          $text.css('font-family', 'font_' + font.fontId);
          $(`.text-wrap`).focus();
        } else {
          this.execCommand('FontName', 'font_' + font.fontId);
        }
      } else {
        if (!eleItem.options.isNewText) {
          eleItem.options.isNewText = true;
          eleItem.changeTextY();
        }
        if (eleItem.options.directWrite && sessionStorage.getItem('directWrite') != eleItem.options.directWrite) {
          eleItem.options.directWrite = sessionStorage.getItem('directWrite');
        }
        const $text = $(eleItem.options.text);
        $text.find('font[face]').attr('face', 'font_' + font.fontId);
        if ($text.find('font[face]').length === 0) {
          $text.wrapInner(`<font face="font_${font.fontId}"></font>`);
        }
        $text.find('*').each(function () {
          if ($(this).css('font-family')) {
            $(this).css('font-family', 'font_' + font.fontId);
          }
        });
        $text.css('font-family', 'font_' + font.fontId);
        $text.find('*').removeAttr('data-family');
        eleItem.options.text = $text[0].outerHTML;
        const textInfo = eleItem.getTextInfo($text[0].outerHTML, false);
        eleItem.props.height = textInfo.height;
        eleItem.props.oHeight = eleItem.props.height / eleItem.options.scale;
        eleItem.props.width = textInfo.width;
        eleItem.props.oWidth = eleItem.props.width / eleItem.options.scale;
        eleItem.states.html = eleItem.toHTML();
        eleItem.options.linesLength = textInfo.linesLength;
        if (eleItem.options.curve) {
          const curveobj = eleItem.getCurvedText(eleItem.options.text, eleItem.props.width, eleItem.options.curve.dir);
          eleItem.options.curve.html = curveobj.html;
          eleItem.props.oHeight = eleItem.props.height = curveobj.height;
          eleItem.states.html = eleItem.toHTML();
        }
        if(!flag) {
          eleItem.emit('modified', {
            type: 'fontFamily',
            target: eleItem,
            stopPropagation: activeElements.length > 1
          });
        }
      }
    }
    if (activeElements.length > 1) {
      activeElements[0].parent.emit('element:modified', {
        types: 'fontFamily',
        targets: activeElements
      });
    }
    this.editor.setActiveElements([... this.editor.activeElements]);
  }

  /**
   * 事件:字号被修改
   * @param value 新的字号
   */
  async onFontSizeChange(value,flag?) {
    const activeElements = this.editor.activeElements;
    value = value < 4 ? 4 : value;
    this.fontSize = value;
    const dpi = this.editor.template.props.unit === 'px' ? 72 : 300;
    const pxNum = pt2px(value, dpi);
    for (const eleItem of this.editor.activeElements) {
      delete eleItem.options.v4svg;
      delete eleItem.options.url;
      if (!eleItem.options.isNewText) {
        eleItem.options.isNewText = true;
        eleItem.changeTextY();
      }
      if (eleItem.options.directWrite && sessionStorage.getItem('directWrite') != eleItem.options.directWrite) {
        eleItem.options.directWrite = sessionStorage.getItem('directWrite');
      }
      if (eleItem.states.editMode && eleItem.options.curve) {
        const $tempText = $(eleItem.options.text);
        $tempText.css('font-size', pxNum + 'px');
        eleItem.options.text = $tempText[0].outerHTML;
        eleItem.states.html = eleItem.toHTML();
        // 单个文本编辑模式下,颜色修改针对选中区域
        setTimeout(() => {
          const textInfo = eleItem.getTextBoxInfo(this.editor.zoom);
          if (eleItem.options.mode === 'h') {
            eleItem.props.height = eleItem.props.oHeight = textInfo.height;
          } else {
            eleItem.props.width = eleItem.props.oWidth = textInfo.width;
          }
        })
      } else {

        const $text = $(eleItem.options.text);
        let cFontSize = pxNum / eleItem.options.scale;
        if (cFontSize < 24) {
          cFontSize = 24;
          eleItem.options.scale = (pxNum / eleItem.options.scale) / cFontSize * eleItem.options.scale
          eleItem.props.oWidth = eleItem.props.width / eleItem.options.scale;
          eleItem.props.oHeight = eleItem.props.height / eleItem.options.scale;
        }
        $text.css('font-size', (cFontSize) + 'px');
        eleItem.options.text = $text[0].outerHTML;
        eleItem.states.html = eleItem.toHTML();
        setTimeout(() => {
          // 单个文本编辑模式下,颜色修改针对选中区域
          const textInfo = eleItem.getTextBoxInfo(this.editor.zoom);
          eleItem.props.height = Math.max(textInfo.height, textInfo.minHeight);
          eleItem.props.width = Math.max(textInfo.width, textInfo.minWidth);
          eleItem.props.oWidth = eleItem.props.width / eleItem.options.scale;
          eleItem.props.oHeight = eleItem.props.height / eleItem.options.scale;
        })
        if (eleItem.options.curve) {
          const curveobj = eleItem.getCurvedText(eleItem.options.text, eleItem.props.width, eleItem.options.curve.dir);
          eleItem.options.curve.html = curveobj.html;
          eleItem.props.oHeight = eleItem.props.height = curveobj.height;
          eleItem.states.html = eleItem.toHTML();
        }
        if(!flag) {
          eleItem.emit('modified', {
            type: 'fontSize',
            target: eleItem,
            stopPropagation: activeElements.length > 1
          });
        }

      }
    }
    if (activeElements.length > 1) {
      activeElements[0].parent.emit('element:modified', {
        types: 'fontSize',
        targets: activeElements
      });
    }
    this.editor.setActiveElements([...this.editor.activeElements]);
  }
  /**
   * 事件:格式刷按钮被单击
   * @param event 鼠标事件
   */
  ontFormatBrushClick() {
    const eleItem = this.editor.activeElements[0];
    const $text = $(eleItem.options.text);

    let textStyle = {} as any;
    // 获取字体
    textStyle.fontFamily = this.fontFamily;
    // 获取字体大小
    textStyle.fontSize = this.fontSize;
    // 获取字体颜色
    textStyle.fontColor = this.fontColor;
    // 获取是否加粗
    textStyle.bold = this.bold;
    // 获取是否有下划线
    textStyle.underLine = this.underLine;
    // 获取是否有删除线
    textStyle.deleteLine = this.deleteLine;
    // 获取是否倾斜
    textStyle.italic = this.italic;
    // 获取对齐方式
    textStyle.align = this.align;
    // 获取大小写
    textStyle.upperCase = this.upperCase;
    // 获取字体间距
    const letterSpacing = $text.css('letter-spacing');
    const lineHeight = $text.css('line-height');
    if (letterSpacing !== '') {
      textStyle.letterSpacing = parseFloat(letterSpacing);
    }
    if (lineHeight !== '') {
      if (lineHeight.indexOf('%') === -1){
        textStyle.lineHeight = (parseFloat(lineHeight) * 100 - 100) / 4;
      }else{
        textStyle.lineHeight = (parseFloat(lineHeight) - 100) / 4;
      }
    }
    this.editor.textStyleTwo = textStyle;
    this.editor.FormatBrush = true;
  }

  formatStyle(){
    const eleItem = this.editor.activeElements[0];
    if(this.editor.textStyle) {
      this.onFontChange(this.editor.textStyle.fontFamily,true);
      this.onFontSizeChange(this.editor.textStyle.fontSize,true);
      this.onColorChange(this.editor.textStyle.fontColor,true);
      this.onItalicChange(this.editor.textStyle.italic);
      this.onBoldClick(this.editor.textStyle);
      if(this.editor.textStyle.underLine) {
        this.onUnderLineClick(this.editor.textStyle);
      }
      if(this.editor.textStyle.deleteLine) {
        this.onDeleteLineClick(this.editor.textStyle);
      }
      this.onUpperCaseClick(this.editor.textStyle);
      this.textSpaceChange(this.editor.textStyle);
      this.textLineHeightChange(this.editor.textStyle);
      this.editor.textStyle = null;
      if(this.editor.key.ctrlIsDown) {
        this.editor.FormatBrush = true;
      } else {
        this.editor.FormatBrush = false;
      }

    }
  }
  onItalicChange(italic) {
    const eleItem = this.editor.activeElements[0];
    delete eleItem.options.v4svg;
    delete eleItem.options.url;
    if (eleItem.states.editMode) {
      this.italic = italic;
      this.execCommand('italic', this.italic);
    } else {
      if (!eleItem.options.isNewText) {
        eleItem.options.isNewText = true;
        eleItem.changeTextY();
      }
      if (eleItem.options.directWrite && sessionStorage.getItem('directWrite') != eleItem.options.directWrite) {
        eleItem.options.directWrite = sessionStorage.getItem('directWrite');
      }
      this.italic = italic;
      const $text = $(eleItem.options.text);
      /*处理style中的斜体*/
      $text.css('font-style', '');
      /*处理style中的斜体*/
      if (this.italic) {
        $text.wrapInner('<i></i>');
      } else {
        $text.find('i').contents().unwrap();
      }
      eleItem.options.text = $text[0].outerHTML;
      eleItem.emit('modified', {
        type: 'italic',
        target: eleItem,
      });
    }
  }

  textSpaceChange(textStyle) {
    const eleItem = this.editor.activeElements[0];
    if (!eleItem.options.isNewText){
      eleItem.options.isNewText = true;
      eleItem.changeTextY();
    }
    if (eleItem.options.directWrite && sessionStorage.getItem('directWrite') != eleItem.options.directWrite) {
      eleItem.options.directWrite = sessionStorage.getItem('directWrite');
    }
    const $tempText = $(eleItem.options.text);
    $tempText.css('letter-spacing', textStyle.letterSpacing + 'px');
    eleItem.options.text = $tempText[0].outerHTML;
    // $('.text-edit').focus();
    // 单个文本编辑模式下,颜色修改针对选中区域
    if (eleItem.options.curve){
      const curveobj = eleItem.getCurvedText(eleItem.options.text, eleItem.props.width, eleItem.options.curve.dir);
      eleItem.options.curve.html = curveobj.html;
      eleItem.props.oHeight = eleItem.props.height = curveobj.height;
      eleItem.states.html = eleItem.toHTML();
    } else{
      eleItem.states.html = eleItem.toHTML();
    }
    setTimeout(()=>{
      const textInfo = eleItem.getTextBoxInfo(this.editor.zoom);
      if (eleItem.options.mode === 'h') {
        eleItem.props.height = textInfo.height;
        eleItem.props.oHeight = textInfo.height / eleItem.options.scale;
      } else {
        eleItem.props.width = textInfo.width;
        eleItem.props.oWidth = textInfo.width / eleItem.options.scale;
      }
    })
  }

  textLineHeightChange(textStyle) {
    const lineHeightValue = (textStyle.lineHeight * 4) + 100;

    const eleItem = this.editor.activeElements[0];
    if (!eleItem.options.isNewText){
      eleItem.options.isNewText = true;
      eleItem.changeTextY();
    }
    if (eleItem.options.curve) return;
    //单行文字不能修改行距
    if (eleItem.options.mode === 'd') return;
    const $tempText = $(eleItem.options.text);
    $tempText.css('line-height', lineHeightValue + '%');
    // 修改文字顶部
    // const fontSize = parseFloat($tempText.css('font-size'));
    // $tempText.css('margin-top', (((1 - lineHeightValue) / 100) * fontSize / 2 + (fontSize / 2)) + 'px');
    $tempText.css('margin-top', '');
    eleItem.options.text = $tempText[0].outerHTML;
    // $('.text-edit').focus();
    eleItem.states.html = eleItem.toHTML();
    setTimeout(()=>{
      const textInfo = eleItem.getTextBoxInfo(this.editor.zoom);
      if (eleItem.options.mode === 'h') {
        eleItem.props.height = textInfo.height;
        eleItem.props.oHeight = textInfo.height / eleItem.options.scale;
      } else {
        eleItem.props.width = textInfo.width;
        eleItem.props.oWidth = textInfo.width / eleItem.options.scale;
      }
    })
  }
  /**
   * 事件:翻译按钮被单击
   * @param event 鼠标事件
   */
  ontTranslateClick(event: MouseEvent) {
    const eleItem = this.editor.activeElements[0];
    if (eleItem.states.editMode) {
      eleItem.exitEditMode();
    }
    const window = new Window({
      title: '翻译',
      width: 400,
      height: 330,
      skin: 'common',
      component: TextTranslateToolComponent
    });
    this.ui.add(window);
  }

  /**
   * 事件:字体距离按钮被单击
   * @param event 鼠标事件
   */
  onFontDistanceToolClick(event: MouseEvent) {
    const eleItem = this.editor.activeElements[0];
    if (eleItem.states.editMode) {
      eleItem.exitEditMode();
    }
    const modal = new Modal({
      x: event.clientX - 260 / 2,
      y: event.clientY + 20,
      width: 260,
      height: 101,
      component: FontDistanceToolComponent
    });
    this.ui.add(modal);
  }
  onCurveTextToolClick(event: MouseEvent) {
    const modal = new Modal({
      x: event.clientX - 216 / 2,
      y: event.clientY + 32,
      width: 216,
      height: 80,
      component: CurveTextToolComponent
    });
    this.ui.add(modal);
  }
  isCurvedText() {
    return this.editor.activeElements.length === 1 && this.editor.activeElements[0].options.curve !== undefined;
  }
  /**
   * 事件:加粗被单击
   */
  async onBoldClick(textStyle?) {
    const activeElements = this.editor.activeElements;
    for (const eleItem of this.editor.activeElements) {
      delete eleItem.options.v4svg;
      delete eleItem.options.url;
      if (eleItem.states.editMode) {
        if(textStyle) {
          this.bold = textStyle.bold;
        } else {
          this.bold = !($(eleItem.states.tempText).find('b').length > 0);
        }
        this.execCommand('bold', this.bold);
      } else {
        if(textStyle) {
          this.bold = textStyle.bold;
        } else {
          this.bold = !($(eleItem.options.text).find('b').length > 0);
        }
        const $text = $(eleItem.options.text);
        if (this.bold) {
          $text.wrapInner('<b></b>');
        } else {
          $text.find('b').contents().unwrap();
        }
        eleItem.options.text = $text[0].outerHTML;
        eleItem.emit('modified', {
          type: 'bold',
          target: eleItem,
          stopPropagation: activeElements.length > 1
        });
      }
    }
    if (activeElements.length > 1) {
      activeElements[0].parent.emit('element:modified', {
        types: 'bold',
        targets: activeElements
      });
    }
    this.editor.setActiveElements([... this.editor.activeElements]);
  }
  getItalicProhibit() {
    return sessionStorage.getItem('directWrite') && sessionStorage.getItem('directWrite') === 'noDirectWrite';
  }
  /**
   * 事件:倾斜被单击
   */
  async onItalicClick() {
    const activeElements = this.editor.activeElements;
    if (sessionStorage.getItem('directWrite') && sessionStorage.getItem('directWrite') === 'noDirectWrite') {
      this.coreModal.diyConfirm({
        title: '提示!',
        content: `当前浏览器未开启DirectWrite高清字体渲染支持，无法预览文字斜体效果，请在浏览器设置-选项-实验室-系统设置内开启。`,
        buttons: [{
          text: '知道了',
          style: {
            backgroundColor: this.coop.coopInfo.color,
            border: 0,
            color: '#fff'
          },
          fun: (check) => {
          }
        }]
      });
      return;
    }
    for (const eleItem of this.editor.activeElements) {
      delete eleItem.options.v4svg;
      delete eleItem.options.url;
      if (eleItem.states.editMode) {
        this.italic = !($(eleItem.options.text).find('i').length > 0);
        this.execCommand('italic', this.italic);
      } else {
        if (!eleItem.options.isNewText) {
          eleItem.options.isNewText = true;
          eleItem.changeTextY();
        }
        if (eleItem.options.directWrite && sessionStorage.getItem('directWrite') != eleItem.options.directWrite) {
          eleItem.options.directWrite = sessionStorage.getItem('directWrite');
        }
        this.italic = !($(eleItem.options.text).find('i').length > 0);
        const $text = $(eleItem.options.text);
        /*处理style中的斜体*/
        $text.css('font-style', '');
        /*处理style中的斜体*/
        if (this.italic) {
          $text.wrapInner('<i></i>');
        } else {
          $text.find('i').contents().unwrap();
        }
        eleItem.options.text = $text[0].outerHTML;
        eleItem.emit('modified', {
          type: 'italic',
          target: eleItem,
          stopPropagation: activeElements.length > 1
        });
      }
    }
    if (activeElements.length > 1) {
      activeElements[0].parent.emit('element:modified', {
        types: 'italic',
        targets: activeElements
      });
    }
    this.editor.setActiveElements([...this.editor.activeElements]);
  }

  /**
   * 事件:下划线被单击
   */
  async onUnderLineClick(textStyle?) {
    const activeElements = this.editor.activeElements;
    for (const eleItem of this.editor.activeElements) {
      delete eleItem.options.v4svg;
      delete eleItem.options.url;
      if (eleItem.states.editMode) {
        if(textStyle) {
          this.underLine = textStyle.underLine;
        } else {
          this.underLine = !($(eleItem.states.tempText).find('u').length > 0);
        }
        this.execCommand('underline', this.underLine);
      } else {
        if(textStyle) {
          this.underLine = textStyle.underLine;
        } else {
          this.underLine = !($(eleItem.options.text).find('u').length > 0);
        }
        const $text = $(eleItem.options.text);
        if (this.underLine) {
          this.deleteLine = false;
          $text.find('del').contents().unwrap();
          $text.wrapInner('<u></u>');
        } else {
          $text.find('u').contents().unwrap();
        }
        eleItem.options.text = $text[0].outerHTML;
        eleItem.emit('modified', {
          type: 'underline',
          target: eleItem,
          stopPropagation: activeElements.length > 1
        });
      }
    }
    if (activeElements.length > 1) {
      activeElements[0].parent.emit('element:modified', {
        types: 'underline',
        targets: activeElements
      });
    }
    this.editor.setActiveElements([... this.editor.activeElements]);
  }

  /**
   * 事件:删除线被单击
   */
  async onDeleteLineClick(textStyle?) {
    const activeElements = this.editor.activeElements;
    for (const eleItem of this.editor.activeElements) {
      delete eleItem.options.v4svg;
      delete eleItem.options.url;
      if (eleItem.states.editMode) {
        if(textStyle) {
          this.deleteLine = textStyle.deleteLine;
        } else {
          this.deleteLine = !($(eleItem.states.tempText).find('del').length > 0);
        }
        this.execCommand('line-through', this.deleteLine);
      } else {
        if(textStyle) {
          this.deleteLine = textStyle.deleteLine;
        } else {
          this.deleteLine = !($(eleItem.options.text).find('del').length > 0);
        }
        const $text = $(eleItem.options.text);
        if (this.deleteLine) {
          this.underLine = false;
          $text.find('u').contents().unwrap();
          $text.wrapInner('<del></del>');
        } else {
          $text.find('del').contents().unwrap();
        }
        eleItem.options.text = $text[0].outerHTML;
        eleItem.emit('modified', {
          type: 'line-through',
          target: eleItem,
          stopPropagation: activeElements.length > 1
        });
      }
    }
    if (activeElements.length > 1) {
      activeElements[0].parent.emit('element:modified', {
        types: 'line-through',
        targets: activeElements
      });
    }
    this.editor.setActiveElements([... this.editor.activeElements]);
  }

  /**
   * 事件:竖形文字被单击
   */
  async onDeleteLineClick2() {
    const activeElements = this.editor.activeElements;
    for (const eleItem of this.editor.activeElements) {
      delete eleItem.options.v4svg;
      delete eleItem.options.url;
      if (!eleItem.options.isNewText){
        eleItem.options.isNewText = true;
        eleItem.changeTextY();
      }
      const $tempText = $(eleItem.options.text);
      //$tempText.css('writing-mode', 'vertical-lr');
      if(eleItem.options.mode !== 'v') {
        eleItem.options.mode = 'v';
        eleItem.config.corner.mr = false;
        eleItem.config.corner.mb = true;
        eleItem.states.html = eleItem.toHTML({flag : true})
        // [eleItem.props.width, eleItem.props.height] = [eleItem.props.height, eleItem.props.width];
        // [eleItem.props.oWidth, eleItem.props.oHeight] = [eleItem.props.oHeight, eleItem.props.oWidth];
      } else if (eleItem.options.mode == 'v') {
        eleItem.options.mode = 'h';
        eleItem.config.corner.mr = true;
        eleItem.config.corner.mb = false;
        eleItem.states.html = eleItem.toHTML({flag : true})
      }
      //eleItem.options.text = $tempText[0].outerHTML;

      // setTimeout(()=>{
      //   const textInfo = eleItem.getTextBoxInfo(this.editor.zoom);
      //   if (eleItem.options.mode === 'h') {
      //     eleItem.props.oHeight = textInfo.height;
      //     eleItem.props.oHeight = textInfo.height / eleItem.options.scale;
      //   // } else if(eleItem.options.mode === 'v') {
      //   //   eleItem.props.width = textInfo.height;
      //   //   eleItem.props.height = textInfo.width;
      //   //   eleItem.props.oWidth = textInfo.height / eleItem.options.scale;
      //   //   eleItem.props.oHeight = textInfo.width / eleItem.options.scale;
      //   } else {
      //     eleItem.props.width = textInfo.width;
      //     eleItem.props.oWidth = textInfo.width / eleItem.options.scale;
      //   }
      // })
    }
    this.editor.setActiveElements([... this.editor.activeElements]);
  }
  /**
   * 事件:转换为大写按钮被点击
   */
  async onUpperCaseClick(textStyle?) {
    const activeElements = this.editor.activeElements;
    this.upperCase = !this.upperCase;
    if(textStyle) {
      this.upperCase = textStyle.upperCase;
    }
    const self = this;
    for (const eleItem of this.editor.activeElements) {
      delete eleItem.options.v4svg;
      delete eleItem.options.url;
      if (eleItem.states.editMode) {
        const $tempText = $(eleItem.states.tempText);
        $tempText
          .find('*')
          .contents()
          .each(function () {
            if (this.nodeType === 3) {
              if (self.upperCase === false) {
                $(this).replaceWith(
                  $(this)
                    .text()
                    .toLowerCase()
                );
              } else {
                $(this).replaceWith(
                  $(this)
                    .text()
                    .toUpperCase()
                );
              }
            }
          });
        eleItem.states.tempText = $tempText[0].outerHTML;
        $('.text-edit').html(eleItem.states.tempText);
        $('.text-edit').focus();
        // 单个文本编辑模式下,颜色修改针对选中区域
        /*const textInfo = eleItem.getTextInfo(eleItem.states.tempText, false);
        if (eleItem.options.mode === 'h') {
          eleItem.props.height = textInfo.height;
        } else {
          eleItem.props.width = textInfo.width;
        }
        eleItem.options.linesLength = textInfo.linesLength;*/
      } else {
        const $text = $(eleItem.options.text);
        $text
          .find('*')
          .contents()
          .each(function () {
            if (this.nodeType === 3) {
              if (self.upperCase === false) {
                $(this).replaceWith(
                  $(this)
                    .text()
                    .toLowerCase()
                );
              } else {
                $(this).replaceWith(
                  $(this)
                    .text()
                    .toUpperCase()
                );
              }
            }
          });
        eleItem.options.text = $text[0].outerHTML;
        eleItem.emit('modified', {
          type: 'textTransform',
          target: eleItem,
          stopPropagation: activeElements.length > 1
        });
      }
    }
    if (activeElements.length > 1) {
      activeElements[0].parent.emit('element:modified', {
        types: 'textTransform',
        targets: activeElements
      });
    }
    this.editor.setActiveElements([...this.editor.activeElements]);
  }
  /**
   * 事件:对齐被单击
   * @param event 鼠标事件
   */
  onAlignClick(event: MouseEvent) {
    const eleItem = this.editor.activeElements[0];
    delete eleItem.options.v4svg;
    delete eleItem.options.url;
    const modal = new Modal({
      x: event.clientX - 170 / 2,
      y: event.clientY + 20,
      width: 220,
      height: 80,
      component: TextAlignToolComponent
    });
    this.ui.add(modal);
  }
  /**
   * 执行设计模式命令(如果不是编辑模式则进入编辑模式)
   * @param command 命令
   * @param value 值
   */
  execCommand(command, value) {
    const eleItem = this.editor.activeElements[0];
    // 隐藏selection样式
    // $('head').append(`<style id="hide-selection">* ::selection{background:transparent}</style>`)
    // 判断是否选中文本(没有选中就全选)
    if (window.getSelection().isCollapsed) {
      const range = document.createRange();
      range.selectNodeContents($('#' + eleItem.id).find('.text-wrap')[0]);
      // range.selectNodeContents($('.text-edit .text-wrap')[0]);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
    //document.execCommand('styleWithCSS', false, true);
    document.execCommand(command, false, value);

    const textInfo = eleItem.getTextInfo(eleItem.options.text, false);
    if (eleItem.options.mode === 'h') {
      eleItem.props.height = textInfo.height;
      eleItem.props.oHeight = textInfo.height / eleItem.options.scale;
    } else {
      eleItem.props.width = textInfo.width;
      eleItem.props.oWidth = textInfo.width / eleItem.options.scale;
    }
    eleItem.options.linesLength = textInfo.linesLength;
    // 取消选择
    // const el = $('.text-edit .text-wrap')[0];
    // el.focus();
    // var range = document.createRange();
    // range.selectNodeContents(el);
    // range.collapse(false);
    // var sel = window.getSelection();
    //
    // sel.removeAllRanges();
    // sel.addRange(range);
    // $('#hide-selection').remove();
  }
  colorPickerClick() {
    //存储选中区域
    const activeElements = this.editor.activeElements;
    if (activeElements.length === 1 && activeElements[0].states.editMode) {
      let sel = window.getSelection();
      if (sel.type != 'None') {
        activeElements[0].states.range = sel.getRangeAt(0);
      }
    }
  }

  async ontTranslateClick2() {
    const activeElements = this.editor.activeElements;
    this.upperCase = !this.upperCase;
    const self = this;
    for (const eleItem of this.editor.activeElements) {
      // delete eleItem.options.v4svg;
      // delete eleItem.options.url;
      let oldElement = eleItem;
      oldElement.exitEditMode();
      let newElement = '666666666';
      let $text = $(oldElement.options.text);
      let fontArr = $text.find('font');
      for (let i = 0; i < fontArr.length;i++){
        if (i !== 0){
          $(fontArr[i]).remove();
        }
      }
      $(fontArr[0]).text(newElement);
      oldElement.options.text = $text[0].outerHTML;
      oldElement.states.html = oldElement.toHTML();
      oldElement.enterEditMode();
      setTimeout(()=>{
        oldElement.exitEditMode();
      },10)
    }
    if (activeElements.length > 1) {
      activeElements[0].parent.emit('element:modified', {
        types: 'textTransform',
        targets: activeElements
      });
    }
    this.editor.setActiveElements([...this.editor.activeElements]);
  }
  /**
   * 事件:键盘按键
   * @param event 事件对象
   */
  @HostListener('document:keydown', ['$event']) onStageContainerKeyDown(event: KeyboardEvent) {
    switch (event.code) {
      case 'CtrlLeft':
      case 'ControlLeft':
        this.editor.key.ctrlIsDown = true;
        break;
    }
  }
  colorChange(value){
    if(value === 'unicolor') {
      const activeElements = this.editor.activeElements;
      for (const eleItem of activeElements) {
        if (eleItem.options.selectedColor) {
          delete eleItem.options.selectedColor;
        }
      }
      this.onColorChange('#000000')
    } else {
      this.onColorChange2()
    }
  }

  onColorChange2() {
    const value = {} as any;
    value.type = '1';
    value.color = '';
    value.opacity = '';
    value.gradientList = [
      {
          "position": "0%",
          "color": "rgba(255, 0, 0, 1)",
          "opacity": "1",
          "colorHex": "#ff0000"
      },
      {
          "position": "100%",
          "color": "rgba(0, 0, 255, 1)",
          "opacity": "1",
          "colorHex": "#0000FF"
      }
    ];
    value.gradientMode = "linear"
    value.gradientAngle = 90;
    value.style = 'linear-gradient(90deg,rgba(255, 0, 0, 1) 0%,rgba(0, 0, 255, 1) 100%)';
    //this.fontColor = color;
    const activeElements = this.editor.activeElements;

    for (const eleItem of activeElements) {
      if (!eleItem.options.isNewText) {
        eleItem.options.isNewText = true;
        eleItem.changeTextY();
      }
      delete eleItem.options.v4svg;
      delete eleItem.options.url;
      eleItem.options.selectedColor = value;
    }
    if (activeElements.length === 1 && activeElements[0].states.editMode) {
      let sel = window.getSelection();
      let range = activeElements[0].states.range;
      const $text = $(activeElements[0].options.text);
      if (range) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
      activeElements[0].states.range = sel.getRangeAt(0);
      // 如果全部选中的话
      if ($text[0].innerText.length === sel.toString().length) {
        $text.find('*[color]').attr('color', null);
        $text.find('*[style]').css('color', '');
        $text.css('background', 'linear-gradient(to right, red, blue)');
        $text.css('-webkit-background-clip', 'text');
        $text.css('-webkit-text-fill-color', 'transparent');
        activeElements[0].options.text = $text[0].outerHTML;
        activeElements[0].emit('modified', {
          type: 'fontColor',
          target: activeElements[0],
          stopPropagation: false
        });

      }
    } else if (
      (activeElements.length === 1 && !activeElements[0].states.editMode) ||
      activeElements.length > 1
    ) {
      // 单个或者多个文本,非编辑模式下,颜色修改针对全局
      activeElements.forEach(eleItem => {
        const $text = $(eleItem.options.text);
        $text.find('*[color]').attr('color', null);
        $text.find('*[style]').css('color', '');
        // 包括font标签,填充颜色
        $text.css('background', 'linear-gradient(to right, red, blue)');
        $text.css('-webkit-background-clip', 'text');
        $text.css('-webkit-text-fill-color', 'transparent');
        eleItem.options.text = $text[0].outerHTML;
        eleItem.emit('modified', {
          type: 'fontColor',
          target: eleItem,
          stopPropagation: activeElements.length > 1
        });
        if (eleItem.options.curve) {
          const curveobj = eleItem.getCurvedText(eleItem.options.text, eleItem.props.width, eleItem.options.curve.dir);
          eleItem.options.curve.html = curveobj.html;
          eleItem.props.oHeight = eleItem.props.height = curveobj.height;
          eleItem.states.html = eleItem.toHTML();
        }
      });
      if (activeElements.length > 1) {
        activeElements[0].parent.emit('element:modified', {
          types: 'fontColor',
          targets: activeElements
        });
      }
    }
  }

  addColor(e,type) {
    const activeElements = this.editor.activeElements;
    let eStyle = e.target.getBoundingClientRect();
    if (this.addReferenceWindow) {
      this.ui.remove(this.addReferenceWindow);
    }
    this.addReferenceWindow = new Modal({
      x: eStyle.left - 200,
      y: eStyle.bottom + 20,
      width: 270,
      component: ColorGradientComponent,
      modelType: 'reference',
      props: {
        guideItem: activeElements[0].options,
        type:type,
        itemType:'text'
      }
    });
    this.addReferenceWindow.on('close', e => {
      this.addReferenceWindow = null;
    });
    this.ui.add(this.addReferenceWindow);
  }
  /**
 * 获取颜色选择器颜色
 */
  getColorBoxStyle(): any {
    const activeElements = this.editor.activeElements;
    if (this.chooseColor === 'gradation' && activeElements[0].options.selectedColor) {
      return {
        background: activeElements[0].options.selectedColor.style
      };
    } else {
      return {
        background: "linear-gradient(90deg,rgba(255, 0, 0, 1) 0%,rgba(0, 0, 255, 1) 100%)"
      };
    }
  }
}
