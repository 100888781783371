import { Component, forwardRef, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import * as $ from 'jquery'
@Component({
  selector: 'app-editor-tip',
  templateUrl: './editor-tip.component.html',
  styleUrls: ['./editor-tip.component.scss']
})
export class EditorTipComponent implements OnInit, OnDestroy {

  constructor(
    private modal: NzModalRef,
    @Inject(forwardRef(() => ModalService))
    private modalService
  ) { }
  @Input() tipData;
  noTipCheck = false;
  ngOnInit() {

  }
  ngOnDestroy(){
    if (this.noTipCheck){
      localStorage.setItem(this.tipData.key,'1')
    } else{
      sessionStorage.setItem(this.tipData.key,'1')
    }
  }
  noTip(){
    this.noTipCheck = true;
    this.closeTip();
  }
  closeTip(){
    if (this.noTipCheck){
      localStorage.setItem(this.tipData.key,'1')
    } else{
      sessionStorage.setItem(this.tipData.key,'1')
    }
    this.close();
  }
  close() {
    this.modal.destroy();
  }
}
