import { Element } from '../base/element';
import * as $ from 'jquery';
import * as SvgPath from 'svgpath';
import { ToolBarShapeComponent } from '../../components/editor-ui/t-tool-bar/tools/tool-bar-shape/tool-bar-shape.component';
export class Shape extends Element {
  public type = 'shape';
  async onCreated() {
    this.config.toolbar = ToolBarShapeComponent;
    this.config.update = true;
    this.states.html = this.toHTML();

    this.loadSvg()
  }

  async loadSvg() {
    // 加载svg图片
    let svg = await window['http'].get(this.options.url, {responseType: 'text'}).toPromise()
    let $svg;
    // 取出SVG数据,去除描述信息
    const $svgData = $(svg);
    for (let i = 0; i < $svgData.length; i++) {
      const item = $svgData[i];
      if (item.nodeName === 'svg') {
        $svg = $(item);
        break;
      }
    }
    if ($svg.find('line').length > 0) {
      this.config.corner.mb = false;
      this.config.corner.mt = false;
    }
    // 设置缩放属性

    svg = $svg[0].outerHTML;
    // 替换hex的简化形式,替换为完整的hex (例如:#000 => #000000)
    svg = svg.replace(/#([0-9a-fA-F]{3})(?![0-9a-fA-F]){3}/g, (m, p1) => {
      return '#' + p1 + p1;
    });
    svg = svg.replace(/#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})/g, (m, p1) => {
      return m.toLowerCase();
    });
    // 将颜色全部转换为小写

    this.states.svg = svg;
    // 分析颜色信息
    this.states.html = this.toHTML();
    return this.states.html;
  }
  /**
   * 重写:获取当前元素的html结果
   * @returns {string} 根据元素信息生成的html
   */
  public toHTML(): string {
    if (!this.states.svg) return '';
    const id = this.id,
      props = this.props,
      options = this.options;
    const $svg = $(this.states.svg);
    $svg.attr('width', '100%');
    $svg.attr('height', '100%');
    $svg.css('position', 'absolute');
    $svg.attr('preserveAspectRatio', 'none');
    // 修改宽高
    if ($svg.find('path,rect').length > 0) {
      $svg.attr(
        'viewBox',
        `-${options.strokeWidth / 2} -${options.strokeWidth / 2} ${props.width +
        options.strokeWidth} ${props.height + options.strokeWidth}`
      );
    } else if ($svg.find('line').length > 0) {
      const ratio = props.height / props.oHeight;
      $svg.attr(
        'viewBox',
        `0 0 ${props.width / ratio} ${props.height / ratio}`
      );
    }
    // 拉伸路径
    const scaleX = props.width / props.oWidth;
    const scaleY = props.height / props.oHeight;

    // 缩放路径(只在有path情况下)
    $svg.find('path').each(function () {
      const d = $(this).attr('d');
      if (d) {
        let path = new SvgPath(d);
        path = path.scale(scaleX, scaleY);
        $(this).attr('d', path.toString());
      }
    });
    // 缩放线条
    const ratio = props.height / props.oHeight;
    $svg.find('line').each(function () {
      $(this).attr('x2', props.width / ratio);
    });
    // 替换fill
    $svg.find('path,rect').attr('fill', options.fill);
    // 描边
    $svg.find('path,rect,line').attr('stroke', options.stroke);
    // 粗细
    $svg.find('path,rect,line').attr('stroke-width', options.strokeWidth);
    // 虚线
    if (options.strokeDashArray && options.strokeDashArray.length > 1) {
      $svg
        .find('*')
        .attr('stroke-dasharray', options.strokeDashArray.join(','));
    }
    // 兼容矩形
    if ($svg.find('rect').length > 0) {
      $svg
        .find('rect')
        .attr('width', props.width)
        .attr('height', props.height);
      if (options.radius) {
        $svg
          .find('rect')
          .attr('rx', options.radius)
          .attr('ry', options.radius);
      }
    }
    return $svg[0].outerHTML;
  }
}
