import { Component, OnInit } from '@angular/core';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';
@Component({
  selector: 'app-combination-align-tool',
  templateUrl: './combination-align-tool.component.html',
  styleUrls: ['./combination-align-tool.component.scss']
})
export class CombinationAlignToolComponent implements OnInit {
  align = 'tl';
  constructor(
    public editor: EditorService,
    public coop:CoopService
  ) { }

  ngOnInit() {
    this.align = this.editor.activeElements[0].options.align;
  }
  /**
   * 事件:对齐按钮被单击
   * @param type 对齐方式
   */
  onAlignClick(type) {
    const el = this.editor.activeElements[0];
    this.align = type
    el.options.align = type;
    el.emit('modified', {
      type: 'align',
      target: el,
      stopPropagation: this.editor.activeElements.length > 1
    });
  }

}
