import { Component, OnInit } from '@angular/core';
import { HotTableRegisterer } from '@handsontable/angular';
import Handsontable from 'handsontable';
import { UploadService } from '@libs/core/src/lib/services/upload.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-variable-excel',
  templateUrl: './variable-excel.component.html',
  styleUrls: ['./variable-excel.component.scss'],
  providers: [HotTableRegisterer]
})
export class VariableExcelComponent implements OnInit {
  code;
  initData;
  loading = false;
  table: Handsontable;
  //excel相关配置
  setting = {
    licenseKey: '91245-5b384-a0873-4f204-a62c0',
    rowHeaders: true,
    colHeaders: true,
    trimWhitespace: false,
    // contextMenu: null,
    allowInsertColumn: false,
    allowRemoveColumn: false,
    fixedRowsTop: 1,
    width: window.innerWidth,
    height: window.innerHeight - 68,
    colWidths: 180,
    rowHeights: 45,
    beforePaste: (data, coords) => {
      const {startCol, endCol, startRow, endRow} = coords[0];
      // 判断数据类型 image render 不是 fileJson 不执行粘贴
      for (let col=startCol;col <= endCol;col++) {
        for (let row=startRow;row <= endRow;row++) {
          const colSetting = this.table.getSettings().columns[col];
          if (colSetting) {
            if (
              (colSetting.renderer !== 'text') !=
              this.isJson(data[row-startRow][col-startCol])
            ) {
              return false
            }
          }
        }
      }
      // data -> [[1, 2, 3], [4, 5, 6]]
      // coords -> [{startRow: 0, startCol: 0, endRow: 1, endCol: 2}]
    },
    afterInit: () => {
      this.table = this.hot.getInstance('variable-excel');
      this.registerImageRender();
      if (this.initData) {
        this.table.updateSettings(this.initData)
      }
    },
    cells: (row, col, prop) => {
      const cellProperties: any = {};
      if (row === 0) {
        cellProperties.readOnly = true;
      }
      return cellProperties;
    },
    // 第一行打标不要拖拽填充功能
    modifyAutofillRange: (startArea) => {
      if (startArea[0] === 0) {
        return false;
      }
    },
    fillHandle: {
      direction: 'vertical',
      autoInsertRow: false
    },
    className: "htMiddle"
  };
  constructor(
    public upload: UploadService,
    public hot: HotTableRegisterer,
    public message: MessageService,
    private http: HttpClient,
    public routeInfo: ActivatedRoute
  ) { }

  // 注册图片类型数据的渲染方式
  registerImageRender() {
    Handsontable.renderers.registerRenderer('image', this.imageRenderer.bind(this))
  }

  // 图片类的渲染方式
  imageRenderer(hotInstance, td, row, column, prop, value, cellProperties) {
    if (row === 0) {
      Handsontable.renderers.TextRenderer.apply(this.table, arguments);
    } else {
      Handsontable.renderers.BaseRenderer.apply(this.table, arguments);
      const div = document.createElement('div');
      const input = document.createElement('input');

      div.style.position = 'relative';
      div.style.display = 'flex';
      div.style.justifyContent = 'center';
      div.style.alignItems = 'center';
      div.style.width = '25px';
      div.style.height = '25px';
      div.style.background = 'url("/assets/img/editor/var-content/add-img.png") no-repeat center #e7e7e7';
      div.style.cursor = 'pointer';

      input.type = 'file';
      input.accept = 'image/jpeg,image/png,image/jpg';
      input.style.display = 'none';

      div.appendChild(input);

      if (this.isJson(value)) {
        const data = JSON.parse(value);
        if (data && data.filePath) {
          const img = document.createElement('img') as HTMLImageElement;
          img.style.maxWidth = '100%';
          img.style.maxHeight = '100%';
          img.src = data.filePath;
          div.appendChild(img);
        }
      }

      Handsontable.dom.addEvent(div, 'click', event => {
        event.preventDefault();
        input.dispatchEvent(new MouseEvent("click", {
          bubbles: false,
          cancelable: true,
          view: window
        }));
      });

      Handsontable.dom.addEvent(input, 'change', event => {
        const file = (event.target as HTMLInputElement).files[0];
        this.upload.uploadTemp({
          file,
          onSuccess: res => {
            hotInstance.setDataAtCell(row, column, JSON.stringify(res))
          },
          onFail: () => {
            this.message.error('上传失败,请再试一下')
          }
        });
      });

      Handsontable.dom.empty(td);
      td.appendChild(div)
    }
  }

  isJson(json) {
    if (typeof json !== 'string') return false;
    try {
      return typeof JSON.parse(json) === 'object'
    } catch (e) {
      return false
    }
  }

  // 表格最后加入一行
  addRow() {
    const rowCount = this.table.getDataAtCol(0).length;
    this.table.alter('insert_row', rowCount, 1);
  }

  ngOnInit() {
    this.code = this.routeInfo.snapshot.params.code;
    this.http.get(
      '/variable-tools/get-share-value-by-code',
      {params: {code: this.code}}
      ).subscribe((res: any) => {
        const excel = JSON.parse(res.value);
        // 至少20行
        if (excel.data.length < 20) {
          for (let i = excel.data.length; i < 20; i++) {
            excel.data.push({})
          }
        }
        this.initData = excel;
        if (this.table) {
          this.table.updateSettings(this.initData)
        }
    })
  }

  update(close: boolean) {
    const value = JSON.stringify({
      columns: this.table.getSettings().columns,
      data: this.table.getSourceData()
    });
    this.loading = true
    this.http.post('/variable-tools/save-value-by-code', {
      code: this.code,
      value
    }).subscribe(res => {
      if (close) window.close()
    }, () => {}, () => {
      this.loading = false;
    })
  }

}
