import { Injectable } from '@angular/core';
import { VipVersion } from '@libs/core/src/lib/models/vip-version';

@Injectable()
export class VipService {
  versions = [
    new VipVersion('personal', '个人VIP', 25, 0.8),
    new VipVersion('team', '企业VIP', 98, 0.75),
  ];

  choseVersion: string;

  constructor() { }
}
