import { UserInterfaceService } from '../../../../../services/ui.service';
import { EditorService } from '../../../../../services/editor.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ApiService } from '../../../../../services/api.service';
import { pt2px, px2pt, getUnitScale } from '../../../../../utils/misc/common';
import { Modal } from '../../../../../models/ui/modal';
import { TableAlignToolComponent } from './modals/table-align-tool/table-align-tool.component';
import { TableDistanceToolComponent } from './modals/table-distance-tool/table-distance-tool.component';
@Component({
  selector: 'app-tool-bar-table',
  templateUrl: './tool-bar-table.component.html',
  styleUrls: ['./tool-bar-table.component.scss']
})
export class ToolBarTableComponent implements OnInit {
  fontFamily = '';
  color = '';
  fontSize = 16;
  fontSizeItems: any = [4, 6, 8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72];
  italic = false;
  constructor(
    public editor: EditorService,
    public api: ApiService,
    public ui: UserInterfaceService
  ) {

  }
  get activeCell() {
    const eleItem = this.editor.activeElements[0];
    return eleItem.states.activeCell;
  }
  ngOnInit(): void {
    this.fontSizeItems = this.fontSizeItems.map(item => {
      return {
        option: item,
        value: item
      };
    });
    const eleItem = this.editor.activeElements[0];
    const firstCell = eleItem.options.cell[0][0];
    this.fontFamily = this.api.fontList.find(item => item.fontId === parseInt(firstCell.family.split('_')[1], 10));
    if (!this.fontFamily) {
      this.fontFamily = this.api.fontList[0];
    }
    this.fontSize = parseInt((px2pt(firstCell.size, this.editor.template.dpi)).toString(), 10);
    eleItem.on('activeCellChange', cell => {
      this.fontFamily = this.api.fontList.find(item => item.fontId === parseInt(cell.family.split('_')[1], 10));
      if (!this.fontFamily) {
        this.fontFamily = this.api.fontList[0];
      }
      this.fontSize = parseInt((px2pt(cell.size, this.editor.template.dpi)).toString(), 10);
    });
  }
  /**
   *
   * 获取当前选中颜色的颜色对象
   * @returns {any[]}
   */
  getColors(): any {
    const eleItem = this.editor.activeElements[0];
    const styles = eleItem.options.style;
    const colorArr = [];
    for (const item of styles) {
      if (item.background && !colorArr.find(color => color.oColor === item.background.oColor)) {
        colorArr.push(item.background);
      }
      if (item.borderTopColor && !colorArr.find(color => color.oColor === item.borderTopColor.oColor)) {
        colorArr.push(item.borderTopColor);
      }
      if (item.borderBottomColor && !colorArr.find(color => color.oColor === item.borderBottomColor.oColor)) {
        colorArr.push(item.borderBottomColor);
      }
      if (item.borderLeftColor && !colorArr.find(color => color.oColor === item.borderLeftColor.oColor)) {
        colorArr.push(item.borderLeftColor);
      }
      if (item.borderRightColor && !colorArr.find(color => color.oColor === item.borderRightColor.oColor)) {
        colorArr.push(item.borderRightColor);
      }
    }
    return colorArr;
  }
  /**
   * 事件:倾斜被单击
   */
  onItalicClick() {
    this.italic = !this.italic;
    const eleItem = this.editor.activeElements[0];
    if (this.activeCell) {
      this.activeCell.italic = this.italic;
    }
    // 触发更改事件
    // eleItem.emit('modified', {
    //   type: 'colorChange',
    //   target: eleItem
    // });
  }

  /**
   * 事件:当颜色改变是
   * @param color 颜色对象
   * @param color 新颜色
   */
  onColorChange(colorItem, newColor, fireEvent = true): void {
    const eleItem = this.editor.activeElements[0];
    // 修改颜色
    const styles = eleItem.options.style;
    for (const item of styles) {
      if (item.background && item.background.oColor === colorItem.oColor) {
        item.background.nColor = newColor;
      }
      if (item.borderTopColor && item.borderTopColor.oColor === colorItem.oColor) {
        item.borderTopColor.nColor = newColor;
      }
      if (item.borderBottomColor && item.borderBottomColor.oColor === colorItem.oColor) {
        item.borderBottomColor.nColor = newColor;
      }
      if (item.borderLeftColor && item.borderLeftColor.oColor === colorItem.oColor) {
        item.borderLeftColor.nColor = newColor;
      }
      if (item.borderRightColor && item.borderRightColor.oColor === colorItem.oColor) {
        item.borderRightColor.nColor = newColor;
      }
    }
    // 触发更改事件
    // if (fireEvent) {
    //   eleItem.emit('modified', {
    //     type: 'colorChange',
    //     target: eleItem
    //   });
    // }
  }
  /**
   * 文本颜色被修改
   * @param newColor 新颜色
   * @param fireEvent 是否触发事件
   */
  onTextColorChange(newColor, fireEvent = true) {
    const eleItem = this.editor.activeElements[0];
    this.activeCell.color.nColor = newColor;
    // 触发更改事件
    // if (fireEvent) {
    //   eleItem.emit('modified', {
    //     type: 'colorChange',
    //     target: eleItem
    //   });
    // }
  }

  /**
   * 事件:当字体被修改
   * @param font 字体
   */
  onFontChange(font) {
    const eleItem = this.editor.activeElements[0];
    this.fontFamily = font;
    this.activeCell.family = 'font_' + font.fontId;
    // eleItem.emit('modified', {
    //   type: 'fontChange',
    //   target: eleItem
    // });
  }
  /**
   * 事件:字号被修改
   * @param value 新的字号
   */
  onFontSizeChange(value) {
    const eleItem = this.editor.activeElements[0];
    this.fontSize = value;
    const ptNum = pt2px(value, this.editor.template.props.unit === 'px' ? 72 : 300);
    this.activeCell.size = ptNum;
    // eleItem.emit('modified', {
    //   type: 'sizeChange',
    //   target: eleItem
    // });
  }
  /**
   * 事件:对齐被单击
   * @param event 鼠标事件
   */
  onAlignClick(event: MouseEvent) {
    const eleItem = this.editor.activeElements[0];
    if (!eleItem.states.editMode.text) {
      eleItem.enterTextEditMode();
      eleItem.exitCellEditMode();
    }
    const modal = new Modal({
      x: event.clientX - 146 / 2,
      y: event.clientY + 20,
      width: 113,
      height: 62,
      component: TableAlignToolComponent
    });
    this.ui.add(modal);
  }
  onFontDistanceToolClick(event: MouseEvent) {
    const eleItem = this.editor.activeElements[0];

    const modal = new Modal({
      x: event.clientX - 260 / 2,
      y: event.clientY + 20,
      width: 260,
      height: 107,
      component: TableDistanceToolComponent
    });
    this.ui.add(modal);
  }
}
