
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';
import { Modal } from '@libs/editor/src/lib/models/ui/modal';

@Component({
  selector: 'app-line-length-tool',
  templateUrl: './line-length-tool.component.html',
  styleUrls: ['./line-length-tool.component.scss']
})
export class LineLengthToolComponent implements OnInit {
  @Input() parentModal: Modal;
  public lock = false;
  public beforeSize = {
    width:0,
    height:0
  };
  public value:any = 0;
  public height:any = 0;
  constructor(public editor: EditorService) { }

  ngOnInit(): void {
    this.value = this.editor.activeElements[0].options.lineLength || '';
  }
  /**
   * 输入宽高值
   */
  onlineLengthChange(e) {
    this.value = e.target.value;
    const el = this.editor.activeElements[0];
    const oldWidth = el.props.width;

    el.options.lineLength = parseInt(e.target.value);
    el.states.html = el.toHTML();
    const scale = el.props.width / el.props.oWidth;

    el.options.fontSize = (el.options.fontSize * scale);
    el.options.strokeWidth *= scale;

    // 获取bbox
    const bbox = el.getBBox();
    el.props.width = el.props.oWidth = bbox.width;
    el.props.height = el.props.oHeight = bbox.height;
    // 更新x坐标
    if (el.options.textAlign === 'right') {
      const offsetX = el.props.width - oldWidth;
      el.props.x -= offsetX;
    } else if (el.options.textAlign === 'center') {
      const offsetX = el.props.width - oldWidth;
      el.props.x -= offsetX / 2;
    }

    el.emit('modified', {
      type: 'lineLength',
      target: el,
      stopPropagation: this.editor.activeElements.length > 1
    });
  }

  /**
   * 取消按钮被单击
   */
  onCancelClick(): void {
    this.parentModal.close();
  }
}
