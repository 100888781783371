import { AppData, appReducer } from './app.reducer';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment.hmr';
import { storeFreeze } from 'ngrx-store-freeze';
import { RouterStateUrl } from '@libs/core/src/lib/util/RouterSerializer';

export interface AppState {
  app: AppData;
  router: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<AppState> = {
  app: appReducer,
  router: routerReducer
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [storeFreeze]
  : [];

export const selectRouter = createFeatureSelector<
  RouterReducerState<RouterStateUrl>
>('router');
export const selectRouterStateParam = createSelector(
  selectRouter,
  (state: RouterReducerState<RouterStateUrl>) => state.state.params
);
export const selectRouterUrl = createSelector(
  selectRouter,
  (state: RouterReducerState<RouterStateUrl>) => state.state.url
);
