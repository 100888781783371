import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery'
import { Calendar } from '@libs/editor/src/lib/models/element/calendar';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';
import { monthSignData } from '@libs/editor/src/lib/utils/calender/holidayData';
import { Svg } from '@libs/editor/src/lib/models/element/svg';
@Component({
  selector: 'app-replace-calendar',
  templateUrl: './replace-calendar.component.html',
  styleUrls: ['./replace-calendar.component.scss']
})
export class ReplaceCalendarComponent implements OnInit {
  @Input() eleItem;
  @Input() type;
  public year = 0;
  public month = 0;
  public calendarList:any = [];
  public currentTab = '';
  public thumbnailWidth = 264;
  public loading = false;
  public calendarCid = '388';
  public calendarTitleCid = '389';
  constructor(
    public http:HttpClient,
    public editor:EditorService
  ) { }

  ngOnInit() {
    this.currentTab = 'calendar';
    this.year = this.eleItem.options.year;
    this.month = this.eleItem.options.month;
    if (this.type === 'calendar'){
      this.getCalendarTplList();
    } else if (this.type === 'calendarTitle'){
      this.getCalenderTitle();
    }
  }

  /**
   * 获取日历列表
   */
  async getCalendarTplList(){
    const params = { cid:this.calendarCid, page: '1', coop: '' };
    this.loading = true;
    params['page_size'] = 200;
    params['status'] = '20';
    const res = await this.http
      .get('/material-official', {
        params,
        observe: 'response'
      })
      .toPromise();
    this.calendarList = res.body;
    for (let item of this.calendarList){
      await $.getJSON(item.filePath).then(res => {
        item['html'] = this.calendarJSONToHtml(res);
        item['json'] = res;
      })
    }
    this.loading = false;
  }

  /**
   * 获取日历标题列表
   */
  async getCalenderTitle(){
    this.loading = true;
    const params = { cid:this.calendarTitleCid, page: '1', coop: '' };
    params['page_size'] = 200;
    params['status'] = '20';
    const res = await this.http
      .get('/material-official', {
        params,
        observe: 'response'
      })
      .toPromise();
    this.calendarList = res.body;
    this.loading = false;
  }
  /**
   * 获取缩略图样式
   * @param item
   */
  getThumbnailStyle(item){
    if (!item.json) return ;
    return {
      width:this.thumbnailWidth+'px',
      height:item.json.height*this.thumbnailWidth/item.json.width+'px'
    }
  }

  /**
   * 修改日历标题
   * @param item
   */
  async changeCalendarTitle(item) {
    let pages = this.editor.template.pages;
    if (this.editor.activeElements.length === 0) return;
    let oldId = this.editor.activeElements[0].options.source;
    for (const page of pages) {
      for (const eleItem of page.elements) {
        if (eleItem.options.hasOwnProperty('isCalendarTitle') && eleItem.options.isCalendarTitle) {
          if (item.filePath.indexOf('.svg') !== -1) {
            if(oldId == eleItem.options.source){
              eleItem.options.url = item.filePath;
              eleItem.options.source = item.fileId;
              eleItem.options.colors = [];
              eleItem.props.width = eleItem.props.height*item.width/item.height;
              eleItem.props.oWidth = item.width;
              eleItem.props.oHeight = item.height;
              eleItem['loadSvg']();
            }
          }
        }
      }
    }
    //添加快照
    this.editor.snapshot();
  }
  /**
   * 改变日历样式
   * @param item
   */
  changeCalendar(item){
    //this.eleItem.props.width = this.eleItem.props.width;
    let pages = this.editor.template.pages;
    if (this.editor.activeElements.length === 0) return;
    let oldId = this.editor.activeElements[0].options.fileId;
    for (const page of pages){
      for (const eleItem of page.elements){
        if (eleItem.type === 'calendar') {
          if (!eleItem.options.hasOwnProperty('fileId') || eleItem.options.fileId == oldId) {
            let tplJSON = JSON.parse(JSON.stringify(item.json));
            let radio = eleItem.props.oWidth / tplJSON.width;
            eleItem.options.templateData = tplJSON;
            eleItem.options.color = tplJSON.color;
            eleItem.options.url = item.filePath;
            eleItem.options.fileId = item.fileId;
            eleItem.options.scale *= radio;
            eleItem.options.scaleWidth = (eleItem.props.width*this.editor.template.scale)/eleItem.options.scale;
            if (eleItem.options.column !== tplJSON.column || eleItem.options.oHeight != tplJSON.height) { // 行数更改且更换的高度不一样时
              eleItem.props.height = eleItem.props.width*tplJSON.height/item.json.width;
              eleItem.options.column = tplJSON.column;
            }
            eleItem.props.oWidth = tplJSON.width;
            eleItem.props.oHeight = tplJSON.height;
            eleItem.states.html = eleItem.toHTML();
          }
        }
      }
    }
    //添加快照
    this.editor.snapshot();
  }

  /**
   * 生成实时缩略图
   * @param josn
   */
  calendarJSONToHtml(josn){
    const scale = this.editor.template.scale;
    let templateData = josn;
    let width = this.thumbnailWidth;
    let height = templateData.height*width/templateData.width;
    let x = 0;
    let y = 0;
    const calendar = new Calendar({
      id: this.editor.getNewID(),
      props: {
        x, y, width, height,
        oWidth: templateData.width,
        oHeight: templateData.height
      },
      options: {
        scale: width/templateData.width,
        templateData:templateData,
        column:templateData.column,
        year:this.year,
        month:this.month,
        color:templateData.color
      }
    });
    calendar.set(calendar.params);
    calendar.onCreated();
    return calendar.toHTML();
  }
  // onTabHeaderItemClick(name){
  //   this.currentTab = name;
  // }
}
