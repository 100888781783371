import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectRouterUrl } from '../../../../../apps/frontend-new/src/app/+state/root.reducer';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RouterUrlService {
  isSearch: boolean;
  params: Map<string, string>;
  data$: Observable<any>;
  url: string;
  constructor(
    private store: Store<any>,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.isSearch = false;
    this.data$ = store
      .select(selectRouterUrl)
      .pipe(
        map(data => this.prepareParam(data))
      );
    // .subscribe(data => this.params = data);
    this.data$.subscribe(data => (this.params = data));
  }
  /**
   * 根据参数生成新的Url 静态
   */
  urlParamStatic(param: { name: string, value: any }[],  clear?: boolean): string {
    const newParam = new Map();
    !clear && this.params.forEach((value, index) => {
      newParam.set(index, value);
    });
    param.forEach(item => {
      // 重置某些参数

        if (item.name === 'k') {
          newParam.clear();
        }

        if (item.name === 'kk' || item.name === 'kb') newParam.delete('k');

        if (item.name !== 'p') {
          newParam.delete('p');
        }

        if (item.value) {
          newParam.set(item.name, item.value);
        } else {
          newParam.delete(item.name);
        }
      // }
      // else {
      //   if (item.name === 'pr') newParam.clear();
      //   else newParam.delete(item.name);
      // }
    });
    return this.prepareUrl(newParam);
  }

  /**
   * 根据参数生成新的Url Observe
   */
  getUrlParam(param: { name: string, value: any }[], clear?: boolean): Observable<string> {
    return this.data$.pipe(
      map(data => {
        const newParam = new Map();
        // 生成新的Map
        !clear && data.forEach((value, index) => {
          newParam.set(index, value);
        });
        param.forEach(item => {
          // 重置某些参数
          if (item.value) {
            if (item.name == 'k') {
              newParam.delete('kk');
              newParam.delete('kb');
            }
            newParam.set(item.name, item.value);
          } else {
            if (item.name === 'pid') newParam.clear();
            else newParam.delete(item.name);
          }
        });

        return this.prepareUrl(newParam);
      })
    );
  }

  /**
   * 处理url参数
   * @param {string} url
   * @returns {Map<string, number>}
   */
  prepareParam(url: string): Map<string, number> {
    // 处理URL
    const queryMap = new Map();
    const match = url.match(/^\/(template\/list|spec-list|test-list)\/[\w+\-(\w|\d)\-?]+\.html.*?$/);
    this.url = match ? match[1] : null;
    if (!match) {
      return queryMap;
    }

    const param = url.replace(/^\/(?:template\/list|spec-list|test-list)\/(.*)\.html.*/, '$1');
    if (param === url) return queryMap;
    if (param.search('-') < 0) {
      return queryMap;
    }

    const routerParam = param.split('-');
    if (length in routerParam) {
      routerParam.forEach((value, index, array) => {
        if (index % 2 !== 0) return;
        let val = array[index + 1];
        if (value === 'k' || value === 'kk' || value === 'kb' || value === 'co') {
          val = val.replace(/_/g, '=');
          val = decodeURIComponent(atob(val));
        }
        queryMap.set(value, val);
      });
    }
    return queryMap;
  }

  /**
   * 整合Map成Url
   * @param {Map<string, number>} data
   * @returns {string}
   */
  prepareUrl(data: Map<string, string>): string {
    // 整合Url
    let url = '';
    data.forEach((value, index) => {
      if (index === 'k' || index === 'kk' || index === 'kb' || index === 'co') {
        value = btoa(encodeURIComponent(value));
        value = value.replace(/=/g, '_');
      }
      url += '-' + index + '-' + value;
    });
    return url.length > 0 ? (url.substr(1) + '.html') : '';
  }

  /**
   * 获取keywords
   * @returns {Observable<string>}
   */
  getKey(key: string): Observable<string> {
    return this.data$.pipe(
      map(data => data.get(key))
    );
  }

  getClassify(): Observable<string> {
    return this.data$.pipe(map(data => data.get('pid')));
  }

  getFrommarket(): Observable<string> {
    return this.data$.pipe(
      map(data => {
        return data.get('fm');
      })
    );
  }

}
