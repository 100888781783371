import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TemplateRoutingModule } from './template-routing.module';

import { TemplateComponent } from './template.component';
import { ListComponent } from './list/list.component';
import { HomeResolverService } from './services/home-resolver.service';
import { ListMenuComponent } from './list-menu/list-menu.component';
import { RouterUrlService } from '@libs/core/src/lib/services/router-url.service';
import { TemplateService } from './services/template.service';
import { TemplateInfoResolverService } from './services/template-info-resolver.service';
import { TemplateCenterComponent } from './template-center/template-center.component';
import { CategoryService } from '@libs/core/src/lib/services/template/category.service';
import { TemplateOfficialService } from '@libs/core/src/lib/services/template/template-official.service';
import { QueryUrlPipe } from '@libs/shared/src/lib/pipes/query-url.pipe';
import { SharedModule } from '@libs/shared';
import { TemplateDetailService } from '@libs/shared/src/lib/components/template-detail/template-detail.service';
import { FormsModule } from '@angular/forms';
import { DiyCenterComponent } from './diy-center/diy-center.component';
import { QRCodeModule } from 'angularx-qrcode';
import { DetailComponent } from './detail/detail.component';
import { ClassifyComponent } from './classify/classify.component'

const DECLARATIONS = [
  TemplateCenterComponent,
  TemplateComponent,
  ListComponent,
  ListMenuComponent,
  DiyCenterComponent,
  QueryUrlPipe,
  DetailComponent,
  ClassifyComponent
];

@NgModule({
  imports: [CommonModule, TemplateRoutingModule, SharedModule, FormsModule, QRCodeModule],
  declarations: [
    ...DECLARATIONS,
    ClassifyComponent,
  ],
  providers: [
    CategoryService,
    RouterUrlService,
    TemplateOfficialService,
    TemplateService,
    HomeResolverService,
    TemplateInfoResolverService,
    TemplateDetailService
  ],
  entryComponents: []
})
export class TemplateModule {}
