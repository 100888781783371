import { TWindowComponent } from '../../components/common-ui/t-window/t-window.component';
import { UserInterfaceItem } from '../base/user-interface-item';
export class Window extends UserInterfaceItem {
  public type = 'window';
  constructor(params = null) {
    super(TWindowComponent);
    this.params = {
      // 标题
      title: '未命名窗口',
      // 是否允许最小化
      minimize: false,
      // 是否关闭
      closeAble: true,
      // 是否在任务栏显示
      taskbar: true,
      // 是否为焦点窗口(禁止操作其他区域)
      backdrop: false,
      // 是否允许缩放
      resize: false,
      // 默认窗口皮肤
      skin: 'material',
      // 样式
      ...params
    };
    if (params.x === undefined) {
      // 如果没有传入坐标,则设置为居中
      this.params.x = window.innerWidth / 2 - params.width / 2;
    }
    if (params.y === undefined) {
      // 如果没有传入坐标,则设置为居中
      this.params.y = window.innerHeight / 2 - params.height / 2;
    }
    this.states = {
      // normal=正常显示 minimize=最小化 maxmize=最大化
      show: 'normal'
    };
  }
  public close() {
    this.parent.remove(this);
  }
}
