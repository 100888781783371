import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AppActionTypes, ModalClose, Personal } from './app.actions';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { AuthActionTypes } from '@libs/core/src/lib/+state/auth.actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AppEffects {

  /**
   * 个人版
   * @type {Observable<any>}
   */
  @Effect({ dispatch: false })
  dataPersonal$ = this.actions$.pipe(
    ofType(AppActionTypes.PersonalAction),
    tap(action => {
      this.localStorage.setItem('_version', {version: 'personal', teamId: 0}).subscribe();
    })
  );

  /**
   * 团队版
   * @type {Observable<any>}
   */
  @Effect({ dispatch: false })
  dataTeam$ = this.actions$.pipe(
    ofType(AppActionTypes.TeamAction),
    tap((action: AppActionTypes.TeamAction) => {
      this.localStorage.setItem('_version', {version: 'team', teamId: action[ 'payload' ]}).subscribe();
    })
  );

  /**
   * 退出登录监听
   * @type {Observable<any>}
   */
  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType(AuthActionTypes.Logout),
    tap(action => {
      this.store.dispatch(new ModalClose());
      this.store.dispatch(new Personal());
      if(this.router.url.indexOf('/member') != -1 || location.href.indexOf('/member') != -1) {
        this.router.navigate(['/template/center']);
      }else if(this.router.url.indexOf('index') != -1) {
        this.router.navigate(['/']);
      }
    })
  );

  constructor(
    private store: Store<any>,
    private router: Router,
    private actions$: Actions,
    private localStorage: LocalStorage,
    private message: NzMessageService
  ) {}
}
