import { Component, OnInit, HostListener } from '@angular/core';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { Store } from '@ngrx/store';
import { AuthState } from '@libs/core/src/lib/+state/auth.reducer';
import { Login, Logout } from '@libs/core/src/lib/+state/auth.actions';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { Subscription } from 'rxjs';
import { TemplateService } from '../services/template.service';
import { Classify } from '@libs/core/src/lib/models/classify';
import Swiper from '../../../../../../../node_modules/swiper/dist/js/swiper.js';
import { Adv } from '@libs/core/src/lib/models/adv';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { RecommendTem } from '@libs/core/src/lib/models/recommendTem';
import { DesignParamService } from '@libs/core/src/lib/services/design-param.service';
import { TemplateDetailService } from '@libs/shared/src/lib/components/template-detail/template-detail.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CodeService } from '@libs/core/src/lib/services/code.service';
import { ClipboardService } from 'ngx-clipboard';
import * as Color from 'color';
import { MemberManageService } from '../../member/services/member-manage.service';
import { RouterUrlService } from '@libs/core/src/lib/services/router-url.service';
import { ClassifyService } from '@libs/core/src/lib/services/template/classify.service';

@Component({
  selector: 'app-diy-center',
  templateUrl: './diy-center.component.html',
  styleUrls: ['./diy-center.component.scss'],
  providers: [
    RouterUrlService
  ]
})
export class DiyCenterComponent implements OnInit {
  date = new Date();
  ismenuEnter = [];
  card_url: string;
  isenter = false;
  isenter1 = false;
  isall = false;
  sliding: Number;
  showTopSearch:boolean = false;
  swiper: Swiper;
  topSwiper: Swiper;
  keywords: string;
  hostUrl: string;
  advTitle: string;
  logoUrl: string;
  chooseAdv = [];
  advNav = [];
  classifyList: Classify[];
  swiperList: Adv[];
  downadvList: Adv[];
  recommendList: RecommendTem[];
  topSubscription: Subscription;
  classifySubscription: Subscription;
  advSubscription1: Subscription;
  recommendSubscription: Subscription;
  backgroundUrl;
  showFooter = false;
  constructor(
    public classify: ClassifyService,
    public coopService: CoopService,
    private store: Store<AuthState>,
    public authService: AuthService,
    public routerUrlService: RouterUrlService,
    private router: Router,
    public modalService: ModalService,
    public manageService: MemberManageService,
    private templateService: TemplateService,
    public designService: DesignParamService,
    private templateDetailService: TemplateDetailService,
    private message: NzMessageService,
    private code: CodeService,
    private clipboardService: ClipboardService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getCardUrl();
    this.hostUrl = window.location.host;
    this.topSubscription = this.templateService.diyHead()
      .subscribe(data => {
        if (data) {
          this.advTitle = data.advTitle;
          this.logoUrl = data.logoUrl;
          this.chooseAdv = data.linkContent ? JSON.parse(data.linkContent) : [];
          this.advNav = data.navLink ? JSON.parse(data.navLink) : []
        }
      });
    this.classifySubscription = this.templateService.hotClassify()
      .subscribe(data => {
        this.classifyList = data;
      });
    this.advSubscription1 = this.templateService.advDiy({type: 2})
      .subscribe(data => {
        this.swiperList =  data;
        // this.swiper = new Swiper('.swiper-container', {
        //   autoplay: 5000,
        // })
        // this.backgroundUrl = data[0].fileUrl;
        this.creatSwiper();
      });
    this.advSubscription1 = this.templateService.advDiy({type: 1})
      .subscribe(data => {
        this.downadvList = data;
      });
    this.recommendSubscription = this.templateService.recommendTem()
      .subscribe(data => {
        data.forEach(list =>{
          if(list.childs && list.childs.length > 0) {
            list.template = list.childs[0].template;
            list.childs[0].selected = true;
          }
        })
        this.recommendList = data;
        this.showFooter = true;
      });
      // this.swiper = new Swiper('.swiper-container', {
      //   autoplay: {
      //     stopOnLastSlide: false,
      //     disableOnInteraction: false
      //   },
      //   slidesPerView :5,
      //   spaceBetween : 30, 
      //   loop: false,
      //   observer: true,
      //   observeParents: true,
      //   pagination: {
      //     el: '.swiper-pagination',
      //     clickable : true
      //   },
      //   navigation: {
      //     nextEl: '.swiper-button-next',
      //     prevEl: '.swiper-button-prev'
      //   }
      // });
  }
  @HostListener('document:scroll', ['$event'])
  documentScroll(): void {
    this.sliding = document.documentElement.scrollTop || document.body.scrollTop;
    if(Number(this.sliding) < 300) {
      this.showTopSearch = false;
      if(this.swiperList && this.swiperList.length) {
        this.creatSwiper();
      }
    } else {
      this.showTopSearch = true
    }
    
  }

  getCardUrl() {
    const card_cid = this.classify.products
      .find(item => item.classify.classifyId == '2')
      .classifyConfig
      .find(item => item.classifyId == 50)
      .id;
    this.card_url = this.routerUrlService.urlParamStatic([{name: 'pid', value: 2}, {name: 'pr', value: 50}, {name: 'cid', value: card_cid}])
  }

  logout(): void {
    this.store.dispatch(new Logout());
  }

  login(): void {
    this.store.dispatch(new Login());
  }

  search(event?) {
    if(!this.keywords) {
      this.message.info('请输入搜索词')
      return
    }
    if (event && event.code !== 'Enter') return;
    this.routerUrlService.getUrlParam([{ name: 'k', value: this.keywords }], true)
      .pipe(take(1))
      .subscribe(
        data => {
          let newWindow = window.open();
          newWindow.onload = function() {
            newWindow.scrollTo(0, 0);
          };
          newWindow.location.href = '/template/list/' + data;
        }
      );
  }

  searches(classify: Classify, name): void {
    this.routerUrlService.getUrlParam([{name: 'pid', value: classify.pid ? classify.pid : classify.classifyId},{name: 'pr', value: classify.pid ? classify.classifyId : ''}, {name: 'cid', value: classify.pid ? classify.classifyConfig.id : ''}, { name: 'kk', value: name }, { name: 'p', value: '' }])
      .pipe(take(1))
      .subscribe(data => {
        this.router.navigate(['/template/list/', data]);
      });
  }

  advHover(): void {
    this.swiper.autoplay.stop();
  }
  advnoHover(): void {
    this.swiper.autoplay.start();
  }

  favoriteTemplate(template: TemplateOfficial, event): void {
    event.preventDefault();
    event.stopPropagation();
    if (template.isFavorite) {
      // 设置为已收藏
      template.isFavorite = 0;
      this.templateDetailService.unFavTemplate(template.templateId)
        .subscribe(
          () => {
            this.authService.updateUser({...this.authService.user, favoriteCount: this.authService.user.favoriteCount - 1});
            this.message.success('已取消收藏!');
          },
          err => {
            template.isFavorite = 1;
            this.message.error(this.code.getOne(err));
          }
        );
    } else {
      // 设置为已收藏
      template.isFavorite = 1;
      this.templateDetailService.favTemplate(template.templateId)
        .subscribe(
          () => {
            this.authService.updateUser({...this.authService.user, favoriteCount: this.authService.user.favoriteCount + 1});
            this.message.success('模版收藏成功!');
          },
          err => {
            template.isFavorite = 0;
            this.message.error(this.code.getOne(err));
          }
        );
    }
  }
  creatSwiper(){
    setTimeout(() => {
      this.swiper = new Swiper('.swiper-container', {
        effect : 'fade',
        speed: 1000,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        loop: false,
        observer: true,
        observeParents: true,
      });
    }, 10);
  }

  openTemplateList(){
    this.router.navigate(['/template/list'])
  }

  enter(): void {
    this.isenter = true;
  }

  over(): void {
    this.isenter = false;
  }

  enter1(): void {
    this.isenter1 = true;
  }

  over1(): void {
    this.isenter1 = false;
  }

  menuEnter(i): void {
    this.ismenuEnter[i] = true;
  }

  menuLeave(i): void {
    this.ismenuEnter[i] = false;
  }

  menuAllEnter(): void {
    this.isall = true;
  }

  menuAllLeave(): void {
    this.isall = false;
  }

  clipboard(tel: string) {
    this.clipboardService.copyFromContent(tel);
    this.message.success('复制成功!');
  }

  openQQ(qq: string) {
    window.open(`tencent://message/?Site=baidu.com&uin=${qq}&Menu=yes`);
  }

  templateDetail(template: TemplateOfficial): void {
    let newWindow = window.open();
    newWindow.location.href = '/template/detail/' + template.templateId;
    newWindow.onload = function() {
      newWindow.scrollTo(0, 0);
    };
  }

  templateEditor(template: TemplateOfficial): void {
    if(!this.authService.isGuest) {
      let newWindow = window.open();
      newWindow.onload = function() {
        newWindow.scrollTo(0, 0);
      };
      let url = this.designService.getMemberCreateUrl(template.templateId, template);
      newWindow.location.href = url;    
    } else {
      this.modalService.loginModal();
    }
  }

  openDesignerDiy(){
    if(!this.authService.isGuest) {
      this.modalService.openDesignerDiy(this.classify.products)
    } else {
      this.modalService.loginModal();
    }
  }

  openDiy(){
    console.log(this)
    if(!this.authService.isGuest) {
      this.modalService.openDiy()
    } else {
      this.modalService.loginModal();
    }
  }

  changeList(i,idx,child:RecommendTem){
    this.recommendList[i].childs.forEach(child => {
      child.selected = false;
    })
    child.selected = true;
    this.recommendList[i].template = this.recommendList[i].childs[idx].template;
    //console.log(this.recommendList[i])
  }

}
