import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixedNumber'
})
export class FixedNumberPipe implements PipeTransform {
  transform(value: any, fixed = 2): any {
    if (fixed === 0) {
      return parseInt(value, 10);
    }
    return parseFloat(value).toFixed(fixed);
  }
}
