import { TextEditComponent } from '../../components/edit-tools/text-edit/text-edit.component';
import { Element } from '../base/element';
import * as $ from 'jquery';
import { ToolBarTextComponent } from '../../components/editor-ui/t-tool-bar/tools/tool-bar-text/tool-bar-text.component';
import CircleType from './../../utils/misc/CircleType.js';
import * as Color from 'color';
import emojiRegex from "emoji-regex";
import { getBase64fromImg, px2pt } from '@libs/editor/src/lib/utils/misc/common';
import warpSpanText from './warpSpan.js'
/**
 * 文本元素
 */
export class Text extends Element {
  public type = 'text';
  onCreated() {
    //console.log('this.options.text',this.options.text);
    this.config.update = false;
    this.config.markAble = false;
    this.config.resizeFromInput = false;
    this.config.toolbar = ToolBarTextComponent;
    this.states = {
      ...this.states,
      // 是否正在编辑
      editMode: false
    };
    this.config.styles = {
      ...this.config.styles,
      textRendering: 'optimizeLegibility',
      whiteSpace: 'nowrap',
      wordWrap: 'break-word'
    };
    const defaultCorner = {
      tl: false,
      tr: false,
      ml: false,
      mr: true,
      bl: false,
      br: true,
      mt: false,
      mb: false,
      rotate: true
    };
    if (this.options.mode == 'v'){
      defaultCorner.mr = false;
      defaultCorner.mb = true;
    }
    this.config.corner = defaultCorner;
    // 双击:进入编辑模式
    this.on('dblclick', this.enterEditMode.bind(this));
    // 取消选择:退出编辑模式
    this.on('deselected', this.exitEditMode.bind(this));
    // 任意角点被选择
    this.on('cornermousedown', this.onCornerMouseDown.bind(this));
    // 进入,退出编辑状态以及修改时更新鼠标状态
    this.on('enterEdit exitEdit modified', e => {
      this.config.corner = defaultCorner;
    });
    this.on('scaling', e => {
      if (!this.options.isNewText){
        this.options.isNewText = true;
      }
      if (e.corner === 'ml' || e.corner === 'mr' || e.corner === 'mb') {
        if (this.options.curve){
          this.props.oWidth = this.props.width / this.options.scale;
          const curveobj = this.getCurvedText(this.options.text, this.props.width, this.options.curve.dir);
          this.options.curve.html = curveobj.html;
          this.props.oHeight = this.props.height = curveobj.height;
          this.states.html = this.toHTML();
        }else{
          if (this.options.mode === 'd'){
            this.options.mode = 'h';
          }
          this.props.oWidth = this.props.width / this.options.scale;
          let textInfo = this.getTextBoxInfo(
            window['editor']['zoom']
          );
          if(this.options.mode == 'h'){
            // 如果高度超过输入文字高度,则增加元素高度
            this.props.height = textInfo.height;
            this.config.minWidth = textInfo.minWidth;
            this.props.oHeight = this.props.height / this.options.scale;
          }else{
            // 如果高度超过输入文字高度,则增加元素高度
            this.props.width = textInfo.width;
            this.props.oWidth = this.props.width / this.options.scale;
          }
          if (!this.states.editMode){
            this.states.html = this.toHTML();
            // if(this.options.mode === 'v' && e.corner === 'mb' ) {
            //   this.states.html = this.toHTML({flag:true});
            // } else {
            //   this.states.html = this.toHTML();
            // }
          }
        }
      }else{
        if (this.options.curve){
          this.options.scale = this.props.width / this.props.oWidth;
          // this.applyScaleToHTML();
          const curveobj = this.getCurvedText(this.options.text, this.props.width, this.options.curve.dir);
          this.options.curve.html = curveobj.html;
          this.props.oHeight = this.props.height = curveobj.height;
          this.states.html = this.toHTML();
        }else{
          // 按照缩放比例对文本进行缩放
          let $text = $(this.options.text);
          const fontSize = parseFloat($text.css('font-size'));
          const dpi = window['editor'].template.props.unit === 'px' ? 72 : 300;
          if ((px2pt(fontSize, dpi) * this.props.width / this.props.oWidth) <= 4.1){
            this.props.width = this.props.oWidth * this.options.scale;
            this.props.height = this.props.oHeight * this.options.scale;
            this.config.minWidth = this.props.width;
          }
          this.options.scale = this.props.width / this.props.oWidth;
          this.states.html = this.toHTML();
        }
      }
    });
    if(this.id == "xzic8khKNZ") {
      //console.log('this1',this)
    }
    if (this.options.text.match(/color="#[0-9A-z]{12}/)) {
      this.options.text = this.options.text.replace(/color="#([0-9A-z]{12})"/g, function($0, $1) {
        const hex = $1.substring(0, 2) + $1.substring(4, 6) + $1.substring(8, 10)
        return `color="#${hex}"`
      })
    }
    this.states.html = this.toHTML();

  }
  public applyScaleToHTML() {
    const props = this.props,
      $text = $(this.options.text),
      scale = this.options.scale;

    // 文字等比缩放后需要更改所有元素的字号

    // 按照缩放比例对文本进行缩放
    let scaleSize =
      parseFloat($text.css('font-size'));
    $text.css('font-size', scaleSize*scale + 'px');

    // 缩放文字间距
    const letterSpacing = $text.css('letter-spacing');
    if (letterSpacing !== '') {
      $text.css('letter-spacing', parseFloat(letterSpacing) * scale + 'px');
    }
    this.options.scale = 1;
    // 更新元素宽高
    const textInfo = this.getTextInfo(
      $text[0].outerHTML,
      false
    );
    this.config.minWidth = textInfo.minWidth;
    this.props.width = this.props.oWidth = textInfo.width;
    this.props.oHeight = this.props.height;
    this.options.linesLength = textInfo.linesLength;
    this.options.text = $text[0].outerHTML;
  }

  resetPos() {
    const xArr = this.calcRotatePos('x');
    const yArr = this.calcRotatePos('y');
    const calcArr = this.calcRotatePos('calc');
    const xMin = Math.min.apply(null, xArr);
    const xMax = Math.max.apply(null, xArr);
    const yMin = Math.min.apply(null, yArr);
    const yMax = Math.max.apply(null, yArr);
    if (xMin < 3) {
      this.props.x = xArr[0] + 3 - xMin + calcArr[0];
    } else if (xMax + 3 > this.parent.width) {
      this.props.x = xArr[0] + this.parent.width - xMax - 3 + calcArr[0];
    }
    if (yMin < 3) {
      this.props.y = yArr[0] + 3 - yMin + calcArr[1];
    } else if (yMax + 3 > this.parent.height) {
      this.props.y = yArr[0] + this.parent.height - yMax - 3 + calcArr[1];
    }
  }

  calcRotatePos(type: string): number[] {
    const angle = this.props.rotation * Math.PI / 180;
    if (type == 'x') {
      const x0 = this.props.x + this.props.width / 2;
      const x1 = Math.cos(angle) * (-this.props.width / 2) - Math.sin(angle) * (-this.props.height / 2) + x0;
      const x2 = Math.cos(angle) * (this.props.width / 2) - Math.sin(angle) * (-this.props.height / 2) + x0;
      const x3 = Math.cos(angle) * (-this.props.width / 2) - Math.sin(angle) * (this.props.height / 2) + x0;
      const x4 = Math.cos(angle) * (this.props.width / 2) - Math.sin(angle) * (this.props.height / 2) + x0;
      return [x1, x2, x3, x4];
    } else if (type == 'y') {
      const y0 = this.props.y + this.props.height / 2;
      const y1 = Math.sin(angle) * (-this.props.width / 2) + Math.cos(angle) * (-this.props.height / 2) + y0;
      const y2 = Math.sin(angle) * (this.props.width / 2) + Math.cos(angle) * (-this.props.height / 2) + y0;
      const y3 = Math.sin(angle) * (-this.props.width / 2) + Math.cos(angle) * (this.props.height / 2) + y0;
      const y4 = Math.sin(angle) * (this.props.width / 2) + Math.cos(angle) * (this.props.height / 2) + y0;
      return [y1, y2, y3, y4];
    } else if (type == 'calc') {
      const calcX = Math.sin(angle) * (-this.props.height / 2) - Math.cos(angle) * (-this.props.width / 2) - this.props.width / 2;
      const calcY = -Math.sin(angle) * (-this.props.width / 2) - Math.cos(angle) * (-this.props.height / 2)  - this.props.height / 2;
      return [calcX, calcY];
    }

  }
  /**
   * 自由变形角点被按下
   * @param e 事件对象
   */
  private onCornerMouseDown(e): void {
    this.exitEditMode();
  }
  /**
   * 进入编辑模式
   */
  public enterEditMode(): void {
    //console.log('this.options:',this)
    if(this.options.curve){
      this.states.editMode = true;
      this.config.visible = false;
      this.emit('enterEdit', {
        target: this,
        // 调用this.config.tool里的对应的TextEditComponent组件
        toolComponent: TextEditComponent
      });
    }
  }
  /**
   * 退出编辑模式
   */
  public exitEditMode(): void {
    delete this.states.range;
    if (this.states.editMode) {
      if (!this.options.curve){
        $(`#${this.id}`).find('.text-wrap').attr('contenteditable',false);
        //退出编辑模式时存储编辑内容
        const $text = $(this.options.text);
        $text.attr('contenteditable', false);
        $text.find('*').removeAttr('data-family');
        //删除由于换行然后删除换行导致的字号,字体自动附加问题
        $text.find('*[style]').each(function () {
          if ($(this).css('font-size')) {
            $(this).css('font-size', '');
          }
        });
        $text.children().each(function () {
          if ($(this)[0].innerText.length > 1) {
            $(this).find('br').last().remove();
          }
        });

        // 解决父标签颜色丢失
        // const color = $text.css('color');
        // if (color === '') {
        //   $text.css('color', '#000000');
        // }
        this.options.text = $text[0].outerHTML;
        let res = this.getTextInfo(this.options.text);
        this.options.linesLength = res.linesLength;
        // 替换emoji
        this.options.text = this.options.text.replace(emojiRegex(), "");
      }else{
        this.emit('exitEdit', {
          target: this,
          toolComponent: TextEditComponent
        });
        this.config.visible = true;
      }
      this.states.editMode = false;
      this.states.html = this.toHTML();
      this.emit('modified', {
        type: 'exitEdit',
        target: this
      });
    }
  }
  /**
   * 重写:生成html文本
   */
  public toHTML({breaks = true, scale = 1 ,flag = false} = {}): string {
    //this.options.linesLength = [11,6]
    // console.log('flag',flag);
    // if((this.options.mode === 'v' && !flag) || (this.options.mode === 'h' && flag)) {
    //   [this.props.width, this.props.height] = [this.props.height, this.props.width];
    //   [this.props.oWidth, this.props.oHeight] = [this.props.oHeight, this.props.oWidth];
    // }
    if (this.options.scale && !this.states.isSvg){
      if(this.props.width / this.props.oWidth != this.options.scale){
        if (this.options.charPosArr) {
          for (let i = 0;i < this.options.charPosArr.length;i++) {
            this.options.charPosArr[i] *= ((this.props.width / this.props.oWidth) / this.options.scale)
          }
        }
        this.options.scale = this.props.width / this.props.oWidth;
      }
    }
    let textHTML = '';
    // 生成文本html
    if (this.options.curve) {
      textHTML = this.options.curve.html;
    } else {
      //textHTML = this.getBreakedText(this.options.text);

      let $text = $(this.options.text);
      // console.log('this.options.text',this.options.text);
      // console.log('$text',$text);
      if (!this.options.isNewText){
        let newText = $(this.getNewBreakedText(this.options.text));
        const scale = this.parent.parent.scale;
        if (!this.options.scale){
          // 缩放字间距
          const letterSpacing = newText.css('letter-spacing');
          if (letterSpacing !== '') {
            newText.css('letter-spacing', parseFloat(letterSpacing) * scale * this.props.width / this.props.oWidth + 'px');
            $text.css('letter-spacing', parseFloat(letterSpacing) * scale * this.props.width / this.props.oWidth + 'px');
          }
          let fontSize = parseFloat($text.css('font-size')) * scale * (this.props.width / this.props.oWidth);
          if (fontSize < 24){
            this.options.scale = fontSize/24;
            this.props.oWidth = this.props.width/this.options.scale;
            this.props.oHeight = this.props.height/this.options.scale;
            fontSize = 24;
          } else{
            this.options.scale = 1;
            this.props.oWidth = this.props.width;
            this.props.oHeight = this.props.height;
          }
          this.props.width += 0.1;
          this.props.oWidth = this.props.width / this.options.scale;
          newText.css('font-size', fontSize + 'px');
          $text.css('font-size', fontSize + 'px');
        }
        if (!$text.css('font-family')) {
          let font = this.getFontFamily($text);
          $text.css('font-family',font);
        }
        //断行
        this.options.textAlign = newText.css('text-align') || newText.find('div').css('text-align') || 'left';
        this.options.text = $text[0].outerHTML;
        this.options.isNewText = false;
        textHTML = newText[0].outerHTML;
        if (this.options.url) {
          this.states.isSvg = true;
          return `<img style="position:absolute;pointer-events:none;width:100%;height:100%" src="${this.options.url}"/>`;
        }
      } else{
        if (this.states.isSvg){
          this.states.isSvg = false;
          delete this.options.v4svg;
          delete this.options.url;
          // // 修复行高偏移
          // // const $text = $(this.options.text);
          // const scale = this.parent.parent.scale;
          // // const fontSize = parseFloat($text.css('font-size'));
          // // const lineHeight = (parseInt($text.css('line-height'), 10) - 100) / 100;
          // // 获取文字px大小
          // // this.props.y -= (lineHeight * fontSize) / scale / 2;
          let self = this;
          $text.each(function (line) {
            // 查找本行的所有标签
            $(this)
              .find('*')
              .contents()
              .each(function () {
                // 获取文字分割结果
                const $warpText = $(`<div>${self.warpSpan($(this).text())}</div>`);
                $warpText.find('span').addClass('char');
                if (this.nodeType === 3) {
                  $(this).replaceWith($warpText.html());
                }
              });
          });
          $text.find('.char').contents().unwrap();
          let xOffset = 0;
          let widthScale = 1.1;
          const align = $text.css('text-align');
          if (align === 'left') {
            xOffset = 0;
          } else if (align === 'center') {
            xOffset = (this.props.width * 0.05);
          } else if (align === 'right') {
            xOffset = (this.props.width * 0.1);
          }
          if (this.props.rotation !== 0) {
            widthScale = 1;
            xOffset = 0;
          }
          this.props.x -= xOffset;
          this.props.width *= widthScale;
          this.props.oWidth = this.props.width;
          this.options.text = $text[0].outerHTML;
        }
        const lineHeight = $text.css('line-height');
        let newText;
        if (breaks&&this.options.textAlign!='justify'&&this.options.mode != 'd'){
          newText = $(this.getNewBreakedText(this.options.text,false));
        } else{
          newText = $text
        }
        if (lineHeight
          && this.options.isNewText
          && this.options.linesLength){
          let number = parseFloat(lineHeight);
          if (lineHeight.indexOf('%') === -1){
            number *= 100;
          }
          let translate = -(number-100) * 0.005;
          if (this.options.mode === 'v'){
            newText[0].style.transform = `translate(${translate}em, 0em)`;
          } else{
            newText[0].style.transform = `translate(0em, ${translate}em)`;
          }
        }
        if (this.options.textAlign){
          newText.css('text-align',this.options.textAlign);
          newText.find('div').css('text-align',this.options.textAlign);
        } else{
          this.options.textAlign = newText.find('div').css('text-align') || newText.css('text-align');
        }
        if (this.options.directWrite && sessionStorage.getItem('directWrite') != this.options.directWrite && breaks && this.options.charPosArr && this.options.textAlign!='justify'){
          textHTML = this.getDirectWrite($text[0].outerHTML,breaks);
        }else{
          this.options.directWrite = sessionStorage.getItem('directWrite');
          textHTML = newText[0].outerHTML;
        }
      }
    }
    // 生成特效代码
    if (this.options.effect) {
      const textHTMLArr = [];
      for (const effectItem of this.options.effect) {
        const $text = $(textHTML);
        $text.css('position', 'absolute');
        if (effectItem) {
          for (const key in effectItem) {
            $text.css(key, effectItem[key]);
          }
        }
        textHTMLArr.push($text[0].outerHTML);
      }
      textHTML = textHTMLArr.join('\n');
    }
    const textScale = this.options.scale * scale;
    let percentage = this.options.curve ? '100%' : (1/textScale*100).toFixed(3) + '%';
    if(flag) {
      [this.props.width, this.props.height] = [this.props.height, this.props.width];
      [this.props.oWidth, this.props.oHeight] = [this.props.oHeight, this.props.oWidth];
    }

    return `
    <div class="text-element"
        id="${this.id}"
        style="
        width: ${percentage};
        height: ${percentage};
        transform: scale(${this.options.curve ? 1 : textScale});
        transform-origin:0% 0% 0px;
        font-size:12px;
        writing-mode:${this.options.mode === 'v' ? 'vertical-lr' : 'normal'};
        white-space: ${this.options.isNewText ? (this.options.mode === 'h' ? (breaks&&this.options.textAlign!='justify' ? 'nowrap' : 'normal'): 'nowrap') : 'pre-wrap'};
        overflow-wrap:${this.options.isNewText ? (this.options.mode === 'd' ? 'inherit' : 'break-word') : 'normal'};
        word-wrap:${this.options.isNewText ? (this.options.mode === 'd' ? 'inherit' : 'break-word') : 'normal'};
    ">${textHTML}</div>`;
  }

  async toSvg(): Promise<any> {
    if (this.options.url) {
      if (!this.states.base64) {
        this.states.base64 = await getBase64fromImg(this.options.url)
      }
      return `<svg x="${this.props.x}" y="${this.props.y}" width="${this.props.width}" height="${this.props.height}" preserveAspectRatio="none"><image width="100%" height="100%" preserveAspectRatio="none" href="${this.states.base64}"/></svg>`
    }

    const $text = $(this.states.html);
    if (!this.options.curve) {
      const scale = this.options.scale / this.parent.parent.scale;
      $text.css({
        'transform': `scale(${scale})`,
        'width': `${100/scale}%`,
        'height': `${100/scale}%`
      })
    }

    $text.removeAttr('id')
    $text.attr('xmlns', "http://www.w3.org/1999/xhtml")
    return `<foreignObject x="${this.props.x}" y="${this.props.y}" width="${this.props.width}" height="${this.props.height}">${$text[0].outerHTML}</foreignObject>`
  }

  /**
   * 获取弯曲处理的文本
   * html 原始html
   * width 当前宽度
   * dir 1向上弯曲 -1向下弯曲
   */
  getCurvedText(html, width, dir) {
    const $text = $(html);
    const fontColor =
      $text.find('*[color]').attr('color') ||
      new Color($text.css('color')).hex();
    let textColor = [];
    $text.find('*[color]').each(function () {
      if ($(this).text().length != $text.text().length) {
        textColor.push({
          color: $(this).attr('color'),
          text: $(this).text()
        })
      }
    })

    const fontFamily =
      $text.find('*[face]').attr('face') || $text.css('font-face');

    const el = document.createElement('div');
    el.style.position = 'fixed';
    el.style.pointerEvents = 'none';
    el.style.opacity = '0';
    el.style.left = '0';
    el.style.top = '0';
    el.style.zIndex = '1000';
    el.innerHTML = html;
    document.body.appendChild(el);
    const textWrap = el.getElementsByClassName('text-wrap')[0];

    textWrap['style'].fontSize = parseFloat(textWrap['style'].fontSize) * this.options.scale + 'px';
    new CircleType(textWrap)
      .radius(width * this.parent.parent.scale)
      .dir(dir)
      ._layout();
    if (textColor.length === 0) {
      textWrap['style'].color = fontColor;
    } else {
      $(textWrap).find('span').each(function() {
        textColor.forEach((item:any) => {
          if (item.text.includes($(this).text())) {
            $(this).css('color', item.color)
          }
        })
      })
    }
    textWrap['style'].fontFamily = fontFamily;
    const rect = el.getBoundingClientRect();
    const curvedHTML = el.innerHTML;

    document.body.removeChild(el);
    return {
      html: curvedHTML,
      height: rect.height / this.parent.parent.scale
    };
  }
  /**
   * 获取缩放处理后的文本
   */
  getScaleText(text): string {
    const self = this;
    const scale = this.parent ? this.parent.parent.scale : 1;
    const $text = $(`<div>${text}</div>`);
    // 按照缩放比例对文本进行缩放
    $text.find('*').each(function () {
      if ($(this).css('font-size') !== '') {
        const scaleSize = parseFloat($(this).css('font-size')) * scale;
        $(this).css('font-size', scaleSize + 'px');
      }
    });
    // 缩放字间距
    const letterSpacing = $text.find('.text-wrap').css('letter-spacing');
    if (letterSpacing !== '') {
      $text.find('.text-wrap').css('letter-spacing', parseFloat(letterSpacing) * scale + 'px');
    }
    return $text.html();
  }
  /**
   * 获取断行处理后的文本
   */
  getBreakedText(text): string {
    const self = this;
    const scale = this.parent ? this.parent.parent.scale : 1;
    const $text = $(`<div>${text}</div>`);
    if (this.options.linesLength) {
      // 获取html内容,包裹span标签
      $text.each(function (line) {
        // 查找本行的所有标签
        $(this)
          .find('*')
          .contents()
          .each(function () {
            // 获取文字分割结果
            const $warpText = $(`<div>${self.warpSpan($(this).text())}</div>`);
            $warpText.find('span').addClass('char');
            if (this.nodeType === 3) {
              $(this).replaceWith($warpText.html());
            }
          });
      });
      // 如果div里面文本不是空的,则删除结尾的br
      // $text.children().children().children().children().last('br').remove();
      // 在linesLength数组里的break位置添加手动断行
      let charIndex = 1;
      let lineIndex = 0;
      $text.find('.char').each(function () {
        if (self.options.linesLength[lineIndex] === charIndex) {
          // 获取当前元素是否为空格,如果是则display:none
          const charCode = $(this).text().charCodeAt(0);
          // console.log('A', lineIndex, $text.find('.text-wrap').children().length);
          //$(this).after('<br/>');

          charIndex = 1;
          lineIndex++;
        } else {
          charIndex++;
        }
      });
      // 搜索所有元素,如果文本是空,则删除他(兼容PDF换行问题)
      $text.find('font').each(function () {
        if ($(this).html().length === 0) {
          $(this).remove();
        }
      });
    }

    // 去掉包裹
    // $text.find('.char').contents().unwrap();
    // 按照缩放比例对文本进行缩放
    //const fontSize = parseFloat($text.find('.text-wrap').css('font-size')) * scale;
    //$text.find('.text-wrap').css('font-size', fontSize + 'px');
    // 缩放字间距
    const letterSpacing = $text.find('.text-wrap').css('letter-spacing');
    if (letterSpacing !== '') {
      $text.find('.text-wrap').css('letter-spacing', parseFloat(letterSpacing) + 'px');
    }
    $text.find('div').css('text-align',this.options.textAlign);
    $text.css('text-align',this.options.textAlign);
    return $text.html();
  }
  /**
   * 获取文本断行位置与文本区域宽高
   * @param text HTML文本
   * @param scaleAble 是否按照当前文本缩放比例对文字进行缩放
   */
  getTextInfo(text, scaleAble = true): any {
    const scale = this.parent ? this.parent.parent.scale : 1;
    // { html, width = null, height = null }
    $('body').append(
      `<div
          class="text-info-${this.id}"
          style="position:absolute;
          white-space: normal;
          word-wrap: ${this.options.mode == 'd' ? 'normal':'break-word'};
          z-index:1000"></div>`
    );
    const $hidden = $(`.text-info-${this.id}`);
    if (this.options.mode === 'h') {
      $hidden.css('width', this.props.width * scale / this.options.scale +  'px');
      $hidden.css('height','auto')
    } else if (this.options.mode === 'v') {
      $hidden.css('width','auto');
      $hidden.css('height', this.props.height * scale / this.options.scale + 'px');
    }else{
      $hidden.css('width','auto');
      $hidden.css('width','auto');
    }
    if (this.options.curve) {
      $hidden.css('width', this.props.width * scale + 'px');
    }
    $hidden.css(
      'writing-mode',
      this.options.mode === 'v' ? 'vertical-lr' : 'normal'
    );

    // 将html内容加入到hiddenText
    $hidden.html(text);
    $hidden.find('.char').unwrap();
    $hidden.contents().wrap('<font></font>');
    // 遍历每行数据,子元素进行find,获取他们的nodetype,如果=3则为文本
    const self = this;
    $hidden.each(function (line) {
      // 查找本行的所有标签
      $(this)
        .find('*')
        .contents()
        .each(function () {
          // 获取文字分割结果
          const $warpText = $(`<div>${self.warpSpan($(this).text(),false)}</div>`);
          $warpText.find('span').addClass('char');
          if (this.nodeType === 3) {
            $(this).replaceWith($warpText.html());
          }
        });
    });
    // 获取文本框高度
    let bbox = $hidden[0].getBoundingClientRect();
    //修复因为空格导致的换行问题
    let addWidth = 0;

    // 为每个字符添加坐标信息
    let lastCharPos = null;
    let lastCharTop = null;
    let lastCharCode = null;
    let lastCharWidth = 0;
    let nowLineLength = 0;
    let linesLength = [];
    const charPosArr = [];
    $hidden.find('.char').each(function () {
      const $this = $(this);
      const char = $this.text();
      const charCode = char.charCodeAt(0);
      let {width, left, top} = $this[0].getBoundingClientRect();
      // (畸形) 思源黑"fl"、"fi" "f"占2个字符位 "i\l" 0宽 其他字体字符不清楚
      if (charCode != 32 && width === 0) {
        $this.css('display', 'inline-block');
        width = $this[0].getBoundingClientRect().width;
        left -= width;
        $this.removeAttr('style')
      }

      let charPos = left;
      let charTop = top;
      if(self.options.mode == 'v') {
        [charPos,charTop] = [charTop,charPos]
      }

      //如果最后一个为空格
      if ($this.parent()[0].tagName == 'FONT'){
        if (charPos == 0 && width == 0 && $this.next().length == 0 && charCode == 32 && $this.parent().next().length == 0) {
          charPos = lastCharPos+1;
        }
        //如果第一个为空格
        if (charPos == 0 && width == 0 && $this.prev().length == 0 && $this.parent().prev().length == 0 && charCode == 32) {
          charPos = -1;
        }
      } else{
        if (charPos == 0 && width == 0 && $this.next().length == 0 && charCode == 32) {
          charPos = lastCharPos+1;
        }
        //如果第一个为空格
        if (charPos == 0 && width == 0 && $this.prev().length == 0 && charCode == 32) {
          charPos = -1;
        }
      }
      charPosArr.push(width);
      if (
        (lastCharPos > charPos && lastCharPos != null) || (lastCharPos == charPos && !(lastCharCode == 32 && lastCharWidth == 0) && !(lastCharCode != 32 && lastCharWidth == 0 && lastCharPos > 0))
      ) {
        linesLength.push(nowLineLength);
        nowLineLength = 0;
      }
      lastCharPos = charPos;
      if (charTop > lastCharTop){
        lastCharTop = charTop;
      }
      lastCharCode = charCode;
      lastCharWidth = width;
      nowLineLength++;
    });
    linesLength.push(nowLineLength);
    if (this.options.mode == 'd'){
      linesLength = [$hidden.find('.char').length]
    }
    let res = {
      linesLength,
      width: 0,
      height: 0,
      textWidth: 0,
      textHeight: 0,
      minWidth: 0,
      minHeight: 0
    };
    res.width = bbox.width / scale * this.options.scale;
    if (addWidth) {
      res.width = (bbox.width - addWidth) / scale * this.options.scale;
    }
    res.height = bbox.height / scale * this.options.scale;
    if (this.options.curve) {
      res.height = bbox.height / scale;
    }
    // if (this.options.mode == 'd'){
    //   res.width += 2;
    // }
    //去除行距带来的高度或者是宽度
    let $text = $(text);
    const lineHeight = $text.css('line-height');
    if (lineHeight
      && this.options.isNewText
      && this.options.linesLength && !this.options.curve){
      let number = parseFloat(lineHeight);
      if (lineHeight.indexOf('%') === -1){
        number *= 100;
      }
      let translate = -(number-100) * 0.005;
      let fontSize = parseFloat($text.css('font-size'));
      if (this.options.mode === 'v'){
        res.width += fontSize*translate/this.parent.parent.scale * 2;
      } else{
        res.height += fontSize*translate*this.options.scale*2/this.parent.parent.scale;
      }
    }
    let minWid = parseFloat($hidden.css('font-size')) * this.options.scale / scale;
    if (this.options.mode == 'd' && res.width < minWid){
      res.width = minWid;
    }
    // 获取纯文字的宽度或者高度
    $hidden.css('width', 'auto');
    $hidden.css('height', 'auto');
    bbox = $hidden[0].getBoundingClientRect();
    res.textWidth = bbox.width / scale * this.options.scale;
    res.textHeight = bbox.height / scale * this.options.scale;
    // 获取最小宽度和高度
    $hidden.css('width', '0px');
    $hidden.css('height', 'auto');
    if ($hidden.find('span').length > 0){
      bbox = $hidden.find('span')[0].getBoundingClientRect();
      res.minWidth = bbox.width / scale * this.options.scale;
    } else{
      res.minWidth = 0;
    }
    $hidden.css('width', 'auto');
    $hidden.css('height', '0px');
    if ($hidden.find('span').length > 0){
      bbox = $hidden.find('span')[0].getBoundingClientRect();
      res.minHeight = bbox.height / scale * this.options.scale;
    } else{
      res.minHeight = 0;
    }
    // 清空内容
    $hidden.remove();
    this.options.linesLength = res.linesLength;
    this.options.charPosArr = charPosArr;
    return res;
  }
  /**
   * 新获取文本断行位置与文本区域宽高
   * @param zoom zoom
   */
  getTextBoxInfo(zoom){
    return this.getTextInfo(this.options.text);
    // if (this.options.mode === 'd'){
    //   return this.getTextInfo(this.options.text);
    // }
    // let textBox = $(`#${this.id}`).find('.text-wrap');
    // let res = {
    //   linesLength:[],
    //   width: 0,
    //   height: 0,
    //   textWidth: 0,
    //   textHeight: 0,
    //   minWidth: 0,
    //   minHeight: 0
    // };
    // let boxInfo = textBox[0].getBoundingClientRect();
    // let scale = zoom * this.parent.parent.scale;
    // res.width = boxInfo.width / scale;
    // res.height = boxInfo.height / scale;
    // res.textWidth = res.width;
    // res.textHeight = res.height;
    // res.minWidth = parseFloat(textBox.css('font-size')) * this.options.scale / this.parent.parent.scale;
    // res.minHeight = parseFloat(textBox.css('font-size')) * this.options.scale / this.parent.parent.scale;
    // let $text = $(this.options.text);
    // const lineHeight = $text.css('line-height');
    // if (lineHeight && this.options.isNewText){
    //   let translate = -(parseFloat(lineHeight)-100) * 0.005;
    //   let fontSize = parseFloat($text.css('font-size'));
    //   if (this.options.mode === 'v'){
    //     res.width += fontSize*translate/this.parent.parent.scale * 2;
    //   } else{
    //     res.height += fontSize*translate*this.options.scale*2/this.parent.parent.scale;
    //   }
    // }
    // return res;
  }
  /**
   * 为子元素添加span标签
   * @param text 原HTML文本
   * @param nbsp 原HTML文本
   */
  warpSpan(text,nbsp = true): string {
    return warpSpanText(text,nbsp);
  }
  async replaceContentResize() {
    const $text = $(this.options.text),
      scale = this.props.width / this.props.oWidth,
      templateScale = this.parent.parent.scale;
    const family = $text.css('font-family') || $text.find('*[face]').attr('face');
    //this.states.tempText = $text[0].outerHTML;
    if (family && family.match(/font_\d+/)) {
      await window['editor'].waitLoadFont(family)
    }

    const textInfo = this.getTextInfo(
      this.options.text,
      false
    );
    this.config.minWidth = textInfo.minWidth;

    // 如果高度超过输入文字高度,则增加元素高度
    this.props.height = textInfo.height;
    this.options.linesLength = textInfo.linesLength;
  }

  /**
   * 旧文字转为新文字时同步换行
   */
  getNewBreakedText(text,nbsp = true,breaks = true){
    const self = this;
    const $text = $(`<div>${text}</div>`);
    if (this.options.linesLength) {
      // 获取html内容,包裹span标签
      $text.each(function (line) {
        // 查找本行的所有标签
        $(this)
          .find('*')
          .contents()
          .each(function () {
            // 获取文字分割结果
            const $warpText = $(`<div>${self.warpSpan($(this).text(),nbsp)}</div>`);
            $warpText.find('span').addClass('char');
            if (this.nodeType === 3) {
              $(this).replaceWith($warpText.html());
            }
          });
      });
      // 如果div里面文本不是空的,则删除结尾的br
      // $text.children().children().children().children().last('br').remove();
      // 在linesLength数组里的break位置添加手动断行
      if (breaks){
        // if($text[0].outerText && $text[0].outerText.indexOf('一线双显') !== -1) {
        //   console.log('$text.outerText',$text[0].outerText)
        // }
        // if($text[0].outerText && $text[0].outerText.indexOf('一线双显') !== -1) {
        //   console.log('$text.outerText',$text);
        //   console.log('self.options.linesLength',self.options.linesLength)

        //   console.log('$text.find-char',$text.find('.char'))
        // }
        let charIndex = 1;
        let lineIndex = 0;
        $text.find('.char').each(function () {
          if (self.options.linesLength[lineIndex] === charIndex) {
            $(this).after('<br/>');
            charIndex = 1;
            lineIndex++;
          } else {
            charIndex++;
          }
        });
      }

    }

    // 去掉包裹
    $text.find('.char').contents().unwrap();
    // if($text[0].outerText && $text[0].outerText.indexOf('一线双显') !== -1) {
    //   console.log('$text.html()',$text.html())
    // }
    return $text.html();
  }

  /**
   * 旧文字转为新文字之后改变y坐标
   */
  changeTextY(){
    delete this.options.v4svg;
    delete this.options.url;
    this.states.html = this.toHTML();
    let $text = $(this.options.text);
    const lineHeight = $text.css('line-height');
    if (lineHeight
      && this.options.linesLength
      && !this.options.curve){
      let number = parseFloat(lineHeight);
      if (lineHeight.indexOf('%') === -1){
        number *= 100;
      }
      let translate = -(number-100) * 0.005;
      let fontSize = parseFloat($text.css('font-size'));
      this.props.y -= fontSize*translate*this.options.scale/this.parent.parent.scale;
    }
  }
  getDirectWrite(text,breaks){
    if (!this.options.charPosArr || !this.options.linesLength) return text;
    // 获取html内容,包裹span标签
    let $text = $(text);
    let self = this;
    $text.each(function (line) {
      // 查找本行的所有标签
      $(this)
        .find('*')
        .contents()
        .each(function () {
          // 获取文字分割结果
          const $warpText = $(`<div>${self.warpSpan($(this).text(), false)}</div>`);
          $warpText.find('span').addClass('char');
          if (this.nodeType === 3) {
            $(this).replaceWith($warpText.html());
          }
        });
    });
    // 在linesLength数组里的break位置添加手动断行
    let charIndex = 1;
    let lineIndex = 0;
    $text.find('.char').each(function (index) {
      if ($(this).text().charCodeAt() == 32 || $(this).text().charCodeAt() == 160){
        $(this).attr('style',`width:${self.options.charPosArr[index]}px;display:inline-block;`);
      }
      if (self.options.linesLength[lineIndex] === charIndex) {
        // 添加换行
        $(this).after('<br/>');
        charIndex = 1;
        lineIndex++;
      } else {
        charIndex++;
      }
    });
    if (this.options.textAlign){
      $text.css('text-align',this.options.textAlign);
      $text.find('div').css('text-align',this.options.textAlign);
    }
    const lineHeight = $text.css('line-height');
    if (lineHeight
      && this.options.linesLength){
      let number = parseFloat(lineHeight);
      if (lineHeight.indexOf('%') === -1){
        number *= 100;
      }
      let translate = -(number-100) * 0.005;
      if (this.options.mode === 'v'){
        $text[0].style.transform = `translate(${translate}em, 0em)`;
      } else{
        $text[0].style.transform = `translate(0em, ${translate}em)`;
      }
    }
    return $text[0].outerHTML;
  }
  async changeForSignData(data) {
    if (typeof data !== 'string') return;
    // 替换文本
    const $text = $(this.options.text);
    // 获取原来文字bbox
    const contentArr = data.split('\n').filter(item => item.length > 0).map(item => item.replace(/(^\s*)/g, ''));
    // 获取使用的字体
    const family = $text.find('*[face]').attr('face') || $text.css('font-family');
    if (family && family.match(/font_\d+/)) {
      await window['editor'].waitLoadFont(family);
    }
    // 获取字体颜色
    let fontColor;
    try {
      fontColor = this.options.text.match(/#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})/g)[0];
    } catch (e) {
      try {
        fontColor = $text.find('*[color]').css('color') || $text.find('*[color]').attr('color') || new Color($text.css('color')).hex();
      } catch (e) {
        fontColor = '#000000'
      }
    }

    $text.html(contentArr.map(item => `<div>${item}</div>`).join(''));
    $text.children('div').wrapInner(`<font face="${family}" color="${fontColor}"></font>`);

    this.options.text = $text[0].outerHTML;
    const textInfo = this.getTextInfo(this.options.text);
    this.config.minWidth = textInfo.minWidth;
    // 如果高度超过输入文字高度,则增加元素高度
    this.props.height = textInfo.height;
    this.options.linesLength = textInfo.linesLength;
    this.states.html = this.toHTML();
  }

  changeSignCombination(data) {
    if (typeof data !== 'string') return;
    // 替换文本
    const $text = $(this.options.text);
    // 获取原来文字bbox
    const contentArr = data.split('\n').filter(item => item.length > 0).map(item => item.replace(/(^\s*)/g, ''));
    // 获取使用的字体
    const family = $text.find('*[face]').attr('face') || $text.css('font-family');
    // 获取字体颜色
    let fontColor;
    try {
      fontColor = this.options.text.match(/#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})/g)[0];
    } catch (e) {
      try {
        fontColor = $text.find('*[color]').css('color') || $text.find('*[color]').attr('color') || new Color($text.css('color')).hex();
      } catch (e) {
        fontColor = '#000000'
      }
    }

    $text.html(contentArr.map(item => `<div>${item}</div>`).join(''));
    $text.children('div').wrapInner(`<font face="${family}" color="${fontColor}"></font>`);

    this.options.text = $text[0].outerHTML;
    const textInfo = this.getTextInfo(this.options.text);
    this.config.minWidth = textInfo.minWidth;
    // 如果高度超过输入文字高度,则增加元素高度
    this.props.height = textInfo.height;
    this.options.linesLength = textInfo.linesLength;
    this.states.html = this.toHTML();
  }
  getFontFamily($text) {
    if ($text.find('*[face]').attr('face')) {
      return $text.find('*[face]').attr('face');
    } else {
      let family = '';
      $text.find('*').each(function () {
        if ($(this).css('font-family')) {
          family = $(this).css('font-family');
        }
      });
      return family;
    }
  }


  /**
   * 翻译
   */
  textTranslate(){
    // this.exitEditMode();
    // let newText = '666666666';
    // let $text = $(this.options.text);
    // let fontArr = $text.find('font');
    // let colorArr = [];
    // for (let i = 0; i < fontArr.length;i++){
    //   if (i !== 0){
    //     $(fontArr[i]).remove();
    //   }
    //   if(fontArr[i].color && fontArr[i].color !== '') {
    //     colorArr.push(fontArr[i].color)
    //   }
    // }
    // $(fontArr[0]).text(newText);
    // if(colorArr.length) {
    //   $(fontArr[0]).css('color', colorArr[0])
    // }
    // //$(fontArr[0]).color();
    // this.options.text = $text[0].outerHTML;
    // this.states.html = this.toHTML();
    // this.enterEditMode();
    // setTimeout(()=>{
    //   this.exitEditMode();
    // },10)
  }
}
