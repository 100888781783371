import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { apiTemplateOfficial, apiTemplateSetRecommend, parseUcFirst,apiTemplateDesigner } from '@libs/core/src/lib/util/api';
import { catchError } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Exchange } from '@libs/core/src/lib/models/exchange.interface';
import { AsyncList } from '@libs/core/src/lib/models/asyncList';
import { Observable, of } from 'rxjs';

const apiGetPrint = '/photo-develop/get-style-one';
const apiGetPostcard = '/photo-develop/get-post-card-back-one';
const printTem = '/photo-develop/style-edit';
const postcardTem = '/photo-develop/postcard-back-edit';
const state = '/template-official/batch-update';
const thumbnail = '/template-official/batch-generate-glyphs';
const templateState = '/template-designer/update-status';
const reject = '/template-designer';

@Injectable()
export class TemplateOfficialService {
  constructor(private http: HttpClient, private toastr: NzMessageService) { }

  list(params = {}): Observable<HttpResponse<TemplateOfficial[]>> {
    // 添加需要的额外字段
    if (params instanceof HttpParams) {
      params = params.set('expand', 'category,classify,tags,myFavorite,orderTemplate');
      // params['per-page'] = 300;
    } else {
      params['expand'] = 'category,classify,tags,myFavorite,orderTemplate';
    }
    return this.http
      .get<TemplateOfficial[]>(apiTemplateOfficial, {
        params: params,
        observe: 'response'
      })
      .pipe(
        catchError((err: any) => {
          this.toastr.error('数据查询失败，请稍候重试');
          return of(null);
        })
      );
  }
  //模板审核
  templateList(params = {}): Observable<HttpResponse<TemplateOfficial[]>> {
    // 添加需要的额外字段
    if (params instanceof HttpParams) {
      params = params.set('expand', 'category,classify,tags,myFavorite,orderTemplate');
      // params['per-page'] = 300;
    } else {
      params['expand'] = 'category,classify,tags,myFavorite,orderTemplate';
    }
    return this.http
      .get<TemplateOfficial[]>(apiTemplateDesigner, {
        params: params,
        observe: 'response'
      })
      .pipe(
        catchError((err: any) => {
          this.toastr.error('数据查询失败，请稍候重试');
          return of(null);
        })
      );
  }

  // 保存照片冲印模板
  savePrint(params: TemplateOfficial): Observable<TemplateOfficial> {
    return this.http.post<TemplateOfficial>(printTem, params);
  }

  // 保存明信片模板
  savePostcard(params: TemplateOfficial): Observable<TemplateOfficial> {
    return this.http.post<TemplateOfficial>(postcardTem, params);
  }
  // 批量修改状态
  modifiedState(params: any): Observable<any> {
    return this.http.post<any>(state, params);
  }

  // 批量生成缩略图
  generatehumbnail(params: any): Observable<any> {
    return this.http.post<any>(thumbnail, params);
  }
  // 批量修改模板审核状态
  modifiedTemplateState(params: any): Observable<any> {
    return this.http.post<any>(templateState, params);
  }
  /**
   * 修改官方模板
   * @param {TemplateOfficial} item
   * @returns {Observable<TemplateOfficial>}
   */
  update(item: TemplateOfficial): Observable<TemplateOfficial> {
    if (item.templateId) {

      return this.http.put<TemplateOfficial>(
        apiTemplateOfficial + '/' + item.templateId,
        parseUcFirst(item)
      );
    } else {
      return this.http.post<TemplateOfficial>(apiTemplateOfficial, item);
    }
  }
  /**
   * 驳回模板
   * @param {TemplateOfficial} item
   * @returns {Observable<TemplateOfficial>}
   */
  reject(templateId,param): Observable<TemplateOfficial> {
    if (templateId) {
      return this.http.put<TemplateOfficial>(
        reject + '/' + templateId,
        parseUcFirst(param)
      );
    } 
  }

  delete(item: TemplateOfficial): Observable<any> {
    return this.http.delete(apiTemplateOfficial + '/' + item.templateId);
  }

  view(fromId): Observable<any> {
    const params = {
      expand: 'content,editConfig'
    };
    return this.http.get(apiTemplateOfficial + '/' + fromId, {
      params: params
    });
  }

  viewDesigner(fromId): Observable<any> {
    const params = {
      expand: 'content,editConfig'
    };
    return this.http.get(apiTemplateDesigner + '/' + fromId, {
      params: params
    });
  }

  printView(templateId): Observable<any> {
    const params = {
      template_id: templateId,
      expand: 'content'
    };
    return this.http.get(apiGetPrint, {
      params: params
    });
  }

  postcardView(templateId): Observable<any> {
    const params = {
      template_id: templateId,
      expand: 'content'
    };
    return this.http.get(apiGetPostcard, {
      params: params
    });
  }

  viewConfig(fromId): Observable<any> {
    return this.http.get(apiTemplateOfficial + '/' + fromId, {
      params: { expand: 'editConfig' }
    });
  }

  setRecommend(id): Observable<TemplateOfficial> {
    return this.http.post<TemplateOfficial>(apiTemplateSetRecommend + '/' + id, {});
  }

  exchangeList(params): Observable<HttpResponse<Exchange[]>> {
    return this.http.get<Exchange[]>('/manage/exchange-list', { params, observe: 'response' });
  }

  upRemark(params): Observable<Exchange> {
    return this.http.post<Exchange>('/manage/exchange-remark', params);
  }

  upExamine(params): Observable<any> {
    return this.http.post<any>('/manage/exchange-examine', params);
  }

  syncList(params): Observable<HttpResponse<AsyncList[]>> {
    return this.http.get<AsyncList[]>('/template-official/sync-list', { params, observe: 'response' });
  }

  subSyncTem(params): Observable<any> {
    return this.http.post<any>('/template-official/sync-template', params);
  }

}
