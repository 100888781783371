import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ClipboardService } from 'ngx-clipboard';
import { MessageService } from '@libs/core/src/lib/services/message.service';

@Component({
  selector: 'app-template-submit',
  templateUrl: './template-submit.component.html',
  styleUrls: ['./template-submit.component.css']
})
export class TemplateSubmitComponent implements OnInit {
  @Input() id: any;
  @Input() orders: any[] = [];
  constructor(
    private modal: NzModalRef,
    private clip: ClipboardService,
    private message: MessageService
  ) { }

  ngOnInit() {
  }

  copyId() {
    this.clip.copyFromContent(this.id);
    this.message.success('复制成功!');
  }

  toPrint() {
    if (this.orders.length === 1) {
      let url = this.orders[0].url + (this.orders[0].url.indexOf('?') >= 0 ? '&' : '?') + 'template_ids=' + this.id;
      window.open(url);
      this.modal.close()
    } else if (this.orders.length > 1) {
      this.modal.close('links')
    }
  }
}
