import { Injectable } from '@angular/core';
import { CodeService } from '@libs/core/src/lib/services/code.service';
import { NzMessageRef, NzMessageService } from 'ng-zorro-antd/message';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private code: CodeService,
    private message: NzMessageService
  ) { }

  success(title) {
    this.message.success(title);
  }

  loading (title, options): NzMessageRef {
    return this.message.loading(title, options)
  }

  info(title) {
    this.message.info(title);
  }

  error(err: any, type: 'error' | 'info' = 'error') {
    console.log(err);
    if (err instanceof HttpErrorResponse) {
      if (type === 'error')
        this.message.error(this.code.getOne(err));
      else if (type === 'info')
        this.message.info(this.code.getOne(err));
    } else if (typeof err === 'string') {
      this.message.error(err);
    }
  }

  warning(title) {
    this.message.warning(title);
  }

  remove(id) {
    this.message.remove(id)
  }

}
