import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Store } from '@ngrx/store';
import { Personal, Team } from '../../+state/app.actions';
import { zip } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WebStartupService {

  constructor(
    private localStorage: LocalStorage,
    private store: Store<any>
  ) { }

  load(): Promise<any> {
    // only works with promises
    return new Promise((resolve, reject) => {
      zip(
        this.localStorage.getItem('_version')
      )
        .pipe(
          // 接收其他拦截器后产生的异常消息
          catchError(([appData]) => {
            resolve(null);
            return [appData];
          })
        )
        .subscribe(
          ([version]) => {
            if (version && version.version === 'team' && version.teamId > 0) {
              this.store.dispatch(new Team(version.teamId));
            } else {
              this.store.dispatch(new Personal());
            }
          },
          () => {},
          () => {
            resolve(null);
          }
        );
    });
  }

}
