import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { CategoryService } from '@libs/core/src/lib/services/template/category.service';
import { ClassifyService } from '@libs/core/src/lib/services/template/classify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HomeResolverService implements Resolve<any> {
  constructor(
    private classService: ClassifyService,
    private categoryService: CategoryService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    if (state.url.indexOf('home') > 0) {
      return this.categoryService.getCategoryList().pipe(
        catchError(() => {
          this.router.navigate(['/']);
          return of(null);
        })
      );
    } else if (state.url.indexOf('center') > 0) {
      return this.classService
        .getTemplateCenterList();
    }

  }
}
