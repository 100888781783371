import { Component, OnInit,OnDestroy } from '@angular/core';
import { AppService } from '@libs/core/src/lib/services/app.service';
import { Store } from '@ngrx/store';
import {
  selectAppMenu, selectAppVersion,
  selectDeleteTemplate,
  selectReductionTemplate
} from '../../../../../../apps/frontend/src/app/+state/app.reducer';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { selectAuthIsGuest } from '@libs/core/src/lib/+state/auth.reducer';
import { MemberService } from '@libs/core/src/lib/services/member/member.service';
import {
  DeleteTemplate,
  ReductionTemplate
} from '../../../../../../apps/frontend/src/app/+state/app.actions';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { CategoryService } from '@libs/core/src/lib/services/template/category.service';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { Login } from '@libs/core/src/lib/+state/auth.actions';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ClipboardService } from 'ngx-clipboard';
import { selectRouter } from 'apps/frontend/src/app/+state/root.reducer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DomSanitizer } from '@angular/platform-browser';
import { of, Subscription } from 'rxjs';
import { skipWhile, switchMap } from 'rxjs/operators';


@AutoUnsubscribe()
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [
    MemberService, CategoryService, MessageService
  ]
})
export class MenuComponent implements OnInit, OnDestroy {
  date = new Date();
  isIndex = false;
  url: string;
  need: string;
  isprompt = false;
  isshowduihao = false;
  myURL:any;
  constructor(
    public appService: AppService,
    public coopService: CoopService,
    public modalService: ModalService,
    private memberService: MemberService,
    private store: Store<any>,
    public authService: AuthService,
    private localStorage: LocalStorage,
    private clipboardService: ClipboardService,
    private message: NzMessageService,
    private sanitizer:DomSanitizer
  ) {
  }

  templateCount = 0;

  countSubscription: Subscription;
  versionSubscription: Subscription;
  deleteTemplateSubscription: Subscription;
  reductionTemplateSubscription: Subscription;

  ngOnInit(): void {
    this.store.select(selectRouter).subscribe(data => {
      this.url = data.state.url;
      const urls = data.state.url.split('?')[0];
      if (urls.indexOf('/template/list') === 0 || this.url === '/template/center') {
        this.isIndex = true;
      } else {
        this.isIndex = false;
      }
    });
    this.countSubscription = this.store.select(selectAppMenu)
      .pipe(
        switchMap(data => {
          if (data === 'member' && !this.authService.isGuest) {
            return this.store.select(selectAuthIsGuest)
              .pipe(
                switchMap(data => {
                  if (data)
                    return of({ count: 0 });
                  else
                    return this.memberService.count();
                })
              );
          } else {
            return of(null);
          }
        })
      ).subscribe(data => {
        if (data) {
          this.templateCount = parseInt(data.count, 10);
        }
      });

    this.versionSubscription = this.store.select(selectAppVersion)
      .pipe(
        skipWhile(() => !this.appService.isMenuMember()),
        switchMap(() => this.memberService.count())
      ).subscribe(data => {
        if (data) {
          this.templateCount = parseInt(data.count, 10);
        }
      });

    this.deleteTemplateSubscription = this.store.select(selectDeleteTemplate)
      .subscribe(data => {
        if (data > 0) {
          this.templateCount = this.templateCount - data;
          this.store.dispatch(new DeleteTemplate(0));
        }
      });

    this.reductionTemplateSubscription = this.store.select(selectReductionTemplate)
      .subscribe(data => {
        if (data > 0) {
          this.templateCount = this.templateCount + data;
          this.store.dispatch(new ReductionTemplate(0));
        }
      });

  }

  login(): void {
    this.store.dispatch(new Login());
  }

  brief(): void {
    this.coopService.coopInfo.isprompt = false;
    if (this.isshowduihao) {
      this.localStorage.setItem('isShowMake', true).subscribe();
    }
  }

  forever(): void {
    this.isshowduihao = !this.isshowduihao;
  }

  clipboard(tel: string) {
    this.clipboardService.copyFromContent(tel);
    this.message.success('复制成功!');
  }

  openQQ(qq: string) {
    window.open(`tencent://message/?Site=baidu.com&uin=${qq}&Menu=yes`);
  }
  openWW(ww: string) {
    this.myURL = this.sanitizer.bypassSecurityTrustResourceUrl(`//amos.alicdn.com/getcid.aw?v=2&uid=${ww}&site=cntaobao&s=1&groupid=0&charset=utf-8`);
  }

  ngOnDestroy():void{

  }
}
