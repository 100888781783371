import { Component, Input, OnInit } from '@angular/core';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { Store } from '@ngrx/store';
import { Login, Logout } from '@libs/core/src/lib/+state/auth.actions';
import { take } from 'rxjs/operators';
import { RouterUrlService } from '../../../../../core/src/lib/services/router-url.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { Env } from '@libs/core/src/lib/util/environment';
import { Subscription } from 'rxjs';
import { TemplateService } from '../../../../../../apps/frontend-new/src/app/pages/template/services/template.service';
import { ClassifyService } from '@libs/core/src/lib/services/template/classify.service';

@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.scss']
})
export class NewHeaderComponent implements OnInit {
  @Input() showBar2 = true;
  @Input() showCustomer = true;
  @Input() shareName: string;
  // 搜索相关
  @Input() showSearch = false;
  @Input() searchUrl = '/template/list';
  @Input() searchClear = true;
  @Input() query = {};

  @Input() keepSearch = true;

  keywords;
  qrUrl: string;

  showCheck = false;

  customerServiceURL: any;

  advTitle: string;
  logoUrl: string;
  chooseAdv = [];
  advNav = [];
  topSubscription: Subscription;
  constructor(
    public coop: CoopService,
    public auth: AuthService,
    public store: Store<any>,
    public urlSer: RouterUrlService,
    private router: Router,
    public modal: ModalService,
    private sanitizer:DomSanitizer,
    private local: LocalStorage,
    public env: Env,
    private templateService: TemplateService,
    public classify: ClassifyService,
  ) { }

  ngOnInit() {
    // 获取手机浏览地址
    this.qrUrl = this.getQrUrl();
    this.topSubscription = this.templateService.diyHead()
    .subscribe(data => {
      if (data) {
        this.advTitle = data.advTitle;
        this.logoUrl = data.logoUrl;
        this.chooseAdv = data.linkContent ? JSON.parse(data.linkContent) : [];
        this.advNav = data.navLink ? JSON.parse(data.navLink) : []
      }
    });
  }

  //如果是来自于生产并且是美术宝这个id就禁用跳转
  getProductionClick(){
    if (this.coop.coopInfo.id == this.env.meishubao_id) {
      return true
    }
  }

  get mobileQr() {
    return ['12146', '12813', '12928', '12866', '10682', '13018'].indexOf(this.coop.coopInfo.id as string) === -1
  }

  login(): void {
    this.store.dispatch(new Login());
  }

  logout(): void {
    this.store.dispatch(new Logout());
  }

  getQrUrl() {
    if (this.coop.coopInfo.id == 12718) {
      return 'http://mmzj.youdianpinwei.com/dist/xz.html'
    } else if (this.coop.coopInfo.domain) {
      //return 'http://m-' + this.coop.coopInfo.domain
      return 'http://' + this.coop.coopInfo.domain
    } else {
      //return 'http://m-' + document.domain
      return 'http://' + document.domain
    }
  }

  search(event?) {
    if (event && event.code !== 'Enter') return;
    this.urlSer.getUrlParam([{ name: 'k', value: this.keywords },{ name: 'p', value: ''}], this.searchClear)
      .pipe(take(1))
      .subscribe(
        data => {
          this.router.navigate([this.searchUrl, data], {queryParams: this.query});
        }
      );
  }

  openQQ(qq: string) {
    window.open(`tencent://message/?Site=baidu.com&uin=${qq}&Menu=yes`);
  }

  openWW(ww: string) {
    this.customerServiceURL = this.sanitizer.bypassSecurityTrustResourceUrl(`//amos.alicdn.com/getcid.aw?v=2&uid=${ww}&site=cntaobao&s=1&groupid=0&charset=utf-8`);
  }


  brief(): void {
    this.coop.coopInfo.isprompt = false;
    if (this.showCheck) {
      this.local.setItem('isShowMake', true).subscribe();
    }
  }

}
