import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotTableModule } from '@handsontable/angular';
import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { SharedModule } from '@libs/shared';
import { CountdownModule } from 'ngx-countdown';
import { AboutusComponent } from './aboutus/aboutus.component';
import { SpecListComponent } from './spec-list/spec-list.component';
import { TemplateModule } from './template/template.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageLoginComponent } from './page-login/page-login.component';
import { MamaOrderComponent } from './mama-order/mama-order.component';
import { AddCardComponent } from './add-card/add-card.component';
import { JuchenBreakComponent } from './juchen-break/juchen-break.component';
import { LinklistComponent } from './linklist/linklist.component';
import { VariableExcelComponent } from './variable-excel/variable-excel.component';
import { TestListComponent } from './test-list/test-list.component';
// import { ListMenuComponent } from './template/list-menu/list-menu.component';

@NgModule({
  imports: [CommonModule, PagesRoutingModule, SharedModule, CountdownModule, TemplateModule, FormsModule, ReactiveFormsModule, HotTableModule],
  declarations: [PagesComponent, AboutusComponent, SpecListComponent, LinklistComponent, PageLoginComponent, MamaOrderComponent, AddCardComponent, JuchenBreakComponent, VariableExcelComponent, TestListComponent ],
  exports: [PagesRoutingModule]
})
export class PagesModule {}
