import { fadeInOut } from '../../../../../../core/src/lib/animations/animations';
import { UserInterfaceService } from '../../../services/ui.service';
import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ElementRef,
  HostListener
} from '@angular/core';

@Component({
  selector: 'app-t-modal',
  templateUrl: './t-modal.component.html',
  styleUrls: ['./t-modal.component.scss'],
  animations: [fadeInOut]
})
export class TModalComponent implements OnInit, OnDestroy {
  @Input() item;
  @ViewChild('compRef', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;
  @ViewChild('modalRef') modalRef: ElementRef;
  componentRef: ComponentRef<any>;

  private offsetX = 0;
  private offsetY = 0;
  private isDown = false;
  constructor(
    public ui: UserInterfaceService,
    private resolver: ComponentFactoryResolver
  ) { }

  ngOnInit(): void {
    this.container.clear();
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(this.item.params.component);
    this.componentRef = this.container.createComponent(factory);
    if (this.item.params.props) {
      for (const item of Object.keys(this.item.params.props)) {
        this.componentRef.instance[item] = this.item.params.props[item];
      }
    }
    // 如果这个模态框里的组件有parent属性,则将Modal实例传入
    this.componentRef.instance.parentModal = this.item;

    setTimeout(() => {
      const offsetX = Math.max(
        0,
        this.modalRef.nativeElement.getBoundingClientRect().right -
        window.innerWidth
      ),
        offsetY = Math.max(
          0,
          this.modalRef.nativeElement.getBoundingClientRect().bottom -
          window.innerHeight
        );
      this.item.params.x -= offsetX;
      this.item.params.y -= offsetY;
    }, 1);
  }

  ngOnDestroy(): void {
    this.componentRef.destroy();
  }
  /**
   * 事件:模态框所占的全屏div被点击
   * @param event 鼠标事件
   */
  @HostListener('document:mousedown', ['$event']) onModalContainerMouseDown(event): void {
    if (!this.item.params.preventClose) {
      this.item.close();
    }
  }
  /**
   * 模态框被按下
   * @param event 鼠标事件
   */
  onModalMouseDown(event: MouseEvent): void {
    // 阻止冒泡,否则会触发关闭事件
    event.stopPropagation();
    // 获取所有自己的children
    this.ui.setActiveUI(this.item);
  }
  onDragAreaMouseDown(event: MouseEvent): void {
    this.offsetX = event.offsetX;
    this.offsetY = event.offsetY;
    this.isDown = true;
  }
  @HostListener('document:mousemove', ['$event']) onModalContainerMouseMove(event): void {
    if (this.isDown) {
      this.item.params.x = event.clientX - this.offsetX;
      this.item.params.y = event.clientY - this.offsetY;
    }
  }
  @HostListener('document:mouseup', ['$event']) onModalContainerMouseUp(event): void {
    this.isDown = false;
  }

  getModalStyle(): any {
    /*const {width, height, x, y, overflow} = this.item.params;
    const style = {
      width: width + 'px',
      height: height ? height + 'px' : 'auto',
      left: x + 'px',
      top: y + 'px',
      overflow
    };*/

    return {
      width: this.item.params.width + 'px',
      height: this.item.params.height ? this.item.params.height + 'px' : 'auto',
      left: this.item.params.x + 'px',
      top: this.item.params.y + 'px',
      overflow: this.item.params.overflow
    };
  }
  /**
   * 事件:模态框容器被按下
   * @param event 鼠标事件对象
   */
  onTModalMouseDown(event: MouseEvent) {
    event.stopPropagation();
    this.item.close();
  }
}
