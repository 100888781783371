import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CodeService {
  code: Map<number, string>;

  serverUrl = '/system/get-errors';
  getErrors = this.http.get<any>(this.serverUrl);

  loadError(data): void {
    const map = new Map();
    for (const k of Object.keys(data)) {
      map.set(parseInt(k, 10), data[k]);
    }
    this.code = map;
  }

  get(value: number): string {
    return this.code.get(value);
  }

  getOne(error: HttpErrorResponse): string {
    if (error instanceof HttpErrorResponse) {
      const err = error.error.message;
      if (typeof err === 'string') return err;
      for (const key in err) {
        if (err.hasOwnProperty(key)) {
          const code = err[key][0];
          if (typeof code === 'number')
            return this.get(code);
          else
            return err[key][0];
        }
      }
    } else {
      return '未知错误！';
    }
    return '';
  }

  constructor(
    private http: HttpClient
  ) {
  }
}
