import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AppData,
  selectAppMenu, selectAppVersion
} from '../../../../../apps/frontend/src/app/+state/app.reducer';
import { HttpClient } from '@angular/common/http';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Env } from '@libs/core/src/lib/util/environment';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  location: Location;
  menuState: string;
  version: string;
  constructor(
    public env: Env,
    private store: Store<AppData>,
    private http: HttpClient,
    private localStorage: LocalStorage
  ) {
    this.store.select(selectAppMenu).subscribe(data => {
      this.menuState = data;
    });
    this.store.select(selectAppVersion).subscribe(data => this.version = data);
  }

  isTeam(): boolean {
    return this.version === 'team';
  }

  /**
   * 判断是否是个人的菜单
   * @returns {boolean}
   */
  isMenuMember(): boolean {
    return this.menuState === 'member';
  }

  /**
   * 判断是否是官方的菜单
   * @returns {boolean}
   */
  isMenuOfficial(): boolean {
    return this.menuState === 'official';
  }

  /**
   * 获取当前商户id
   * @returns {Observable<any>}
   */
  getAppCoopId(): Observable<any> {
    if (this.env.coop_id && this.env.coop_id !== '0') return of(this.env.coop_id);
    if (this.env.handle == 'backend') return of('13068');
    return this.localStorage.getItem('_coop_id').pipe(
      switchMap(data => {
        if (data) return of(data.toString());
        else return this.http.post<{coopId: number}>('/system/get-coop-id', {host: location.host})
          .pipe(
            map(data => data.coopId.toString())
          );
      })
    );
  }
}
