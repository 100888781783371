import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalService } from '@libs/core/src/lib/services/modal.service';

@Component({
  selector: 'app-long-time-no-operation',
  templateUrl: './long-time-no-operation.component.html',
  styleUrls: ['./long-time-no-operation.component.scss']
})
export class LongTimeNoOperationComponent implements OnInit {

  constructor(
    private modal: NzModalRef,
    @Inject(forwardRef(() => ModalService))
    private modalService
  ) { }

  ngOnInit() {
  }
  close(){
    this.modal.destroy();
  }
}
