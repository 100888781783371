import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoopMessage } from '../../../../../apps/frontend/src/app/pages/coop/service/coop.interface';
import { CoopInfo } from '@libs/core/src/lib/models/coop-info.interface';
import { Env } from '@libs/core/src/lib/util/environment';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import * as Color from 'color';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ClassifyService } from '@libs/core/src/lib/services/template/classify.service';
import { ppy_zhuge_load } from '@libs/core/src/lib/util/ppy-zhuge';
import { SetInfo } from '../models/post-designer.interface';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CoopService {
  coopInfo: CoopInfo;
  setInfo: SetInfo;
  search = '/coop-agent/search';
  apiCoopInfo = '/cooperation/info';
  style: any;
  styles = {
    '12146': {
      header_logo_bg: { background: 'transparent' },
      header_left_space: { paddingLeft: '7px' },
      header_link_btn: { background: 'transparent', fontSize: '16px', padding: '0px' },
      header_link_btn_icon: { display: 'none' }
    }
  };

  postorderEspecialCoop = '10937,11597'

  constructor(
    private http: HttpClient,
    private env: Env,
    private auth: AuthService,
    private local: LocalStorage,
    private classify: ClassifyService
  ) {
  }

  searchCoop(data): Observable<CoopMessage[]> {
    return this.http.post<CoopMessage[]>(this.search, { keyword: data });
  }

  searchCoopInfo(): Observable<CoopInfo> {
    return zip(
      // info
      this.http.post<CoopInfo>(this.apiCoopInfo, {}, { headers: { 'Access-No-Login': '10' } }),
      // 是否显示商户后台按钮提示
      this.local.getItem('isShowMake'),
      // 轮播图
      this.http.get<any[]>('/adv', { headers: { 'Access-No-Login': '10' } }),
      // 推荐分类
      this.http.get<any[]>('/cooperation/classify-recommend', { headers: { 'Access-No-Login': '10' } })
    ).pipe(
      map(([info, isprompt, adv, recommend]) => {
        info.adv = adv;
        info.recommend = recommend;
        info.isprompt = !isprompt;
        // 皮肤对应字体颜色
        info.fontColor = Color(info.color).hsl().object().l > 80 ? 'rgba(0,0,0,.9)' : '#fff';
        // 自定义链接转换格式
        if (info.custom_link_json) {
          const link_array = [];
          for (let key in info.custom_link_json) {
            link_array.push({ name: key, link: info.custom_link_json[key] });
          }
          info.custom_link_json = link_array;
        }
        return info;
      }),
      tap(data => this.style = this.styles[data.id])
    );
  }

  searchCoopInfoOrder(): Observable<CoopInfo> {
    return zip(
      // info
      this.http.post<CoopInfo>(this.apiCoopInfo, {}, { headers: { 'Access-No-Login': '10' } }),
    ).pipe(
      map(([info, isprompt, adv, recommend]) => {
        info.isprompt = !isprompt;
        // 皮肤对应字体颜色
        info.fontColor = Color(info.color).hsl().object().l > 80 ? 'rgba(0,0,0,.9)' : '#fff';
        // 自定义链接转换格式
        if (info.custom_link_json) {
          const link_array = [];
          for (let key in info.custom_link_json) {
            link_array.push({ name: key, link: info.custom_link_json[key] });
          }
          info.custom_link_json = link_array;
        }
        return info;
      }),
      tap(data => this.style = this.styles[data.id])
    );
  }

  load(data) {
    const appFavicon = document.getElementById('app_favicon');
    this.coopInfo = data;
    if (this.env.handle === 'frontend' && !location.hostname.match(/www\.(tbz|tubangzhu)/)) {
      if (data.ico_url) {
        if (appFavicon) {
          appFavicon.setAttribute('href', data.ico_url);
        }
      } else {
        if (appFavicon) {
          appFavicon.setAttribute('href', '/assets/favicon.ico');
        }
      }
      if (data.id == 12718) {
        const style = document.createElement('link');
        style.rel = 'stylesheet';
        style.href = '/assets/mama/ui-dialog.css';
        document.getElementsByTagName('head')[0].appendChild(style);

        const script = document.createElement('script');
        script.src = '/assets/mama/dialog-plus-min.js';
        document.body.appendChild(script);
      } else if (data.id == 12146) {

        //客服插件
        const script_kf = document.createElement('script');
        script_kf.src = '/assets/js/ppy-kefu.js';
        script_kf.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(script_kf);

        // 诸葛统计
        ppy_zhuge_load(this.coopInfo.coop_app_key, () => {
          const url = window.location.pathname;
          if (url.match(/\/design\//)) {
            window['zhuge']['track']('微创印-进入在线编辑页', {
              '用户ID': this.auth.isGuest ? '0' : this.auth.user.coopUid,
              '平台': 'PC'
            });
          } else if (url.match(/\/template\/center/) || url == '/') {
            window['zhuge']['track']('微创印-进入微创印首页', {
              '用户ID': this.auth.isGuest ? '0' : this.auth.user.coopUid,
              '平台': 'PC'
            });
          }
        });
      }
    } else if (appFavicon) {
      appFavicon.setAttribute('href', '/assets/favicon.ico');
    }
  }

  loadSet(data) {
    this.setInfo = data;
  }

  updateSet(data) {
    Object.assign(this.setInfo, data);
  }
}
