import { Container } from '../../models/element/container';
import { Table } from '../../models/element/table';
import { Page } from '../../models/base/page';
import { Text } from '../../models/element/text';
import { TextV2 } from '../../models/element/textv2';
import { Svg } from '../../models/element/svg';
import { Image } from '../../models/element/image';
import { Chart } from '../../models/element/chart';
import { Template } from '../../models/base/template';
import { Shape } from '../../models/element/shape';
import { Layout } from '../../models/element/layout';
import { Calendar } from '@libs/editor/src/lib/models/element/calendar';
import { NewTable } from '../../models/element/newTable'
import { Combination } from '@libs/editor/src/lib/models/element/combination';
import { Element } from '../../models/base/element';

export class TemplateLoader {
  /**
   * 从对象创建模版
   * @param object 对象
   */
  public static loadTemplate(object) {
    const { id, title, props, pages, states } = object;
    const template = new Template({ id, title, props, states });
    pages.forEach(pageItem => {
      template.addPage(this.loadPage(pageItem, template), false);
    });
    return template;
  }
  /**
   * 从对象创建页面
   * @param object 对象
   * @param toTemplate 要加载到的模版对象
   */
  public static loadPage(object, toTemplate) {
    const { id, props, elements, states } = object;
    const pageItem = new Page({ id, props, states });
    pageItem.parent = toTemplate;
    for (const eleObjItem of elements) {
      pageItem.addElement(this.loadElement(eleObjItem), false);
    }
    return pageItem;
  }
  /**
   * 从对象创建元素
   * @param object 对象
   */
  public static loadElement(object): Element {
    const { id, type, props, options } = object;
    const classes = {
      text: Text,
      svg: Svg,
      image: Image,
      chart: Chart,
      table: Table,
      container: Container,
      shape: Shape,
      layout: Layout,
      combination: Combination,
      calendar: Calendar,
      newTable: NewTable,
      textv2: TextV2
    };
    return new classes[type]({ id, props, options });
  }
}
