import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
  NgModule
} from '@angular/core';

import { AppComponent } from './app.component';
import { NxModule } from '@nrwl/nx';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './+state/app.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment.hmr';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule
} from '@ngrx/router-store';
import { PagesModule } from './pages/pages.module';
import { ConfigLoader, ConfigService } from '@ngx-config/core';
import { MetaLoader } from '@ngx-meta/core';
import { configFactory, CoreModule, metaFactory } from '@libs/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RestInterceptor } from '@libs/core/src/lib/interceptors/rest-interceptor';
import { StartupService } from '@libs/core/src/lib/services/startup.service';
import { metaReducers, reducers } from './+state/root.reducer';
import { Router, RouterModule } from '@angular/router';
import { RouterSerializer } from '@libs/core/src/lib/util/RouterSerializer';
//import { ServiceWorkerModule } from '@angular/service-worker';
import { WebStartupService } from '@web-core/services/web-startup.service';
import { CodeService } from '@libs/core/src/lib/services/code.service';
import { Env } from '@libs/core/src/lib/util/environment';
import { CookieService } from 'ngx-cookie-service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { ClassifyService } from '@libs/core/src/lib/services/template/classify.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { DatePipe } from '@angular/common';

// Http 拦截器工厂方法
export function interceptorFactory(injector: Injector): RestInterceptor {
  return new RestInterceptor(environment, injector.get(Store), injector.get(Router), injector.get(MessageService), injector.get(CoopService));
}

/**
 * 应用初始化
 * @param {StartupService} startupService
 * @returns {Function}
 * @constructor
 */
export function StartupServiceFactory(startupService: StartupService): Function {
  return () => startupService.load();
}

export function WebStartupServiceFactory(
  startupService: WebStartupService
): Function {
  return () => startupService.load();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({appId: 'tubangzhu-server'}),
    BrowserAnimationsModule,
    NxModule.forRoot(),
    // 注入CORE
    CoreModule.forRoot([
      {
        provide: ConfigLoader,
        useFactory: configFactory,
        deps: [Injector]
      },
      {
        provide: MetaLoader,
        useFactory: metaFactory,
        deps: [ConfigService]
      }
    ]),
    RouterModule.forRoot([]),
    // Redux
    StoreModule.forRoot(reducers, { metaReducers }),
    // store effects
    EffectsModule.forRoot([AppEffects]),
    // Store 开发工具
    !environment.production
      ? StoreDevtoolsModule.instrument({
        name: 'tubangzhu Store DevTools',
        maxAge: 25,
        logOnly: environment.production
      })
      : [],
    StoreRouterConnectingModule.forRoot(),
    //ServiceWorkerModule.register('/ngsw-worker.js', {
    //  enabled: environment.production
    //}),
    PagesModule
  ],
  providers: [
    CookieService,
    ClassifyService,
    // 路由格式化
    { provide: RouterStateSerializer, useClass: RouterSerializer },
    // 注入http拦截器
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: interceptorFactory,
      deps: [Injector],
      multi: true
    },
    StartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: StartupServiceFactory,
      deps: [StartupService],
      multi: true
    },
    WebStartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: WebStartupServiceFactory,
      deps: [WebStartupService],
      multi: true
    },
    CodeService,
    {
      provide: Env,
      useValue: environment
    },
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
