import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppData } from '../../../+state/app.reducer';
import { Classify, ClassifyLevel } from '@libs/core/src/lib/models/classify';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterUrlService } from '@libs/core/src/lib/services/router-url.service';
import { TemplateService } from '../services/template.service';
import { MemberManageService } from '../../member/services/member-manage.service';
import { MemberService } from '@libs/core/src/lib/services/member/member.service';
import { TemplateMember } from '@libs/core/src/lib/models/template-member';
import { DOCUMENT } from '@angular/common';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { FolderMember } from '@libs/core/src/lib/models/folder-member';
import { DesignParamService } from '@libs/core/src/lib/services/design-param.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { ClassifyService } from '@libs/core/src/lib/services/template/classify.service';
import Swiper from 'swiper';


@AutoUnsubscribe()
@Component({
  selector: 'app-template-center',
  templateUrl: './template-center.component.html',
  styleUrls: ['./template-center.component.scss'],
  providers: [MemberManageService, MemberService]
})
export class TemplateCenterComponent implements OnInit, OnDestroy, AfterViewInit {
  newTemplateList: ClassifyLevel[];
  recentlySubscription: Subscription;
  textListSubscription: Subscription;
  renameSubscription: Subscription;
  delSubscription: Subscription;
  deslist: TemplateMember[];
  type = 'template';
  delRef: NzModalRef;
  public folderList: FolderMember[];
  newData: TemplateMember;
  createAt: string;
  updataAt: string;
  adIsShow: boolean;
  adSwiper: Swiper;

  constructor(
    public classify: ClassifyService,
    public coop: CoopService,
    public urlS: RouterUrlService,
    public jumpService: RouterUrlService,
    private route: ActivatedRoute,
    private store: Store<AppData>,
    private templateService: TemplateService,
    public manageService: MemberManageService,
    private memberService: MemberService,
    public designService: DesignParamService,
    private renderer: Renderer2,
    private modal: ModalService,
    private modalService: NzModalService,
    private router: Router,
    private message: MessageService,
    public authService: AuthService,
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  ngOnInit(): void {
    // this.store.dispatch(new MenuOfficial());
    // 模版中心列表
    /*this.textListSubscription = this.memberService.getFolderList()
      .subscribe(data => {
        this.folderList = data;
      });*/

    /*if (this.classify.products.length > 20) {
      this.newTemplateList = this.classify.products.slice(0, 19);
    } else {
      this.newTemplateList = this.classify.products;
    }*/

    /*this.store.select(selectAuthIsGuest)
      .subscribe(data => {
        if (!data) {
          this.recentlySubscription = this.memberService.getMemberTemplateList1({ recent_design: 1 })
            .subscribe(data => {
              this.deslist = data.slice(0, 6);
            });
        } else {
          this.deslist = [];
        }
      });*/
  }

  ngOnDestroy(): void {
  }

  /*copyEdit(data: TemplateMember) {
    this.manageService.editData = data;
    this.manageService.operation(
      'copy',
      null,
      false,
      data => {
        if (data)
          window.open(this.designService.getMemberUpdateUrl(data.templateId, data));
      });
  }*/

  /*@HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent): void {
    // 取消选中
    this.manageService.resetSelected();
    // 取消修改标题
    this.manageService.editTitle = false;
    this.manageService.editData = false;
    // 取消鼠标右键
    this.renderer.setStyle(
      this.contextmenuTemplate.nativeElement,
      'display',
      'none'
    );
  }*/

  // 操作单个模版的Ref
  /*@ViewChild('contextmenuTemplate')
  contextmenuTemplate: ElementRef<HTMLElement>;*/

  /*templateMousedown(data: any, event: MouseEvent): boolean {
    if (this.authService.isGuest) {
      this.store.dispatch(new Login());
    } else {
      this.newData = data;
      this.createAt = this.newData.createdAt;
      this.updataAt = this.newData.updatedAt;
      event.stopPropagation();
      this.manageService.editData = {
        ...data
      };
      const y = this.document.documentElement.scrollTop + event.clientY - 126;
      const scrollHeight = this.document.body.scrollHeight;
      const scrollWidth = this.document.body.scrollWidth;
      // 设置悬浮窗位置
      this.renderer.setStyle(
        this.contextmenuTemplate.nativeElement,
        'display',
        'block'
      );
      this.renderer.setStyle(
        this.contextmenuTemplate.nativeElement,
        'top',
        y + 'px'
      );
      this.renderer.setStyle(
        this.contextmenuTemplate.nativeElement,
        'left',
        event.clientX + 10 + 'px'
      );
      // 调整高度
      const height =
        scrollHeight -
        20 -
        (y +
          this.contextmenuTemplate.nativeElement.getBoundingClientRect().height);
      if (height < 0) {
        this.renderer.setStyle(
          this.contextmenuTemplate.nativeElement,
          'top',
          y - Math.abs(height) - 60 + 'px'
        );
      }

      //调整宽度
      const width =
        scrollWidth -
        20 -
        (event.clientX +
          this.contextmenuTemplate.nativeElement.getBoundingClientRect().width);
      if (width < 0) {
        this.renderer.setStyle(
          this.contextmenuTemplate.nativeElement,
          'left',
          event.clientX - Math.abs(width) - 169 + 'px'
        );
      }
      return false;
    }
  }

  contextmenuTemplateClick(event?: MouseEvent): void {
    if (event) event.stopPropagation();
    // 取消鼠标右键
    this.renderer.setStyle(
      this.contextmenuTemplate.nativeElement,
      'display',
      'none'
    );
  }*/

  /*rename(item) {
    if (this.manageService.editData.title === item.title) {
      this.manageService.editTitle = false;
      return;
    }
    this.renameSubscription = this.memberService.update({
      type: 'rename',
      templateId: item.templateId,
      title: this.manageService.editData.title
    })
      .subscribe(data => {
        if (data) {
          this.message.success('修改成功！');
          this.recentlySubscription = this.memberService.getMemberTemplateList1({ recent_design: 1 })
            .subscribe(data => {
              this.deslist = data;
            });
        }
      });
  }*/

  /*@ViewChild('deleteTem') deleteTem: TemplateRef<{}>;

  Temdelete(): void {
    this.delRef = this.modalService.create({
      nzContent: this.deleteTem,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: { top: '283px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 424
    });
  }*/

  /*ok() {
    this.delSubscription = this.memberService.operation({ type: 'trash', ids: [this.newData.templateId] })
      .subscribe(data => {
        if (data) {
          this.message.success('删除成功！');
          this.delRef.destroy();
          this.recentlySubscription = this.memberService.getMemberTemplateList1({ recent_design: 1 })
            .subscribe(data => {
              this.deslist = data;
            });
        } else {
          this.message.success('删除失败！');
          this.delRef.destroy();
        }
      });
  }

  cancel() {
    this.delRef.destroy();
  }*/

  ngAfterViewInit(): void {
    //首页广告位轮播图
    if (this.coop.coopInfo && this.coop.coopInfo.adv.length > 1) {
      this.adSwiper = new Swiper('.swiper-container', {
        direction: 'horizontal',
        grabCursor: true,
        autoplay: true,
        loop: true,
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      });
    }
  }

  /*search1(label, classifyid): void {
    this.jumpService.getUrlParam([{ name: 'k', value: label }, { name: 'pr', value: classifyid }], true)
      .pipe(take(1))
      .subscribe(data => {
        this.router.navigate(['/template/list/', data]);
      });
  }*/

  toDesign(item) {
    if (this.coop.coopInfo.id == 12718 && item.thumbnailShouldAt > item.thumbnailUpdatedAt) {
      this.message.error('缩略图正在生成，请稍后刷新重试');
    } else if (!item.isEdit && this.coop.coopInfo.id == 12718) {
      this.modal.preview(item, false);
    } else {
      window.open(this.designService.getMemberUpdateUrl(item.templateId, item));
    }
  }

  statistics(name) {
    if (window['zhuge']) {
      window['zhuge']['track']('微创印-点击模版分类图标', {
        '用户ID': this.authService.isGuest ? '0' : this.authService.user.coopUid,
        '模版分类名称': name
      });
    }
  }

  swipeLink(link: string) {
    if (link) window.open(link)
  }

  trackByElements(index: number, item): any {
    return item.classifyId;
  }
}
