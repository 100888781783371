import { Component, OnInit } from '@angular/core';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';

@Component({
  selector: 'app-calendar-content-tool',
  templateUrl: './calendar-content-tool.component.html',
  styleUrls: ['./calendar-content-tool.component.scss']
})
export class CalendarContentToolComponent implements OnInit {
  element = this.editor.activeElements[0];
  content = this.element.options.templateData;

  constructor(
    public editor: EditorService
  ) { }

  ngOnInit() {
  }

  getFont(id) {
    return this.editor.fontList.find(font => font.fontId === id)
  }

  trackByIndex(index:number) {
    return index;
  }

  changeFontColor(color, fontIndex, index) {
    this.content.fonts[fontIndex].colors[index] = color;
    this.element.parent.emit('element:modified', {
      type: 'colorChange',
      targets: [this.element]
    });
  }

  changeFont(font, fontIndex) {
    this.content.fonts[fontIndex].id = font.fontId;
    this.element.parent.emit('element:modified', {
      type: 'fontFamily',
      targets: [this.element]
    });

  }

  changeBgColor(color, index) {
    this.content.bgColors[index] = color;
    this.element.parent.emit('element:modified', {
      type: 'colorChange',
      targets: [this.element]
    });
  }

  changeBorderColor(color, index) {
    this.content.borderColors[index] = color;
    this.element.parent.emit('element:modified', {
      type: 'colorChange',
      targets: [this.element]
    });
  }

}
