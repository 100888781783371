import { Component, Input, OnInit } from '@angular/core';
import { ModalObject } from '@libs/modal/src/lib/models/modal-object';
import { fadeInOut } from '@libs/core/src/lib/animations/animations';
@Component({
  selector: 'lib-t-toast',
  templateUrl: './t-toast.component.html',
  styleUrls: ['./t-toast.component.scss'],
  animations: [fadeInOut]
})
export class TToastComponent implements OnInit {
  @Input('modalObject') modalObject: ModalObject;
  @Input('icon') icon = '';
  @Input('msg') msg = '';
  @Input('duration') duration = 0;

  // 定义icon对应的class样式与动画
  public iconClass = {
    info: {
      class: 'icon-info',
      bgColor: '#43d2ff'
    },
    warn: {
      class: 'icon-warning',
      bgColor: '#f1c40f'
    },
    error: {
      class: 'icon-error-r-o',
      bgColor: '#ff9f81'
    },
    loading: {
      class: 'icon-loading rotate',
      bgColor: '#1abc9c'
    },
    success: {
      class: 'icon-success2e',
      bgColor: '#1abc9c'
    }
  };
  constructor() {}
  ngOnInit(): void {}
}
