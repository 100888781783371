import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastIcon } from '@libs/modal/src/lib/models/toast-icon';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';
import { UploadService } from '@libs/core/src/lib/services/upload.service';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';

@Component({
  selector: 'app-video-transcoding',
  templateUrl: './video-transcoding.component.html',
  styleUrls: ['./video-transcoding.component.scss']
})
export class VideoTranscodingComponent implements OnInit {
  constructor(
    public modal:ModalService,
    public upload:UploadService,
    public editor:EditorService
  ) { }
  @ViewChild('qrCodeComponent') qrCodeComponent: ElementRef;
  public url = '';
  public qrCodeShow = false;
  public loading = false;
  ngOnInit() {
  }
  createQrCode(){
    if (!this.url){
      this.modal.toast({
        icon: ToastIcon.ERROR,
        msg: '请输入链接'
      });
      return;
    }
    this.qrCodeShow = true;
  }
  addQrCode(){
    if (!this.qrCodeShow) return;
    if (this.loading) return;
    this.loading = true;
    let canvasData = document.getElementById('qr-code-img').getElementsByTagName('canvas')[0];
    let imgData = canvasData.toDataURL("images/png",1.0);
    let fileName = this.randomString()+'.png';
    let imgFile = this.dataURLtoFile(imgData,fileName);
    this.upload.uploadTemp({
      file:imgFile,
      onSuccess: res => {
        this.editor.stage.createElementFromJSON(res);
        this.modal.toast({
          icon: ToastIcon.SUCCESS,
          msg: '已添加到画布'
        });
        this.loading = false;
      },
      onFail: e => {
        this.modal.toast({
          icon: ToastIcon.SUCCESS,
          msg: '添加失败请重试'
        });
        this.loading = false;
      }
    });
  }
  downloadQrCode(){
    if (!this.qrCodeShow) return;
    let qrCodeData = document.getElementById('qr-code-img').getElementsByTagName('canvas')[0].toDataURL("images/png",1.0);
    let domA = document.createElement('a');
    domA.href = qrCodeData;
    domA.dataset.downloadurl = ["images/png",'', domA.href].join(':');
    domA.download="下载";
    document.body.appendChild(domA);
    domA.click();
    domA.remove();
  }
  clearContent(){
    if (!this.qrCodeShow) return;
    this.url = "";
    this.qrCodeShow = false;
  }
  randomString(len = 32): string {
    const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    const maxPos = $chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  }
  dataURLtoFile(dataurl,filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
