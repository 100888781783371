// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  hmr: false,
  production: false,
  // baseUrl: 'http://api5.tubangzhu.site',
  // baseUrl: 'http://api.tbz.com',
  //baseUrl: 'http://api.aisjb.cn',
  baseUrl: 'https://d.aisjb.cn/sxb-api',
  version: 'v1',
  clientId: 'tubangzhu_web',
  handle: 'frontend',
  coop_id: '13068'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
