import { Component, forwardRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Validator } from '../../../validators/validators';
import { mobileValidator } from '../../../directives/mobile.directive';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { ModalService } from '../../../../../../core/src/lib/services/modal.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss']
})
export class ApplyComponent implements OnInit, OnDestroy {
  public val: Validator; //定义一个validators类型的变量 val
  applyForm: FormGroup;

  // 是否显示正在加载中
  isLoadingSubmit = false;
  //获取验证码倒计时
  count = 0;

  constructor(
    private authService: AuthService,
    private modal: NzModalRef,
    @Inject(forwardRef(() => ModalService))
    private modalService,
    private message: MessageService,
    private fb: FormBuilder
  ) {
    this.val = new Validator();
  }

  ngOnInit(): void {
    //创建手机登录表单元素
    this.applyForm = this.fb.group({
      name: ['', Validators.required],
      mobile: ['', [Validators.required, mobileValidator()]],
      wx: [''],
      qq: ['']
    });

  }

  /**
   * 表单提交动作
   * @param form
   */
  onSubmit(): void {
    if (this.applyForm.invalid) return;
    this.isLoadingSubmit = true;

    this.authService.coopApply(this.applyForm.value).subscribe(
      () => {
        this.isLoadingSubmit = false;
        this.message.success('申请成功！');
        this.modal.destroy();
      },
      (err: HttpErrorResponse) => {
        this.isLoadingSubmit = false;
        this.message.error(err);
      }
    );
  }

  ngOnDestroy(): void {}

}
