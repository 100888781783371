import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import { ModalClose, PasswordLogin } from '../+state/app.actions';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { selectAuthIsLogin } from '@libs/core/src/lib/+state/auth.reducer';
import { LoginClose } from '@libs/core/src/lib/+state/auth.actions';

import * as bowser from 'bowser';
import { CoopService } from '@libs/core/src/lib/services/coop.service';

@Component({
  selector: 'app-pages',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: []
})
export class PagesComponent implements OnInit {

  constructor(
    private message: NzMessageService,
    private modal: ModalService,
    private store: Store<any>,
    private router: Router,
    private coop: CoopService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit(): void {
    // 检测浏览器
    if (isPlatformBrowser(this.platformId)) {
      const browser = bowser.getParser(window.navigator.userAgent);
      const browserInfo = browser.getBrowser();
      if (browserInfo.name !== 'Chrome' && !sessionStorage.getItem("showCompatible")) {
        if (!/^www\.(tbz|tubangzhu).*$/.test(location.hostname)) {
          this.modal.showCompatible();
          sessionStorage.setItem("showCompatible","true")
        }
      }
    }

    // 打开登录窗口
    this.store.select(selectAuthIsLogin).subscribe(data => {
      if (data) {
        if (this.coop.coopInfo.login_url) {
          window.location.href = this.coop.coopInfo.login_url;
        } else {
          const loginModal = this.modal.loginModal();
          loginModal.afterClose.subscribe(() => {
            this.store.dispatch(new LoginClose());
            this.store.dispatch(new PasswordLogin());
          });
        }

      }
    });

  }
}
