import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DataProvider } from '@libs/core/src/lib/util/data-provider';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { UploadService } from '@libs/core/src/lib/services/upload.service';
import { OrderService } from '@libs/core/src/lib/services/post-order/order.service';
import { catchError, map, debounceTime, switchMap } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { mobileValidator } from '@libs/shared/src/lib/directives/mobile.directive';
import { DesignerService } from '@libs/core/src/lib/services/post-order/designer.service';

@Component({
  selector: 'app-create-thread',
  templateUrl: './create-thread.component.html',
  styleUrls: ['./create-thread.component.scss']
})
export class CreateThreadComponent implements OnInit {
  @Input() id: any;
  validateForm: FormGroup;
  titleText: string;
  dataProvider = new DataProvider<any>();
  threadInfo = {
    title: '',
    shop_id: null,
    contacts: '',
    qq: '',
    wang_wang: '',
    wechat: '',
    code_id: null,
    code_path: '',
    mobile: '',
    designer_id: null,
  }
  loading = false;
  isLoading = false;
  searchChange$ = new BehaviorSubject('');
  optionList = [];
  btnLoading = false;
  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private message: MessageService,
    public auth: AuthService,
    public upload: UploadService,
    private orderService: OrderService,
    private designerService: DesignerService
  ) { }

  submitForm(value: any) {
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }
    if (value.mobile !== '') {
      if (!(/^1[3456789]\d{9}$/.test(value.mobile))) {
        this.message.error('请填写正确手机号');
        return false
      }
    }
    if (this.validateForm.invalid) {
      return
    }
    value.shop_id = value.shop_id.toString();
    value.designer_id = Number(value.designer_id);
    if (value.designer_id === 0) {
      value.designer_id = '';
    }
    if (this.id !== 'add') {
      this.btnLoading = true;
      value.id = this.id;
      this.orderService.editParentOrder(value).subscribe(res => {
        this.btnLoading = false;
        this.message.success('修改成功');
        this.modal.close(res);
      }, (err: HttpErrorResponse) => {
        this.btnLoading = false;
        this.message.error(err);
      })
    } else {
      this.btnLoading = true;
      this.orderService.addParentOrder(value).subscribe(res => {
        this.btnLoading = false;
        if (res.message) {
          this.message.error(res.message);
        } else {
          this.message.success('添加成功');
          // window.location.reload();
          this.modal.close(res);
        }
      }, (err: HttpErrorResponse) => {
        this.btnLoading = false;
        this.message.error(err);
      })
    }
  }

  fileUpload = item => {
    return this.upload.upload(item, 0, 'frontend', 'normal');
  };

  updateImage(info: { file: NzUploadFile }) {
    if (info.file.status === 'uploading') {
      this.loading = true;
      return;
    }
    if (info.file.status === 'done') {
      this.loading = false;
      this.validateForm.patchValue({ 'code_path': info.file.response.filePath });
      this.validateForm.patchValue({ 'code_id': info.file.response.fileId });
    }
  }

  delImage() {
    this.validateForm.patchValue({ 'code_path': '' });
    this.validateForm.patchValue({ 'code_id': null });
  }

  formInit() {
    this.validateForm = this.fb.group({
      title: [this.threadInfo.title, [Validators.required]],
      shop_id: [this.threadInfo.shop_id, [Validators.required]],
      contacts: [this.threadInfo.contacts],
      qq: [this.threadInfo.qq],
      wang_wang: [this.threadInfo.wang_wang],
      wechat: [this.threadInfo.wechat],
      code_path: [this.threadInfo.code_path],
      code_id: [this.threadInfo.code_id],
      mobile: [this.threadInfo.mobile, mobileValidator()],
      designer_id: [this.threadInfo.designer_id]
    });
  }

  onSearch(value: string) {
    this.isLoading = true;
    this.searchChange$.next(value);
  }

  ngOnInit() {
    this.formInit();
    if (this.id !== 'add') {
      this.titleText = '修改';
      this.orderService.parentOrderDetail(this.id).subscribe(res => {
        this.threadInfo = res;
        this.threadInfo.shop_id = res.shopId;
        this.threadInfo.code_path = res.codePath;
        this.threadInfo.code_id = res.codeId;
        this.threadInfo.wang_wang = res.wangWang;
        this.threadInfo.designer_id = res.designerId;
        this.formInit();
      }, (err: HttpErrorResponse) => {
        this.message.error(err);
      })
    } else {
      this.titleText = '创建';
    }

    const getRandomNameList = (name: string) => this.designerService.designerList({ status: 20, keywords: name }).pipe(
      map(data => this.dataProvider.prepare(data))
    )
    const optionList$: Observable<string[]> = this.searchChange$.asObservable().pipe(debounceTime(500)).pipe(switchMap(getRandomNameList));
    optionList$.subscribe(data => {
      this.optionList = data;
      this.isLoading = false;
    });
  }

}
