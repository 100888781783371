import { Component, HostListener, OnInit } from '@angular/core';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { LoginSuccess } from '@libs/core/src/lib/+state/auth.actions';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Env } from '@libs/core/src/lib/util/environment';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-ppy-login',
  templateUrl: './ppy-login.component.html',
  styleUrls: ['./ppy-login.component.css']
})
export class PpyLoginComponent implements OnInit {
  loginUrl: any;

  constructor(
    private env: Env,
    private auth: AuthService,
    private coop: CoopService,
    private sanitizer: DomSanitizer,
    private modalRef: NzModalRef,
    private store: Store<any>
  ) {
  }

  ngOnInit() {
    this.loginUrl = this.safeUrl();
  }

  @HostListener('window:message', ['$event'])
  handleMessage(event: MessageEvent): void {

    console.log('拍拍印 登录回调:', event);
    // 拍拍印登录iframe 回调
    if (event.data.event == 'closeMe') {
      this.modalRef.close();
    } else if (event.data.event == 'loginOk') {
      this.auth.codeLogin(event.data.loginCode).subscribe(user => {
        this.auth.user = user;
        this.auth.isGuest = false;
        this.store.dispatch(new LoginSuccess({ user: user }));
        this.modalRef.close();
      })
    }
  }

  safeUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.coop.coopInfo.coop_pc_domain + '/third/tbz/p4plogin');
  }

  close() {
    this.modalRef.close()
  }
}
