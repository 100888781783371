import { fadeInOut } from '@libs/core/src/lib/animations/animations';
import { UserInterfaceService } from '../../../services/ui.service';
import { UserInterfaceItem } from '../../../models/base/user-interface-item';
import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  HostListener, EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-t-window',
  templateUrl: './t-window.component.html',
  styleUrls: ['./t-window.component.scss'],
  animations: [fadeInOut]
})
export class TWindowComponent implements OnInit, OnDestroy {
  @Input() item: UserInterfaceItem;
  @ViewChild('compRef', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;
  componentRef: ComponentRef<any>;
  private mouse = {
    vecX: 0,
    vecY: 0,
    lastX: null,
    lastY: null,
    corner: null
  };
  constructor(
    public ui: UserInterfaceService,
    private resolver: ComponentFactoryResolver
  ) { }

  ngOnInit(): void {
    this.container.clear();
    const factory: ComponentFactory<
      any
      > = this.resolver.resolveComponentFactory(this.item.params.component);
    this.componentRef = this.container.createComponent(factory);
    if (this.item.params.props) {
      for (const item of Object.keys(this.item.params.props)) {
        this.componentRef.instance[item] = this.item.params.props[item];
      }
    }
    this.componentRef.instance.parentWindow = this.item;
    if (this.componentRef.instance.close instanceof EventEmitter) {
      this.componentRef.instance.close.subscribe(() => this.onCloseClick());
    }

  }
  ngOnDestroy(): void {
    setTimeout(() => {
      this.componentRef.destroy();
    }, 1000);
  }
  onWindowCornerMousedown(corner, event): void {
    event.stopPropagation();
    this.mouse.corner = corner;
  }
  onWindowMouseDown(event: MouseEvent): void {
    this.ui.setActiveUI(this.item);
    event.stopPropagation();
  }
  async onCloseClick() {
    const beforeDestroy = this.componentRef.instance.beforeDestroy;
    if (beforeDestroy) {
      const goon = await beforeDestroy.apply(this.componentRef.instance);
      if (goon === false) return;
    }
    this.ui.remove(this.item);
  }
  @HostListener('document:mousedown', ['$event']) onWindowContainerMouseDown(): void {
    this.ui.setActiveUI(null);
  }
  onDragAreaMouseDown(event: MouseEvent): void {
    this.mouse.corner = 'move';
  }
  @HostListener('document:mousemove', ['$event']) onWindowContainerMouseMove(event): void {
    if (this.mouse.lastX === null || this.mouse.lastY === null) {
      this.mouse.lastX = event.clientX;
      this.mouse.lastY = event.clientY;
    }
    this.mouse.vecX = event.clientX - this.mouse.lastX;
    this.mouse.vecY = event.clientY - this.mouse.lastY;
    this.mouse.lastX = event.clientX;
    this.mouse.lastY = event.clientY;

    switch (this.mouse.corner) {
      case 'move':
        this.item.params.x += this.mouse.vecX;
        this.item.params.y += this.mouse.vecY;
        break;
      case 'tl':
        this.item.params.x += this.mouse.vecX;
        this.item.params.y += this.mouse.vecY;
        this.item.params.width -= this.mouse.vecX;
        this.item.params.height -= this.mouse.vecY;
        break;
      case 'tr':
        this.item.params.y += this.mouse.vecY;
        this.item.params.width += this.mouse.vecX;
        this.item.params.height -= this.mouse.vecY;
        break;
      case 'bl':
        this.item.params.x += this.mouse.vecX;
        this.item.params.width -= this.mouse.vecX;
        this.item.params.height += this.mouse.vecY;
        break;
      case 'br':
        this.item.params.width += this.mouse.vecX;
        this.item.params.height += this.mouse.vecY;
        break;
      case 'mt':
        this.item.params.y += this.mouse.vecY;
        this.item.params.height -= this.mouse.vecY;
        break;
      case 'mb':
        this.item.params.height += this.mouse.vecY;
        break;
      case 'ml':
        this.item.params.x += this.mouse.vecX;
        this.item.params.width -= this.mouse.vecX;
        break;
      case 'mr':
        this.item.params.width += this.mouse.vecX;
        break;
    }
    if (this.mouse.corner !== null && this.mouse.corner === 'move') {
      this.item.emit('move', {
        x: this.item.params.x,
        y: this.item.params.y
      });
    } else if (this.mouse.corner !== null) {
      this.item.emit('resize', {
        width: this.item.params.width,
        height: this.item.params.height
      });
    }
  }
  @HostListener('document:mouseup', ['$event']) onWindowContainerMouseUp(event): void {
    this.mouse.corner = null;
  }
  onMinimizeClick(): void {
    this.item.states.show = 'minimize';
    this.ui.setActiveUI(undefined);
  }
  getWindowStyle(): void {
    const style = {
      ...this.getPosAndSize()
    };
    if (this.item.states.show === 'minimize') {
      style.opacity = 0;
      style.transform = 'scale(0)';
    }
    return style;
  }
  getPosAndSize(): any {
    return {
      left: this.item.params.x + 'px',
      top: this.item.params.y + 'px',
      width: this.item.params.width + 'px',
      height: this.item.params.height + 'px'
    };
  }
  getWindowContainerStyle(): any {
    const style = {
      pointerEvents: 'none',
      backgroundColor: 'none'
    };
    if (this.item.params.backdrop) {
      style.backgroundColor = 'rgba(0,0,0,.5)';
      style.pointerEvents = 'auto';
    }
    // if (this.mouse.corner === 'move') {
    //   style.pointerEvents = 'auto';
    // }

    return style;
  }
}
