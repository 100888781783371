import { Component, OnInit } from '@angular/core';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { ClipboardService } from 'ngx-clipboard';
import { MessageService } from '@libs/core/src/lib/services/message.service';

@Component({
  selector: 'app-prompts',
  templateUrl: './prompts.component.html',
  styleUrls: ['./prompts.component.scss']
})
export class PromptsComponent implements OnInit {

  constructor(
    public coopService: CoopService,
    private clipboardService: ClipboardService,
    private message: MessageService
  ) { }

  ngOnInit() {
  }

  clipboard(tel: string) {
    this.clipboardService.copyFromContent(tel);
    this.message.success('复制成功!');
  }

  openQQ(qq: string) {
    window.open(`tencent://message/?Site=baidu.com&uin=${qq}&Menu=yes`);
  }

}
