import { Component, forwardRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { AuthState, selectAuthState } from '@libs/core/src/lib/+state/auth.reducer';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { Store } from '@ngrx/store';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { LoginSuccess } from '@libs/core/src/lib/+state/auth.actions';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { CodeService } from '@libs/core/src/lib/services/code.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Validator } from '../../../validators/validators';
import { mobileValidator } from '../../../directives/mobile.directive';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { Env } from '@libs/core/src/lib/util/environment';
import { passwordValidator } from '@libs/shared/src/lib/validators/passwordvalidators';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { DesignerService } from '@libs/core/src/lib/services/post-order/designer.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as $ from 'jquery';
import { stringify } from 'querystring';
declare const wx: any;
@AutoUnsubscribe()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public val: Validator; //定义一个validators类型的变量 val
  loginForm: FormGroup;
  countryCode: any[] = [];
  //loginType = this.coop.coopInfo.id == 12718 ? 'mobile' : 'password';
  loginType = 'wx';
  // 是否显示正在加载中
  isLoadingSubmit = false;
  //获取验证码倒计时
  count = 0;
  reg_count = 0;
  qrCodeUrl:string = '';
  myId: string = '';
  ticket: string = '';
  timer;


  private auth$;

  constructor(
    private env: Env,
    public coop: CoopService,
    private authService: AuthService,
    private store: Store<AuthState>,
    private notification: NzNotificationService,
    private modal: NzModalRef,
    @Inject(forwardRef(() => ModalService))
    private modalService,
    private message: MessageService,
    private code: CodeService,
    private fb: FormBuilder,
    private designer: DesignerService,
    private cookie: CookieService,
    private router: Router,
    public http: HttpClient,
  ) {
    this.auth$ = store.select(selectAuthState);
    this.val = new Validator();
    this.authService.getCountryCode().subscribe(data => {
      this.countryCode = data;
    });
  }

  async ngOnInit() {
    // 初始化
    //await this.initData();
    //await this.initWxLogin();
    // 获取公众号二维码
    //sessionStorage.setItem('myId', '')
    this.getQrCode();
    // 同时，开始轮询
    this.loopGet()
    //创建手机登录表单元素
    this.loginForm = this.fb.group({
      mobileCode: this.fb.group({
        is_sms: [1],
        country_code_id: [1],
        mobile: ['', [Validators.required]],
        code: ['', [Validators.required, Validators.minLength(6)]]
      }),
      byPassword: this.fb.group(
        {
          mobile: ['', [Validators.required, mobileValidator()]],
          password: ['', [Validators.required, Validators.minLength(6)]],
        },
      ),
      register: this.fb.group(
        {
          is_coop: ['0'],
          coop_id: [this.env.coop_id],
          country_code_id: [1],
          mobile: ['', [Validators.required, mobileValidator()]],
          code: ['', [Validators.required, Validators.minLength(6)]],
          password: ['', [Validators.required, Validators.minLength(8)]],
        },
      ),
      forget: this.fb.group(
        {
          coop_id: [this.env.coop_id],
          mobile: ['', [Validators.required, mobileValidator()]],
          code: ['', [Validators.required, Validators.minLength(6)]],
          password: ['', [Validators.required, Validators.minLength(8)]],
          password_repeat: ['', [Validators.required]]
        },
        { validator: passwordValidator }
      )
    });

  }

  /**
   * 表单提交动作
   * @param form
   */
  onSubmit(form): void {
    if (this.loginForm.get(form).invalid) return;
    this.isLoadingSubmit = true;
    const value = this.loginForm.value[form];
    switch (form) {
      // 手机密码登录
      case 'byPassword':
      case 'mobileCode':
        this.authService.loginByMobile(value)
          .pipe(
            switchMap(data => {
              if (data.status == 'y') {
                return this.authService.updateUserInfo(data.user).pipe(
                  switchMap(user => {
                    return this.designer.designerInfo().pipe(
                      map(role => {
                        return {
                          ...user,
                          ...role,
                          ...data.user
                        }
                      })
                    )
                  })
                )
              } else {
                return of(data.msg)
              }
            })
          )
          .subscribe(
            data => {
              this.isLoadingSubmit = false;
              if (typeof data == 'object') {
                this.doLogin(data);
              } else {
                this.message.error(data);
              }
            },
            (err: HttpErrorResponse) => {
              this.isLoadingSubmit = false;
              this.message.error(err);
            }
          );
        break;

      case 'register':
        this.authService.register(value)
          .pipe(
            switchMap(data => {
              if (data.status == 'y') {

                return this.authService.updateUserInfo(data.user).pipe(
                  switchMap(userInfo => {
                    return this.designer.designerInfo().pipe(
                      map(role => ({
                        ...userInfo,
                        ...role,
                        ...data.user
                      }))
                    )
                  })
                )

                /*return this.designer.designerInfo().pipe(
                  switchMap(role => {
                    return this.authService.updateUserInfo({ ...role, ...data.user }).pipe(
                      map(user => {
                        return {
                          ...user,
                          ...role,
                          ...data.user
                        }
                      })
                    )
                  })
                )*/
              } else {
                return of(data.msg)
              }
            })
          )
          .subscribe(
            data => {
              this.isLoadingSubmit = false;
              if (typeof data == 'object') {
                this.doLogin(data);
              } else {
                this.message.error(data);
              }
            },
            (err: HttpErrorResponse) => {
              this.isLoadingSubmit = false;
              this.message.error(err);
            }
          );
        break;
      case 'forget':
        this.authService.userForget(value).subscribe(
          () => {
            this.isLoadingSubmit = false;
            this.message.success('更改成功,请登录');
            this.loginType = 'password';
          },
          (err: HttpErrorResponse) => {
            this.isLoadingSubmit = false;
            this.message.error(err);
          }
        )
    }
  }

  changeLoginType(type){
    this.loginType = type;
    if(type === 'wx') {
      this.getQrCode();
      this.loopGet();
    } else {
      clearTimeout(this.timer);
    }
  }

  enterpriseWechatLogin() {
    this.authService.getEnterpriseWechatUrl().subscribe(res =>{
      if(res && res.url){
        window.open(res.url);
      }
    });
  }

  //绑定手机获取短信验证码
  getMobileCode(mobile, type): void {
    if (type === 'register') {
      this.reg_count = 59;
      const interval$ = setInterval(() => {
        this.reg_count -= 1;
        if (this.reg_count <= 0) clearInterval(interval$);
      }, 1000);
      this.authService.getSms(mobile, type, this.loginForm.value.register.country_code_id).subscribe(data => { });
    } else {
      this.count = 59;
      const interval$ = setInterval(() => {
        this.count -= 1;
        if (this.count <= 0) clearInterval(interval$);
      }, 1000);
      this.authService.getSms(mobile, type, this.loginForm.value.mobileCode.country_code_id).subscribe(data => { });
    }

  }

  doLogin(data) {
    this.store.dispatch(new LoginSuccess({ user: data }));
    this.destroyModal();
    if (location.pathname.includes('/postorder/')) { // 放单系统，设计师登录成功后刷新页面
      window.location.reload();
    }
  }

  destroyModal(): void {
    this.modal.destroy();
  }

  ngOnDestroy(): void {
    //console.log('1111111')
    clearTimeout(this.timer);


  }

  get loginByPasswordTel(): AbstractControl {
    return this.loginForm.get('byPassword.mobile')
  }

  get loginByPassword() {
    return this.loginForm.get('byPassword.password')
  }

  get loginByCodeTel() {
    return this.loginForm.get('mobileCode.mobile')
  }

  get loginByCode() {
    return this.loginForm.get('mobileCode.code')
  }

  get registerTel() {
    return this.loginForm.get('register.mobile')
  }

  get registerPassword() {
    return this.loginForm.get('register.password')
  }

  get registerCode() {
    return this.loginForm.get('register.code')
  }

  get forgetTel() {
    return this.loginForm.get('forget.mobile')
  }

  get forgetPassword() {
    return this.loginForm.get('forget.password')
  }

  get forgetRepeat() {
    return this.loginForm.get('forget.password_repeat')
  }

  get forgetrCode() {
    return this.loginForm.get('forget.code')
  }

  // 获取公众号二维码
  getQrCode() {
    this.authService.getLoginQrcode().subscribe(res =>{
      if(res && res.content){
        this.qrCodeUrl = res.content;
        let myImage = document.getElementById('myImage') as any;
        myImage.src = this.qrCodeUrl;
      }
    });
  }

  loopGet() {
    try{
      this.authService.getWxSession().subscribe({
        next: data => {
          if(data) {
            this.authService.updateUserInfo(data).pipe(
              switchMap(user => {
                return this.designer.designerInfo().pipe(
                  map(role => {
                    return {
                      ...user,
                      ...role,
                      ...data
                    }
                  })
                )
              })
            )
            this.isLoadingSubmit = false;
            if (typeof data == 'object') {
              this.doLogin(data);
            }
          }
        },
        error: err => {
          this.timer=setTimeout(()=>{
            clearTimeout(this.timer);
            this.loopGet();
          }, 4000)
        }
      });
    } catch(error) {
      throw '报错'+error
    }

  }


  initWxLogin() {
    // 这里的配置参数需要你根据实际情况进行设置
    wx.config({
      debug: true,
      appId: '', // 必填，公众号的唯一标识
      timestamp: '', // 必填，生成签名的时间戳
      nonceStr: '', // 必填，生成签名的随机串
      signature: '',// 必填，签名
      jsApiList: ['checkJsApi', 'onMenuShareTimeline', 'onMenuShareAppMessage'] // 必填，需要使用的JS接口列表
    });

    wx.ready(function () {
      // 在这里调用 API
      wx.checkJsApi({
        jsApiList: ['getNetworkType', 'previewImage'], // 需要检测的JS接口列表，所有JS接口列表见微信官方文档
        success: function (res) {
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkJsApi":true}
        }
      });
    });

    wx.error(function (res) {
      // 配置失败回调
      console.log('配置失败', res);
    });
  }


}
