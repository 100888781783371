import { Component, OnInit, OnDestroy } from '@angular/core';
import { EditorService } from '../../../../../services/editor.service';
import { Modal } from '../../../../../models/ui/modal';
import { UserInterfaceService } from '../../../../../services/ui.service';
import { LineToolComponent } from '../../modals/line-tool/line-tool.component';
import { ShapeRadiusToolComponent } from '../../modals/shape-radius-tool/shape-radius-tool.component';
import * as $ from 'jquery';
@Component({
  selector: 'app-tool-bar-shape',
  templateUrl: './tool-bar-shape.component.html',
  styleUrls: ['./tool-bar-shape.component.scss']
})
export class ToolBarShapeComponent implements OnInit {
  showRadiusSetting = false;
  selectEvent;
  get activeElement() {
    return this.editor.activeElements[0];
  }
  constructor(public editor: EditorService, public ui: UserInterfaceService) { }

  ngOnInit() {
    // 判断是否为矩形
    this.showRadiusSetting = $(this.activeElement.states.svg).find('rect').length > 0;

  }


  onRadiusToolClick(event) {
    const modal = new Modal({
      x: event.clientX - 290 / 2,
      y: event.clientY + 20,
      width: 290,
      height: 70,
      component: ShapeRadiusToolComponent
    });
    this.ui.add(modal);
  }
  onLineToolClick(event) {
    const modal = new Modal({
      x: event.clientX - 250 / 2,
      y: event.clientY + 20,
      width: 250,
      height: 200,
      component: LineToolComponent
    });
    this.ui.add(modal);
  }
  /**
   * 事件:属性被修改
   * @param name 属性
   * @param value 值
   * @param fireEvent 是否触发事件
   */
  onChange(name, value, fireEvent = true): void {
    this.editor.activeElements.forEach(eleItem => {
      eleItem.options[name] = value;
    });
    if (fireEvent) {
      if (this.editor.activeElements.length === 1) {
        this.editor.activeElements[0].emit('modified', {
          type: 'set' + name,
          target: this.editor.activeElements[0]
        });
      } else if (this.editor.activeElements.length > 1) {
        this.editor.activeElements.forEach(eleItem => {
          eleItem.emit('modified', {
            type: 'set' + name,
            target: eleItem,
            targets: this.editor.activeElements,
            stopPropagation: true
          });
        });
        this.editor.activeElements[0].parent.emit('element:modified', {
          type: 'set' + name,
          targets: this.editor.activeElements
        });
      }
    }
  }
}
