import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import * as $ from 'jquery';

@Component({
	selector: 'preview-image',
	templateUrl: './preview-image.component.html',
	styleUrls: ['./preview-image.component.scss']
})

export class PreviewImageComponent implements OnInit {
	@Input() src;
	@Input() maxWidth;
	constructor(private modal: NzModalRef, private elementRef: ElementRef) {}
	close() {
		this.modal.close();
	}
	ngOnInit() {
	}
}
