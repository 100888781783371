import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { LoginComponent } from '@libs/shared/src/lib/components/modal/login/login.component';
import { TemplateOfficial } from '../models/template-official';
import { Store } from '@ngrx/store';
import { Login } from '../+state/auth.actions';
import { UpgradePromptComponent } from '@libs/shared/src/lib/components/modal/upgrade-prompt/upgrade-prompt.component';
import { ChargeTemplateComponent } from '@libs/shared/src/lib/components/modal/charge-template/charge-template.component';
import { DownloadTemplateComponent } from '@libs/shared/src/lib/components/modal/download-template/download-template.component';
import { Observable } from 'rxjs';
import { ShareTemplateComponent } from '@libs/shared/src/lib/components/modal/share-template/share-template.component';
import { ConfirmComponent } from '@libs/shared/src/lib/components/modal/confirm/confirm.component';
import { CompatibleComponent } from '@libs/shared/src/lib/components/modal/compatible/compatible.component';
import { OauthComponent } from '@libs/shared/src/lib/components/modal/oauth/oauth.component';
import { QrcodeComponent } from '@libs/shared/src/lib/components/modal/qrcode/qrcode.component';
import { isPlatformServer } from '@angular/common';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { ApplyComponent } from '@libs/shared/src/lib/components/modal/apply/apply.component';
import { PromptsComponent } from '@libs/shared/src/lib/components/modal/prompts/prompts.component';
import { PreferentialComponent } from '@libs/shared/src/lib/components/modal/preferential/preferential.component';
import { FolderMember } from '@libs/core/src/lib/models/folder-member';
import { DiyComponent } from '@libs/shared/src/lib/components/modal/diy/diy.component';
import { DiyDesignerComponent } from '@libs/shared/src/lib/components/modal/diy-designer/diy-designer.component';
import { UploadPsdComponent } from '@libs/shared/src/lib/components/modal/upload-psd/upload-psd.component';
import { RechargeComponent } from '@libs/shared/src/lib/components/modal/recharge/recharge.component';
import { NoticeDetailComponent } from '@libs/shared/src/lib/components/modal/notice-detail/notice-detail.component';
import { PreviewImageComponent } from '@libs/shared/src/lib/components/modal/preview-image/preview-image.component';
import { VideoPlayerComponent } from '@libs/shared/src/lib/components/modal/video-player/video-player.component';
import { NewLoginComponent } from '@libs/shared/src/lib/components/modal/new-login/new-login.component';
import { NewRegisterComponent } from '@libs/shared/src/lib/components/modal/new-register/new-register.component';
import { CardMessageComponent } from '@libs/shared/src/lib/components/modal/card-message/card-message.component';
import { TemplateSubmitComponent } from '@libs/shared/src/lib/components/modal/template-submit/template-submit.component';
import { PpyHintComponent } from '@libs/shared/src/lib/components/modal/ppy-hint/ppy-hint.component';
import { PreviewComponent } from '@libs/shared/src/lib/components/modal/preview/preview.component';
import { NologinSaveComponent } from '@libs/shared/src/lib/components/modal/nologin-save/nologin-save.component';
import { NologinSubmitComponent } from '@libs/shared/src/lib/components/modal/nologin-submit/nologin-submit.component';
import { NoVipModalComponent } from '@libs/shared/src/lib/components/modal/no-vip-modal/no-vip-modal.component';
import { BuyVipComponent } from '@libs/shared/src/lib/components/modal/buy-vip/buy-vip.component';
import { VipDownloadComponent } from '@libs/shared/src/lib/components/modal/vip-download/vip-download.component';
import { VipDownloadListComponent } from '@libs/shared/src/lib/components/modal/vip-download-list/vip-download-list.component';
import { KejieApplyComponent } from '@libs/shared/src/lib/components/modal/kejie-apply/kejie-apply.component';
import { WechatPayQrcodeComponent } from '@libs/shared/src/lib/components/modal/wechat-pay-qrcode/wechat-pay-qrcode.component';
import { EditorTipComponent } from '@libs/shared/src/lib/components/modal/editor-tip/editor-tip.component';
import { LongTimeNoOperationComponent } from '@libs/shared/src/lib/components/modal/long-time-no-operation/long-time-no-operation.component';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { PpyLoginComponent } from '@libs/shared/src/lib/components/modal/ppy-login/ppy-login.component';
import { IframeModalComponent } from '@libs/shared/src/lib/components/modal/iframe-modal/iframe-modal.component';
import { AddTemComponent } from '@libs/shared/src/lib/components/modal/add-tem/add-tem.component';
import { SubmitMakeSureComponent } from '@libs/shared/src/lib/components/modal/submit-make-sure/submit-make-sure.component';
import { DiyConfirmComponent } from '@libs/shared/src/lib/components/modal/diy-confirm/diy-confirm.component';
import { SaveSuccessComponent } from '@libs/shared/src/lib/components/modal/save-success/save-success.component';
import { ChoiceSizeComponent } from '@libs/shared/src/lib/components/modal/choice-size/choice-size.component';
import { ClassifyConfig } from '@libs/core/src/lib/models/classify';
import { CustomCardMessageComponent } from '@libs/shared/src/lib/components/modal/custom-card-message/custom-card-message.component';
import { CardSetting } from '@libs/core/src/lib/models/user';
import { TemplateLinkComponent } from '@libs/shared/src/lib/components/modal/template-link/template-link.component';
import { CreateStoreComponent } from '@libs/shared/src/lib/components/modal/create-store/create-store.component';
import { CreatCategoryComponent } from '@libs/shared/src/lib/components/modal/creat-category/creat-category.component';
import { CreateMemorandumComponent } from '@libs/shared/src/lib/components/modal/create-memorandum/create-memorandum.component';
import { AddServiceComponent } from '@libs/shared/src/lib/components/modal/add-service/add-service.component';
import { CreateThreadComponent } from '@libs/shared/src/lib/components/modal/create-thread/create-thread.component';
import { PorderConfirmComponent } from '@libs/shared/src/lib/components/modal/porder-confirm/porder-confirm.component';
import { CutPaymentComponent } from '@libs/shared/src/lib/components/modal/cut-payment/cut-payment.component';
import { TaskHandleComponent } from '@libs/shared/src/lib/components/modal/task-handle/task-handle.component';
import { MessageBoxComponent } from '@libs/shared/src/lib/components/modal/message-box/message-box.component';
import { SizeUpdateHintComponent } from '@libs/shared/src/lib/components/modal/size-update-hint/size-update-hint.component';
import { ExportExcelComponent } from '@libs/shared/src/lib/components/modal/export-excel/export-excel.component';
import { DownloadHistoryComponent } from '@libs/shared/src/lib/components/modal/download-history/download-history.component';
import { LimitClassifyComponent } from '@libs/shared/src/lib/components/modal/limit-classify/limit-classify.component';
import { OrderNoticeComponent } from '@libs/shared/src/lib/components/modal/order-notice/order-notice.component';
import { EditMattingComponent } from '@libs/shared/src/lib/components/modal/edit-matting/edit-matting.component';
import { UpgradeDetailComponent } from '@libs/shared/src/lib/components/modal/upgrade-detail/upgrade-detail.component';
import { SuccessModalComponent } from '@libs/shared/src/lib/components/modal/success-modal/success-modal.component';
import { FolderSelectorComponent } from '@libs/shared/src/lib/components/modal/folder-selector/folder-selector.component';
import { UploadMoadlComponent } from '@libs/editor/src/lib/components/editor-ui/scroll-bar/upload/upload.component';
import { BackgroundComponent } from '@libs/editor/src/lib/components/editor-ui/t-source-tab/tabs/background/background.component';
import { flyLeft } from '../animations/animations';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  wechatModal: NzModalRef;
  tipModal:NzModalRef;
  constructor(
    private authService: AuthService,
    private coop: CoopService,
    @Inject(NzModalService) private modal,
    private store: Store<any>,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
  }

  private checkLogin(): boolean {
    if (this.authService.isGuest) {
      this.store.dispatch(new Login());
      return false;
    }
    return true;
  }

  //上传psd文件
  uploadPsd(template) {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: UploadPsdComponent,
      nzOkText: null,
      nzCancelText: null,
      nzComponentParams: { template },
      nzBodyStyle: { padding: 0 },
      nzStyle: { top: '158px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 580
    });
  }
  /**
   *  删除确认框
   * @param {string} title
   * @param {(data) => void} success
   * @param type
   * @returns {NzModalRef}
   */
  confirm(title: string, success: (data) => void, type?): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: ConfirmComponent,
      nzOkText: null,
      nzCancelText: null,
      nzComponentParams: { title: title, success: success, type: type },
      nzBodyStyle: { padding: 0 },
      nzStyle: { top: '200px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWidth: 424
    });
  }

  /**
   * 登录窗口
   * @returns {NzModalRef}
   */
  loginModal(): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    if (this.coop.coopInfo.id == 12146) {
        return this.ppyLogin();
    }
    return this.modal.create({
      nzContent: LoginComponent,
      nzOkText: null,
      nzCancelText: null,
      nzStyle: { top: '200px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 720
    });
  }

  ppyLogin(): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: PpyLoginComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: { top: 'calc(50vh - 200px)' },
      nzFooter: null,
      nzClosable: false,
      nzMask: true,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 350
    });
  }

  payModal(price: number): void {
    if (isPlatformServer(this.platformId)) return;
    this.modal.create({
      nzContent: RechargeComponent,
      nzOkText: null,
      nzCancelText: null,
      nzComponentParams: { price },
      nzStyle: { top: '200px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 352
    });
  }

  // 公告详情
  noticeDetailModal(id: number): void {
    if (isPlatformServer(this.platformId)) return;
    this.modal.create({
      nzContent: NoticeDetailComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzComponentParams: { id },
      nzStyle: { top: 'calc(50vh - 376px)' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 720
    });
  }

  // 预览公告图片大图
  previewImageModal(src: string, maxWidth?): void {
    if (isPlatformServer(this.platformId)) return;
    this.modal.create({
      nzContent: PreviewImageComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzComponentParams: { src, maxWidth },
      nzStyle: { top: '2vh' },
      nzFooter: null,
      nzMaskClosable: true,
      nzClosable: false,
      nzWidth: '95vw',
      nzClassName: 'no-background'
    });
  }

  coopApplyModal(): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: ApplyComponent,
      nzOkText: null,
      nzCancelText: null,
      nzStyle: { top: '200px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 434
    });
  }

  normalIframe(url, width, height) {
    return this.modal.create({
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzComponentParams: { width, height, url },
      nzStyle: { top: '10vh' },
      nzContent: IframeModalComponent,
      nzFooter: null,
      nzMask: true,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: width
    });
  }

  /**
   * 第三方登录
   * @returns {NzModalRef}
   */
  loginByOauth(url: string, style?: object): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: OauthComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzComponentParams: { url: url },
      nzStyle: { top: 'calc(50vh - 300px)' },
      nzFooter: null,
      nzClosable: false,
      nzMask: true,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 720
    });
  }

  /**
   * 显示购买Vip
   * @returns {NzModalRef}
   */
  showBuyVip(): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: UpgradePromptComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: { top: '280px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 600
    });
  }

  /**
   * 显示点击模板时的购买Vip弹层
   * @param {TemplateOfficial} template
   * @returns {NzModalRef<ChargeTemplateComponent>}
   */
  showTemplateBuyVip(template: TemplateOfficial) {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: UpgradePromptComponent,
      nzComponentParams: { template: template },
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: { top: '280px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 750
    });
  }

  downloadTemplate(template: TemplateOfficial) {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: DownloadTemplateComponent,
      nzComponentParams: { template: template },
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: { top: '280px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWidth: 465
    });
  }

  shareTemplateModal(callback: (userId) => Observable<any>) {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: ShareTemplateComponent,
      nzOkText: null,
      nzComponentParams: { doShare: callback },
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: { top: '280px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWidth: 423
    });
  }

  showCompatible() {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: CompatibleComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: { top: '280px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 544
    });
  }

  /**
   * 名片编辑信息弹框
   */
  cardMessage(type: 'editor' | 'normal' = 'normal', current?: CardSetting): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    if (this.authService.isGuest) {
      this.store.dispatch(new Login());
      return;
    }

    if (this.coop.coopInfo.id == 10214)
      return this.modal.create({
        nzContent: CustomCardMessageComponent,
        nzComponentParams: { type, current },
        nzOkText: null,
        nzCancelText: null,
        nzBodyStyle: { padding: 0 },
        nzStyle: {},
        nzMaskStyle: {},
        nzMaskClosable: false,
        nzFooter: null,
        nzWidth: 540
      });
    else
      return this.modal.create({
        nzContent: CardMessageComponent,
        nzOkText: null,
        nzCancelText: null,
        nzBodyStyle: { padding: 0 },
        nzStyle: {},
        nzMaskStyle: {},
        nzMaskClosable: false,
        nzFooter: null,
        nzWidth: 434
      });
  }

  selectFolder() {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: FolderSelectorComponent,
      nzClosable: false,
      nzComponentParams: { },
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { },
      nzStyle: { top: '320px' },
      nzMaskStyle: { },
      nzFooter: null,
      nzWidth: 438
    });
  }

  fileMenu(style, type: string, data: FolderMember, folder: FolderMember[], side: string): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: PreferentialComponent,
      nzClosable: false,
      nzComponentParams: { type, data, folder, side },
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: { position: 'absolute', ...style },
      nzMaskStyle: { visibility: 'hidden' },
      nzFooter: null,
      nzWidth: 190
    });
  }

  /**
   * 自定义模板
   */
  openDiy(): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: DiyComponent,
      nzClosable: true,
      nzComponentParams: {},
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: {top: '165px'},
      nzFooter: null,
      nzMaskClosable: false,
      nzWidth: 902
    });
  }
  /**
 * 设计师创建模板
 */
  openDesignerDiy(products): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: DiyDesignerComponent,
      nzClosable: true,
      nzComponentParams: {products},
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: {top: '165px'},
      nzFooter: null,
      nzMaskClosable: false,
      nzWidth: 902
    });
  }

  /**
   * menu左下角疑惑解答联系方式
   */
  showLinkWay(): void {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: PromptsComponent,
      nzClosable: false,
      nzComponentParams: {},
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: { position: 'absolute', top: 'auto', bottom: '77px', left: '97px' },
      nzMaskStyle: { visibility: 'hidden' },
      nzFooter: null,
      nzWidth: 284
    });
  }

  showQrcode(url) {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: QrcodeComponent,
      nzComponentParams: { url: url },
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: '20px' },
      nzStyle: { top: '300px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWidth: 170
    });
  }

  /**
   * 视频播放
   */
  videoModal(videoPath: string): void {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: VideoPlayerComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzClosable: false,
      nzComponentParams: { videoPath },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom'
    });
  }

  /**
   * 新登录弹窗
   */
  newLogin(is_coop: number, is_agent: number): void {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: NewLoginComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: { top: '30%' },
      nzFooter: null,
      nzMaskClosable: false,
      nzComponentParams: { is_coop, is_agent },
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 434
    });
  }

  /**
   * 新注册弹窗
   */
  newRegister(): void {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: NewRegisterComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: { top: '28%' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 435
    });
  }

  templateSubmit(params): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: TemplateSubmitComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzComponentParams: params,
      nzStyle: {top: '28%'},
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 622
    });
  }

  //预览弹窗
  preview(template: TemplateOfficial, edit = true, cid = null, virtual = null): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: PreviewComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzComponentParams: {template, edit, cid, virtual},
      nzStyle: {top: '10%'},
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 1200
    });
  }

  ppyHint(url): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: PpyHintComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzComponentParams: {url},
      nzStyle: {top: 'calc(50vh - 300px)'},
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 1200
    });
  }

  noLoginSave () {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: NologinSaveComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzComponentParams: {},
      nzStyle: {top: 'calc(50vh - 144px)'},
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 580
    });
  }

  noLoginSubmit (params) {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: NologinSubmitComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzComponentParams: params,
      nzStyle: {top: 'calc(50vh - 216px)'},
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 514
    });
  }

  closeAll() {
    this.modal.closeAll();
  }
  /**
   * 非VIP用户提示 编辑器
   * @param {string} type 可不传，用于科杰的单独判断
   * @param {string} template 可不传，用于科杰的单独判断
   * @returns {NzModalRef}
   */
  showNoVipUser(template?, type?): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: NoVipModalComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzComponentParams: { type, template },
      nzStyle: {top: 'calc(50vh - 200px)'},
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 387
    });
  }
  /**
   * 显示购买VIP
   * @returns {NzModalRef}
   */
  showBuyVipS(): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: BuyVipComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzComponentParams: {},
      nzStyle: {top: 'calc(50vh - 240px)'},
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 444
    });
  }
  /**
   * 显示保存历史
   * @returns {NzModalRef}
   */
  // showSaveHistory(): NzModalRef {
  //   if (isPlatformServer(this.platformId)) return;
  //   return this.modal.create({
  //     nzContent: SaveHistoryComponent,
  //     nzOkText: null,
  //     nzCancelText: null,
  //     nzBodyStyle: { padding: 0 },
  //     nzComponentParams: {},
  //     nzStyle: {top: 'calc(50vh - 200px)'},
  //     nzFooter: null,
  //     nzMaskClosable: false,
  //     nzWrapClassName: 'ant-modal-custom',
  //     nzWidth: 380
  //   });
  // }
  /**
   * 显示VIP下载
   * @returns {NzModalRef}
   */
  showVipDownload(template, params?): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: VipDownloadComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzComponentParams: {template, params},
      nzStyle: {top:'63px',right:'calc(195px - 50vw'},
      nzFooter: null,
      nzMaskClosable: true,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 350,
      nzMask:true,
      nzMaskStyle:{'background-color':'initial',opacity:0,}
    });
  }
  /**
   * 显示下载列表
   * @returns {NzModalRef}
   */
  showVipDownloadList(template, params?): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: VipDownloadListComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzComponentParams: {template, params},
      nzStyle: {top:'63px',right:'calc(195px - 50vw'},
      nzFooter: null,
      nzMaskClosable: true,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 350,
      nzMask:true,
      nzMaskStyle:{'background-color':'initial',opacity:0,}
    });
  }

  /**
   * 科杰申请免费下载
   */

  showKejieApply(template): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: KejieApplyComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzComponentParams: { template },
      nzStyle: { top: 'calc(50vh - 300px)' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 426
    });
  }

  /**
   * 展示付款二维码
   * @returns {NzModalRef}
   */
  showWechatQrcode(wechatUrl: string, title = '微信扫描完成支付'): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    this.wechatModal = this.modal.create({
      nzContent: WechatPayQrcodeComponent,
      nzComponentParams: { wechatUrl: wechatUrl, title },
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: { top: '340px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 300
    });
    return this.wechatModal;
  }
  /**
   * 关闭付款二维码
   */
  closeWechat(): void {
    this.wechatModal && this.wechatModal.close();
  }

  /**
   * 显示提示弹窗
   */
  showEditorTip(params): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: EditorTipComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzComponentParams: {tipData:params},
      nzStyle: params.style,
      nzFooter: null,
      nzMask:true,
      nzClosable:false,
      nzMaskStyle:{'background-color':'rgba(0, 0, 0, 0.15)'},
      nzWrapClassName: 'ant-tip-style',
      nzWidth: 252
    });
  }

  /**
   * 长时间无操作提示框
   */
  showLongTime(){
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: LongTimeNoOperationComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: {top: 'calc(50vh - 120px)'},
      nzFooter: null,
      nzMaskClosable: false,
      nzClosable:false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 330
    });
  }

  createTemplateModal() {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: AddTemComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: {top: 'calc(50vh - 115px)'},
      nzFooter: null,
      nzMaskClosable: false,
      nzClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 420
    });
  }

  templateLinkModal(template) {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: TemplateLinkComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: {top: 'calc(50vh - 115px)'},
      nzComponentParams: { template },
      nzFooter: null,
      nzMaskClosable: false,
      nzClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 420
    })
  }

  diyConfirm(options) {
    return this.modal.create({
      nzContent: DiyConfirmComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: {top: 'calc(50vh - 100px)'},
      nzComponentParams: Object.assign({
        icon: 'icon-jinggao'
      }, options),
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 442
    });
  }

  sizeUpdateConfirm() {
    return this.modal.create({
      nzContent: SizeUpdateHintComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: {top: 'calc(50vh - 100px)'},
      nzComponentParams: {},
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 472
    });
  }

  saveSuccessTip() {
    return this.modal.create({
      nzContent: SaveSuccessComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: {top: 'calc(50vh - 100px)'},
      nzFooter: null,
      nzMaskClosable: false,
      nzClosable: true,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 410
    });
  }

  submitMakeSure(template) {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: SubmitMakeSureComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: {top: 'calc(50vh - 368px)'},
      nzComponentParams: {template},
      nzFooter: null,
      nzMaskClosable: false,
      nzClosable: true,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 1000
    });
  }

  choiceSize(size: ClassifyConfig[]) {
    return this.modal.create({
      nzTitle: '尺寸选择',
      nzContent: ChoiceSizeComponent,
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: {top: 'calc(50vh - 120px)'},
      nzComponentParams: {size},
      nzFooter: null,
      nzMaskClosable: false,
      nzClosable: true,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 428,
    });
  }

  /**
   * 创建、修改店铺
   * @param {any} id
  */
  createStoreModal(id?:any): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: CreateStoreComponent,
      nzClosable: true,
      nzComponentParams: { id },
      nzOkText: null,
      nzCancelText: null,
      nzStyle: { top: 'calc(50vh - 124px - 50px)' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 430
    });
  }

  /**
   * 创建、修改分类
   * @param {any} id
  */
 createCategorsModal(id?:any, name?: any): NzModalRef {
  if (isPlatformServer(this.platformId)) return;
  return this.modal.create({
    nzContent: CreatCategoryComponent,
    nzClosable: true,
    nzComponentParams: { id, name },
    nzOkText: null,
    nzCancelText: null,
    nzStyle: { top: 'calc(50vh - 124px - 50px)' },
    nzFooter: null,
    nzMaskClosable: false,
    nzWrapClassName: 'ant-modal-custom',
    nzWidth: 430
  });
}

  /**
  * 创建、修改分类
  * @param {any} id
  */
  createMemorandum(id?:any, name?: any, orderPrice?:any, issuingPrice?: any, remarks?:any): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: CreateMemorandumComponent,
      nzClosable: true,
      nzComponentParams: { id, name, order_price: orderPrice, issuing_price: issuingPrice, remarks },
      nzOkText: null,
      nzCancelText: null,
      nzStyle: { top: 'calc(50vh - 180px - 50px)' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 430
    });
  }

  /**
   * 添加、修改客服
   * @param {any} id
  */
  addService(id?:any): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: AddServiceComponent,
      nzComponentParams: { id },
      nzOkText: null,
      nzCancelText: null,
      nzStyle: { top: 'calc(50vh - 270px - 50px)' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 432
    });
  }


  /**
   * 创建、修改客户主线
   * @param {any} id
  */
  createThreadModal(id?: any): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: CreateThreadComponent,
      nzClosable: true,
      nzComponentParams: { id },
      nzOkText: null,
      nzCancelText: null,
      nzStyle: { top: 'calc(50vh - 733px/2)' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 430
    });
  }

  /**
   * 操作确认对话框
   * @param {any} mainContent 主标题
   * @param {any} subContent 副标题
   * @param {any} type 确认类型
   * @param {any} id 操作用户的id
  */
  porderConfirmModal(mainContent?: string, subContent?: string, type?: string, id?): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: PorderConfirmComponent,
      nzComponentParams: { mainContent, subContent, type, id },
      nzOkText: null,
      nzCancelText: null,
      nzStyle: { top: 'calc(50vh - 100px - 50px - 40px)' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 430
    });
  }

  /**
   * 扣款
   * @param {any} id
  */
  cutPayment(id?, deduct?, reason?, deduct_order_id?): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: CutPaymentComponent,
      nzComponentParams: { id, deduct, reason, deduct_order_id },
      nzOkText: null,
      nzCancelText: null,
      nzStyle: { top: 'calc(50vh - 215px - 50px)' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 430
    });
  }

  messageBox(type, message): NzModalRef {
    return this.modal.create({
      nzContent: MessageBoxComponent,
      nzComponentParams: { type, message },
      nzOkText: null,
      nzCancelText: null,
      nzStyle: { top: `calc(50vh - 215px - 50px)` },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 430
    });
  }

  /**
   * 接单流程对话框
   * @param {string} type 弹框作用
   * @param {number} id 订单id
   * @param {string} kind 是否有操作权限
   * @param {string} orderPrice 订单价格
   * @param {number} status 订单状态
   * @param {string} content 传递显示的文字内容
  */
  taskHandle(type?, id?, kind?, orderPrice?, status?, content?): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    var width;
    var top;
    if (type === 'fail') {
      width = 471;
    } else if (type === 'reset') {
      width = 481;
    } else {
      width = 430;
    }
    if (type === 'reset') {
      top = 600;
    } else {
      top = 342;
    }
    if (type === 'check') { top = 400; }
    if (type === 'change') { top = 450; }
    return this.modal.create({
      nzContent: TaskHandleComponent,
      nzComponentParams: { type, id, kind, orderPrice, status, content },
      nzOkText: null,
      nzCancelText: null,
      nzStyle: { top: `calc(50vh - ${top}px/2 - 50px)` },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: width
    });
  }

  /**
   * 导出表格
  */
  exprortExcelModal(type?): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: ExportExcelComponent,
      nzClosable: true,
      nzComponentParams: { type },
      nzOkText: null,
      nzCancelText: null,
      nzStyle: { top: '50%', marginTop: '-173px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 430
    });
  }

  /**
   * 进阶版升级
   */
  upgradeModel(type?): NzModalRef {
    // if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: UpgradeDetailComponent,
      nzClosable: true,
      nzComponentParams: { type },
      nzOkText: null,
      nzCancelText: null,
      nzStyle: null,
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 938
    });
  }

  /**
   * 提交信息成功弹窗
   */
  successModel(): NzModalRef {
    return this.modal.create({
     nzContent: SuccessModalComponent,
     nzClosable: true,
     nzOkText: null,
     nzCancelText: null,
     nzStyle: null,
     nzFooter: null,
     nzMaskClosable: false,
     nzWrapClassName: 'ant-modal-custom',
     nzWidth: 600
   });
  }


  /**
   * 下载记录
  */
  downloadHistoryModal(list?): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: DownloadHistoryComponent,
      nzClosable: true,
      nzComponentParams: { list },
      nzOkText: null,
      nzCancelText: null,
      nzStyle: { top: 'calc(50vh - 324px/2 - 50px)' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 430
    });
  }

  /**
   * 订单提醒
   */
  orderNoticeModal(): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: OrderNoticeComponent,
      nzClosable: true,
      nzComponentParams: {  },
      nzOkText: null,
      nzCancelText: null,
      nzStyle: { top: 'calc(50vh - 582px/2) - 10px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 550
    });
  }

  /**
   * 限制品类
  */
  limitClassifyModal(item?): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: LimitClassifyComponent,
      nzClosable: true,
      nzComponentParams: { item },
      nzOkText: null,
      nzCancelText: null,
      nzStyle: { top: 'calc(50vh - 324px/2 - 50px)' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 807
    });
  }

  /**
   * 编辑器抠图页面
   */
  editCutImageModal(params?) {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: EditMattingComponent,
      nzComponentParams: { template: params },
      nzOkText: null,
      nzCancelText: null,
      nzBodyStyle: { padding: 0 },
      nzStyle: { top: '100px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWidth: 1186,
      nzClosable: false
    });
  }
  /**
   * 上传窗口
   * @returns {NzModalRef}
   */
  uploadModal(currentFolder?): NzModalRef {
    if (isPlatformServer(this.platformId)) return;
    return this.modal.create({
      nzContent: UploadMoadlComponent,
      nzComponentParams: { currentFolder: currentFolder },
      nzOkText: null,
      nzCancelText: null,
      nzStyle: { top: '200px' },
      nzFooter: null,
      nzMaskClosable: false,
      nzWrapClassName: 'ant-modal-custom',
      nzWidth: 420
    });
  }
}


