import { Template } from './template';
import { Element } from './element';
import { getUnitScale } from '../../utils/misc/common';
import { Container } from '@libs/editor/src/lib/models/element/container';
import { editorThumbProps, getBase64fromUrl } from '@libs/editor/src/lib/utils/misc/common';
import $ from 'jquery'

export class Page {
  // 父级对象(template)
  public parent: Template;
  // 页面ID
  public id: any = 0;
  // 配置信息
  public props: any = {
    // 页面背景色(留空为透明)
    bgColor: '#ffffff',
    background:'',
    // 背景图的元素ID
    bgId: null,
    // 辅助线
    guides: [],
    spine: null,
    //凹槽
    groove: null,
    //包边
    overlapping: null,
    banLeft: false,
    banRight: false,
    barCode: null,
    width: null,
    height: null
  };
  // 状态信息
  states: any = {
  };

  // 元素列表
  public elements: Element[] = [];
  // 事件管理器
  private eventManager = [];
  // 事件ID计数器
  private eventIDCounter = 0;
  constructor(params) {
    // if (this.states == null) {
    //   this.states.updateAt = new Date().valueOf();
    // }
    // // 元素更新时,更新时间戳
    // this.on('element:added element:modified element:remove', e => {
    //   this.states.updateAt = new Date().valueOf();
    // });
    // 实时操作更新
    // let thumbnailTimer
    // this.on('element:added element:modified element:removed', () => {
    //   thumbnailTimer && clearTimeout(thumbnailTimer)
    //   thumbnailTimer = setTimeout(() => {
    //     this.getSvg()
    //   }, 800)
    // })
    this.set(params);
  }
  get width() {
    let value = 0;
    if (this.props.width) {
      value = this.props.width;
    } else {
      value = this.parent.props.width;
    }
    return value;
  }
  get height() {
    let value = 0;
    if (this.props.height) {
      value = this.props.height;
    } else {
      value = this.parent.props.height;
    }
    return value;
  }
  /**
   * 设置页面属性
   * @param params 属性object
   */
  set(params) {
    if (params.id) {
      this.id = params.id;
    }
    if (params.props) {
      this.props = {
        ...this.props,
        ...params.props
      };
    }
    if (params.states) {
      this.states = {
        ...this.states,
        ...params.states
      };
    }
  }

  resize(nw, nh, ow = this.width, oh = this.height) {
    const over2 = this.props.overlapping * 2;
    const scaleX = (nw - over2) / (ow - over2);
    const scaleY = (nh - over2) / (oh - over2);
    const centerLine = ow / 2;
    const diff = nw - ow;
    for (let eleItem of this.elements) {
      let x = eleItem.props.x;
      let width = eleItem.props.width;
      //如果是组合取组合最大宽度和最小X
      if (eleItem.props.group.length > 0) {
        let groupElements = this.elements.filter(item => eleItem.props.group.indexOf(item.id) != -1);
        let GX = 9999999;
        let GW = 0;
        for (let ele of groupElements) {
          if (ele.props.x < GX) GX = ele.props.x;
          if (ele.props.width + GX > GW) GW = ele.props.width + GX;
        }
        x = GX;
        width = GW;
      }
      if (x < centerLine && x + width > centerLine) {
        if (eleItem.props.rotation > 0 && eleItem.props.rotation % 90 == 0) {
          eleItem.calcRotate();
        }
        eleItem.props.x *= scaleX;
        eleItem.props.y *= scaleY;
        eleItem.props.width *= scaleX;
        eleItem.props.height *= scaleY;

        if (eleItem.props.rotation > 0 && eleItem.props.rotation % 90 == 0) {
          eleItem.calcRotate(false);
        }

        if (eleItem instanceof Container && eleItem.options.clip) {
          eleItem.resetClip()
        }

        if (eleItem.type == 'calendar') {
          eleItem.options.scale = eleItem.props.width * (300 / 25.4) / eleItem.options.scaleWidth;
        }
      } else {
        if (x >= centerLine) {
          eleItem.props.x += diff;
        }
      }
      eleItem.states.html = eleItem.toHTML();
    }
    this.props.width = nw;
    this.props.height = nh;
  }

  /**
   * 添加元素页面
   * @param {Element} elementItem 元素对象
   * @param fireEvent 是否出发added事件
   */
  public addElement(elementItem: Element, fireEvent = true): Element {
    // 设置父级页面
    elementItem.parent = this;
    // 传入参数
    elementItem.set(elementItem.params);
    // 调用创建时调用的方法
    elementItem.onCreated();
    this.elements.push(elementItem);
    elementItem.emit('added', {
      target: elementItem,
      stopPropagation: true
    });
    if (fireEvent) {
      this.emit('element:added', {
        target: elementItem
      });
    }
    return elementItem;
  }
  /**
   * 插入新元素
   * @param pageItem 要插入的元素对象
   * @param toIndex 要插入的位置
   * @param fireEvent 是否触发事件
   */
  public insertElement(elementItem: Element, toIndex, fireEvent = true): void {
    // 设置父级页面
    elementItem.parent = this;
    // 传入参数
    elementItem.set(elementItem.params);
    // 调用创建时调用的方法
    elementItem.onCreated();
    this.elements.splice(toIndex, 0, elementItem);
    elementItem.emit('added', {
      target: elementItem,
      stopPropagation: true
    });
    if (fireEvent) {
      this.emit('element:added', {
        target: elementItem
      });
    }
  }
  /**
   * 删除元素对象
   * @param elementItem 元素对象
   * @param fireEvent 是否触发remove事件
   */
  public removeElement(elementItem: Element, fireEvent = true): number {
    const idx = this.elements.findIndex(item => item === elementItem);
    if (idx === -1) {
      throw new Error('试图删除不存在的元素');
    } else {
      this.elements.splice(idx, 1);
      if (fireEvent) {
        this.emit('element:removed', {
          target: elementItem
        });
      }
    }

    return idx;
  }

  resetBackground() {
    if (this.props.bgId) {
      const bgEl = this.elements.find(el => el.id == this.props.bgId);
      const { width, height, bleed } = this.parent.props;
      const stageWidth = bleed * 2 + width;
      const stageHeight = bleed * 2 + height;
      const radio = stageWidth / stageHeight;
      const radioCur = bgEl.props.width / bgEl.props.height;
      if (radioCur > radio) {
        bgEl.props.width = stageHeight * radioCur;
        bgEl.props.height = stageHeight;
      } else {
        bgEl.props.width = stageWidth;
        bgEl.props.height = stageWidth / radioCur;
      }

      bgEl.props.x = (stageWidth - bgEl.props.width) / 2 - bleed;
      bgEl.props.y = (stageHeight - bgEl.props.height) / 2 - bleed;
      bgEl.states.html = bgEl.toHTML()
    }
  }

  /**
   * 替换当前页的名片信息
   * @param signData
   */
  async loadCardSetting(signData, fireEvent = true) {
    console.log('这里替换的1');

    const sign = {
      '姓名': signData.name,
      '名字': signData.name,
      '地址': signData.address,
      '二维码': signData.codeConfig,
      '公司': signData.company,
      '邮箱': signData.email,
      '岗位': signData.job,
      '职位': signData.job,
      'LOGO': signData.logoConfig,
      '手机': signData.mobile,
      '固话': signData.phone,
      '网址': signData.webUrl
    };

    for (let eleItem of this.elements) {
      if (eleItem.props.sign && sign[eleItem.props.sign]) {
        if (eleItem.type === 'svg') {
          eleItem = eleItem['toImage']();
        }
        if (eleItem.type === 'image' || eleItem.type === 'text' || eleItem.type === 'textv2') {
          await eleItem['changeForSignData'](sign[eleItem.props.sign])
        }
      }
    }

    if (fireEvent) {
      this.emit('modified', { commit: true });
    }
  }

  /**
   * 注册事件侦听器
   * @param event 事件名
   * @param callback 回调函数
   */
  public on(event: string, callback: Function): any {
    const events = event.split(' ');
    if (events.length === 1) {
      this.eventIDCounter++;
      const id = this.eventIDCounter;
      this.eventManager.push({
        id,
        event,
        callback
      });
      return id;
    } else if (events.length > 1) {
      const idArr = [];
      for (const item of events) {
        this.eventIDCounter++;
        const id = this.eventIDCounter;
        this.eventManager.push({
          id,
          event: item,
          callback
        });
        idArr.push(id);
      }
      return idArr;
    }
  }
  /**
   * 销毁事件侦听器
   * @param eventID 注册事件侦听器返回的ID
   */
  public off(eventID): void {
    if (eventID.length) {
      eventID.forEach(event => {
        const eventIndex = this.eventManager.findIndex(
          item => item.id === event
        );
        if (eventIndex !== -1) {
          this.eventManager.splice(eventIndex, 1);
        } else {
          throw new Error('无法销毁未注册的事件');
        }
      });
    } else {
      const eventIndex = this.eventManager.findIndex(
        item => item.id === eventID
      );
      if (eventIndex !== -1) {
        this.eventManager.splice(eventIndex, 1);
      } else {
        throw new Error('无法销毁未注册的事件');
      }
    }
  }
  /**
   * 触发事件
   * @param event 事件名
   * @param data 触发事件
   */
  public emit(event: string, data: any = {}): void {
    this.eventManager.forEach(item => {
      if (item.event === event) {
        item.callback({
          event,
          ...data
        });
      }
    });
    if (event.indexOf('element:') !== -1) {
      // 如果子级element没有组织冒泡,则会调用页面对象的emit方法,并执行到这里,继续向上冒泡
      this.parent.emit(event, data);
    } else {
      if (!data.stopPropagation) {
        this.parent.emit('page:' + event, data);
      }
    }
  }
  /**
   * 生成当前页面的HTML方法
   * 一般在服务端生成或者客户端请求生成缩略图调用
   */
  public toHTML(): any {
    const template = this.parent;
    let html = `<div style="
            width:${template.props.width}px;
            height:${template.props.height};
            transform:scale(${template.scale});
            transform-origin:left top;">`;
    this.elements.forEach(eleItem => {
      const { props } = eleItem;
      html += `<div style="
                position: absolute;
                left:${props.x}px;
                top:${props.y}px;
                width:${props.width}px;
                height:${props.height}px;
                transform:rotate(${props.rotation}deg) scale(${
        props.flipX ? '-1' : '1'
        },${props.flipY ? '-1' : '1'});
                opacity:${props.opacity}">
                    ${eleItem.toHTML()}
                </div>
            `;
    });
    html += '</div>';
    return html;
  }
  /**
   * 删除当前页面
   * @param fireEvent 是否触发removed事件
   */
  public remove(fireEvent = true): void {
    this.parent.removePage(this, fireEvent);
  }
  /**
   * 获取当前页的所有Object
   * @param deepClone 深度复制
   */
  public toObject(deepClone = false, snap = false): any {
    const obj = {
      id: this.id,
      props: this.props,
      elements: [],
      states: snap ? this.states : undefined
    };
    for (const eleItem of this.elements) {
      obj.elements.push(eleItem.toObject());
    }
    if (deepClone) {
      return JSON.parse(JSON.stringify(obj));
    } else {
      return obj;
    }
  }

  public async getSvg() {
    if (this.states.loading) return;
    this.states.loading = true;
    const svgs = await Promise.all(this.elements.map(async item => {
      const $svg = $(await item.toSvg())
      $svg.attr('transform-origin', (item.props.x + item.props.width / 2) + " " + (item.props.y + item.props.height / 2))
      $svg.css({
        'opacity': item.props.opacity,
        'transform': `rotate(${item.props.rotation}deg) scale(${item.props.flipX ? '-1' : '1'},${item.props.flipY ? '-1' : '1'})`
      })

      if (item.props.filter.shadow && item.props.filter.shadow.use) {
        const shadow = item.props.filter.shadow;
        // 将弧度转换成角度
        const angle = (Math.PI / 180) * shadow.rotation;
        const distance = shadow.distance * 0.084666;
        const strength = shadow.strength * 0.084666;
        // 转换坐标与角度为坐标
        const x = distance * Math.cos(angle),
          y = distance * Math.sin(angle);
        // 将color和opacity转换为rgba
        const color = shadow.color.replace('rgb', 'rgba').replace(')', shadow.opacity + ')');
        $svg.attr('filter', `drop-shadow(${color} ${x} ${y} ${strength})`)
      }

      return $svg[0].outerHTML.replace(/foreignobject/g, 'foreignObject').replace(/&nbsp;/g, '&#160;')
    }));
    const bleed = this.parent.props.bleed;
    const ele_content = svgs.join("");

    let fonts = ele_content.match(/font_\d+/g);
    let fontStyles = '', bgColor = '';
    if (fonts && fonts.length) {
      fonts = Array.from(new Set(fonts))
      for (let fontItem of fonts) {
        const source = editorThumbProps.fontList.find(v => 'font_' + v.fontId === fontItem);
        if (source) {
          if (!source.base64) {
            source.base64 = await getBase64fromUrl(source.path)
          }
          fontStyles += `@font-face {font-family: ${fontItem};src: url(${source.base64});}`;
        }
      }
      fontStyles = `<defs><style>${fontStyles}</style></defs>`
    }

    if (this.props.bgColor) {
      bgColor = `<rect x="${-bleed}" y="${-bleed}" width="${this.width + bleed * 2}" height="${this.height + bleed * 2}" fill="${this.props.bgColor}"></rect>`
    }

    let svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" viewBox="${-bleed} ${-bleed} ${this.width + bleed * 2} ${this.height + bleed * 2}">${fontStyles}${bgColor}${ele_content}</svg>`
    // 去空格
    svg = svg.replace(/>[\s\n]+</g, '><').replace(/\s{2,}/g, ' ').replace(/<br>/g, '<br/>').replace(/href=("|')\/\//g, 'href=$1http://')
    const blob = new Blob([svg], {type: 'image/svg+xml'})
    this.states.thumbnail = URL.createObjectURL(blob);
    // 转译
    // svg = btoa(unescape(encodeURIComponent(svg)))
    // console.log('data:image/svg+xml,'+svg)
  }
}
