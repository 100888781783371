/**
 * 定义全局的Rest Url 路径
 */
// 获取用户信息接口
export const apiUserInfo = '/user/get-user-info';
// 修改用户信息接口
export const apiUpdateUserInfo = '/user/save-user-info';

//代理商相关
export const apiAgent = '/manage/agent-list';
export const apiAddAgent = '/manage/agent-register';
export const apiUpdateAgent = '/manage/agent-update';
export const apiCooperation = '/manage/cooperation';
export const apiAgentApply = '/manage/agent-apply';
export const apiCoopApply = '/coop-apply';
export const apiOrderList = '/coop-apply?product=2';
export const apiOpenCoop = '/manage/cooperation-open';

// 用户相关接口
export const apiUser = '/user';

// 品类管理URl
export const apiCategory = '/category';
export const apiClassify = '/classify';
// 模板中心页接口
export const apiTemplateCenter = '/cooperation/center';
// 获取模板列表页分类的帅选条件
export const apiTemplateClassify = '/classify/classify-tag';
// 官方模板和接口
export const apiTemplateList = '/cooperation/template';
export const apiTemplateMenu = '/cooperation/product';
export const apiTemplateSize = '/cooperation/product-child';
// 官方模板和接口
export const apiTemplateOfficial = '/template-official';
// 官方模板编组接口
export const apiTemplateOfficialGroup = '/set-template-official';
// 审核模板和接口
//export const apiTemplateDesigner = '/template-designer';
// 官方模板和接口
export const apiTemplateRecommend = '/template-official/recommend';
export const apiTemplateSetRecommend = '/template-official/set-recommend';

// 用户模板接口地址
export const apiMemberTemplate = '/template-member';
export const apiMemberTemp = '/member/template';
export const apiTemplateDesigner = '/template-designer';

export const apiMemberTemplateCount = '/template-member/count';

//用户模板需求
export const apiTemplateNeed = '/demand';

// 用户模板接口地址
export const apiMemberShareTemplate = '/share-template';
// 用户下载模板接口
export const apiMemberTemplateDownload = '/template-member/download';

// 用户模板接口地址
export const apiMemberMaterial = '/member/material';
// 用户模板操作
export const apiMemberTemplateOperation = '/template-member/template-operation';
// 用户素材操作
export const apiMemberMaterialOperation = '/material/material-operation';
// 用户收藏接口
export const apiMemberFavorite = '/member/favorite';
// 用户回收站接口
export const apiMemberTrash = '/member/trash';
// 文件夹接口
export const apiFolderTemplate = '/member/folder';
export const apiEditFolder = '/folder-template';

// 文件夹接口
export const apiFolderMaterial = '/folder-material';
export const apiMemberVipList = '/buy-vip';

// 模板专题接口
export const apiTopic = '/gain-template-cover';
export const apiTopicInfo = '/gain-template-cover/product-topic';
export const apiTopicInfoClassify = '/template-topic/topic-list';
export const apiTopicInfoList = '/template-topic';
export const apiTopicDelete = '/template-topic/delete-topic-temp';

/******************* 团队接口地址 *******************/
export const apiTeam = '/team';
export const apiTeamColor = '/team/team-color';
export const apiTeamMember = '/team-member';
export const apiTeamFont = '/team/team-font';
export const apiTeamMemberList = '/team-member/get-team';
export const apiTeamMemberQuite = '/team-member/quit-team';
/******************* 团队接口地址 *******************/

/******************* 帮助中心 *******************/
export const apiHelp = '/help-classify';
export const apiHelpList = '/help-article';
export const apiHelpAccess = '/help-article/member-behavior';
/******************* 帮助中心 *******************/

/******************* 素材接口地址 *******************/
export const apiMaterialClassify = '/material-classify';
export const apiMaterialOfficial = '/material-official';
export const apiCopywritingMaterialOfficial = '/material-official/create-text';
export const backgroundOfficial = '/background';
/******************* 素材接口地址 *******************/

/*********************支付相关接口********************************/
// 用户充值
export const apiMemberCharge = '/cooperation/order';
export const apiMemberInvite = '/member-invite';
export const apiAgentOrder = '/manage/agent-order';
export const apiWarchOrder = '/pay/watch-order';
export const apiBuyVip = '/coop-vip';
export const apiPromoCode = '/promo-code';
export const apiPromonVip = '/promo-code/give-vip';

export const apiPayMenu = '/cooperation/price';
/*********************支付相关接口********************************/

/*********************优惠码相关接口********************************/
export const apiCreateDiscountCode = '/promo-code';
/*********************优惠码相关接口********************************/

/*********************签到相关接口********************************/
export const apiSignActivity = '/sign-activity';
export const apiSignMember = '/sign-in';
export const apiSign = '/sign-in/sign-in';
export const apiSignPay = '/sign-in/enroll';

/*********************签到相关接口********************************/

export const apiMessage = '/message';
export const apiMessageReadAll = '/read-news';
export const apiMessageDeleteAll = '/read-news/delete-read';

export const apiTags = '/tag';

export const apiFont = '/font';

/*********************网站相关接口********************************/
export const apiAdv = '/adv';
export const apiThird = '/open/oauth';
/*********************网站相关接口********************************/

// 后台用户自动登录连接
export const apiUserLoginLink = '/system/user-link';
export const apiUserLoginKey = '/system/user-key';
export const apiDoUserLogin = '/system/do-user-login';

// 角色列表
export const apiAuthItemList = '/auth/item-list';

/**
 * 将大写字母替换成 下划线加小写
 * @param data
 * @returns {{}}
 */
export function parseUcFirst(data: any) {
  const newData = {};
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      // 存在大写的话就替换
      const newKey = key.replace(
        /([a-z]+)([A-Z])([a-z]+)/,
        (data, ...matches) => {
          return matches[0] + '_' + matches[1].toLowerCase() + matches[2];
        }
      );
      newData[newKey] = data[key];
    }
  }
  return newData;
}
