import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Message } from '@libs/core/src/lib/models/message';
import { apiMessage, apiMessageDeleteAll, apiMessageReadAll } from '@libs/core/src/lib/util/api';
import { DataProvider } from '@libs/core/src/lib/util/data-provider';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {

  dataProvider = new DataProvider<Message[]>();
  listSubscription: Subscription;

  message: Message[];
  count = 0;

  message$ = new Subject<any>();

  constructor(
    private http: HttpClient
  ) {
    this.listSubscription = this.message$.pipe(
      switchMap(data => this.list()),
      map(data => this.dataProvider.prepare(data))
    ).subscribe(
      data => {
        this.message = data;
        this.count = 0;
        this.message.forEach((value, index) => {
          if (value.isRead === 0)  {
            this.count++;
          }
        });
      }
    );
  }

  load() {
    this.message$.next();
  }

  /**
   * 获取消息列表
   * @returns {Observable<Message[]>}
   */
  list(params?): Observable<HttpResponse<Message[]>> {
    return this.http.get<Message[]>(apiMessage, { params: params, observe: 'response' });
  }

  /**
   * 读取一个消息
   * @param id
   */
  readOne(id) {
    const index = this.message.findIndex(item => item.id === id);
    this.message[index] = {
      ...this.message[index],
      isRead: 1
    };
    this.count -= 1;
    this.http.post(apiMessageReadAll, {message_id: id}).subscribe(data => {});
  }

  /**
   * 新增消息
   * @param params
   * @returns {Observable<Message>}
   */
  create(params): Observable<Message> {
    return this.http.post<Message>(apiMessage, params);
  }

  update(params): Observable<Message> {
    return this.http.put<Message>(apiMessage + '/' + params.id, params);
  }

  readAll(): Observable<any> {
    return this.http.post(apiMessageReadAll, {});
  }

  deleteAll(): Observable<any> {
    return this.http.post(apiMessageDeleteAll, {});
  }

}
