import { Table } from '../../../../models/element/table';
import { Element } from '../../../../models/base/element';
import { EditorService } from '../../../../services/editor.service';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { getUnitScale } from '../../../../utils/misc/common';
import * as $ from 'jquery';

@Component({
  selector: 'app-table-text-editor',
  templateUrl: './table-text-editor.component.html',
  styleUrls: ['./table-text-editor.component.scss']
})
export class TableTextEditorComponent implements OnInit, OnDestroy {
  cellData = [];
  public get activeElement(): Table {
    return this.editor.activeElements[0];
  }
  public get scale(): any {
    return this.editor.zoom * this.editor.template.scale;
  }
  constructor(public editor: EditorService) { }

  ngOnInit(): void {
    const id = this.activeElement.id;
    const row = this.activeElement.options.row;
    const col = this.activeElement.options.col;
    const cell = this.activeElement.options.cell;
    const eleBBox = this.editor.stage.getBBox(this.activeElement);
    for (const rowIndex in cell) {
      for (const colIndex in cell[rowIndex]) {
        const row = parseInt(rowIndex, 10);
        const col = parseInt(colIndex, 10);
        // 获取boundingClientRect
        const selector = `rect[data-col="${col}"][data-row="${row}"][data-id="${id}"]`;
        const $rect = $(selector);
        const bbox = $rect[0].getBoundingClientRect();
        this.cellData.push({
          cell: cell[rowIndex][colIndex],
          row,
          col,
          left: bbox.left - eleBBox.left,
          top: bbox.top - eleBBox.top,
          width: bbox.width,
          height: bbox.height
        });
      }
    }
    console.log('this.cellData', this.cellData);
  }
  ngOnDestroy(): void {
    const eleItem: Element = this.editor.activeElements[0];
    eleItem.states.activeCell = null;
  }
  isActiveCell(item) {
    const eleItem: Element = this.editor.activeElements[0];
    if (!eleItem.states.activeCell) {
      return false;
    }
    return item.cell === eleItem.states.activeCell;
  }

  /**
   * 事件:输入框被按下
   */
  onCellInputItemMouseDown(item) {
    const eleItem: Element = this.editor.activeElements[0];
    eleItem.states.activeCell = item.cell;
    eleItem.emit('activeCellChange', item.cell)
  }
  /**
   * 获取单元格样式
   * @param item 单元格
   */
  getCellInputStyle(item) {
    const scale = this.editor.template.scale;
    const props = this.activeElement.props;
    const options = this.activeElement.options;
    return {
      left: item.left + 'px',
      top: item.top + 'px',
      width: item.width + 'px',
      height: item.height + 'px',
      color: item.cell.color.nColor,
      fontFamily: item.cell.family,
      fontSize: (item.cell.size * this.editor.zoom) + 'px',
      textAlign: item.cell.align,
      lineHeight: item.cell.lineHeight,
      letterSpacing: item.cell.letterSpacing + 'px',
      fontStyle: item.cell.italic ? 'italic' : 'normal'
    };
  }
  /**
   * 获取最外面的表格整体的样式
   */
  getTextEditorStyle(): any {
    const elementItem: Element = this.editor.activeElements[0];
    const props = elementItem.props;
    const scale =
      this.editor.template.scale * this.editor.zoom;
    const style = {
      left: this.editor.stagePos.x + props.x * scale + 'px',
      top: this.editor.stagePos.y + props.y * scale + 'px',
      width: props.width * scale + 'px',
      height: props.height * scale + 'px',
      transform: `rotate(${props.rotation}deg)`,
      transformOrigin: '0 0 0'
    };
    return style;
  }

}
