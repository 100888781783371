import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ClipboardService } from 'ngx-clipboard';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { DOCUMENT } from '@angular/common';
import { MemberService } from '@libs/core/src/lib/services/member/member.service';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';

@Component({
  selector: 'app-nologin-submit',
  templateUrl: './nologin-submit.component.html',
  styleUrls: ['./nologin-submit.component.scss']
})
export class NologinSubmitComponent implements OnInit {
  @Input() orders;
  @Input() id;
  url: string;
  important_notice: string;
  constructor(
    public coop: CoopService,
    private modal: NzModalRef,
    private clipboard: ClipboardService,
    public auth: AuthService,
    private message: MessageService,
    @Inject(DOCUMENT)
    private document: Document,
  ) { }

  ngOnInit() {
    this.url = window.location.href;
    if (this.coop.coopInfo['important_notice'] !== '') {
      this.important_notice = this.coop.coopInfo['important_notice'];
    } else {
      this.important_notice = '请把文件ID复制发送给客服，客服会跟您核对稿件进行印刷哦';
    }
  }

  jump(url) {
    const href = url + (url.indexOf('?') >= 0 ? '&' : '?') + 'template_ids=' + this.id;
    window.open(href);
  }

  copy(content) {
    this.clipboard.copyFromContent(content);
    this.message.success('复制成功!');
  }

  collect() {
    if (window.external && window.external['addFavorite']) {
      window.external['addFavorite'](window.location.href, this.document.title);
      this.message.success('收藏成功');
    } else if (window['sidebar'] && window['sidebar']['addPanel']) {
      window['sidebar']['addPanel'](this.document.title, window.location.href,'');
      this.message.success('收藏成功');
    } else {
      this.message.error("加入收藏夹失败，请使用Ctrl+D快捷键进行添加操作!");
    }
  }
}
