import { Component, OnInit } from '@angular/core';
import { EditorService } from '../../../../../../../services/editor.service';

@Component({
  selector: 'app-table-distance-tool',
  templateUrl: './table-distance-tool.component.html',
  styleUrls: ['./table-distance-tool.component.scss']
})
export class TableDistanceToolComponent implements OnInit {
  letterSpacing = 0;
  lineHeight = 100;
  constructor(public editor: EditorService) { }
  get activeCell() {
    const eleItem = this.editor.activeElements[0];
    return eleItem.states.activeCell;
  }
  ngOnInit() {
    const eleItem = this.editor.activeElements[0];
    this.letterSpacing = this.activeCell.letterSpacing;
    this.lineHeight = this.activeCell.lineHeight * 100;
  }
  /**
   * 字间距被修改
   * @param value 新字间距
   */
  onLetterSpacingChange(value, fireEvent = false) {
    this.letterSpacing = value;
    this.activeCell.letterSpacing = value;
  }
  /**
   * 行距被修改
   * @param value 新行距
   */
  onLineHeightChange(value, fireEvent = false) {
    this.lineHeight = value;
    this.activeCell.lineHeight = value / 100;

  }
}
