import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User } from '../models/user';
import { AuthActions, AuthActionTypes } from './auth.actions';

export interface AuthState {
  isLogin: boolean;
  isGuest: boolean;
  user: User;
}

export const initialState: AuthState = {
  isLogin: false,
  isGuest: true,
  user: undefined
};

export function AuthReducer(
  state = initialState,
  action: AuthActions
): AuthState {
  switch (action.type) {
    case AuthActionTypes.Login:
      return {
        ...state,
        isLogin: true
      };
    case AuthActionTypes.LoginClose:
      return {
        ...state,
        isLogin: false
      };
    // 登录成功
    case AuthActionTypes.LoginSuccessLocal:
    case AuthActionTypes.LoginSuccess:
      return {
        ...state,
        isLogin: false,
        isGuest: false,
        user: action.payload.user
      };

    // 退出登录
    case AuthActionTypes.Logout:
      return initialState;

    default: {
      return state;
    }
  }
}

export const selectAuthState = createFeatureSelector<AuthState>('auth');
export const selectAuthUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.user
);
export const selectAuthIsGuest = createSelector(
  selectAuthState,
  (state: AuthState) => state.isGuest
);
export const selectAuthIsLogin = createSelector(
  selectAuthState,
  (state: AuthState) => state.isLogin
);
