import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Calendar } from '@libs/editor/src/lib/models/element/calendar';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';
import { ToastIcon } from '@libs/modal/src/lib/models/toast-icon';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';
import { Svg } from '@libs/editor/src/lib/models/element/svg';
import { TemplateLoader } from '@libs/editor/src/lib/utils/misc/template-loader';
import { Element } from '@libs/editor/src/lib/models/base/element';
@Component({
  selector: 'app-batch-add-eleitem',
  templateUrl: './batch-add-eleitem.component.html',
  styleUrls: ['./batch-add-eleitem.component.scss']
})
export class BatchAddEleItem implements OnInit {
  public firstType = false;
  public selectedType = 'current';
  @Input() type = 'add';
  @Output() closeBatchAdd = new EventEmitter();
  constructor(
    public editor:EditorService,
    public modal:ModalService
  ) { }

  ngOnInit() {
  }
  confirm(){
    this.addCalendarGroup()
  }
  getAddPage(){
    let index = this.editor.activePageIndex;
    let addPage = [];
    // 全部页面从0开始
    let start = this.selectedType === 'all' ? 0 : index;

    for (let i = start; i < this.editor.template.pages.length;i++) {
      if (i === index) continue; // 排除当前页
      if (this.firstType && ((i - index) % 2)) continue; // 排除隔页

      addPage.push(this.editor.template.pages[i]);
    }
    return addPage;
  }
  addCalendarGroup(){
    let addPage = this.getAddPage();
    // 获取需要复制的object
    const copyArr = [];
    let eleItems = this.editor.activeElements;
    // 按照图层顺序重新排序
    eleItems.forEach(element => {
      element.index = this.editor.activePage.elements.findIndex(item => item === element);
    });
    eleItems = eleItems.sort((a, b) => a.index - b.index);

    for (const eleItem of eleItems) {
      copyArr.push(eleItem.toObject());
    }

    const copyDatas = JSON.stringify({
      elements: copyArr
    });

    for (let i = 0; i < addPage.length;i++){
      let copyData = JSON.parse(copyDatas);
      const strogeData = copyData.elements;
      this.editor.setActiveElement(null);
      const isGroupElements =
        strogeData[0].props.group.length === strogeData.length &&
        strogeData.filter(item =>
          strogeData[0].props.group.find(id => item.id === id)
        ).length === strogeData.length;
      const createdElements: Element[] = [];
      // id对应表
      const idList = {};
      for (const objItem of strogeData) {
        if (objItem.type === 'calendar'){
          let oldCalendar = addPage[i].elements.filter(item=>item.type == 'calendar');
          if (oldCalendar.length > 0){
            oldCalendar.forEach(item=>{
              addPage[i].removeElement(item,false)
            })
          }
        }
        if (objItem.type === 'svg' && objItem.options.hasOwnProperty('isCalendarTitle') && objItem.options.isCalendarTitle){
          let oldCalendarTitle = addPage[i].elements.filter(item=> item.type == 'svg'&&item.options.isCalendarTitle);
          if (oldCalendarTitle.length > 0){
            oldCalendarTitle.forEach(item=>{
              addPage[i].removeElement(item,false)
            })
          }
        }
        const newID = this.editor.getNewID();
        idList[objItem.id] = newID;
        objItem.id = newID;
        if (!isGroupElements) {
          objItem.props.group = [];
        }
        const eleItem = addPage[i].addElement(
          TemplateLoader.loadElement(objItem),
          false
        );
        createdElements.push(eleItem);
      }
      // 如果是编组元素,则更新他们group属性的id
      if (isGroupElements) {
        createdElements.forEach(eleItem => {
          eleItem.props.group = eleItem.props.group.map(item => idList[item]);
        });
      }
      addPage[i].emit('element:added', {
        targets: createdElements,
        commit: false
      });
    }
    this.editor.snapshot();
    this.close();
  }
  close(){
    this.closeBatchAdd.emit();
  }
  confirmDel(){
    if (this.editor.activeElements.length == 0) {
      this.close();
      return;
    }
    for (let page of this.editor.template.pages){
      if (page.id == this.editor.activePage.id) continue;
      for (let item of page.elements){
        for (let chooseItem of this.editor.activeElements){
          //判断是否为同一个元素
          if (chooseItem.type == item.type &&
            chooseItem.props.x == item.props.x &&
            chooseItem.props.y == item.props.y &&
            chooseItem.props.width == item.props.width &&
            chooseItem.props.height == item.props.height
          ) {
            if (item.props.group.length > 0){
              for (let id of item.props.group){
                if (id == item.id) continue;
                let groupItem = this.editor.findElement(id,page.id);
                if (groupItem){
                  page.removeElement(groupItem,false);
                }
              }
            }
            page.removeElement(item,false);
          }
        }
      }
    }
    for (let chooseItem of this.editor.activeElements){
      this.editor.activePage.removeElement(chooseItem,false);
    }
    this.editor.setActiveElement(null);
    this.editor.snapshot();
    this.close();
  }
}
