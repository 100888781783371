import { ToolBarTextv2Component } from './../../components/editor-ui/t-tool-bar/tools/tool-bar-textv2/tool-bar-textv2.component';
import { Element } from '../base/element';
import * as Color from 'color';
import * as $ from 'jquery';
export class TextV2 extends Element {
  public type = 'textv2';
  async onCreated() {
    this.config.toolbar = ToolBarTextv2Component;
    this.config.corner = {
      tl: true,
      tr: true,
      ml: false,
      mr: false,
      bl: true,
      br: true,
      mt: false,
      mb: false,
      rotate: true
    };
    this.on('dblclick', () => {
      setTimeout(() => {
        document.getElementById('art-text-edit-btn').click()
      }, 300);
    });

    this.on('modified', e => {
      if (e.type === 'resize') {
        const scaleM = this.props.width / this.props.oWidth;
        this.options.fontSize *= scaleM;
        this.options.letterSpacing *= scaleM;
        this.options.strokeWidth *= scaleM;
        this.props.oWidth = this.props.width;
      }
      if (e.type === 'text' && e.oldWidth) {
        // 获取更新后的宽
        const { rWidth } = this.getBBox();
        // 更新x坐标
        switch (this.options.textAlign) {
          case 'right':
            this.props.x -= rWidth - e.oldWidth;
            break;
          case 'center':
            this.props.x -= (rWidth - e.oldWidth)/2;
        }
      }
    });
    // this.config.update = true;
    await window['editor'].waitLoadFont(this.options.fontFamily);
    this.states.html = this.toHTML();
  }
  public changeForSignData(text) {
    if (typeof text === 'string') {
      const oldWidth = this.props.width;
      this.options.text = text;
      this.emit('modified', {
        type: 'text',
        target: this,
        oldWidth,
        stopPropagation: true
      });
    }
  }

  changeSignCombination(text) {
    if (typeof text === 'string') {
      const oldWidth = this.props.width;
      this.options.text = text;
      this.emit('modified', {
        type: 'text',
        target: this,
        oldWidth,
        stopPropagation: true
      });
    }
  }

  public getBBox() {
    const options = this.options;

    let fontSize = 100;
    let scale = fontSize / options.fontSize;
    let strokeWidth = options.strokeWidth * scale;
    let letterSpacing = options.letterSpacing * scale;

    let text = options.text;
    if (options.lineLength) {
      let newText = [];
      let lineLength = options.lineLength;
      let curLength = 0;
      for (let i = 0; i < text.length; i++) {
        const char = text[i];
        if (char == '\n') {
          curLength = 0;
          newText.push('\n');
        } else {
          if (curLength >= lineLength) {
            newText.push('\n');
            curLength = 0;
          }
          newText.push(char);
          curLength++;

        }
      }
      text = newText.join('');
    }
    const texts = text
      .split("\n")
      .map(text => (text === "" ? " " : text));

    let textSVG = [];
    for (let i = 0; i < texts.length; i++) {
      textSVG.push(
        `<tspan x="0" y="${fontSize *
        (i * options.lineHeight)}px">${texts[i]}</tspan>`
      );
    }

    let svg = `<svg id="temp-svg" style="position:fixed;left:0;top:0;" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                  <text letter-spacing="${letterSpacing}px"
                        font-family="${options.fontFamily}"
                        font-size="${fontSize}px">${textSVG.join("\n")}</text>
               </svg>`;

    $("body").append(svg);
    const bbox = $("#temp-svg text")[0].getBBox();
    const rWidth = (bbox.width + strokeWidth) / scale;
    const rHeight = (bbox.height + strokeWidth * 2) / scale;
    $("#temp-svg").remove();
    return {
      rWidth,
      rHeight,
      width: bbox.width,
      height: bbox.height,
      y: -bbox.y,
      texts
    }
  }
  public toHTML(): string {
    const id = this.id,
      props = this.props,
      options = this.options;
    let shadowFilter = '';
    let fontSize = 100;
    let scale = fontSize / options.fontSize;
    let strokeWidth = options.strokeWidth * scale;
    let letterSpacing = options.letterSpacing * scale;
    let stroke = options.stroke === 'transparent' ? 'none' : options.stroke;
    let textAlign = "start", x = 0, textSVG = [];

    let {rWidth, rHeight, width, height, y, texts} = this.getBBox();

    switch (options.textAlign) {
      case 'left':
        textAlign = "start";
        x = 0;
        break;
      case 'center':
        textAlign = "middle";
        x = width / 2;
        break;
      case 'right':
        textAlign = "end";
        x = width;
        break;
    }

    for (let i = 0; i < texts.length; i++) {
      textSVG.push(
        `<tspan x="${x}" dy="${i === 0 ? y : fontSize * options.lineHeight}px">${texts[i]}</tspan>`
      );
    }

    if (props.filter && props.filter.shadow && props.filter.shadow.use) {
      const shadow = props.filter.shadow
      const angle = (Math.PI / 180) * shadow.rotation;
      const x = shadow.distance / scale * Math.cos(angle),
        y = shadow.distance / scale * Math.sin(angle);
      // 将color和opacity转换为rgba
      shadowFilter =
        `<defs>
          <filter id="shadow-filter-${this.id}">
            <feDropShadow dx="${x}" dy="${y}" stdDeviation="${shadow.strength / scale}" flood-color="${shadow.color}" flood-opacity="${shadow.opacity}"/>
          </filter>
        </defs>`
    }

    let svg = `<svg style="position: absolute;" width="100%" height="100%"
                viewBox="${-strokeWidth/2} ${-strokeWidth/2} ${width + strokeWidth} ${height + strokeWidth}"
                version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" text-rendering="geometricPrecision">
              ${shadowFilter}
              <text fill="${options.fill}"
                    ${shadowFilter ? `style="filter:url(#shadow-filter-${this.id});"` : ''}
                    font-family="${options.fontFamily},'Segoe UI', Roboto, Helvetica, Arial, 'Microsoft YaHei', sans-serif, 'Segoe UI Symbol',Tahoma,'Malgun Gothic','Times New Roman',SimSun,'Microsoft Himalaya','Segoe UI Emoji'"
                    font-size="${fontSize}px"
                    stroke-width="${strokeWidth}px"
                    stroke="${stroke}" stroke-linejoin="round"
                    text-anchor="${textAlign}"
                    letter-spacing="${letterSpacing}px">${textSVG.join("")}</text>
              <text fill="${options.fill}"
                    font-family="${options.fontFamily},'Segoe UI', Roboto, Helvetica, Arial, 'Microsoft YaHei', sans-serif, 'Segoe UI Symbol',Tahoma,'Malgun Gothic','Times New Roman',SimSun,'Microsoft Himalaya','Segoe UI Emoji'"
                    font-size="${fontSize}px"
                    text-anchor="${textAlign}"
                    letter-spacing="${letterSpacing}px">${textSVG.join("")}</text>
           </svg>`;

    // 更新高度

    const ratio = rHeight / rWidth;
    this.props.width = this.props.oWidth = rWidth;
    this.props.height = this.props.oHeight = rHeight;
    // this.props.height = this.props.width * ratio;


    // 存储svg到元素,服务器直接渲染
    this.options.svg = svg;

    return svg;
  }
}
