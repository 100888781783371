import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseConvertPipe } from './case-convert.pipe';
import { FixedNumberPipe } from './fixed-number.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [CaseConvertPipe, SanitizeHtmlPipe, FixedNumberPipe],
  exports: [CaseConvertPipe, SanitizeHtmlPipe, FixedNumberPipe]
})
export class PipeModule {}
