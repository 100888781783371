import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { DesignParamService } from '@libs/core/src/lib/services/design-param.service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { ClassifyService } from '@libs/core/src/lib/services/template/classify.service';
import { RouterUrlService } from '@libs/core/src/lib/services/router-url.service';
import { ClassifyConfig } from '@libs/core/src/lib/models/classify';

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.scss']
})
export class CreateTemplateComponent implements OnInit {
  @Input() template: TemplateOfficial;
  @Output() delete = new EventEmitter<{status: string, template: TemplateOfficial, index: number}>();

  query: any;
  virtual: ClassifyConfig;
  sizeMsgStr: string;
  cid: string;
  child_page: string;
  constructor(
    public routerUrlService: RouterUrlService,
    public coopService: CoopService,
    public classify: ClassifyService,
    public designService: DesignParamService,
    public authService: AuthService,
    private routeInfo: ActivatedRoute
  ) { }

  ngOnInit() {
    const product = this.classify.products.find(item => item.classify.classifyId == this.template.classifyPid.toString());
    this.cid = this.routerUrlService.params.get('cid');
    this.child_page = this.routerUrlService.params.get('cp');
    if (this.cid) {
      this.virtual = product.classifyConfig.find(item => item.id.toString() == this.cid);
    } else {
      this.virtual = product.classifyConfig.find(item => this.activeState(item));
    }

    this.sizeMsgStr = this.sizeMsg;
  }

  activeState(temp: ClassifyConfig): boolean {
    if (this.template.classifyId != temp.classifyId) {
      return false;
    }
    const judge = this.classify.no_required_query.every(val => {
      if (!this.query || !this.query[val]) return true;
      return temp[val] == this.query[val]
    });

    return judge
  }

  createEmpty() {
    let params: any = {
      unit: 'mm',
      width: this.virtual ? this.virtual.width : this.template.editConfig.width,
      height: this.virtual ? this.virtual.height : this.template.editConfig.height,
      size: this.query || {},
      templateClassifyId: this.template.classifyId,
      editFrom: this.template.templateId,
    };
    if (this.cid) params.cid = this.cid;
    if (this.child_page) params.size.page = this.child_page;
    window.open(this.designService.getMemberDiy(params, this.template))
  }

  get sizeMsg() {
    let width, height;
    if (this.virtual && this.virtual.expand === 0) {
      width = this.virtual.width;
      height = this.virtual.height;
    } else {
      width = this.template.editConfig.width;
      height = this.template.editConfig.height
    }
    if ([1, 3].indexOf(this.template.likePid || this.template.classifyPid) >= 0) {
      width /= 2;
    }
    if (this.template.classifyId == 144) {
      width /= 2;
    }

    return `${width}*${height}mm`
  }
}
