import { Component, OnInit, Input } from '@angular/core';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { DesignParamService } from '@libs/core/src/lib/services/design-param.service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { ClassifyConfig } from '@libs/core/src/lib/models/classify';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  @Input() template: TemplateOfficial;
  @Input() edit: boolean;
  @Input() cid: number;
  @Input() virtual: ClassifyConfig;
  query: any = this.routeInfo.snapshot.queryParams;
  isQuery = Object.keys(this.query).length === 0;
  isTest = false;
  constructor(
    private routeInfo: ActivatedRoute,
    public designService: DesignParamService,
    public coop: CoopService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

  }
  // edit() {
  //   if (this.template.classify.pid === 2) {
  //     this.modal.cardMessage(true, this.template.templateId);
  //   } else {
  //     window.open(this.designService.getMemberCreateUrl(this.template.templateId, this.template));
  //   }
  // }

}
