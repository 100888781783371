import { Component, Input, OnInit } from '@angular/core';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-diy-confirm',
  templateUrl: './diy-confirm.component.html',
  styleUrls: ['./diy-confirm.component.scss']
})
export class DiyConfirmComponent implements OnInit {
  @Input() icon: string = 'icon-jinggao';
  @Input() title: string;
  @Input() content: string;
  @Input() showCheck = false;
  @Input() buttons: {text: string, style?: any, fun: () => {}}[];

  check = false;

  constructor(
    public coop: CoopService,
    private modalRef: NzModalRef
  ) { }

  ngOnInit() {
  }

  btnClick(fun) {
    this.modalRef.close();
    fun(this.check)
  }

}
