import { Modal } from '@libs/editor/src/lib/models/ui/modal';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
  Output, EventEmitter
} from '@angular/core';
import * as Color from 'color';
import { HSB2RGB, RGB2HSB } from '@libs/editor/src/lib/utils/misc/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpClient } from '@angular/common/http';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import ColorPipette from './color-selected';
/**
 * 自定义颜色选择器组件
 */
declare const EyeDropper: any;
declare interface Window {
  EyeDropper: any
}
@Component({
  selector: 'app-color-selector',
  templateUrl: './color-selector.component.html',
  styleUrls: ['./color-selector.component.scss']
})
export class ColorSelectorComponent implements OnInit,AfterViewInit {
  @Input() disableDropColor: boolean;
  @Input() parentModal: Modal;
  @Input() nowColor = '';
  @Input() selectFn: (color: string) => void;
  @Output() colorChange = new EventEmitter();
  @Output() colorInput = new EventEmitter();
  @Output() openColorDropper = new EventEmitter();
  @Output() colorSelectorType = new EventEmitter();
  @ViewChild('bright', {static: true}) brightEl: ElementRef;
  @ViewChild('hue', {static: true}) hueEl: ElementRef;
  NoCMYK = true;
  colorType = 'RGB';
  public hsb = [0, 0, 0];
  public rgbColor = [0, 0, 0];
  public downType = '';
  public cmyk = [0, 0, 0, 0];
  backColorC;
  backColorM;
  backColorY;
  backColorK;
  colorTypeShow = false;
  cmykType = "";
  constructor(
    private modal: NzMessageService,
    public http: HttpClient,
    public coop: CoopService
  ) { }



  ngOnInit(): void {
    // 分析颜色的HSL,并定位颜色选择器位置
    this.hsb = RGB2HSB(new Color(this.nowColor.toLowerCase()).rgb().color);
    // 绘制HUE
    this.drawHue();
    // 绘制颜色选择图
    this.drawBright();
    // 从nowColor获取cmyk
    const cmykMatch = /(rgba\()([0-9]+),([0-9]+),([0-9]+),(255)([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{3})\)/gi.exec(this.nowColor.toLowerCase());
    if (cmykMatch) {
      this.cmyk[0] = parseFloat(cmykMatch[6]);
      this.cmyk[1] = parseFloat(cmykMatch[7]);
      this.cmyk[2] = parseFloat(cmykMatch[8]);
      this.cmyk[3] = parseFloat(cmykMatch[9]);
      this.colorType = 'CMYK';
      this.cmykInput('all')
    }else{
      this.colorType = 'RGB';
    }
    this.colorSelectorType.emit(this.colorType);
  }
  ngAfterViewInit(){

  }
  PrefixInteger(num, length) {
    return (Array(length).join('0') + num).slice(-length);
  }
  onRGBChange() {
    try {
      const ColorStr = `rgb(${Math.floor(this.rgbColor[0])},${Math.floor(this.rgbColor[1])},${Math.floor(this.rgbColor[2])})`
      this.nowColor = ColorStr;
      this.hsb = RGB2HSB(Color(ColorStr).color);
      this.drawBright();
      this.colorChange.emit(ColorStr);
      // this.parentModal && this.parentModal.emit('colorChange', {
      //   color: ColorStr,
      //   type: 'selector'
      // });
    }catch (e) {
      this.modal.info('请输入正确的颜色值');
    }
  }
  /**
   * 事件:bright区域按下鼠标
   */
  onBrightMouseDown(): void {
    this.downType = 'bright';
  }
  /**
   * 事件:色相选择器鼠标按下
   */
  onHueMouseDown(): void {
    this.downType = 'hue';
  }
  /**
   * 事件:鼠标在窗口移动
   * @param event 事件对象
   */
  onWindowMouseMove(event): void {
    let x, y;
    const offset = 0.1;
    if (this.downType === 'bright') {
      const bbox = this.brightEl.nativeElement.getBoundingClientRect();
      (x = event.clientX - bbox.left), (y = event.clientY - bbox.top);

      if (x > bbox.width - offset) {
        x = bbox.width - offset;
      }
      if (y > bbox.height - offset) {
        y = bbox.height - offset;
      }
      if (x < offset) {
        x = offset;
      }
      if (y < offset) {
        y = offset;
      }
      const el = this.brightEl.nativeElement,
        s = (x / el.width) * 100,
        b = 100 - (y / el.height) * 100;
      this.hsb[1] = s;
      this.hsb[2] = b;
      this.nowColor = Color.rgb(HSB2RGB(this.hsb)).hex();
      this.colorInput.emit(this.nowColor);
      // this.parentModal && this.parentModal.emit('colorInput', {
      //   color: this.nowColor,
      //   type: 'selector'
      // });
    } else if (this.downType === 'hue') {
      const el = this.hueEl.nativeElement,
        bbox = el.getBoundingClientRect();
      y = event.clientY - bbox.top;
      if (y > bbox.height - offset) {
        y = bbox.height - offset;
      }
      if (y < offset) {
        y = offset;
      }
      const h = 360 - (y / el.height) * 360;
      this.hsb[0] = h;
      this.drawBright();
      this.nowColor = Color.rgb(HSB2RGB(this.hsb)).hex();
      this.colorInput.emit(this.nowColor)
      // this.parentModal && this.parentModal.emit('colorInput', {
      //   color: this.nowColor,
      //   type: 'selector'
      // });
    }
    const colorObj = Color(this.nowColor.toLowerCase());
    this.rgbColor[0] = colorObj.red();
    this.rgbColor[1] = colorObj.green();
    this.rgbColor[2] = colorObj.blue();
  }
  /**
   * 事件:窗口松开鼠标
   */
  async onWindowMouseUp() {
    if (this.downType !== '') {
      this.downType = '';
      this.selectFn && this.selectFn(this.nowColor);
      const ColorStr = `rgb(${Math.floor(this.rgbColor[0])},${Math.floor(this.rgbColor[1])},${Math.floor(this.rgbColor[2])})`
      this.nowColor = ColorStr;
      this.hsb = RGB2HSB(Color(ColorStr).color);
      this.colorChange.emit(this.nowColor);
      // this.parentModal && this.parentModal.emit('colorChange', {
      //   color: ,
      //   type: 'selector'
      // });
    }
  }
  /**
   * 事件:输入颜色文本被改变
   * @param event 事件对象
   */
  onColorInputChange(event): void {
    setTimeout(() => {
      let nColor = event.target.value;
      if (nColor.indexOf('#') === -1) {
        nColor = '#' + nColor;
      }
      let color;
      try {
        color = new Color(nColor);
        this.hsb = RGB2HSB(color.color);
        this.nowColor = color.hex();
        this.drawBright();
        this.colorChange.emit(this.nowColor);
        // this.parentModal && this.parentModal.emit('colorChange', {
        //   color: this.nowColor,
        //   type: 'selector'
        // });
        this.selectFn && this.selectFn(this.nowColor);
      } catch (error) {
        this.modal.info('请输入正确的颜色值');
      }
    }, 1);
  }
  /**
   * 绘制hue色板
   */
  drawHue(): void {
    const el = this.hueEl.nativeElement,
      ctx = el.getContext('2d'),
      linearGradient = ctx.createLinearGradient(0, 0, 0, el.height);
    // 创建过渡色
    const colors = [
      '#ff0000',
      '#ff00ff',
      '#0000ff',
      '#00ffff',
      '#00ff00',
      '#ffff00',
      '#ff0000'
    ];
    for (let i = 0; i < colors.length; i++) {
      linearGradient.addColorStop(i / (colors.length - 1), colors[i]);
    }
    // 绘制矩形
    ctx.fillStyle = linearGradient;
    ctx.fillRect(0, 0, el.width, el.height);
  }
  /**
   * 根据传入颜色绘制
   * @param color 颜色
   */
  drawBright(): void {
    const el = this.brightEl.nativeElement,
      ctx = el.getContext('2d'),
      baseColor = Color.hsl(this.hsb[0], 100, 50).hex();
    // 绘制基础色
    ctx.fillStyle = baseColor;
    ctx.fillRect(0, 0, el.width, el.height);
    // 生成白色过渡(从左到右)
    const linearGradient1 = ctx.createLinearGradient(0, 0, el.width, 0);
    linearGradient1.addColorStop(0, 'rgba(255,255,255,1)');
    linearGradient1.addColorStop(1, 'rgba(255,255,255,0)');
    // 绘制白色过渡矩形
    ctx.fillStyle = linearGradient1;
    ctx.fillRect(0, 0, el.width, el.height);
    // 生成黑色过渡(从下到上)
    const linearGradient2 = ctx.createLinearGradient(0, el.height, 0, 0);
    linearGradient2.addColorStop(0, 'rgba(0,0,0,1)');
    linearGradient2.addColorStop(1, 'rgba(0,0,0,0)');
    // 绘制黑色过渡矩形
    ctx.fillStyle = linearGradient2;
    ctx.fillRect(0, 0, el.width, el.height);
  }
  /**
   * 获取当前颜色
   */
  getNowColor(): string {
    if (this.nowColor === 'transparent') {
      return 'ffffff';
    } else {
      return Color(this.nowColor.toLowerCase()).hex().replace('#', '');
    }
  }
  /**
   * 事件:吸色工具按钮被点击
   */
  async onOpenColorDropperClick() {
    const eyeDropper = new EyeDropper();
    if(!eyeDropper) {
      this.modal.info('该浏览器不兼容此功能!');
    }
    const abortController = new AbortController();
    try {
      const result = await eyeDropper.open({
        signal: abortController.signal,
      });
      //console.log(result);
      try {
        let color = new Color(result.sRGBHex);
        this.hsb = RGB2HSB(color.color);
        this.nowColor = color.hex();
        this.drawBright();
        this.colorChange.emit(this.nowColor);
        this.selectFn && this.selectFn(this.nowColor);
      } catch (error) {
        this.modal.info('获取颜色失败');
      }
    } catch (e) {
      console.log(e);
    }

    //this.openColorDropper.emit('openColorDropper');
    //this.parentModal.emit('openColorDropper');

  }
  //   onOpenColorDropperClick(): void {
  //     const pipette = new ColorPipette({
  //       container: document.body,
  //       scale: 2,
  //       listener: {
  //         onOk: ({ color, colors }) => {
  //           console.info(color, colors);
  //         },
  //       },
  //     });
  //     // 开始取色
  //     pipette.start();

  // }
  async changeColorType(type){
    this.colorTypeShow = false;
    this.colorType = type;
    if (type == 'CMYK'){
      const colorObj = Color(this.nowColor.toLowerCase());

      const cmykRes = await this.http.post('/soft-ware-use/node-rgb-cmyk', {
        r: colorObj.red(),
        g: colorObj.green(),
        b: colorObj.blue()
      }).toPromise();
      this.cmyk[0] = parseInt(cmykRes['c'], 10);
      this.cmyk[1] = parseInt(cmykRes['m'], 10);
      this.cmyk[2] = parseInt(cmykRes['y'], 10);
      this.cmyk[3] = parseInt(cmykRes['k'], 10);
      if(colorObj.red() === 0 && colorObj.green() === 0&&colorObj.blue() === 0){
        this.cmyk[0] = 0;
        this.cmyk[1] = 0;
        this.cmyk[2] = 0;
        this.cmyk[3] = 100;
      }
      const ColorStr = `rgba(${Math.floor(this.rgbColor[0])},${Math.floor(this.rgbColor[1])},${Math.floor(this.rgbColor[2])},255${this.PrefixInteger(this.cmyk[0], 3)}${this.PrefixInteger(this.cmyk[1], 3)}${this.PrefixInteger(this.cmyk[2], 3)}${this.PrefixInteger(this.cmyk[3], 3)})`
      this.nowColor = ColorStr;
      this.hsb = RGB2HSB(Color(ColorStr).color);
      this.drawBright();
      this.colorChange.emit(ColorStr);
      // this.parentModal && this.parentModal.emit('colorChange', {
      //   color: ColorStr,
      //   type: 'selector'
      // });
      this.cmykInput('all');
    } else if (type == 'RGB') {
      const ColorStr = `rgb(${Math.floor(this.rgbColor[0])},${Math.floor(this.rgbColor[1])},${Math.floor(this.rgbColor[2])})`
      this.nowColor = ColorStr;
      this.hsb = RGB2HSB(Color(ColorStr).color);
      this.drawBright();
      this.colorChange.emit(ColorStr);
      // this.parentModal && this.parentModal.emit('colorChange', {
      //   color: ColorStr,
      //   type: 'selector'
      // });
    }
    this.colorSelectorType.emit(this.colorType)
  }
  async getCMYKColor(color){
    this.cmyk[0] = parseInt(color.cmyk['c'], 10);
    this.cmyk[1] = parseInt(color.cmyk['m'], 10);
    this.cmyk[2] = parseInt(color.cmyk['y'], 10);
    this.cmyk[3] = parseInt(color.cmyk['k'], 10);
    const ColorStr = `rgba(${Math.floor(this.rgbColor[0])},${Math.floor(this.rgbColor[1])},${Math.floor(this.rgbColor[2])},255${this.PrefixInteger(this.cmyk[0], 3)}${this.PrefixInteger(this.cmyk[1], 3)}${this.PrefixInteger(this.cmyk[2], 3)}${this.PrefixInteger(this.cmyk[3], 3)})`
    this.drawBright();
    this.cmykInput('all');
  }
  getRGBColor(color){
    if (color.rgb == 'transparent'){
      this.rgbColor[0] = 0;
      this.rgbColor[1] = 0;
      this.rgbColor[2] = 0;
    } else{
      this.hsb = RGB2HSB(Color(color.rgb).color);
      const colorObj = Color(color.rgb.toLowerCase());
      this.rgbColor[0] = colorObj.red();
      this.rgbColor[1] = colorObj.green();
      this.rgbColor[2] = colorObj.blue();
      this.drawBright();
    }

  }
  /**
   * cmyk值改变事件
   * @param event event 对象
   * @param type 类型
   */
  cmykChange(event,type){
    this.rgbColor[0] = Math.floor(255 * (100 - this.cmyk[0]) * (100 - this.cmyk[3])/10000);
    this.rgbColor[1] = Math.floor(255 * (100 - this.cmyk[1]) * (100 - this.cmyk[3])/10000);
    this.rgbColor[2] = Math.floor(255 * (100 - this.cmyk[2]) * (100 - this.cmyk[3])/10000);
    const ColorStr = `rgba(${Math.floor(this.rgbColor[0])},${Math.floor(this.rgbColor[1])},${Math.floor(this.rgbColor[2])},255${this.PrefixInteger(this.cmyk[0], 3)}${this.PrefixInteger(this.cmyk[1], 3)}${this.PrefixInteger(this.cmyk[2], 3)}${this.PrefixInteger(this.cmyk[3], 3)})`
    this.nowColor = ColorStr;
    this.hsb = RGB2HSB(Color(ColorStr).color);
    this.drawBright();
    this.colorChange.emit(ColorStr);
    // this.parentModal && this.parentModal.emit('colorChange', {
    //   color: ColorStr,
    //   type: 'selector'
    // });
    this.cmykInput(type)
  }
  cmykInput(type){
    if (type == "C"){
      this.getBackK();
      this.getBackM();
      this.getBackY();
    } else if (type == "M"){
      this.getBackK();
      this.getBackC();
      this.getBackY();
    } else if(type == "Y"){
      this.getBackK();
      this.getBackM();
      this.getBackC();
    }else if(type == "K"){
      this.getBackC();
      this.getBackM();
      this.getBackY();
    }else{
      this.getBackC();
      this.getBackM();
      this.getBackY();
      this.getBackK();
    }
  }
  /**
   * 获取C背景色
   */
  getBackC(){
    let startR,startG,startB,endR;
    startR = Math.floor(255 * 100 * (100 - this.cmyk[3])/10000);
    startG = Math.floor(255 * (100 - this.cmyk[1]) * (100 - this.cmyk[3])/10000);
    startB = Math.floor(255 * (100 - this.cmyk[2]) * (100 - this.cmyk[3])/10000);
    endR = 0;
    this.backColorC = `-webkit-linear-gradient(left, rgb(${startR},${startG},${startB}) , rgb(${endR},${startG},${startB}))`;
  }
  /**
   * 获取M背景色
   */
  getBackM(){
    let startR,startG,startB,endG;
    startR = Math.floor(255 * (100 - this.cmyk[0]) * (100 - this.cmyk[3])/10000);
    startG = Math.floor(255 * 100 * (100 - this.cmyk[3])/10000);
    startB = Math.floor(255 * (100 - this.cmyk[2]) * (100 - this.cmyk[3])/10000);
    endG = 0;
    this.backColorM =  `-webkit-linear-gradient(left, rgb(${startR},${startG},${startB}) , rgb(${startR},${endG},${startB}))`
  }
  /**
   * 获取Y背景色
   */
  getBackY(){
    let startR,startG,startB,endB;
    startR = Math.floor(255 * (100 - this.cmyk[0]) * (100 - this.cmyk[3])/10000);
    startG = Math.floor(255 * (100 - this.cmyk[1]) * (100 - this.cmyk[3])/10000);
    startB = Math.floor(255 * 100 * (100 - this.cmyk[3])/10000);
    endB = 0;
    this.backColorY = `-webkit-linear-gradient(left, rgb(${startR},${startG},${startB}) , rgb(${startR},${startG},${endB}))`
  }
  /**
   * 获取K背景色
   */
  getBackK(){
    let startR,startG,startB;
    startR = Math.floor(255 * (100 - this.cmyk[0]) / 100);
    startG = Math.floor(255 * (100 - this.cmyk[1]) / 100);
    startB = Math.floor(255 * (100 - this.cmyk[2]) / 100);
    this.backColorK = `-webkit-linear-gradient(left, rgb(${startR},${startG},${startB}) , rgb(0,0,0))`
  }
}
