import { UserInterfaceService } from '../../../services/ui.service';
import { EditorService } from '../../../services/editor.service';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener
} from '@angular/core';
import * as $ from 'jquery';
import { getUnitScale } from '../../../utils/misc/common';
import { Text } from '../../../models/element/text';
import emojiRegex from 'emoji-regex'

@Component({
  selector: 'app-text-edit',
  templateUrl: './text-edit.component.html',
  styleUrls: ['./text-edit.component.scss']
})
export class TextEditComponent implements OnInit, OnDestroy {
  @Input() element;
  @Input() stage;
  @ViewChild('input', {static: true}) input: ElementRef;
  scaleEventID;
  beforeBBox: ClientRect;
  // 单行文字编辑模式,如果进入编辑模式时行数=1则为真,当resize时则改为false
  oneLineEdit = false;
  constructor(public editor: EditorService, public ui: UserInterfaceService) { }

  ngOnInit(): void {
    const $text = $(this.element.options.text);
    if (this.element.options.url) {
      delete this.element.options.url;
      delete this.element.options.v4svg;
      // 修复行高偏移
      const $text = $(this.element.options.text);
      const scale = this.editor.template.scale;
      const fontSize = parseFloat($text.css('font-size'));
      const lineHeight = (parseInt($text.css('line-height'), 10) - 100) / 100;
      // 获取文字px大小
      this.element.props.y -= (lineHeight * fontSize) / scale / 2;

      let xOffset = 0;
      let widthScale = 1.1;
      const align = $text.css('text-align');
      if (align === 'left') {
        xOffset = 0;
      } else if (align === 'center') {
        xOffset = (this.element.props.width / scale * 0.05);
      } else if (align === 'right') {
        xOffset = (this.element.props.width / scale * 0.1);
      }
      if (this.element.props.rotation !== 0) {
        widthScale = 1;
        xOffset = 0;
      }
      this.element.props.x -= xOffset;
      this.element.props.width *= widthScale;
    }
    // 获取元素编辑前的bbox,用于修正宽高修改后发生的偏移
    this.beforeBBox = this.stage.getBBox(this.element);
    // 文字等比缩放后需要更改所有元素的字号
    if (!this.element.options.scale){
      let fontSize = parseFloat($text.css('font-size')) * (this.element.props.width/this.element.props.oWidth) * this.editor.template.scale;
      $text.css('font-size', fontSize + 'px');
      this.element.options.text = $text[0].outerHTML;
      this.element.options.scale = 1;
      this.element.oWidth = this.element.width;
      this.element.oHeight = this.element.height;
    }
    // 按照缩放比例对文本进行缩放
    let scaleSize =
      parseFloat($text.css('font-size')) *
      this.element.options.scale;
    if (scaleSize < 12) {
      scaleSize = 12;
    }
    $text.css('font-size', scaleSize + 'px');
    // 缩放文字间距
    const letterSpacing = $text.css('letter-spacing');
    //const lineHeight = $text.css('line-height');
    if (letterSpacing !== '') {
      $text.css('letter-spacing', parseFloat(letterSpacing) * this.element.options.scale + 'px');
    }
    /*if (lineHeight !== '') {
      $text.css('line-height', parseFloat(lineHeight) * scale + '%');
    }*/

    // 更新元素宽高
    this.input.nativeElement.innerHTML = this.element.states.text =
      $text[0].outerHTML;

    $(this.input.nativeElement).find('.text-wrap').attr('contenteditable', true);
    const textInfo = this.element.getTextInfo(
      this.element.states.text,
      false
    );
    this.element.config.minWidth = textInfo.minWidth;
    // if (textInfo.linesLength.length === 1) {
    //   this.oneLineEdit = true;
    // }
    // 如果高度超过输入文字高度,则增加元素高度
    this.element.props.height = textInfo.height;
    this.element.options.linesLength = textInfo.linesLength;
    // 显示光标并移动到最后
    const $textEl = $(this.input.nativeElement).find('.text-wrap')[0];
    $textEl.focus();
    // 全选
    const range = document.createRange();
    range.selectNodeContents($textEl);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    // 注册 event:scaling 侦听,当宽度被修改,则更新文本框高度
    // this.scaleEventID = this.element.on(
    //   'scaling',
    //   this.onElementScaling.bind(this)
    // );
    $('.stage-container div').css('pointer-events', 'none');

  }
  ngOnDestroy(): void {
    $('.stage-container div').css('pointer-events', '');

    this.element.config.minWidth = 0.001;
    this.element.config.minHeight = 0.001;
    this.element.props.oWidth = this.element.props.width / this.element.options.scale;
    this.element.props.oHeight = this.element.props.height / this.element.options.scale;
    const $text = $(this.element.options.text);
    $text.attr('contenteditable', null);
    $text.find('*').removeAttr('data-family');
    // 删除由于换行然后删除换行导致的字号,字体自动附加问题
    $text.find('*[style]').each(function () {
      if ($(this).css('font-size')) {
        $(this).css('font-size', '');
      }
    });
    // 规格化每行的内容,删除多余内容
    /*$text.children().each(function () {
      if ($(this).text().length === 0) {
        $(this).html('<br>');
      } else {
        $(this).text($(this).text());
      }
    });*/
    $text.children().each(function () {
      if ($(this)[0].innerText.length > 1) {
        $(this).find('br').last().remove();
      }
    });

    // if ($text.css('font-size') !== '') {
    //   const scaleSize = parseFloat($text.css('font-size')) / this.element.options.scale;
    //   $text.css('font-size', scaleSize + 'px');
    // }
    //const letterSpacing = $text.css('letter-spacing');
    // if (letterSpacing !== '') {
    //   $text.css('letter-spacing', parseFloat(letterSpacing) / this.element.options.scale + 'px');
    // }
    // 解决父标签颜色丢失
    const color = $text.css('color');
    if (color === '') {
      $text.css('color', '#000000');
    }
    this.element.options.text = $text[0].outerHTML;
    // 替换emoji

    this.element.options.text = this.element.options.text.replace(emojiRegex(), "");
    // 销毁 event:scaling 侦听
    this.element.off(this.scaleEventID);
    delete this.element.states.range;
    // 文字弧度
    if (this.element.options.curve) {
      const curveobj = this.element.getCurvedText(this.element.options.text, this.element.props.width, this.element.options.curve.dir);
      this.element.options.curve.html = curveobj.html;
      this.element.props.oHeight = this.element.props.height = curveobj.height;
      this.element.states.html = this.element.toHTML();
    }
  }
  // @HostListener('document:selectionchange', ['$event']) onTextEditSelectionChange(event) {
  //   const sel = window.getSelection();
  //   const range = sel.getRangeAt(0);

  //   if (sel.type === 'Range') {
  //     this.element.states.range = range;
  //   }

  // }
  onTextEditMousedown() {
    delete this.element.states.range;
  }
  getTextInputStyle(): any {
    return {
      transform: `scale(${this.editor.zoom})`,
      transformOrigin: '0% 0%',
      width: Math.round(this.element.props.width * this.editor.template.scale) + 'px',
      height: Math.round(this.element.props.height * this.editor.template.scale) + 'px'
    };
  }
  /**
   * 获取文本编辑框样式
   */
  getTextEditStyle(): any {
    const props = this.element.props;
    const options = this.element.options;
    const zoom =
      this.editor.zoom * this.editor.template.scale;
    const style = {
      left: this.editor.stagePos.x + props.x * zoom + 'px',
      top: this.editor.stagePos.y + props.y * zoom + 'px',
      transform: `rotate(${props.rotation}deg)`,
      transformOrigin: null,
      writingMode: options.mode === 'v' ? 'vertical-lr' : 'normal'
    };

    return style;
  }

  // 为子元素添加span标签
  warpSpan(text): any {
    let result = '';
    for (let ii = 0; ii < text.length; ii++) {
      const char = text.charAt(ii);
      if (char.charCodeAt() === 32) {
        result = result + '<span> </span>';
      } else {
        result = result + '<span>' + char + '</span>';
      }
    }
    return result;
  }
  /**
   * 当粘贴文本时
   * @param event 事件对象
   */
  @HostListener('document:paste', ['$event']) onTextPaste(event: ClipboardEvent): boolean {
    // this.oneLineEdit = false;
    const pastedText = event.clipboardData.getData('Text');
    let resultText = '';
    const filteChars = [9, 10, 13];
    for (const char of pastedText) {
      const code = char.charCodeAt(0),
        idx = filteChars.findIndex(item => item === code);
      if (idx === -1) {
        resultText += char;
      }
    }
    document.execCommand('insertText', true, resultText);
    if ($('.text-edit').find('.text-wrap').find('div').length === 0) {
      // 粘贴进来的文字没有包裹div,手动添加
      const html = '<div>' + $('.text-edit').find('.text-wrap').text() + '</div>';
      $('.text-edit').find('.text-wrap').html(html);
    }
    return false;
  }
  /**
   * 事件:当文本输入
   * @param event 事件对象
   */
  onTextInput(event): void {
    let html = event.currentTarget.innerHTML;

    const zoom = this.editor.zoom * this.editor.template.scale;


    if ($(html).children('div').length === 0) {
      // 如果html被删除,则使用emptyHtml重新添加html代码
      event.target.innerHTML = `<div><font face="font_2"><br></font></div>`;
    }

    this.element.options.text = event.target.outerHTML;
    let textInfo = this.element.getTextInfo(
      this.element.options.text,
      false
    );
    // 如果高度超过输入文字高度,则增加元素高度
    const $tempText = $(this.element.options.text);
    const align = $tempText.css('text-align-last') || $tempText.css('text-align');
    if (this.element.options.mode === 'h') {
      this.element.props.height = textInfo.height;
    } else {
      this.element.props.width = textInfo.width;
    }
    this.element.props.oWidth = this.element.props.width;
    this.element.props.oHeight = this.element.props.height;
    this.element.options.scale = 1;
    // 计算位置偏移
    const afterBBox = this.stage.getBBox(this.element),
      offsetLeft = this.beforeBBox.right - afterBBox.right,
      offsetTop = this.beforeBBox.top - afterBBox.top,
      offsetRight = this.beforeBBox.right - afterBBox.right,
      offsetBottom = this.beforeBBox.bottom - afterBBox.bottom,
      offsetWidth = this.beforeBBox.width - afterBBox.width,
      offsetHeight = this.beforeBBox.height - afterBBox.height,
      rotation = this.element.props.rotation;

    if (this.element.options.mode === 'h') {
      if (
        (rotation > 0 && rotation < 90) ||
        (rotation > 180 && rotation < 270)
      ) {
        this.element.props.x -= offsetLeft / zoom;
        this.element.props.y += offsetBottom / zoom;
      } else if (
        (rotation > 90 && rotation < 180) ||
        (rotation > 270 && rotation < 360)
      ) {
        this.element.props.x += offsetLeft / zoom;
        this.element.props.y += offsetBottom / zoom;
      }
    } else {
      if (
        (rotation > 0 && rotation < 90) ||
        (rotation > 180 && rotation < 270)
      ) {
        this.element.props.y -= offsetTop / zoom;
      } else if (
        (rotation > 90 && rotation < 180) ||
        (rotation > 270 && rotation < 360)
      ) {
        this.element.props.y += offsetTop / zoom;
      }
      this.element.props.x += offsetLeft / zoom;
    }
    this.beforeBBox = this.stage.getBBox(this.element);
    this.element.options.linesLength = textInfo.linesLength;
    if (this.element.options.mode === 'h') {
      this.element.config.minWidth = textInfo.minWidth;
    }
  }
  findPoint({ x, y, angle, center, rad = angle * (Math.PI / 180) }) {
    return {
      x:
        (x - center.x) * Math.cos(rad) -
        (y - center.y) * Math.sin(rad) +
        center.x,
      y:
        (x - center.x) * Math.sin(rad) +
        (y - center.y) * Math.cos(rad) +
        center.y
    };
  }
  /**
   * 当元素被缩放
   * @param event 事件对象
   */
  onElementScaling(event): void {
    const textInfo = this.element.getTextInfo(
      this.element.states.tempText,
      false
    );
    // 如果高度超过输入文字高度,则增加元素高度
    this.element.props.width = textInfo.width;

    // const heightDiff = textInfo.height - this.element.props.oHeight;
    // if (heightDiff !== 0) {
    //   const positionOffset = {
    //     x: this.element.props.width / 2, y: this.element.props.height / 2
    //   };
    //   const point1 = this.findPoint({
    //     x: this.element.props.x + positionOffset.x,
    //     y: this.element.props.y + positionOffset.y,
    //     angle: this.element.props.rotation,
    //     center: {
    //       x: this.element.props.x + this.element.props.width / 2,
    //       y: this.element.props.y + this.element.props.height / 2
    //     }
    //   });
    //   const point2 = this.findPoint({
    //     x: this.element.props.x + positionOffset.x,
    //     y: this.element.props.y + positionOffset.y,
    //     angle: this.element.props.rotation,
    //     center: {
    //       x: this.element.props.x + this.element.props.width / 2,
    //       y: this.element.props.y + textInfo.height / 2
    //     }
    //   });
    //   const point3 = {
    //     x: point2.x - point1.x,
    //     y: point2.y - point1.y
    //   };
    //   console.log('修正', point3);

    //   this.element.props.x -= point3.x * 2;
    //   this.element.props.y -= point3.y * 2;

    // }
    this.element.props.height = textInfo.height;

    this.element.props.oWidth = this.element.props.width;
    this.element.props.oHeight = this.element.props.height;
    this.element.options.linesLength = textInfo.linesLength;
    // 取消选择
    if (window.getSelection().type === 'Range') {
      const el = $('.text-edit')[0];
      el.focus();
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();

      sel.removeAllRanges();
      sel.addRange(range);
    }


  }
}
