import { Component, OnInit } from '@angular/core';
import { EditorService } from '../../../../../../../services/editor.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-table-align-tool',
  templateUrl: './table-align-tool.component.html',
  styleUrls: ['./table-align-tool.component.scss']
})
export class TableAlignToolComponent implements OnInit {
  align = 'left';
  constructor(public editor: EditorService) { }
  get activeCell() {
    const eleItem = this.editor.activeElements[0];
    return eleItem.states.activeCell;
  }
  ngOnInit() { }
  /**
   * 事件:对齐按钮被单击
   * @param type 对齐方式
   */
  onAlignClick(type) {
    this.align = type;
    const eleItem = this.editor.activeElements[0];
    if (this.activeCell) {
      this.activeCell.align = this.align;
    }
    // 触发更改事件
    // eleItem.emit('modified', {
    //   type: 'alignChange',
    //   target: eleItem
    // });
  }
}
