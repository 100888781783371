import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { TemplateComponent } from './template.component';
import { ListComponent } from './list/list.component';
import { HomeResolverService } from './services/home-resolver.service';
import {
  categoryClassifyMenuReg,
  categoryMenuReg,
  searchClassifyMenuReg,
  templateInfoReg
} from '@libs/core/src/lib/util/regular';
import { TemplateCenterComponent } from './template-center/template-center.component';
import { DetailComponent } from './detail/detail.component';
import { LinklistComponent } from "../linklist/linklist.component";
import { ClassifyComponent } from './classify/classify.component';

/**
 * 品类页路由分析
 * @param {UrlSegment[]} url
 * @returns {UrlMatchResult}
 * @constructor
 */
export function TemplateCategoryMatcher(url: UrlSegment[]): UrlMatchResult {
  if (url.length === 0) return null;

  // 没有参数
  if (url.length === 1) {
    const param = url[0].toString();
    if (param.match(categoryMenuReg))
      return {
        consumed: url,
        posParams: { query: url[0] }
      };
  }
  return null;
}

/**
 * 模版列表路由配置
 * @param {UrlSegment[]} url
 * @returns {UrlMatchResult}
 * @constructor
 */
export function TemplateMatcher(url: UrlSegment[]): UrlMatchResult {
  // if (url.length === 0) return null;

  // 有参数
  let parseUrl = '';
  url.forEach(value => {
    parseUrl += '/' + value.path;
  });
  if (parseUrl.match(categoryClassifyMenuReg)) {
    return {
      consumed: url,
      posParams: { query: url[0] }
    };
  }
  if (parseUrl.match(searchClassifyMenuReg)) {
    return {
      consumed: url,
      posParams: { query: url[0] }
    };
  }

  return null;
}

export function TemplateInfoMatcher(url: UrlSegment[]): UrlMatchResult {
  if (url.length === 0) return null;
  if (url.length === 1) {
    const route = url[0].toString();
    console.log(route);
    if (route.match(templateInfoReg))
      return {
        consumed: url,
        posParams: { id: url[0] }
      };
  }
}

const routes: Routes = [
  {
    path: '',
    component: TemplateComponent,
    children: [
      {
        path: 'center',
        component: TemplateCenterComponent,
        // resolve: { list: HomeResolverService },
        data: {
          meta: {
            title: '设小帮-免费在线作图神器-轻量在线平面设计软件-海报画册印刷品设计专用',
            description: '为您提供最新模版在线设计制作服务，让您轻松体验到强大的自助设计服务'
          }
        }
      },
      {
        path: 'classify',
        component: ClassifyComponent
      },
      { path: 'list/:query', component: ListComponent },
      { path: 'detail/:id', component: DetailComponent},
      { path: '**', redirectTo: '/template/center' },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TemplateRoutingModule {
}
