import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from '@libs/core/src/lib/services/post-order/admin.service';
import { DataProvider } from '@libs/core/src/lib/util/data-provider';
import { map } from 'rxjs/operators';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { DesignerService } from '@libs/core/src/lib/services/post-order/designer.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from '@libs/core/src/lib/services/message.service';

@Component({
  selector: 'app-limit-classify',
  templateUrl: './limit-classify.component.html',
  styleUrls: ['./limit-classify.component.scss']
})
export class LimitClassifyComponent implements OnInit {
  @Input() item;
  classifyList:any = []
  dataProvider = new DataProvider<any>();

  constructor(
    private adminService: AdminService,
    private modal: NzModalRef,
    private designerService: DesignerService,
    private message: MessageService) { }

  getClassify() {
    this.adminService.classifyList().pipe(
      map(data => this.dataProvider.prepare(data))
    ).subscribe(res => {
      this.classifyList = res;
      this.classifyList.forEach(item => {
        item.label = item.name;
        item.value = item.id
        if (this.item.noClassify.length > 0) {
          this.item.noClassify.forEach(element => {
            if (element.id === item.id) {
              item.checked = true;
            }
          });
        }
      });
    });
  }

  close() {
    this.modal.close();
  }

  confirm() {
    let no_access_classify = [];
    this.classifyList.forEach(item => {
      if (item.checked) {
        no_access_classify.push(item.id);
      }
    });
    this.designerService.optDesigner({id: this.item.id, no_access_classify: no_access_classify.toString(), method: 'no_classify'}).subscribe(() => {
      this.message.success('设置成功');
      this.close();
    }, (err: HttpErrorResponse) => {
      // this.message.error(err);
      this.message.error('设置失败');
    })
  }

  ngOnInit() {
    this.getClassify();
  }

}
