import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { EditorService } from '../../../../../services/editor.service';
import { FilterSettingToolComponent } from '../../modals/filter-setting-tool/filter-setting-tool.component';
import { Modal } from '../../../../../models/ui/modal';
import { UserInterfaceService } from '../../../../../services/ui.service';
import { ImageRadiusSettingToolComponent } from './modals/image-radius-setting-tool/image-radius-setting-tool.component';
import { UploadService } from '../../../../../../../../core/src/lib/services/upload.service';
import { ModalService } from '../../../../../../../../modal/src/lib/services/modal.service';
import { ToastIcon } from '../../../../../../../../modal/src/lib/models/toast-icon';
import * as $ from 'jquery';
import { Svg } from '@libs/editor/src/lib/models/element/svg';
import { Container } from '@libs/editor/src/lib/models/element/container';
import { TOrderInfoComponent } from '@libs/editor/src/lib/components/editor-ui/t-order-info/t-order-info.component';
import { Window } from '@libs/editor/src/lib/models/ui/window';
import { CutOutImageComponent } from '@libs/editor/src/lib/components/editor-ui/cut-out-image/cut-out-image.component';
import { ModalService as Modal2Service } from '../../../../../../../../core/src/lib/services/modal.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@libs/core/src/lib/services/auth.service';

@Component({
  selector: 'app-tool-bar-image',
  templateUrl: './tool-bar-image.component.html',
  styleUrls: ['./tool-bar-image.component.scss']
})
export class ToolBarImageComponent implements OnInit {
  @ViewChild('changeImageUpload', {static: true}) inputFile: ElementRef<any>;
  @Input() group: boolean;
  constructor(
    public editor: EditorService,
    public ui: UserInterfaceService,
    public upload: UploadService,
    public modal: ModalService,
    public modal2: Modal2Service,
    public http: HttpClient,
    public auth: AuthService
  ) { }
  eleItem;
  ngOnInit(): void {
    this.eleItem = this.editor.activeElements[0];
  }
  /**
   * 换图按钮被单击
   */
  onChangeImageClick() {
    this.inputFile.nativeElement.click()
  }
  /**
   * 换图的回调
   */
  onFileChange() {
    const file = this.inputFile.nativeElement.files[0];
    // 判断类型
    if (
      ['jpg', 'png', 'svg+xml', 'jpeg'].filter(
        item => 'image/' + item === file.type
      ).length === 0
    ) {
      alert('仅支持上传图片文件');
      return;
    } else if (file.size > 20 * 1024 * 1024) {
      // 尺寸判断
      alert('文件最大尺寸为20M，请压缩后上传，推荐压缩文件网址：                 https://www.bejson.com/ui/compress_img/');
      return;
    }
    const loadingToast = this.modal.toast({
      icon: ToastIcon.LOADING,
      msg: '正在上传',
      duration: 0
    });

    this.upload.uploadTemp({
      file,
      onSuccess: res => {
        const radio = res.height / res.width;
        if (file.type === 'image/svg+xml') {
          const elementIndex = this.editor.activePage.elements.findIndex(v => v.id === this.eleItem.id);
          const { width, x, y } = this.eleItem.props;
          this.eleItem.remove(false);
          this.eleItem = new Svg({
            id: this.editor.getNewID(),
            props: { x, y, width, height: width * radio, oWidth: res.width, oHeight: res.height },
            options: {
              url: res.filePath,
              source: res.fileId,
              colors: []
            }
          });
          this.editor.activePage.insertElement(this.eleItem, elementIndex, false);
          this.editor.snapshot();
          this.editor.setActiveElement(this.eleItem);
        } else {
          this.eleItem.props.height = this.eleItem.props.width * radio;
          this.eleItem.props.oWidth = res.width;
          this.eleItem.props.oHeight = res.height;
          this.eleItem.options.source = res.fileId;
          this.eleItem.options.url = res.filePath;
          if (this.eleItem.options.clip) {
            this.eleItem.options.clip = {
              x: 0,
              y: 0,
              width: res.width,
              height: res.height
            };
          }
          this.eleItem.emit('modified', {
            type: 'changeImage',
            target: this.eleItem
          });
        }

        loadingToast.close();
        this.modal.toast({
          icon: ToastIcon.SUCCESS,
          msg: '已更换'
        });
      },
      onFail: e => {
        console.log('上传失败', e);
        loadingToast.close();
        this.modal.toast({
          icon: ToastIcon.ERROR,
          msg: '上传失败'
        });
      }
    });
    this.inputFile.nativeElement.value = ''
  }
  /**
   * 事件:滤镜按钮被单击
   * @param event mouse
   */
  onFilterBtnClick(event) {
    const modal = new Modal({
      x: event.clientX - 10,
      y: event.clientY + 20,
      width: 305,
      height: 547,
      component: FilterSettingToolComponent
    });
    this.ui.add(modal);
  }
  /**
   * 事件:裁剪按钮被单击
   */
  onClipBtnClick() {
    const eleItem = this.editor.activeElements[0];
    if (!eleItem.states.editMode) {
      eleItem.enterEditMode();
    }
  }
  /**
   * 事件:圆角按钮被单击
   */
  onRadiusBtnClick(event) {
    const modal = new Modal({
      x: event.clientX - 260 / 2,
      y: event.clientY + 20,
      width: 260,
      height: 70,
      component: ImageRadiusSettingToolComponent
    });
    this.ui.add(modal);
  }

  /**
   * 事件抠图按钮被点击
   * @param event
   */
  showCutoutWindow(event){
    if (this.auth.isGuest) {
      this.modal2.loginModal();
      return
    }
    if (this.editor.activeElements.length != 1 || this.editor.activeElements[0].type != 'image') return;
    const path = this.editor.activeElements[0].options.url.match(/updata_new\/(\S*)/)[0];
    const loadingToast = this.modal.toast({
      icon: ToastIcon.LOADING,
      msg: '正在抠图',
      duration: 0
    });
    this.http.post('/image-handle/image-matting', {img_url: path}).subscribe(res => {
      loadingToast.close();
      this.modal.toast({
        icon: ToastIcon.SUCCESS,
        msg: '抠图成功'
      });
      this.modal2.editCutImageModal(res).afterClose.subscribe(res => {
        if (res) {
          this.editor.activeElements[0].options.url = res.path;
          this.editor.activeElements[0].options.source = res.fileId;
          this.editor.template.emit('element:modified', {
            type: 'changeImg',
            target: this.editor.activeElements[0]
          });
        }
      });
    }, err => {
      loadingToast.close();
      if (err.status == 403) {
        this.modal2.loginModal();
      } else {
        this.modal.toast({
          icon: ToastIcon.ERROR,
          msg: '抠图失败'
        });
      }
    })
  }
  /**
   * 转换为容器
   */
  async covertToContainer() {
    const toast = this.modal.toast({ icon: ToastIcon.LOADING, msg: '正在转换为相框', duration: 0 });
    const element = this.editor.activeElements[0];
    await element.covertToContainer();
    toast.close();
    this.modal.toast({ icon: ToastIcon.SUCCESS, msg: '已转换为相框' });
  }
  oneToOne(){
    if (this.editor.activeElements.length != 1 || this.editor.activeElements[0].type != 'image') return;
    let actEle = this.editor.activeElements[0];
    actEle.props.width = actEle.props.oWidth / this.editor.template.scale;
    actEle.props.height = actEle.props.oHeight / this.editor.template.scale;
    actEle.states.html = actEle.toHTML();
  }
}
