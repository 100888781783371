import { Modal } from '../../../../../models/ui/modal';
import { UserInterfaceService } from '../../../../../services/ui.service';
import { EditorService } from '../../../../../services/editor.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-more-color-tool',
  templateUrl: './more-color-tool.component.html',
  styleUrls: ['./more-color-tool.component.scss']
})
export class MoreColorToolComponent implements OnInit {
  @Input('parentModal') parentModal: Modal;
  constructor(public editor: EditorService, public ui: UserInterfaceService) {}

  ngOnInit(): void {}
  /**
   * 组件鼠标左键被按下
   * @param event 事件对象
   */
  onMoreCoolToolMouseDown(event): void {
    // 关闭除了我之外的所有模态框
    this.ui.userInterfaceList
      .filter(item => item.type === 'modal' && item.id !== this.parentModal.id)
      .forEach(modalItem => {
        this.ui.remove(modalItem);
      });
  }
  /**
   * 获取当前选中颜色的颜色对象
   */
  getColors(): any {
    const activeElements = this.editor.activeElements;
    if (!activeElements) {
      return [];
    }
    const colors = [];
    activeElements.filter(item => item.type === 'svg').forEach(eleItem => {
      // 获取svg的原始颜色
      const resColor = eleItem.states.oColors ? eleItem.states.oColors : [];
      // 将元素的colors属性与colors变量合并
      resColor.forEach(colorItem => {
        const findColor = eleItem.options.colors.find(
          item => item.oColor === colorItem.oColor
        );
        if (findColor) {
          colorItem.nColor = findColor.nColor;
        }
      });
      // 剔除重复颜色
      resColor.forEach(colorItem => {
        const findColor = colors.find(item => item.oColor === colorItem.oColor);
        if (!findColor) {
          colors.push(colorItem);
        }
      });
    });
    return colors;
  }
  /**
   * 事件:当颜色改变是
   * @param color 颜色对象
   * @param color 新颜色
   */
  onColorChange(item, color, fireEvent): void {
    this.setColor(this.editor.activeElements, item.oColor, color, fireEvent);
  }
  /**
   * 设置指定元素的颜色
   * @param eleItems 元素
   * @param oColor 原始颜色
   * @param nColor 即将设置的新颜色
   * @param fireEvent 是否触发事件
   */
  setColor(eleItems, oColor, nColor, fireEvent): void {
    // 修改颜色
    eleItems.forEach(eleItem => {
      const findColor = eleItem.options.colors.findIndex(
        colorItem => colorItem.oColor === oColor
      );
      if (findColor !== -1) {
        // 这个颜色存在于colors属性里
        if (oColor === nColor) {
          // 新修改的颜色和原始颜色一样,从颜色数组内移除
          eleItem.options.colors.splice(findColor, 1);
        } else {
          // 颜色被更新,使用新颜色
          eleItem.options.colors[findColor].nColor = nColor;
        }
      } else {
        // 这个颜色不存在于colors属性里,如果新修改的颜色与原始颜色不一致,则将其添加到colors属性内
        eleItem.options.colors.push({
          oColor,
          nColor
        });
      }
      if (fireEvent === false) {
        // 如果不触发modified事件,则手动更新HTML
        eleItem.states.html = eleItem.toHTML();
      }
    });
    // 触发更改事件
    if (fireEvent) {
      eleItems.forEach(eleItem => {
        if (eleItems.length === 1) {
          eleItem.emit('modified', {
            type: 'colorChange',
            target: eleItem
          });
        } else if (eleItems.length > 1) {
          eleItem.emit('modified', {
            type: 'colorChange',
            target: eleItem,
            targets: eleItems,
            stopPropagation: true
          });
        }
      });
      if (eleItems.length > 1) {
        eleItems[0].parent.emit('element:modified', {
          type: 'colorChange',
          targets: eleItems
        });
      }
    }
  }
}
