import { Component, OnInit } from '@angular/core';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { AuthService } from '@libs/core/src/lib/services/auth.service';

@Component({
  selector: 'app-new-menu',
  templateUrl: './new-menu.component.html',
  styleUrls: ['./new-menu.component.scss']
})
export class NewMenuComponent implements OnInit {

  constructor(
    public coop: CoopService,
    public modal: ModalService,
    public auth: AuthService
  ) { }

  ngOnInit() {
  }

}
