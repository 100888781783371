import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './components/menu/menu.component';
import { LoginComponent } from './components/modal/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TemplateDetailComponent } from './components/template-detail/template-detail.component';
import { QRCodeModule } from 'angularx-qrcode';
import { LoadingComponent } from './components/loading/loading.component';
import { NoAnythingComponent } from './components/no-anything/no-anything.component';
import { SearchComponent } from './components/search/search.component';
import { UpgradePromptComponent } from './components/modal/upgrade-prompt/upgrade-prompt.component';
import { ChargeTemplateComponent } from './components/modal/charge-template/charge-template.component';
import { DownloadTemplateComponent } from './components/modal/download-template/download-template.component';
import { DataTimePipe } from '@libs/shared/src/lib/pipes/data-time.pipe';
import { ShareTemplateComponent } from './components/modal/share-template/share-template.component';
import { ConfirmComponent } from './components/modal/confirm/confirm.component';
import { ColorSelectorComponent } from './components/modal/color-selector/color-selector.component';
import { ImageUrlPipe } from './pipes/image-url.pipe';
import { CompatibleComponent } from './components/modal/compatible/compatible.component';
import { OauthComponent } from './components/modal/oauth/oauth.component';
import { PreferentialComponent } from './components/modal/preferential/preferential.component';
import { QrcodeComponent } from './components/modal/qrcode/qrcode.component';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { PromptsComponent } from './components/modal/prompts/prompts.component';
import { ApplyComponent } from '@libs/shared/src/lib/components/modal/apply/apply.component';
import { RechargeComponent } from '@libs/shared/src/lib/components/modal/recharge/recharge.component';
import { NoticeDetailComponent } from '@libs/shared/src/lib/components/modal/notice-detail/notice-detail.component';
import { PreviewImageComponent } from '@libs/shared/src/lib/components/modal/preview-image/preview-image.component';
import { DiyComponent } from '@libs/shared/src/lib/components/modal/diy/diy.component';
import { DiyDesignerComponent } from '@libs/shared/src/lib/components/modal/diy-designer/diy-designer.component'
import { UploadPsdComponent } from './components/modal/upload-psd/upload-psd.component';
import { VideoPlayerComponent } from '@libs/shared/src/lib/components/modal/video-player/video-player.component';
import { SafeHtmlPipe } from '@libs/shared/src/lib/pipes/sanitize-html.pipe';
import { WaterfallItemDirective } from '@libs/shared/src/lib/directives/waterfall-item.directive';
import { WaterfallComponent } from '@libs/shared/src/lib/components/waterfall/waterfall.component';
import { NewLoginComponent } from '@libs/shared/src/lib/components/modal/new-login/new-login.component';
import { NewRegisterComponent } from '@libs/shared/src/lib/components/modal/new-register/new-register.component';
import { TemplateItemComponent } from '@libs/shared/src/lib/components/template-item/template-item.component';
import { CardMessageComponent } from '@libs/shared/src/lib/components/modal/card-message/card-message.component';
import { SimpleHeaderComponent } from '@libs/shared/src/lib/components/simple-header/simple-header.component';
import { ListQueryComponent } from '@libs/shared/src/lib/components/list-query/list-query.component';
import { MathPipe } from '@libs/shared/src/lib/pipes/math.pipe';
import { TemplateSubmitComponent } from './components/modal/template-submit/template-submit.component';
import { CreateTemplateComponent } from './components/create-template/create-template.component';
import { PpyHintComponent } from './components/modal/ppy-hint/ppy-hint.component';
import { PreviewComponent } from './components/modal/preview/preview.component';
import { NologinSaveComponent } from './components/modal/nologin-save/nologin-save.component';
import { NologinSubmitComponent } from './components/modal/nologin-submit/nologin-submit.component';
import { FileDownloadDirective } from '@libs/shared/src/lib/directives/file-download.directive';
import { NoVipModalComponent } from '@libs/shared/src/lib/components/modal/no-vip-modal/no-vip-modal.component';
import { BuyVipComponent } from './components/modal/buy-vip/buy-vip.component';
import { VipDownloadComponent } from './components/modal/vip-download/vip-download.component';
import { VipDownloadListComponent } from './components/modal/vip-download-list/vip-download-list.component';
import { WechatPayQrcodeComponent } from './components/modal/wechat-pay-qrcode/wechat-pay-qrcode.component';
import { EditorTipComponent } from './components/modal/editor-tip/editor-tip.component';
import { LongTimeNoOperationComponent } from './components/modal/long-time-no-operation/long-time-no-operation.component';
import { PpyLoginComponent } from './components/modal/ppy-login/ppy-login.component';
import { ReplaceCalendarComponent } from './components/modal/replace-calendar/replace-calendar.component';
import { PipeModule } from '@libs/editor/src/lib/utils/pipe/pipe.module';
import { IframeModalComponent } from './components/modal/iframe-modal/iframe-modal.component';
import { BatchAddCalendarComponent } from './components/modal/batch-add-calendar/batch-add-calendar.component';
import { AddTemComponent } from './components/modal/add-tem/add-tem.component';
import { VideoTranscodingComponent } from './components/modal/video-transcoding/video-transcoding.component';
import { SubmitMakeSureComponent } from './components/modal/submit-make-sure/submit-make-sure.component';
import { NewHeaderComponent } from '@libs/shared/src/lib/components/new-header/new-header.component';
import { NewMenuComponent } from '@libs/shared/src/lib/components/new-menu/new-menu.component';
import { RouterLinkActiveStyleDirective } from '@libs/shared/src/lib/directives/router-link-active-style.directive';
import { FooterComponent } from './components/footer/footer.component';
import { DiyConfirmComponent } from './components/modal/diy-confirm/diy-confirm.component';
import { SaveSuccessComponent } from './components/modal/save-success/save-success.component';
import { ChoiceSizeComponent } from '@libs/shared/src/lib/components/modal/choice-size/choice-size.component';
import { CreateStoreComponent } from '@libs/shared/src/lib/components/modal/create-store/create-store.component';
import { CreatCategoryComponent } from '@libs/shared/src/lib/components/modal/creat-category/creat-category.component';
import { CreateMemorandumComponent } from '@libs/shared/src/lib/components/modal/create-memorandum/create-memorandum.component';
import { AddServiceComponent } from '@libs/shared/src/lib/components/modal/add-service/add-service.component';
import { PorderConfirmComponent } from '@libs/shared/src/lib/components/modal/porder-confirm/porder-confirm.component';
import { CutPaymentComponent } from '@libs/shared/src/lib/components/modal/cut-payment/cut-payment.component';
import { TaskHandleComponent } from '@libs/shared/src/lib/components/modal/task-handle/task-handle.component';
import { MessageBoxComponent } from '@libs/shared/src/lib/components/modal/message-box/message-box.component';
import { BatchAddEleItem } from '@libs/shared/src/lib/components/modal/batch-add-eleitem/batch-add-eleitem.component';
import { ReversePipe } from './pipes/reverse.pipe';
import { CustomCardMessageComponent } from './components/modal/custom-card-message/custom-card-message.component';
import { TemplateLinkComponent } from './components/modal/template-link/template-link.component';
import { SizeUpdateHintComponent } from './components/modal/size-update-hint/size-update-hint.component';
import { CreateThreadComponent } from './components/modal/create-thread/create-thread.component';
import { ExportExcelComponent } from './components/modal/export-excel/export-excel.component';
import { DownloadHistoryComponent } from './components/modal/download-history/download-history.component';
import { LimitClassifyComponent } from './components/modal/limit-classify/limit-classify.component';
import { OrderNoticeComponent } from './components/modal/order-notice/order-notice.component';
import { KejieApplyComponent } from './components/modal/kejie-apply/kejie-apply.component';
import { EditMattingComponent } from './components/modal/edit-matting/edit-matting.component';
import { UpgradeDetailComponent } from './components/modal/upgrade-detail/upgrade-detail.component';
import { SuccessModalComponent } from './components/modal/success-modal/success-modal.component';
import { ImageCropperModule } from '@libs/shared/src/lib/modules/image-cropper/image-cropper.module';
import { NgZorroAntdModule } from '@libs/shared/src/lib/ng-zorro-antd.module';
import { SortablejsDirective } from '@libs/shared/src/lib/directives/sortable.directive';
import { FolderSelectorComponent } from '@libs/shared/src/lib/components/modal/folder-selector/folder-selector.component';
import { SafeUrlPipe } from '@libs/shared/src/lib/pipes/sanitize-url.pipe';
import { NzIconModule } from 'ng-zorro-antd/icon';

import {
  EditOutline,
  UndoOutline,
  PlusOutline,
  DeleteOutline,
  EllipsisOutline,
  FilterOutline,
  SettingOutline,
  SettingFill,
  LinkOutline,
  CheckOutline,
  UserOutline,
  UploadOutline,
  CopyrightOutline,
  LogoutOutline,
  ToolOutline,
  StarOutline,
  MenuFoldOutline,
  FullscreenOutline,
  DownloadOutline
} from '@ant-design/icons-angular/icons';

const icons = [
  EditOutline,
  UndoOutline,
  PlusOutline,
  DeleteOutline,
  EllipsisOutline,
  FilterOutline,
  SettingOutline,
  SettingFill,
  LinkOutline,
  CheckOutline,
  UserOutline,
  UploadOutline,
  CopyrightOutline,
  LogoutOutline,
  ToolOutline,
  StarOutline,
  MenuFoldOutline,
  FullscreenOutline,
  DownloadOutline
]
const COMPONENTS = [
  DataTimePipe,
  ReversePipe,
  HeaderComponent,
  SimpleHeaderComponent,
  MenuComponent,
  LoginComponent,
  ApplyComponent,
  UploadPsdComponent,
  TemplateDetailComponent,
  LoadingComponent,
  NoAnythingComponent,
  SearchComponent,
  UpgradePromptComponent,
  ChargeTemplateComponent,
  UpgradePromptComponent,
  ChargeTemplateComponent,
  DownloadTemplateComponent,
  ShareTemplateComponent,
  ConfirmComponent,
  ColorSelectorComponent,
  ImageUrlPipe,
  MathPipe,
  CompatibleComponent,
  OauthComponent,
  PreferentialComponent,
  QrcodeComponent,
  EllipsisPipe,
  PromptsComponent,
  RechargeComponent,
  NoticeDetailComponent,
  PreviewImageComponent,
  DiyComponent,
  DiyDesignerComponent,
  VideoPlayerComponent,
  SafeHtmlPipe,
  SafeUrlPipe,
  WaterfallComponent,
  WaterfallItemDirective,
  SortablejsDirective,
  FileDownloadDirective,
  RouterLinkActiveStyleDirective,
  NewLoginComponent,
  NewRegisterComponent,
  TemplateItemComponent,
  ListQueryComponent,
  TemplateSubmitComponent,
  CreateTemplateComponent,
  PpyHintComponent,
  PreviewComponent,
  NologinSaveComponent,
  NologinSubmitComponent,
  NoVipModalComponent,
  BuyVipComponent,
  VipDownloadComponent,
  VipDownloadListComponent,
  WechatPayQrcodeComponent,
  EditorTipComponent,
  LongTimeNoOperationComponent,
  PpyLoginComponent,
  ReplaceCalendarComponent,
  IframeModalComponent,
  BatchAddCalendarComponent,
  AddTemComponent,
  VideoTranscodingComponent,
  SubmitMakeSureComponent,
  NewHeaderComponent,
  NewMenuComponent,
  FooterComponent,
  DiyConfirmComponent,
  SaveSuccessComponent,
  ChoiceSizeComponent,
  CreateStoreComponent,
  CreatCategoryComponent,
  CreateMemorandumComponent,
  AddServiceComponent,
  PorderConfirmComponent,
  CutPaymentComponent,
  TaskHandleComponent,
  MessageBoxComponent,
  BatchAddEleItem,
  FolderSelectorComponent,
  ChoiceSizeComponent,
  CardMessageComponent,
  CustomCardMessageComponent,
  TemplateLinkComponent,
  SizeUpdateHintComponent,
  CreateThreadComponent,
  ExportExcelComponent,
  DownloadHistoryComponent,
  OrderNoticeComponent,
  KejieApplyComponent,
  EditMattingComponent,
  UpgradeDetailComponent,
  LimitClassifyComponent,
  SuccessModalComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    // qrcode
    QRCodeModule,
    PipeModule,
    ImageCropperModule,
    NgZorroAntdModule,
    NzIconModule.forRoot(icons)
  ],
  declarations: [...COMPONENTS, PreferentialComponent, EllipsisPipe, PromptsComponent, VipDownloadComponent,VipDownloadListComponent, WechatPayQrcodeComponent, EditorTipComponent, LongTimeNoOperationComponent, ReplaceCalendarComponent, BatchAddCalendarComponent, VideoTranscodingComponent, UpgradeDetailComponent, SuccessModalComponent],
  exports: [...COMPONENTS, NgZorroAntdModule, NzIconModule,ImageCropperModule],
  entryComponents: [
    UploadPsdComponent,
    LoginComponent,
    ApplyComponent,
    UpgradePromptComponent,
    ChargeTemplateComponent,
    DownloadTemplateComponent,
    ShareTemplateComponent,
    ConfirmComponent,
    ColorSelectorComponent,
    CompatibleComponent,
    OauthComponent,
    QrcodeComponent,
    PromptsComponent,
    PreferentialComponent,
    DiyComponent,
    DiyDesignerComponent,
    RechargeComponent,
    NoticeDetailComponent,
    PreviewImageComponent,
    VideoPlayerComponent,
    NewLoginComponent,
    NewRegisterComponent,
    TemplateItemComponent,
    CardMessageComponent,
    CustomCardMessageComponent,
    TemplateSubmitComponent,
    CreateTemplateComponent,
    PpyHintComponent,
    PpyLoginComponent,
    PreviewComponent,
    NologinSaveComponent,
    NologinSubmitComponent,
    NoVipModalComponent,
    BuyVipComponent,
    VipDownloadComponent,
    VipDownloadListComponent,
    WechatPayQrcodeComponent,
    EditorTipComponent,
    LongTimeNoOperationComponent,
    ReplaceCalendarComponent,
    IframeModalComponent,
    BatchAddCalendarComponent,
    AddTemComponent,
    VideoTranscodingComponent,
    SubmitMakeSureComponent,
    DiyConfirmComponent,
    SaveSuccessComponent,
    BatchAddEleItem,
    FolderSelectorComponent,
    ChoiceSizeComponent,
    TemplateLinkComponent,
    CreateStoreComponent,
    CreatCategoryComponent,
    CreateMemorandumComponent,
    AddServiceComponent,
    PorderConfirmComponent,
    CutPaymentComponent,
    TaskHandleComponent,
    MessageBoxComponent,
    SizeUpdateHintComponent,
    CreateThreadComponent,
    ExportExcelComponent,
    DownloadHistoryComponent,
    OrderNoticeComponent,
    KejieApplyComponent,
    EditMattingComponent,
    LimitClassifyComponent,
    UpgradeDetailComponent,
    SuccessModalComponent,
  ]
})
export class SharedModule {
}
