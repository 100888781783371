import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { fadeInOut } from '@libs/core/src/lib/animations/animations';

@Component({
  selector: 'lib-t-alert',
  templateUrl: './t-alert.component.html',
  styleUrls: ['./t-alert.component.scss'],
  animations: [fadeInOut]
})
export class TAlertComponent implements OnInit {
  @Input() title;
  @Input() msg;
  @Input() buttons;
  @Output() select = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  /**
   * 判断是否是首要按钮
   * @param str 原始按钮文本
   */
  isPrimary(str: string): boolean {
    return str.indexOf('!') !== -1;
  }
  /**
   * 过滤按钮文本(去掉主要按钮的叹号)
   * @param {string} str 原始按钮文本
   * @returns {string} 处理后的按钮文本
   */
  getButtonText(str: string): string {
    return str.replace('!', '');
  }
  onButtonClick(idx: number): void {
    this.select.emit(idx);
  }
}
