import { Component, Input, OnInit } from '@angular/core';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';
import { UserInterfaceService } from '@libs/editor/src/lib/services/ui.service';
import { ApiService } from '@libs/editor/src/lib/services/api.service';
import { pt2px, px2pt } from '@libs/editor/src/lib/utils/misc/common';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';
import { Modal } from '@libs/editor/src/lib/models/ui/modal';
import { TableTextAlignToolComponent } from '@libs/editor/src/lib/components/editor-ui/t-tool-bar/tools/tool-bar-text/modal/table-text-align-tool/table-text-align-tool.component';

@Component({
  selector: 'app-tool-bar-new-table',
  templateUrl: './tool-bar-new-table.component.html',
  styleUrls: ['./tool-bar-new-table.component.scss']
})
export class ToolBarNewTableComponent implements OnInit {
  @Input() eleItem = null;
  public fontColor = '#000000';
  public mulitFont = false;
  public fontSize = '12px';
  public fontSizeItems: any = [4, 6, 8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72];
  public borderColor = '#000000';
  public tableBgColor = '#000000';
  public align = 'align';
  constructor(
    public editor:EditorService,
    public ui: UserInterfaceService,
    public api: ApiService,
    public modal:ModalService
  ) { }

  ngOnInit() {
    if (this.eleItem == null){
      this.eleItem = this.editor.activeElements[0]
    }
    this.fontSizeItems = this.fontSizeItems.map(item => {
      return {
        option: item,
        value: item
      };
    });
    this.borderColor = this.eleItem.options.borderColor;
    this.tableBgColor = this.eleItem.options.tableBgColor;
  }
  enterEdit(){
    this.eleItem.enterEditMode(this.eleItem);
  }
  getFontColor(){
    let chooseItems = this.eleItem.getChooseItem();
    if (this.eleItem && chooseItems.length){
      return chooseItems[0].style.color;
    }
    return '#000';
  }
  getFontFamily(){
    //检测选中的文本是否存在字体信息
    let chooseItems = this.eleItem.getChooseItem();
    if (this.eleItem && chooseItems.length && chooseItems[0].chooseFont){
      return chooseItems[0].chooseFont;
    }
    return this.api.fontList[0];
  }
  //获取选中的字体大小
  getFontSize(){
    // 获取字号
    let chooseItems = this.eleItem.getChooseItem();
    if (this.eleItem && chooseItems.length){
      const fontSize = parseInt(chooseItems[0].style.fontSize)*this.eleItem.options.scale;
      return parseInt((px2pt(fontSize, 300)).toString(), 10);
    }
    return 12;
  }
  onColorChange(e){
    let chooseItems = this.eleItem.getChooseItem();
    if (this.eleItem && chooseItems.length){
      for (let item of chooseItems){
        item.style.color = e;
      }
      this.editor.snapshot();
    }
  }
  onFontChange(e){
    let chooseItems = this.eleItem.getChooseItem();
    if (this.eleItem && chooseItems.length){
      for (let item of chooseItems){
        item.style.fontFamily = `font_${e.fontId}`;
        item.chooseFont = e;
      }
      this.editor.snapshot();
    }
  }
  onFontSizeChange(value){
    let chooseItems = this.eleItem.getChooseItem();
    if (this.eleItem && chooseItems.length){
      this.fontSize = value;
      const dpi = this.editor.template.props.unit === 'px' ? 72 : 300;
      const pxNum = pt2px(value, dpi)/this.eleItem.options.scale;
      let chooseItemIndexArr = [];
      for (let item of chooseItems){
        item.style.fontSize = pxNum + 'px';
        let indexArr = this.eleItem.getCellIndex(item);
        if (indexArr.length == 2){
          chooseItemIndexArr.push(indexArr);
        }
      }
      setTimeout(()=>{
        this.changeTableBoxHeight();
        for (let index of chooseItemIndexArr){
          this.eleItem.changeCellRealSize(index[0],index[1],this.editor.zoom,false);
        }
        setTimeout(()=>{
          this.eleItem.calculationCellWidth();
          setTimeout(()=>{
            this.editor.snapshot();
          })
        })
      });
    }
  }
  changeTableBoxHeight(){
    let tableElement = document.getElementById('table-element');
    let tableStyle = tableElement.getBoundingClientRect();
    this.eleItem.props.height = tableStyle.height/(this.editor.template.scale*this.editor.zoom);
    this.eleItem.props.width = tableStyle.width/(this.editor.template.scale*this.editor.zoom);
  }
  onBgColorChange($event){
    this.eleItem.options.tableBgColor = $event;
    this.tableBgColor = $event;
  }
  getAlignIconClass() {
    let align = 'left';
    let chooseItems = this.eleItem.getChooseItem();
    if (this.eleItem && chooseItems.length){
      align = chooseItems[0].style.textAlign;
    }
    switch (align) {
      case 'left':
        return 'icon-zuoduiqiwenben';
      case 'center':
        return 'icon-juzhongwenben';
      case 'right':
        return 'icon-youduiqiwenben';
      case 'justify':
        return 'icon-quanbuduiqi';
      default :
        return 'icon-zuoduiqiwenben';
    }
  }
  /**
   * 事件:对齐被单击
   * @param event 鼠标事件
   */
  onAlignClick(event: MouseEvent) {
    const eleItem = this.editor.activeElements[0];
    const modal = new Modal({
      x: event.clientX - 170 / 2,
      y: event.clientY + 20,
      width: 170,
      height: 80,
      component: TableTextAlignToolComponent
    });
    this.ui.add(modal);
  }
}

