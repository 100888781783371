export default function warpSpanText(text,nbsp = true){
  let result = '';
  for (let char of text){
    if (char.charCodeAt() === 32 && nbsp) {
      result = result + '<span>&nbsp;</span>';
    } else {
      result = result + '<span>' + char + '</span>';
    }
  }
  return result;
}
