import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Swiper from 'swiper';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-submit-make-sure',
  templateUrl: './submit-make-sure.component.html',
  styleUrls: ['./submit-make-sure.component.scss']
})
export class SubmitMakeSureComponent implements OnInit, AfterViewInit {
  @ViewChild('swipe') swipe: ElementRef;
  @Input() template;
  constructor(
    private modalRef: NzModalRef
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    //首页广告位轮播图
    setTimeout(() => {
      new Swiper(this.swipe.nativeElement, {
        direction: 'horizontal',
        effect: 'fade',
        grabCursor: true,
        autoplay: false,
        loop: false,
        // 如果需要分页器
        // pagination: {
        //   el: '.swiper-pagination',
        // }
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    }, 0)

  }

  submit() {
    this.modalRef.close({method: 'submit'})
  }
}
