import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import * as $ from 'jquery';
import { EditorService } from '../../../../../../../services/editor.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { ApiService } from '@libs/editor/src/lib/services/api.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-font-fill-picture-tool',
  templateUrl: './font-fill-picture-tool.component.html',
  styleUrls: ['./font-fill-picture-tool.component.scss']
})
export class FontFillPictureToolComponent implements OnInit {
  element;
  mine: any[] = [];
  // 标签数据
  tagData = [];
  currentPage = 0;
  totalPage = 0;
  totalNum = 0;
  loading = false;
  cid = this.api.getClassify('background')[0].cid.toString();
  // 当前选中的tag (classify) 分类
  tagCid = '';
  localData = null;
  showPopUpTagList = false;
  @ViewChild('listRef', { static: true }) listRef: ElementRef;
  constructor(
    public editor: EditorService,
    public message:MessageService,
    public api: ApiService,
    public http: HttpClient
  ) { }

  ngOnInit() {
    this.loadTagList();
    this.loadBackgroundList(this.cid);
  }

  /**
   * 加载二级分类
   * @param cid 一级分类
   */
  async loadTagList() {
    const res = (await this.http.get('/material-classify', { params: { pid: this.cid } }).toPromise()) as any[];
    this.tagData = res;
    this.tagCid = this.cid;
    this.tagData.unshift({
      cid: this.cid,
      name: '全部'
    });
  }
  /**
   * 事件:tag项目
   * @param item 标签项目
   */
  onTagItemClick(item) {
    if (item.cid === this.tagCid) return;
    this.mine = [];
    this.tagCid = item.cid;
    this.currentPage = 0;
    this.totalPage = 0;
    this.loadBackgroundList(item.cid);
  }
  //获取素材列表
  async loadBackgroundList(cid){
    if (this.loading === true) return;
    if (this.currentPage >= this.totalPage && this.totalPage > 0) return;
    this.currentPage++;
    this.loading = true;
    let params: any = { cid:cid,page: this.currentPage.toString()};
    params['limit'] = 50;
    const res = await this.http
      .get('/material-official', {
        params,
        observe: 'response'
      })
      .toPromise();
    this.totalPage = parseInt(res.headers.get('X-Pagination-Page-Count'), 10);
    this.totalNum = parseInt(res.headers.get('X-Pagination-Total-Count'), 10);
    this.loading = false;
    console.log('this.loading',this.loading)
    this.mine = this.mine.concat(res.body);
  }

  /**
   * 事件:背景图片被选择
   * @param item 素材项目
   */
  onBgImgClick(item) {
    const activeElements = this.editor.activeElements;
    for (const eleItem of activeElements) {
      if (!eleItem.options.isNewText) {
        eleItem.options.isNewText = true;
        eleItem.changeTextY();
      }
      delete eleItem.options.v4svg;
      delete eleItem.options.url;
      //eleItem.options.selectedColor = value;
    }
    if (activeElements.length === 1 && activeElements[0].states.editMode) {
      let sel = window.getSelection();
      let range = activeElements[0].states.range;
      const $text = $(activeElements[0].options.text);
      if (range) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
      activeElements[0].states.range = sel.getRangeAt(0);
      // 如果全部选中的话
      if ($text[0].innerText.length === sel.toString().length) {
        $text.find('*[color]').attr('color', null);
        $text.find('*[style]').css('color', '');
        $text.css('background-image', "url(" + item.filePath + ")");
        $text.css('background-size', 'contain');
        $text.css('-webkit-background-clip', 'text');
        $text.css('-webkit-text-fill-color', 'transparent');
        activeElements[0].options.text = $text[0].outerHTML;
        activeElements[0].emit('modified', {
          type: 'fontColor',
          target: activeElements[0],
          stopPropagation: false
        });

      }
    } else if (
      (activeElements.length === 1 && !activeElements[0].states.editMode) ||
      activeElements.length > 1
    ) {
      // 单个或者多个文本,非编辑模式下,颜色修改针对全局
      activeElements.forEach(eleItem => {
        const $text = $(eleItem.options.text);
        $text.find('*[color]').attr('color', null);
        $text.find('*[style]').css('color', '');
        // 包括font标签,填充颜色
        $text.css('background-image', "url(" + item.filePath + ")");
        $text.css('background-size', 'contain');
        $text.css('-webkit-background-clip', 'text');
        $text.css('-webkit-text-fill-color', 'transparent');
        eleItem.options.text = $text[0].outerHTML;
        eleItem.emit('modified', {
          type: 'fontColor',
          target: eleItem,
          stopPropagation: activeElements.length > 1
        });
        if (eleItem.options.curve) {
          const curveobj = eleItem.getCurvedText(eleItem.options.text, eleItem.props.width, eleItem.options.curve.dir);
          eleItem.options.curve.html = curveobj.html;
          eleItem.props.oHeight = eleItem.props.height = curveobj.height;
          eleItem.states.html = eleItem.toHTML();
        }
      });
      if (activeElements.length > 1) {
        activeElements[0].parent.emit('element:modified', {
          types: 'fontColor',
          targets: activeElements
        });
      }
    }
  }

  /**
   * 事件:列表滚动
   * @param event 事件对象
   */
  onTabContentScroll(event) {
    if (this.localData) return;

    const scrollPos = event.target.scrollTop,
      hiddenHeight =
        event.target.scrollHeight -
        event.target.offsetHeight;
    if (scrollPos >= hiddenHeight - 50) {
      this.loadBackgroundList(this.tagCid);
    }
    if (scrollPos > 0) {
      this.showPopUpTagList = true;
    } else {
      this.showPopUpTagList = false;
    }
  }

}
