import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
import { UploadService } from '@libs/core/src/lib/services/upload.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit {

  constructor(
    public http:HttpClient,
    public message:MessageService,
    public upload:UploadService,
    public modalService:NzModalService
  ) { }
  public tplId;
  public signList = [];
  public contentData = {};
  public chooseSign = '';
  public isSave = false;
  ngOnInit() {
    if(window.screen.width <= 600){
      document.body.style.width=window.screen.width+'px';
      document.body.style.minWidth=window.screen.width+'px';
      document.getElementsByTagName('html')[0].style.width = window.screen.width+'px';
      document.getElementsByTagName('html')[0].style.minWidth = window.screen.width+'px';
    }
    let tplStr = window.location.hash;
    if (!tplStr){
      this.tplId = 0;
    } else{
      tplStr = tplStr.slice(1);
      this.tplId = decodeURIComponent(atob(tplStr));
    }
    this.getSignList();
  }
  async getSignList(){
    let signData = await this.http.get(`/template-member/get-sign?template_id=${this.tplId}`).toPromise();
    if (signData && signData['signData'] && signData['signData'].length > 0){
      this.signList = signData['signData'];
      for (let item of this.signList){
        this.contentData[item.sign] = '';
      }
    }
  }
  uploadImg(sign){
    this.chooseSign = sign;
    $('#upload-img').click();
  }
  async saveData(){
    let res = await this.http.post('/template-member/update-sign-content',{
      template_id:this.tplId,
      sign_content:JSON.stringify(this.contentData),
      sign_method:'add'
    }).toPromise();
    if (res) {
      this.message.success(`提交成功`);
      this.isSave = true;
    }else{
      this.message.error(`提交失败，请重试`);
    }
  }
  /**
   * 图片文件被修改
   */
  onImgChange(file = []){
    let $upload = $('#upload-img');
    let files = $upload[0].files;
    if (file.length > 0){
      files = file;
    }
    if (files.length > 0) {
      for (const fileItem of files) {
        // 判断类型
        if (
          ['jpg', 'png', 'svg+xml', 'jpeg'].filter(
            item => 'image/' + item === fileItem.type
          ).length === 0
        ) {
          this.message.error('仅支持上传图片文件');
          return;
        } else if (fileItem.size > 20 * 1024 * 1024) {
          // 尺寸判断
          this.message.error('文件最大才尺寸为20M');
          return;
        }
      }
      this.upload.upload({
        file:files[0],
        onSuccess: res => {
          this.contentData[this.chooseSign] = {};
          this.contentData[this.chooseSign].fileId = res.fileId;
          this.contentData[this.chooseSign].filePath = res.filePath;
          this.contentData[this.chooseSign].width = res.width;
          this.contentData[this.chooseSign].height = res.height;
          this.message.success('上传成功');
        },
        onFail: e => {
          this.message.error('上传失败');
        }
      }, 0,'frontend','normal');
    }
    $upload.val('');
  }

  /**
   * 显示确认对话框
   */
  showConfirmModal(){
    if (this.isSave){
      this.message.error(`请不要重复提交`);
      return;
    }
    for (let sign of this.signList){
      if (sign.isChoose&&this.contentData[sign.sign] == ''){
        this.message.error(`请填写${sign.sign}`);
        return;
      }
    }
    this.modalService.confirm({
      nzTitle: '<i>请确认填写信息无误</i>',
      nzContent: '<b>一旦提交，该表单信息将无法更改</b>',
      nzOnOk: () => this.saveData()
    });
  }
  onImgDragEnter(e){
    e.stopPropagation();
    e.preventDefault();
  }
  onImgDragLeave(e){
    e.stopPropagation();
    e.preventDefault();
  }
  onImgDrag(e,sign){
    e.stopPropagation();
    e.preventDefault();
    let file = e.dataTransfer.files[0];
    if (file){
      this.chooseSign = sign;
      this.onImgChange([file]);
    }
  }
}
