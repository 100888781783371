import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Calendar } from '@libs/editor/src/lib/models/element/calendar';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';
import { ToastIcon } from '@libs/modal/src/lib/models/toast-icon';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';
import { Svg } from '@libs/editor/src/lib/models/element/svg';
import { TemplateLoader } from '@libs/editor/src/lib/utils/misc/template-loader';
import { Element } from '@libs/editor/src/lib/models/base/element';
@Component({
  selector: 'app-batch-add-calendar',
  templateUrl: './batch-add-calendar.component.html',
  styleUrls: ['./batch-add-calendar.component.scss']
})
export class BatchAddCalendarComponent implements OnInit {
  public startYear:any = '2023';
  public endYear:any = '2023';
  public startMonth:any = 1;
  public endMonth:any = 12;
  public monthArray = ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'];
  public firstType = true;
  //类型 0 日历 1 日历标题 2 日历组合
  @Input() type = 0;
  @Output() closeBatchAdd = new EventEmitter();
  constructor(
    public editor:EditorService,
    public modal:ModalService
  ) { }

  ngOnInit() {
  }
  onYearChange($event,type){
    if (type === 'start'){
      this.startYear = new Date($event).getFullYear().toString();
    } else{
      this.endYear = new Date($event).getFullYear().toString();
    }
  }
  confirm(){
    if (!this.startYear){
      this.modal.toast({ icon: ToastIcon.ERROR, msg: '请选择开始年份' });
      return;
    }
    if (!this.endYear){
      this.modal.toast({ icon: ToastIcon.ERROR, msg: '请选择结束年份' });
      return;
    }
    if (parseInt(this.endYear) < parseInt(this.startYear)){
      this.modal.toast({ icon: ToastIcon.ERROR, msg: '结束年份不能小于开始年份' });
      return;
    }
    if (!this.startMonth){
      this.modal.toast({ icon: ToastIcon.ERROR, msg: '请选择开始月份' });
      return;
    }
    if (!this.endMonth) {
      this.modal.toast({ icon: ToastIcon.ERROR, msg: '请选择结束月份' });
      return;
    }
    if (this.endMonth < this.startMonth && parseInt(this.endYear) == parseInt(this.startYear)) {
      this.modal.toast({ icon: ToastIcon.ERROR, msg: '结束月份不能小于开始月份' });
      return;
    }
    if (this.type == 0){
      this.addCalendar()
    } else if (this.type == 1){
      this.addCalendarTitle()
    } else{
      this.addCalendarGroup()
    }
  }
  addCalendar(){
    let activeCalendar = this.editor.activeElements[0];
    let templateData = this.editor.activeElements[0].options.templateData;
    let addPage = this.getAddPage();
    let year = parseInt(this.startYear);
    let endYear = parseInt(this.endYear);
    let month = this.startMonth;
    let oldHtml = this.editor.activeElements[0].options.templateData.dayItemHtml;
    for (let i = 0; i < addPage.length;i++){
      if (i === 0){
        this.editor.activeElements[0].options.month = month;
        this.editor.activeElements[0].options.year = year;
        this.editor.activeElements[0].states.html = this.editor.activeElements[0].toHTML();
      }else{
        let oldCalendar = addPage[i].elements.filter(item=>item.type == 'calendar' && item.options.templateData.dayItemHtml == oldHtml);
        if (oldCalendar.length > 0){
          oldCalendar.forEach(item=>{
            addPage[i].removeElement(item,false)
          })
        }
        const calendar = new Calendar({
          id: this.editor.getNewID(),
          props: {
            x:JSON.parse(JSON.stringify(activeCalendar.props.x)),
            y:JSON.parse(JSON.stringify(activeCalendar.props.y)),
            width:JSON.parse(JSON.stringify(activeCalendar.props.width)),
            height:JSON.parse(JSON.stringify(activeCalendar.props.height)),
            oWidth: JSON.parse(JSON.stringify(templateData.width)),
            oHeight: JSON.parse(JSON.stringify(templateData.height))
          },
          options: {
            scale: JSON.parse(JSON.stringify(activeCalendar.options.scale)),
            column:JSON.parse(JSON.stringify(activeCalendar.options.column)),
            templateData:JSON.parse(JSON.stringify(activeCalendar.options.templateData)),
            year:year,
            month:month,
            url:activeCalendar.options.filePath,
            fileId:activeCalendar.options.fileId,
            color: activeCalendar.options.color ? JSON.parse(JSON.stringify(activeCalendar.options.color)) : undefined,
            scaleWidth:JSON.parse(JSON.stringify(activeCalendar.options.scaleWidth))
          }
        });
        addPage[i].addElement(calendar);
      }
      if (month+1 > this.endMonth && year === endYear) {
        this.close();
        return;
      }
      if (month+1 > 12 && endYear > year){
        year++;
        month = month - (12 * (year-parseInt(this.startYear))) + 1;
      }else{
        month++;
      }
      this.editor.snapshot();
    }
    this.close();
  }
  addCalendarTitle(){
    let calendarTitleOptions = this.editor.activeElements[0].options;
    let addPage = this.getAddPage();
    let year = parseInt(this.startYear);
    let endYear = parseInt(this.endYear);
    let month = this.startMonth;
    for (let i = 0; i < addPage.length;i++){
      if (i === 0){
        this.editor.activeElements[0].options.month = month;
        this.editor.activeElements[0].options.year = year;
        this.editor.activeElements[0]['loadSvg']();
      } else{
        let oldId = calendarTitleOptions.source;
        let oldCalendarTitle = addPage[i].elements.filter(item=> item.type == 'svg'&&item.options.isCalendarTitle&&item.options.source == oldId);
        if (oldCalendarTitle.length > 0){
          oldCalendarTitle.forEach(item=>{
            addPage[i].removeElement(item,false)
          })
        }
        const options = {
          url: JSON.parse(JSON.stringify(calendarTitleOptions.url)),
          source: JSON.parse(JSON.stringify(calendarTitleOptions.source)),
          colors: JSON.parse(JSON.stringify(calendarTitleOptions.colors)),
          isCalendarTitle:true,
          month:month,
          year:year
        };
        let calendarTitle = new Svg({
          id: this.editor.getNewID(),
          props: {
            x:JSON.parse(JSON.stringify(this.editor.activeElements[0].props.x)),
            y:JSON.parse(JSON.stringify(this.editor.activeElements[0].props.y)),
            width:JSON.parse(JSON.stringify(this.editor.activeElements[0].props.width)),
            height:JSON.parse(JSON.stringify(this.editor.activeElements[0].props.height)),
            oWidth:JSON.parse(JSON.stringify(this.editor.activeElements[0].props.oWidth)),
            oHeight:JSON.parse(JSON.stringify(this.editor.activeElements[0].props.oHeight))
          },
          options
        });
        addPage[i].addElement(calendarTitle);
      }
      if (month+1 > this.endMonth && year === endYear) {
        this.close();
        return;
      }
      if (month+1 > 12 && endYear > year){
        year++;
        month = month - (12 * (year-parseInt(this.startYear))) + 1;
      }else{
        month++;
      }
      this.editor.snapshot();
    }
    this.close();
  }
  getAddPage(){
    let index = 0;
    let addPage = [];
    for (let i = 0; i < this.editor.template.pages.length;i++){
      if (this.editor.activePage.id === this.editor.template.pages[i].id){
        index = i;
      }
    }
    let judge = 0;
    for (let i = index; i < this.editor.template.pages.length;i++) {
      if (!this.firstType) {
        addPage.push(this.editor.template.pages[i]);
      }else{
        if (judge == 0){
          addPage.push(this.editor.template.pages[i]);
          judge++;
        } else{
          judge = 0;
        }
      }
    }
    return addPage;
  }
  addCalendarGroup(){
    let addPage = this.getAddPage();
    let year = parseInt(this.startYear);
    let endYear = parseInt(this.endYear);
    let month = this.startMonth;
    // 获取需要复制的object
    const copyArr = [];
    let eleItems = this.editor.activeElements;
    // 按照图层顺序重新排序
    eleItems.forEach(element => {
      element.index = this.editor.activePage.elements.findIndex(item => item === element);
    });
    eleItems = eleItems.sort((a, b) => a.index - b.index);

    for (const eleItem of eleItems) {
      copyArr.push(eleItem.toObject());
    }

    const copyDatas = JSON.stringify({
      elements: copyArr
    });
    for (let i = 0; i < addPage.length;i++){
      if (i === 0){
        this.editor.activeElements.forEach(item=>{
          if (item.type === 'calendar'){
            item.options.month = month;
            item.options.year = year;
            item.states.html = item.toHTML();
          }
          if (item.type === 'svg' && item.options.hasOwnProperty('isCalendarTitle') && item.options.isCalendarTitle){
            item.options.month = month;
            item.options.year = year;
            item['loadSvg']();
          }
        });
      } else{
        let copyData = JSON.parse(copyDatas);
        const strogeData = copyData.elements;
        this.editor.setActiveElement(null);
        const isGroupElements =
          strogeData[0].props.group.length === strogeData.length &&
          strogeData.filter(item =>
            strogeData[0].props.group.find(id => item.id === id)
          ).length === strogeData.length;
        const createdElements: Element[] = [];
        // id对应表
        const idList = {};
        for (const objItem of strogeData) {
          if (objItem.type === 'calendar'){
            let oldId = objItem.options.fileId;
            let oldCalendar = addPage[i].elements.filter(item=>item.type == 'calendar'&&item.options.fileId == oldId);
            if (oldCalendar.length > 0){
              oldCalendar.forEach(item=>{
                addPage[i].removeElement(item,false)
              })
            }
          }
          if (objItem.type === 'svg' && objItem.options.hasOwnProperty('isCalendarTitle') && objItem.options.isCalendarTitle){
            let oldId = objItem.options.source;
            let oldCalendarTitle = addPage[i].elements.filter(item=> item.type == 'svg'&&item.options.isCalendarTitle&&item.options.source == oldId);
            if (oldCalendarTitle.length > 0){
              oldCalendarTitle.forEach(item=>{
                addPage[i].removeElement(item,false)
              })
            }
          }
          const newID = this.editor.getNewID();
          idList[objItem.id] = newID;
          objItem.id = newID;
          if (!isGroupElements) {
            objItem.props.group = [];
          }
          const eleItem = addPage[i].addElement(
            TemplateLoader.loadElement(objItem),
            false
          );
          createdElements.push(eleItem);
        }
        // 如果是编组元素,则更新他们group属性的id
        if (isGroupElements) {
          createdElements.forEach(eleItem => {
            eleItem.props.group = eleItem.props.group.map(item => idList[item]);
          });
        }
        createdElements.forEach(eleItem => {
          if (eleItem.type === 'calendar'){
            eleItem.options.month = month;
            eleItem.options.year = year;
            eleItem.states.html = eleItem.toHTML();
          }
          if (eleItem.type === 'svg' && eleItem.options.hasOwnProperty('isCalendarTitle') && eleItem.options.isCalendarTitle){
            eleItem.options.month = month;
            eleItem.options.year = year;
            eleItem['loadSvg']();
          }
        });
        addPage[i].emit('element:added', {
          targets: createdElements,
          commit: false
        });
      }
      if (month+1 > this.endMonth && year === endYear) {
        this.close();
        return;
      }
      if (month+1 > 12 && endYear > year){
        year++;
        month = month - (12 * (year-parseInt(this.startYear))) + 1;
      }else{
        month++;
      }
      this.editor.snapshot();
    }
    this.close();
  }
  close(){
    this.closeBatchAdd.emit();
  }
}
