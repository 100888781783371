import { Component, OnInit } from '@angular/core';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';

@Component({
  selector: 'app-text2-line-tool',
  templateUrl: './text2-line-tool.component.html',
  styleUrls: ['./text2-line-tool.component.scss']
})
export class Text2LineToolComponent implements OnInit {
  constructor(public editor: EditorService) { }
  ngOnInit() { }
  /**
   * 事件:属性被修改
   * @param name 属性
   * @param value 值
   * @param fireEvent 是否触发事件
   */
  onChange(value): void {
    const el = this.editor.activeElements[0];
    el.options.strokeWidth = parseFloat(value);

    el.emit('modified', {
      type: 'strokeWidth',
      target: el,
      stopPropagation: this.editor.activeElements.length > 1
    });
  }
}
