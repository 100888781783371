import { Component, OnInit } from '@angular/core';
import { EditorService } from '../../../../../../../services/editor.service';
import * as $ from 'jquery';
import { ModalService as CoreModal } from '@libs/core/src/lib/services/modal.service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
@Component({
  selector: 'app-text-align-tool',
  templateUrl: './text-align-tool.component.html',
  styleUrls: ['./text-align-tool.component.scss']
})
export class TextAlignToolComponent implements OnInit {
  align = 'left';
  type = 'text';
  constructor(
    public editor: EditorService,
    public coreModal:CoreModal,
    public coop:CoopService
  ) { }

  ngOnInit() {
    const eleItem = this.editor.activeElements[0];

    this.type = eleItem.type;
    if (this.type === 'text') {
      const $tempText = $(eleItem.options.text);
      this.align = eleItem.options.textAlign || $tempText.css('text-align-last') || $tempText.css('text-align') || 'left';
    } else if (this.type === 'textv2') {
      this.align = eleItem.options.textAlign || 'left'
    }

  }
  /**
   * 事件:对齐按钮被单击
   * @param type 对齐方式
   */
  onAlignClick(type) {
    if (this.type === 'text') {
      this.changeTextAlign(type)
    } else if (this.type === 'textv2') {
      this.changeTextV2Align(type)
    }
  }

  changeTextAlign(type) {
    if (
      sessionStorage.getItem('directWrite')
      && sessionStorage.getItem('directWrite') === 'noDirectWrite'
      && type=='justify'
    ) {
      this.coreModal.diyConfirm({
        title: '提示!',
        content: `当前浏览器未开启DirectWrite高清字体渲染支持，无法预览文字两端对齐效果，请在浏览器设置-选项-实验室-系统设置内开启。`,
        buttons: [{
          text: '知道了',
          style: {
            backgroundColor: this.coop.coopInfo.color,
            border: 0,
            color: '#fff'
          },
          fun: (check) => {
          }
        }]
      });
      return;
    }
    this.align = type;
    const eleItem = this.editor.activeElements[0];
    if (!eleItem.options.isNewText){
      eleItem.options.isNewText = true;
      eleItem.changeTextY();
    }
    if (eleItem.options.directWrite && sessionStorage.getItem('directWrite') != eleItem.options.directWrite) {
      eleItem.options.directWrite = sessionStorage.getItem('directWrite');
    }
    if (eleItem.options.mode == 'd'){
      eleItem.options.mode = 'h';
    }
    eleItem.options.textAlign = type;
    eleItem.emit('modified', {
      type: 'align',
      target: eleItem
    });
  }

  changeTextV2Align(type) {
    this.align = type;
    const el = this.editor.activeElements[0];
    el.options.textAlign = type;
    el.emit('modified', {
      type: 'textAlign',
      target: el,
      stopPropagation: this.editor.activeElements.length > 1
    });
  }
}
