import { TModalComponent } from '../../components/common-ui/t-modal/t-modal.component';
import { UserInterfaceItem } from '../base/user-interface-item';

export class Modal extends UserInterfaceItem {
  public type = 'modal';
  constructor(params = null) {
    super(TModalComponent, params);
    this.params = {
      // 是否显示模态框
      visible: true,
      // 子级模态框
      children: [],
      backdrop: false,
      // 是否在失去焦点的时候阻止关闭
      preventClose: false,
      ...this.params
    };
  }
  /**
   * 关闭Modal
   */
  close() {
    this.emit('close');
    this.parent.remove(this);
  }
  /**
   * 添加子级模态框
   * @param modalItem 模态框对象
   */
  add(modalItem: Modal) {
    modalItem.parent = this;
    this.params.children.push(modalItem);
  }

  /**
   * 从子级删除模态框
   * @param modalItem 模态框对象
   */
  remove(modalItem: Modal): void {
    const index = this.params.children.findIndex(
      arrItem => arrItem === modalItem
    );
    this.params.children.splice(index, 1);
  }
}
