import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'math'
})
export class MathPipe implements PipeTransform {

  constructor() { }

  transform(value: any, method: string, dot?: number): any {
    switch (method) {
      case 'round':
        if (typeof value === 'string') value = parseFloat(value);
        if (dot) value = value * (1 / dot)
        value = Math.round(value);
        if (dot) value *= dot;
        break;
    }
    return value;
  }
}
