import { Modal } from '../../../../../models/ui/modal';
import { EditorService } from '../../../../../services/editor.service';
import { Component, OnInit } from '@angular/core';
import { UserInterfaceService } from '../../../../../services/ui.service';
import { MoreColorToolComponent } from '../../modals/more-color-tool/more-color-tool.component';
import { ToastIcon } from '@libs/modal/src/lib/models/toast-icon';
import * as $ from 'jquery';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';
import { UploadService } from '@libs/core/src/lib/services/upload.service';
import { Image } from '@libs/editor/src/lib/models/element/image';
import { PatternScaleToolComponent } from '../../modals/pattern-scale-tool/pattern-scale-tool.component';
import { Window } from '@libs/editor/src/lib/models/ui/window';
import { ReplaceCalendarComponent } from '@libs/shared/src/lib/components/modal/replace-calendar/replace-calendar.component';
import { ApiService } from '@libs/editor/src/lib/services/api.service';
import { SvgOutlineComponent } from '@libs/editor/src/lib/components/edit-tools/free-transform/tool/svg-outline/svg-outline.component';
import { ColorGradientComponent } from '@libs/editor/src/lib/components/edit-tools/color-gradient/color-gradient.component';


@Component({
  selector: 'app-tool-bar-svg',
  templateUrl: './tool-bar-svg.component.html',
  styleUrls: ['./tool-bar-svg.component.scss']
})
export class ToolBarSvgComponent implements OnInit {
  constructor(
    public editor: EditorService,
    public ui: UserInterfaceService,
    public upload: UploadService,
    public modal: ModalService,
    public api: ApiService
  ) { }
  eleItem;
  public isCalendarTitle = false;
  public calendarTitleWindow:any;
  public showBatchAddPop = false;
  public addReferenceWindow = null;
  ngOnInit(): void {
    this.eleItem = this.editor.activeElements[0];
    if (this.eleItem.options.isCalendarTitle) this.isCalendarTitle = true;
  }
  onPatternScaleClick(event) {
    const modal = new Modal({
      x: event.clientX - (292 / 2),
      y: event.clientY + 20,
      width: 292,
      height: 67,
      component: PatternScaleToolComponent
    });
    this.ui.add(modal);
  }
  /**
   * 获取当前选中颜色的颜色对象
   */
  getColors(): any {
    const activeElements = this.editor.activeElements;
    if (!activeElements) {
      return [];
    }
    const colors = [];
    activeElements.filter(item => item.type === 'svg').forEach(eleItem => {
      // 获取svg的原始颜色
      const resColor = eleItem.states.oColors ? eleItem.states.oColors : [];
      // 将元素的colors属性与colors变量合并
      resColor.forEach(colorItem => {
        const findColor = eleItem.options.colors.find(
          item => item.oColor.toUpperCase() === colorItem.oColor.toUpperCase()
        );
        if (findColor) {
          colorItem.nColor = findColor.nColor.toUpperCase();
        }
      });
      // 剔除重复颜色
      resColor.forEach(colorItem => {
        const findColor = colors.find(item => item.oColor.toUpperCase() === colorItem.oColor.toUpperCase());
        if (!findColor) {
          colors.push(colorItem);
        }
      });
    });

    return colors;
  }
  /**
   * 获取当前选中颜色的颜色对象
   */
  getStrokeSvgColors(): any {
    const activeElements = this.editor.activeElements;
    if (!activeElements) {
      return [];
    }
    const colors = [];
    activeElements.filter(item => item.type === 'svg').forEach(eleItem => {
      // 获取svg的原始颜色
      const resColor = eleItem.states.oStrockColors ? eleItem.states.oStrockColors : [];
      // 将元素的colors属性与colors变量合并
      resColor.forEach(colorItem => {
        const findColor = eleItem.options.colors.find(
          item => item.oColor.toUpperCase() === colorItem.oColor.toUpperCase()
        );
        if (findColor) {
          colorItem.nColor = findColor.nColor.toUpperCase();
        }
      });
      // 剔除重复颜色
      resColor.forEach(colorItem => {
        const findColor = colors.find(item => item.oColor.toUpperCase() === colorItem.oColor.toUpperCase());
        if (!findColor) {
          colors.push(colorItem);
        }
      });
    });

    return colors;
  }

  fillClick(e) {

    const activeElements = this.editor.activeElements;
    let eStyle = e.target.getBoundingClientRect();
    if (this.addReferenceWindow) {
      this.ui.remove(this.addReferenceWindow);
    }
    this.addReferenceWindow = new Modal({
      x: eStyle.left - 200,
      y: eStyle.bottom + 20,
      width: 270,
      backdrop: true,
      component: ColorGradientComponent,
      //modelType: 'reference',
      props: {
        guideItem: activeElements[0].options,
        type:'1',
        itemType:'svg'
      }
    });
    this.addReferenceWindow.on('close', e => {
      this.addReferenceWindow = null;
    });
    this.ui.add(this.addReferenceWindow);
  }

  outlineClick(event): void {
    const modal = new Modal({
      x: event.clientX - 140,
      y: event.clientY + 30,
      width: 280,
      height: 150,
      component: SvgOutlineComponent
    });
    this.ui.add(modal);
  }
  /**
   * 事件:当颜色改变是
   * @param color 颜色对象
   * @param color 新颜色
   */
  onColorChange(item, color, fireEvent = true): void {
    this.setColor(this.editor.activeElements, item.oColor, color, fireEvent);
  }
  /**
   * 设置指定元素的颜色
   * @param eleItems 元素
   * @param oColor 原始颜色
   * @param nColor 即将设置的新颜色
   * @param fireEvent 是否触发事件
   */
  setColor(eleItems, oColor, nColor, fireEvent): void {
    //console.log('eleItems',eleItems)
    // 修改颜色
    eleItems.forEach(eleItem => {
      const findColor = eleItem.options.colors.findIndex(
        colorItem => colorItem.oColor.toLowerCase() === oColor.toLowerCase()
      );
      if (findColor !== -1) {
        // 这个颜色存在于colors属性里
        if (oColor === nColor) {
          // 新修改的颜色和原始颜色一样,从颜色数组内移除
          eleItem.options.colors.splice(findColor, 1);
        } else {

          // 颜色被更新,使用新颜色
          eleItem.options.colors[findColor].nColor = nColor;
        }
      } else {
        // 这个颜色不存在于colors属性里,如果新修改的颜色与原始颜色不一致,则将其添加到colors属性内
        eleItem.options.colors.push({
          oColor,
          nColor
        });
      }
      if (fireEvent === false) {
        // 如果不触发modified事件,则手动更新HTML
        eleItem.states.html = eleItem.toHTML();
      }
    });
    // 触发更改事件
    if (fireEvent) {
      eleItems.forEach(eleItem => {
        if (eleItems.length === 1) {
          eleItem.emit('modified', {
            type: 'colorChange',
            target: eleItem
          });
        } else if (eleItems.length > 1) {
          eleItem.emit('modified', {
            type: 'colorChange',
            target: eleItem,
            targets: eleItems,
            stopPropagation: true
          });
        }
      });
      if (eleItems.length > 1) {
        eleItems[0].parent.emit('element:modified', {
          type: 'colorChange',
          targets: eleItems
        });
      }
    }
  }
  /**
   * 事件:更多颜色被选择
   * @param event 鼠标事件
   */
  onMoreColorClick(event): void {
    const colorLength = this.getColors().slice(5).length;
    const lines =
      (colorLength - (colorLength % 8)) / 8 +
      (colorLength % 8 === 0 ? 0 : 1) -
      1;
    const modal = new Modal({
      x: event.clientX - (292 / 2),
      y: event.clientY + 20,
      width: 292,
      height: lines * (70 + 30),
      component: MoreColorToolComponent
    });
    this.ui.add(modal);
  }

  onFileChange() {
    const file = $('#change-image-upload')[0].files[0];
    // 判断类型
    if (
      ['jpg', 'png', 'svg+xml', 'jpeg'].filter(
        item => 'image/' + item === file.type
      ).length === 0
    ) {
      alert('仅支持上传图片文件');
      return;
    } else if (file.size > 20 * 1024 * 1024) {
      // 尺寸判断
      alert('文件最大尺寸为20M，请压缩后上传，推荐压缩文件网址：                 https://www.bejson.com/ui/compress_img/');
      return;
    }
    const loadingToast = this.modal.toast({
      icon: ToastIcon.LOADING,
      msg: '正在上传',
      duration: 0
    });
    this.upload.uploadTemp({
      file,
      onSuccess: res => {
        const radio = res.height / res.width;
        if (file.type === 'image/svg+xml') {
          this.eleItem.options.source = res.fileId;
          this.eleItem.options.url = res.filePath;
          this.eleItem.options.colors = [];

          this.eleItem.props.oWidth = res.width;
          this.eleItem.props.oHeight = res.height;
          this.eleItem.props.height = this.eleItem.props.width * radio;
          this.eleItem.onCreated();
          this.editor.snapshot();
        } else {
          //svg中加入图片
          const elementIndex = this.editor.activePage.elements.findIndex(v => v.id === this.eleItem.id);
          const { width, x, y } = this.eleItem.props;
          this.eleItem.remove(false);
          this.eleItem = new Image({
            id: this.editor.getNewID(),
            props: {
              x,
              y,
              width,
              height: width * radio,
              oWidth: res.width,
              oHeight: res.height,
            },
            options: {
              url: res.filePath,
              source: res.fileId
            }
          });
          this.editor.activePage.insertElement(this.eleItem, elementIndex, false);
          this.editor.snapshot();
          this.editor.setActiveElement(this.eleItem);
        }


        loadingToast.close();
        this.modal.toast({
          icon: ToastIcon.SUCCESS,
          msg: '已更换'
        });
      },
      onFail: e => {
        console.log('上传失败', e);
        loadingToast.close();
        this.modal.toast({
          icon: ToastIcon.ERROR,
          msg: '上传失败'
        });
      }
    });
    $('#change-image-upload').val('');
  }

  onChangeImageClick() {
    $('#change-image-upload').click();
  }
  changeCalendarTitle(){
    if (this.calendarTitleWindow){
      this.ui.remove(this.calendarTitleWindow)
    }
    this.calendarTitleWindow = new Window({
      width: 326,
      height: 560,
      props:{eleItem:this.eleItem,type:'calendarTitle'},
      skin: 'common',
      taskbar: false,
      title:"更换标题样式",
      component: ReplaceCalendarComponent
    });
    this.ui.add(this.calendarTitleWindow);
  }

  onSpotClick() {
    let element = this.editor.activeElements[0];
    if (element.props.spotColor) {
      element.props.spotColor = null
    } else {
      element.props.spotColor = {
        name: "default",
        percentage: 1
      }
    }
  }

  batchAddCalendar(){
    this.showBatchAddPop = true;
  }
  closeBatchAdd(){
    this.showBatchAddPop = false;
  }
}
