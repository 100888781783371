import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { EditorService } from '../../../../../../../services/editor.service';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UserInterfaceService } from '../../../../../../../services/ui.service';
import * as Color from 'color';
@Component({
  selector: 'app-text-translate-tool',
  templateUrl: './text-translate-tool.component.html',
  styleUrls: ['./text-translate-tool.component.scss']
})
export class TextTranslateToolComponent implements OnInit {
  letterSpacing = 0;
  lineHeight = 0;
  element;
  source_language = 'zh';
  target_language = 'en';
  text;
  translatText;
  constructor(
    public editor: EditorService,
    public message:MessageService,
    public http: HttpClient,
    public ui: UserInterfaceService,
  ) { }

  ngOnInit() {
    this.element = this.editor.activeElements[0];
    if (this.element.type === 'text') {
      let $text = $(this.element.options.text);
      let fontArr = $text[0].innerText;
      this.text =  fontArr;
      if(this.containsEnglish(fontArr)){
        this.source_language = 'en';
        this.target_language = 'zh';
      }
    }
  }

  ontTranslateClick() {
    let params = {
      text:this.text,
      source_language:this.source_language,
      target_language:this.target_language
    }
    this.http.post<any>('/cooperation/translate', params).subscribe(data => {
      if(data && data.Translated) {
        this.translatText = data.Translated
      }
      this.message.success('翻译成功');
    }, err => {
      this.message.error('翻译失败');
    })
  }
  replace(){
    const activeElements = this.editor.activeElements;
    for (const eleItem of this.editor.activeElements) {
      let oldElement = eleItem;
      oldElement.exitEditMode();
      let newElement = this.translatText;
      let $text = $(oldElement.options.text);
      let fontArr = $text.find('font');
      let colorArr = [];
          // 获取字体颜色
      let fontColor;
      try {
        fontColor = $text.text.match(/#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})/g)[0];
      } catch (e) {
        try {
          fontColor = $text.find('*[color]').css('color') || $text.find('*[color]').attr('color') || new Color($text.css('color')).hex();
        } catch (e) {
          fontColor = '#000000'
        }
      }
      for (let i = 0; i < fontArr.length;i++){

        if (i !== 0){
          $(fontArr[i]).remove();
        }
      }
      $(fontArr[0]).text(newElement);
      $(fontArr[0]).css('color', fontColor);
      if (!fontArr || !fontArr.length) {
        $text[0].innerHTML = '<font>' + newElement + '</font>';
      }
      oldElement.options.text = $text[0].outerHTML;
      oldElement.states.html = oldElement.toHTML();
      oldElement.enterEditMode();
      setTimeout(()=>{
        oldElement.exitEditMode();
      },10)
    }
    if (activeElements.length > 1) {
      activeElements[0].parent.emit('element:modified', {
        types: 'textTransform',
        targets: activeElements
      });
    }
    this.editor.setActiveElements([...this.editor.activeElements]);
    this.ui.clear();
  }

  containsEnglish(str) {
    return /[A-Za-z]/.test(str);
}
}
