import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { PayService } from '@libs/shared/src/lib/services/pay.service';
import { MemberCharge } from '@libs/core/src/lib/models/member-charge';
import { PayMenu } from '@libs/core/src/lib/models/pay-menu';

@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html',
  styleUrls: ['./recharge.component.scss'],
  providers: [PayService]
})
export class RechargeComponent implements OnInit {
  @Input() price: number;
  start = false;

  loading = false;
  payMenu: PayMenu[];
  current = 0;

  chargeSubscription: Subscription;
  constructor(
    public authService: AuthService,
    public payService: PayService,
    private notification: NzNotificationService
  ) {}

  ngOnInit() {
    this.payService.modalWechat = false;
    // this.payService.getChargeMenu().subscribe(
    //   res => {
    //     this.payMenu = res;
    //   }
    // )
  }

  choseMoney(index): void {
    this.current = index;
    if (this.payService.payment === 'wechat') {
      // 重置Url
      this.payService.wechatUrl = '';
      this.pay();
    }
  }

  /**
   * 改变支付方式
   */
  changePayType(type: string): void {
    this.payService.payment = type;
    if (type === 'wechat' && this.payService.wechatUrl === '') {
      this.pay();
    }
  }

  pay(): void {
    let params = {
      price: this.price,
      gid: 0
    };
    this.loading = true;
    this.chargeSubscription = this.payService
      .coopCharge(params, () => this.loading = false)
      .subscribe((data: MemberCharge) => {
        this.loading = false;
        this.notification.success(
          '到账提醒',
          '您已成功充值'
        );
      });
  }
}
