import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MemberService } from '@libs/core/src/lib/services/member/member.service';
import { FolderMember } from '@libs/core/src/lib/models/folder-member';
import { NzModalRef } from 'ng-zorro-antd/modal';
@Component({
  selector: 'app-folder-selector',
  templateUrl: './folder-selector.component.html',
  styleUrls: ['./folder-selector.component.scss'],
  providers: [MemberService]
})
export class FolderSelectorComponent implements OnInit {

  folders: FolderMember[];
  folderId: number = 0;

  constructor(
    private modal: NzModalRef,
    public member: MemberService,
  ) { }

  ngOnInit() {
    this.member.getFolderMaterial().subscribe(data => {
      this.folders = data;
    });
  }
  confirm(){
    this.modal.close(this.folderId)
  }
  close() {
    this.modal.close()
  }
}
