import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@libs/core/src/lib/services/auth.service';

@Component({
  selector: 'app-no-anything',
  templateUrl: './no-anything.component.html',
  styleUrls: ['./no-anything.component.scss']
})
export class NoAnythingComponent implements OnInit {
  @Input() loading: boolean;
  @Input() type;
  @Input() fileUpload: () => any;
  @Output() uploadHandle = new EventEmitter();

  constructor(
    public authService: AuthService,
  ) {}

  ngOnInit() {}

  upload(event) {
    this.uploadHandle.emit(event);
  }

}
