import { px2pt, pt2px } from '@libs/editor/src/lib/utils/misc/common';
import { UserInterfaceService } from './../../../../../services/ui.service';
import { Window } from '@libs/editor/src/lib/models/ui/window';
import { ArtFontTextEditorComponent } from './modal/art-font-text-editor/art-font-text-editor.component';
import { EditorService } from '../../../../../services/editor.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services/api.service';
import { TextAlignToolComponent } from '@libs/editor/src/lib/components/editor-ui/t-tool-bar/tools/tool-bar-text/modal/text-align-tool/text-align-tool.component';
import { Modal } from '@libs/editor/src/lib/models/ui/modal';
import { FontDistanceToolComponent } from '@libs/editor/src/lib/components/editor-ui/t-tool-bar/tools/tool-bar-text/modal/font-distance-tool/font-distance-tool.component';
import { Text2LineToolComponent } from '@libs/editor/src/lib/components/editor-ui/t-tool-bar/tools/tool-bar-textv2/modal/line-tool/text2-line-tool.component';
import { LineLengthToolComponent } from '@libs/editor/src/lib/components/editor-ui/t-tool-bar/tools/tool-bar-textv2/modal/line-length-tool/line-length-tool.component';
import * as Color from 'color';
import * as $ from 'jquery';
import { Text } from '@libs/editor/src/lib/models/element/text';

@Component({
  selector: 'app-tool-bar-textv2',
  templateUrl: './tool-bar-textv2.component.html',
  styleUrls: ['./tool-bar-textv2.component.scss']
})
export class ToolBarTextv2Component implements OnInit, OnDestroy {
  fontFamily = '';
  stroke = '';
  textAlign = 'left';
  lineHeight = 1;
  letterSpacing = 0;
  strokeWidth = 0;
  lineLength = 0;
  fontSize = 5;
  fontSizeItems: any = [4, 6, 8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72];
  fill = '#000000';
  enterEditEventID;
  el;

  constructor(
    public editor: EditorService,
    public api: ApiService,
    public ui: UserInterfaceService,
  ) { }

  ngOnInit() {
    const el = this.editor.activeElements[0];
    this.el = el;
    const fontId = parseInt(el.options.fontFamily.split('_')[1], 10);
    this.fontSizeItems = this.fontSizeItems.map(item => {
      return {
        option: item,
        value: item
      };
    });
    this.fontFamily =
      this.api.fontList.find(item => item.fontId === fontId) ||
      this.api.fontList[0];
    this.textAlign = el.options.textAlign;
    const scale = el.props.width / el.props.oWidth;
    const templateScale = this.editor.template.scale;

    this.fontSize = Math.round(px2pt(el.options.fontSize * scale * templateScale));
    this.lineHeight = el.options.lineHeight;
    this.letterSpacing = el.options.letterSpacing;
    this.stroke = el.options.stroke;
    this.strokeWidth = el.options.strokeWidth;
    this.fill = el.options.fill;
    this.lineLength = el.options.lineLength ? el.options.lineLength : '';

    this.enterEditEventID = el.on('modified', e => {
      if (e.type === 'resize') {
        this.fontSize = Math.round(px2pt(el.options.fontSize * templateScale));
      }
    });
  }

  ngOnDestroy(): void {
    if (this.el && this.enterEditEventID) {
      this.el.off(this.enterEditEventID);
    }
  }

  onLineLengthToolClick(event) {
    const modal = new Modal({
      x: event.clientX - 170 / 2,
      y: event.clientY + 20,
      width: 220,
      height: 120,
      component: LineLengthToolComponent
    });
    this.ui.add(modal);
  }

  onFillChange(color) {
    this.fill = color;
    const el = this.editor.activeElements[0];
    el.options.fill = this.fill;
    el.states.html = el.toHTML();
    el.emit('modified', {
      type: 'fill',
      target: el,
      stopPropagation: this.editor.activeElements.length > 1
    });
  }
  onEditTextClick() {
    const window = new Window({
      title: '编辑文字',
      width: 400,
      height: 300,
      skin: 'common',
      component: ArtFontTextEditorComponent
    });
    this.ui.add(window);
  }

  /**
   * 事件:字体距离按钮被单击
   * @param event 鼠标事件
   */
  onFontDistanceToolClick(event: MouseEvent) {
    const eleItem = this.el;
    if (eleItem.states.editMode) {
      eleItem.exitEditMode();
    }
    const modal = new Modal({
      x: event.clientX - 260 / 2,
      y: event.clientY + 20,
      width: 260,
      height: 101,
      component: FontDistanceToolComponent
    });
    this.ui.add(modal);
  }

  onStrokeChange(color) {
    this.stroke = color;
    const el = this.el;
    el.options.stroke = this.stroke;
    el.states.html = el.toHTML();
    el.emit('modified', {
      type: 'color',
      target: el,
      stopPropagation: this.editor.activeElements.length > 1
    });
  }

  /**
   * 事件:对齐被单击
   * @param event 鼠标事件
   */
  onAlignClick(event: MouseEvent) {
    const eleItem = this.editor.activeElements[0];
    delete eleItem.options.v4svg;
    delete eleItem.options.url;
    const modal = new Modal({
      x: event.clientX - 170 / 2,
      y: event.clientY + 20,
      width: 220,
      height: 80,
      component: TextAlignToolComponent
    });
    this.ui.add(modal);
  }
  // 描边宽度
  onLineToolClick(event) {
    const modal = new Modal({
      x: event.clientX - 250 / 2,
      y: event.clientY + 20,
      width: 250,
      height: 70,
      component: Text2LineToolComponent
    });
    this.ui.add(modal);
  }
  async onFontChange(font) {
    this.fontFamily = font;
    const el = this.editor.activeElements[0];
    el.options.fontFamily = 'font_' + font.fontId;
    el.emit('modified', {
      type: 'fontFamily',
      target: el,
      stopPropagation: this.editor.activeElements.length > 1
    });
  }
  onFontSizeChange(value) {

    this.fontSize = value / this.editor.template.scale;
    const el = this.el;
    el.options.fontSize = pt2px(this.fontSize);

    el.emit('modified', {
      type: 'fontSize',
      target: el,
      stopPropagation: this.editor.activeElements.length > 1
    });
  }


  getAlignIconClass() {
    switch (this.textAlign) {
      case 'left':
        return 'icon-zuoduiqiwenben';
      case 'center':
        return 'icon-juzhongwenben';
      case 'right':
        return 'icon-youduiqiwenben';
    }
  }

  onStrokeClick() {
    //const $text = $(this.el.options.text);
    let  text = this.el.options.text;
    let textArr = text.split('\n');
    let linesLength = [];
    textArr.forEach(element => {
      linesLength.push(element.length)
    });
    text = textArr.join('');
    this.el.options.text = textArr.join('');
    const scale = this.el.options.fontSize / 24;
    const fill = this.el.options.fill;
    const fontFamily = this.el.options.fontFamily;
    const lineHeight = this.el.options.lineHeight * 100 + '%';
    const letterSpacing = this.el.options.letterSpacing;
    const textAlign = this.el.options.textAlign;
    const textEl = new Text({
      id: this.editor.getNewID(),
      //props:this.el.options.textProps,
      props: {
        ...this.el.props,
        oWidth: this.el.options.textProps.oWidth,
        oHeight: this.el.options.textProps.oHeight,
        width: this.el.options.textProps.width * (scale / this.el.options.textOptions.scale),
        height: this.el.options.textProps.height * (scale / this.el.options.textOptions.scale),
      },
      options: {
        // 文本
        text: `<div class="text-wrap" style="font-size: 24px;letter-spacing:${letterSpacing}px;text-align:${textAlign};line-height:${lineHeight};color:${fill}"><div><font face=${fontFamily}>${text}</font></div></div>`,
        mode: 'h',
        // 每行字符数
        linesLength: linesLength,
        scale:scale,
        isNewText: true
      }
    });
    //console.log('textEl',textEl)
    //console.log('this.editor.template.scale',this.editor.template.scale)
    this.el.remove();
    this.editor.activePage.addElement(textEl);
  }
}
