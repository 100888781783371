import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';
import { Window } from '@libs/editor/src/lib/models/ui/window';
import { ReplaceCalendarComponent } from '@libs/shared/src/lib/components/modal/replace-calendar/replace-calendar.component';
import { UserInterfaceService } from '@libs/editor/src/lib/services/ui.service';
import { ModalService } from '@libs/core/src/lib/services/modal.service';
import { Modal } from '@libs/editor/src/lib/models/ui/modal';
import { CalendarContentToolComponent } from '@libs/editor/src/lib/components/editor-ui/t-tool-bar/modals/calendar-content-tool/calendar-content-tool.component';

@Component({
  selector: 'app-tool-bar-calendar',
  templateUrl: './tool-bar-calendar.component.html',
  styleUrls: ['./tool-bar-calendar.component.css']
})
export class ToolBarCalendarComponent implements OnInit,AfterViewInit {
  eleItem;
  styleEditor: boolean;
  public colorLength = [];
  public showBatchAddPop = false;
  constructor(
    public editor:EditorService,
    public ui:UserInterfaceService,
    public modal:ModalService
  ) { }

  ngOnInit() {
    this.eleItem = this.editor.activeElements[0];
    if (this.eleItem.options.color && this.eleItem.options.color.length) {
      this.colorLength = new Array(this.eleItem.options.color.length);
    }

    const {fonts, borderColors, bgColors} = this.eleItem.options.templateData;
    this.styleEditor = fonts || borderColors || bgColors;
  }

  onEditBtnClick(event) {
    this.ui.clearReference('calendar')
    const modal = new Modal({
      x: event.clientX - 222 / 2,
      y: event.clientY + 20,
      width: 222,
      overflow: 'visible',
      component: CalendarContentToolComponent
    });
    this.ui.add(modal);
  }

  ngAfterViewInit(): void {
    if (this.eleItem.options.color && this.eleItem.options.color.length) {
      this.colorLength = new Array(this.eleItem.options.color.length);
    }
  }

  onColorChange(color,index){
    this.eleItem.options.color[index] = color;
    this.eleItem.parent.emit('element:modified', {
      type: 'colorChange',
      targets: [this.eleItem]
    });
    //this.eleItem.states.html = this.eleItem.toHTML();
  }
  changeCalendarStyle(){
    this.ui.clearReference('calendar');

    const calendarWindow = new Window({
      width: 326,
      height: 560,
      modelType: 'calendar',
      props:{eleItem: this.eleItem, type: 'calendar'},
      skin: 'common',
      taskbar: false,
      title:"更换日历样式",
      component: ReplaceCalendarComponent
    });
    this.ui.add(calendarWindow);
  }
  batchAddCalendar(){
    this.showBatchAddPop = true;
  }
  closeBatchAdd(){
    this.showBatchAddPop = false;
  }
}
