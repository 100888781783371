import { Component, OnInit } from '@angular/core';
import { EditorService } from '../../../../../../../services/editor.service';

@Component({
  selector: 'app-image-radius-setting-tool',
  templateUrl: './image-radius-setting-tool.component.html',
  styleUrls: ['./image-radius-setting-tool.component.scss']
})
export class ImageRadiusSettingToolComponent implements OnInit {
  constructor(public editor: EditorService) {}

  ngOnInit() {}
  /**
   * 获取圆角值
   */
  getRadius() {
    const activeElement = this.editor.activeElements[0];
    if (!activeElement) {
      return;
    }
    if (activeElement.options.radius) {
      return activeElement.options.radius;
    } else {
      return 0;
    }
  }
  /**
   * 设置圆角值
   * @param value 值
   * @param fireEvent 是否触发事件
   */
  setRadius(value, fireEvent = true) {
    const eleItems = this.editor.activeElements;
    eleItems.forEach(eleItem => {
      eleItem.options.radius = value;
      if (fireEvent) {
        eleItem.emit('modified', {
          type: 'setRadius',
          target: eleItem,
          stopPropagation: eleItems.length > 1
        });
      } else {
        eleItem.states.html = eleItem.toHTML();
      }
    });
    if (fireEvent && eleItems.length > 1) {
      eleItems[0].parent.emit('element:modified', {
        type: 'setRadius',
        targets: eleItems
      });
    }
  }
}
