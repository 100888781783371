import { Page } from './page';

/**
 * 模版类(原docData)
 */
export interface TemplateProps {
  width: number;
  height: number;
  bleed: number;
  unit: string;
  splitLine: any;
  splitHorizonLine: any;
  split_cover: string;
  split_inside: string;
  bind_position: number;
  usedColors: string[];
  noFirstPage: boolean;
  banEndPage: boolean;
  addPage?: boolean;
  resize: boolean;
  cardSettingId: number;
  insideBackCover: boolean;
  //用户标记信息
  userSign?: string[];
  lockReference?: boolean;
}
export class Template {
  id = 0;
  title = '未命名';
  editFrom = null;
  props: TemplateProps = {
    width: 0,
    height: 0,
    bleed: 0,
    unit: 'px',
    split_cover: '0',
    split_inside: '0',
    bind_position: 0,
    splitLine: 0,
    splitHorizonLine: 0,
    resize: true,
    noFirstPage: false,
    banEndPage: false,
    cardSettingId: null,
    insideBackCover: false,
    usedColors: [],
    lockReference: false
  };
  // 页面
  pages: Page[] = [];
  // 事件管理器
  eventManager = [];
  // 事件ID计数器
  eventIDCounter = 0;
  states;
  constructor(params) {
    this.set(params);
  }
  get width() {
    const spine = window['editor'].activePage.props.spine;
    if (spine) {
      return this.props.width + spine;
    } else {
      return this.props.width;
    }
  }
  get height() {
    return this.props.height;
  }
  /**
   * 获取缩放比例
   */
  get scale() {
    switch (this.props.unit) {
      case 'px':
        return 1;
      case 'mm':
        return 300 / 25.4;
      case 'cm':
        return (300 * 10) / 25.4 ;
    }
    // if (this.states && this.states.dpi) {
    //   console.log('指定DPI',);

    //   // 如果这个模版指定dpi,这应用指定dpi
    //   const dpi = parseInt(this.states.dpi, 10);
    //   if (this.props.unit === 'px') return 1;
    //   return dpi / 25.4;
    // } else {
    //   switch (this.props.unit) {
    //     case 'px':
    //       return 1;
    //     case 'mm':
    //       return 300 / 25.4;
    //   }
    // }
  }
  /**
   * 获取分辨率
   */
  get dpi() {
    if (this.states && this.states.dpi) {
      // 如果这个模版指定dpi,这应用指定dpi
      const dpi = parseInt(this.states.dpi, 10);
      return dpi;
    } else {
      switch (this.props.unit) {
        case 'px':
          return 72;
        case 'mm':
          return 300;
      }
    }
  }
  /**
   * 设置模版属性
   * @param params 属性object
   */
  set(params): void {
    if (params.states) {
      this.states = params.states;
    }
    if (params.title) {
      this.title = params.title;
    }
    if (params.id) {
      this.id = params.id;
    }
    if (params.props) {
      this.props = {
        ...this.props,
        ...params.props
      };
    }
  }
  /**
   * 创建新页面
   * @param {Page} pageItem 页面对象
   */
  public addPage(pageItem: Page, fireEvent = true): void {
    pageItem.parent = this;
    this.pages.push(pageItem);

    pageItem.emit('added', {
      target: pageItem,
      stopPropagation: true
    });
    if (fireEvent) {
      this.emit('page:added', {
        target: pageItem
      });
    }
  }
  /**
   * 插入新页面(用于复制页面)
   * @param pageItem 要插入的页面对象
   * @param toIndex 要插入的位置
   * @param fireEvent 是否触发事件
   */
  public insertPage(pageItem: Page, toIndex, fireEvent = true): void {
    pageItem.parent = this;
    this.pages.splice(toIndex, 0, pageItem);
    /*pageItem.emit('added', {
            target: pageItem,
            // 本次事件触发不提交状态
            commit: false
        });*/
    pageItem.emit('added', {
      target: pageItem,
      stopPropagation: true
    });
    if (fireEvent) {
      this.emit('page:added', {
        target: pageItem
      });
    }
  }

  /**
   * 替换整个模板的名片信息
   * @param signData
   */
  async loadCardSetting(signData, fireEvent = true) {
    for (const pageItem of this.pages) {
      await pageItem.loadCardSetting(signData, false)
    }
    if (fireEvent) {
      this.emit('modified', {commit: true})
    }
  }
  /**
   * 注册事件侦听器
   * @param event 事件名
   * @param callback 回调函数
   */
  public on(event: string, callback: Function): any {
    const events = event.split(' ');
    if (events.length === 1) {
      this.eventIDCounter++;
      const id = this.eventIDCounter;
      this.eventManager.push({
        id,
        event,
        callback
      });
      return id;
    } else if (events.length > 1) {
      const idArr = [];
      for (const item of events) {
        this.eventIDCounter++;
        const id = this.eventIDCounter;
        this.eventManager.push({
          id,
          event: item,
          callback
        });
        idArr.push(id);
      }
      return idArr;
    }
  }
  /**
   * 销毁事件侦听器
   * @param eventID 注册事件侦听器返回的ID
   */
  public off(eventID): any {
    if (eventID.length) {
      eventID.forEach(event => {
        const eventIndex = this.eventManager.findIndex(
          item => item.id === event
        );
        if (eventIndex !== -1) {
          this.eventManager.splice(eventIndex, 1);
        } else {
          throw new Error('无法销毁未注册的事件');
        }
      });
    } else {
      const eventIndex = this.eventManager.findIndex(
        item => item.id === eventID
      );
      if (eventIndex !== -1) {
        this.eventManager.splice(eventIndex, 1);
      } else {
        throw new Error('无法销毁未注册的事件');
      }
    }
  }

  /**
   * 删除页面对象
   * @param pageItem 元素对象
   * @param fireEvent 是否触发remove事件
   */
  public removePage(pageItem: Page, fireEvent = true): void {
    const idx = this.pages.findIndex(item => item === pageItem);
    if (idx === -1) {
      throw new Error('试图删除不存在的页面');
    } else {
      this.pages.splice(idx, 1);
      this.emit('removed', {
        target: pageItem,
        stopPropagation: true
      });
      if (fireEvent) {
        this.emit('page:removed', {
          target: pageItem
        });
      }
    }
  }
  /**
   * 触发事件
   * @param event 事件名
   * @param data 触发事件
   */
  public emit(event: string, data: any = {}): void {
    this.eventManager.forEach(item => {
      if (item.event === event) {
        item.callback({
          event,
          ...data
        });
      }
    });
  }
  /**
   * 获取整个模版的Object对象
   */
  public toObject(deepClone = false, snap = false): any {
    const obj = {
      props: this.props,
      pages: []
    };
    for (const pageItem of this.pages) {
      obj.pages.push(pageItem.toObject(false, snap));
    }
    if (deepClone) {
      return JSON.parse(JSON.stringify(obj));
    } else {
      return obj;
    }
  }
  /**
   * 获取JSON文本
   */
  public toJson(deepClone = false, snap = false): string {
    const obj = this.toObject(deepClone, snap);
    return JSON.stringify(obj);
  }
}
