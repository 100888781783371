import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppData } from '../../+state/app.reducer';
import { MenuOfficial } from '../../+state/app.actions';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { selectRouter } from '../../+state/root.reducer';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-template',
  template: `
      <ng-container *ngIf="coopService.coopInfo?.is_diy === '1' && isDiy; else notemDiy">
          <router-outlet></router-outlet>
      </ng-container>
      <ng-template #notemDiy>
          <app-new-header></app-new-header>
          <div class="app-body">
              <router-outlet></router-outlet>
          </div>
          <app-footer></app-footer>
      </ng-template>
  `,
  styles: [`
      /*::ng-deep ::-webkit-scrollbar {*/
      /*    width: 0;*/
      /*}*/

      ::ng-deep button {
          border: none;
          outline: none;
          appearance: none;
          padding: 0;
      }

      ::ng-deep input {
          border: none;
          outline: none;
          appearance: none;
          padding: 0;
      }
  `]
})
export class TemplateComponent implements OnInit {
  isDiy = false;
  url: string;
  routerSubscription: Subscription;

  constructor(
    private store: Store<AppData>,
    private stores: Store<any>,
    public coopService: CoopService
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(new MenuOfficial());
    this.routerSubscription = this.stores.select(selectRouter)
      .subscribe(data => {
        this.url = data.state.url;
        const urls = data.state.url.split('?')[0];
        if (this.url === '/template/center') {
          this.isDiy = true;
        } else {
          this.isDiy = false;
        }
      });
  }
}
