import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { OrderService } from '@libs/core/src/lib/services/post-order/order.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { map, debounceTime, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataProvider } from '@libs/core/src/lib/util/data-provider';
import { DesignerService } from '@libs/core/src/lib/services/post-order/designer.service';

@Component({
	selector: 'message-box',
	templateUrl: 'message-box.component.html',
	styleUrls: ['./message-box.component.scss']
})

export class MessageBoxComponent implements OnInit {
	@Input() type;
  @Input() message;

	constructor(
		// private message: MessageService,
		private modal: NzModalRef,
		private order: OrderService,
		private router: Router,
		public authService: AuthService,
		private designerService: DesignerService,
		public coop: CoopService) {}


  close() {
    this.modal.close();
  }


  ngOnInit() {
  }
}
