import { Injectable } from '@angular/core';
import { TemplateMember } from '@libs/core/src/lib/models/template-member';
import { Observable } from 'rxjs';
import { TemplateOfficial } from '@libs/core/src/lib/models/template-official';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {
  apiEditFolder,
  apiFolderMaterial,
  apiFolderTemplate, apiMemberCharge,
  apiMemberFavorite, apiMemberInvite,
  apiMemberMaterial,
  apiMemberMaterialOperation, apiMemberShareTemplate, apiMemberTemp,
  apiMemberTemplate, apiMemberTemplateCount, apiMemberTemplateDownload,
  apiMemberTemplateOperation, apiMemberTrash, apiMemberVipList, apiUser, parseUcFirst,apiTemplateDesigner
} from '@libs/core/src/lib/util/api';
import { FolderMember } from '@libs/core/src/lib/models/folder-member';
import { FolderTeam } from '@libs/core/src/lib/models/folder-team';
import { Order } from '@libs/core/src/lib/models/order';
import { MaterialMember } from '@libs/core/src/lib/models/material-member';
import { OrderVip } from '@libs/core/src/lib/models/OrderVip';
import { MemberCharge } from '@libs/core/src/lib/models/member-charge';
import { MemberInvite } from '@libs/core/src/lib/models/member-invite';
import { ViewShared } from '@libs/core/src/lib/models/viewshare';
const apiNewTemplateText = '/folder-template';
const apiText = '/template-member';

@Injectable()
export class MemberService {
  apiViewShare = '/share-template/view-share';
  constructor(private http: HttpClient) { }

  /**
   * 我的设计
   * @returns {Observable<TemplateMember[]>}
   */
  getMemberTemplateList(
    params = {},
    headers?: HttpHeaders
  ): Observable<HttpResponse<TemplateMember[]>> {
    params['expand'] = 'pages';
    return this.http.get<TemplateMember[]>(apiText, {
      headers: headers,
      params: params,
      observe: 'response'
    });
  }

  getMemberTemplateList1(
    params = {},
    headers?: HttpHeaders
  ): Observable<TemplateMember[]> {
    params['expand'] = 'pages';
    return this.http.get<TemplateMember[]>(apiMemberTemp, {
      headers: headers,
      params: params
    });
  }

  /**
   * 我的收藏
   * @returns {Observable<TemplateOfficial[]>}
   */
  getMemberFavoriteList(
    params = {}
  ): Observable<HttpResponse<TemplateOfficial[]>> {
    return this.http.get<TemplateOfficial[]>(apiMemberFavorite, {
      params: params,
      observe: 'response'
    });
  }

  /**
   * 我的回收站
   * @returns {Observable<Order[]>}
   */
  getMemberTrashList(
    params = {}
  ): Observable<HttpResponse<TemplateOfficial[]>> {
    return this.http.get<TemplateOfficial[]>(apiMemberTrash, {
      params: params,
      observe: 'response'
    });
  }
  /**
   * 个人文件夹列表
   * @returns {Observable<FolderMember | FolderTeam>}
   */
  // getFolderList(): Observable<FolderMember[]> {
  //   return this.http.get<FolderMember[]>(apiFolderTemplate);
  // }
  getFolderList(): Observable<FolderMember[]> {
    return this.http.get<FolderMember[]>(apiNewTemplateText);
  }

  /**
   * 个人文件夹列表
   * @returns {Observable<FolderMember | FolderTeam>}
   */
  getFolderMaterial(params = {}): Observable<FolderMember[]> {
    return this.http.get<FolderMember[]>(apiFolderMaterial,{
      params: params
    });
  }

  /**
   * 查询用户素材
   * @param {{}} params
   * @returns {Observable<HttpResponse<MaterialMember[]>>}
   */
  getMaterialList(params = {}): Observable<HttpResponse<MaterialMember[]>> {
    return this.http.get<MaterialMember[]>(apiMemberMaterial, {
      params: params,
      observe: 'response'
    });
  }

  /**
   * 查询用户素材
   * @param {{}} params
   * @returns {Observable<HttpResponse<MaterialMember[]>>}
   */
  getMaterial(params = {}): Observable<HttpResponse<MaterialMember[]>> {
    return this.http.get<MaterialMember[]>('/material', {
      params: params,
      observe: 'response'
    });
  }

  /**
   * 创建或修改文件夹
   * @param {FolderMember} data
   * @param {string} type
   * @returns {Observable<FolderMember>}
   */
  updateFolder(data: FolderMember, type: string): Observable<FolderMember> {
    const url = type === 'template' ? apiEditFolder : apiFolderMaterial;
    if (data.id > 0)
      return this.http.put<FolderMember>(url + '/' + data.id, data);
    else return this.http.post<FolderMember>(url, data);
  }

  operation(data): Observable<any> {
    return this.http.post(apiMemberTemplateOperation, data);
  }

  operationMaterial(data): Observable<any> {
    return this.http.post(apiMemberMaterialOperation, parseUcFirst(data));
  }

  /**
   * 删除文件夹
   * @param {FolderMember} data
   * @param {string} type
   * @param {boolean} withDelete
   * @returns {Observable<any>}
   */
  deleteFolder(data: FolderMember, type: string, withDelete: string = '0'): Observable<any> {
    const url = type === 'template' ? apiEditFolder : apiFolderMaterial;
    const params = {with_delete: withDelete};
    return this.http.delete(url + '/' + data.id, {params: params});
  }
  /**
   * 修改官方模板
   */
  update(params): Observable<TemplateMember> {
    return this.http.put<TemplateMember>(
      apiMemberTemplate + '/' + params.templateId,
      params
    );
  }

  create(params): Observable<TemplateMember> {
    return this.http.post<TemplateMember>(apiMemberTemplate, params);
  }

  templateDesigner(params): Observable<TemplateMember> {
    return this.http.post<TemplateMember>(apiTemplateDesigner, params);
  }

  delete(item: TemplateMember): Observable<any> {
    return this.http.delete(apiMemberTemplate + '/' + item.templateId);
  }

  /**
   * 共享模板
   * @param {number} templateId
   * @param {number} userId
   * @returns {Observable<any>}
   */
  shareTemplate(templateId: number, userId: number): Observable<any> {
    return this.http.post(apiMemberShareTemplate, { sharing_template: templateId, shared_person: userId });
  }

  view(fromId): Observable<any> {
    const params = {
      expand: 'content,editConfig,setInfo'
    };
    return this.http.get(apiMemberTemplate + '/' + fromId, { params: params });
  }

  count(): Observable<any> {
    return this.http.get(apiMemberTemplateCount);
  }

  download(params): Observable<string> {
    return this.http.get<string>(apiMemberTemplateDownload, { params: params });
  }

  /**
   * vip充值记录
   * @returns {Observable<HttpResponse<OrderVip[]>>}
   */
  getVipList(params): Observable<HttpResponse<OrderVip[]>> {
    return this.http.get<OrderVip[]>(apiMemberVipList, { params: params, observe: 'response' });
  }

  /**
   * 图币记录
   * @param params
   * @returns {Observable<HttpResponse<MemberCharge[]>>}
   */
  getCoinLog(params): Observable<HttpResponse<MemberCharge[]>> {
    return this.http.get<MemberCharge[]>(apiMemberCharge, { params: params, observe: 'response' });
  }

  getInvite(params): Observable<HttpResponse<MemberInvite[]>> {
    return this.http.get<MemberInvite[]>(apiMemberInvite, {params: params, observe: 'response'});
  }

  //查看相应分享
  viewShare(params): Observable<ViewShared> {
    return this.http.get<ViewShared>(this.apiViewShare, {params: params});
  }

  /**
   * 为你印定制接口
   * 收藏用户模版
   * @param params
   */
  favoriteTemplate(params) {
    return this.http.post(apiText + '/favorite', params);
  }
}
