import { Action } from '@ngrx/store';
import { TemplateMember } from '@libs/core/src/lib/models/template-member';
import { User } from '@libs/core/src/lib/models/user';
import { Message } from '@libs/core/src/lib/models/message';

export enum AppActionTypes {
  PersonalAction = '[App Personal]',
  TeamAction = '[App Team]',
  MenuMember = '[App Menu Member]',
  MenuOfficial = '[App Menu official]',
  MenuCategoryActive = '[App Menu Category Active]',
  // Login
  WechatLogin = '[Login Wechat]',
  PasswordLogin = '[Login Password]',
  RegisterLogin = '[Login Register]',
  MobileLogin = '[Login Mobile]',
  BindMobile = '[Login Bind Mobile]',
  ForgetPassword = '[Login Forget Password]',
  ResetPassword = '[Login Reset Password]',
  ModifySuccess = '[Login Modify Success]',
  // Diy
  ModalOpenDiy = '[Modal Open Diy]',
  ModalOpenImport = '[Modal Open Import]',
  ModalOpenNotice = '[Modal Open Notice]',
  ModalOpenNoticeDetail = '[Modal Open Notice Detail]',
  ModalOpenTeam = '[Modal Open Team]',
  ModalOpenAccount = '[Modal Open Account]',
  ModalOpenTemplatePreview = '[Modal Open Member Template Preview]',
  ModalClose = '[Modal Close]',
  DeleteTemplate = '[Delete Template]',
  ReductionTemplate = '[Reduction Template]',
  DoneImport = '[Done Import]',

  //侧边栏状态
  SideBarHas = '[Side Bar Has]',
  SideBarNot = '[Side Bar Not]'

  // 导入模版
}

export class SideBarHas implements Action {
  readonly type = AppActionTypes.SideBarHas;
}

export class SideBarNot implements Action {
  readonly type = AppActionTypes.SideBarNot;
}

export class Personal implements Action {
  readonly type = AppActionTypes.PersonalAction;
}

export class Team implements Action {
  readonly type = AppActionTypes.TeamAction;

  constructor(public payload: number) {
  }
}

export class MenuMember implements Action {
  readonly type = AppActionTypes.MenuMember;
}

export class MenuOfficial {
  readonly type = AppActionTypes.MenuOfficial;
}

export class MenuCategoryActive {
  readonly type = AppActionTypes.MenuCategoryActive;

  constructor(public payload: string) {
  }
}

// 微信登录
export class WechatLogin implements Action {
  readonly type = AppActionTypes.WechatLogin;
}

// 账号密码登录
export class PasswordLogin implements Action {
  readonly type = AppActionTypes.PasswordLogin;
}

// 手机号验证码登录
export class MobileLogin implements Action {
  readonly type = AppActionTypes.MobileLogin;
}

// 注册登录
export class RegisterLogin implements Action {
  readonly type = AppActionTypes.RegisterLogin;
}

// 绑定手机
export class BindMobile implements Action {
  readonly type = AppActionTypes.BindMobile;

  constructor(public payload: User) {
  }
}

//找回密码
export class ForgetPassword implements Action {
  readonly type = AppActionTypes.ForgetPassword;
}

//重置密码
export class ResetPassword implements Action {
  readonly type = AppActionTypes.ResetPassword;
}

//修改密码成功
export class ModifySuccess implements Action {
  readonly type = AppActionTypes.ModifySuccess;
}

export class ModalOpenDiy implements Action {
  readonly type = AppActionTypes.ModalOpenDiy;
}

export class ModalOpenTeam implements Action {
  readonly type = AppActionTypes.ModalOpenTeam;
}

export class ModalOpenNotice implements Action {
  readonly type = AppActionTypes.ModalOpenNotice;
}

export class ModalOpenNoticeDetail implements Action {
  readonly type = AppActionTypes.ModalOpenNoticeDetail;

  constructor(public payload: Message) {
  }
}

export class ModalOpenAccount implements Action {
  readonly type = AppActionTypes.ModalOpenAccount;

  constructor(public payload: string) {
  }
}

export class ModalClose implements Action {
  readonly type = AppActionTypes.ModalClose;
}

export class ModalOpenTemplatePreview implements Action {
  readonly type = AppActionTypes.ModalOpenTemplatePreview;

  constructor(public payload: TemplateMember) {
  }
}

export class ModalOpenImport implements Action {
  readonly type = AppActionTypes.ModalOpenImport;
}

export class DeleteTemplate implements Action {
  readonly type = AppActionTypes.DeleteTemplate;

  constructor(public payload: number) {
  }
}

export class ReductionTemplate implements Action {
  readonly type = AppActionTypes.ReductionTemplate;

  constructor(public payload: number) {
  }
}

export class DoneImport implements Action {
  readonly type = AppActionTypes.DoneImport;
}

export type AppActions =
  | Personal
  | Team
  | MenuMember
  | MenuOfficial
  | MenuCategoryActive
  | WechatLogin
  | PasswordLogin
  | MobileLogin
  | RegisterLogin
  | BindMobile
  | ForgetPassword
  | ResetPassword
  | ModifySuccess
  | ModalOpenDiy
  | ModalOpenTeam
  | ModalOpenImport
  | ModalOpenNotice
  | ModalOpenNoticeDetail
  | ModalOpenAccount
  | ModalOpenTemplatePreview
  | DeleteTemplate
  | ReductionTemplate
  | DoneImport
  | SideBarHas
  | SideBarNot
  | ModalClose;
