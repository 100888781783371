import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { selectAuthUser } from '@libs/core/src/lib/+state/auth.reducer';
import { User } from '@libs/core/src/lib/models/user';
import { Router } from '@angular/router';
import { Logout } from '@libs/core/src/lib/+state/auth.actions';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { CoopService } from '@libs/core/src/lib/services/coop.service';
import { Observable, of, throwError, zip } from 'rxjs';
import { map, mergeMap, shareReplay, switchMap, take, tap } from 'rxjs/operators';

/** Pass untouched request through to the next request handler. */
export class RestInterceptor implements HttpInterceptor {
  accessToken: string;
  teamId: number;

  constructor(
    public config: any,
    private store: Store<any>,
    private router: Router,
    private message: MessageService,
    private coop: CoopService
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const baseUrl = this.config.baseUrl + '/' + this.config.version;
    // 处理headers
    return this.store.select(selectAuthUser).pipe(
      take(1),
      map(user => user && user.accessToken || null),
      switchMap(data => {
        if (
          req.url.indexOf('/assets') !== 1 &&
          req.url.match(/^(http|https|\/\/)/) === null
        ) {
          return next.handle(req.clone({
            setHeaders: {
              Client: this.config.clientId,
              Handle: this.config.handle,
              CoopId: this.config.coop_id,
              Authorization: data ? 'Bearer ' + data : ''
            },
            url: req.url === '/api/check-login' ? req.url : baseUrl + req.url,
            withCredentials: true
          }));
        } else {
          return next.handle(req);
        }
      }),
      mergeMap((event: any) => {
        // 允许统一对请求错误处理，这是因为一个请求若是业务上错误的情况下其HTTP请求的状态是200的情况下需要

        if (
          event instanceof HttpResponse &&
          event.body &&
          (event.body.success === false || event.body.success === true)
        )
          if (event.body.success === false) {
            const httpError = new HttpErrorResponse({
              error: event.body.data,
              headers: event.headers,
              status: event.body.data.status,
              statusText: event.body.data.name,
              url: event.url
            });
            if (httpError.status === 403) {
              // 判断是否过期
              if (this.accessToken) {
                let payload = JSON.parse(atob(this.accessToken.split('.')[1]));
                const timestamp = Date.parse(new Date().toString());

                if ((payload.exp - 1800) < timestamp / 1000) {
                  window && window.location.reload();
                } else {
                  this.store.dispatch(new Logout());
                  this.message.error(event.body.data.message)
                }
              }
            }
            if (event.body.data.code === 90002) {
              if (this.coop.coopInfo.login_url) {
                window.location.href = this.coop.coopInfo.login_url
              } else {
                this.router.navigate(['login'])
              }
            }
            return throwError(httpError);
          } else if (event.body.success === true) {
            const response = event.clone({
              body: event.body.data
            });

            return of(response);
          }
        return of(event);
      }),
      shareReplay()
    )
  }
}
