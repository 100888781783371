import { HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs';

export class DataProvider<T> {
  initialPages = {
    searchTotal: 0,
    current: 0,
    total: 0,
    totalPages: 0,
    keywords: '',
    prePage: 0,
    relationClassify: ''
  };
  private _data: T;
  private _pages: any = this.initialPages;
  private params: any;

  constructor(private data$?: Subject<any>) {}

  public reset() {
    this._pages = this.initialPages;
  }

  public prepare(data: HttpResponse<T>) {
    this._pages = {
      searchTotal: data.headers.get('Template-Search-Total'),
      current: data.headers.get('X-Pagination-Current-Page'),
      total: data.headers.get('X-Pagination-Total-Count'),
      totalPages: data.headers.get('X-Pagination-Page-Count'),
      keywords: data.headers.get('Search-Key-Words'),
      prePage: data.headers.get('X-Pagination-Per-Page'),
      relationClassify: data.headers.get('Relation-Classify')
    };
    return data.body;
  }

  get currentPage() {
    return parseInt(this._pages.current);
  }

  /**
   * 设置页码
   * @param data
   */
  set currentPage(data) {
    this._pages.current = data;
    this.params = {
      page: data,
      limit : this._pages.prePage
    };
    if (this.data$) this.data$.next(this.params);
  }

  get searchTotal() {
    return this._pages.searchTotal;
  }

  get total() {
    return this._pages.total;
  }

  get totalPages() {
    return parseInt(this._pages.totalPages);
  }

  get keywords() {
    return decodeURIComponent(this._pages.keywords);
  }

  get prePage() {
    return this._pages.prePage;
  }

  /**
   * 设置页数
   * @param data
   */
  set prePage(data) {
    this._pages.prePage = data;
    this.params = {
      page:this._pages.current,
      limit: data
    };
    if (this.data$) this.data$.next(this.params);
  }  

  get relationClassify() {
    if (this._pages.relationClassify) {
      return JSON.parse(decodeURIComponent(this._pages.relationClassify));
    } else {
      return ;
    }
  }

  public get data(): T {
    return this._data;
  }

  public set data(data) {
    this._data = data;
  }
}
