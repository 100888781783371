import { Component, Input, OnInit } from '@angular/core';
import { EditorService } from '@libs/editor/src/lib/services/editor.service';
import { HttpClient } from '@angular/common/http';
import { Layout } from '@libs/editor/src/lib/models/element/layout';
import { Combination } from '@libs/editor/src/lib/models/element/combination';
import { ModalService } from '@libs/modal/src/lib/services/modal.service';
import { ToastIcon } from '@libs/modal/src/lib/models/toast-icon';
import { deepClone } from '@libs/editor/src/lib/utils/misc/common';

@Component({
  selector: 'app-combination-list',
  templateUrl: './combination-list.component.html',
  styleUrls: ['./combination-list.component.scss']
})
export class CombinationListComponent implements OnInit {
  @Input() isWindow = false;
  @Input() element;

  public classifyList;
  public chooseClassify: any;
  public materialList = [];
  public totalCount: number;
  public totalPage: number;
  public loading: boolean;
  public materialPage = 1;
  currentPage = 1;
  totalNum;
  currentCombination;
  combinationLoading = false;
  public tabList = [
    {
      name:'商品组合',
      component:'current-com'
    }
  ];

  constructor(
    public editor: EditorService,
    public http: HttpClient,
    public modal: ModalService
  ) { }

  ngOnInit() {
    this.loadTagList()
  }

  classifyClick(classify) {
    this.chooseClassify = classify;
    this.materialPage = 1;
    this.totalPage = 0;
    this.materialList = [];
    this.loadList();
  }
  /**
   * 组合素材被点击
   * @param item 组合素材item
   */
  async combinationClick(item) {
    const data: any = await this.http.post('/image-combination/json', {id: item.id}).toPromise();
    const element = JSON.parse(data.json)
    if (this.element) {
      this.replaceCombination(element)
    } else {
      this.addCombination(element)
    }
  }
  /**
   * 添加组合到当前页
   * @param item 组合素材item
   */
  addCombination(element) {
    const {width, height} = this.editor.activePage;
    const {width: eWidth, height: eHeight} = element.props;
    element.props.x = (width - eWidth) / 2;
    element.props.y = (height - eHeight) / 2;
    delete element.type;
    delete element.options.name;

    const combination = new Combination({
      ...element,
      id: this.editor.getNewID()
    });
    this.editor.activePage.addElement(combination);
  }
  /**
   * 替换样式到选中组合
   * @param item 组合素材item
   */
  async replaceCombination(element) {
    // 对比打标是否一致
    const signNow = Object.keys(this.element.options.items[0].data);
    const signRep = Object.keys(element.options.items[0].data);

    if (
      signRep.some(sign => sign !== '条形码' && signNow.indexOf(sign) === -1)
    ) {
      this.modal.toast({
        icon: ToastIcon.ERROR,
        msg: '打标内容不匹配'
      });
      return;
    }

    // 定位保持不变
    this.element.props.oWidth = element.props.oWidth;
    this.element.props.oHeight = element.props.oHeight;
    this.element.options.baseElements = element.options.baseElements;

    for (let item of this.element.options.items) {
      const elements = Combination.loadElements(deepClone(element.options.baseElements), this.element.parent);
      for (const element of elements) {
        if (element.props.sign in item.data) {
          element.changeSignCombination(item.data[element.props.sign])
        }
      }

      item.elements = elements;
    }
    this.element.emit('modified',{
      target: this.element
    })
  }
  /**
   * 加载二级分类
   */
  async loadTagList() {
    this.classifyList = (await this.http.get('/image-combination').toPromise()) as any[];
    this.classifyList.unshift({ id: '0', name: '全部' });
    this.chooseClassify = this.classifyList[0];
    this.loadList();
  }
  /**
   * 获取组合列表
   */
  async loadList() {
    if (this.loading === true) return;
    this.loading = true;
    const params = { id: this.chooseClassify.id, page: this.materialPage.toString(), limit: '20' };
    const res = await this.http.get('/image-combination/' + params.id, { params, observe: 'response' }).toPromise();
    this.totalPage = parseInt(res.headers.get('X-Pagination-Page-Count'), 10);
    this.totalNum = parseInt(res.headers.get('X-Pagination-Total-Count'), 10);
    this.loading = false;
    this.materialList = this.materialList.concat(res.body);
  }

  //下拉事件 加载下一页
  onFileListScroll(event) {
    const scrollPos = event.target.scrollTop,
      hiddenHeight =
        event.target.scrollHeight -
        event.target.getBoundingClientRect().height;
    if (scrollPos >= hiddenHeight - 50) {
      if (this.loading === true) return;
      if (this.materialPage >= this.totalPage && this.totalPage > 0) return;
      this.materialPage++;
      this.loadList();
    }
  }
  //获取当前模版组合
  async getCurrentCombination(){
    this.combinationLoading = true;
    let arr = [];
    for(let pageItem of this.editor.template.pages){
      for (let element of pageItem.elements){
        if (element.type == 'layout'){
          if(!arr.find(item=>element.options.source === item.fileId)){
            const data = await this.http.post('/material-official/file',{file_id:element.options.source}).toPromise();
            if (data){
              arr.push(data)
            } else{
              arr.push({fileId:element.options.source,filePath:element.options.url,width:element.props.oWidth,height:element.props.oHeight})
            }
          }
        }
      }
    }
    this.currentCombination = arr;
    this.combinationLoading = false;
  }
}
