import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { mobileValidator } from '@libs/shared/src/lib/directives/mobile.directive';
import { AuthService } from '@libs/core/src/lib/services/auth.service';
import { LoginSuccess } from '@libs/core/src/lib/+state/auth.actions';
import { Store } from '@ngrx/store';
import { AuthState } from '@libs/core/src/lib/+state/auth.reducer';
import { MessageService } from '@libs/core/src/lib/services/message.service';
import { passwordValidator } from '@libs/shared/src/lib/validators/passwordvalidators';

@Component({
  selector: 'app-new-register',
  templateUrl: './new-register.component.html',
  styleUrls: ['./new-register.component.scss']
})
export class NewRegisterComponent implements OnInit {
  regForm: FormGroup;
  //倒计时
  count = 0;
  //同意协议
  agree = true;

  loadingSubmit: boolean;

  constructor(
    private fb: FormBuilder,
    private store: Store<AuthState>,
    private authService: AuthService,
    private message: MessageService
  ) { }

  ngOnInit() {
    this.regForm = this.fb.group({
      is_coop: [''],
      coop_agent_id: [''],
      coop_id: [''],
      agree: [''],
      mobile: ['', [Validators.required, mobileValidator()]],
      sms_code: ['', [Validators.required, Validators.minLength(6)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      password_repeat: ['', [Validators.required]]
    },
    { validator: passwordValidator }
    );
  }

    //绑定手机获取短信验证码
    getMobileCode(mobile, type): void {
      this.count = 59;
      const interval$ = setInterval(() => {
        this.count -= 1;
        if (this.count <= 0) clearInterval(interval$);
      }, 1000);
      this.authService.getSms(mobile, type).subscribe(data => {
      });
    }
    onSubmit() {
      if (this.regForm.invalid) return;
      this.loadingSubmit = true;
      console.log(this.regForm.value);
      this.authService.register(this.regForm.value).subscribe(
        res => {
          this.loadingSubmit = false;
          if (res.status === 'y') {
            this.store.dispatch(new LoginSuccess({ user: res.user }));
            location.href = res.callback;
          } else {
            this.message.info(res.msg);
          }
        },
        err => {
          this.loadingSubmit = false;
          console.log(err);
        }
      );
    }

}
